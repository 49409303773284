import { memo, useEffect, useMemo } from "react";
import { Form, Button, Row, CardHeader, Card } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import CustomInput from "components/Common/CustomInput";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { OrganizationService } from "services/Employer/NewMember/Organization";
import CustomTextarea from "components/CustomTextarea";
import { ReducerType } from "redux/reducers";
import { setSettingReducer } from "redux/reducers/Employer/actionTypes";
import SearchLocationInput from "components/Common/GoogleAddress";

interface Props {}

interface OrganisationFormData {
  recruitOnBehalf: boolean;
  id?: number;
  name: string;
  mission?: string;
  culture?: string;
  city?: string;
  country?: string;
  address?: string;
  email?: string;
}

const Organisation = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);

  // Yup schema for form validation
  const schema = useMemo(
    () =>
      yup.object().shape({
        recruitOnBehalf: yup.boolean(),
        name: yup.string().required("Organisation name is required"),
        mission: yup.string().nullable(),
        culture: yup.string().nullable(),
        address: yup.string().nullable(),
        city: yup.string().nullable(),
        country: yup.string().nullable(),
        email: yup.string().email().nullable(),
      }),
    []
  );

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<OrganisationFormData>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: {
      name: "",
      mission: "",
      culture: "",
      address: "",
      city: "",
      country: "",
      email: user?.email || "",
      recruitOnBehalf: false,
    },
  });

  // Watch for changes in recruitOnBehalf field
  const recruitOnBehalf = watch("recruitOnBehalf");

  useEffect(() => {
    if (setting && user) {
      reset({
        recruitOnBehalf: setting?.recruitOnBehalf ?? false,
        id: setting?.idOrganisation,
        name: setting?.name || "",
        mission: setting?.mission || "",
        culture: setting?.culture || "",
        address:
          setting?.city && setting?.country
            ? `${setting?.city}, ${setting?.country}`
            : "",
        email: user?.email || "",
      });
    }
  }, [dispatch, reset, setting, user]);

  const handleBack = () => {
    dispatch(push(routes.employer.newMember.basicProfile));
  };

  const currentDate = new Date();
  const renewalDate = new Date(currentDate.setDate(currentDate.getDate() + 10)).getTime();

  // Submit handler for form submission
  const onSubmit = async (data: OrganisationFormData) => {
    try {
      // Common data for both PUT and POST requests
      const commonDataSubmit = {
        name: data.name,
        mission: data.mission || "",
        culture: data.culture || "",
        city: data.city || "",
        country: data.country || "",
        email: data.email || "",
      };
  
      dispatch(setLoading(true));
  
      if (data?.id) {
        // PUT request: do not include `paid` or `dateOfRenewal`
        const res = await OrganizationService.putOrganization(commonDataSubmit, data?.id);
        dispatch(
          setSettingReducer({
            ...setting,
            idOrganisation: res?.id,
            name: res?.name,
            mission: res?.mission,
            culture: res?.culture,
            city: res?.city,
            country: res?.country,
            email: res?.email,
          })
        );
      } else {
        // POST request: include `paid` and `dateOfRenewal`
        const currentDate = new Date();
        const renewalDate = new Date(currentDate.setDate(currentDate.getDate() + 10)).getTime();
        const postDataSubmit = {
          ...commonDataSubmit,
          paid: true,
          dateOfRenewal: renewalDate,
        };
  
        const res = await OrganizationService.postOrganization(postDataSubmit);
        dispatch(
          setSettingReducer({
            ...setting,
            idOrganisation: res?.id,
            name: res?.name,
            mission: res?.mission,
            culture: res?.culture,
            city: res?.city,
            country: res?.country,
            email: res?.email,
            paid: true,
            dateOfRenewal: renewalDate,
          })
        );
      }
  
      dispatch(push(routes.employer.admin.dashboard));
    } catch (error) {
      console.error("Error during form submission", error);
      dispatch(setErrorMess(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
  

  return (
    <Card className={classes.card}>
      <CardHeader>
        <p className={classes.title}>Your Organisation</p>
      </CardHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.content}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <CustomInput
                placeholder="Organisation name"
                type="text"
                {...field}
                errorMessage={errors.name?.message}
              />
            )}
          />

          {!recruitOnBehalf && (
            <>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <SearchLocationInput
                    {...field}
                    placeholder="Your nearest town or city"
                    errorMessage={errors.address?.message}
                    onChange={(value: any) => {
                      setValue("city", value?.address?.city);
                      setValue("country", value?.address?.country);
                      field.onChange(value?.address?.formattedAddress);
                    }}
                  />
                )}
              />

              <Controller
                name="mission"
                control={control}
                render={({ field }) => (
                  <CustomTextarea
                    className={classes.textarea}
                    placeholder="What is your mission statement?"
                    {...field}
                    errorMessage={errors.mission?.message}
                  />
                )}
              />

              <Controller
                name="culture"
                control={control}
                render={({ field }) => (
                  <CustomTextarea
                    className={classes.textarea}
                    placeholder="What culture do you look for?"
                    {...field}
                    errorMessage={errors.culture?.message}
                  />
                )}
              />
            </>
          )}
        </div>

        <Row className="justify-content-between p-0 m-0">
          <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            size="md"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Finish
          </Button>
        </Row>
      </Form>
    </Card>
  );
});

export default Organisation;
