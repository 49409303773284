import { memo, useState, useEffect, Fragment } from "react";
import { Button, Container, FormGroup, Input, Label, Table } from "reactstrap";
import FooterTransparentEmployer from "components/Footers/FooterTransparentEmployer";
import classes from "./styles.module.scss";
import clsx from "clsx";
import CustomInput from "components/Common/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AccountantNavbar from "components/Navbars/Employer/AccountantNavbar";
import { AccountantService } from "services/Employer/Accountant/Accountant";
import {
  setErrorMess,
  setLoading,
  setSuccessMess,
} from "redux/reducers/Status/actionTypes";
import { ReducerType } from "redux/reducers";
import CustomSwitch from "components/Common/CustomSwitch";

interface Props {}

const Accountant = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);

  const [timesheetList, setTimesheetList] = useState([]);
  const [cSelected, setCSelected] = useState([]);
  const [valueOfSearchInput, setValueOfSearchInput] = useState("");
  const [increasingFilterDate, setIncreasingFilterDate] = useState(true);
  const [filterNotPaid, setFilterNotPaid] = useState(false);

  useEffect(() => {
    AccountantService.getAllTimesheets().then((res) => {
      setCSelected(
        res
          .filter((item) => item.paid)
          .map((item) => ({
            id: item?.id,
            xeroBillId: item?.xeroBillId,
          }))
      );
      setTimesheetList(res.filter((item) => item.approved));
    });
  }, [dispatch]);

  useEffect(() => {
    const newList = [...timesheetList];
    if (increasingFilterDate && newList?.length) {
      newList.sort((a: any, b: any) => {
        const firstTime = new Date(b.weekEnding).getTime();
        const secondTime = new Date(a.weekEnding).getTime();
        return firstTime - secondTime;
      });
    } else if (!increasingFilterDate && newList?.length) {
      newList.sort((a: any, b: any) => {
        const firstTime = new Date(b.weekEnding).getTime();
        const secondTime = new Date(a.weekEnding).getTime();
        return -firstTime + secondTime;
      });
    }
    setTimesheetList(newList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [increasingFilterDate]);

  const handleCheckBoxChange = (id, xeroBillId) => {
    const newList = [...cSelected];
    const index = newList.findIndex((item) => item?.id === id);
    if (index === -1) {
      newList.push({
        id: id,
        xeroBillId: xeroBillId,
      });
      setCSelected(newList);
    } else {
      newList.splice(index, 1);
      setCSelected(newList);
    }
  };

  const handleSearch = () => {
    dispatch(setLoading(true));
    AccountantService.searchTimesheet(valueOfSearchInput)
      .then((res) => {
        setTimesheetList(res.filter((item) => item.approved));
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleClickFilterDate = () => {
    setIncreasingFilterDate(!increasingFilterDate);
  };

  const handleSubmit = async () => {
    const currentPaid = timesheetList
      .filter((item) => item.paid)
      .map((item) => ({
        id: item?.id,
        xeroBillId: item?.xeroBillId,
      }));
    const paid = cSelected.filter(
      (item) => currentPaid.findIndex((p) => p?.id === item?.id) === -1
    );
    const unpaid = currentPaid.filter(
      (item) => cSelected.findIndex((cs) => cs?.id === item?.id) === -1
    );
    const paidSuccess = [];
    const unpaidSuccess = [];

    dispatch(setLoading(true));
    dispatch(setSuccessMess("Updating, please wait..."));
    if (paid.length) {
      await Promise.all(
        paid.map(
          async (item) =>
            await AccountantService.paidTimesheet(item?.id, new Date()).then(
              async () => {
                if (item?.xeroBillId) {
                  await AccountantService.getXEROInvoicesByInvoiceId(
                    user?.id,
                    item?.xeroBillId
                  ).then(async (invoice) => {
                    await AccountantService.paidXEROBill(user?.id, {
                      date: moment().format("YYYY-MM-DD"),
                      invoiceID: item?.xeroBillId,
                      accountCode: 970,
                      amount: invoice?.Invoices[0]?.Total,
                    });
                  });
                }
                paidSuccess.push(item?.id);
              }
            )
        )
      )
        .then(() => dispatch(setSuccessMess("Saved")))
        .catch((err) => dispatch(setErrorMess(err)));
    }
    if (unpaid.length) {
      await Promise.all(
        unpaid.map(
          async (item) =>
            await AccountantService.unpaidTimesheet(item?.id).then(async () => {
              if (item?.xeroBillId) {
                await AccountantService.getXEROInvoicesByInvoiceId(
                  user?.id,
                  item?.xeroBillId
                ).then(async (invoice) => {
                  await AccountantService.unpaidXEROBill(
                    user?.id,
                    invoice?.Invoices[0]?.Payments[0]?.PaymentID
                  );
                });
              }
              unpaidSuccess.push(item?.id);
            })
        )
      )
        .then(() => dispatch(setSuccessMess("Saved")))
        .catch((err) => dispatch(setErrorMess(err)));
    }
    const newList = [...timesheetList];
    newList.forEach((item, index, array) => {
      if (paidSuccess.includes(item.id)) {
        array[index] = { ...item, paid: true };
      }
      if (unpaidSuccess.includes(item.id)) {
        array[index] = { ...item, paid: false };
      }
    });
    setTimesheetList(newList);
    dispatch(setLoading(false));
  };

  return (
    <Fragment>
      <AccountantNavbar />
      <div
        className={clsx("page-header header-filter", classes.pageHeader)}
        filter-color="yellow"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project19.jpg") + ")",
          }}
        />
        <div className={clsx("content mb-0", classes.content)}>
          <Container className={classes.container}>
            <p className={classes.title}>Approved Timesheets</p>
            <div className={classes.tableHeaderContainer}>
              <CustomInput
                endIcon={
                  <i
                    className={clsx("fa fa-search", classes.searchIcon)}
                    onClick={handleSearch}
                  />
                }
                placeholder="Search..."
                className={classes.customInput}
                onChange={(e) => {
                  setValueOfSearchInput(e.target.value);
                }}
              />
              <Button className="m-0" color="primary" onClick={handleSubmit}>
                Save
              </Button>
            </div>
            <div className={classes.customSwitchWrapper}>
              <p>Not paid</p>
              <CustomSwitch
                value={filterNotPaid}
                onChange={() => setFilterNotPaid(!filterNotPaid)}
              />
            </div>

            <div className={classes.tableContainer}>
              <Table responsive className={classes.table}>
                <thead>
                  <tr>
                    <th className="text-left pl-3">Paid</th>
                    <th className="text-center">Name</th>
                    <th className={clsx("text-center", classes.hideOnMobile)}>
                      Title
                    </th>
                    <th className={clsx("text-center", classes.hideOnMobile)}>
                      Hours
                    </th>
                    <th>Rate</th>
                    <th>
                      <div
                        className={classes.tableTitle}
                        onClick={handleClickFilterDate}
                      >
                        End date
                        {increasingFilterDate ? (
                          <i className="now-ui-icons arrows-1_minimal-up" />
                        ) : (
                          <i className="now-ui-icons arrows-1_minimal-down" />
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody className={classes.desktop}>
                  {timesheetList
                    ?.filter((item) => (filterNotPaid ? !item?.paid : item))
                    .map((item, index) => (
                      <tr key={index}>
                        <td className="text-center pl-4">
                          <FormGroup check>
                            <Label check>
                              <Input
                                key={item?.id}
                                type="checkbox"
                                defaultChecked={item?.paid}
                                onChange={() => {
                                  handleCheckBoxChange(
                                    item?.id,
                                    item?.xeroBillId
                                  );
                                }}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                        <td className="text-center">
                          {item?.contractorFirstName} {item?.contractorLastName}
                        </td>
                        <td className="text-center">{item?.contractorTitle}</td>
                        <td className="text-center">{item?.hours}</td>
                        <td className="text-center">{item?.rateOffered}</td>
                        <td className="text-center">
                          {moment(item?.weekEnding).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    ))}
                </tbody>

                <tbody className={classes.mobile}>
                  {timesheetList
                    ?.filter((item) => (filterNotPaid ? !item?.paid : item))
                    .map((item, index) => (
                      <tr key={index}>
                        <td className="text-center pl-4">
                          <FormGroup check>
                            <Label check>
                              <Input
                                key={item?.id}
                                type="checkbox"
                                defaultChecked={item?.paid}
                                onChange={() => {
                                  handleCheckBoxChange(
                                    item?.id,
                                    item?.xeroBillId
                                  );
                                }}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </td>
                        <td className="text-center">
                          {item?.contractorFirstName} {item?.contractorLastName}
                        </td>
                        <td className="text-center">{item?.rateOffered}</td>
                        <td className="text-center">
                          {moment(item?.weekEnding).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Container>
        </div>
        {/* <FooterTransparentEmployer /> */}
      </div>
    </Fragment>
  );
});

export default Accountant;
