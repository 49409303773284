import { memo, useMemo, useEffect } from "react";
import { Form, Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import classes from "./styles.module.scss";
import CustomSlider from "components/Common/CustomSlider";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    updateFilterMinRate?: (value) => void;
    updateFilterMaxRate?: (value) => void;
    rateType: string;
    defaultFilterRate: {
        minRateFilter: number;
        maxRateFilter: number;
    };
}

const RateFilter = memo(({ isOpen, onClose, updateFilterMinRate, updateFilterMaxRate, rateType, defaultFilterRate }: Props) => {

    const schema = useMemo(() => {
        return yup.object().shape({
            rate: yup.number().required("This field is required")
        })
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        resetField,
        setValue,
        watch,
        control,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    useEffect(() => {
        if (rateType === 'min') {
            setValue('rate', defaultFilterRate?.minRateFilter || 30)
        }
        else {
            setValue('rate', defaultFilterRate?.maxRateFilter || 30)
        }
    }, [isOpen])

    const onSubmit = (data) => {
        if (rateType === 'min') {
            updateFilterMinRate(data.rate);
        }
        else {
            updateFilterMaxRate(data.rate);
        }
        onClose();
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} className={classes.termModal}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader className={classes.modalHeader}>
                        Filter {rateType === "min" ? "min" : "preference"} rate:
                    </ModalHeader>
                    <CustomSlider
                        name="rate"
                        min={30}
                        max={300}
                        control={control}
                        className={classes.sliderRate}
                        errorMessage={errors.rate?.message}
                    />
                    <ModalFooter className="d-flex justify-content-between">
                        <Button color="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button color="primary">
                            Done
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
});

export default RateFilter;
