import {
  EMPLOYMENT_TYPE,
  GST_STARTING_MONTH,
  JOB_TYPE,
  USER_STATUS,
  WORK_POLICY,
} from "config/constants";
import { EGstTypes, GstTypes } from "models/Contractor/NewMember/Tax";

export class UtilContractor {
  static getCurrentTravellingRadius(radius) {
    switch (radius) {
      case 5:
        return { id: 1, name: "5km", value: 5 };
      case 10:
        return { id: 2, name: "10km", value: 10 };
      case 20:
        return { id: 3, name: "20km", value: 20 };
      case 40:
        return { id: 4, name: "40km", value: 40 };
      case 100:
        return { id: 5, name: "100km", value: 100 };
      case 200:
        return { id: 6, name: "200km", value: 200 };
      case 500:
        return { id: 7, name: "500km", value: 500 };
      default:
        return null;
    }
  }

  static convertGstType(value) {
    switch (value) {
      case EGstTypes.INVOICE_BASIS:
        return GstTypes[0];
      case EGstTypes.CASH_BASIS:
        return GstTypes[1];
      default:
        return null;
    }
  }

  static getCurrentAvailableType(availableDate, noticePeriod) {
    if (availableDate) {
      return { id: 0, name: "On a specific date" };
    } else if (noticePeriod === 2) {
      return { id: 2, name: "Two weeks notice" };
    } else if (noticePeriod === 4) {
      return { id: 4, name: "Four weeks notice" };
    } else {
      return null;
    }
  }

  static getCurrentWorkPolicy(workPolicy) {
    switch (workPolicy) {
      case WORK_POLICY.OFFICE:
        return { id: 1, name: "Office", value: WORK_POLICY.OFFICE };
      case WORK_POLICY.MIXED:
        return { id: 2, name: "Mixed", value: WORK_POLICY.MIXED };
      case WORK_POLICY.WORK_FROM_HOME:
        return {
          id: 3,
          name: "Work From Home",
          value: WORK_POLICY.WORK_FROM_HOME,
        };
      case WORK_POLICY.ALL:
        return {
          id: 4,
          name: "All",
          value: WORK_POLICY.ALL,
        };
      default:
        return null;
    }
  }

  // status
  static getCurrentUserStatus(userStatus) {
    switch (userStatus) {
      case USER_STATUS.AVAILABLE:
        return { id: 1, name: "Available", value: USER_STATUS.AVAILABLE };
      case USER_STATUS.INTERVIEW:
        return { id: 2, name: "Interview", value: USER_STATUS.INTERVIEW };
      // case USER_STATUS.CONTRACT:
      //   return {
      //     id: 3,
      //     name: "Contract",
      //     value: USER_STATUS.CONTRACT,
      //   };
        case USER_STATUS.ENGAGED:
          return {
            id: 4,
            name: "Engaged",
            value: USER_STATUS.ENGAGED,
          };
          case USER_STATUS.DEACTIVATED:
            return {
              id: 5,
              name: "Deactivated",
              value: USER_STATUS.DEACTIVATED,
            };
      default:
        return null;
    }
  }

  static getCurrentEmploymentType(employmentType) {
    switch (employmentType) {
      case EMPLOYMENT_TYPE.PERMANENT:
        return { id: 1, name: "Permanent", value: EMPLOYMENT_TYPE.PERMANENT };
      default:
        return { id: 2, name: "Contract", value: EMPLOYMENT_TYPE.CONTRACT };
    }
  }

  static getCurrentHoldingTax(holdingTax) {
    switch (holdingTax) {
      case "0":
        return { id: 1, name: "0%", value: 0 };
      case "5":
        return { id: 2, name: "5%", value: 5 };
      case "10":
        return { id: 3, name: "10%", value: 10 };
      case "15":
        return { id: 4, name: "15%", value: 15 };
      case "20":
        return { id: 5, name: "20%", value: 20 };
      case "25":
        return { id: 6, name: "25%", value: 25 };
      case "30":
        return { id: 7, name: "30%", value: 30 };
      case "35":
        return { id: 9, name: "35%", value: 35 };
      case "40":
        return { id: 10, name: "40%", value: 40 };
      case "45":
        return { id: 11, name: "45%", value: 45 };
      default:
        return { id: 8, name: "33%", value: 33 };
    }
  }

  static getCurrentTaxCode(TaxCode) {
    switch (TaxCode) {
      case "M":
        return { id: 1, name: "M", value: "M" };
      case "M SL":
        return { id: 2, name: "M SL", value: "M SL" };
      case "ME":
        return { id: 3, name: "ME", value: "ME" };
      case "ME SL":
        return { id: 4, name: "ME SL", value: "ME SL" };
      case "S":
        return { id: 5, name: "S", value: "S" };
      case "S SL":
        return { id: 6, name: "S SL", value: "S SL" };
      case "SH":
        return { id: 7, name: "SH", value: "SH" };
      case "SH SL":
        return { id: 8, name: "SH SL", value: "SH SL" };
      case "ST":
        return { id: 9, name: "ST", value: "ST" };
      case "ST SL":
        return { id: 10, name: "ST SL", value: "ST SL" };
      case "SB":
        return { id: 11, name: "SB", value: "SB" };
      case "SB SL":
        return { id: 12, name: "SB SL", value: "SB SL" };
      case "WT":
        return { id: 13, name: "WT", value: "WT" };
      case "CAE":
        return { id: 14, name: "CAE", value: "CAE" };
      case "EDW":
        return { id: 15, name: "EDW", value: "EDW" };
      case "NSW":
        return { id: 16, name: "NSW", value: "NSW" };
      case "ND":
        return { id: 17, name: "ND", value: "ND" };
      default:
        return null;
    }
  }

  static getCurrentGstFilingFrequency(gstFilingFrequency) {
    switch (gstFilingFrequency) {
      case GST_STARTING_MONTH.MONTHLY:
        return { id: 1, name: "Monthly", value: GST_STARTING_MONTH.MONTHLY };
      case GST_STARTING_MONTH.TWO_MONTHLY:
        return {
          id: 2,
          name: "Two-Monthly",
          value: GST_STARTING_MONTH.TWO_MONTHLY,
        };
      case GST_STARTING_MONTH.SIX_MONTHLY:
        return {
          id: 3,
          name: "Six-Month",
          value: GST_STARTING_MONTH.SIX_MONTHLY,
        };
      default:
        return null;
    }
  }

  static getCurrentGstStartingMonth(gstStartingMonth) {
    switch (gstStartingMonth) {
      case 1:
        return { id: 1, name: "January", value: 1 };
      case 2:
        return { id: 2, name: "February", value: 2 };
      case 3:
        return { id: 3, name: "March", value: 3 };
      case 4:
        return { id: 4, name: "April", value: 4 };
      case 5:
        return { id: 5, name: "May", value: 5 };
      case 6:
        return { id: 6, name: "June", value: 6 };
      case 7:
        return { id: 7, name: "July", value: 7 };
      case 8:
        return { id: 8, name: "August", value: 8 };
      case 9:
        return { id: 9, name: "September", value: 9 };
      case 10:
        return { id: 10, name: "October", value: 10 };
      case 11:
        return { id: 11, name: "November", value: 11 };
      case 12:
        return { id: 12, name: "December", value: 12 };
      default:
        return null;
    }
  }

  static getCurrenJobType(jobType) {
    switch (jobType) {
      case JOB_TYPE.FIXED:
        return { id: 1, name: "Fixed", value: JOB_TYPE.FIXED };
      case JOB_TYPE.PERMANENT:
        return { id: 1, name: "Permanent", value: JOB_TYPE.PERMANENT };
      case JOB_TYPE.CONTRACT:
        return { id: 1, name: "Contract", value: JOB_TYPE.CONTRACT };
      default:
        return null;
    }
  }

  static getLevelExperience(yearExperience) {
    if (yearExperience <= 3) {
      return "Junior";
    } else if (yearExperience > 3 && yearExperience <= 6) {
      return "Intermediate";
    } else {
      return "Senior";
    }
  }
}
