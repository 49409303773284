import { Fragment, memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Button,
} from "reactstrap";
import Logo from "assets/img/logo.png";
import routes, { routesOutside } from "routers/routes";
import classes from "./styles.module.scss";
import { EMPLOYER_PAGE, USER_TYPE } from "config/constants";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { setUserLogoutReducer } from "redux/reducers/User/actionTypes";
import authFactory from "services/authService/AuthFactory";
import { ReducerType } from "redux/reducers";
import clsx from "clsx";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import {
  setAllChatsReducer,
  setAllTimesheetsReducer,
  setJobListReducer,
  setJobOffersReducer,
  setSettingReducer,
  setTalentReducer,
} from "redux/reducers/Employer/actionTypes";
import { useWebSocket } from 'services/Message/User/WebSocketProvider.js';

interface Props {
  name: string;
  to: string;
  employerPage?: EMPLOYER_PAGE;
}

const DropdownScrollNavbar = memo(({ name, to, employerPage }: Props) => {
  const { user } = useSelector((state: ReducerType) => state.user);
  const { timesheets } = useSelector((state: ReducerType) => state.employer);
  const { isHaveNewMessage } = useSelector(
    (state: ReducerType) => state.status
  );
  const dispatch = useDispatch();

  const { hasUnreadMessages, unreadMessages, clearHasUnreadMessages } = useWebSocket();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [navbarColor, setNavbarColor] = useState(" navbar-transparent");
  const [numberOfPendingTimesheets, setNumberOfPendingTimesheets] = useState(0);

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 10 ||
        document.body.scrollTop > 10
      ) {
        setNavbarColor("");
      } else {
        setNavbarColor(" navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return () => {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  useEffect(() => {
    if (timesheets) {
      const pendingTimesheets = timesheets.filter((item) => item?.pending);
      setNumberOfPendingTimesheets(pendingTimesheets.length);
    }
  }, [dispatch, user, timesheets]);

  const handleCollapseNavbar = () => {
    if (window.innerWidth <= 991) {
        const navOpenClass = classes['nav-open']; // Accessing class from the module
        document.documentElement.classList.toggle(navOpenClass);
        setCollapseOpen(!collapseOpen);
    }
};

  const handleSignOut = async () => {
    dispatch(setLoading(true));
    await authFactory
      .generate()
      .logout()
      .then(() => {
        dispatch(setUserLogoutReducer());
        dispatch(setSettingReducer(null));
        dispatch(setJobListReducer([]));
        dispatch(setTalentReducer([]));
        dispatch(setJobOffersReducer([]));
        dispatch(setAllTimesheetsReducer([]));
        dispatch(setAllChatsReducer(null));
        dispatch(push(routes.employer.login));
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <Fragment>
      {collapseOpen && <div id="bodyClick" onClick={handleCollapseNavbar} />}
      <Navbar
        className={clsx("fixed-top", navbarColor && classes.bgBlack, classes.navbar)}
        color="white"
        expand="lg"
      >
        <Container>
          <div className={classes['navbar-translate']}>
            <NavbarBrand 
              to="/" tag={Link} 
              id="navbar-brand" 
              className={classes['navbar-brand']}>
              <img src={Logo} alt="Necta Logo" width={50} className="mr-3" />
              <span className={classes.logoWord}>Necta</span>
            </NavbarBrand>
            <button
              onClick={handleCollapseNavbar}
              aria-expanded={collapseOpen}
              className={clsx(classes["navbar-toggler"])}
            >
              <span className={clsx(classes["navbar-toggler-bar"], classes["top-bar"])}></span>
              <span className={clsx(classes["navbar-toggler-bar"], classes["middle-bar"])}></span>
              <span className={clsx(classes["navbar-toggler-bar"], classes["bottom-bar"])}></span>
              <div
                className={
                  numberOfPendingTimesheets > 0 || isHaveNewMessage
                    ? clsx(classes.navbarMenuDot)
                    : clsx(classes.navbarMenuDot, "d-none")
                }
              ></div>
            </button>
          </div>
          <Collapse
            isOpen={collapseOpen}
            navbar
            className={classes['navbar-collapse']}
          >
            <Nav className={clsx("ml-auto", classes['navbar-nav'])} id="ceva" navbar>
              {employerPage === EMPLOYER_PAGE.LISTING && user?.role === 7 && (
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    color="default"
                    id="navbarDropdownMenuLink"
                    nav
                    // className={classes['navbar-nav']}
                    to={routes.employer.listing.talent}
                    tag={Link}
                    onClick={handleCollapseNavbar}
                  >
                    <i
                      aria-hidden={true}
                      // className="now-ui-icons business_badge"
                      className={clsx("now-ui-icons business_badge", classes['icon-badge'], classes['now-ui-icons'])}
                    />
                    <p>Talent</p>
                  </DropdownToggle>
                </UncontrolledDropdown>
              )}

              <UncontrolledDropdown nav>
              {user && (
                <DropdownToggle
                  color="default"
                  id="navbarDropdownMenuLink"
                  nav
                  to={
                    employerPage === EMPLOYER_PAGE.MANAGE
                      ? routes.employer.manage.root
                      : routes.employer.listing.dashboard
                  }
                  tag={Link}
                  onClick={handleCollapseNavbar}
                >
                  <i
                    aria-hidden={true}
                    className={clsx("now-ui-icons business_briefcase-24", classes['icon-badge'], classes['now-ui-icons'])}
                  />
                  <p>Dashboard</p>
                </DropdownToggle>
              )}
              </UncontrolledDropdown>

              {employerPage === EMPLOYER_PAGE.LISTING && (
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    color="default"
                    id="navbarDropdownMenuLink"
                    nav
                    to={routes.employer.listing.messenger}
                    tag={Link}
                    onClick={() => {
                      handleCollapseNavbar();
                      clearHasUnreadMessages();
                    }}
                  >
                    <i
                      aria-hidden={true}
                      className={clsx("now-ui-icons ui-2_chat-round", classes['icon-badge'], classes['now-ui-icons'])}

                    >
                      
                      <div
                        className={
                          unreadMessages > 0
                            ? clsx(classes.offersDot)
                            : clsx(classes.offersDot, "d-none")
                        }
                      ></div>
                    </i>
                    <p>Messages</p>
                  </DropdownToggle>
                </UncontrolledDropdown>
              )}

              <UncontrolledDropdown nav>
              {user && (
                <DropdownToggle
                  color="default"
                  id="navbarDropdownMenuLink"
                  nav
                  to={routes.employer.listing.settings}
                  tag={Link}
                  onClick={handleCollapseNavbar}
                >
                  <i 
                                        className={clsx("now-ui-icons ui-1_settings-gear-63", classes['icon-badge'], classes['now-ui-icons'])}/>
                  <p>Settings</p>
                </DropdownToggle>
              )}
              </UncontrolledDropdown>

              {employerPage === EMPLOYER_PAGE.MANAGE && (
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    color="default"
                    id="navbarDropdownMenuLink"
                    nav
                    to={routes.employer.manage.timesheets}
                    tag={Link}
                    onClick={handleCollapseNavbar}
                  >
                    <i
                      aria-hidden={true}
                      className={clsx("now-ui-icons ui-1_simple-add", classes['icon-badge'], classes['now-ui-icons'])}
                    >
                      {numberOfPendingTimesheets > 0 && (
                        <div
                          id="messenger-employer-dot"
                          className={clsx(classes.timesheetNotify)}
                        >
                          <span className={classes.numberOfPendingTimesheets}>
                            {numberOfPendingTimesheets}
                          </span>
                        </div>
                      )}
                    </i>
                    <p>Timesheets</p>
                  </DropdownToggle>
                </UncontrolledDropdown>
              )}
              {employerPage === EMPLOYER_PAGE.MANAGE && (
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    color="default" 
                    id="navbarDropdownMenuLink"
                    nav
                    to={routes.employer.manage.billing}
                    tag={Link}
                    onClick={handleCollapseNavbar}
                  >
                    <i
                      aria-hidden={true}
                      className={clsx("now-ui-icons files_single-copy-04", classes['icon-badge'], classes['now-ui-icons'])}
                    ></i>
                    <p>Billing</p>
                  </DropdownToggle>
                </UncontrolledDropdown>
              )}

              <UncontrolledDropdown nav className={classes.other}>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                  tag="a"
                  href={
                    window?.location?.pathname?.split("/")[1] ===
                    USER_TYPE.CONTRACTOR
                      ? routesOutside.contractorBlog
                      : routesOutside.employerBlog
                  }
                  onClick={handleCollapseNavbar}
                >
                  <i
                    aria-hidden={true}
                    className={clsx("now-ui-icons text_align-left", classes['icon-badge'], classes['now-ui-icons'])}
                  />
                  <p>Blog</p>
                </DropdownToggle>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav className={classes.other}>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                  tag="a"
                  href={routesOutside.aboutUs}
                  onClick={handleCollapseNavbar}
                >
                  <i
                    aria-hidden={true}
                    className={clsx("now-ui-icons business_bulb-63", classes['icon-badge'], classes['now-ui-icons'])}
                  />
                  <p>About Us</p>
                </DropdownToggle>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav className={classes.other}>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                  tag="a"
                  href={routesOutside.policy}
                  onClick={handleCollapseNavbar}
                >
                  <i
                    aria-hidden={true}
                    className={clsx("now-ui-icons objects_globe", classes['icon-badge'], classes['now-ui-icons'])}
                  />
                  <p>Policies</p>
                </DropdownToggle>
              </UncontrolledDropdown>

              {user && (
                <UncontrolledDropdown nav className={classes.other}>
                  <DropdownToggle
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                    onClick={handleSignOut}
                  >
                    <i
                      aria-hidden={true}
                      className={clsx("now-ui-icons media-1_button-power", classes['icon-badge'], classes['now-ui-icons'])}
                    />
                    <p>Sign Out</p>
                  </DropdownToggle>
                </UncontrolledDropdown>
              )}

              {user && (
                <>
                  <NavItem className={classes.signOutDesktop}>
                    <Button
                      className={clsx("nav-link btn-default ml-3", classes['nav-link'], classes['btn'])}
                      color="secondary"
                      onClick={handleSignOut}
                    >
                      <p>Sign Out</p>
                    </Button>
                  </NavItem>
                </>
              )}
              <NavItem>
                <Button
                  className={clsx("nav-link btn-default ml-3", classes['nav-link'], classes['btn'])}
                  color="primary"
                  tag={Link}
                  to={to}
                  onClick={handleCollapseNavbar}
                >
                  <p>{name}</p>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
});

export default DropdownScrollNavbar;
