import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createRootReducer from 'redux/reducers';
import middlewares from 'redux/middlewares';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { rootSaga } from 'redux/sagas';

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root', 
  storage, //AsyncStorage for react native
  whitelist: [ ]// if you only want this to happen to one or more areas
  //blacklist: ['messenger'], will need this to be blacklisted due to websockets
};


const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createConfigureStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      return [...getDefaultMiddleware({ thunk: false }), sagaMiddleware, ...middlewares(history)]
    },
    devTools: process.env.NODE_ENV !== 'production'
  });

  //const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store };
  // return { store, persistor };
};