import produce from "immer";
import * as types from "./actionTypes";
import { bool } from "aws-sdk/clients/signer";

interface CandidatesOfJob {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  city: string;
  experienceYears: number;
  lastCompany: string;
  lastTitle: string;
  summary: string;
  scores: string;
  workPolicy: string;
  availableDate: string;
}

export interface IAllCandidatesList {
  id: string;
  jobId: number;
  jobTitle: string;
  firstName: string;
  lastName: string;
  title: string;
  city: string;
  countryCode: number;
  mobile: number;
  email: string;
  country: string;
  experienceYears: number;
  modified: Date;
  created: Date;
  lastCompany: string;
  lastTitle: string;
  maxSalary: number;
  maxRate: number;
  noticePeriod: number;
  summary: string;
  status: string;
  scores: string;
  workPolicy: string;
  availableDate: string;
  accepted: boolean;
  offered: boolean;
  revoked: boolean;
  rejected: boolean;
  applied: boolean;
  social: string;
  source: string;
}

export interface IJob {
  city: string;
  client: number;
  companyName: string;
  country: string;
  department: number;
  description: string;
  educationField: string;
  educationLevel: string; 
  educationName: string;
  experienceFeild: string;
  experienceYears: number;
  experinceName: string;
  id: number;
  isContract: boolean;
  isFixed: boolean;
  isPermanent: boolean;
  maxRate: number;
  maxSalary: number;
  minRate: number;
  minSalary: number;
  organization: number;
  referenceCode: string;
  region: string;
  responsibilities: string;
  startDate: string | Date;
  technicalSkills: string[];
  title: string;
  workPolicy: string;
  headhunterAmount: number;
  contacted?: number;
  applied?: number;
  declined?: number;
  isArchived: boolean;
  status: string;
  candidates: CandidatesOfJob[];
  headhunter: boolean;
  listings: boolean;
  private: boolean;
  created?: Date;
  template?: string;
  trademe?: boolean;
  dogoodjobs?: boolean;
  seek?: boolean;
  linkedin?: boolean;

}

export interface IDeclinedCandidates {
  employer: number;
  jobId: number;
  contractorIds: number[];
}

export interface ICandidatesList {
  candidatesOfJob: CandidatesOfJob[];
}

export interface ISetting {
  idBasicProfile?: number;
  firstName: string;
  lastName: string;
  title: string;
  workPhone?: string;
  avatar?: File;
  recruitOnBehalf?: boolean;
  workPhoneCountryCode?: string;
  photoUrl?: string;
  headHunter?: boolean;
  idOrganisation?: number;
  name?: string;
  industry?: string;
  numberOfWorkers?: number[];
  mission?: string;
  culture?: string;
  behaviouralSklls?: string[];
  nzbn?: string;
  email?: string;
  phone?: string;
  countryCode?: string;
  uniqueCode?: string;
  value?: any;
  city?: string;
  country?: string;
  region?: string;
  admin?: bool;
  colour?: string;
  paid?: bool; 
  numberOfJobs?: any;
  dateOfRenewal?: Date;
  paymentTerms?: any;
  invoiceEmail?: any;
  paymentReference?: any;
  terms?: bool; 
}

export interface IJobOffers {
  id?: number;
  job?: number;
  contractor: number;
  employer: number;
  offered?: boolean;
  salary?: number;
  revoked?: boolean;
  accepted?: boolean;
  rejected?: boolean;
  startDate?: string;
  endDate?: string;
  rateOffered?: number;
  managerName?: string;
  approvalName?: string;
  contractUrl?: string;
  isContraContractUsed?: boolean;
  purchaseOrderNumber?: string;
  created?: Date;
}

export interface ITimesheet {
  id?: number;
  job?: number;
  contractor: number;
  employer: number;
  approver?: string;
  hours?: number;
  pending?: boolean;
  approved?: boolean;
  declined?: boolean;
  paid?: boolean;
  weekEnding?: string | Date;
  jobOffer?: number;
  rateOffered?: number;
  contractorFirstName?: string;
  contractorLastName?: string;
  contractorTitle?: string;
}

export interface IInterviewOffers {
  id?: number;
  job?: number;
  contractor: number;
  created?: Date;
  employer: number;
  offered?: boolean;
  revoked?: boolean;
  accepted?: boolean;
  rejected?: boolean;
  interviewTime?: string | Date;
  type?: string;
  interviewLink?: string;
  interviewAddress?: string;
  title?: string;
}

export interface ITalent {
  id?: number;
  mobile?: number;
  postalCode?: string;
  region?: string;
  country?: string;
  city?: string;
  countryCode?: string;
  cvUrl?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  minRate?: number;
  maxRate?: number;
  availableDate?: string | Date;
  maxSalary?: number;
  minSalary?: number;
  noticePeriod?: number;
  workPolicy?: string;
  visaType?: string;
  title?: string;
  status?: string;
  radius?: number;
  org?: number;
}

export interface EmployerState {
  setting: ISetting;
  jobOfListing: IJob;
  declinedCandidates: IDeclinedCandidates;
  jobList: IJob[];  
  jobTemplatesList: IJob[]; 
  jobClientList: IJob[]; 
  candidatesList: CandidatesOfJob[];
  jobOffers: IJobOffers[];
  timesheets: ITimesheet[];
  timesheetsApprover: ITimesheet[];
  allChats: any;
  talentsList: ITalent[];
  allCandidatesList: IAllCandidatesList[];
  allClientCandidatesList: IAllCandidatesList[];
  interviewOffers: IInterviewOffers[];
  clientInterviewOffers: IInterviewOffers[];
}

const initial: EmployerState = {
  setting: null,
  jobOfListing: null,
  declinedCandidates: null,
  jobList: [],
  jobTemplatesList: [],
  jobClientList: [],
  candidatesList: [], 
  jobOffers: [],
  allCandidatesList: [],
  allClientCandidatesList: [],
  timesheets: [],
  timesheetsApprover: [],
  allChats: null,
  talentsList: [],
  interviewOffers: [],
  clientInterviewOffers: [],
};

export const employerReducer = (state = initial, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_SETTING_REDUCER:
        draft.setting = action?.data;
        break;
        case types.SET_JOB_OF_LISTING_REDUCER:
          draft.jobOfListing = action?.data;
          const jobIndex = draft.jobList.findIndex(j => j.id === action.data.id);
          if (jobIndex !== -1) {
              draft.jobList[jobIndex] = action.data;
          }
          break;      
        break;
      case types.SET_DECLINED_CANDIDATES_REDUCER:
        draft.declinedCandidates = action?.data;
        break;
      case types.SET_JOB_LIST_REDUCER:
        draft.jobList = action?.data;
        break;
      case types.SET_JOB_TEMPLATES_LIST_REDUCER:
        draft.jobTemplatesList = action?.data;
        break;
      case types.SET_JOB_CLIENT_LIST_REDUCER:
        draft.jobClientList = action?.data;
        break;
      case types.SET_CANDIDATES_LIST_REDUCER:
        draft.candidatesList = action?.data;
        break;
      case types.SET_ALL_CLIENT_CANDIDATES_LIST_REDUCER:
        draft.allClientCandidatesList = action?.data;
        break;
      case types.SET_ALL_CANDIDATES_LIST_REDUCER:
        draft.allCandidatesList = action?.data;
        break;
      case types.SET_JOB_OFFERS_REDUCER:
        draft.jobOffers = action?.data;
        break;
      case types.SET_ALL_TIMESHEETS_EMPLOYER_REDUCER:
        draft.timesheets = action?.data;
        break;
      case types.SET_ALL_TIMESHEETS_APPROVER_REDUCER:
        draft.timesheetsApprover = action?.data;
        break;
      case types.SET_ALL_CHATS_REDUCER:
        draft.allChats = action?.data;
        break;
      case types.SET_TALENTS_REDUCER:
        draft.talentsList = action?.data;
        break;
      case types.SET_ALL_INTERVIEWS_EMPLOYER_REDUCER:
        draft.interviewOffers = action?.data;
        break;
      case types.SET_ALL_CLIENT_INTERVIEWS_EMPLOYER_REDUCER:
        draft.clientInterviewOffers = action?.data;
        break;
      default:
        return state;
    }
  });
