// this is a hook to allow onOpen and onClose based on whats passed to it 

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from '../../redux/store/action';
import { UIState } from '../../redux/store/reducer';
import { isModalOpen } from '../../redux/store/selector';
import { ModalMeta } from '../../typings/modal';

export function useModal(modalFileName: string) {
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  const onOpen = useCallback(
    (meta: ModalMeta) => dispatch(openModal(modalFileName, meta)),
    [modalFileName]
  );
  const onClose = useCallback(() => dispatch(closeModal(modalFileName)), [
    modalFileName
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const isOpen = useSelector<UIState>((state) =>
    isModalOpen(state, modalFileName)
  );

  return {
    isOpen,
    onOpen,
    onClose
  };
}
