import { memo } from "react";
import { Modal, ModalFooter, Button, ModalHeader } from "reactstrap";
import { ITimesheet } from "redux/reducers/Employer";
import classes from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleDecline: (timesheet: any) => void;
  handleApprove: (timesheet: any) => void;
  timesheet: ITimesheet;
}

const PopupHandle = memo(
  ({ isOpen, onClose, handleDecline, handleApprove, timesheet }: Props) => {
    console.log(timesheet);
    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          toggle={onClose}
          className={classes.termModal}
        >
          <ModalHeader toggle={onClose} className="justify-content-center">
            {timesheet?.contractorFirstName} {timesheet?.contractorLastName}
          </ModalHeader>
          <ModalFooter className="d-flex justify-content-around">
            <Button color="danger" onClick={() => handleDecline(timesheet)}>
              Decline
            </Button>
            <Button color="success" onClick={() => handleApprove(timesheet)}>
              Approve
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
);

export default PopupHandle;
