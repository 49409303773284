import React from 'react';

function Check_circle(props) {
	const title = props.title || "check circle";

	return (
		<svg height="12" width="12" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#212121"/>
	</g>
</svg>
	);
};

export default Check_circle;