import { memo, useMemo, useEffect } from "react";
import { Form, Button, Row, CardHeader, Card } from "reactstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { ReducerType } from "redux/reducers";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";
import CustomSelect from "components/Common/CustomSelect";
import { VisaTypes } from "models/Contractor/NewMember/MojCheck";
import { MojCheckService } from "services/Contractor/NewMember/MojCheck";
import { convertVisaType } from "utils/moj";

interface Props {}

interface VisaFormData {
  id?: number;
  visaType: {
    id: number;
    name: string;
    value: string;
  };
}

const Visa = memo((props: Props) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const schema = useMemo(() => {
    return yup.object().shape({
      id: yup.number(),
      visaType: yup.object().typeError("This field is required"),
    });
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<VisaFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (profile?.mojCheck) {
      reset({
        id: profile?.mojCheck?.id,
        visaType: convertVisaType(profile?.mojCheck?.visaType),
      });
    }
  }, [profile, reset]);

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.futureOpportunities));
  };

  const onSubmit = async (data: VisaFormData) => {
    dispatch(setLoading(true));
    if (data?.id) {
      await MojCheckService.putMojCheck(data.id, {
        visaType: data?.visaType.value,
      })
        .then((res) => {
          dispatch(setProfileReducer({ ...profile, mojCheck: res }));
          // dispatch(push(routes.contractor.newMember.testimonials));
          dispatch(push(routes.contractor.newMember.contactProfile));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    } else {
      await MojCheckService.postMojCheck({
        visaType: data?.visaType.value,
      })
        .then((res) => {
          dispatch(setProfileReducer({ ...profile, mojCheck: res }));
          // dispatch(push(routes.contractor.newMember.testimonials));
          dispatch(push(routes.contractor.newMember.contactProfile));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    }
  };

  return (
    <Card className={classes.card}>
      <CardHeader>
        <p className={classes.title}>Work Visa Type</p> 
      </CardHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.content}>
          <CustomSelect
            className={classes.commonSelect}
            placeholder="Set Visa Type"
            name="visaType"
            control={control}
            options={VisaTypes}
            errorMessage={errors?.visaType?.message}
          />
        </div>
        <Row className="justify-content-between p-0 m-0">
          <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            size="md"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Next
          </Button>
        </Row>
      </Form>
    </Card>
  );
});

export default Visa;
