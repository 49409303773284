import { History } from "history";
import Routers from "routers";
import { ConnectedRouter } from "connected-react-router";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { getUser } from "redux/reducers/User/actionTypes";
import AppStatus from "components/Common/AppStatus";
import { useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import {
  // getAllChats as getAllChatsContractor,
  getAllOffers,
  getProfile,
  getAllTimesheets as getAllTimesheetsContractor,
  getAllExpenses,
} from "redux/reducers/Contractor/actionTypes";
import {
  // getAllChats as getAllChatsEmployer,
  getAllTimesheets as getAllTimesheetsEmployer,
  getAllApproverTimesheets,
  getJobList,
  getJobClientList,
  getTalentList,
  getJobOffers,
  getSetting,
  getInterviewOffers,
  getAllCandidateList,
  getAllClientCandidateList,
  getJobTemplatesList
} from "redux/reducers/Employer/actionTypes";
import { USER_TYPE } from "config/constants";
import { WebSocketProvider } from 'services/Message/User/WebSocketProvider.js';
import FacebookLogin from 'components/Social/facebook';
import ReactGa from "react-ga4"

interface AppProps {
  history: History;
  dispatch: Dispatch<AnyAction>;
}

const App = ({ history, dispatch }: AppProps) => {
  ReactGa.initialize("G-SW6MQ9P86V");
  const isLoadingAuth = useSelector(
    (state: ReducerType) => state.status.isLoadingAuth
  );
  const { user } = useSelector((state: ReducerType) => state.user);
  
  
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    //console.log(user?.id)
    if (user?.role !== 9) {
      if (user?.type === USER_TYPE.CONTRACTOR) {
        dispatch(getProfile());
        dispatch(getAllOffers(user?.id));
        dispatch(getAllTimesheetsContractor(user?.id));
        dispatch(getAllExpenses());
        if (user?.chatSecret) {
          //dispatch(getAllChatsContractor(user));
        }
      } else if (user?.type === USER_TYPE.EMPLOYER) {
        dispatch(getJobList());
        dispatch(getJobClientList());
        dispatch(getSetting());
        dispatch(getAllClientCandidateList());
        dispatch(getJobOffers());
        dispatch(getTalentList());
        dispatch(getInterviewOffers());
        dispatch(getJobTemplatesList());
        dispatch(getAllTimesheetsEmployer(user?.id));
        dispatch(getAllApproverTimesheets(user?.id, encodeURIComponent(user?.email)));
        dispatch(getAllCandidateList());
        if (user?.chatSecret) {
          //dispatch(getAllChatsEmployer(user));
        }
      }
    }
  }, [dispatch, user]);
  

  return (
    <ConnectedRouter history={history}>
      <WebSocketProvider isLoggedIn={!isLoadingAuth} userId={user?.id}>
        <AppStatus />
        <FacebookLogin />
        {!isLoadingAuth && <Routers />}
      </WebSocketProvider>
    </ConnectedRouter>
  );
};

export default App;
