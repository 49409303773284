import React, { Fragment, memo, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, Button, CardTitle, CardSubtitle, Row, Col, Badge } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import classes from './styles.module.scss';
import { ReducerType } from 'redux/reducers';
import TerminateContract from "./SeeContract/TerminateContract";
import EditExtendContract from "./SeeContract/EditExtendContract";

interface ContractorProps extends RouteComponentProps {}

const Contractor: React.FC<ContractorProps> = memo((props: ContractorProps) => {
  const dispatch = useDispatch();
  const { jobList, jobOffers, timesheets, timesheetsApprover, allCandidatesList } = useSelector((state: ReducerType) => state.employer);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filter, setFilter] = useState('all');
  const [dropdownOpen, setDropdownOpen] = useState(false);


  const [modalTerminateContract, setModalTerminateContract] = useState({
    isOpenModal: false,
    contract: null,
  });

  const [modalEditExtendContract, setModalEditExtendContract] = useState({
    isOpenModal: false,
    contract: null,
  });

  const handleOpenTerminateContract = (item) => {
    setModalTerminateContract({
      isOpenModal: true,
      contract: item,
    });
  };

  const handleOpenEditExtendContract = (item) => {
    setModalEditExtendContract({
      isOpenModal: true,
      contract: item,
    });
  };

  const handleCloseTerminateContract = () => {
    setModalTerminateContract({
      isOpenModal: false,
      contract: null,
    });
  };

  const handleCloseEditExtendContract = () => {
    setModalEditExtendContract({
      isOpenModal: false,
      contract: null,
    });
  };
  
  useEffect(() => {
    const fetchJobs = async () => {
      if (jobList && jobList.length > 0) {
        const filtered = jobList.map(job => {
          const acceptedOffers = jobOffers.filter(offer => offer.job === job.id && offer.accepted);
          const activeContractors = acceptedOffers.filter(offer => moment().isBetween(moment(offer.startDate), moment(offer.endDate)));
          const pastContractors = acceptedOffers.filter(offer => moment().isAfter(moment(offer.endDate)));

          const jobTimesheets = timesheets.filter(ts => ts.job === job.id);
          const jobTimesheetsApprover = timesheetsApprover.filter(tsa => tsa.job === job.id);
          const allTimesheets = [...jobTimesheets, ...jobTimesheetsApprover];

          if (activeContractors.length > 0 || pastContractors.length > 0) {
            return {
              ...job,
              activeContractors,
              pastContractors,
              timesheets: allTimesheets
            };
          }
          return null;
        }).filter(job => job !== null);

        setFilteredJobs(filtered.sort((a, b) => {
          const aEndDate = a.activeContractors[0]?.endDate || a.pastContractors[0]?.endDate;
          const bEndDate = b.activeContractors[0]?.endDate || b.pastContractors[0]?.endDate;
          return moment(bEndDate).diff(moment(aEndDate)); // Sort by nearest end date first
        }));
      }
    };
    fetchJobs();
  }, [jobList, jobOffers, timesheets, timesheetsApprover]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const getFilteredJobs = () => {
    return filteredJobs.filter(job => {
      if (filter === 'active') {
        return job.activeContractors.length > 0;
      } else if (filter === 'past') {
        return job.pastContractors.length > 0;
      } else {
        return true;
      }
    });
  };

  const JobCard = ({ job }) => {
    let contractors = [];
    if (filter === 'active') {
      contractors = job.activeContractors;
    } else if (filter === 'past') {
      contractors = job.pastContractors;
    } else {
      contractors = [...job.activeContractors, ...job.pastContractors];
    }
  
    return (
      <Card className={classes.jobCard}>
        <CardBody>
          <CardTitle tag="h5">{job.title} | {job.city}, {job.country}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            
          </CardSubtitle>
          {/* <p><strong>Description:</strong> {job.description}</p> */}
          {/* <p><strong>Start Date:</strong> {moment(job.startDate).format('DD/MM/YYYY')}</p>
          <p><strong>End Date:</strong> {moment(job.endDate).format('DD/MM/YYYY')}</p> */}
          {contractors.map((contractor, index) => {
            const candidateDetails = allCandidatesList.find(candidate => candidate.id === contractor.contractor);
            const isPastContractor = moment().isAfter(moment(contractor.endDate));
            const timeLeft = isPastContractor
              ? `Ended ${moment(contractor.endDate).fromNow()}`
              : `Ends in ${moment(contractor.endDate).toNow(true)}`;
            const totalTimesheets = job.timesheets.filter(ts => ts.contractor === contractor.contractor).length;
            const totalCost = job.timesheets
              .filter(ts => ts.contractor === contractor.contractor)
              .reduce((acc, ts) => acc + (parseFloat(ts.hours) * parseFloat(contractor.rateOffered)), 0);
  
            return (
<Card key={index} className={isPastContractor ? classes.pastContractor : ''}>
  <CardBody>
    <Row>
      
      <Col md="10">
      <h4 className={classes.h6}>{candidateDetails?.firstName} {candidateDetails?.lastName}</h4>
        <Row>
          <Col md="6">
            
            <p>
              <strong>Contract Period:</strong> {moment(contractor?.startDate).format('DD/MM/YYYY')} - {moment(contractor?.endDate).format('DD/MM/YYYY')}
            </p>
            <p>
              <strong>Rate:</strong> ${contractor?.rateOffered}/hr
            </p>
            <p>
              <strong>Time Left:</strong> {timeLeft}
            </p>
            <p>
              <strong>Number of Timesheets:</strong> {totalTimesheets}
            </p>
            <p>
              <strong>Total Cost:</strong> ${totalCost?.toFixed(2)}
            </p>
            {candidateDetails && (
              <div>
                <p>
                  <strong>Candidate Details:</strong>
                </p>
                <p>Email: {candidateDetails?.email}</p>
                <p>Phone: {candidateDetails?.mobile}</p>
              </div>
            )}
          </Col>
          <Col md="6">
            <p>
              <strong>Manager's Name:</strong> {contractor?.managerName}
            </p>
            <p>
              <strong>Purchase Order:</strong> {contractor?.purchaseOrderNumber}
            </p>
            <p>
              <strong>Timesheet Approval:</strong> {contractor?.approvalName}
            </p>
            {!isPastContractor && (
                    <div>
                      <Button
                        color="default"
                        type="button"
                        onClick={() => handleOpenTerminateContract(contractor)}
                      >
                        Terminate
                      </Button>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => handleOpenEditExtendContract(contractor)}
                      >
                        Extend/Edit
                      </Button>
                    </div>
                  )}
          </Col>
        </Row>
      </Col>
      <Col md="2">
        <div className="d-flex justify-content-end">
          <Badge color={isPastContractor ? 'danger' : 'primary'}>
            {isPastContractor ? 'Past Contractor' : 'Active Contractor'}
          </Badge>
        </div>
      </Col>
    </Row>
  </CardBody>
</Card>

            );
          })}
        </CardBody>
      </Card>
    );
  };
  

  return (
    <Fragment>
      <Container className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.title}>Contractors</div>
          <p>
            Evaluate all your organisations contractors, extend contracts or give notice in one place.
          </p>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle caret>
              Filter: {filter.charAt(0).toUpperCase() + filter.slice(1)}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleFilterChange('all')}>All</DropdownItem>
              <DropdownItem onClick={() => handleFilterChange('active')}>Active</DropdownItem>
              <DropdownItem onClick={() => handleFilterChange('past')}>Past</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        {getFilteredJobs().map((job) => (
          <JobCard key={job.id} job={job} />
        ))}
      </Container>
      <TerminateContract
        isOpen={modalTerminateContract.isOpenModal}
        onClose={handleCloseTerminateContract}
        contract={modalTerminateContract.contract}
      />

      <EditExtendContract
        isOpen={modalEditExtendContract.isOpenModal}
        onClose={handleCloseEditExtendContract}
        contract={modalEditExtendContract.contract}
      />
    </Fragment>
  );
});

export default Contractor;
