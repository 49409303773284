import { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import routes from "routers/routes";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";

interface FooterMobileProps {
  className?: string;
} 

// eslint-disable-next-line no-empty-pattern
const FooterMobile = memo((props: FooterMobileProps) => {
  const { className } = props;
  const { isOffersStillExist, isHaveNewMessage } = useSelector(
    (state: ReducerType) => state.status
  );

  return (
    <div className={clsx(classes.wrapper, className)}>
      <Container>
        <div className={classes.content}>
          <Button
            className={classes.button}
            color="neutral"
            to={routes.contractor.profile}
            tag={Link}
          >
            <i
              className={clsx(
                "now-ui-icons users_single-02",
                classes.footerIcon
              )}
            />
          </Button>
          <Button
            className={classes.button}
            color="neutral"
            to={routes.contractor.messenger}
            tag={Link}
          >
            <i
              className={clsx(
                "now-ui-icons ui-2_chat-round",
                classes.footerIcon,
                classes.messageIcon
              )}
            >
              <div
                className={
                  isHaveNewMessage
                    ? clsx(classes.messageDot)
                    : clsx(classes.messageDot, "d-none")
                }
              ></div>
            </i>
          </Button>
          <Button
            className={classes.button}
            color="neutral"
            to={routes.contractor.offer}
            tag={Link}
          >
            <i
              className={clsx(
                "now-ui-icons business_briefcase-24",
                classes.footerIcon,
                classes.offersIcon
              )}
            >
              <div
                className={
                  isOffersStillExist
                    ? clsx(classes.offersDot)
                    : clsx(classes.offersDot, "d-none")
                }
              ></div>
            </i>
          </Button>
          <Button
            className={classes.button}
            color="neutral"
            to={routes.contractor.timesheet.root}
            tag={Link}
          >
            <i
              className={clsx(
                "now-ui-icons shopping_credit-card",
                classes.footerIcon
              )}
            />
          </Button>
        </div>
      </Container>
    </div>
  );
});

export default FooterMobile;
