import { Form, Button, Row, CardHeader, Card } from "reactstrap";
import { memo, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const MojCheck = memo(({}: Props) => {
  const dispatch = useDispatch();

  const schema = useMemo(() => {
    return yup.object().shape({
      mojUpload: yup.mixed(),
    });
  }, []);

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.testimonials));
  };

  const onSubmit = (data) => {
    console.log(data, "onSubmit");
    dispatch(push(routes.contractor.profile));
  };

  return (
    <Card className={classes.card}>
      <CardHeader>
        <p className={classes.title}>MOJ Check</p>
        <p className={classes.subTitle}>
          We can upload at any time, it's free but will save you time in the
          future!
        </p>
        <p className={classes.subTitle}>
          If you haven't completed one before, no problem, you can upload later.
          We will send you an email on how to complete it, step by step.
        </p>
      </CardHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.content}>
          <input
            className={classes.uploadFile}
            type="file"
            placeholder="Upload..."
            {...register("mojUpload")}
          />
        </div>
        <Row className="justify-content-between p-0 m-0">
          <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            size="md"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Finish
          </Button>
        </Row>
      </Form>
    </Card>
  );
});
export default MojCheck;
