import { memo, useEffect, useMemo, useState } from "react";
import Logo from "../../../assets/img/logo.png";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import ScrollNavbar from "components/Navbars/Employer/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "./styles.module.scss";
import { useForm } from "react-hook-form";
import CustomInput from "components/Common/CustomInput";
import routes from "routers/routes";
import userFactory from "services/userService/UserFactory";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { ReducerType } from "redux/reducers";
import { setUserVerificationReducer } from "redux/reducers/User/actionTypes";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const ConfirmVerificationCode = memo(({}: Props) => {
  const { userVerification } = useSelector((state: ReducerType) => state.user);
  const [isResendEmail, setIsResendEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const userState = useSelector((state: ReducerType) => state.user);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({ code: yup.string().required("This field is required") });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!userVerification) {
      dispatch(push(routes.contractor.login));
    }
    if (userState?.user) {
      if (userState?.user?.profileCompleted) {
        dispatch(push(routes.employer.listing.dashboard));
      } else {
        dispatch(push(routes.employer.newMember.root));
      }
    }
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendEmail = async () => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .resendConfimationCode(userVerification?.username)
      .then(() => {
        setIsResendEmail(true);
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const handleConfirm = async (data) => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .confirm(userVerification?.username, data?.code)
      .then(() => {
        dispatch(push(routes.employer.login));
        dispatch(setUserVerificationReducer(null));
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <>
      <ScrollNavbar name="Talent" to={routes.contractor.login} />
      <div className="page-header header-filter" filter-color="yellow">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/employer_login.jpg") + ")",
          }}
        ></div>
        <div className={classes.content}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="6" lg="4">
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons media-2_sound-wave"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Connecting the right people</h5>
                    <p className="description">
                      We simplify the finding talent experience. Our software
                      allows you to enter your requirements and automatically
                      recieve offers that match the closest
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons media-1_button-pause"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">
                      End to End Technology Partner
                    </h5>
                    <p className="description">
                      Continuously updating new features to simplify finding
                      talent, managing contracts, approving timesheets and
                      creating a single payroll invoice for all.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons users_single-02"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Simple Pricing</h5>
                    <p className="description">
                      Necta is a membership system, we offer everyone the chance
                      to join. Its free for one active engagement and a simple
                      tier system for increased needs.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto my-auto" md="6" lg="4">
                <Card className="card-signup">
                  <CardBody>
                    <img alt="..." src={Logo} width={150}></img>
                    <CardTitle className="text-center" tag="h4">
                      Confirm your email
                    </CardTitle>
                    <Form
                      action=""
                      className="form"
                      method=""
                      onSubmit={handleSubmit(handleConfirm)}
                    >
                      <CardBody className={classes.cardBody}>
                        <div className={classes.inputCodeContainer}>
                          <p className={classes.text}>
                            {isResendEmail
                              ? "New email has been sent"
                              : "The code has been sent to your email"}
                          </p>
                          <CustomInput
                            placeholder="Verification code"
                            type="text"
                            autoComplete="off"
                            inputRef={register("code")}
                            errorMessage={errors.code?.message}
                          />
                          <p className={classes.text}>
                            <span onClick={handleResendEmail}>
                              Resend email?
                            </span>
                          </p>
                          <span className={`text-danger ${classes.text}`}>
                            {errorMessage}
                          </span>
                        </div>
                        <CardFooter className={classes.cardFooter}>
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                            size="lg"
                          >
                            Submit
                          </Button>
                          <div className={classes.btnHaveAccountContainer}>
                            <Button
                              className={classes.btnHaveAccount}
                              tag={Link}
                              to={routes.contractor.login}
                              color="neutral"
                            >
                              I have an account
                            </Button>
                          </div>
                        </CardFooter>
                      </CardBody>
                      <CardFooter className="text-center"></CardFooter>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
});

export default ConfirmVerificationCode;
