import { Storage } from "aws-amplify";
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

export class ImageService {
  static async postJobDescription(file, key) {
    try {
      const credentials = await Auth.currentCredentials();
      const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        region: 'ap-southeast-2',
        credentials: Auth.essentialCredentials(credentials),
      });

      const params = {
        Bucket: 'jobdescriptionsnecta', // Your S3 bucket name
        Key: key,
        Body: file,
        ContentType: file.type, // Dynamic content type
      };

      const response = await s3.upload(params).promise();
      return response.Key; // Return the S3 key of the uploaded file
    } catch (error) {
      console.error('Error uploading job description:', error);
      throw error;
    }
  }
  static async postImage(image, key) {
    try {
      if (image) {
        const response = await Storage.put(key, image, {
          level: "protected",
          contentType: "image/png",
        });
        return response?.key;
      }
      return "";
    } catch (error) {
      console.log(error);
    }
  }

  static async postCompanyAvatar(image, key, mimeType = 'image/png') {
    try {
      const credentials = await Auth.currentCredentials();
      const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        region: 'ap-southeast-2',
        credentials: Auth.essentialCredentials(credentials),
      });
  
      // Ensure the key ends with .png if mimeType is 'image/png'
      const extension = mimeType.split('/')[1]; // This will be 'png' for 'image/png'
      const formattedKey = key.endsWith(`.${extension}`) ? key : `${key}.${extension}`;
  
      const params = {
        Bucket: 'companyimagesnecta', // Specify your bucket name
        Key: formattedKey,
        Body: image,
        ContentType: mimeType, // Setting the ContentType based on the image's MIME type
      };
  
      const response = await s3.upload(params).promise();
      // console.log("Upload response:", response);
      return response.Key;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  


  
  // static async getCompanyAvatar(keyPrefix) {
  //   try {
  //     const credentials = await Auth.currentCredentials();
  //     const s3 = new AWS.S3({
  //       apiVersion: '2006-03-01',
  //       region: 'ap-southeast-2',
  //       credentials: Auth.essentialCredentials(credentials),
  //     });
  
  //     const params = {
  //       Bucket: 'companyimagesnecta',
  //       Prefix: keyPrefix,
  //     };
  
  //     const data = await s3.listObjectsV2(params).promise();
  
  //     // Check if there are any objects returned
  //     if (data.Contents && data.Contents.length > 0) {
  //       // Get the key of the first object (assuming you only want one object)
  //       const firstObjectKey = data.Contents[0].Key;
        
  //       // Use getObject to retrieve the object based on the key
  //       const objectData = await s3.getObject({ Bucket: params.Bucket, Key: firstObjectKey }).promise();
  
  //       if (objectData.Body instanceof Blob) {
  //         // If the environment is a browser, objectData.Body should be a Blob
  //         return objectData.Body;
  //       } else if (objectData.Body instanceof Uint8Array) {
  //         // Convert Uint8Array to Blob if necessary
  //         return new Blob([objectData.Body], { type: objectData.ContentType });
  //       } else {
  //         // Handle other types as needed, or throw an error if the type is unexpected
  //         throw new Error('Unexpected data type for image content.');
  //       }
  //     } else {
  //       throw new Error('No objects found with the specified key prefix.');
  //     }
  //   } catch (error) {
  //     console.error("Error fetching image Blob:", error);
  //     throw error;
  //   }
  // }
  
  static async getCompanyAvatar(keyPrefix) {
    try {
      const url = `https://companyimagesnecta.s3.ap-southeast-2.amazonaws.com/${encodeURIComponent(keyPrefix)}.png`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error('Error fetching image Blob:', error);
      throw error;
    }
  }
  
  


  static async getImage(key, identityId) {
    if (identityId) {
      try {
        const response = await Storage.get(key, {
          download: true,
          level: "protected",
          identityId: identityId,
        });
        return URL.createObjectURL(response?.Body as any);
      } catch (error) {
        console.log(error, "fail");
      }
    } else {
      try {
        const response = await Storage.get(key, {
          download: true,
          level: "protected",
        });
        return URL.createObjectURL(response?.Body as any);
      } catch (error) {
        console.log(error, "fail");
      }
    }
  }

  static async getImageBlob(key) {
    try {
      const response = await Storage.get(key, {
        download: true,
        level: "protected",
      });
  
      if (response.Body instanceof Blob) { // For browser environments
        return response.Body;
      } else {
        // Additional handling might be necessary for Node.js environments or unexpected response types
        console.error("Unexpected response type for image blob");
      }
    } catch (error) {
      console.error("Error fetching image blob:", error);
      throw error; // Or handle this more gracefully depending on your application's needs
    }
  }
}