import { memo, useEffect, useState } from "react";
import {
  Button,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  Container,
} from "reactstrap";
import classes from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { JobOfferService } from "services/Contractor/Offer/Offer";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { ReducerType } from "redux/reducers";
import TinderCard from "react-tinder-card";
import clsx from "clsx";
import { ImageService } from "services/Image";
import {
  EmailTypes,
  TypesOfInterview,
} from "models/Employer/Listings/Dashboard";
import DeclineReason from "./components/DeclineReason";
import { ListingEmailService } from "services/Employer/Listing/Email";
import { getAllOffers } from "redux/reducers/Contractor/actionTypes";
import { UtilContractor } from "utils/contractor";
import DOMPurify from 'dompurify';

interface InterviewProps { }

// eslint-disable-next-line no-empty-pattern
const Interview = memo((props: InterviewProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { allOffers } = useSelector((state: ReducerType) => state.contractor);

  const [offerList, setOfferList] = useState([]);
  const [countItem, setCountItem] = useState(0);
  const [activeItem, setActiveItem] = useState(offerList[0]);
  const [animating, setAnimating] = useState(false);
  const [tinderList, setTinderList] = useState([]);
  const [declineModal, setDeclineModal] = useState({
    isOpen: false,
    declineItem: null,
    declineType: null,
    index: null,
  });

  const createMarkup = (htmlContent) => {
    if (typeof window === 'undefined') {
      // Fallback or simplified sanitization for SSR
      // For example, escaping the HTML to prevent XSS in SSR or simply return the content as is
      return { __html: htmlContent };
    } else {
      // Ensure DOMPurify is only required in a client-side context
      const DOMPurify = require('dompurify');
      return { __html: DOMPurify.sanitize(htmlContent) };
    }
  };

  const handleProgressOffer = (jobItem) => {
    if (jobItem) {
      dispatch(setLoading(true));
      JobOfferService.acceptInterviewOffer(jobItem.interviewId, {
        job: jobItem.id,
        contractor: user.id,
        employer: jobItem.employer,
        interviewTime: jobItem.interviewTime,
        type: jobItem.interviewType,
      })
        .then(async () => {
          await Promise.all([
            ListingEmailService.acceptOfferEmail(jobItem.interviewId, {
              type: EmailTypes.CONTRACTOR_INTERVIEW_OFFER_ACCEPTED,
            }),
            ListingEmailService.contractorInterviewDetailsEmail(
              jobItem.interviewId,
              {
                type: EmailTypes.INTERVIEW_OFFER_DETAILS_FOR_CONTRACTOR,
              }
            ),
            ListingEmailService.employerInterviewDetailsEmail(
              jobItem.interviewId,
              {
                type: EmailTypes.INTERVIEW_OFFER_DETAILS_FOR_EMPLOYER,
              }
            ),
          ])
            .then(() => {
              dispatch(getAllOffers(user?.id));
            })
            .catch((err) => dispatch(setErrorMess(err)));
        })
        .catch((err) => dispatch(setErrorMess(err)))
        .finally(() => dispatch(setLoading(false)));
    }
  };

  const handleDeclineOffer = (jobItem, index) => {
    setDeclineModal({
      isOpen: true,
      declineItem: jobItem,
      declineType: "desktop",
      index: index,
    });
  };

  const handleDeclineModal = (jobItem, reason) => {
    dispatch(setLoading(true));
    JobOfferService.rejectInterviewOffer(jobItem.interviewId, {
      job: jobItem.id,
      contractor: user.id,
      employer: jobItem.employer,
      interviewTime: jobItem.interviewTime,
      type: jobItem.interviewType,
    })
      .then(async (res) => {
        await JobOfferService.interviewContractorDeclined(res?.id, {
          type: EmailTypes.CONTRACTOR_INTERVIEW_OFFER_DECLINED,
          reason: reason
        })
        dispatch(getAllOffers(user?.id));
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const allOfferJobId = allOffers?.interviewOffers
      .filter((offer) => !offer.rejected && !offer.revoked && !offer.accepted)
      .map((offer) => {
        return {
          job: offer.job,
          interviewId: offer.id,
          employer: offer.employer,
          interviewTime: offer?.interviewTime,
          interviewLink: offer?.interviewLink || null,
          interviewAddress: offer?.interviewAddress || null,
          interviewType: offer?.type || null,
        };
      });

    const tempOfferJob = [];
    allOffers?.jobs.forEach((job) => {
      const foundJobInOffers = allOfferJobId.findIndex(
        (e) => e?.job === job.id
      );
      if (foundJobInOffers !== -1) {
        const employer = allOfferJobId[foundJobInOffers]?.employer;
        const interviewId = allOfferJobId[foundJobInOffers]?.interviewId;
        const interviewTime = allOfferJobId[foundJobInOffers]?.interviewTime;
        const interviewLink = allOfferJobId[foundJobInOffers]?.interviewLink;
        const interviewAddress =
          allOfferJobId[foundJobInOffers]?.interviewAddress;
        const interviewType = allOfferJobId[foundJobInOffers]?.interviewType;

        tempOfferJob.push({
          ...job,
          employer,
          interviewId,
          interviewLink,
          interviewAddress,
          interviewType,
          interviewTime,
          isDisplayed: false,
        });
      }
    });

    const promiseArr = [];
    tempOfferJob?.map((item) => {
      if (item?.employerPhotoUrl) {
        promiseArr.push(
          ImageService.getImage(
            item?.employerPhotoUrl,
            item?.employerIdentityId
          )
        );
      }
      return null;
    });

    Promise.all(promiseArr)
      .then((res) => {
        const offerJobs = tempOfferJob.map((item, index) => ({
          ...item,
          photoURL: res[index],
        }));
        return offerJobs;
      })
      .then((offerJobs) => {
        setOfferList([...offerJobs]);
        setTinderList([...offerJobs].reverse());
        if (offerJobs.length !== 0) {
          setActiveItem(offerJobs[offerJobs.length - 1].id);
        }
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  }, [dispatch, user?.id, allOffers]);

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveItem(newIndex);
  };

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const handleAddStamp = (index, direction) => {
    let tinderCardItem = document.getElementById(`tinder-card-${index}`)
      .firstChild as HTMLElement;
    tinderCardItem.classList.add(classes.currentTinderCard);
    if (direction === "right") {
      tinderCardItem.classList.remove(classes.tinderDeclineCard);
      tinderCardItem.classList.add(classes.tinderAcceptCard);
    } else {
      tinderCardItem.classList.remove(classes.tinderAcceptCard);
      tinderCardItem.classList.add(classes.tinderDeclineCard);
    }
  };

  const handleRemoveStamp = (index) => {
    let tinderCardItem = document.getElementById(`tinder-card-${index}`)
      .firstChild as HTMLElement;
    tinderCardItem.classList.remove(classes.tinderAcceptCard);
    tinderCardItem.classList.remove(classes.tinderDeclineCard);
  };

  const handleRestoreCard = (item, index) => {
    const newList = [...offerList];
    newList[offerList.length - 1 - index].isDisplayed = false;
    setActiveItem(item?.id);
    setOfferList([...newList]);
    setCountItem((prev) => prev - 1);
  };

  const handleSwipe = (item, index, direction) => {
    const newList = [...offerList];
    newList[offerList.length - 1 - index].isDisplayed = true;
    setActiveItem(item?.id);
    setOfferList([...newList]);
    if (direction === "right") {
      JobOfferService.acceptInterviewOffer(item.interviewId, {
        job: item.id,
        contractor: user.id,
        employer: item.employer,
        interviewTime: item.interviewTime,
        type: item.interviewType,
      })
        .then(async () => {
          await Promise.all([
            ListingEmailService.acceptOfferEmail(item.interviewId, {
              type: EmailTypes.CONTRACTOR_INTERVIEW_OFFER_ACCEPTED,
            }),
            ListingEmailService.contractorInterviewDetailsEmail(
              item.interviewId,
              {
                type: EmailTypes.INTERVIEW_OFFER_DETAILS_FOR_CONTRACTOR,
              }
            ),
            ListingEmailService.employerInterviewDetailsEmail(
              item.interviewId,
              {
                type: EmailTypes.INTERVIEW_OFFER_DETAILS_FOR_EMPLOYER,
              }
            ),
          ])
            .then(() => {
              dispatch(getAllOffers(user?.id));
            })
            .catch((err) => dispatch(setErrorMess(err)));
        })
        .catch((err) => dispatch(setErrorMess(err)))
    } else {
      setDeclineModal({
        isOpen: true,
        declineItem: item,
        declineType: "mobile",
        index: index,
      });
    }
  };

  const handleDeclineOfferMobile = (item, reason) => {
    JobOfferService.rejectInterviewOffer(item.interviewId, {
      job: item.id,
      contractor: user.id,
      employer: item.employer,
      interviewTime: item.interviewTime,
      type: item.interviewType,
    })
      .then(async (res) => {
        await JobOfferService.interviewContractorDeclined(res?.id, {
          type: EmailTypes.CONTRACTOR_INTERVIEW_OFFER_DECLINED,
          reason: reason
        })
        dispatch(getAllOffers(user?.id))
      })
      .catch((err) => dispatch(setErrorMess(err)))
  };

  const onCloseDeclineModal = () => {
    setDeclineModal({
      isOpen: false,
      declineItem: null,
      declineType: null,
      index: null,
    });
  };

  return (
    <div className={classes.containerRight}>
      <Container className={classes.containerCarousel}>
        {offerList.length > 0 && countItem < offerList.length ? (
          <Carousel
            activeIndex={countItem}
            className={classes.carousel}
            interval={false}
            slide={false}
            next={() =>
              handleDeclineOffer(
                offerList.findIndex((item) => item?.id === activeItem),
                null
              )
            }
            previous={() =>
              handleProgressOffer(
                offerList.findIndex((item) => item?.id === activeItem)
              )
            }
          >
            <CarouselIndicators
              items={offerList}
              activeIndex={countItem}
              onClickHandler={goToIndex}
            />
            {offerList.map((item, index) => {
                          const sanitizedHtml = DOMPurify.sanitize(item?.description);
                          const shortSummary = sanitizedHtml.replace(/<[^>]*>/g, '');
                          const sanitizedRHtml = DOMPurify.sanitize(item?.responsibilities);
                          const shortResponsibilities = sanitizedRHtml.replace(/<[^>]*>/g, '');
                          
              if (!item?.isDisplayed) {
                return (
                  <CarouselItem
                    className={classes.displayNone}
                    key={index}
                    onExiting={onExiting}
                    onExited={onExited}
                  >
                    <>
                      <p className={classes.titleHeaderRight}>
                        OFFER TO INTERVIEW
                      </p>
                      <div className={classes.headerRight}>
                        <img
                          src={
                            item?.photoURL ||
                            require("assets/img/placeholder.jpg")
                          }
                          alt="Avatar"
                          className={classes.avatar}
                        />
                        <div className={classes.buttonContainer}>
                          <Button
                            color="success"
                            size="lg"
                            onClick={(e) => {
                              e.preventDefault();
                              handleProgressOffer(item);
                            }}
                            className="d-none d-sm-block"
                          >
                            Progress
                          </Button>
                          <Button
                            color="danger"
                            size="lg"
                            onClick={() => handleDeclineOffer(item, index)}
                            className="d-none d-sm-block"
                          >
                            Decline
                          </Button>
                        </div>
                      </div>
                      <div className={classes.descriptionContainer}>
                        <div className={classes.descriptionHeader}>
                          <p className={classes.descriptionTitle}>
                            {"Interview information"}
                          </p>
                        </div>
                        <div className={classes.descriptionContent}>
                          - Time:{" "}
                          {new Date(item?.interviewTime).toLocaleString(
                            "en-GB"
                          )}
                          <br></br>- Type:{" "}
                          {item?.interviewType === TypesOfInterview[0].value
                            ? TypesOfInterview[0].name
                            : TypesOfInterview[1].name}
                          <br></br>
                          {item?.interviewAddress || item?.interviewLink
                            ? `- ${item?.interviewType ===
                              TypesOfInterview[1].value
                              ? item?.interviewLink &&
                              `Link: ${item?.interviewLink}`
                              : item?.interviewAddress &&
                              `${item.interviewAddress}`
                            }`
                            : ""}
                        </div>
                      

                        <div className={classes.descriptionHeader}>
                          <p
                            className={classes.descriptionTitle}
                          >{`${item?.title}   |`}</p>
                          <p className={classes.descriptionSubtitle}>
                              {item?.client || item?.companyName || ""}
                          </p>
                        </div>
                        <div className={classes.descriptionContent}>
                          - Experience years required: {UtilContractor.getLevelExperience(item?.experienceYears)}{" "}
                          <br></br>- Employment type:{" "}
                          {(item?.isContract && "Contract") ||
                            (item?.isFixed && "Fixed") ||
                            (item?.isPermanent && "Permanent")}{" "}
                          <br></br>-{" "}
                          {item?.isContract
                            ? `Hourly rate: $${item?.maxRate}`
                            : `Expected salary: $${item?.maxSalary}`}
                          <br></br>
                          {item?.technicalSkills?.length > 0
                            ? `- Skills required: ${item?.technicalSkills.join(
                              ", "
                            )}\n`
                            : ""}
                        </div>
                      {/* <p className={classes.title}>Job description:</p>
                      <div className={classes.responsibilityContainer}>
                      {item?.description}
                      </div>
                      <p className={classes.title}>Responsibilities:</p>
                      <div className={classes.responsibilityContainer}>
                        {item?.responsibilities}
                      </div> */}
                      </div>

                    </>
                  </CarouselItem>
                );
              } else {
                return (
                  <CarouselItem
                    className={classes.displayNone}
                    key={index}
                  ></CarouselItem>
                );
              }
            })}
          </Carousel>
        ) : (
          <p className={classes.titleHeaderRight}>
            There are no more offers for you at the moment!
          </p>
        )}
      </Container>

      <Container className={classes.containerTinderCard}>
        {offerList.length > 0 && countItem < offerList.length ? (
          tinderList.map((item, index) => {
            if (item?.isDisplayed === false) {
              const sanitizedHtml = DOMPurify.sanitize(item?.description);
              const plainText = sanitizedHtml.replace(/<[^>]*>/g, '');
              let shortSummary = plainText.substring(0, 1000);
              const sanitizedRHtml = DOMPurify.sanitize(item?.responsibilities);
              const plainRText = sanitizedRHtml.replace(/<[^>]*>/g, '');
              let shortResponsibilities = plainRText.substring(0, 1000);
              return (
                <div
                  key={index}
                  id={`tinder-card-${index}`}
                  onTouchEndCapture={() => handleRemoveStamp(index)}
                  onMouseUpCapture={() => handleRemoveStamp(index)}
                >
                  <TinderCard
                    swipeThreshold={180}
                    className={classes.swipe}
                    preventSwipe={["up", "down"]}
                    onCardLeftScreen={(direction) =>
                      handleSwipe(item, index, direction)
                    }
                    onSwipeRequirementFulfilled={(direction) =>
                      handleAddStamp(index, direction)
                    }
                  >
                    <div className={classes.cardContainerWrapper}>
                      <p className={classes.progressWord}>Progress</p>
                      <p className={classes.declineWord}>decline</p>
                      <p className={classes.titleHeaderRight}>
                        OFFER TO INTERVIEW
                      </p>
                      <div className={classes.headerRight}>
                        <img
                          src={item?.photoURL || require("assets/img/ryan.jpg")}
                          alt="Avatar"
                          className={classes.avatar}
                        />
                        <div className={classes.buttonContainer}>
                          <Button
                            color="success"
                            size="lg"
                            onClick={() => handleProgressOffer(item)}
                            className="d-none d-sm-block"
                          >
                            Progress
                          </Button>
                          <Button
                            color="danger"
                            size="lg"
                            onClick={() => handleDeclineOffer(item, index)}
                            className="d-none d-sm-block"
                          >
                            Decline
                          </Button>
                        </div>
                      </div>
                      <div className={classes.descriptionContainer}>
                        <div className={classes.descriptionHeader}>
                          <p className={classes.descriptionTitle}>
                            {"Interview information"}
                          </p>
                        </div>
                        <div className={classes.descriptionContent}>
                          - Time:{" "}
                          {new Date(item?.interviewTime).toLocaleString(
                            "en-GB"
                          )}
                          <br></br>- Type:{" "}
                          {item?.interviewType === TypesOfInterview[0].value
                            ? TypesOfInterview[0].name
                            : TypesOfInterview[1].name}
                          <br></br>
                          {item?.interviewAddress || item?.interviewLink
                            ? `- ${item?.interviewType ===
                              TypesOfInterview[1].value
                              ? item?.interviewLink &&
                              `Link: ${item?.interviewLink}`
                              : item?.interviewAddress &&
                              `${item.interviewAddress}`
                            }`
                            : ""}
                        </div>
                      </div>
                      <div className={classes.descriptionContainer}>
                        <div className={classes.descriptionHeader}>
                          <p
                            className={classes.descriptionTitle}
                          >{`${item?.title}   |`}</p>
                          <p className={classes.descriptionSubtitle}>
                          {item?.client || item?.companyName || ""}
                          </p>
                        </div>
                        <div className={classes.descriptionContent}>
                          - Experience years required: {UtilContractor.getLevelExperience(item?.experienceYears)}{" "}
                          <br></br>- Employment type:{" "}
                          {(item?.isContract && "Contract") ||
                            (item?.isFixed && "Fixed") ||
                            (item?.isPermanent && "Permanent")}{" "}
                          <br></br>-{" "}
                          {item?.isContract
                            ? `Hourly rate: $${item?.maxRate}`
                            : `Expected salary: $${item?.maxSalary}`}
                          <br></br>
                          {item?.technicalSkills?.length > 0
                            ? `- Skills required: ${item?.technicalSkills.join(
                              ", "
                            )}\n`
                            : ""}
                        </div>
                        <p className={classes.title}>Job description:</p>
                      <div className={classes.responsibilityContainer}>
                      {shortSummary}
                      </div>

                      </div>
                      <p className={classes.title}>Responsibilities:</p>
                      <div className={classes.responsibilityContainer}>
                        {shortResponsibilities}
                      </div>
                    </div>
                  </TinderCard>
                  <div className={clsx("d-sm-none", classes.mobileButtons)}>
                    <Button
                      color="danger"
                      className={clsx("rounded-circle", classes.mobileBtn)}
                      onClick={() => handleDeclineOffer(item, index)}
                    >
                      Decline
                    </Button>
                    <Button
                      color="success"
                      className={clsx("rounded-circle", classes.mobileBtn)}
                      onClick={() => handleProgressOffer(item)}
                    >
                      Progress
                    </Button>
                  </div>
                </div>
              );
            } else {
              return (
                <TinderCard
                  className="d-none"
                  key={index}
                  preventSwipe={["up", "down"]}
                  onCardLeftScreen={(direction) =>
                    handleSwipe(item, index, direction)
                  }
                  swipeThreshold={180}
                />
              );
            }
          })
        ) : (
          <p
            className={clsx(classes.titleHeaderRight, classes.noOfferContainer)}
          >
            There are no more offers for you at the moment !
          </p>
        )}
      </Container>
      <DeclineReason
        isOpen={declineModal.isOpen}
        onClose={onCloseDeclineModal}
        declineType={declineModal.declineType}
        declineItem={declineModal.declineItem}
        handleDeclineOfferDesktop={handleDeclineModal}
        handleDeclineOfferMobile={handleDeclineOfferMobile}
        handleRestoreCard={handleRestoreCard}
        index={declineModal.index}
      />
    </div>
  );
});

export default Interview;
