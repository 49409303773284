/*eslint-disable*/
import clsx from "clsx";
import { USER_TYPE } from "config/constants";
import { memo } from "react";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import { ReducerType } from "redux/reducers";
import { routesOutside } from "routers/routes";
import classes from "./styles.module.scss";

const FooterDefault = memo(() => {
  const { user } = useSelector((state: ReducerType) => state.user);

  return (
    <>
      <footer className={clsx("footer footer-default", classes.footer)}>
        <Container>
          <nav>
            <ul>
              <li>
                <a href={routesOutside.aboutUs} target="_blank">
                  About Us
                </a>
              </li>
              <li>
                <a
                  href={
                    window?.location?.pathname?.split("/")[1] ===
                    USER_TYPE.CONTRACTOR
                      ? routesOutside.contractorBlog
                      : routesOutside.employerBlog
                  }
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li>
                <a href={routesOutside.policy} target="_blank">
                  Policies
                </a>
              </li>
            </ul>
          </nav>
          <div className={`copyright ${classes.copyright}`} id="copyright">
            © {new Date().getFullYear()} {" "}
            <a href="https://www.necta.nz" target="_blank">
              Necta
            </a>
          </div>
        </Container>
      </footer>
    </>
  );
});

export default FooterDefault;
