import { API } from "aws-amplify";

export class AccountantService {
  static async getAllTimesheets(): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/timesheets`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async searchTimesheet(keyword): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/timesheets?name=${keyword}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async paidTimesheet(timesheetId, datePaid): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/timesheets/${timesheetId}/paid`;
    const myInit = {
      response: true,
      body: {
        datePaid,
      }
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async unpaidTimesheet(timesheetId): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/timesheets/${timesheetId}/unpaid`;
    const myInit = {
      response: true,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async paidXEROBill(id, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/xero/payments`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  } 
  
  static async unpaidXEROBill(id, paymentId): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/xero/payments/${paymentId}`;
    const myInit = {
      response: true,
      body: {
        status: "DELETED"
      }
    };
    return API.del(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getXEROInvoicesByInvoiceId(id, invoiceId): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/xero/invoices/${invoiceId}`;
    const myInit = {
      response: true
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
