import { API } from "aws-amplify";

export class BasicProfileService {
  static postBasicProfile(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/employerprofile";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static getBasicProfile() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/employerprofile";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
  static putBasicProfile(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employerprofile/${id}`;
    const myInit = {
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putAssistUpdate(id, data) {
    const contractorId = id.contractorId;
    console.log("data", data);
    console.log("id", contractorId);
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/assistupdateprofile/details/${contractorId}`;
    const myInit = {
        response: true,
        headers: {
            'Content-Type': 'application/json',
        },
        body: data
    };
    return API.put(apiName, path, myInit)
        .then((res) => {
            console.log('Response:', res);
            return Promise.resolve(res.data);
        })
        .catch((e) => {
            console.error('Error response:', e);
            return Promise.reject(e?.response?.data);
        });
}
}

