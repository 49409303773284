import { ModalMap } from '../../typings/modal';
import {
  ModalOpenAction,
  ModalCloseAction,
  MODAL_OPEN,
  MODAL_CLOSE,
} from './action';

export const MODALS_DATA_MODEL_SAMPLE: ModalMap = {
  // TestModal: {
  //   id: 'TestModal',
  //   open: true
  // },
  AssignOpportunity: {
    id: 'AssignOpportunity',
    open: false,
  },
  RatesChoice: {
    id: 'RatesChoice',
    open: false,
  },
  NewProject: {
    id: 'NewProject',
    open: false,
  },
  NewClient: {
    id: 'NewClient',
    open: false,
  },
  RatesFees: {
    id: 'RatesFees',
    open: false,
  },
  Shortlist: {
    id: 'Shortlist',
    open: false,
  },
  Evaluation: {
    id: 'Evaluation',
    open: false,
  },
  Payment: {
    id: 'Payment',
    open: false,
  },
  CreateCV: {
    id: 'CreateCV',
    open: false,
  },
  Upgrade: {
    id: 'Upgrade',
    open: false,
  },
};

type ModalAction = ModalOpenAction | ModalCloseAction;

export interface UIState {
  modal: ModalMap;
}

const initialState: UIState = {
  modal: MODALS_DATA_MODEL_SAMPLE,
};

export default function uiReducer(
  state: UIState = initialState,
  action: ModalAction
): UIState {
  switch (action.type) {
    case MODAL_OPEN: {
      const id = action.payload.modalFileName;
      const meta = action.payload.meta;
      return {
        ...state,
        modal: {
          ...state.modal,
          [id]: { id, meta, open: true },
        },
      };
    }

    case MODAL_CLOSE: {
      const id = action.payload.modalFileName;
      return {
        ...state,
        modal: {
          ...state.modal,
          [id]: { id, open: false },
        },
      };
    }

    default:
      return state;
  }
}
