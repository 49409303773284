import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_JOB_OFFERS_REQUEST,
  setJobOffersReducer,
} from "redux/reducers/Employer/actionTypes";
import { JobService } from "services/Employer/Manage/Job";

function* requestGetJobOffers() {
  try {
    yield put(setLoading(true));
    const [jobOffers] = yield all([call(JobService.getAllSentContracts)]);
    yield put(setJobOffersReducer(jobOffers));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getJobOffers() {
  yield takeLatest(GET_JOB_OFFERS_REQUEST, requestGetJobOffers);
}

export default getJobOffers;
