import { memo, useEffect } from "react";

interface Props {
  className?: string;
}

 const ScrollToTop = memo(({ className }: Props) => {

  useEffect(() => {
    document.body.classList.add(className || "content-center");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove(className || "content-center");
      document.body.classList.remove("sidebar-collapse");
    };
    // eslint-disable-next-line
  }, []);

  return null;
})

export default ScrollToTop;