import { API } from "aws-amplify";
import {
  EducationParams,
  DeleteEducation,
} from "models/Contractor/NewMember/Education";

export class EducationService {
  static async postEducation(data: EducationParams[]): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/educations";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getEducation(): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/educations";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putEducation(data: EducationParams[]): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/educations";
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async deleteEducation(data: DeleteEducation): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/educations/delete";
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
