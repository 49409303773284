import { memo, useEffect, useMemo } from 'react';
import { Form, Button, Card, CardBody, Modal } from 'reactstrap';
import classes from './styles.module.scss';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/Common/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from 'redux/reducers';
import clsx from 'clsx';
import { NOTICE_PERIOD, SET_TIME } from 'config/constants';
import CustomSelect from 'components/Common/CustomSelect';
import {
  gstFilingFrequency,
  gstStartingMonth,
  GstTypes,
  TaxCode,
  WithholdingTax,
} from 'models/Contractor/NewMember/Tax';
import CustomRadioButtons from 'components/Common/CustomRadioButtons';
import { UtilContractor } from 'utils/contractor';
import CustomInputMask from 'components/Common/CustomInputMask';
import { setErrorMess } from 'redux/reducers/Status/actionTypes';
import { XeroContactService } from 'services/Xero/XeroContact';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  contract: any;
  handleAddTax: (data) => void;
}

interface TaxDetailsFormData {
  id?: number;
  irdNumber?: string;
  bankAccountName?: string;
  bankAccountNumber?: string;
  bankName?: string;
  branchName?: string;
  accountName?: string;
  suffix?: string;
  gstNumber?: string;
  gstType?: {
    id?: number;
    name?: string;
    value?: string;
  };
  taxCode?: {
    id?: number;
    name?: string;
    value?: number;
  };
  withholdingTax?: {
    id?: number;
    name?: string;
    value?: number;
  };
  limitedCompanyName?: string;
  businessNumber?: string;
  isLimitedCompany?: boolean;
  isGstRegistered?: boolean;
  taxType?: boolean;
  gstFilingFrequency?: {
    id?: number;
    name?: string;
    value?: number;
  };
  gstStartingMonth?: {
    id?: number;
    name?: string;
    value?: number;
  };
  workType?: {
    id?: number;
    name?: string;
    value?: number;
  };
  bicCode?: string;
  isUseHomeOffice?: boolean;
  homeOfficeRate?: number;
}

const AddTax = memo(({ onClose, isOpen, contract, handleAddTax }: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const schema = useMemo(() => {
    return yup.object().shape({
      id: yup.string(),
      irdNumber: yup.string().required('This field is required'),
      bankAccountName: yup.string().required('This field is required'),
      bankAccountNumber: yup
        .string()
        .required('This field is required')
        .test(
          'len',
          'Please enter a valid bank account number',
          (val) => val !== null && val?.split('_').join('').length >= 19
        ),
      gstNumber: yup
        .string()
        .nullable()
        .when('isGstRegistered', {
          is: true,
          then: yup.string().required('This field is required'),
        }),
      gstType: yup.object().nullable().typeError('This field is required'),
      taxCode: yup
        .mixed()
        .nullable()
        .when('taxType', {
          is: true,
          then: yup.mixed().required('This field is required'),
        }),
      withholdingTax: yup.mixed().nullable().required('This field is required'),
      limitedCompanyName: yup
        .string()
        .nullable()
        .when('isLimitedCompany', {
          is: true,
          then: yup.string().required('This field is required'),
        }),
      businessNumber: yup
        .string()
        .nullable()
        .when('isLimitedCompany', {
          is: true,
          then: yup.string().required('This field is required'),
        }),
      isLimitedCompany: yup.boolean().nullable(),
      isGstRegistered: yup.boolean().required('This field is required'),
      taxType: yup.boolean().nullable(),
      gstFilingFrequency: yup
        .mixed()
        .nullable()
        .when('isGstRegistered', {
          is: true,
          then: yup.mixed().required('This field is required'),
        }),
      gstStartingMonth: yup
        .mixed()
        .nullable()
        .when('isGstRegistered', {
          is: true,
          then: yup.mixed().required('This field is required'),
        }),
      // workType: yup.mixed().required("This field is required"),
      bicCode: yup.string().required('This field is required'),
      isUseHomeOffice: yup.boolean().required('This field is required'),
      homeOfficeRate: yup
        .number()
        .nullable()
        .typeError('Please enter a Valid Number')
        .when('isUseHomeOffice', {
          is: true,
          then: yup
            .number()
            .typeError('Please enter a Valid Number')
            .required('This field is required'),
        }),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const availableType = watch('availableType');

  useEffect(() => {
    if (availableType?.id === SET_TIME) {
      resetField('noticePeriod');
      setValue('noticePeriod', null);
    } else if (availableType?.id === NOTICE_PERIOD) {
      resetField('setTime');
      setValue('setTime', null);
    }
    // eslint-disable-next-line
  }, [availableType]);

  useEffect(() => {
    if (!isOpen) {
      if (profile && profile?.taxDetails) {
        reset({
          id: profile?.taxDetails?.id,
          irdNumber: profile?.taxDetails?.irdNumber,
          bankAccountName: profile?.taxDetails?.nameOnAccount,
          bankAccountNumber: `${profile?.taxDetails?.bank}-${profile?.taxDetails?.branch}-${profile?.taxDetails?.account}-${profile?.taxDetails?.suffix}`,
          gstNumber: profile?.taxDetails?.gstNumber || '',
          gstType: UtilContractor.convertGstType(profile?.taxDetails?.gstType),
          taxCode: profile?.taxDetails?.taxCode
            ? UtilContractor?.getCurrentTaxCode(profile?.taxDetails?.taxCode)
            : null,
          withholdingTax: UtilContractor?.getCurrentHoldingTax(
            profile?.taxDetails?.withholdingTax
          ),
          limitedCompanyName: profile?.taxDetails?.companyName || '',
          businessNumber: profile?.taxDetails?.businessNumber || '',
          isLimitedCompany: profile?.taxDetails?.isLimitedCompany || false,
          isGstRegistered: profile?.taxDetails?.isGstRegistered || false,
          taxType: profile?.taxDetails?.isPaye,
          gstFilingFrequency: UtilContractor?.getCurrentGstFilingFrequency(
            profile?.taxDetails?.gstFilingFrequency
          ),
          gstStartingMonth: UtilContractor?.getCurrentGstStartingMonth(
            profile?.taxDetails?.gstStartingMonth
          ),
          // workType: UtilContractor?.getCurrenJobType(
          //   profile?.taxDetails?.workType
          // ),
          bicCode: profile?.taxDetails?.bicCode,
          isUseHomeOffice: profile?.taxDetails?.usingHomeOffice || false,
          homeOfficeRate: profile?.taxDetails?.homeOfficeRate,
        });
      } else {
        reset({
          irdNumber: '',
          bankAccountName: '',
          bankAccountNumber: '',
          gstNumber: '',
          gstType: null,
          taxCode: null,
          withholdingTax: { id: 8, name: '33%', value: 33 },
          limitedCompanyName: '',
          businessNumber: '',
          isLimitedCompany: false,
          isGstRegistered: false,
          taxType: false,
          gstFilingFrequency: null,
          gstStartingMonth: null,
          // workType: null,
          bicCode: '',
          isUseHomeOffice: false,
          homeOfficeRate: null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  const onSubmit = async (data: TaxDetailsFormData) => {
    const bankInfo = data?.bankAccountNumber.split('-');
    const isLimitedCompany = !data?.taxType ? data?.isLimitedCompany : false;
    const _data = {
      id: data?.id || null,
      irdNumber: data?.irdNumber,
      nameOnAccount: data?.bankAccountName,
      bank: bankInfo[0],
      branch: bankInfo[1],
      account: bankInfo[2],
      suffix: bankInfo[3].split('_')[0],
      gstNumber: data?.isGstRegistered ? data?.gstNumber : null,
      gstType: data?.gstType ? data?.gstType?.value : null,
      taxCode: data?.taxType ? data?.taxCode?.value : null,
      isLimitedCompany: isLimitedCompany,
      isGstRegistered: data?.isGstRegistered || false,
      companyName: isLimitedCompany ? data?.limitedCompanyName : null,
      businessNumber: isLimitedCompany ? data?.businessNumber : null,
      withholdingTax: data?.withholdingTax?.value,
      isPaye: data?.taxType,
      userId: user?.id,
      gstFilingFrequency: data?.isGstRegistered
        ? data?.gstFilingFrequency?.value
        : null,
      gstStartingMonth: data?.isGstRegistered
        ? data?.gstStartingMonth?.value
        : null,
      usingHomeOffice: data?.isUseHomeOffice || false,
      homeOfficeRate: data?.isUseHomeOffice ? data?.homeOfficeRate : null,
      // workType: data?.workType?.value || null,
      bicCode: data?.bicCode,
    };
    await handleAddTax(_data);
    await createOrUpdateXeroAccount();
    onClose();
  };

  const createOrUpdateXeroAccount = () => {
    XeroContactService.putContractorContact(user?.id, user?.id, {
      name:
        profile?.taxDetails?.companyName ||
        `${profile?.basicProfile?.firstName} ${profile?.basicProfile?.lastName}`,
      firstName: profile?.basicProfile?.firstName,
      lastName: profile?.basicProfile?.lastName,
      emailAddress: user?.email,
      contactNumber: user?.id,
      addresses: [
        {
          AddressType: 'STREET',
          City: profile?.contact?.city,
          Region: profile?.contact?.region,
          Country: profile?.contact?.country,
        },
        {
          AddressType: 'POBOX',
          City: profile?.contact?.city,
          Region: profile?.contact?.region,
          Country: profile?.contact?.country,
        },
      ],
      phones: [
        {
          PhoneType: 'DEFAULT',
          PhoneNumber: profile?.contact?.mobile,
          PhoneCountryCode: profile?.contact?.countryCode || '+64',
        },
        {
          PhoneType: 'DDI',
          PhoneNumber: profile?.contact?.mobile,
          PhoneCountryCode: profile?.contact?.countryCode || '+64',
        },
        {
          PhoneType: 'MOBILE',
          PhoneNumber: profile?.contact?.mobile,
          PhoneCountryCode: profile?.contact?.countryCode || '+64',
        },
        {
          PhoneType: 'FAX',
          PhoneNumber: profile?.contact?.mobile,
          PhoneCountryCode: profile?.contact?.countryCode || '+64',
        },
      ],
    }).catch((e) => dispatch(setErrorMess(e)));
  };

  return (
    <Card className={classes.card}>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        centered
        scrollable
        className={classes.modal}
      >
        <CardBody className={classes.cardBody}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p className={classes.title}>Add Tax</p>
            <div className={classes.content}>
              <div className={classes.commonItem}>
                <p
                  className={clsx(
                    classes.commonSubtitle,
                    classes.commonSubtitleFutureOpportunities
                  )}
                >
                  Tax type:
                </p>
                <CustomRadioButtons
                  content={[
                    {
                      title: 'PAYE',
                      value: true,
                      default: false,
                      disabled: true,
                    },
                    {
                      title: 'DIY',
                      value: false,
                      default: true,
                      disabled: false,
                    },
                  ]}
                  name='taxType'
                  inputName='taxType'
                  control={control}
                  className={classes.customRadio}
                  errorMessage={errors.taxType?.message}
                />
              </div>
              {!watch('taxType') && (
                <>
                  <div className={classes.commonItem}>
                    <p
                      className={clsx(
                        classes.commonSubtitle,
                        classes.commonSubtitleFutureOpportunities
                      )}
                    >
                      Are you a Limited Company ?
                    </p>
                    <CustomRadioButtons
                      content={[
                        { title: 'Yes', value: true, default: false },
                        { title: 'No', value: false, default: true },
                      ]}
                      name='isLimitedCompany'
                      inputName='isLimitedCompany'
                      control={control}
                      className={classes.customRadio}
                      errorMessage={errors.isLimitedCompany?.message}
                    />
                  </div>
                  {watch('isLimitedCompany') && (
                    <>
                      <CustomInput
                        className={classes.commonInput}
                        placeholder='Your Company Legal Name'
                        type='text'
                        autoComplete='off'
                        inputRef={register('limitedCompanyName')}
                        errorMessage={errors.limitedCompanyName?.message}
                      />
                      <CustomInput
                        className={classes.commonInput}
                        placeholder='New Zealand Business Number'
                        type='text'
                        autoComplete='off'
                        inputRef={register('businessNumber')}
                        errorMessage={errors.businessNumber?.message}
                      />
                    </>
                  )}
                </>
              )}
              <div className={classes.commonItem}>
                <p
                  className={clsx(
                    classes.commonSubtitle,
                    classes.commonSubtitleFutureOpportunities
                  )}
                >
                  Are you GST registered ?
                </p>
                <CustomRadioButtons
                  content={[
                    { title: 'Yes', value: true, default: false },
                    { title: 'No', value: false, default: true },
                  ]}
                  name='isGstRegistered'
                  inputName='isGstRegistered'
                  control={control}
                  className={classes.customRadio}
                  errorMessage={errors.isGstRegistered?.message}
                />
              </div>
              {watch('isGstRegistered') && (
                <>
                  <CustomInput
                    placeholder='GST number'
                    type='text'
                    autoComplete='off'
                    inputRef={register('gstNumber')}
                    errorMessage={errors.gstNumber?.message}
                  />
                  <CustomSelect
                    className={classes.commonSelect}
                    placeholder='GST Type'
                    name='gstType'
                    control={control}
                    options={GstTypes}
                    errorMessage={errors?.gstType?.message}
                  />
                  <CustomSelect
                    className={classes.commonSelect}
                    placeholder='How often do you file GST'
                    name='gstFilingFrequency'
                    control={control}
                    options={gstFilingFrequency}
                    errorMessage={errors?.gstFilingFrequency?.message}
                  />
                  <CustomSelect
                    className={classes.commonSelect}
                    placeholder='Starting Month'
                    name='gstStartingMonth'
                    control={control}
                    options={gstStartingMonth}
                    errorMessage={errors?.gstStartingMonth?.message}
                  />
                </>
              )}
              {/* <CustomSelect
                className={classes.commonSelect}
                placeholder="Work Type"
                name="workType"
                control={control}
                options={WorkTypes}
                errorMessage={errors?.workType?.message}
              /> */}
              <CustomInput
                className={classes.commonInput}
                placeholder='BIC Code'
                autoComplete='off'
                type='text'
                inputRef={register('bicCode')}
                errorMessage={errors?.bicCode?.message}
                endIcon={
                  <a
                    href='https://www.businessdescription.co.nz'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className={clsx('fa fa-search', classes.searchIcon)} />
                  </a>
                }
              />
              <div className={classes.commonItem}>
                <p
                  className={clsx(
                    classes.commonSubtitle,
                    classes.commonSubtitleFutureOpportunities
                  )}
                >
                  Do you use a Home Office?
                </p>
                <CustomRadioButtons
                  content={[
                    { title: 'Yes', value: true, default: false },
                    { title: 'No', value: false, default: true },
                  ]}
                  name='isUseHomeOffice'
                  inputName='isUseHomeOffice'
                  control={control}
                  className={classes.customRadio}
                  errorMessage={errors.isUseHomeOffice?.message}
                />
              </div>
              {watch('isUseHomeOffice') && (
                <CustomInput
                  className={classes.commonInput}
                  placeholder='Your percentage of your home office used'
                  autoComplete='off'
                  inputRef={register('homeOfficeRate')}
                  errorMessage={errors.homeOfficeRate?.message}
                />
              )}
              <CustomInput
                placeholder='IRD number'
                type='text'
                autoComplete='off'
                inputRef={register('irdNumber')}
                errorMessage={errors.irdNumber?.message}
              />
              <CustomInput
                placeholder='Your Bank Account Name'
                type='text'
                autoComplete='off'
                inputRef={register('bankAccountName')}
                errorMessage={errors.bankAccountName?.message}
              />
              <CustomInputMask
                placeHolder='Your Bank Account Number'
                name='bankAccountNumber'
                format='99-9999-9999999-999'
                control={control}
                errorMessage={errors.bankAccountNumber?.message}
              />
              <CustomSelect
                className={classes.commonSelect}
                placeholder='Your Withholding Tax'
                name='withholdingTax'
                control={control}
                options={WithholdingTax}
                errorMessage={errors?.withholdingTax?.message}
              />
              {watch('withholdingTax')?.id === 1 && (
                <p className={clsx(classes.commonSubtitle, 'text-danger mb-3')}>
                  Please send 0% letter from IRD to michael@necta.nz
                </p>
              )}
              {watch('taxType') && (
                <CustomSelect
                  className={classes.commonSelect}
                  placeholder='Tax Code'
                  name='taxCode'
                  control={control}
                  options={TaxCode}
                  errorMessage={errors?.taxCode?.message}
                />
              )}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                size='md'
                color='default'
                type='button'
                className={classes.btn}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                size='md'
                color='primary'
                type='submit'
                className={classes.btn}
              >
                Ok
              </Button>
            </div>
          </Form>
        </CardBody>
      </Modal>
    </Card>
  );
});

export default AddTax;
