/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useMemo, useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  CardBody,
  Modal,
  CardTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardFooter,
} from "reactstrap";
import classes from "./styles.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomDatePicker from "components/Common/CustomDatePicker";
import CustomInput from "components/Common/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { JobService } from "services/Employer/Listing/Job";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import { ReducerType } from "redux/reducers";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import clsx from "clsx";
import moment from "moment";
import { ListingEmailService } from "services/Employer/Listing/Email";
import { EmailTypes } from "models/Employer/Listings/Dashboard";
import CustomSwitch from "components/Common/CustomSwitch";
import CustomSlider from "components/Common/CustomSlider";
import SignTerm from "./SignTerms";
import { saveAs } from "file-saver";
import CustomSelect from "components/Common/CustomSelect";
import { XeroContactService } from "services/Xero/XeroContact";

// import PizZipUtils from "pizzip/utils/index.js";

interface SendContractProps {
  onClose: () => void;
  isOpen: boolean;
  contractor?: any;
  jobId: number;
  isSendAgain: boolean;
  handleGetCandidates: (item, index, isSendContract) => void;
  subTableIndex: number;
  isContract?: boolean;
  job?: any;
}

const SendContract = memo(
  ({
    onClose,
    isOpen,
    contractor,
    jobId, 
    isSendAgain,
    handleGetCandidates,
    subTableIndex,
    isContract,
    job,
  }: SendContractProps) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: ReducerType) => state.user);
    const { setting, jobList } = useSelector((state: ReducerType) => state.employer);
    const { jobOffers } = useSelector((state: ReducerType) => state.employer);

    const contractKey = `${jobId}-${contractor?.id}-${S3_UPLOAD_TYPE_PRIFIX.JOB_CONTRACT}`;

    const [isUseNectaContract, setIsUseNectaContract] = useState(true);
    const [modalTermsOfAgreement, setModalTermsOfAgreement] = useState(false);
    const [employerSettingInfo, setEmployerSettingInfo] = useState(null);
    const [isOpenSignTermModal, setIsOpenSignTermModal] = useState(false);
    const [contractData, setContractData] = useState(null);
    const [country, setCountry] = useState('');

    const schema = useMemo(() => {
      return yup.object().shape({
        client: yup.string().required("This field is required"),
        startDate: yup.date().nullable().required("This field is required"),
        endDate: isContract
          ? yup.date().nullable().required("This field is required")
          : yup.date().nullable(),
        title: yup.string().required("This field is required"),
        rateOffered: isContract
          ? yup
            .number()
            .typeError("Please enter a number")
            .required("This field is required")
          : yup.number(),
        salary: !isContract
          ? yup
            .number()
            .typeError("Please enter a number")
            .required("This field is required")
          : yup.number(),
        managerName: yup.string().required("This field is required"),
        approvalName: isContract
          ? yup.string().when("approvalNameSelect", {
            is: (select) => select?.id === 2,
            then: yup.string().required("This field is required"),
          })
          : yup.string(),
        purchaseOrderNumber: yup.string(),
        approvalNameSelect: isContract
          ? yup.object().nullable().required("This field is required")
          : yup.object().nullable(),
        approvalEmail: yup.string().when("approvalNameSelect", {
          is: (select) => select?.id === 2,
          then: yup.string().required("This field is required"),
        }),
        contractUpload: isUseNectaContract
          ? yup.mixed().nullable()
          : yup.mixed().required("This field is required"),
      });
    }, [isUseNectaContract]);

    useEffect(() => {
      ListingEmailService.getEmployerSetting(user?.id).then((res) => {
        setEmployerSettingInfo(res);
      });
    }, [dispatch, user?.id]);

    useEffect(() => {
      const job = jobList.find(job => job.id === jobId);
      if (job) {
        setCountry(job.country);
        // console.log("here", country)

      }
      // console.log(country)
      if (isContract && country === "New Zealand") {
        setIsUseNectaContract(true);
      } else {
        setIsUseNectaContract(false);
      }
    }, [isOpen]);

    const handleShowModalTermsOfAgreement = () => {
      setModalTermsOfAgreement(true);
    };

    const handleAcceptTerm = async () => {
      await ListingEmailService.putEmployerSetting(
        user?.id,
        employerSettingInfo?.id,
        {
          employerContraAgreementAccepted: true,
        }
      )
        .then(async (res) => {
          setEmployerSettingInfo(res);
          await JobService.getTemplate("ContractorTemplate.docx")
            .then((res) => {
              const myFile = new File(
                [res as BlobPart],
                "ContractorTemplate.docx",
                {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }
              );
              var reader = new FileReader();
              reader.readAsBinaryString(myFile);
              reader.onload = async function (evt) {
                const content = evt.target.result;
                var zip = new PizZip(content);
                var doc = new Docxtemplater(zip, {
                  paragraphLoop: true,
                  linebreaks: true,
                });
                const duration = moment(contractData?.endDate).diff(
                  moment(contractData?.startDate),
                  "days"
                );
                doc.render({
                  Name: `${contractor?.firstName} ${contractor?.lastName}`,
                  Client: contractData?.client,
                  Contractor: `${contractor?.firstName} ${contractor?.lastName}`,
                  Title: contractor?.title,
                  Department_Project: contractData?.client,
                  NameOfContractor: contractor?.firstName,
                  Lead: contractData?.managerName,
                  CurrentDate: moment(new Date()).format("DD/MM/YYYY"),
                  StartDate: moment(contractData?.startDate).format(
                    "DD/MM/YYYY"
                  ),
                  EndDate: moment(contractData?.endDate).format("DD/MM/YYYY"),
                  RATE: contractData?.rateOffered,
                  DURATION: duration,
                  T_RATE: contractData?.rateOffered * 8 * duration,
                });
                var blob = doc.getZip().generate({
                  type: "blob",
                  mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  compression: "DEFLATE",
                });
                const contract = new File([blob], `${contractKey}.docx`, {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                await JobService.postContract(contract, contractKey)
                  .then(async (res) => {
                    handleShowSignTermModal();
                    handleHideTerm();
                    await handleSendContract({
                      contractor: contractor?.id,
                      employer: user?.id,
                      job: jobId,
                      startDate: contractData?.startDate,
                      endDate: contractData?.endDate,
                      rateOffered: contractData?.rateOffered,
                      contractUrl: res,
                      managerName: contractData?.managerName,
                      approvalName:
                        contractData?.approvalNameSelect?.id === 2
                          ? contractData?.approvalName
                          : `${setting?.firstName} ${setting?.lastName}`,
                      approvalEmail:
                        contractData?.approvalNameSelect?.id === 2
                          ? contractData?.approvalEmail
                          : setting?.email,
                      purchaseOrderNumber: contractData?.purchaseOrderNumber,
                      offered: true,
                      revoked: false,
                      rejected: false,
                      accepted: false,
                      isContraContractUsed: isUseNectaContract,
                    });
                  })
                  .catch((e) => dispatch(setErrorMess(e)));
              };
            })
            .catch((e) => dispatch(setErrorMess(e)));
        })
        .catch((e) => dispatch(setErrorMess(e)));
    };

    const handleHideTerm = () => {
      setModalTermsOfAgreement(false);
    };

    const handleShowSignTermModal = () => {
      setIsOpenSignTermModal(!isOpenSignTermModal);
    };

    const handleFileInputChange = ({ target }) => {
      if (target?.files[0]) {
        setValue("contractUpload", target.files[0], { shouldValidate: true });
      } else {
        setValue("contractUpload", null);
      }
    };

    const handleSendContract = async (dataSubmit) => {
      if (isSendAgain) {
        await JobService.putContractJobOffer(dataSubmit, contractor?.jobOfferId)
          .then(async (res) => {
            await ListingEmailService.offerCreateEmail(res?.id, {
              type: EmailTypes.NEW_JOB_OFFER_FOR_CONTRACTOR,
            })
              .then(async (contractRes) => {
                if (isContract) {
                  await JobService.checkAccountExistOnNecta(
                    user?.id,
                    encodeURIComponent(contractData?.approvalEmail)
                  )
                    .then((checkRes) => {
                      if (checkRes?.status === false) {
                        JobService.inviteToBeApproverMail(res?.id, {
                          type: EmailTypes.TIMESHEET_APPROVER_INVITE,
                        }).catch((e) => dispatch(setErrorMess(e)));
                      }
                    })
                    .catch((e) => dispatch(setErrorMess(e)));
                }
                onClose();
                handleGetCandidates({ id: jobId }, subTableIndex, true);
              })
              .catch((e) => dispatch(setErrorMess(e)));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      } else {
        await JobService.postContractJobOffer(dataSubmit)
          .then(async (res) => {
            await ListingEmailService.offerCreateEmail(res?.id, {
              type: EmailTypes.NEW_JOB_OFFER_FOR_CONTRACTOR,
            })
              .then(async (contractRes) => {
                if (isContract) {
                  await JobService.checkAccountExistOnNecta(
                    user?.id,
                    encodeURIComponent(contractData?.approvalEmail)
                  )
                    .then((checkRes) => {
                      if (checkRes?.status === false) {
                        JobService.inviteToBeApproverMail(res?.id, {
                          type: EmailTypes.TIMESHEET_APPROVER_INVITE,
                        }).catch((e) => dispatch(setErrorMess(e)));
                      }
                    })
                    .catch((e) => dispatch(setErrorMess(e)));
                }
                onClose();
                handleGetCandidates({ id: jobId }, subTableIndex, true);
              })
              .catch((e) => dispatch(setErrorMess(e)));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      }
    };

    const onSubmit = (data) => {
      createOrUpdateXeroAccount();
      if (isUseNectaContract) {
        setContractData(data);
        const checkTerms = jobOffers?.length
          ? jobOffers?.filter((item) => item?.isContraContractUsed)
          : [];
        if (checkTerms?.length) {
          handleShowSignTermModal();
        } else {
          handleShowModalTermsOfAgreement();
        }
      } else {
        if (isContract) {
          dispatch(setLoading(true));
          data?.contractUpload.arrayBuffer().then((arrayBuffer) => {
            const blob = new Blob([new Uint8Array(arrayBuffer)], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            JobService.postContract(blob, contractKey)
              .then(async (res) => {
                await handleSendContract({
                  contractor: contractor?.id,
                  employer: user?.id,
                  job: jobId,
                  startDate: data?.startDate,
                  endDate: data?.endDate,
                  rateOffered: data?.rateOffered,
                  contractUrl: res,
                  managerName: data?.managerName,
                  approvalName:
                    data?.approvalNameSelect?.id === 2
                      ? data?.approvalName
                      : `${setting?.firstName} ${setting?.lastName}`,
                  approvalEmail:
                    data?.approvalNameSelect?.id === 2
                      ? data?.approvalEmail
                      : setting?.email,
                  purchaseOrderNumber: data?.purchaseOrderNumber,
                  offered: true,
                  revoked: false,
                  rejected: false,
                  accepted: false,
                  isContraContractUsed: isUseNectaContract,
                });
              })
              .catch((e) => dispatch(setErrorMess(e)))
              .finally(() => dispatch(setLoading(false)));
          });
        } else {
          dispatch(setLoading(true));
          data?.contractUpload.arrayBuffer().then((arrayBuffer) => {
            const blob = new Blob([new Uint8Array(arrayBuffer)], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            JobService.postContract(blob, contractKey)
              .then(async (res) => {
                await handleSendContract({
                  contractor: contractor?.id,
                  employer: user?.id,
                  job: jobId,
                  startDate: data?.startDate,
                  salary: data?.salary,
                  contractUrl: res,
                  managerName: data?.managerName,
                  purchaseOrderNumber: data?.purchaseOrderNumber,
                  offered: true,
                  revoked: false,
                  rejected: false,
                  accepted: false,
                  isContraContractUsed: isUseNectaContract,
                });
              })
              .catch((e) => dispatch(setErrorMess(e)))
              .finally(() => dispatch(setLoading(false)));
          });
        }
      }
    };

    const {
      handleSubmit,
      formState: { errors },
      control,
      reset,
      watch,
      setValue,
      register,
    } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });



    const [client, setClient] = useState(null);
    useEffect(() => {
      if (job?.client !== undefined) {
        JobService.getClient()
          .then(clients => {
            // Assuming clients is an array of client objects and each has an id property
            const matchingClient = clients.find(c => c.id === job.client);
            setClient(matchingClient);
            console.log(client)
          })
          .catch(error => {
            console.error('Failed to fetch clients:', error);
            // Handle error or set client to null or an error state as needed
          });
      }
    }, [job, job?.client]); 

    useEffect(() => {
      // console.log("Settings:", setting, "Job Information:", job)
      const clientName = client?.company || setting?.name || "";
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const jobStartDate = job?.startDate ? new Date(job.startDate) : null;
      const startDateValue = jobStartDate && jobStartDate < today ? null : jobStartDate;

      reset({
        client: clientName,
        startDate: startDateValue,
        endDate: null,
        title: job?.title || "",
        rateOffered: job?.maxRate || 30,
        salary: job?.maxSalary || 40000,
        managerName: `${client?.firstName ?? ""} ${client?.lastName ?? ""}`.trim() || "",
        approvalName: "",
        purchaseOrderNumber: "",
        approvalNameSelect: null,
      });
    }, [isOpen, reset, setting?.name, client]);

    const handleDownloadTerm = () => {
      dispatch(setLoading(true));
      JobService.getContract("Terms-Of-Business-V2.docx", null)
        .then((res) => {
          const myFile = new File(
            [res as BlobPart],
            "Terms-Of-Business-V2.docx",
            {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }
          );
          saveAs(myFile, "TermOfBusiness.docx");
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    };

    const createOrUpdateXeroAccount = () => {
      XeroContactService.putEmployerContact(user?.id, user?.id, {
        name: client?.company || setting?.name,
        firstName: client?.firstName || setting?.firstName,
        lastName: client?.lastName || setting?.lastName,
        emailAddress: client?.email || setting?.email,
        contactNumber: client?.orgadmin || user?.id,
        addresses: [
          {
            AddressType: "STREET",
            City: setting?.city,
            Region: setting?.region,
            Country: setting?.country
          },
          {
            AddressType: "POBOX",
            City: setting?.city,
            Region: setting?.region,
            Country: setting?.country
          }
        ],
        phones: [
          {
            PhoneType: "DEFAULT",
            PhoneNumber: String(client?.phone) || setting?.workPhone,
            PhoneCountryCode: setting?.workPhoneCountryCode || "+64"
          },
          {
            PhoneType: "DDI",
            PhoneNumber: String(client?.phone) || setting?.workPhone,
            PhoneCountryCode: setting?.workPhoneCountryCode || "+64"
          },
          {
            PhoneType: "MOBILE",
            PhoneNumber: String(client?.phone) || setting?.workPhone,
            PhoneCountryCode: setting?.workPhoneCountryCode || "+64"
          },
          {
            PhoneType: "FAX",
            PhoneNumber: String(client?.phone) || setting?.workPhone,
            PhoneCountryCode: setting?.workPhoneCountryCode || "+64"
          }
        ]
      })
        .catch((e) => dispatch(setErrorMess(e)));
    }

    return (
      <Card className={classes.card}>
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          centered
          scrollable
          className={classes.modal}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader className={classes.cardHeader}>
              <CardTitle className={classes.title}>
                SEND OFFER TO CONTRACT
              </CardTitle>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <CustomInput
                placeholder="Contractor name"
                type="text"
                autoComplete="off"
                value={`${contractor?.firstName} ${contractor?.lastName}`}
                readOnly
              />
              <CustomInput
                placeholder="Organization or company"
                type="text"
                autoComplete="off"
                inputRef={register("client")}
                errorMessage={errors.client?.message}
                readOnly={!!setting?.name}
              />
              <CustomDatePicker
                name={"startDate"}
                control={control}
                errorMessage={errors.startDate?.message}
                placeholder={"Start date"}
                dateFormat="dd/MM/yyyy"
                minDate={moment().toDate()}
                maxDate={watch("endDate")}
                timeClassName={classes.timeStyle}
              />
              {isContract && (
                <CustomDatePicker
                  name={"endDate"}
                  control={control}
                  errorMessage={errors.endDate?.message}
                  placeholder={"End date"}
                  dateFormat="dd/MM/yyyy"
                  minDate={watch("startDate") || moment().toDate()}
                  timeClassName={classes.timeStyle}
                />
              )}
              <CustomInput
                placeholder="Role title"
                type="text"
                autoComplete="off"
                inputRef={register("title")}
                errorMessage={errors.title?.message}
              />
              <CustomInput
                placeholder="Manager name"
                type="text"
                autoComplete="off"
                inputRef={register("managerName")}
                errorMessage={errors.managerName?.message}
              />
              {isContract && country === "New Zealand" && (
                <CustomSelect
                  placeholder="Approval Name"
                  name="approvalNameSelect"
                  control={control}
                  options={[
                    { id: 1, name: "Myself" },
                    { id: 2, name: "Other" },
                  ]}
                  errorMessage={errors.approvalNameSelect?.message}
                />
              )}
              {watch("approvalNameSelect")?.id === 2 && (
                <>
                  <CustomInput
                    placeholder="Approval Name"
                    type="text"
                    autoComplete="off"
                    inputRef={register("approvalName")}
                    errorMessage={errors.approvalName?.message}
                  />
                  <CustomInput
                    placeholder="Approval Email Address"
                    type="text"
                    autoComplete="off"
                    inputRef={register("approvalEmail")}
                    errorMessage={errors.approvalEmail?.message}
                  />
                </>
              )}

              <CustomInput
                placeholder="Purchase Order Number"
                type="text"
                autoComplete="off"
                inputRef={register("purchaseOrderNumber")}
                errorMessage={errors.purchaseOrderNumber?.message}
              />
              {isContract && (
                <>
                  <span className="ml-1 mb-3">Rate offered:</span>
                  <CustomSlider
                    name="rateOffered"
                    min={30}
                    max={300}
                    control={control}
                    className={classes.sliderRateOffered}
                    errorMessage={errors.rateOffered?.message}
                  />
                </>
              )}
              {!isContract && (
                <>
                  <span className="ml-1 mb-3">Salary:</span>
                  <CustomSlider
                    name="salary"
                    min={40000}
                    max={500000}
                    control={control}
                    className={classes.sliderRateOffered}
                    errorMessage={errors.salary?.message}
                  />
                </>
              )}
              {isContract && country === "New Zealand" && (
                <div className={classes.customSwitchWrapper}>
                  <CustomSwitch
                    value={isUseNectaContract}
                    onChange={() => {
                      setIsUseNectaContract(!isUseNectaContract);
                    }}
                    className={clsx(classes.customCheckbox, "my-2")}
                  />
                  <p>Use Necta's contract</p>
                </div>
              )}
              <div className={isUseNectaContract ? "d-none" : classes.content}>
                <p className={classes.commonTitle}>Upload Contract:</p>
                <input
                  className={classes.uploadFile}
                  type="file"
                  placeholder="Upload..."
                  onChange={handleFileInputChange}
                  accept=".doc, .docx, .pdf"
                />
                <span className="text-danger ml-3 mt-1 d-block text-left">
                  {errors?.contractUpload?.message &&
                    errors?.contractUpload?.message}
                </span>
              </div>
            </CardBody>
            <CardFooter className={classes.cardFooter}>
              <div className={classes.buttonContainer}>
                <Button
                  size="md"
                  color="default"
                  type="button"
                  className={classes.btn}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  size="md"
                  color="primary"
                  type="submit"
                  className={classes.btn}
                >
                  {isUseNectaContract ? "Submit" : "Send Contract"}
                </Button>
              </div>
            </CardFooter>
          </Form>
        </Modal>

        <Modal
          isOpen={modalTermsOfAgreement}
          toggle={handleHideTerm}
          className={classes.termModal}
        >
          <ModalHeader toggle={handleHideTerm}>Terms of Agreement</ModalHeader>
          <ModalBody>
            Please read the terms of agreement{" "}
            <span
              className={classes.downloadTermBtn}
              onClick={handleDownloadTerm}
            >
              here
            </span>
            .
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="default" onClick={handleHideTerm}>
              Decline
            </Button>
            <Button
              className="ml-3"
              color="primary"
              onClick={handleShowSignTermModal}
            >
              Sign &amp; Accept
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={isOpenSignTermModal}
          toggle={handleShowSignTermModal}
          className={classes.termModal}
        >
          <SignTerm
            onCloseSignTermModal={handleShowSignTermModal}
            handleAcceptTerm={handleAcceptTerm}
          />
        </Modal>
      </Card>
    );
  }
);

export default SendContract;
