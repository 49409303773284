import { memo, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import SignaturePad from "signature_pad";
import classes from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import AddTax from "./AddTax";
import { TaxService } from "services/Contractor/Tax/Tax";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";
import { setErrorMess } from "redux/reducers/Status/actionTypes";
import { EmailTypes } from "models/Employer/Listings/Dashboard";
// import { saveAs } from "file-saver";

interface SignContractProps {
  contract: any;
  backToContract: () => void;
  handleSetSignatureForMobile: (signature) => void;
  handleProgressOffer: (jobItem, signature) => void;
}

const SignContract = memo((props: SignContractProps) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const {
    contract,
    backToContract,
    handleSetSignatureForMobile,
    handleProgressOffer,
  } = props;

  const [signature, setSignature] = useState(null);
  const [addTaxModalOpen, setAddTaxModalOpen] = useState({
    isOpenModal: false,
  });

  useEffect(() => {
    const canvas = document.getElementById("canvas");
    function resizeCanvas() {
      // When zoomed out to less than 100%, for some very strange reason,
      // some browsers report devicePixelRatio as less than 1
      // and only part of the canvas is cleared then.
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      (canvas as any).width = canvas.offsetWidth * ratio;
      (canvas as any).height = canvas.offsetHeight * ratio;
      (canvas as any).getContext("2d").scale(ratio, ratio);
    }
    if (canvas) {
      window.onresize = resizeCanvas;
      resizeCanvas();
      const signaturePad = new SignaturePad(canvas as any, {
        backgroundColor: "#ffffff",
        penColor: "#000000",
      });
      document.getElementById("clear").addEventListener("click", () => {
        signaturePad.clear();
      });
      document.getElementById("undo").addEventListener("click", () => {
        var data = signaturePad.toData();
        if (data) {
          data.pop();
          signaturePad.fromData(data);
        }
      });
      document
        .getElementById("submit-contract")
        .addEventListener("click", () => {
          const data = signaturePad.toDataURL("image/png");
          setSignature(data);
        });
      document
        .getElementById("submit-contract-mobile")
        .addEventListener("click", () => {
          const data = signaturePad.toDataURL("image/png");
          handleSetSignatureForMobile(data);
          handleOpenAddTaxForm();
          // backToContract();
        });
      document
        .getElementById("submit-contract-mobile")
        .addEventListener("touchstart", () => {
          const data = signaturePad.toDataURL("image/png");
          handleSetSignatureForMobile(data);
          handleOpenAddTaxForm();
          // backToContract();
        });
    }
  }, [handleSetSignatureForMobile]);

  useEffect(() => {
    if (signature && contract) {
      handleOpenAddTaxForm();
    }
  }, [signature, contract]);

  const handleOpenAddTaxForm = () => {
    setAddTaxModalOpen({
      isOpenModal: true,
    });
  };

  const onCloseAddTaxModal = () => {
    setAddTaxModalOpen({
      isOpenModal: false,
    });
  };

  const handleAddTax = (data) => {
    const _data = {
      ...data,
      jobOffer: contract?.offerId,
    };
    if (_data?.id) {
      TaxService.putTax(_data, _data?.id).then((res) => {
        handleProgressOffer(contract, signature);
        const _profile = { ...profile, taxDetails: res };
        TaxService.sendWithholdingTaxForm(contract?.offerId, {
          type: EmailTypes.CONTRACTOR_WITHHOLDING_TAX_FORM,
        }).catch((err) => dispatch(setErrorMess(err)));
        dispatch(setProfileReducer(_profile));
        backToContract();
      });
    } else {
      TaxService.postTax(_data).then((res) => {
        handleProgressOffer(contract, signature);
        const _profile = { ...profile, taxDetails: res };
        TaxService.sendWithholdingTaxForm(contract?.offerId, {
          type: EmailTypes.CONTRACTOR_WITHHOLDING_TAX_FORM,
        }).catch((err) => dispatch(setErrorMess(err)));
        dispatch(setProfileReducer(_profile));
        backToContract();
      });
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <CardBody className={classes.cardBody}>
          <CardTitle className={classes.title}>SIGN HERE</CardTitle>
        </CardBody>
        <CardBody className={classes.canvasContainer}>
          <canvas
            id="canvas"
            className={classes.canvas}
            width={350}
            height={200}
          />
        </CardBody>
        <div className={classes.canvasButtons}>
          <Button size="sm" color="info" type="button" id="undo">
            Undo
          </Button>
          <Button size="sm" color="info" type="button" id="clear">
            Clear
          </Button>
        </div>
        <CardBody className={classes.cardBody}>
          <div className={classes.buttonContainer}>
            <Button
              size="md"
              color="default"
              type="button"
              className={classes.btn}
              onClick={backToContract}
            >
              Back
            </Button>
            <Button
              id="submit-contract"
              size="md"
              color="primary"
              type="button"
              className={classes.btnSubmitDesktop}
            >
              Submit
            </Button>
            <Button
              id="submit-contract-mobile"
              size="md"
              color="primary"
              type="button"
              className={classes.btnSubmitMobile}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>

      <AddTax
        onClose={onCloseAddTaxModal}
        isOpen={addTaxModalOpen.isOpenModal}
        contract={contract}
        handleAddTax={handleAddTax}
      />
    </>
  );
});

export default SignContract;
