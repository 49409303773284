import { memo, useEffect, useState } from 'react'
import ScrollNavbar from 'components/Navbars/Contractor/ScrollNavbar'
import FooterDefault from 'components/Footers/FooterDefault'
import FooterMobile from 'components/Footers/FooterMobile'
import classes from './styles.module.scss'
import { Container, NavItem, NavLink, Nav } from 'reactstrap'
import Apply from './components/Apply'
import { OFFER_TYPE } from 'config/constants'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerType } from 'redux/reducers'
import moment from 'moment'
import { setLoading } from 'redux/reducers/Status/actionTypes'


interface JobsProps {}

// eslint-disable-next-line no-empty-pattern
const Offer = memo((props: JobsProps) => {
	const dispatch = useDispatch()
	const { allOffers, profile } = useSelector(
		(state: ReducerType) => state.contractor
	)
	const {
		isOffersToConnectStillExist,
		isOffersToInterviewStillExist,
		isContractualOffersStillExist,
	} = useSelector((state: ReducerType) => state.status)

	const [hTabs, setHTabs] = useState('1')
	const [type, setType] = useState(OFFER_TYPE.APPLY)
	const [displayMobileMenu, setDisplayMobileMenu] = useState(true)
	const [acceptedOffer, setAcceptedOffer] = useState(null)
	const [acceptedOfferArr, setAcceptedOfferArr] = useState(null)
	const [acceptedJob, setAcceptedJob] = useState(null)
	const [interviewOffersArr, setInterviewOffersArr] = useState([])
	const [activeClients, setActiveClients] = useState([])

	const getInformation = async () => {
		dispatch(setLoading(true))
		setInterviewOffersArr([])
		setActiveClients([])
		const acceptedNectaOfferArr = allOffers?.jobOffers.filter(
			(item) => item?.accepted === true && item?.isContraContractUsed
		)
		if (acceptedNectaOfferArr && acceptedNectaOfferArr.length > 0) {
			setAcceptedOffer(acceptedNectaOfferArr[0])
			const acceptedJobArr = allOffers?.jobs.filter(
				(item) => item?.id === acceptedNectaOfferArr[0]?.job
			)
			setAcceptedJob(acceptedJobArr[0])
		}
	}
	useEffect(() => {
		getInformation()
		// console.log("Item:", JSON.stringify(allOffers, null, 2)); // Log the entire 'item' object

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allOffers, dispatch])



	return (
		<>
			<ScrollNavbar />
			<div className='wrapper' id='detect_swipe'>
				<div className={classes.header} />
				<div className={classes.section}>
					<Container className={classes.container}>
						<div className={classes.contentLeft}>
							<div className={classes.activeClientContainer}>
								<p className={clsx(classes.activeClientTitle, 'mb-1')}>
									Previous Applications
								</p>
							</div>
							{acceptedOfferArr
								?.slice()
								.sort(
									(a, b) =>
										moment(b?.endDate).valueOf() -
										moment(a?.endDate).valueOf()
								)
								}
							<div className={classes.activeClientContainer}>
								{acceptedOffer &&
									acceptedOffer?.isContraContractUsed && (
										<>
											{acceptedJob && (
												<p
													className={clsx(
														classes.actionsTitle,
														'mb-0'
													)}
												>
													{acceptedJob?.title}
												</p>
											)}
											<p className={classes.actionsContent}>
												{acceptedJob?.companyName}
												<br></br>
												Status:{' '}
												{acceptedJob?.status ? acceptedJob?.status : "Applied"}
											</p>
											
										</>
									)}

							</div>
						</div>
						<div className={classes.contentRight}>

							<div
								className={clsx({
									[classes.offerMobile]: displayMobileMenu,
								})}
							>
								<div className={classes.backContainer}>
									<i
										className='fa fa-arrow-left'
										onClick={() => setDisplayMobileMenu(true)}
									/>
								</div>

								{type === OFFER_TYPE.APPLY && <Apply />}
							</div>
						</div>
					</Container>
				</div>
				<FooterDefault />
				<FooterMobile />
			</div>

		</>
	)
})

export default Offer
