import { memo, useEffect, useMemo, useState } from 'react';
import { Form, Button, Card, CardBody } from 'reactstrap';
import CustomInput from 'components/Common/CustomInput';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import classes from './styles.module.scss';
import routes from 'routers/routes';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { EducationService } from 'services/Contractor/NewMember/Education';
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes';
import { EducationParams } from 'models/Contractor/NewMember/Education';
import { ReducerType } from 'redux/reducers';
import { setProfileReducer } from 'redux/reducers/Contractor/actionTypes';
import CustomCheckbox from 'components/Common/CustomCheckbox';
import clsx from 'clsx';
import SearchLocationInput from 'components/Common/GoogleAddress';

interface Props {}
interface EducationFormData {
  educationList: {
    id: number;
    name: string;
    provider: string;
    endDateIsPresent: boolean;
    dateFinished: string | Date;
    city: string;
    country: string;
    address?: string;
  }[];
}

const Education = memo((props: Props) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const [deleteItems, setDeleteItem] = useState([]);

  const handleSkip = () => {
    reset({
      educationList: [
        {
          name: '',
          provider: '',
          endDateIsPresent: false,
          dateFinished: null,
          city: '',
          country: '',
          address: '',
        },
      ],
    });
    dispatch(push(routes.contractor.newMember.experience));
  };

  const schema = useMemo(() => {
    return yup.object().shape({
      educationList: yup.array().of(
        yup.object().shape({
          id: yup.number(),
          name: yup
            .string()
            .typeError('This field is required')
            .required('This field is required'),
          provider: yup
            .string()
            .typeError('This field is required')
            .required('This field is required'),
          endDateIsPresent: yup.boolean(),
          dateFinished: yup.date()
          .typeError('Please enter a valid date')
          .nullable()
          .when('endDateIsPresent', {
            is: false, // Only require dateFinished if endDateIsPresent is false
            then: yup.date().required('Date finished is required when end date is not present').typeError('Please enter a valid date'),
            otherwise: yup.date().nullable(),
          }),
          address: yup.string().max(100, 'Maximum 100 characters').nullable(),
        })
      ),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    control,
    reset,
  } = useForm<EducationFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'educationList',
    keyName: 'fieldID',
  });

  useEffect(() => {
    append({});
  }, [append]);

  useEffect(() => {
    if (!!profile?.education?.length) {
      reset({
        educationList: profile.education.map((item: EducationParams) => ({
          id: item?.id,
          name: item?.degreeName,
          provider: item?.schoolName,
          address:
            item.city && item.country ? `${item.city}, ${item.country}` : '',
          endDateIsPresent: item?.endDateIsPresent,
          dateFinished: new Date(item?.endDate),
        })),
      });
    }
  }, [reset, profile]);

  const handleDeleteItem = (id: number, index: number) => {
    remove(index);
    if (id) {
      setDeleteItem([...deleteItems, id]);
    }
  };

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.basicProfile));
  };

  const handleChangeEndDateIsPresent = (index) => {
    const currentEndDateIsPresent = getValues(
      `educationList.${index}.endDateIsPresent`
    );
    setValue(
      `educationList.${index}.endDateIsPresent`,
      !currentEndDateIsPresent
    );
  };

  const onSubmit = async (data: EducationFormData) => {
    if (
      data?.educationList[0]?.name === '' ||
      data?.educationList[0]?.provider === '' ||
      (data?.educationList[0]?.endDateIsPresent === false &&
        data?.educationList[0]?.dateFinished === undefined)
    ) {
      dispatch(push(routes.contractor.newMember.experience));
    } else {
      dispatch(setLoading(true));
      const educationParams = data?.educationList?.map((item) => {
        if (item?.endDateIsPresent) {
          item.dateFinished = new Date().toISOString();
        }
        return {
          id: item?.id,
          schoolName: item?.provider,
          degreeName: item?.name,
          endDate: item?.dateFinished || new Date().toISOString(),
          city: item?.city,
          country: item?.country,
          endDateIsPresent: item?.dateFinished ? item?.endDateIsPresent : true,
        };
      });
      if (deleteItems?.length > 0) {
        await EducationService.deleteEducation({
          ids: deleteItems,
        }).catch((e) => dispatch(setErrorMess(e)));
      }

      if (profile?.education?.length) {
        await EducationService.putEducation(educationParams)
          .then((res) => {
            dispatch(setProfileReducer({ ...profile, education: res }));
            dispatch(push(routes.contractor.newMember.experience));
          })
          .catch((e) => dispatch(setErrorMess(e)))
          .finally(() => dispatch(setLoading(false)));
      } else {
        await EducationService.postEducation(educationParams)
          .then((res) => {
            dispatch(setProfileReducer({ ...profile, education: res }));
            dispatch(push(routes.contractor.newMember.experience));
          })
          .catch((e) => dispatch(setErrorMess(e)))
          .finally(() => dispatch(setLoading(false)));
      }
    }
  };

  return (
    <Card className={classes.card}>
      <CardBody className={classes.cardBody}>
        <p className={classes.title}>Education &amp; Qualifications</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.content}>
            {fields?.map((field: any, index) => {
              return (
                <div key={field?.fieldID}>
                  {fields?.length > 1 && (
                    <div className={classes.btnDelete}>
                      <Button
                        onClick={() => handleDeleteItem(field?.id, index)}
                      >
                        <i className='fa fa-trash' />
                      </Button>
                    </div>
                  )}
                  <CustomInput
                    placeholder='Name of Qualification'
                    type='text'
                    autoComplete='off'
                    inputRef={register(`educationList.${index}.name`)}
                    errorMessage={
                      errors.educationList &&
                      errors.educationList[index]?.name?.message
                    }
                  />
                  <CustomInput
                    placeholder='Provider'
                    type='text'
                    autoComplete='off'
                    inputRef={register(`educationList.${index}.provider`)}
                    errorMessage={
                      errors.educationList &&
                      errors.educationList[index]?.provider?.message
                    }
                  />
                  <CustomCheckbox
                    content='I am currently in this education'
                    inputRef={register(
                      `educationList.${index}.endDateIsPresent`
                    )}
                    errorMessage={
                      errors.educationList &&
                      errors.educationList[index]?.endDateIsPresent?.message
                    }
                    className={clsx('my-2', classes.customCheckbox)}
                    onChange={() => handleChangeEndDateIsPresent(index)}
                  />
                  <CustomDatePicker
                    className={
                      watch(`educationList.${index}.endDateIsPresent`)
                        ? classes.disabled
                        : ''
                    }
                    readOnly={watch(`educationList.${index}.endDateIsPresent`)}
                    name={`educationList.${index}.dateFinished`}
                    control={control}
                    placeholder='Date Finished'
                    dateFormat='dd/MM/yyyy'
                    errorMessage={
                      errors.educationList &&
                      errors.educationList[index]?.dateFinished?.message
                    }
                  />
                  <Controller
                    name={`educationList.${index}.address`}
                    control={control}
                    render={({ field }) => (
                      <SearchLocationInput
                        {...field}
                        ref={null}
                        placeholder='Location'
                        errorMessage={
                          errors.educationList &&
                          errors.educationList[index]?.address?.message
                        }
                        value={field.value}
                        onChange={(value: any) => {
                          setValue(
                            `educationList.${index}.city`,
                            value?.address?.city
                          );
                          setValue(
                            `educationList.${index}.country`,
                            value?.address?.country
                          );
                          return field.onChange(
                            value?.address?.formattedAddress
                          );
                        }}
                      />
                    )}
                  />
                  {index !== fields.length - 1 && (
                    <hr className={classes.divider} />
                  )}
                </div>
              );
            })}
          </div>
          <div className={classes.buttonContainer}>
            <Button color='default' type='button' onClick={handleBack}>
              Back
            </Button>
            <Button
              type='button'
              className={classes.btnAddMore}
              onClick={() => append({})}
            >
              <span className='text-dark'>
                Add more
                <i className='fa fa-plus ml-2' />
              </span>
            </Button>
            <Button
              // color='primary'
              onClick={handleSkip}
              type='submit'
              style={{ backgroundColor: '#f2e822', color: 'black' }}
            >
              Skip
            </Button>
            <Button color='primary' type='submit'>
              Next
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
});

export default Education;
