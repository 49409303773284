import { API } from "aws-amplify";

export class ContractorEmailService {
  static async terminateContractEmail(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${id}/terminateemail`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
