import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Form } from 'reactstrap';
import { useSelector } from 'react-redux';
import UAParser from 'ua-parser-js';
import styles from "./styles.module.scss";

const FeedbackButton = () => {
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [feedbackType, setFeedbackType] = useState('');
  const [comment, setComment] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const { user } = useSelector((state) => state.user);
  const { setting } = useSelector((state) => state.employer);

  // Fetch the IP address
  useEffect(() => {
    async function fetchIp() {
      const ip = await fetchIpAddress();
      setIpAddress(ip);
    }
    fetchIp();
  }, []);

  const toggleModal = () => setOpen(!open);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const parser = new UAParser();
    const userAgentInfo = parser.getResult();
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
  
    // Prepare feedback data
    const feedbackData = {
      user: user,
      setting: setting,
      type: feedbackType,
      comment: comment,
      page: window.location.pathname,
      time: new Date().toISOString(),
      ip: ipAddress,
      deviceInfo: {
        browser: userAgentInfo.browser.name,
        browserVersion: userAgentInfo.browser.version,
        os: userAgentInfo.os.name,
        osVersion: userAgentInfo.os.version,
        deviceType: userAgentInfo.device.type || 'desktop',
        deviceVendor: userAgentInfo.device.vendor,
        screenResolution: screenResolution,
        isMobile: /Mobi|Android/i.test(navigator.userAgent)
      },
      device: navigator.userAgent
    };
  
    // Prepare the Adaptive Card message for Microsoft Teams
    const messagePayload = {
      type: "message",
      attachments: [
        {
          contentType: "application/vnd.microsoft.card.adaptive",
          content: {
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            type: "AdaptiveCard",
            version: "1.3",
            body: [
              {
                type: "TextBlock",
                size: "Medium",
                weight: "Bolder",
                text: "New Feedback Received",
                spacing: "Large"
              },
              {
                type: "TextBlock",
                size: "Medium",
                weight: "Bolder",
                text: "Feedback Details"
              },
              {
                type: "FactSet",
                facts: [
                  { title: "User:", value: `${feedbackData.user.email}` },
                  { title: "Type:", value: `${feedbackData.type}` },
                  { title: "IP Address:", value: `${feedbackData.ip}` },
                  { title: "Page:", value: `${feedbackData.page}` },
                  { title: "Browser:", value: `${feedbackData.deviceInfo.browser} ${feedbackData.deviceInfo.browserVersion}` },
                  { title: "OS:", value: `${feedbackData.deviceInfo.os} ${feedbackData.deviceInfo.osVersion}` },
                  { title: "Device Type:", value: `${feedbackData.deviceInfo.deviceType}` },
                  { title: "Screen Resolution:", value: `${feedbackData.deviceInfo.screenResolution}` },
                  { title: "Time:", value: `${feedbackData.time}` }
                ]
              },
              {
                type: "TextBlock",
                text: "Comment",
                size: "Medium",
                weight: "Bolder",
                spacing: "Large",
                separator: true
              },
              {
                type: "TextBlock",
                text: feedbackData.comment,
                wrap: true,
                spacing: "Small"
              }
            ]
          }
        }
      ]
    };
  
    try {
      let webhookUrl;

      switch (feedbackType) {
        case 'feature_request':
          webhookUrl = 'https://prod-12.australiasoutheast.logic.azure.com:443/workflows/586ab5d59d8d455aa7a3157150182e0e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=xBHUT42jLOTlWUPC_cRwTp-_lgAnJAFU716dvQsOyeI';
          break;
        case 'issue':
          webhookUrl = 'https://prod-30.australiasoutheast.logic.azure.com:443/workflows/915fd7fb2f364cdcac3391402e6a479c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=YgWHP-c3amoCYbMJ7dm886SD3CLNPYWrwyJWCSOj_2M';
          break;
        default: // Default to feedback
          webhookUrl = 'https://prod-03.australiasoutheast.logic.azure.com:443/workflows/6fa89cdf7c6c40de808cf772ed72585c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Znsdo3l02e3kCRA-4Cqs5wndwur7XJbYfgjrw0pao2k';
      }
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messagePayload),
      });
  
      if (response.ok) {
        console.log('Feedback sent to Teams successfully');
      } else {
        console.error('Failed to send feedback to Teams');
      }
    } catch (error) {
      console.error('Error sending feedback to Teams:', error);
    }
  
    // Clear form inputs
    setFeedbackType('');
    setComment('');

    // Close the modal and show "Thank you!" message
    setOpen(false);
    setShowThankYou(true);
  
    setTimeout(() => {
      setShowThankYou(false);
    }, 3000);
  };

  
 
  return (
    <div className={styles.feedbackContainer}>
      {/* Feedback Button */}
      <Button
        className={`${styles.feedbackButton} ${hovered || showThankYou ? styles.hovered : ''}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={toggleModal}
        style={{ opacity: showThankYou ? 1 : undefined }} /* Force opacity to 1 when "Thank you!" is showing */
      >
        {/* Speech bubble appears when hovered or after submission */}
        {(hovered || showThankYou) && (
          <div className={styles.speechBubble}>
            {showThankYou ? "Thank you!" : "Submit Feedback Here"}
          </div>
        )}
      </Button>

      {/* Feedback Modal */}
      <Modal isOpen={open} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Submit Feedback</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            
            <FormGroup>
              <Label for="feedbackType">Feedback Type</Label>
              <Input
                type="select"
                name="feedbackType"
                id="feedbackType"
                value={feedbackType}
                onChange={(e) => setFeedbackType(e.target.value)}
              >
                <option value="">Select Type</option>
                <option value="feature_request">Feature Request</option>
                <option value="feedback">Feedback</option>
                <option value="issue">Issue</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="comment">Comment</Label>
              <Input
                type="textarea"
                name="comment"
                id="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Write your feedback here..."
              />
            </FormGroup>
            <Button color="primary" type="submit" block>
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

// Function to fetch the IP address
async function fetchIpAddress() {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Failed to fetch IP address:', error);
    return 'IP fetch failed';
  }
}

export default FeedbackButton;
