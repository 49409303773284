/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  Container,
  CardHeader,
  CardFooter,
} from "reactstrap";
import { JobService } from 'services/Employer/Listing/Job';
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import * as yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Footer from "components/Footers/FooterTransparent";
import classes from "./styles.module.scss";
import CustomInput from "components/Common/CustomInput";
import CustomSelect from "components/Common/CustomSelect";
import CustomDatePicker from "components/Common/CustomDatePicker";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import routes from "routers/routes";
import FooterMobile from "components/Footers/FooterMobile";
import { ReducerType } from "redux/reducers";
import { JobOfferService } from "services/Contractor/Offer/Offer";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { ContractorTimesheetService } from "services/Contractor/Timesheet/Timesheet";
import { setAllTimesheetsReducer } from "redux/reducers/Contractor/actionTypes";
import { OrganizationService } from "services/Employer/NewMember/Organization";
import { useParams } from "react-router-dom";
import { calculateTimesheet, fCurrency } from "utils/price";
import { ITimesheet, JobOffers, Jobs } from "redux/reducers/Contractor";
import moment from "moment";
import { EmailTypes } from "models/Employer/Listings/Dashboard";

interface ITimesheetParams {
  timesheetId?: string;
}

export interface ITimesheetStatus {
  quantity: number;
  totalPrice: number;
}

interface Props {}

const AddEditTimesheet = memo((props: Props) => {
  const { timesheetId } = useParams<ITimesheetParams>();

  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);
  const { allOffers, allTimesheets } = useSelector(
    (state: ReducerType) => state.contractor
  );

  const schema = useMemo(() => {
    return yup.object().shape({
      client: yup.object().required("This field is required"),
      approver: yup.string(),
      weekEnding: yup
        .date()
        .typeError("Please enter a valid date")
        .required("This field is required"),
      hour: yup
        .number()
        .typeError("Please enter a number")
        .required("This field is required"),
    });
  }, []);

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const isInt = (number: number) => {
    return number % 1 === 0;
  };

  const isEdit = !isNaN(+timesheetId) && isInt(+timesheetId);
  const initialStatus: ITimesheetStatus = { quantity: 0, totalPrice: 0 };

  const [pending, setPending] = useState<ITimesheetStatus>(initialStatus);
  const [approved, setApproved] = useState<ITimesheetStatus>(initialStatus);
  const [paid, setPaid] = useState<ITimesheetStatus>(initialStatus);
  const [clientList, setClientList] = useState<any>([]);
  const [timesheetNotFound, setTimesheetNotFound] = useState<boolean>(false);
  const [disabledDates, setDisabledDates] = useState<Array<Date>>([]);

  const watchClient = watch("client");

  // GET CLIENT LIST & HANDLE ADD OR EDIT TIMESHEETS
  useEffect(() => {
    // asynchronous function to get client list
    const getClientList = async () => {
      if (allTimesheets && allOffers?.jobs && allOffers?.jobOffers) {
        // filter signed contracts & create employer ID list
        const jobList = allOffers.jobs;
        // const archived = jobList
        //   .filter((item) => item.isArchived)
        //   .map((item) => item.id);
        // console.log(allOffers.jobOffers)
        const jobOfferList = allOffers.jobOffers?.filter((item) => {
          if (!item.accepted) {
            // If the item is not accepted, immediately filter it out
            return false;
          }
          
          // Parse the endDate from the item
          const endDate = new Date(item.endDate);
        
          // Create a new Date object that adds 2 months to the endDate for the buffer
          const bufferDate = new Date(endDate);
          bufferDate.setMonth(bufferDate.getMonth() + 2);
        
          // Get today's date with time reset to 00:00:00 for consistent comparison
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          // console.log("Buffer Date:", bufferDate.toISOString(), "Today:", today.toISOString());
          // Check if the buffered endDate is greater than today
          return bufferDate > today;
        });
        // console.log(jobOfferList)
        const employerIdList = jobOfferList
          .filter(
            (value, index, array) =>
              array.findIndex((item) => item.employer === value.employer) ===
              index
          )
          ?.map((item) => item.employer);
        // console.log(employerIdList)
        // get employer information based on employer ID list
        const employerList = [];
        await Promise.all(
          employerIdList.map(
            async (item) =>
              await JobOfferService.getEmployerProfile(item)
                .then(async (profile) => {
                  await OrganizationService.getEmployerOrganization(item)
                    .then((organization) => {
                      employerList.push({ profile, organization });
                    })
                    .catch((e) => dispatch(setErrorMess(e)));
                })
                .catch((e) => dispatch(setErrorMess(e)))
          )
        );

        // create client list
        const result = [];
        // console.log(jobOfferList)
        jobOfferList.forEach((offer: JobOffers) => {
          const index = allTimesheets.findIndex(
            (item) =>
              item.jobOffer === offer.id && !item.pending && !item.approved
          );
          if (index === -1) {
            const job = jobList?.find((job: Jobs) => job.id === offer.job);
            const client = employerList.find(
              (employer) => employer.profile?.employerId === offer.employer
            );
            // console.log("here", job)
            const clientName = `${job?.title || ""} - ${job?.client || client?.organization?.name || ""}`;
            result.push({
              ...offer,
              name: clientName,
              approver: `${offer?.approvalName || ""}`,
            });
          }
        });
        setClientList(result);
        // console.log(clientList)
      }
    };

    // function to get a client to update
    const getAClient = async () => {
      if (allOffers?.jobs && allOffers?.jobOffers) {
        dispatch(setLoading(true));
        await ContractorTimesheetService.getOneTimesheet(user.id, timesheetId)
          .then(async (res) => {
            if (res?.approved) {
              setTimesheetNotFound(true);
            } else {
              await JobOfferService.getEmployerProfile(res?.employer)
                .then(async (profile) => {
                  await OrganizationService.getEmployerOrganization(
                    res?.employer
                  )
                    .then((organization) => {
                      const job = allOffers.jobs?.find(
                        (job) => job.id === res?.job
                      );
                      setValue("client", {
                        ...res,
                        name: `${job?.title || ""} - ${
                          organization?.name || ""
                        }`,
                        approver: `${profile.firstName || ""} ${
                          profile?.lastName || ""
                        }`,
                      });
                      setValue("weekEnding", new Date(res?.weekEnding));
                      setValue("hour", res?.hours);
                    })
                    .catch((e) => dispatch(setErrorMess(e)));
                })
                .catch((e) => dispatch(setErrorMess(e)));
            }
          })
          .catch((e) => {
            setTimesheetNotFound(true);
            dispatch(setErrorMess(e));
          })
          .finally(() => dispatch(setLoading(false)));
      }
    };

    // execute function
    if (isEdit && user && timesheetId) {
      getAClient();
    } else {
      getClientList();
    }

    // clean up useEffect()
    return () => {
      setClientList([]);
      setTimesheetNotFound(false);
    };
  }, [allOffers, allTimesheets, dispatch, isEdit, setValue, timesheetId, user]);

  // useEffect(() => {
  //   console.log({ allOffers, allTimesheets, isEdit, setValue, timesheetId, user });
  //   // Rest of the useEffect code...
  // }, [allOffers, allTimesheets, dispatch, isEdit, setValue, timesheetId, user]);
  

  useEffect(() => {
    // set disabled dates
    if (allTimesheets?.length && watchClient) {
      const tempAllTimesheets = allTimesheets.filter(
        (item) => item?.job === watchClient?.job
      );
      setDisabledDates(
        tempAllTimesheets.map((item) => new Date(item.weekEnding))
      );
    }
  }, [allTimesheets, watchClient]);

  // TIMESHEET STATUSES
  useEffect(() => {
    if (allTimesheets) {
      let pendingTotal = 0;
      let approvedTotal = 0;
      let paidTotal = 0;
      const pendingList = allTimesheets.filter(
        (item: ITimesheet) => item.pending
      );
      const approvedList = allTimesheets.filter(
        (item: ITimesheet) => item.approved && !item.paid
      );
      const paidList = allTimesheets.filter((item: ITimesheet) => item.paid);

      pendingList?.forEach((item: ITimesheet) => {
        pendingTotal += calculateTimesheet(item.hours, item.rateOffered);
      });
      approvedList?.forEach((item: ITimesheet) => {
        approvedTotal += calculateTimesheet(item.hours, item.rateOffered);
      });
      paidList?.forEach((item: ITimesheet) => {
        paidTotal += calculateTimesheet(item.hours, item.rateOffered);
      });

      setPending({ quantity: pendingList?.length, totalPrice: pendingTotal });
      setApproved({
        quantity: approvedList?.length,
        totalPrice: approvedTotal,
      });
      setPaid({ quantity: paidList?.length, totalPrice: paidTotal });
    }
  }, [allTimesheets]);

  // AUTOMATIC APPROVER
  useEffect(() => {
    if (watchClient) {
      setValue("approver", watchClient.approver);
    }
  }, [setValue, watchClient]);

  const handleDeleteTimesheet = () => {
    dispatch(setLoading(true));
    ContractorTimesheetService.deleteTimesheet(user?.id, timesheetId)
      .then(() => {
        dispatch(push(routes.contractor.timesheet.status));
        dispatch(
          setAllTimesheetsReducer(
            allTimesheets.filter((item) => item.id !== +timesheetId)
          )
        );
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  // VALIDATE FRIDAY AND TIMESHEET DATE REQUIREMENTS
  const filterTimesheetDate = (date: Date) => {
    const day = date.getDay();

    const momentTarget = moment(date);
    const momentToday = moment();

    const difference = momentToday.diff(momentTarget, "days");

    return day === 5 && difference <= 7 && difference >= -1;
  };

  const onSubmit = (data: FieldValues) => {
    // console.log(data)
    dispatch(setLoading(true));
    if (isEdit) {
      ContractorTimesheetService.putTimesheet(user?.id, timesheetId, {
        contractor: data?.client?.contractor,
        employer: data?.job?.orgadmin || data?.client?.employer,
        job: data?.client?.job,
        approver: data?.approver,
        hours: data?.hour,
        weekEnding: data?.weekEnding,
        jobOffer: data?.client?.jobOffer,
        rateOffered: data?.client?.rateOffered,
        contractorTitle: profile?.basicProfile?.title,
      })
        .then((res) => {
          let newList: ITimesheet[] = [...allTimesheets];
          newList.splice(
            allTimesheets?.findIndex((item: ITimesheet) => item.id === res?.id),
            1,
            {
              ...res,
              rateOffered: allOffers?.jobOffers.find(
                (item: JobOffers) => item.id === res?.jobOffer
              )?.rateOffered,
            }
          );
          dispatch(push(routes.contractor.timesheet.status));
          dispatch(setAllTimesheetsReducer(newList));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    } else {
      ContractorTimesheetService.postTimesheet(user?.id, {
        contractor: data?.client?.contractor,
        employer: data?.job?.orgadmin || data?.client?.employer,
        job: data?.client?.job,
        approver: data?.approver,
        hours: data?.hour,
        weekEnding: moment(data?.weekEnding).utcOffset(0, true).format(),
        jobOffer: data?.client?.id,
        rateOffered: data?.client?.rateOffered,
        contractorTitle: profile?.basicProfile?.title,
      })
        .then((res) => {
          ContractorTimesheetService.sendNewTimesheetEmail(res?.id, {
            type: EmailTypes.EMPLOYER_TIMESHEET_AWAITING,
          }).catch((err) => dispatch(setErrorMess(err)));
          dispatch(push(routes.contractor.timesheet.status));
          dispatch(
            setAllTimesheetsReducer([
              ...allTimesheets,
              {
                ...res,
                rateOffered: allOffers?.jobOffers.find(
                  (item: JobOffers) => item.id === res?.jobOffer
                )?.rateOffered,
              },
            ])
          );
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    }
  };

  return (
    <Fragment>
      <ScrollNavbar />
      <div
        className={clsx("page-header header-filter", classes.wrapper)}
        filter-color="yellow"
      >
        <div className={classes.header} />
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg32.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.container}>
            <Card className={classes.card}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <CardHeader className={classes.cardHeader}>
                  <div
                    className={clsx(
                      classes.headerItem,
                      classes.backgroundPending
                    )}
                    onClick={() =>
                      dispatch(
                        push(
                          routes.contractor.timesheet.status.replace(
                            ":status",
                            "pending"
                          )
                        )
                      )
                    }
                  >
                    <div className={classes.headerItemTitle}>
                      <p>PENDING</p>
                      <p>({pending.quantity})</p>
                    </div>
                    <p className={classes.headerItemPrice}>
                      {fCurrency(pending.totalPrice)}
                    </p>
                  </div>
                  <div className={classes.verticalDiviver} />
                  <div
                    className={clsx(
                      classes.headerItem,
                      classes.backgroundApproved
                    )}
                    onClick={() =>
                      dispatch(
                        push(
                          routes.contractor.timesheet.status.replace(
                            ":status",
                            "approved"
                          )
                        )
                      )
                    }
                  >
                    <div className={classes.headerItemTitle}>
                      <p>APPROVED</p>
                      <p>({approved.quantity})</p>
                    </div>
                    <p className={classes.headerItemPrice}>
                      {fCurrency(approved.totalPrice)}
                    </p>
                  </div>
                  <div className={classes.verticalDiviver} />
                  <div
                    className={clsx(classes.headerItem, classes.backgroundPaid)}
                    onClick={() =>
                      dispatch(
                        push(
                          routes.contractor.timesheet.status.replace(
                            ":status",
                            "paid"
                          )
                        )
                      )
                    }
                  >
                    <div className={classes.headerItemTitle}>
                      <p>PAID</p>
                      <p>({paid.quantity})</p>
                    </div>
                    <p className={classes.headerItemPrice}>
                      {fCurrency(paid.totalPrice)}
                    </p>
                  </div>
                </CardHeader>
                {!timesheetNotFound && (
                  <Fragment>
                    <CardBody className={classes.cardBody}>
                      <div className={classes.inputContainer}>
                        <p className={classes.inputTitle}>Week Ending:</p>
                        <CustomDatePicker
                          className={clsx(
                            classes.input,
                            !watchClient && classes.disabledTime
                          )}
                          placeholder="Please choose your week ending"
                          name="weekEnding"
                          control={control}
                          dateFormat="dd/MM/yyyy"
                          // filterDate={filterTimesheetDate}
                          minDate={moment(watchClient?.startDate).toDate()}
                          maxDate={moment(watchClient?.endDate).toDate()}
                          disabled={!watchClient}
                          excludeDates={disabledDates}
                          errorMessage={errors.weekEnding?.message}
                        />
                      </div>
                      <div className={classes.inputContainer}>
                        <p className={classes.inputTitle}>Client:</p>
                        <CustomSelect
                          className={classes.input}
                          placeholder="Please choose your client"
                          name="client"
                          control={control}
                          options={clientList}
                          errorMessage={errors.client?.message}
                          isDisabled={isEdit}
                        />
                      </div>
                      <div className={classes.inputContainer}>
                        <p className={classes.inputTitle}>Approver:</p>
                        <CustomInput
                          className={classes.input}
                          placeholder="Please choose a client first"
                          autoComplete="off"
                          inputRef={register("approver")}
                          errorMessage={errors.approver?.message}
                          readOnly
                        />
                      </div>
                      <div className={classes.inputContainer}>
                        <p className={classes.inputTitle}>Hours:</p>
                        <CustomInput
                          className={classes.input}
                          placeholder="Please enter the number of hours worked"
                          autoComplete="off"
                          inputRef={register("hour")}
                          errorMessage={errors.hour?.message}
                        />
                      </div>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      {isEdit && (
                        <Fragment>
                          <Button
                            className={classes.buttonDelete}
                            type="button"
                            size="lg"
                            onClick={handleDeleteTimesheet}
                          >
                            Delete
                          </Button>
                          <Button
                            className={classes.buttonSave}
                            type="submit"
                            size="lg"
                          >
                            Save
                          </Button>
                        </Fragment>
                      )}
                      {!isEdit && (
                        <Button
                          className={classes.buttonSubmit}
                          type="submit"
                          size="lg"
                        >
                          Submit
                        </Button>
                      )}
                    </CardFooter>
                  </Fragment>
                )}
                {timesheetNotFound && (
                  <CardBody className={classes.cardBody}>
                    This timesheet is approved or not available.
                  </CardBody>
                )}
              </Form>
            </Card>
          </Container>
        </div>
        <Footer className={classes.footer} />
      </div>
      <FooterMobile />
    </Fragment>
  );
});

export default AddEditTimesheet;
