import { memo } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import ScrollNavbar from "components/Navbars/Employer/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import classes from "./styles.module.scss";
import clsx from "clsx";
import routes from "routers/routes";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const Membership = memo(({}: Props) => {
  const lists = [
    {
      title: "Free Tier",
      icon: "tech_headphones",
      iconType: "icon-info",
      price: "0",
      content: [
        "One Active Search",
        "Message Contractors",
        "Send Necta Contracts",
        "Send Necta Contracts",
      ],
      buttonContent: "Manage",
    },
    {
      title: "Member",
      icon: "business_badge",
      iconType: "icon-warning",
      price: "90",
      content: [
        "All member plus..",
        "Ten Active Search",
        "Timesheet Management",
        "Billing and Payroll $2 p.h",
        "Management Report",
      ],
      buttonContent: "Upgrade",
    },
    {
      title: "Champion Member",
      icon: "objects_diamond",
      iconType: "icon-danger",
      price: "640",
      content: [
        "All member plus..",
        "Unlimited Search",
        "$1 per hour payroll",
        "Trend analysis",
        "AI Predictive Modelling",
      ],
      buttonContent: "Upgrade",
    },
  ];
  
  return (
    <>
      <ScrollNavbar name="Manage" to={routes.employer.manage.root} />
      <div
        className={clsx("page-header header-filter", classes.pageHeader)}
        filter-color="yellow"
      >
        <div
          className={clsx("page-header-image", classes.pageHeaderImg)}
          style={{
            backgroundImage: "url(" + require("assets/img/project16.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container>
            <Row className={clsx(classes.cardWrapper, "pt-2")}>
              {lists.map((item, index) => (
                <Col lg="4" className={classes.cardItem} key={index}>
                  <Card
                    className={clsx(
                      "card-pricing mb-0",
                      classes.cardItemWrapper
                    )}
                  >
                    <CardBody className="pb-0">
                      <h5 className={clsx("category", classes.black)}>
                        {item.title}
                      </h5>
                      <div className={`icon ${item.iconType}`}>
                        <i className={`now-ui-icons ${item.icon}`}></i>
                      </div>
                      <CardTitle tag="h3" className={classes.black}>
                        ${item.price} Per Month
                      </CardTitle>
                      <ul>
                        {item.content.map((itemContent) => (
                          <li>{itemContent}</li>
                        ))}
                      </ul>
                    </CardBody>
                    <CardFooter className="mt-0 mb-2">
                      <Button
                        className="btn-round"
                        color="primary"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        {item.buttonContent}
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
});

export default Membership;
