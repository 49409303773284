import React, { useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Table
} from 'reactstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import classes from './styles.module.scss';
import { jobBoards } from './jobBoardData';
import { JobService } from 'services/Employer/Listing/Job';

const PaymentModal = ({ isOpen, jobBoardsToPayFor, onProceed, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);

  // Get the job boards data to display
  const boardsToPayFor = jobBoards.filter((board) =>
    jobBoardsToPayFor.includes(board.id)
  );

  // Calculate total amount
  const totalAmount = boardsToPayFor.reduce((sum, board) => {
    const price = parseFloat(board.price.replace('$', ''));
    return sum + price;
  }, 0);

  //needs to add gst if new zealand and not if not. 

  const handlePaymentSubmit = async () => {
    setProcessing(true);

  // Calculate total amount and add GST
  const totalAmountExclGST = boardsToPayFor.reduce((sum, board) => {
    const price = parseFloat(board.price.replace('$', ''));
    return sum + price;
  }, 0);

  const totalAmountInclGST = (totalAmountExclGST * 1.15).toFixed(2); // Adding 15% GST
  
    try {
      // Prepare items with names and prices
      const itemsToSend = boardsToPayFor.map((board) => ({
        name: board.name,
        price: parseFloat(board.price.replace('$', '')).toFixed(2), // Price in dollars as a string
      }));
  
      // Calculate total amount
      const totalAmountInclGST = itemsToSend.reduce((sum, item) => sum + parseFloat(item.price), 0).toFixed(2);
  
      // Create PaymentIntent on the server
      const response = await JobService.postIntent({
        items: itemsToSend,
        amount: totalAmountInclGST,
      });
  
      // Access clientSecret directly from response
      const { clientSecret, error: serverError } = response;
  
      if (serverError) {
        setError(`Server error: ${serverError}`);
        setProcessing(false);
        return;
      }
  
      // Confirm the payment on the client
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
  
      if (payload.error) {
        setError(`Payment failed: ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        // Payment successful, show thank you message
        setShowThankYou(true);
      }
    } catch (err) {
      setError(`Payment failed: ${err.message}`);
      setProcessing(false);
    }
  };
  

  if (showThankYou) {
    return (
      <Modal isOpen={isOpen} toggle={onCancel} centered size="lg">
        <ModalHeader toggle={onCancel}>Payment Successful</ModalHeader>
        <ModalBody className={classes.modalBody}>
          <p>Thank you for your payment! Your upgrades will take effect when your job goes live.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              onProceed(); // Proceed with saving the job
              setShowThankYou(false); // Reset the thank you message
            }}
          >
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered size="lg">
      <ModalHeader toggle={onCancel}>Payment Required</ModalHeader>
      <ModalBody className={classes.modalBody}>
        <p>
          Upgrades can be paid for in advance. When your job goes live, they will take effect.
          If you did not mean to upgrade, you can press cancel, and your opportunity will still
          be saved without upgrades.
        </p>
        <Table className={classes.table}>
          <thead>
            <tr>
              <th>Job Board</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            {boardsToPayFor.map((board) => (
              <tr key={board.id}>
                <td>{board.name}</td>
                <td>{board.price}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <p className={classes.totalAmount}>
          <strong>Total Amount:</strong> ${totalAmount.toFixed(2)} excl GST
        </p>
        {/* Stripe CardElement */}
        <div className={classes.cardElement}>
          <CardElement options={{ hidePostalCode: true }} />
        </div>
        {error && <div className={classes.errorMessage}>{error}</div>}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handlePaymentSubmit}
          disabled={!stripe || processing}
        >
          {processing ? 'Processing...' : 'Pay Now'}
        </Button>
        <Button color="secondary" onClick={onCancel} disabled={processing}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PaymentModal;
