export const ExpenseTypes = [
    {
        id: 1,
        name: "Vehicle expense", 
        value: "vehicleExpense"
    },
    {
        id: 2,
        name: "Transport costs", 
        value: "transportCosts"
    },
    {
        id: 3,
        name: "Travel", 
        value: "travel"
    },
    {
        id: 4,
        name: "Rent", 
        value: "rent"
    },
    {
        id: 6,
        name: "Interest", 
        value: "interest"
    },
    {
        id: 7,
        name: "Insurance", 
        value: "insurance"
    },
    {
        id: 8,
        name: "Work-related journals and magazines", 
        value: "workRelatedJournalsAndMagazines"
    },
    {
        id: 9,
        name: "Membership of professional associations", 
        value: "membershipOfProfessionalAssociations"
    },
    {
        id: 10,
        name: "Home office expenses", 
        value: "homeOfficeExpenses"
    },
    {
        id: 11,
        name: "Mobile phones", 
        value: "mobilePhones"
    },
    {
        id: 12,
        name: "Phone bills", 
        value: "phoneBills"
    },
    {
        id: 13,
        name: "Stationery", 
        value: "stationery"
    },
    {
        id: 14,
        name: "Tax agent's fees", 
        value: "taxAgentsFees"
    }
]