import numeral from "numeral";
import { COMMISSION_RATE, TAX_RATE } from "config/constants";

export function calculateTimesheet(hour, rateOffered) {
  const total = (+rateOffered + COMMISSION_RATE) * +hour;
  const tax = (total * TAX_RATE) / 100;
  return total + tax;
}

export function calculateTimesheetNetContractor(hour, rateOffered) {
  const total = (+rateOffered) * +hour;
  return total;
}

export function fCurrency(number: number | string) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}
