import { useSelector } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { ReducerType } from "redux/reducers";
import routes from "./routes";

interface IEmployerAuthRoute extends RouteProps {}

const EmployerAuthRoute = ({
  path,
  component: Component,
  render,
  ...rest
}: IEmployerAuthRoute) => {
  const { user } = useSelector((state: ReducerType) => state.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: routes.employer.login,
              }}
            />
          );
        } else if(user?.role === 1) {
          return (
            <Redirect
              to={{
                pathname: routes.contractor.profile,
              }}
            />
          );
        }
        return render ? render(props) : <Component {...props} />;
      }}
    />
  );
};

export default EmployerAuthRoute;
