import { Suspense, useEffect, useState } from "react";
import { Route, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { routes } from "./routes";
import LoadingScreen from "components/Common/LoadingScreen";
import ContractorSignup from "views/Contractor/Signup";
import ContractorConfirmVerificationCode from "views/Contractor/ConfirmVerificationCode";
import ContractorLogin from "views/Contractor/Login";
import EmployerSignup from "views/Employer/Signup";
import EmployerConfirmVerificationCode from "views/Employer/ConfirmVerificationCode";
import EmployerLogin from "views/Employer/Login";
import DocumentHistory from "views/Contractor/DocumentHistory";
import AuthCallback from "components/Common/AuthCallback";
import ContractorNewMember from "views/Contractor/NewMember";
import Profile from "views/Contractor/Profile";
import ContractorSetting from "views/Contractor/Setting";
import ContractorMessenger from "views/Contractor/Messenger";
import Offer from "views/Contractor/Offer";
import AddEditTimesheet from "views/Contractor/AddEditTimesheet";
import Payslip from "views/Contractor/Payslip";
import Expense from "views/Contractor/Expense";
import ContractorTimesheet from "views/Contractor/Timesheet";
import Membership from "views/Employer/Membership";
import EmployerNewMember from "views/Employer/NewMember";
import Manage from "views/Employer/Manage";
import Listing from "views/Employer/Listing";
import Listing2 from "views/Contractor/Listing";
import JobContractorProfile from "views/Employer/JobContractorProfile";
import ContractorAuthRoute from "./ContractorAuthRoute";
import EmployerAuthRoute from "./EmployerAuthRoute";
import { useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import { ChatEngineWrapper } from "react-chat-engine";
import ContractorForgotPassword from "views/Contractor/ForgotPassword";
import EmployerForgotPassword from "views/Employer/ForgotPassword";
import Invoice from "views/Contractor/Invoice";
import Report from "views/Contractor/Report";
import Payroll from "views/Contractor/Payroll";
import Accountant from "views/Employer/Accountant";
import ContractorDashboard from "views/Contractor/Dashboard";
import Jobs from "views/Contractor/Jobs";
import Admin from "views/Employer/admin";

const Routers = () => {
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);
  const history = useHistory();
  const location = useLocation();
  const [orgId, setOrgId] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad && location.pathname === "/") {
      const host = window.location.hostname;
      const subdomain = host.split('.')[0];

      // For local testing, check a query parameter or path segment
      const searchParams = new URLSearchParams(location.search);
      const localSubdomain = searchParams.get('subdomain') || (host === 'localhost' ? 'local' : subdomain);

      // Define a map for both hostnames and subdomains
      const domainToOrgIdMap = {
        'ats.mikes.co.nz': 235,
        'local': 311, // Example for local testing
        // Add more mappings here
      };

      // Check for both full hostname and subdomain in the map
      const orgId = domainToOrgIdMap[host] || domainToOrgIdMap[localSubdomain];
      if (orgId) {
        history.push(`/employer/login/${orgId}`);
      } else {
        history.push(routes.employer.login);
      }
    }
    setInitialLoad(false);
  }, [location, initialLoad, history]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {/* -------------------------------------- COMMON ROUTES -------------------------------------- */}
        <Route path="/auth-callback" component={AuthCallback} />
        {/* -------------------------------------- CONTRACTOR -------------------------------------- */}
        <Route path={routes.contractor.signup} render={(props) => <ContractorSignup {...props} />} />
        <Route path={routes.contractor.confirmVerificationCode} render={(props) => <ContractorConfirmVerificationCode {...props} />} />
        <Route path={routes.contractor.login} render={(props) => <ContractorLogin {...props} />} />
        <Route path={routes.contractor.forgotPassword} render={(props) => <ContractorForgotPassword {...props} />} />
        <ContractorAuthRoute path={routes.contractor.newMember.root} render={(props) => <ContractorNewMember {...props} />} />
        <ContractorAuthRoute path={routes.contractor.profile} render={(props) => <Profile {...props} />} />
        <ContractorAuthRoute path={routes.contractor.setting} render={(props) => <ContractorSetting {...props} />} />
        <ContractorAuthRoute
          path={routes.contractor.messenger}
          render={(props) => (
            <ChatEngineWrapper>
              <ContractorMessenger {...props} />
            </ChatEngineWrapper>
          )}
        />
        <ContractorAuthRoute path={routes.contractor.offer} render={(props) => <Offer {...props} />} />
        <ContractorAuthRoute path={routes.contractor.jobs} render={(props) => <Jobs {...props} />} />
        <ContractorAuthRoute path={routes.contractor.addEditTimesheet} render={(props) => <AddEditTimesheet {...props} />} />
        <ContractorAuthRoute path={routes.contractor.dashboard} render={(props) => <ContractorDashboard {...props} />} />
        <ContractorAuthRoute path={routes.contractor.timesheet.edit} render={(props) => <AddEditTimesheet {...props} />} />
        <ContractorAuthRoute path={routes.contractor.timesheet.status} render={(props) => <ContractorTimesheet {...props} />} />
        <ContractorAuthRoute path={routes.contractor.timesheet.root} render={(props) => <ContractorTimesheet {...props} />} />
        <ContractorAuthRoute path={routes.contractor.invoice.root} render={(props) => <Invoice {...props} />} />
        <ContractorAuthRoute path={routes.contractor.expense.root} render={(props) => <Expense {...props} />} />
        <ContractorAuthRoute path={routes.contractor.payslip} render={(props) => <Payslip {...props} />} />
        <ContractorAuthRoute path={routes.contractor.report} render={(props) => <Report {...props} />} />
        <ContractorAuthRoute path={routes.contractor.listing.root} render={(props) => <Listing2 {...props} />} />
        {profile && profile?.taxDetails && profile?.taxDetails?.isPaye && <ContractorAuthRoute path={routes.contractor.payroll} render={(props) => <Payroll {...props} />} />}
        <ContractorAuthRoute path={routes.contractor.documentHistory} render={(props) => <DocumentHistory {...props} />} />
        {/* -------------------------------------- EMPLOYER -------------------------------------- */}
        <Route path={routes.employer.signup} render={(props) => <EmployerSignup {...props} />} />
        <Route path={routes.employer.confirmVerificationCode} render={(props) => <EmployerConfirmVerificationCode {...props} />} />
        <Route exact path={routes.employer.login} render={(props) => <EmployerLogin {...props} />} />
        <Route path="/employer/login/:organisationID" render={(props) => <EmployerLogin {...props} />} />
        <Route path={routes.employer.forgotPassword} render={(props) => <EmployerForgotPassword {...props} />} />
        <EmployerAuthRoute path={routes.employer.newMember.root} render={(props) => <EmployerNewMember {...props} />} />
        <EmployerAuthRoute path={routes.employer.membership} render={(props) => <Membership {...props} />} />
        {/* <EmployerAuthRoute path={routes.employer.manage.root} render={(props) => <Manage {...props} />} />
        <EmployerAuthRoute path={routes.employer.listing.root} render={(props) => <Listing {...props} />} /> */}
        <EmployerAuthRoute path={routes.employer.admin.root} render={(props) => <Admin {...props} />} />
        <EmployerAuthRoute path={routes.employer.accountant} render={(props) => <Accountant {...props} />} />
        <EmployerAuthRoute path={routes.jobs.contractorProfile} render={(props) => <JobContractorProfile {...props} />} />


        {user?.type === "employer" && <Redirect to={routes.employer.admin.dashboard} />}
        {user?.type === "contractor" && <Redirect to={routes.contractor.profile} />}
        {/* {orgId ? (
          <Route path="/employer/login/:organisationID" render={(props) => <EmployerLogin {...props} />} />
        ) : (
          <Redirect to={routes.contractor.login} />
        )} */}
      </Switch>
    </Suspense>
  );
};

export default Routers;