import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_JOB_TEMPLATES_LIST_REQUEST,
  setJobTemplatesListReducer,
} from "redux/reducers/Employer/actionTypes";
import { JobService } from "services/Employer/Listing/Job";

function* requestGetJobTemplatesList() {
  try {
    yield put(setLoading(true));
    // console.log("called")
    const [jobTemplatesList] = yield all([call(JobService.getJobTemplates)]);
    yield put(setJobTemplatesListReducer(jobTemplatesList));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getJobTemplatesList() {
  // console.log("jobs calling")
  yield takeLatest(GET_JOB_TEMPLATES_LIST_REQUEST, requestGetJobTemplatesList);
}

export default getJobTemplatesList;
