import React, { Fragment, memo, useState } from 'react';
import { Container, Card, CardBody, CardTitle, CardText, Collapse } from 'reactstrap';
import FooterTransparentEmployer from 'components/Footers/FooterTransparentEmployer';
import classes from './styles.module.scss';
import clsx from 'clsx';
import TinderCard from 'react-tinder-card';
import Advanced from './tinder'

interface DashboardProps {}

const Dashboard = memo((props: DashboardProps) => {
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [jobs, setJobs] = useState(
    [
      {
        "id": 1,
        "title": "Software Engineer",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "companyLogo": "(link unavailable)",
        "companyName": "Company 1",
        "type": "Full-time",
        "location": {
          "city": "New York",
          "countryCode": "USA"
        },
        "remoteType": "Office",
        "salaryType": "Annual",
        "salary": 120000,
        "currency": "USD",
        "shortDescription": "Short description of the job.",
        "companyDescription": "Company description.",
        "fullDescription": "Full job description.",
        "responsibilities": "Job responsibilities."
      },
      {
        "id": 2,
        "title": "Product Designer",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "companyLogo": "(link unavailable)",
        "companyName": "Company 2",
        "type": "Contract",
        "location": {
          "city": "London",
          "countryCode": "UK"
        },
        "remoteType": "Work from home",
        "salaryType": "Hourly",
        "salary": 50,
        "currency": "GBP",
        "shortDescription": "Short description of the job.",
        "companyDescription": "Company description.",
        "fullDescription": "Full job description.",
        "responsibilities": "Job responsibilities."
      },
      {
        "id": 3,
        "title": "Marketing Manager",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "companyLogo": "(link unavailable)",
        "companyName": "Company 3",
        "type": "Fixed-term",
        "location": {
          "city": "Paris",
          "countryCode": "FRA"
        },
        "remoteType": "Hybrid",
        "salaryType": "Annual",
        "salary": 80000,
        "currency": "EUR",
        "shortDescription": "Short description of the job.",
        "companyDescription": "Company description.",
        "fullDescription": "Full job description.",
        "responsibilities": "Job responsibilities."
      }
    ])

  const onSwipe = (direction) => {
    setSwipeDirection(direction);
    if (direction === 'right') {
      console.log('Apply');
    } else if (direction === 'left') {
      console.log('Decline');
    } else if (direction === 'up') {
      // Expand for more information
    }
  };
  

  const JobCard = ({ job }) => {
    return (
      
      <TinderCard onSwipe={onSwipe} className="swipe">
        <div className={`hover-effect ${swipeDirection === 'up' ? 'expanded' : ''}`}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Card
            style={{
              position: 'relative',
              width: '100%',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
              borderRadius: '20px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: '#333',
            }}
          >
            <CardBody style={{ 
              width: '100%',
              height: '100%',
             }}>
              <div className="company-logo">
                <img src={job.companyLogo} alt={job.companyName} />
              </div>
              <CardTitle>
                {job.title} ({job.type}) - {job.location.city}, {job.location.countryCode}
              </CardTitle>
              <CardText>
                {job.remoteType} - {job.salaryType} {job.salary} {job.currency}
              </CardText>
              <CardText>
                {job.shortDescription}
              </CardText>
            </CardBody>
            <Collapse isOpen={swipeDirection === 'up'}>
              <CardBody>
                <CardText>
                  {job.companyDescription}
                </CardText>
                <CardText>
                  {job.fullDescription}
                </CardText>
                <CardText>
                  {job.responsibilities}
                </CardText>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </TinderCard>
    );
  };

  const JobList = () => {
    return (
      <div className="job-list" style={{
        position: 'relative',
        height: '40vh',
        overflow: 'hidden', // Adjust as necessary based on your design
        // backgroundColor: 'red',
        zIndex: 1,
      }}>
        {jobs.map((job) => (
          <JobCard key={job.id} job={job} />
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <div className={clsx('page-header header-filter', classes.pageHeader)}>
        <div className='page-header-image' style={{ backgroundImage: 'url(' + require('assets/img/bg24.jpg') + ')' }} />
        <div className={clsx('content', classes.content)}>
          <Container className={classes.container}>
            {/* <JobList /> */}
            <Advanced />
          </Container>
        </div>
        {/* <FooterTransparentEmployer /> */}
      </div>
    </Fragment>
  );
});

export default Dashboard;
