import React, { useEffect, memo, useRef, useState } from "react";
import { Container, Form, FormGroup, Label, Input, Button, Card, CardBody, CardTitle, CardText, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import dagreD3 from 'dagre-d3';
import * as d3 from 'd3';
import classes from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faEnvelope, faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';
import logo from "assets/img/logo.png";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import { ImageService } from "services/Image";
import { ReducerType } from "redux/reducers";
import { OrganizationService } from "services/Employer/NewMember/Organization";


interface Props {}

const OrganizationChart: React.FC<Props> = memo((props: Props) => {
  const { setting } = useSelector((state: ReducerType) => state.employer);
  const [showSidebar, setShowSidebar] = useState(true); // New state to control sidebar visibility
  const [initialImage, setInitialImage] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const imageKey = `${setting?.idOrganisation}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`;
      try {
        if (imageKey) {
          const imageUrl = await ImageService.getCompanyAvatar(imageKey);
          const imageFile = blobToFile(imageUrl, "avatar.png");
          setInitialImage(URL.createObjectURL(imageFile));
        } else {
          setInitialImage(logo);
        }
      } catch (error) {
        console.error("Failed to load initial image", error);
        setInitialImage(logo);
      }
    };
  
    fetchImage();
  }, [setting]);


  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await OrganizationService.getOrganizationChart();
  
        // Function to recursively process employees and their reports
        const processEmployee = async (employee) => {
          try {
            // Only fetch the image if the employee has a photo and identity_id
            if (employee.photo && employee.identity_id) {
              const photoUrl = await ImageService.getImage(employee.photo, employee.identity_id);
              employee.photo = photoUrl; // Set the fetched photo URL
            } else {
              employee.photo = null; // Set to null or a placeholder if no photo is available
            }
          } catch (error) {
            // Handle image fetching error silently
            employee.photo = null; // Fallback to null or a placeholder image if fetching fails
          }
  
          // Recursively process reports (if any)
          if (employee.reports && employee.reports.length > 0) {
            employee.reports = await Promise.all(employee.reports.map(processEmployee));
          }
  
          return employee;
        };
  
        // Process top-level employees (and their reports recursively)
        const orgDataWithImages = await Promise.all(response.map(processEmployee));
  
        setData(orgDataWithImages); // Update state with the processed data
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
  
    fetchOrgData();
  }, []);
  
  
  
  const [expandedNodes, setExpandedNodes] = useState({ 1: true });
  const [selectedNode, setSelectedNode] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newNodeData, setNewNodeData] = useState({
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phone: '',
    city: '',
    country: ''
  });
  //  const [data, setData] = useState(dummyData);




  const toggleNode = (nodeId) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [nodeId]: !prevState[nodeId],
    }));
  };

  const toggleModal = () => {
    setShowAddForm(!showAddForm);
    setShowSidebar(false); // Hide the sidebar when modal opens
  };

  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current || !data) return; // Ensure data exists
    
    const g = new dagreD3.graphlib.Graph().setGraph({ rankdir: 'LR' });
  
    const addNodesAndEdges = (g, node) => {
      if (!node) return; // Safety check in case node is null or undefined
      const isExpanded = expandedNodes[node.id] !== false;
  
      g.setNode(node.id, {
        labelType: "html",
        label: `
          <div class="node-container" data-node-id="${node.id}" style="display: flex; align-items: center; padding: 15px; width: 300px; position: relative; overflow: visible;">
            <img src="${node.photo ? node.photo : initialImage}" alt="${node.firstName} ${node.lastName}" style="width: 60px; height: 60px; border-radius: 50%; object-fit: cover; margin-right: 15px;">
            <div style="text-align: left; max-width: 50%; word-break: break-word; white-space: normal;">
              <strong style="font-size: 16px;">${node.firstName} ${node.lastName}</strong><br/>
              <span style="font-size: 14px; color: #666;">${node.title}</span>
            </div>
            ${node.reports.length > 0 
              ? `
                  <div style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);">
                    <button class="toggle-button" data-node-id="${node.id}" 
                      style="background-color: ${isExpanded ? '#f44336' : '#4CAF50'}; 
                      border: none; border-radius: 50%; color: white; font-size: 20px; 
                      width: 30px; height: 30px; cursor: pointer;">
                      <strong>${isExpanded ? '-' : '+'}</strong>
                    </button>
                  </div>`
              : ''
            }
          </div>
        `,
        width: 300,
        height: 100,
        style: "fill: #fff; stroke: #ccc; stroke-width: 1px; rx: 25; ry: 25;",
        shape: "rect",
      });
  
      if (isExpanded && node.reports.length > 0) {
        node.reports.forEach((report) => {
          addNodesAndEdges(g, report);
          g.setEdge(node.id, report.id, { style: "stroke: black; stroke-width: 2px;" });
        });
      }
    };
  
    // Now run addNodesAndEdges with the root data
    if (data) {
      addNodesAndEdges(g, data[0]); // Assuming you want to pass the first item
    }
  
    const render = new dagreD3.render();
    const svg = d3.select(svgRef.current as any);
    svg.selectAll("*").remove();
    const inner = svg.append("g");
    render(inner, g);
  
    d3.selectAll(".toggle-button").on("click", function (event) {
      event.preventDefault();
      event.stopPropagation();
      const nodeId = d3.select(this).attr("data-node-id");
      toggleNode(parseInt(nodeId, 10));
    });
  
    d3.selectAll(".node-container").on("click", function () {
      const nodeId = d3.select(this).attr("data-node-id");
      const nodeData = findNodeById(data[0], parseInt(nodeId, 10)); // Ensure you pass correct root node
      setSelectedNode(nodeData);
      setShowSidebar(true);
      setShowAddForm(false);
    });
  
    const zoom = d3.zoom().on("zoom", (event) => {
      svg.select("g").attr("transform", event.transform);
    });
    svg.call(zoom as any);
  
    return () => {
      svg.on(".zoom", null);
      svg.selectAll("*").remove();
    };
  }, [expandedNodes, data]);
  
  

  const findNodeById = (node, id) => {
    if (node.id === id) return node;
    for (let report of node.reports) {
      const found = findNodeById(report, id);
      if (found) return found;
    }
    return null;
  };

  const handleAddDirectReport = () => {
    setShowAddForm(true);
    setShowSidebar(false); // Ensure the sidebar is hidden when adding a direct report
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const newNode = { 
      ...newNodeData, 
      id: Date.now(), 
      reports: [], 
      name: `${newNodeData.firstName} ${newNodeData.lastName}` 
    };
    const updatedData = { ...data };
    const parentNode = findNodeById(updatedData, selectedNode.id);
    parentNode.reports.push(newNode);
    setData(updatedData);
    setShowAddForm(false);
    setSelectedNode(parentNode); // Set the parent node again after adding the direct report
    // setShowSidebar(true); // Reopen the sidebar
  };
  

  const handleFormChange = (e) => {
    setNewNodeData({ ...newNodeData, [e.target.name]: e.target.value });
  };

  return (
    <Container fluid className={classes.mainContainer}>
      {/* <div className={classes.header}>Organization Chart</div> */}

      <svg width="100%" height="100%" ref={svgRef}>
        <g />
      </svg>

      {selectedNode && showSidebar && (
        <div className={`${classes.sidebar} ${classes.sidebarOpen}`}>
          <Card className={classes.profileCard}>
            <button className={classes.closeButton} onClick={() => setShowSidebar(false)}>X</button>
          <CardBody className={classes.profileCardBody}>
            <div className={classes.profileImageWrapper}>
              <img 
                src={selectedNode.photo ? selectedNode.photo : initialImage} 
                alt={`${selectedNode.firstName} ${selectedNode.lastName}`} 
                className={classes.profilePhoto} 
              />
            </div>
            <CardTitle tag="h2" className={classes.profileName}>
              {`${selectedNode.firstName} ${selectedNode.lastName}`}
            </CardTitle>
            
            <div className={classes.profileDetails}>
              <p className={classes.profileTitle}>
                <strong>{selectedNode.title}</strong>
              </p>

              <div className={classes.contactInfo}>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} /> {selectedNode.email}
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} /> {selectedNode.phone}
                </p>
                <p>
                  <FontAwesomeIcon icon={faMapMarkerAlt} /> {`${selectedNode.city}, ${selectedNode.country}`}
                </p>
              </div>
            </div>

            <div className={classes.profileBio}>
              <h4>About</h4>
              <p>{selectedNode.bio}</p>
            </div>


            <Button color="primary" onClick={toggleModal}>
              See Profile
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Add Direct Report
            </Button>
          </CardBody>

          </Card>
        </div>
      )}

      <Modal isOpen={showAddForm} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Add Direct Report</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleFormSubmit}>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input type="text" name="firstName" placeholder="First Name" onChange={handleFormChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input type="text" name="lastName" placeholder="Last Name" onChange={handleFormChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input type="text" name="title" placeholder="Title" onChange={handleFormChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="city">City</Label>
              <Input type="text" name="city" placeholder="City" onChange={handleFormChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="country">Country</Label>
              <Input type="text" name="country" placeholder="Country" onChange={handleFormChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="email" placeholder="Email" onChange={handleFormChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input type="tel" name="phone" placeholder="Phone" onChange={handleFormChange} required />
            </FormGroup>
            <Button color="primary" type="submit">Submit</Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <FontAwesomeIcon icon={faSearchPlus} className={classes.scrollIcon} />
    </Container>
  );
});

export default OrganizationChart;
