import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes';
import { ReducerType } from 'redux/reducers';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, Tooltip, CardBody, Row, Col, CardTitle, Container, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Button, CustomInput } from 'reactstrap';
import classes from './styles.module.scss';
import { useSwipeable } from 'react-swipeable';
import SetInterviewDateTime from '../SetInterviewDateTime';
import SendContract from '../SendContract';
import { JobService } from "services/Employer/Listing/Job";
import { ListingEmailService } from "services/Employer/Listing/Email";
import { EmailTypes } from "models/Employer/Listings/Dashboard";
import { push } from 'connected-react-router';
import { downloadCV } from 'components/docxtemplater/downloadCv';
import { useModal } from 'components/Modals';
import { setUserOfChatReducer } from 'redux/reducers/Message/actionTypes';
import routes from 'routers/routes';
import { useWebSocket } from 'services/Message/User/WebSocketProvider.js';
import { useHistory } from 'react-router-dom';
import SeeContract from '../SeeContract';
import { ImageService } from "services/Image";

interface JobParams {
  jobId: string;
}

interface Task {
  id: string;
  content: string;
  yearsExperience: number;
  jobTitle: string;
  lastJob: string;
  location: string;
  workStyle: string;
  rate: string;
  salary: string;
  summary: string;
  approved: boolean;
  image: string;
  scores: number;
  source: string;
  mobile: number;
  email: string;
  social: string;
  awaitingAcceptance?: boolean;
  offerRejected?: boolean;
  interviewDetails?: Array<{
    id: number;
    accepted: boolean;
    additionalEmails: string;
    contractor: number;
    employer: number;
    interviewAddress: string;
    interviewLink: string | null;
    interviewTime: string;
    job: number;
    offered: boolean;
    rejected: boolean;
    revoked: boolean;
    title: string | null;
    type: string;
  }>;
}

const initialData = {
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'Long List',
      taskIds: [] as string[],
    },
    'column-2': {
      id: 'column-2',
      title: 'Shortlist',
      taskIds: [] as string[],
    },
    'column-3': {
      id: 'column-3',
      title: 'Interview',
      taskIds: [] as string[],
    },
    'column-4': {
      id: 'column-4',
      title: 'Contract Offer',
      taskIds: [] as string[],
    },
    'column-5': {
      id: 'column-5',
      title: 'Archive',
      taskIds: [] as string[],
    },
  },
  tasks: {} as Record<string, Task>,
  columnOrder: ['column-5', 'column-1', 'column-2', 'column-3', 'column-4'],
};

function ensureProtocol(url) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`; // Default to http if no protocol is specified
  }
  return url;
}


const getSourceDisplayName = (source) => {
  switch (source) {
    case 'applied':
      return 'Application';
    case 'headhunter':
      return 'Nelly';
    case 'necta':
      return 'Necta';
    case 'internal':
      return 'Internal';
    case 'recruiter':
      return 'Recruiter';
    default:
      return source; // Return the original source if no match is found
  }
};



const IconWithTooltip = ({ iconClass, tooltipText, iconId, handleDetails }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      <i
        id={iconId}
        className={`fas ${iconClass} ${classes.icon}`}
        onClick={handleDetails}
        style={{ cursor: 'pointer' }}
      ></i>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={iconId}
        toggle={toggleTooltip}
      >
        {tooltipText}
      </Tooltip>
    </div>
  );
};


const JobDetail = (props) => {
  const { jobId } = useParams<JobParams>();
  const dispatch = useDispatch();
  const { jobClientList, jobList, clientInterviewOffers, interviewOffers, jobOffers, allClientCandidatesList, allCandidatesList, setting } = useSelector((state: ReducerType) => state.employer);
  // console.log("intervire", clientInterviewOffers)
  const { user } = useSelector((state: ReducerType) => state.user);
  const [jobData, setJobData] = useState(props.job || null);
  
  const [data, setData] = useState(initialData);
  const [showArchive, setShowArchive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [prevColumn, setPrevColumn] = useState('');
  const [originalColumn, setOriginalColumn] = useState('');
  const [interviewModalOpen, setInterviewModalOpen] = useState(false);
  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [tooltipMessageOpen, setTooltipMessageOpen] = useState(false);
  const toggleMessageTooltip = () => setTooltipMessageOpen(!tooltipMessageOpen);
  const [showHeadhunterTasks, setShowHeadhunterTasks] = useState(false);

  
  const { webSocket } = useWebSocket();
  const history = useHistory();


  
  useEffect(() => {
    if (!props.job) {
        let jobFromList = jobClientList.find((j) => j.id === parseInt(jobId));
        
        if (!jobFromList) {
            jobFromList = jobList.find((j) => j.id === parseInt(jobId));
        }

        if (jobFromList) {
            setJobData(jobFromList);
            // console.log("THIS", jobData)
        } else {
            console.error('Job not found.'); // Log the error instead of dispatching
        }
    }
}, [props.job, jobId, dispatch, jobClientList, jobList]);



  useEffect(() => {

    const fetchImageForApprovedCandidates = async (jobId, contractorId) => {
      try {
        const profile = await JobService.getContractorProfileJobOffer(jobId, contractorId);
        if (profile?.photoUrl && profile?.identityId) {
          const imageUrl = await ImageService.getImage(profile.photoUrl, profile.identityId);
          return imageUrl;
        }
      } catch (error) {
        console.error(`Error fetching image for contractor ${contractorId}:`, error);
        return ''; // Fallback to empty string if there’s an error
      }
      return ''; // Fallback if no photoUrl or identityId is found
    };


    const fetchImagesAsync = async (tasksToUpdate) => {
      const updatedTasks = { ...tasksToUpdate };
  
      for (const taskId in updatedTasks) {
        const task = updatedTasks[taskId];
        if (task.approved) {
          const imageUrl = await fetchImageForApprovedCandidates(jobId, task.id.replace('task-', ''));
          updatedTasks[taskId] = { ...task, image: imageUrl };
        }
      }
  
      setData((prevData) => ({
        ...prevData,
        tasks: updatedTasks,
      }));
    };
  
    const fetchCandidatesData = async () => {
      if (allClientCandidatesList || allCandidatesList) {
          // Combine candidates from both lists
          const combinedCandidates = [
              ...(allClientCandidatesList || []),
              ...(allCandidatesList || []),
          ];
  
          const filteredCandidates = combinedCandidates.filter((candidate) => candidate.jobId === parseInt(jobId));
  
          const interviewMap = [...clientInterviewOffers, ...interviewOffers].reduce((map, offer) => {
              if (offer.job === parseInt(jobId) && offer.offered && !offer.revoked) {
                  map[offer.contractor] = offer;
              }
              return map;
          }, {});
  
          const jobMap = jobOffers.reduce((map, offer) => {
              if (offer.job === parseInt(jobId) && offer.offered && !offer.revoked) {
                  map[offer.contractor] = offer;
              }
              return map;
          }, {});
  
          const tasks = {};
  
          const sortedCandidates = [...filteredCandidates].sort((a, b) => {
              const scoreA = typeof a.scores === 'number' ? a.scores : 0;
              const scoreB = typeof b.scores === 'number' ? b.scores : 0;
              return scoreB - scoreA;
          });
  
          const longList = [];
          const shortlist = [];
          const interviews = [];
          const contracts = [];
          const archive = [];
          
          sortedCandidates.forEach((candidate) => {
              const taskId = `task-${candidate.id}`;
              const task = {
                  id: taskId,
                  content: `${candidate.firstName} ${candidate.lastName}`,
                  yearsExperience: candidate.experienceYears,
                  email: candidate.email,
                  social: candidate.social,
                  mobile: candidate.mobile,
                  jobTitle: candidate.title,
                  lastJob: candidate.lastTitle && candidate.lastCompany
                      ? `${candidate.lastTitle} at ${candidate.lastCompany}`
                      : candidate.lastTitle ? candidate.lastTitle : '',
                  location: `${candidate.city}, ${candidate.country}`,
                  workStyle: candidate.workPolicy,
                  rate: candidate.maxRate ? `$${candidate.maxRate}/hr` : 'N/A',
                  salary: candidate.maxSalary ? `$${candidate.maxSalary} /annum` : 'N/A',
                  summary: candidate.summary,
                  approved: candidate.accepted,
                  image: '', // Initially set image to empty
                  scores: candidate.scores
                      ? candidate.source === "headhunter"
                          ? Math.ceil(parseFloat(candidate.scores))
                          : Math.ceil(parseFloat(candidate.scores) * 100)
                      : 0,
                  source: candidate.source || (candidate.applied ? "applied" : "necta"),
                  awaitingAcceptance: (interviewMap[candidate.id] || jobMap[candidate.id]) && !(interviewMap[candidate.id]?.accepted || jobMap[candidate.id]?.accepted),
                  offerRejected: (interviewMap[candidate.id] || jobMap[candidate.id]) && (interviewMap[candidate.id]?.rejected || jobMap[candidate.id]?.rejected),
                  interviewDetails: interviewMap[candidate.id] ? interviewMap[candidate.id] : [],
              };
  
              tasks[taskId] = task;
  
              if (task.source === "headhunter") {
                  if (candidate.rejected) {
                      archive.push(taskId);
                  } else if (candidate.offered) {
                      shortlist.push(taskId);
                  } else {
                      longList.push(taskId);
                  }
              } else {
                  if (candidate.revoked || candidate.rejected) {
                      archive.push(taskId);
                  } else if (jobMap[candidate.id]) {
                      contracts.push(taskId);
                  } else if (interviewMap[candidate.id]) {
                      interviews.push(taskId);
                  } else if (candidate.offered && !candidate.rejected) {
                      shortlist.push(taskId);
                  } else {
                      longList.push(taskId);
                  }
              }
          });
  
          const sortedLongList = longList.sort((a, b) => tasks[b].scores - tasks[a].scores);
          const sortedShortlist = shortlist.sort((a, b) => tasks[b].scores - tasks[a].scores);
  
          setData((prevData) => ({
              ...prevData,
              columns: {
                  ...prevData.columns,
                  'column-1': { ...prevData.columns['column-1'], taskIds: sortedLongList },
                  'column-2': { ...prevData.columns['column-2'], taskIds: sortedShortlist },
                  'column-3': { ...prevData.columns['column-3'], taskIds: interviews },
                  'column-4': { ...prevData.columns['column-4'], taskIds: contracts },
                  'column-5': { ...prevData.columns['column-5'], taskIds: archive },
              },
              tasks,
          }));
  
          // Fetch images asynchronously after tasks have been set
          fetchImagesAsync(tasks);
      }
  };
  
  fetchCandidatesData();
  }, [allClientCandidatesList, allCandidatesList, jobId, clientInterviewOffers, interviewOffers, jobOffers]);
  
  
  

  

  const [modalSeeContract, setModalSeeContract] = useState({
    isOpenModal: false,
    selectedItem: null,
    contract: null,
    job: null,
  });


  // Define the onCloseSeeContract function
  const onCloseSeeContract = () => {
    setModalSeeContract({
      isOpenModal: false,
      selectedItem: null,
      contract: null,
      job: null,
    });
  };


  const { onOpen: openEvaluation } = useModal('Evaluation');

  const handleEvaluation = async (item, candidate) => {
    const newContractorId = candidate.id.replace('task-', '');
  
    try {
      dispatch(setLoading(true));
  
      const [
        profile,
        experiences,
      ] = await Promise.all([
        JobService.getContractorProfileJobOffer(jobId, newContractorId),
        JobService.getContractorExperiencesJobOffer(jobId, newContractorId),
      ]);
  
      const firstName = profile?.firstName
      const updatedCandidate = {
        experiences,
        firstName
      };
      // console.log("looks here", updatedCandidate)
      openEvaluation({
        item: item,
        candidate: updatedCandidate,
      });
  
      toggleModal();
    } catch (err) {
      dispatch(setErrorMess(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSeeContract = (job, candidate) => {
    dispatch(setLoading(true));
    JobService.getAllSentContractOfAJob(job?.id)
      .then((res) => {
        const contracts = res.filter((item) => item?.contractor === candidate?.id);
        setModalSeeContract({
          isOpenModal: true,
          selectedItem: candidate,
          contract: contracts[0],
          job,
        });
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleReSendContract = async (task) => {
    dispatch(setLoading(true));
    try {
      const newContractorId = task.id.replace('task-', '');
      const [profile] = await Promise.all([
        JobService.getContractorProfileJobOffer(jobId, newContractorId),
      ]);
  
      const firstName = profile?.firstName;
      const lastName = profile?.lastName;
  
      const updatedCandidate = {
        ...task,
        firstName,
        lastName,
      };
      dispatch(setLoading(false));
      if (task && task.approved) {
        setSelectedTask(updatedCandidate);
        setContractModalOpen(true);
      } else {
        setOriginalColumn(originalColumn); // Ensure originalColumn is set properly in your context
        setSelectedTask(updatedCandidate);
        toggleWarningModal();
      }
    } catch (error) {
      dispatch(setErrorMess(error));
    }
  };

  const handleRedirectToMessage = (webSocket, candidate, item) => {
    const newContractorId = candidate.replace('task-', '');
    const myId = newContractorId;
    const receiverId = item;

    //log('myId:', myId); // log to check
    //console.log('receiverId:', receiverId); // log to check

    if (webSocket) {
      const data = {
        action: 'sendPrivate',
        data: {
          userId: receiverId,
          receiverId: myId,
        },
      };

      //console.log('Data to be sent:', data); // log to check

      webSocket.send(JSON.stringify(data));
    }
    // Send the email to the contractor.
    ListingEmailService.newMessageEmail(myId, {
      type: EmailTypes.NEW_CHAT_FOR_CONTRACTOR,
    })
      .then((res) => {
        dispatch(
          setUserOfChatReducer({
            userId: myId,
            chatId: res.id,
          })
        );
        dispatch(push(routes.employer.admin.messenger));
      })
      .catch((error) => dispatch(setErrorMess(error)));
  };



  const { onOpen: openShortList } = useModal('Shortlist');

  const handleShortList = async ( item, candidate ) => {
    try {
      dispatch(setLoading(true));
      const newContractorId = candidate.id.replace('task-', '')
      const [
        profile,
        preference,
        experiences,
      ] = await Promise.all([
        JobService.getContractorProfileJobOffer(jobId, newContractorId),
        JobService.getContractorPreferenceJobOffer(jobId, newContractorId),
        JobService.getContractorExperiencesJobOffer(jobId, newContractorId),
      ]);
  
      const firstName = profile?.firstName
      const lastName = profile?.lastName
      const availableDate = preference?.availableDate
      const noticePeriod = preference?.noticePeriod
      const maxRate = preference?.maxRate
      const maxSalary = preference?.maxSalary
      const workPolicy = preference?.workPolicy

      const updatedCandidate = {
        experiences,
        firstName, 
        lastName, 
        availableDate,
        noticePeriod,
        maxRate,
        maxSalary,
        workPolicy
      };
      
      
      // Assuming getContractorPreferenceJobOffer is an async function
      const candidatePreference = await JobService.getContractorPreferenceJobOffer(item.id, newContractorId);
      handleDownloadCV(item.id, newContractorId, item);

      toggleModal();
      // console.log(updatedCandidate)
      // After the promise resolves, open the modal and pass the item, candidate, and the candidatePreference
      openShortList({ 
        item: item, 
        candidate: updatedCandidate, 
        candidatePreference: candidatePreference,
      });
      dispatch(setLoading(false));
    } catch (error) {
      console.error("Error in handleShortList function:", error);
      // Handle any errors that occur during the API call
    }
  };

  const handleDownloadCV = (jobId, contractorId, engagement) => {
    const newContractorId = contractorId.replace('task-', '')
    dispatch(setLoading(true));
    Promise.all([
      JobService.getContractorContactJobOffer(jobId, newContractorId),
      JobService.getContractorProfileJobOffer(jobId, newContractorId),
      JobService.getContractorPreferenceJobOffer(jobId, newContractorId),
      JobService.getContractorExperiencesJobOffer(jobId, newContractorId),
      JobService.getContractorEducationsJobOffer(jobId, newContractorId),
    ])
      .then((res) => {
        const [contact, profile, preference, experience, education] = res;

        downloadCV(
          dispatch,
          contact,
          profile,
          preference,
          experience,
          education,
          engagement
        );
      })
      .catch((err) => {
        dispatch(setErrorMess(err));
      })
      .finally(() => dispatch(setLoading(false)));
  };
  

  const handleEditInterview = (interviewDetails) => {
    setInterviewModalOpen(true);
    setSelectedTask((prevSelectedTask) => ({
      ...prevSelectedTask,
      interviewDetails,
    }));
  };


  // Define the specific functions
  const moveToArchive = async (task) => {
    // console.log(task);
    
    if (task.source === 'headhunter') {
      const data = {
        job: parseInt(jobId, 10),
        contractor: task.id.split('-')[1],
        rejected: true,
        offered: false,
        employer: user.id,
      };
      console.log("part 1", data)
      const res = await JobService.putJobOfferHeadHunter(data)
      console.log("part 2", res)
      return;
    }
    
    const data = {
      job: parseInt(jobId, 10),
      contractor: task.id.split('-')[1],
      revoked: task.approved ? false : true,
      rejected: task.approved ? true : false,
      employer: user.id,
    };


  
    try {
      const res = await JobService.putJobOffer(data);

      // need to have if you have applied or approved, it sends a rejection email
      //if (!task.approved) {
      //   try {
      //     await ListingEmailService.offerCreateEmail(res.id, {
      //       type: EmailTypes.NEW_CONNECT_OFFER_FOR_CONTRACTOR,
      //     });
      //   } catch (err) {
      //     console.error('Error sending email:', err);
      //     dispatch(setErrorMess(err));
      //   }
      // }  

    } catch (error) {
      console.error('Error moving to archive:', error);
    }
  };

  const routeSeeFullProfile = (candidateID, jobId) => {
    const newContractorId = candidateID.replace('task-', '')
    dispatch(push(`/jobs/73957395/candidates/${newContractorId}`));
  };

  const moveToShortlist = async (task) => {

    if (task.source === 'headhunter') {
      const data = {
        job: parseInt(jobId, 10),
        contractor: task.id.split('-')[1],
        offered: true,
        rejected: false,
        employer: user.id,
      };
      // console.log("part 1", data)
      const res = await JobService.putJobOfferHeadHunter(data)
      // console.log("part 2", res)
      return;
    }

    const data = {
      job: parseInt(jobId, 10),
      contractor: task.id.split('-')[1],
      accepted: true,
      offered: true,
      rejected: false,
      revoked: false,
      employer: user.id,
    };
  
    try {
      const res = await JobService.putJobOffer(data);
  
      if (!task.approved) {
        try {
          await ListingEmailService.offerCreateEmail(res.id, {
            type: EmailTypes.NEW_CONNECT_OFFER_FOR_CONTRACTOR,
          });
        } catch (err) {
          console.error('Error sending email:', err);
          dispatch(setErrorMess(err));
        }
      }  
      setData((prevData) => {
        const newColumns = { ...prevData.columns };
  
        // Check if the start column exists
        const startColumn = newColumns[task.columnId];
        if (!startColumn) {
          console.error('Start column not found:', task.columnId);
          return prevData;
        }
  
        // Remove from the previous column
        startColumn.taskIds = startColumn.taskIds.filter((id) => id !== task.id);
  
        // Add to the shortlist column
        const shortlistColumn = newColumns['column-2'];
        if (!shortlistColumn) {
          console.error('Shortlist column not found');
          return prevData;
        }
  
        shortlistColumn.taskIds.push(task.id);
  
        return { ...prevData, columns: newColumns };
      });
    } catch (error) {
      console.error('Error moving to shortlist:', error);
    }
  };

  const moveToLonglist = async (task) => {

    if (task.source === 'headhunter') {
      const data = {
        job: parseInt(jobId, 10),
        contractor: task.id.split('-')[1],
        offered: false,
        rejected: false,
        employer: user.id,
      };
      await JobService.putJobOfferHeadHunter(data)
      return;
    }

    const data = {
      job: parseInt(jobId, 10),
      contractor: task.id.split('-')[1],
      rejected: false,
      revoked: false,
      offered: false,
      employer: user.id
    };

    try {
      await JobService.putJobOffer(data);
    } catch (error) {
      console.error("Error moving to shortlist:", error);
    }
  };

  const moveToInterview = (task, originalColumnId) => {
    if (task.approved) {
      setSelectedTask(task);
      setOriginalColumn(originalColumnId);
      setInterviewModalOpen(true);
  
      // Move the task to the interview column
      setData(prevData => {
        const newColumns = { ...prevData.columns };
        const startTaskIds = newColumns[originalColumnId].taskIds.filter(id => id !== task.id);
        const finishTaskIds = newColumns['column-3'].taskIds.includes(task.id)
          ? newColumns['column-3'].taskIds
          : [...newColumns['column-3'].taskIds, task.id];
  
        newColumns[originalColumnId] = {
          ...newColumns[originalColumnId],
          taskIds: startTaskIds,
        };
  
        newColumns['column-3'] = {
          ...newColumns['column-3'],
          taskIds: finishTaskIds,
        };
  
        return {
          ...prevData,
          columns: newColumns,
        };
      });
    } else {
      setOriginalColumn(originalColumnId);
      setSelectedTask(task);
      toggleWarningModal();
      // Move the task back to the original column
      setData(prevData => {
        const newColumns = { ...prevData.columns };
        const startTaskIds = [...newColumns[originalColumnId].taskIds, task.id];
        const finishTaskIds = newColumns['column-3'].taskIds.filter(id => id !== task.id);
  
        newColumns[originalColumnId] = {
          ...newColumns[originalColumnId],
          taskIds: startTaskIds,
        };
  
        newColumns['column-3'] = {
          ...newColumns['column-3'],
          taskIds: finishTaskIds,
        };
  
        return {
          ...prevData,
          columns: newColumns,
        };
      });
    }
  };
  
  
  const moveToContract = async (task, originalColumnId) => {
    dispatch(setLoading(true));
    try {
      const newContractorId = task.id.replace('task-', '');
      const [profile] = await Promise.all([
        JobService.getContractorProfileJobOffer(jobId, newContractorId),
      ]);
  
      const firstName = profile?.firstName;
      const lastName = profile?.lastName;
  
      const updatedCandidate = {
        ...task,
        firstName,
        lastName,
      };
      dispatch(setLoading(false));
      if (task.approved) {
        setSelectedTask(updatedCandidate);
        setContractModalOpen(true);
      } else {
        setOriginalColumn(originalColumnId);
        setSelectedTask(updatedCandidate);
        toggleWarningModal();
        
        // Move the task back to the original column
        setData(prevData => {
          const newColumns = { ...prevData.columns };
          const startTaskIds = Array.from(newColumns[originalColumnId].taskIds);
          startTaskIds.push(task.id);
          const finishTaskIds = newColumns['column-4'].taskIds.filter(id => id !== task.id);
  
          newColumns[originalColumnId] = {
            ...newColumns[originalColumnId],
            taskIds: startTaskIds,
          };
  
          newColumns['column-4'] = {
            ...newColumns['column-4'],
            taskIds: finishTaskIds,
          };
  
          return {
            ...prevData,
            columns: newColumns,
          };
        });
      }
    } catch (error) {
      dispatch(setErrorMess(error));
    }
  };
  
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
  
    if (!destination) return;
  
    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];
  
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
  
    if (finish.id === 'column-2' && start.id === 'column-3') {
      return;
    }
  
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };
  
    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };
  
    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
  
    setData(newState);
  
    const task = data.tasks[draggableId];
  
    if (finish.id === 'column-5') {
      moveToArchive(task);
    } else if (finish.id === 'column-1') {
      moveToLonglist(task);
    } else if (finish.id === 'column-2') {
      moveToShortlist(task);
    } else if (finish.id === 'column-3') {
      setOriginalColumn(start.id);
      moveToInterview(task, start.id);
    } else if (finish.id === 'column-4') {
      setOriginalColumn(start.id);
      moveToContract(task, start.id);
    }
  };


  const handleArchive = async () => {
    if (selectedTask) {
      await moveToArchive(selectedTask);
      const selectedTaskColumnId = Object.keys(data.columns).find(columnId => data.columns[columnId].taskIds.includes(selectedTask.id));
      if (selectedTaskColumnId) {
        const updatedColumns = {
          ...data.columns,
          [selectedTaskColumnId]: {
            ...data.columns[selectedTaskColumnId],
            taskIds: data.columns[selectedTaskColumnId].taskIds.filter(id => id !== selectedTask.id),
          },
          'column-5': {
            ...data.columns['column-5'],
            taskIds: [...data.columns['column-5'].taskIds, selectedTask.id],
          },
        };
        setData({
          ...data,
          columns: updatedColumns,
        });
        toggleModal();
      }
    }
  };

  
  const handleDetails = async () => {
    // console.log("hit");
    if (selectedTask) {
      await moveToArchive(selectedTask);
      const updatedColumns = {
        ...data.columns,
        [selectedTask.columnId]: {
          ...data.columns[selectedTask.columnId],
          taskIds: data.columns[selectedTask.columnId].taskIds.filter(id => id !== selectedTask.id),
        },
        'column-5': {
          ...data.columns['column-5'],
          taskIds: [...data.columns['column-5'].taskIds, selectedTask.id],
        },
      };
      setData({
        ...data,
        columns: updatedColumns,
      });
      toggleModal();
    }
  };

  const toggleArchive = () => setShowArchive(!showArchive);

  const toggleModal = () => setModalOpen(!modalOpen);

  const toggleWarningModal = () => setWarningModalOpen(!warningModalOpen);

  const handleCardClick = (task, jobData, columnId) => {
    // console.log(task)
    const updatedTask = {
      ...task,
      jobId: jobData.id, // Add job ID
      contractorId: task.id.replace('task-', ''), // Extract contractor ID
      item: { id: task.id }, // Assuming task.id is the job ID
      candidate: { id: task.id }, // Assuming task.id is the candidate ID
    };
    setSelectedTask(updatedTask);
    // console.log("this selected task", selectedTask)
    toggleModal();
  };

  const handleCloseInterviewModal = (isSubmitted = false) => {
    setInterviewModalOpen(false);
    if (!isSubmitted && selectedTask && originalColumn) {
      const taskId = selectedTask.id;
      const newColumns = { ...data.columns };
      const originalTaskIds = [...newColumns[originalColumn].taskIds, taskId];
      const newFinishTaskIds = newColumns['column-3'].taskIds.filter(id => id !== taskId);
  
      newColumns[originalColumn] = {
        ...newColumns[originalColumn],
        taskIds: originalTaskIds,
      };
  
      newColumns['column-3'] = {
        ...newColumns['column-3'],
        taskIds: newFinishTaskIds,
      };
  
      setData({
        ...data,
        columns: newColumns,
      });
    }
    setSelectedTask(null);
  };

  const workStyleTranslation = {
    mixed: 'Hybrid',
    office: 'Office',
    all: 'All',
    workfromhome: 'Remote',
  };

  const handleCloseContractModal = () => {
    setContractModalOpen(false);
    if (selectedTask && originalColumn) {
      const taskId = selectedTask.id;
      const newColumns = { ...data.columns };
      const originalTaskIds = Array.from(newColumns[originalColumn].taskIds);
      originalTaskIds.push(taskId);
      const newFinishTaskIds = newColumns['column-4'].taskIds.filter(id => id !== taskId);

      newColumns[originalColumn] = {
        ...newColumns[originalColumn],
        taskIds: originalTaskIds,
      };

      newColumns['column-4'] = {
        ...newColumns['column-4'],
        taskIds: newFinishTaskIds,
      };

      setData({
        ...data,
        columns: newColumns,
      });
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      document.querySelector('.kanbanBoard').scrollBy({ left: 300, behavior: 'smooth' });
    },
    onSwipedRight: () => {
      document.querySelector('.kanbanBoard').scrollBy({ left: -300, behavior: 'smooth' });
    }
  });

  if (!jobData) return <div>Loading...</div>;

  const formatSalaryOrRate = (salary, rate) => {
    if (salary) {
      return `$${salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per annum`;
    } else if (rate) {
      return `$${rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per hour`;
    }
    return '';
  };

  const getInitials = (name) => {
    const names = name.split(' ');
    const initials = names.map(n => n[0]).join('');
    return initials.toUpperCase();
  };


  
  return (
    <Container className={classes.container}>
      <Row>
        <Col xs="12" lg="10" className={classes.jobDetails}>
          <h1>{jobData.title}</h1>
          {jobData.city} | {jobData.country}<br />
          {formatSalaryOrRate(jobData.maxSalary, jobData.maxRate)}

          <div className="d-flex align-items-center mt-3">

          <span className="mr-2">Show Passive Candidates:</span>
            <CustomInput 
              type="switch" 
              id="headhunterTasksToggle" 
              name="headhunterTasksToggle" 
              checked={showHeadhunterTasks} 
              onChange={() => setShowHeadhunterTasks(!showHeadhunterTasks)}
            />
                      <br />
          {
                    jobData?.organization === setting?.idOrganisation && (
                       <strong><span className="mr-2">THIS JOB IS OWNED BY YOU</span></strong>
                    )}
          </div>
        </Col>
        <Col xs="12" lg="2" className={classes.huntingProgress}>
          <h4>Progress</h4>
          <Row noGutters>
            <Col xs="6" className="text-center">
              <Badge color="primary">Identified</Badge>
              <div>{jobData.headhunterAmount}</div>
            </Col>
            <Col xs="6" className="text-center">
              <Badge color="warning">Contacted</Badge>
              <div>{jobData.contacted}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={classes.colToggleArchive}>
          <button className={classes.toggleArchive} onClick={toggleArchive}>
            {showArchive ? '<< Hide' : 'Show >>'}
          </button>
        </Col>
        <Col className="text-right">
          <div className={classes.candidateImages}>
            <div>Candidates:</div>
            <div className={classes.imagesContainer}>
              {Object.values(data.tasks)
                .filter(task => task.approved)
                .slice(0, 5)
                .map((task, index) => (
                  task.image ? (
                    <img key={task.id} src={task.image} alt="profile" className={classes.profileImage} />
                  ) : (
                    <div key={task.id} className={classes.initialsCircle}>
                      {getInitials(task.content)}
                    </div>
                  )
                ))
              }
              {Object.values(data.tasks).filter(task => task.approved).length > 5 && (
                <div className={classes.moreCircle}>
                  +{Object.values(data.tasks).filter(task => task.approved).length - 5}
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classes.kanbanBoard} {...swipeHandlers}>
          {data.columnOrder.map((columnId) => {
            const column = data.columns[columnId];
            const tasks = column.taskIds
              .map((taskId) => data.tasks[taskId])
              .filter((task) => showHeadhunterTasks || task.source !== 'headhunter');

            if (columnId === 'column-5' && !showArchive) {
              return null;
            }

            return (
              <div key={column.id} className={classes.columnWrapper}>
                <Droppable droppableId={column.id}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={classes.column}
                    >
                      <h2>{column.title}</h2>
                      <div className={classes.taskList}>
                        {tasks.map((task, index) => (
                          <Draggable key={task.id} draggableId={task.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`${classes.card} ${classes[task.source]}`}
                                onClick={() => handleCardClick(task, jobData, column.id)}
                              >
                                <div className={classes.sideBar}></div>
                                <div className={classes.cardContent}>
                                  <div className={classes.cardDetails}>
                                    {task.approved ? <div className={classes.candidateName}>{task.content}</div> : <div className={classes.candidateName}>{task.jobTitle}</div>}
                                    <div className={classes.candidateExperience}>{task.yearsExperience} Years Experience</div>
                                    {!task.approved && task.offered && <div className={classes.awaitingConnection}>Awaiting Connection</div>}
                                    {
                                      task.offerRejected ? (
                                        <div className={classes.offerRejected}>Offer Was Rejected</div>
                                      ) : task.awaitingAcceptance || !task.approved ? (
                                        <div className={classes.awaitingAcceptance}>Awaiting Acceptance</div>
                                      ) : (
                                        <div className={classes.confirmed}>Confirmed</div>
                                      )
                                    }
                                    {task.approved && <div className={classes.awaitingConnection}>Aligned: {task.scores}%</div>}
                                  </div>
                                  <div className={classes.percentageFit}>
                                    {task.approved ? (
                                      task.image ? (
                                        <img src={task.image} alt="profile" className={classes.profileImage2} />
                                      ) : (
                                        <div className={classes.pendingCircle}>
                                          {getInitials(task.content)}
                                        </div>
                                      )
                                    ) : (
                                      <div className={classes.pendingCircle}>
                                        {task.scores}%
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
            );
          })}
        </div>
      </DragDropContext>
      {selectedTask && (
        <Modal isOpen={modalOpen} toggle={toggleModal} centered size="lg">
          <ModalHeader toggle={toggleModal} className={classes.modalHeader}>
              {selectedTask.approved ? selectedTask.content : selectedTask.jobTitle}
              <span className={classes.scores}>   {selectedTask.scores}%</span>
          </ModalHeader>
          <ModalBody className={classes.modalBody}>
              <p>{selectedTask.summary}</p>
              {selectedTask?.source !== 'headhunter' && (
                <>
                  <p><strong>Last Job:</strong> {selectedTask.lastJob}</p>
                  <p><strong>Location:</strong> {selectedTask.location}</p>
                  <p><strong>Work Style:</strong> {workStyleTranslation[selectedTask.workStyle]}</p>
                  <p><strong>Preferred Rate:</strong> {jobData.isContract ? selectedTask.rate : selectedTask.salary}</p>
                  <p><strong>Years of Experience:</strong> {selectedTask.yearsExperience}</p>
                </>
              )}
              <p className={classes.sourceTag}>{getSourceDisplayName(selectedTask.source)}</p>
              {selectedTask.interviewDetails?.interviewTime && (
                <div style={{ backgroundColor: 'lightgray', padding: '10px', borderRadius: '5px', marginTop: '20px' }}>
                  <p className={classes.boxtitle}>Interview Details</p>
                    <div style={{ marginBottom: '10px' }}>
                    <p><strong>Date and Time:</strong> {new Date(selectedTask?.interviewDetails?.interviewTime).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</p>
                      {selectedTask?.interviewDetails?.interviewAddress ? (
                        <p><strong>Interview Address:</strong> {selectedTask?.interviewDetails?.interviewAddress}</p>
                      ) : (
                        <p><strong>Interview Link:</strong> {selectedTask?.interviewDetails?.interviewLink}</p>
                      )}
                      <p>
                          <strong>Status:</strong> 
                          {selectedTask?.interviewDetails?.accepted 
                            ? ' Confirmed' 
                            : selectedTask?.interviewDetails?.rejected 
                              ? ' Rejected' 
                              : ' Awaiting Confirmation'}
                        </p>
                        <p
                          className={classes.interviewTag}
                          onClick={() => handleEditInterview(selectedTask?.interviewDetails)}
                        >
                          {selectedTask?.interviewDetails?.rejected ? 'Rebook Interview' : 'Edit Interview'}
                        </p>
                    </div>
                
                </div>
              )}
              {user.role === 7 && (
                                <div className={classes["additional-info"]}>
                                  <p><strong>Name:</strong> {selectedTask?.content}</p>
                                  <p>
                                  <strong> Email: </strong>
                                  {selectedTask.email 
                                      ? <a href={`mailto:${selectedTask.email}`}>{selectedTask.email}</a>
                                      : ''
                                    }
                                  </p>
                                  <p><strong>Phone:</strong> {selectedTask.mobile}</p>
                                  <p>
                                  <strong>Social: </strong> 
                                    {selectedTask.social 
                                      ? <a href={ensureProtocol(selectedTask.social)} target="_blank" rel="noopener noreferrer">
                                          {selectedTask.social}
                                        </a>
                                      : ''
                                    }
                                  </p>
                                </div>
                                )}
            </ModalBody>
          <ModalFooter className={classes.modalFooter}>
            <Col xs="4">
            {user.role === 7 && !selectedTask?.approved && selectedTask?.source !== 'headhunter' && (
              <div className={classes.modalIconFooter}>
                <IconWithTooltip
                iconClass="fa-info-circle"
                tooltipText="See Details"
                iconId="DetailIcon1"
                handleDetails={() => routeSeeFullProfile(selectedTask?.id, jobId)}
                />
                <IconWithTooltip
                iconClass="fa-download"
                tooltipText="Download CV"
                iconId="DetailIcon2"
                handleDetails={() =>
                  handleDownloadCV(
                    jobId,
                    selectedTask?.id,
                    jobData
                  )
                }
              />                  
                <IconWithTooltip 
              iconClass="fa-envelope"
              tooltipText="Message Candidate"
              iconId="MessageIcon"
              handleDetails={() => {
                handleRedirectToMessage(
                  webSocket,
                  selectedTask?.id,
                  user?.id
                );
                history.push(
                  routes.employer
                    .admin
                    .messenger
                );
              }}
            />

                    <IconWithTooltip
                      iconClass="fa-check"
                      tooltipText="Shortlist to Client"
                      iconId="DetailIcon3"
                      handleDetails={() =>
                        handleShortList(
                          jobData,
                          selectedTask,
                        )
                      }
                    />

                  
            </div>
            )}
            
            {
    selectedTask?.approved && (
        <>
            <div className={classes.modalIconFooter}>
                <IconWithTooltip
                    iconClass="fa-info-circle"
                    tooltipText="See Details"
                    iconId="DetailIcon1"
                    handleDetails={() => routeSeeFullProfile(selectedTask?.id, jobId)}
                />

                {
                    jobData?.organization === setting?.idOrganisation && (
                        <>
                            <IconWithTooltip
                                iconClass="fa-envelope"
                                tooltipText="Message Candidate"
                                iconId="MessageIcon"
                                handleDetails={() => {
                                    handleRedirectToMessage(
                                        webSocket,
                                        selectedTask?.id,
                                        user?.id
                                    );
                                    history.push(
                                        routes.employer
                                            .admin
                                            .messenger
                                    );
                                }}
                            />

                            <IconWithTooltip
                                iconClass="fa-download"
                                tooltipText="Download CV"
                                iconId="DetailIcon2"
                                handleDetails={() =>
                                    handleDownloadCV(
                                        jobId,
                                        selectedTask?.id,
                                        jobData
                                    )
                                }
                            />

                            {selectedTask?.state !== 'Contract Accepted' && jobData.client && !selectedTask?.interviewDetails?.interviewTime && (
                                <IconWithTooltip
                                    iconClass="fa-check"
                                    tooltipText="Shortlist to Client"
                                    iconId="DetailIcon3"
                                    handleDetails={() =>
                                        handleShortList(
                                            jobData,
                                            selectedTask,
                                        )
                                    }
                                />
                            )}

                            <IconWithTooltip
                                iconClass="fa-star"
                                tooltipText="Evaluate"
                                iconId="DetailIcon4"
                                handleDetails={() =>
                                    handleEvaluation(
                                        jobData,
                                        selectedTask
                                    )
                                }
                            />

                            {(selectedTask?.state === 'Contract Accepted' ||
                                selectedTask?.state === 'Contract Declined' ||
                                selectedTask?.state === 'Contract Offer Sent') && (
                                <IconWithTooltip
                                    iconClass="fa-file-contract"
                                    tooltipText="See Contract"
                                    iconId="DetailIcon5"
                                    handleDetails={() => {
                                        handleSeeContract(
                                            jobData,
                                            selectedTask
                                        );
                                    }}
                                />
                            )}

                            {(selectedTask?.state === 'Contract Declined' ||
                                selectedTask?.state === 'Contract Offer Sent') && (
                                <IconWithTooltip
                                    iconClass="fa-paper-plane"
                                    tooltipText="Send Contract"
                                    iconId="DetailIcon6"
                                    handleDetails={() => {
                                        handleReSendContract(selectedTask);
                                    }}
                                />
                            )}
                        </>
                    )
                }
            </div>
        </>
    )
}


              </Col>
              <Col xs="8" className={classes.modalFooter}>
            <Button color="danger" onClick={handleArchive}>Archive</Button>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
              </Col>
          </ModalFooter>
        </Modal>
      )}
      {warningModalOpen && (
        <Modal isOpen={warningModalOpen} toggle={toggleWarningModal} centered>
          <ModalHeader toggle={toggleWarningModal}>Action Required</ModalHeader>
          <ModalBody>
            <p>To "interview" or "send a contract" the candidate must respond to the connection. By placing the candidate into shortlist, Necta will try and obtain permission on your behalf.</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleWarningModal}>Okay</Button>
          </ModalFooter>
        </Modal>
      )}
        <SetInterviewDateTime
          isOpen={interviewModalOpen}
          onClose={handleCloseInterviewModal}
          selectedItem={selectedTask}
          contractorId={selectedTask ? selectedTask.id : null}
          jobId={parseInt(jobId, 10)}
          isSendAgain={false}
        />
        <SeeContract
          onClose={onCloseSeeContract}
          isOpen={modalSeeContract.isOpenModal}
          contractor={modalSeeContract.selectedItem}
          contract={modalSeeContract.contract}
          job={modalSeeContract.job}
        />
      <SendContract
        isOpen={contractModalOpen}
        onClose={handleCloseContractModal}
        contractor={selectedTask}
        jobId={parseInt(jobId, 10)}
        isSendAgain={false}
        handleGetCandidates={() => {}}
        subTableIndex={0}
        isContract={jobData.isContract}
        job={jobData}
      />
      <div className={classes.colorKey}>
        <div className={classes.colorKeyContainer}>
          {['active', 'passive', 'internal', 'applied', 'recruiter'].map((origin) => (
            <div key={origin} className={classes.colorKeyItem}>
              <span className={`${classes.colorBox} ${classes[origin]}`}></span>
              {origin.charAt(0).toUpperCase() + origin.slice(1)}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default JobDetail;
