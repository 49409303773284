import React, { createContext, useEffect, useState, useContext } from "react";

// Create Context
const WebSocketContext = createContext(null);



// Provide the WebSocket connection to children components
export const WebSocketProvider = ({ children, isLoggedIn, userId }) => {
  const [webSocket, setWebSocket] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState(0);
   const [hasUnreadMessages, setHasUnreadMessages] = useState(false); // Flag for any unread message

  // Connect function that initiates WebSocket connection
  const connect = () => {
    if (isLoggedIn && userId && (!webSocket || webSocket.readyState === WebSocket.CLOSED)) {
      const ws = new WebSocket(
        `wss://ysvh3kcbwg.execute-api.ap-southeast-2.amazonaws.com/production?userId=${userId}`
      );
      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        // console.log("Received WebSocket Message:", message);
        if (message.type === 'message' && message.userId !== userId) {
          // Update the state with the number of unread messages
          setReceivedMessage(message);
          setUnreadMessages(prevUnreadMessages => prevUnreadMessages + 1);
          setHasUnreadMessages(true);
        } else if (message.type === 'rooms') {
          setReceivedMessage(message);
          // Handle rooms
          let totalUnreadMessages = 0;
          message.rooms.forEach(room => {
            room.messages.forEach(msg => {
              if (msg.userId !== userId && msg.read === null) {
                totalUnreadMessages += 1;
              }
            });
          });
          if (totalUnreadMessages > 0) {
            setUnreadMessages(prevUnreadMessages => prevUnreadMessages + totalUnreadMessages);
            setHasUnreadMessages(true);
           
          }
        } else {
          // Handle other types of messages
          setReceivedMessage(message);

        }
      };
      ws.onerror = (error) => {
        console.log('WebSocket error:', error);
        ws.close();
      };
      setWebSocket(ws);
    }
  };

  const clearHasUnreadMessages = () => {
        // console.log("Clearing unread messages...");
        setHasUnreadMessages(false);
        setUnreadMessages(0);
    };

  // Listen to 'close' event and try to reconnect
  useEffect(() => {
    if (webSocket) {
      webSocket.onclose = () => {
        console.log('WebSocket connection closed, retrying...');
        connect();
      };
    }
  }, [webSocket]);

  // useEffect(() => {
  //   console.log(hasUnreadMessages);
  // }, [hasUnreadMessages]);

  useEffect(() => {
    connect();
  }, [isLoggedIn, userId]);

  useEffect(() => {
    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, []);

  const sendMessage = (message) => {
    if (webSocket) {
      webSocket.send(JSON.stringify(message));
    }
  };

  return (
    <WebSocketContext.Provider
      value={{ 
        webSocket, 
        sendMessage, 
        receivedMessage, 
        unreadMessages, 
        hasUnreadMessages, 
        clearHasUnreadMessages 
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};



// Hook for children components to get the WebSocket instance
export const useWebSocket = () => {
  const { webSocket, sendMessage, receivedMessage, unreadMessages, hasUnreadMessages, clearHasUnreadMessages  } = useContext(WebSocketContext);
  return { webSocket, sendMessage, receivedMessage, unreadMessages, hasUnreadMessages, clearHasUnreadMessages  };
};