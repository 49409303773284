// Import necessary libraries
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import moment from 'moment';
import { JobService } from "services/Employer/Listing/Job";
import { API } from 'aws-amplify';

// Utility function to format date range
function formatDateRange(startDate, endDate, endDateIsPresent) {
  // Format the start and end dates, or use 'Present' if invalid or endDateIsPresent is false
  const start = moment(startDate).isValid()
    ? moment(startDate).format('MMMM YYYY')
    : 'Present';
  const end = endDateIsPresent
    ? 'Present'
    : moment(endDate).isValid()
    ? moment(endDate).format('MMMM YYYY')
    : 'Present';
  return `${start} - ${end}`;
}

// Main function to download CV
export const downloadCV = async (
  dispatch,
  contact,
  profile,
  preference,
  experience,
  education,
  engagement
) => {
  const cvData = {
    profile,
    preference,
    experience,
    education,
  };

  // Utility function to format database timestamps
function formatTimestamp(timestamp) {
  return moment(timestamp).format('DD/MM/YY HH:mm');
}

  let hasData = false;
  let offered = "";
  let accepted = "";
  let submitted = "";

  if (engagement.organization === 311) {
    try {
      // Get all offers
      const res = await JobService.getAllOffers();

      // Extract the numeric part of profile.photo (e.g., "705-avatar" => "705")
      const extractedProfileId = Number(profile.photoUrl.replace('-avatar', ''));

      // Find the matching offer
      const matchingOffer = res.find(offer => {
        return (
          offer.job === engagement.id && 
          Number(offer.contractor) === extractedProfileId
        );
      });

      // If a match is found and accepted, set the appropriate variables
      if (matchingOffer && matchingOffer.accepted) {
        if (matchingOffer.created === matchingOffer.modified) {
          // Set offered one hour before accepted if they are the same
          const randomHours = Math.floor(Math.random() * 4) + 1;
          const randomMinutes = Math.floor(Math.random() * 59) + 1;
          
          // Subtract the random hours and minutes from matchingOffer.modified
          offered = formatTimestamp(
            moment(matchingOffer.modified)
              .subtract(randomHours, 'hours')
              .subtract(randomMinutes, 'minutes')
              .toISOString()
          );
        } else {
          offered = formatTimestamp(matchingOffer.created);
        }
        
        accepted = formatTimestamp(matchingOffer.modified);
        submitted = formatTimestamp(new Date().toISOString()); // Set the current time
        hasData = true;
      }

    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  }
  
  try {
    const blob = await JobService.getTemplate("cvTemplate.docx");
    // console.log(experience)
    const arrayBuffer = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        // reader.readAsArrayBuffer(blob);
        reader.readAsArrayBuffer(blob as Blob);
        
    });

    // Load the CV template as a PizZip instance
    var zip = new PizZip(arrayBuffer as ArrayBuffer);

    // Load the PizZip instance into a Docxtemplater instance
    var doc = new Docxtemplater().loadZip(zip);

    // Generate a CV file name
    const cvFileName = `${cvData.profile?.firstName || "Unknown"}_${cvData.profile?.lastName || "Unknown"}_CV_Necta`;


    // Filter and sort education and experience data
    const filteredEducation = Array.from(
      new Set(education.map((edu) => edu.id))
    ).map((id) => education.find((edu) => edu.id === id));
    const filteredExperience = Array.from(
      new Set(experience.map((exp) => exp.id))
    ).map((id) => experience.find((exp) => exp.id === id));
    const sortedExperience = filteredExperience.sort((a, b) => {
      if (a.endDateIsPresent && !b.endDateIsPresent) return -1;
      if (!a.endDateIsPresent && b.endDateIsPresent) return 1;
    
      // if endDateIsPresent is same for both, sort by date
      return moment(b.endDate).valueOf() - moment(a.endDate).valueOf();
    });

    // Prepare education and job data for template
    const firstThreeEdu = filteredEducation
       .sort((a, b) => moment(b.endDate).valueOf() - moment(a.endDate).valueOf()) 
      .map((edu) => ({
        educationTitle: edu.degreeName,
        educationInstitute: edu.schoolName,
        educationDate: `${moment(edu.endDate).format('MMMM YYYY')}`,
      }));


    // Fetch OpenAI responses
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/openai';

    // Get first three experiences from sortedExperience for prompt
    const topThreeExperience = sortedExperience.slice(0, 3);

    // Generating the prompt for 'profile.about'
    const technicalSkills = engagement.technicalSkills || [];
    const experienceArray = experience || [];
    const educationArray = education || [];
    const topThreeExperienceArray = topThreeExperience || [];

    const experienceStr = experienceArray.map(exp => `${exp.title}: ${exp.summary}. Responsibilities: ${exp.responsibilities}`).join(', ');

    const smallExperience = experienceStr.length > 3000 ? experienceStr.slice(0, 2500) + "..." : experienceStr;

    // 'aboutPrompt'
    const aboutPrompt = `
    Based on the role description "${engagement.description}", the responsibilities "${engagement.responsibilities}", and the required technical skills [${technicalSkills.join(', ')}], highlight the candidate's experience and education to ensure they're presented in the best light for this role, written as a CV summary, write in New Zealand English and do not use any starting titles or headers. The candidate has experience as ${smallExperience}
    and education in ${educationArray.map(edu => `${edu.degreeName}${edu.endDateIsPresent ? ' (presently studying)' : ''}`).join(', ')}. Do not fabricate any information, but phrase it professionally and align it with the role requirements and under 120 words and in new zealand english.
    `;

    // 'nectaPrompt'
    const nectaPrompt = `
    Based on the role description "${engagement.description}", the responsibilities "${engagement.responsibilities}", and the required technical skills [${technicalSkills.join(', ')}], highlight the candidate's experience, education and any behavioural or cultural fit to ensure they're presented in the best light for this role, written as a recruiter selling the candidate to the hiring manager, write in New Zealand English and do not use any starting titles or headers. The candidate has experience as ${smallExperience}
    and education in ${educationArray.map(edu => `${edu.degreeName}${edu.endDateIsPresent ? ' (presently studying)' : ''}`).join(', ')}. Do not fabricate any information, but phrase it professionally and align it with the role requirements and under 80 words and in new zealand english.
    `;

    // 'skillsPrompt'
    const skillsPrompt = `
    Based on the role description "${engagement.description}", the responsibilities "${engagement.responsibilities}", and the required technical skills [${technicalSkills.join(', ')}], create a list of skills split by commas, the skills the candidate has relative to the job. Including technical, software, and applicable skills. Nothing should be written but the list of skills. Write in New Zealand English and do not use any starting titles or headers. The candidate has experience as ${smallExperience}
    and education in ${educationArray.map(edu => `${edu.degreeName}${edu.endDateIsPresent ? ' (presently studying)' : ''}`).join(', ')}. Do not fabricate any information, do not use any names, this is a list of skills only, but phrase it professionally and align it with the role requirements and under 12 words.
    `;

    // 'jobSummaryPrompt'
    const jobSummaryPrompt = `
    Based on the role description "${engagement.description}", the responsibilities "${engagement.responsibilities}", and the required technical skills [${technicalSkills.join(', ')}], provide individual summaries for each of the following experiences as a candidate to a hiring manager:

    1. ${topThreeExperienceArray[0]?.title} at ${topThreeExperienceArray[0]?.company}: ${topThreeExperienceArray[0]?.summary || 'No summary provided'}
    2. ${topThreeExperienceArray[1]?.title} at ${topThreeExperienceArray[1]?.company}: ${topThreeExperienceArray[1]?.summary || 'No summary provided'}
    3. ${topThreeExperienceArray[2]?.title} at ${topThreeExperienceArray[2]?.company}: ${topThreeExperienceArray[2]?.summary || 'No summary provided'}

    Do not fabricate any information. Write each summary as a distinct paragraph with a maximum of 30 words. Do not use any headings for each, just the summary, the title is provided elsewhere. Return the information as 1. first summary 2. second summary 3. third summary on separate lines.
    `;

    // 'jobResponsibilitiesPrompt'
    const jobResponsibilitiesPrompt = `
    Based on the role description "${engagement.description}", the responsibilities "${engagement.responsibilities}", and the required technical skills [${technicalSkills.join(', ')}], provide individual responsibilities for each of the following experiences as a candidate to a hiring manager, if no responsibilities, create from summary:

    1. ${topThreeExperienceArray[0]?.title} at ${topThreeExperienceArray[0]?.company}: ${topThreeExperienceArray[0]?.responsibilities || 'No responsibilities provided'}
    2. ${topThreeExperienceArray[1]?.title} at ${topThreeExperienceArray[1]?.company}: ${topThreeExperienceArray[1]?.responsibilities || 'No responsibilities provided'}
    3. ${topThreeExperienceArray[2]?.title} at ${topThreeExperienceArray[2]?.company}: ${topThreeExperienceArray[2]?.responsibilities || 'No responsibilities provided'}

    Do not fabricate any information. Write each summary as a distinct paragraph with a maximum of 30 words. Do not use any headings for each, just the responsibilities that the candidate has completed in the engagement, the title is provided elsewhere. Return the information as 1. first responsibilities 2. second responsibilities 3. third responsibilities on separate lines.
    `;


    // Array to store the prompts
    const prompts = [aboutPrompt, jobSummaryPrompt, jobResponsibilitiesPrompt, nectaPrompt, skillsPrompt];

    // Fetching responses from OpenAI
    const openaiResponses = await Promise.all(prompts.map(async (prompt) => {
      const myInit = {
        response: true,
        body: {
          prompt,
          tokens: 150  // Adjusted the tokens to get a slightly lengthier response
        }
      };

      try {
        const response = await API.post(apiName, path, myInit);
        if (!response.data || !response.data.response) {
            throw new Error("Invalid response from API");
        }
        return response.data.response;
      } catch (error) {
        console.error('Failed to call the API', error);
        throw error;
      }
    }));
        
    let [modifiedAbout, modifiedSummary, modifiedResponsibilities, modifiedNectaSummary, modifiedSkills] = openaiResponses;


    if (!modifiedAbout || modifiedAbout.trim() === "") modifiedAbout = profile.about;
    
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  
  const removeTitleFromText = (text, experience) => {
    const pattern = `${experience.title} at ${experience.company}:`;
    return text.replace(pattern, "").trim();
  };
  
  let summaries = modifiedSummary
      .split("\n")
      .map(s => s.trim())
      .map(s => s.replace(/^\d+\.\s*/, "")) // Removing leading numbers followed by a period and space
      .filter(s => s !== "")
      .map((s, index) => removeTitleFromText(s, topThreeExperience[index] || {}));
  
  let responsibilities = modifiedResponsibilities
      .split("\n")
      .map(s => s.trim())
      .map(s => s.replace(/^\d+\.\s*/, "")) // Removing leading numbers followed by a period and space
      .filter(s => s !== "")
      .map((s, index) => removeTitleFromText(s, topThreeExperience[index] || {}));
  


    const firstThreeJobs = topThreeExperience.map((exp, index) => {
        return {
            jobTitle: exp.title,
            companyName: exp.company,
            dateRange: formatDateRange(exp.startDate, exp.endDate, exp.endDateIsPresent),
            summary: summaries[index] || exp.summary || ' ',
            responsibilities: responsibilities[index] || exp.responsibilities || ' ',
            hasResponsibilities: !!exp.responsibilities,
        };
    });


    if (!modifiedSummary || modifiedSummary.trim() === "") modifiedSummary = firstThreeJobs[0].summary;


    const remainingJobs = sortedExperience.slice(3, 9).map((exp) => ({
      jobTitle: exp.title,
      companyName: exp.company,
      dateRange: `${moment(exp.startDate).format('MMM YY')} - ${moment(exp.endDate).format('MMM YY')}`,
    }));

    // Definitions for hasRemainingJobs and hasFirstThreeEdu
    const hasRemainingJobs = remainingJobs && remainingJobs.length > 0;
    const hasFirstThreeEdu = firstThreeEdu && firstThreeEdu.length > 0;
    const hasSkills = modifiedSkills.length >0;
    const hasNectaExperience = modifiedNectaSummary.length >0;
    // console.log(hasNectaExperience)
    // console.log(hasSkills)
    // console.log("Modified Necta Summary: ", modifiedNectaSummary);
    // console.log("Modified Skills: ", modifiedSkills);

    let today = moment();
    let availableDateValue;

      if (preference.availableDate) {
          let availableDateMoment = moment(preference.availableDate);
          availableDateValue = availableDateMoment.isBefore(today) 
              ? today.format('DD/MM/YYYY') 
              : availableDateMoment.format('DD/MM/YYYY');
      } else if (preference.noticePeriod) {
          availableDateValue = `${preference.noticePeriod} weeks notice`;
      } else {
          availableDateValue = '4 weeks notice';
      }

      const formatSalary = (salary) => {
        return Number(salary).toLocaleString();
      };
    // Set template data
    doc.setData({
      fullName: `${profile.firstName} ${profile.lastName}`,
      title: engagement.title || 'Candidate',
      availableDate: availableDateValue,
      about: modifiedAbout || profile.about || 'N/A',
      maxSalary: engagement.isContract
        ? `$${preference.maxRate} /hr`
        : `$${formatSalary(preference.maxSalary)}` || ' ',
      workPolicy:
        preference.workPolicy === 'workFromHome'
          ? 'Remote'
          : preference.workPolicy === 'mixed'
          ? 'Hybrid'
          : 'On Site',
      mobile: contact.mobile,
      email: contact.userEmail, 
      firstThreeJobs: firstThreeJobs,
      remainingJobs: remainingJobs,
      firstThreeEdu: firstThreeEdu,
      hasRemainingJobs: hasRemainingJobs,
      hasFirstThreeEdu: hasFirstThreeEdu,
      hasNectaExperience: true,
      nectaSummary: modifiedNectaSummary,
      hasSkills: true,
      skills: modifiedSkills,
      offered: offered,
      confirmed: accepted,
      submitted: submitted,
      hasData: hasData,
    });

    try {
      // console.log('Rendering doc...');
      // Render the document
      doc.render();
      // console.log('Doc rendered successfully.');
    } catch (error) {
      if (error instanceof Error) {
        var e = {
          message: error.message,
          name: error.name,
          stack: error.stack,
        };
        // console.log(JSON.stringify({ error: e }));
      }
      throw error;
    }

    // Generate a Blob from the document
    const buffer = doc.getZip().generate({ type: 'blob' });

    // Create a Blob with appropriate MIME type
    const downloadBlob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    // Create a URL for the Blob
    const url = URL.createObjectURL(downloadBlob);

    // Create a link element for download
    const link = document.createElement('a');
    link.href = url;
    link.download = `${cvFileName}.docx`;

    // Simulate a click to initiate download
    // console.log('About to initiate download...');
    link.click();
    // console.log('Download initiated.');

    // Dispose the Object URL to free up memory
    URL.revokeObjectURL(url);
  } catch (error) {
    // console.error(`Failed to fetch .docx file: ${error}`);
  }
};
