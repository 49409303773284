import { memo } from "react";
import Select, { GroupBase, StylesConfig } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";
// import classes from "./styles.module.scss";

const customStyles = (
  _?: boolean
): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () => ({
    display: "none",
  }),
  container: (provided) => ({
    ...provided,
    margin: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    background: state.isSelected ? "#e8f1fb" : "#ffffff",
    color: "#2c2c2c",
    "&:hover": {
      background: "#e8f1fb",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.menuIsOpen ? "1px solid #f96332" : "1px solid #e3e3e3",
    borderRadius: "30px",
    boxShadow: "none",
    backgroundColor: state.isDisabled ? "#e3e3e3" : "#888888",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      borderColor: "#f96332",
      transition: "all 0.3s ease-in-out",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: "18px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "500",
    margin: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#ffffff",
    "&:hover": {
      color: "#ffffff",
    },
    "&:focus": {
      color: "#ffffff",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#cccccc" : "#ffffff",
    fontSize: "0.8571em",
    fontWeight: 400,
    margin: 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
});

interface Props extends StateManagerProps {
  className?: string;
  bindKey?: any;
  bindLabel?: any;
}

const SelectYear = memo(({ className, bindKey, bindLabel, ...rest }: Props) => {
  return (
    <Select
      className={className}
      styles={customStyles()}
      menuPortalTarget={document.querySelector("body")}
      getOptionValue={(option) => option[bindKey || "id"]}
      getOptionLabel={(option) => option[bindLabel || "name"]}
      noOptionsMessage={() => "..."}
      isSearchable={false}
      {...rest}
    />
  );
});

export default SelectYear;
