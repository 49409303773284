import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_ALL_CANDIDATE_LIST_REQUEST,
  setCandidateListReducer,
} from "redux/reducers/Employer/actionTypes";
import { TalentProfileService } from "services/Employer/Listing/Talent/TalentProfile";

function* requestGetCandidateList() {
  try {
    yield put(setLoading(true));
    const [jobList] = yield all([call(TalentProfileService.getAllCandidates)]);
    yield put(setCandidateListReducer(jobList));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getCandidateList() {
  yield takeLatest(GET_ALL_CANDIDATE_LIST_REQUEST, requestGetCandidateList);
}

export default getCandidateList;
