import { memo } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import classes from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const WelcomeModal = memo(({ isOpen, onClose }: Props) => {
  return (
    <>
      <Modal isOpen={isOpen} toggle={onClose} className={classes.termModal}>
        <ModalHeader toggle={onClose}>
          Welcome to the Future!
        </ModalHeader>
        <ModalBody>
          <div>
            If you have used our CV/LinkedIn Parser, please check your Profile and navigate to your Settings to confirm and review your extracted information.
          </div>
          <br />
          <div>
            Here is a brief onboarding video to help you get started.
          </div>
          <div className="video-container mt-3">
            <video width="100%" controls>
              <source src="https://nectachatbot.s3.ap-southeast-2.amazonaws.com/2024-08-14+15-59-24_1.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

export default WelcomeModal;
