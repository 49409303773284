import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Spinner, Alert } from 'reactstrap';

import { JobService } from 'services/Employer/Listing/Job';
import mammoth from 'mammoth';
import pdfToText from 'react-pdftotext';

const UploadJobDescriptionModal = ({ isOpen, toggle, organizationId, onFileParsed }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadSuccess(false);
    setUploadError(null);
  };

  const extractTextFromPDF = async (file) => {
    const text = await pdfToText(file);
    return text;
  };

  const extractTextFromDocx = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const { value: text } = await mammoth.extractRawText({ arrayBuffer });
    return text;
  };

  const handleFileUpload = async () => {
    if (file) {
      setIsUploading(true);
      setUploadError(null);
  
      let fileText = '';
  
      try {
        const fileExtension = file.name.split('.').pop();
        if (fileExtension === 'pdf') {
          fileText = await extractTextFromPDF(file);
        } else if (fileExtension === 'docx') {
          fileText = await extractTextFromDocx(file);
        } else if (fileExtension === 'txt') {
          fileText = await file.text();
        } else {
          throw new Error('Unsupported file format');
        }
  
        const parsedData = await JobService.postJobDescription({ prompt: fileText });
        parsedData.filename = file.name;

        // console.log("parsed", parsedData)
        // Pass both the parsed data and the file to the parent component
        onFileParsed(parsedData, file);
   
        setUploadSuccess(true);
        setFile(null); // Clear file input after successful upload
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploadError('Failed to parse file, this is a server timeout issue. Please try again later.');
      } finally {
        setIsUploading(false);
      }
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Upload Job Description</ModalHeader>
      <ModalBody>
        {uploadSuccess && <Alert color="success">File uploaded successfully!</Alert>}
        {uploadError && <Alert color="danger">{uploadError}</Alert>}
        <Form>
          <FormGroup>
            <Label for="fileInput">
              <Button
                color="secondary"
                onClick={() => document.getElementById('fileInput').click()}
                disabled={isUploading}
              >
                {file ? `Selected File: ${file.name}` : 'Click to Upload File'}
              </Button>
            </Label>
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              accept=".doc,.docx,.pdf,.txt"
              style={{ display: 'none' }}
              disabled={isUploading}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        {!isUploading ? (
          <>
            <Button color="primary" onClick={handleFileUpload} disabled={!file}>
              Upload
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </>
        ) : (
          <Spinner color="primary" />
        )}
      </ModalFooter>
    </Modal>
  );
};

export default UploadJobDescriptionModal;