import { memo, useEffect, useState } from 'react'
import ScrollNavbar from 'components/Navbars/Contractor/ScrollNavbar'
import FooterDefault from 'components/Footers/FooterDefault'
import FooterMobile from 'components/Footers/FooterMobile'
import classes from './styles.module.scss'
import { Container, NavItem, NavLink, Nav } from 'reactstrap'
import Connect from './components/Connect'
import Interview from './components/Interview'
import Contract from './components/Contract'
import { OFFER_TYPE } from 'config/constants'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerType } from 'redux/reducers'
import moment from 'moment'
import { JobOfferService } from 'services/Contractor/Offer/Offer'
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes'
import { JobService } from 'services/Employer/Listing/Job'
import { saveAs } from 'file-saver'
import { OrganizationService } from 'services/Employer/NewMember/Organization'
import SeeContract from './SeeContract'

interface OfferProps {}

// eslint-disable-next-line no-empty-pattern
const Offer = memo((props: OfferProps) => {
	const dispatch = useDispatch()
	const { allOffers, profile } = useSelector(
		(state: ReducerType) => state.contractor
	)
	const {
		isOffersToConnectStillExist,
		isOffersToInterviewStillExist,
		isContractualOffersStillExist,
	} = useSelector((state: ReducerType) => state.status)

	const [hTabs, setHTabs] = useState('1')
	const [type, setType] = useState(OFFER_TYPE.CONNECT)
	const [displayMobileMenu, setDisplayMobileMenu] = useState(true)
	const [acceptedOffer, setAcceptedOffer] = useState(null)
	const [acceptedOfferArr, setAcceptedOfferArr] = useState(null)
	const [acceptedJob, setAcceptedJob] = useState(null)
	const [interviewOffersArr, setInterviewOffersArr] = useState([])
	const [activeClients, setActiveClients] = useState([])
	const [modalSeeContract, setModalSeeContract] = useState({
		isOpenModal: false,
		contractor: null,
		contract: null,
		job: null,
	})

	const onCloseSeeContract = () => {
		setModalSeeContract({
			isOpenModal: false,
			contractor: null,
			contract: null,
			job: null,
		})
	}

	const findClientForInterviewOffer = (interviewOffer) => {
    // Find the corresponding job based on the interview offer's job ID
    const correspondingJob = allOffers.jobs.find(job => job.id === interviewOffer.job);
    
    // If a corresponding job is found and it has a 'client' property, return it
    return correspondingJob?.client;
}

	const sortDate = (a, b) => {
		if (moment(a?.endDate).toDate() > moment(b?.endDate).toDate()) {
			return 1
		} else if (moment(a?.endDate).toDate() < moment(b?.endDate).toDate()) {
			return -1
		} else {
			return 0
		}
	}

	const getInformation = async () => {
		dispatch(setLoading(true))
		setInterviewOffersArr([])
		setActiveClients([])
		const acceptedNectaOfferArr = allOffers?.jobOffers.filter(
			(item) => item?.accepted === true && item?.isContraContractUsed
		)
		if (acceptedNectaOfferArr && acceptedNectaOfferArr.length > 0) {
			setAcceptedOffer(acceptedNectaOfferArr[0])
			const acceptedJobArr = allOffers?.jobs.filter(
				(item) => item?.id === acceptedNectaOfferArr[0]?.job
			)
			setAcceptedJob(acceptedJobArr[0])
		}

		// Document history
		const acceptedOfferArray = []
		allOffers?.jobOffers.map((itemJobOffer) => {
			if (itemJobOffer?.accepted === true) {
				allOffers?.jobs.map((itemJob) => {
					if (itemJob?.id === itemJobOffer?.job) {
						acceptedOfferArray.push({
							...itemJobOffer,
							title: itemJob?.title,
						})
					}
				})
			}
		})
		setAcceptedOfferArr(acceptedOfferArray.sort(sortDate))
		//-------------

		const activeClientsArray = []
		const acceptedNectaOfferArrRequest = acceptedNectaOfferArr?.map(
			async (item, index) => {
				await OrganizationService.getEmployerOrganization(item?.employer)
					.then((res) => {
						let startDate = new Date(item?.startDate).getTime()
						const currentDate = new Date().getTime()
						if (startDate < currentDate) {
							startDate = currentDate
						}
						const endDate = new Date(item?.endDate).getTime()
						const duration = Math.ceil(
							(endDate - startDate) / (7 * 24 * 60 * 60 * 1000)
						)
						if (duration > 0) {
							const jobsArr = allOffers?.jobs.filter(
								(job) => job?.id === item?.job
							)
							activeClientsArray.push({
								employerName: res?.name || '',
								duration: duration,
								contract: item,
								job: jobsArr[0],
							})
						}
					})
					.catch((e) => dispatch(setErrorMess(e)))
			}
		)

		const interviewOffersNotExpired = allOffers?.interviewOffers.filter(
			(item) => new Date(item.interviewTime).getTime() > new Date().getTime()
		)
		const interviewOffersArray = []
		const interviewOffersArrRequest = interviewOffersNotExpired.map(
			async (item, index) => {
				await JobOfferService.getEmployerProfile(item?.employer)
					.then((res) => {
						interviewOffersArray.push({
							...item,
							employerName: `${res?.firstName} ${res?.lastName}`,
						})
					})
					.catch((e) => dispatch(setErrorMess(e)))
			}
		)

		await Promise.all([
			...acceptedNectaOfferArrRequest,
			...interviewOffersArrRequest,
		])
			.then(() => {
				activeClientsArray.sort((a, b) => a?.duration - b?.duration)
				setActiveClients(activeClientsArray)
				
				interviewOffersArray.sort(
					(a, b) =>
						new Date(a?.interviewTime).getTime() -
						new Date(b?.interviewTime).getTime()
				)
				setInterviewOffersArr(interviewOffersArray)
			})
			.catch((e) => dispatch(setErrorMess(e)))
			.finally(() => dispatch(setLoading(false)))
	}

	useEffect(() => {
		console.log("allOffers", allOffers)	
		getInformation()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allOffers, dispatch])

	useEffect(() => {
		// Check offers to connect
		if (isOffersToConnectStillExist) {
			document
				.getElementById('offers-to-connect-dot')
				.classList.remove('d-none')
		} else {
			document
				.getElementById('offers-to-connect-dot')
				.classList.add('d-none')
		}
		// Check offers to interview
		if (isOffersToInterviewStillExist) {
			document
				.getElementById('offers-to-interview-dot')
				.classList.remove('d-none')
		} else {
			document
				.getElementById('offers-to-interview-dot')
				.classList.add('d-none')
		}
		// Check contractual offers
		if (isContractualOffersStillExist) {
			document
				.getElementById('contractual-offers-dot')
				.classList.remove('d-none')
		} else {
			document
				.getElementById('contractual-offers-dot')
				.classList.add('d-none')
		}
	}, [
		isOffersToConnectStillExist,
		isOffersToInterviewStillExist,
		isContractualOffersStillExist,
	])

	const downloadContract = (key, identityId) => {
		dispatch(setLoading(true))
		JobService.getContract(key, identityId)
			.then((res) => {
				const myFile = new File([res as BlobPart], `${key}.docx`, {
					type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				})
				saveAs(myFile, `${key}.docx`)
			})
			.catch((e) => dispatch(setErrorMess(e)))
			.finally(() => dispatch(setLoading(false)))
	}

	const handleSeeContract = (activeClient) => {
		setModalSeeContract({
			isOpenModal: true,
			contractor: profile?.basicProfile,
			contract: activeClient?.contract,
			job: activeClient?.job,
		})
	}

	return (
		<>
			<ScrollNavbar />
			<div className='wrapper' id='detect_swipe'>
				<div className={classes.header} />
				<div className={classes.section}>
					<Container className={classes.container}>
						<div className={classes.contentLeft}>
							<div className={classes.activeClientContainer}>
								<p className={clsx(classes.activeClientTitle, 'mb-1')}>
									Active Clients
								</p>
								{activeClients?.map((item, index) => {
									return (
										<div
											className={classes.activeClientItem}
											key={index}
											onClick={() => handleSeeContract(item)}
										>
											<p
												className={clsx(
													classes.activeClientName,
													'mb-2'
												)}
											>
												{item?.employerName}
											</p>
											<p
												className={clsx(
													classes.activeClientDuration,
													'mb-2'
												)}
											>
												{item?.duration}
												{item?.duration <= 1
													? ' week left'
													: ' weeks left'}
											</p>
										</div>
									)
								})}
							</div>
							<div className={clsx(classes.documentHistory, 'mb-1')}>
								Document History
							</div>
							{acceptedOfferArr
								?.slice()
								.sort(
									(a, b) =>
										moment(b?.endDate).valueOf() -
										moment(a?.endDate).valueOf()
								)
								.map((item, index) => (
									<div key={index}>
										<p className={clsx(classes.actionsTitle, 'mb-0')}>
											{item?.title}{' '}
											{moment(item?.endDate) < moment() &&
												' - Ended'}
										</p>
										<p
											className={clsx(classes.downloadBtn, 'mb-2')}
											onClick={() =>
												downloadContract(item?.contractUrl, null)
											}
										>
											Download
										</p>
									</div>
								))}
							<div className={classes.activeClientContainer}>
								<p
									className={clsx(
										classes.activeClientTitle,
										'mt-3 mb-1'
									)}
								>
									Actions
								</p>
								{acceptedOffer &&
									acceptedOffer?.isContraContractUsed && (
										<>
											{acceptedJob && (
												<p
													className={clsx(
														classes.actionsTitle,
														'mb-0'
													)}
												>
													{acceptedJob?.companyName}
												</p>
											)}
											<p className={classes.actionsContent}>
												Start date:{' '}
												{moment(acceptedOffer?.startDate).format(
													`DD/MM/YYYY`
												)}
											</p>
										</>
									)}

						{interviewOffersArr?.map((item, index) => (
								<div key={index}>
										<p className={clsx(classes.actionsTitle, 'mb-0')}>
												Employer Name: {findClientForInterviewOffer(item) || item.employerName}
										</p>
										<p
											className={clsx(
												classes.actionsContent,
												'mb-0'
											)}
										>
											Date and time:{' '}
											{moment(item?.interviewTime).format(
												`DD/MM/YYYY h:mm:ss a`
											)}
										</p>
										<p
											className={clsx(
												classes.actionsContent,
												'mb-0'
											)}
										>
											Interview type: {item?.type}
										</p>
										{item?.type === 'inPerson' ? (
											<p
												className={clsx(
													classes.actionsContent,
													'mb-2'
												)}
											>
												Location: {item?.interviewAddress}
											</p>
										) : (
											<p
												className={clsx(
													classes.actionsContent,
													'mb-2'
												)}
											>
												Link:{' '}
												<a
													className={classes.interviewLink}
													href={item?.interviewLink}
												>
													{item?.interviewLink}
												</a>
											</p>
										)}
									</div>
								))}
							</div>
						</div>
						<div className={classes.contentRight}>
							<Nav
								className={clsx('nav-pills-primary', classes.navbar, {
									[classes.offerMobile]: !displayMobileMenu,
								})}
								pills
							>
									
								<NavItem>
									<NavLink
										className={
											hTabs === '1'
												? clsx('active', classes.navLinkItem)
												: classes.navLinkItem
										}
										onClick={(e) => {
											e.preventDefault()
											setHTabs('1')
											setType(OFFER_TYPE.CONNECT)
											setDisplayMobileMenu(false)
										}}
									>
										<div
											id='offers-to-connect-dot'
											className={clsx(classes.offersDot, 'd-none')}
										></div>
										Offer to Connect
									</NavLink>
									
								</NavItem>
								<NavItem>
									<NavLink
										className={
											hTabs === '2'
												? clsx('active', classes.navLinkItem)
												: classes.navLinkItem
										}
										onClick={(e) => {
											e.preventDefault()
											setHTabs('2')
											setType(OFFER_TYPE.INTERVIEW)
											setDisplayMobileMenu(false)
										}}
									>
										<div
											id='offers-to-interview-dot'
											className={clsx(classes.offersDot, 'd-none')}
										></div>
										Offer to Interview
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={
											hTabs === '3'
												? clsx('active', classes.navLinkItem)
												: classes.navLinkItem
										}
										onClick={(e) => {
											e.preventDefault()
											setHTabs('3')
											setType(OFFER_TYPE.CONTRACT)
											setDisplayMobileMenu(false)
										}}
									>
										<div
											id='contractual-offers-dot'
											className={clsx(classes.offersDot, 'd-none')}
										></div>
										Contractual Offer
									</NavLink>
								</NavItem>
							</Nav>
							<div
								className={clsx({
									[classes.offerMobile]: displayMobileMenu,
								})}
							>
								<div className={classes.backContainer}>
									<i
										className='fa fa-arrow-left'
										onClick={() => setDisplayMobileMenu(true)}
									/>
								</div>
								{type === OFFER_TYPE.CONNECT && <Connect />}
								{type === OFFER_TYPE.INTERVIEW && <Interview />}
								{type === OFFER_TYPE.CONTRACT && <Contract />}
						
							</div>
						</div>
					</Container>
				</div>
				<FooterDefault />
				<FooterMobile />
			</div>
			<SeeContract
				onClose={onCloseSeeContract}
				isOpen={modalSeeContract.isOpenModal}
				contractor={modalSeeContract.contractor}
				contract={modalSeeContract.contract}
				job={modalSeeContract.job}
			/>
		</>
	)
})

export default Offer
