import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import moment from 'moment';
import { API } from 'aws-amplify';
import { JobService } from "services/Employer/Listing/Job";

export const downloadJD = async (formData, setting) => {
  ;

  // Fetch OpenAI responses
  const apiName = process.env.REACT_APP_API_NAME;
  const path = '/openai';

  const prompts = [
    `Write a professional description in new zealand english with no titles and if there is no data, ignore it, for the role of ${formData.title} at ${formData.company}, located in ${formData.address}. The company culture is ${setting.culture}, its mission or strategy is ${setting.mission} and it operates in the ${setting.industry} industry. they prefer ${setting.behaviouralSkills} as behavioural skills. the opportunity description is ${formData.description} complete it in two paragraphs. One about the company and one about the opportunity. `,
    `Enumerate key responsibilities in new zealand english as a numbered list without a title or prequel like "key responsibilities" for a ${formData.title} at ${formData.company}. This role requires skills like ${formData.skill.join(", ")} and here is the job description ${formData.description}, limit this to eight responsibilities`,
    `Describe the ideal candidate for the role of ${formData.title} at ${formData.name}. The role is ${formData.title}, targets a candidate who is a ${formData.numberOfExperience.name} , requires a ${formData.visaType.name} visa type. The work policy is ${formData.policy.name}.  it operates in the ${setting.industry} industry. they prefer ${setting.behaviouralSkills} as behavioural skills. culture of the company is ${setting.culture}. Respond using New Zealand English`,
  ];


  const openaiResponses = await Promise.all(prompts.map(async (prompt) => {
    const myInit = {
      response: true,
      body: {
        prompt,
        tokens: 60
      }
    };

    try {
      const response = await API.post(apiName, path, myInit);
      return response.data.response;
    } catch (error) {
      // console.error('Failed to call the API', error);
    }
  }));

  let [description, responsibilities, candidate] = openaiResponses;

  let respList = responsibilities.split(/(\d+\.\s|•\s|\-\s|;\s|,\s)/);
    respList = respList.filter(resp => resp.trim() !== "" && !resp.match(/(\d+\.\s|•\s|\-\s|;\s|,\s)/));
    respList = respList.map((resp) => {
      resp = resp.trim();
      if (resp.endsWith('.')) {
        resp = resp.slice(0, -1);
      }
      return resp;
    });

    
    // Remove duplicates
    respList = respList.filter((resp, index) => {
      return respList.indexOf(resp) === index;
    });
    
    responsibilities = respList;
  




  try {
    const templateBlob = await JobService.getTemplate("jdTemplate.docx");
    const templateBuffer = await blobToArrayBuffer(templateBlob as Blob);

    var zip = new PizZip(templateBuffer);

    // Load the PizZip instance into a Docxtemplater instance
    var jd = new Docxtemplater().loadZip(zip);

    // Generate a JD file name
    const jdFileName = `${formData.title}_JobDescription_Necta`;

    // Set template data
    jd.setData({
      title: formData.title,
      company: formData.company,
      jobType: formData.jobType.name,
      location: formData.address,
      workPolicy: formData.policy.name,
      proficiency: formData.numberOfExperience.name,
      remuneration:
        formData.jobType.name === 'Contract'
          ? `$${formData.hourlyRate} /hr`
          : `$${formData.hourlyRate} /per annum`,
      startDate: moment(formData.startDate).format('DD/MM/YYYY'),
      description: description,
      responsibilities: responsibilities,
      skills: formData.skill.map((skill) => skill),
      visaType: formData.visaType.name,
      candidate: candidate,
    });
    // console.log(formData, setting);
    try {
      // console.log('Rendering JD doc...');
      // Render the document
      jd.render();
      // console.log('JD doc rendered successfully.');
    } catch (error) {
      if (error instanceof Error) {
        var e = {
          message: error.message,
          name: error.name,
          stack: error.stack,
        };
        // console.log(JSON.stringify({ error: e }));
      }
      throw error;
    }

    // Generate a Blob from the document
    const buffer = jd.getZip().generate({ type: 'blob' });

    // Create a Blob with appropriate MIME type
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element for download
    const link = document.createElement('a');
    link.href = url;
    link.download = `${jdFileName}.docx`;

    // Simulate a click to initiate download
    // console.log('About to initiate download...');
    link.click();
    // console.log('Download initiated.');

    // Dispose the Object URL to free up memory
    URL.revokeObjectURL(url);
  } catch (error) {
    // console.error(`Failed to fetch .docx file: ${error}`);
  }
};

const blobToArrayBuffer = async (blob: Blob): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
};
