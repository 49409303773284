import { API } from "aws-amplify";

export class ContractorTimesheetService {
  static async getAllTimesheet(contractorId): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/timesheets`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getOneTimesheet(contractorId, timesheetId): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/timesheets/${timesheetId}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postTimesheet(contractorId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/timesheets`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putTimesheet(contractorId, timesheetId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/timesheets/${timesheetId}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async deleteTimesheet(contractorId, timesheetId): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/timesheets/${timesheetId}`;
    const myInit = {
      response: true,
    };
    return API.del(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async sendNewTimesheetEmail(timesheetId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/timesheets/${timesheetId}/timesheetcreateemail`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
}
