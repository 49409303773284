import { Fragment, memo, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  CardHeader,
  Table,
} from "reactstrap";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import classes from "./styles.module.scss";
import clsx from "clsx";
import FooterMobile from "components/Footers/FooterMobile";
import SelectYear from "components/Common/SelectYear";
import { InvoiceService as ContractorInvoiceService } from "services/Contractor/Books/Invoice";
import { BillService } from "services/Contractor/Bill/Bill";
import { XeroInvoiceService } from "services/Xero/XeroInvoice";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import moment from "moment";
import ManualInvoice from "./ManualInvoice";
import saveAs from "file-saver";
import { ClientInfoService } from "services/Contractor/Books/ClientInfo";
import { LineItemService } from "services/Contractor/Books/lineItem";

interface Props {}

const Invoice = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const [invoiceList, setInvoiceList] = useState([]);
  const [manualInvoiceModal, setManualInvoiceModal] = useState(false);

  useEffect(() => {
    let _mainInvoiceList = [];
    BillService.getAllContractorBill(user?.id)
      .then(async (res) => {
        if (res?.length) {
          const xeroBillIds = res.map((item) => item?.xeroBillId);
          await XeroInvoiceService.getInvoicesByInvoiceIds(
            user?.id,
            xeroBillIds.join(", ")
          )
            .then((xeroInvoices) => {
              if (xeroInvoices?.Invoices?.length) {
                const mainInvoiceList = xeroInvoices.Invoices.map(
                  (xeroItem) => {
                    const tempItem = res.find(
                      (i) => i.xeroBillId === xeroItem.InvoiceID
                    );
                    return {
                      ...xeroItem,
                      employerFirstName: tempItem?.employerFirstName,
                      employerLastName: tempItem?.employerLastName,
                      timesheetPaid: tempItem?.timesheetPaid,
                      timesheetApproved: tempItem?.timesheetApproved,
                      timesheetPending: tempItem?.timesheetPending,
                      timesheetDeclined: tempItem?.timesheetDeclined,
                      timesheetWeekEnding: tempItem?.timesheetWeekEnding,
                    };
                  }
                );
                _mainInvoiceList = [...mainInvoiceList];
              }
            })
            .catch((error) => dispatch(setErrorMess(error)));
        }
      })
      .then(() => {
        ContractorInvoiceService.getAllManualInvoice(user?.id)
          .then((allManualInvoices) => {
            allManualInvoices.map(async (invoice) => {
              await Promise.all([
                ClientInfoService.getOneClient(user?.id, invoice?.client),
                LineItemService.getAllLineItem(user?.id, invoice?.id),
              ]).then((infoRes) => {
                const [clientInfo, lineItem] = infoRes;
                _mainInvoiceList.push({
                  Status: invoice?.status,
                  employerFirstName: clientInfo?.firstName,
                  employerLastName: clientInfo?.lastName,
                  timesheetPaid:
                    invoice?.status === "AUTHORISED" ? false : true,
                  timesheetWeekEnding: lineItem?.dueDate,
                  Total: lineItem?.total,
                  SubTotal: lineItem?.rate,
                  TotalTax: lineItem?.total * 0.15,
                });
              });
            });
          })
          .then(() => {
            setInvoiceList(_mainInvoiceList);
          });
      })
      .catch((err) => dispatch(setErrorMess(err)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleCloseManualInvoice = () => {
    setManualInvoiceModal(false);
  };

  const handleOpenManualInvoice = () => {
    setManualInvoiceModal(true);
  };

  const handleDownloadInvoice = (id) => {
    dispatch(setLoading(true));
    XeroInvoiceService.downloadPDFInvoice(id)
      .then((res) => {
        const temp = Uint8Array.from(res?.data);
        const blob = new Blob([temp.buffer], { type: "application/pdf" });
        saveAs(blob, `${id}.pdf`);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <Fragment>
      <ScrollNavbar />
      <div
        className={clsx("page-header header-filter", classes.wrapper)}
        filter-color="yellow"
      >
        <div className={classes.header} />
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project14.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.container}>
            <Card className={classes.card}>
              <div className={classes.buttonContainer}>
                <SelectYear
                  placeholder="Year"
                  options={[{ id: 1, name: "April 2022 - March 2023" }]}
                />
                <p
                  className={classes.addButton}
                  onClick={() => handleOpenManualInvoice()}
                >
                  <i className="now-ui-icons ui-1_simple-add" />
                  Manual Invoice
                </p>
              </div>
              <CardHeader>
                <div className={classes.awaitingPaymentSection}>
                  <p className={classes.sectionItem}>Awaiting Payment</p>
                  <p className={classes.sectionItem}>
                    $
                    {invoiceList
                      .map((invoice) =>
                        !(invoice?.Status === "PAID") ? invoice?.Total : 0
                      )
                      .reduce((partialSum, a) => partialSum + a, 0)}
                  </p>
                </div>
                <div className={classes.paidSection}>
                  <p className={classes.sectionItem}>Paid</p>
                  <p className={classes.sectionItem}>
                    $
                    {invoiceList
                      .map((invoice) =>
                        invoice?.Status === "PAID" ? invoice?.Total : 0
                      )
                      .reduce((partialSum, a) => partialSum + a, 0)}
                  </p>
                </div>
                <hr className={classes.divider} />
              </CardHeader>
              <CardBody className={classes.cardBody}>
                <div className={classes.tableContainer}>
                  <Table className={classes.table} responsive>
                    <thead>
                      <tr>
                        <th className={classes.statusItem}>STATUS</th>
                        <th className={classes.hideOnMobile}>DATE</th>
                        <th className={classes.clientItem}>CLIENT</th>
                        <th className={classes.hideOnMobile}>NET</th>
                        <th className={classes.hideOnMobile}>GST</th>
                        <th>TOTAL</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceList.map((item, index) => (
                        <tr key={index}>
                          <td className={classes.hideOnMobile}>
                            {(item?.Status === "PAID" && "PAID") ||
                              (item?.Status === "AUTHORISED" && "APPROVED") ||
                              item?.Status}
                          </td>
                          <td className={classes.hideOnDesktop}>
                            <div
                              className={
                                item?.Status === "PAID"
                                  ? classes.paidStatus
                                  : classes.approvedStatus
                              }
                            />
                          </td>
                          <td className={classes.hideOnMobile}>
                            {moment(item?.timesheetWeekEnding).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td>
                            {item?.employerFirstName} {item?.employerLastName}
                          </td>
                          <td className={classes.hideOnMobile}>
                            ${item?.SubTotal}
                          </td>
                          <td className={classes.hideOnMobile}>
                            ${item?.TotalTax}
                          </td>
                          <td>${item?.Total}</td>
                          <td>
                            <Button
                              onClick={() =>
                                handleDownloadInvoice(item?.InvoiceID)
                              }
                              color="info"
                            >
                              Download Details
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
        <Footer className={classes.footer} />
      </div>
      <FooterMobile />
      <ManualInvoice
        onClose={handleCloseManualInvoice}
        isOpen={manualInvoiceModal}
      />
    </Fragment>
  );
});

export default Invoice;
