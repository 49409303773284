import InputMask from "react-input-mask";
import { memo } from "react";
import { Controller, FieldError, FieldErrors, Merge } from "react-hook-form";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { FormGroup } from "reactstrap";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

interface Props extends StateManagerProps {
  className?: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrors<any>>;
  name?: string;
  control?: any;
  placeHolder?: string;
  format?: string;
  [key: string]: any;
}

const CustomInputMask = memo(
  ({
    className,
    name,
    control,
    defaultValue,
    placeHolder,
    format,
    errorMessage,
    ...rest
  }: Props) => {
    return (
      <FormGroup className={clsx(classes.root, className)}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <InputMask
                className="form-control"
                mask={format}
                placeholder={placeHolder}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                {...rest}
              />
            );
          }}
        />
        {errorMessage && (
          <span className="text-danger ml-2 mt-1 d-block">{errorMessage}</span>
        )}
      </FormGroup>
    );
  }
);

export default CustomInputMask;
