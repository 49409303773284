import { WORK_POLICY } from "config/constants";
export interface PreferenceParams {
  id?: number;
  availableDate?: string;
  noticePeriod?: number;
  minRate?: number;
  maxRate?: number;
  radius?: number;
  workPolicy?: string;
  preferFixedTerm?: boolean;
  preferPermanent?: boolean;
  minSalary?: number;
  maxSalary?: number;
}

export const PreferenceAvailableTypes = [
  { id: 1, name: "A specific date" },
  { id: 2, name: "Two weeks notice" },
  { id: 4, name: "Four weeks notice" },
];

export const PreferencePolicyTypes = [
  { id: 1, name: "Office", value: WORK_POLICY.OFFICE },
  { id: 2, name: "Hybrid", value: WORK_POLICY.MIXED },
  { id: 3, name: "Work From Home", value: WORK_POLICY.WORK_FROM_HOME },
  { id: 4, name: "All", value: WORK_POLICY.ALL },
];

export const PreferenceTravelingRadius = [
  { id: 1, name: "5km", value: 5 },
  { id: 2, name: "10km", value: 10 },
  { id: 3, name: "20km", value: 20 },
  { id: 4, name: "40km", value: 40 },
  { id: 5, name: "100km", value: 100 },
  { id: 6, name: "200km", value: 200 },
  { id: 7, name: "500km", value: 500 },
];
