import { put, takeLatest, call } from "redux-saga/effects";
import { setLoadingAuth } from "redux/reducers/Status/actionTypes";
import {
  GET_USER_REQUEST,
  setUserReducer,
} from "redux/reducers/User/actionTypes";
import { UserService } from "services/User";

function* requestGetUser() {
  try {
    const user = yield call(UserService.getUser);
    yield put(setUserReducer(user));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoadingAuth(false));
  }
}

function* getUser() {
  yield takeLatest(GET_USER_REQUEST, requestGetUser);
}

export default getUser;
