import React, { memo, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { JobService } from "services/Employer/Listing/Job";
import SignaturePad from "signature_pad";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import mammoth from "mammoth";
import classes from "./styles.module.scss";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import ImageModule from "docxtemplater-image-module-free";
import { saveAs } from "file-saver";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import { XeroContactService } from "services/Xero/XeroContact";
import { OrganizationService } from "services/Employer/NewMember/Organization";

interface SignTermProps {
  onCloseSignTermModal: () => void;
  handleAcceptTerm: () => Promise<void>;
}

const SignTerm = memo((props: SignTermProps) => {
  const { handleAcceptTerm, onCloseSignTermModal } = props;
  const { user } = useSelector((state: ReducerType) => state.user);
  const dispatch = useDispatch();
  const { setting } = useSelector((state: ReducerType) => state.employer);

  const [signature, setSignature] = useState<string | null>(null);
  const [termsContent, setTermsContent] = useState<string>("");

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const res = await JobService.getTemplate("Terms-Of-Business-copilot.docx");
        
        // Ensure response is a Blob
        let blob: Blob;
        if (res instanceof Blob) {
          blob = res;
        } else {
          throw new Error("Unsupported response type");
        }
  
        const arrayBuffer = await blob.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setTermsContent(result.value);
      } catch (error) {
        console.error("Error fetching terms content", error);
        dispatch(setErrorMess("Error fetching terms content"));
      }
    };
  
    fetchTerms();
  
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    function resizeCanvas() {
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d")?.scale(ratio, ratio);
    }
    if (canvas) {
      window.onresize = resizeCanvas;
      resizeCanvas();
      const signaturePad = new SignaturePad(canvas, {
        backgroundColor: "#ffffff",
        penColor: "#000000",
      });
      document.getElementById("clear")?.addEventListener("click", () => {
        signaturePad.clear();
      });
      document.getElementById("undo")?.addEventListener("click", () => {
        var data = signaturePad.toData();
        if (data) {
          data.pop();
          signaturePad.fromData(data);
        }
      });
      document.getElementById("submit-contract")?.addEventListener("click", () => {
        const data = signaturePad.toDataURL("image/png");
        setSignature(data);
      });
    }
  }, [dispatch]);

  function base64Parser(dataURL: string) {
    const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
    if (typeof dataURL !== "string" || !base64Regex.test(dataURL)) {
      return false;
    }
    const stringBase64 = dataURL.replace(base64Regex, "");
    const binaryString = window.atob(stringBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes.buffer;
  }

  const handleSubmit = async (res: Blob) => {
    try {
      const myFile = new File([res], "Terms-Of-Business-copilot.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      const imageOpts = {
        getImage(tag: string) {
          return base64Parser(tag);
        },
        getSize() {
          return [210, 120];
        },
      };
      const reader = new FileReader();
      reader.readAsBinaryString(myFile);
      reader.onload = async function (evt) {
        const content = evt.target?.result as string;
        const zip = new PizZip(content);
        let doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
        doc.render({
          Client: `${setting?.firstName} ${setting?.lastName}`,
          Company: setting?.name,
        });
        doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          modules: [new ImageModule(imageOpts)],
          delimiters: { start: "[", end: "]" },
        });
        await doc.renderAsync({
          signature,
        });
        const blob = doc.getZip().generate({
          type: "blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          compression: "DEFLATE",
        });
        await JobService.postContract(
          blob,
          `${user?.id}-${S3_UPLOAD_TYPE_PRIFIX.TERMS_OF_BUSINESS_COPILOT}`
        );
        const myTermsOfBusiness = new File([blob], "SignedTermOfBusiness.docx", {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        saveAs(myTermsOfBusiness, "SignedTermOfBusiness.docx");
        await handleAcceptTerm();
      };
    } catch (e) {
      dispatch(setErrorMess(e));
    }
  };

  const createOrUpdateXeroAccount = async () => {
    // const res = await XeroContactService.getContact(user?.id)
    // console.log(res)
    try {
      await XeroContactService.putEmployerContact(user?.id, user?.id, {
        name: setting?.name,
        firstName: setting?.firstName,
        lastName: setting?.lastName,
        emailAddress: setting?.email,
        contactNumber: user?.id,
        addresses: [
          {
            AddressType: "STREET",
            City: setting?.city,
            Region: setting?.region,
            Country: setting?.country,
          },
          {
            AddressType: "POBOX",
            City: setting?.city,
            Region: setting?.region,
            Country: setting?.country,
          },
        ],
        phones: [
          {
            PhoneType: "DEFAULT",
            PhoneNumber: setting?.workPhone,
            PhoneCountryCode: setting?.workPhoneCountryCode || "+64",
          },
          {
            PhoneType: "DDI",
            PhoneNumber: setting?.workPhone,
            PhoneCountryCode: setting?.workPhoneCountryCode || "+64",
          },
          {
            PhoneType: "MOBILE",
            PhoneNumber: setting?.workPhone,
            PhoneCountryCode: setting?.workPhoneCountryCode || "+64",
          },
          {
            PhoneType: "FAX",
            PhoneNumber: setting?.workPhone,
            PhoneCountryCode: setting?.workPhoneCountryCode || "+64",
          },
        ],
      });
    } catch (e) {
      dispatch(setErrorMess(e));
    }
  };

  const handleSubmitContract = async () => {
    dispatch(setLoading(true));
    OrganizationService.putOrganization(
      {
        terms: true
      },
      setting?.idOrganisation
    )
    await createOrUpdateXeroAccount();

    JobService.getTemplate("Terms-Of-Business-copilot.docx")
      .then(async (res) => {
        await handleSubmit(res as Blob);
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  // useEffect(() => {
  //   if (signature) {
  //     handleSubmitContract();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [signature]);

  return (
    <Card className={classes.card}>
      <CardBody className={classes.cardBody}>
        <CardTitle className={classes.title}>Organisation Terms Acceptance</CardTitle>
        <div className={classes.termsContent} style={{ maxHeight: "300px", overflowY: "auto" }}>
          <div dangerouslySetInnerHTML={{ __html: termsContent }} />
        </div>
      </CardBody>
      <CardBody className={classes.cardBody}>
        <CardTitle className={classes.title}>SIGN HERE</CardTitle>
      </CardBody>
      <CardBody className={classes.canvasContainer}>
        <canvas id="canvas" className={classes.canvas} width={350} height={200} />
      </CardBody>
      <div className={classes.canvasButtons}>
        <Button size="sm" color="info" type="button" id="undo">
          Undo
        </Button>
        <Button size="sm" color="info" type="button" id="clear">
          Clear
        </Button>
      </div>
      <CardBody className={classes.cardBody}>
        <div className={classes.buttonContainer}>
          <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={onCloseSignTermModal}
          >
            Back
          </Button>
          <Button id="submit-contract" size="md" color="primary" type="button" className={classes.btn} onClick={handleSubmitContract}>
            Submit
          </Button>
        </div>
      </CardBody>
    </Card>
  );
});

export default SignTerm;
