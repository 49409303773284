/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  Container,
} from "reactstrap";
import classes from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { JobOfferService } from "services/Contractor/Offer/Offer";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { ReducerType } from "redux/reducers";
import TinderCard from "react-tinder-card";
import clsx from "clsx";
import { ImageService } from "services/Image";
//import { UtilChatEngine } from "utils/chatEngine";
import moment from "moment";
import { JobService } from "services/Employer/Listing/Job";
import { saveAs } from "file-saver";
import { ListingEmailService } from "services/Employer/Listing/Email";
import { EmailTypes } from "models/Employer/Listings/Dashboard";
import SignContract from "./SignContract";
import { getAllOffers } from "redux/reducers/Contractor/actionTypes";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import ImageModule from "docxtemplater-image-module-free";
import { UtilContractor } from "utils/contractor";
import DeclineReason from "./DeclineReason";

interface ContractProps { }

const Contract = memo((props: ContractProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { allOffers } = useSelector((state: ReducerType) => state.contractor);

  const [offerList, setOfferList] = useState([]);
  const [countItem, setCountItem] = useState(0);
  const [activeItem, setActiveItem] = useState(offerList[0]);
  const [animating, setAnimating] = useState(false);
  const [tinderList, setTinderList] = useState([]);
  const [contract, setContract] = useState(null);
  const [signContract, setSignContract] = useState({
    isSignContract: false,
    contract: null,
  });
  const [signatureForMobile, setSignatureForMobile] = useState(null);
  const [declineModal, setDeclineModal] = useState({
    isOpen: false,
    declineItem: null,
    declineType: null,
    index: null,
  });

  const handleOpenSignModal = (jobItem) => {
    setSignContract({ isSignContract: true, contract: jobItem });
  };

  const handleProgressOffer = (jobItem, signature) => {
    dispatch(setLoading(true));
    JobService.getContract(jobItem?.contractUrl, jobItem?.employerIdentityId)
      .then((res) => {
        const myFile = new File(
          [res as BlobPart],
          `${jobItem?.contractUrl}.docx`,
          {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }
        );

        const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
        function base64Parser(dataURL) {
          if (typeof dataURL !== "string" || !base64Regex.test(dataURL)) {
            return false;
          }
          const stringBase64 = dataURL.replace(base64Regex, "");
          const binaryString = window.atob(stringBase64);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }
          return bytes.buffer;
        }
        const imageOpts = {
          getImage(tag) {
            return base64Parser(tag);
          },
          getSize() {
            return [210, 120];
          },
        };

        var reader = new FileReader();
        reader.readAsBinaryString(myFile);
        reader.onload = async function (evt) {
          const content = evt.target.result;
          var zip = new PizZip(content);
          var doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            modules: [new ImageModule(imageOpts)],
            delimiters: { start: "[", end: "]" },
          });
          await doc.renderAsync({
            signature,
          });
          var blob = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            compression: "DEFLATE",
          });
          // const myContract = new File([blob], "contract.docx", {
          //   type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          // });
          // saveAs(myContract, "output.docx");
          //revoked old contract and send email
          if (jobItem?.isExtendContract) {
            const oldContract = allOffers?.jobOffers?.find((i) => i?.job === jobItem?.id && i?.accepted && !i?.revoked)
            JobService.revokeContractJobOffer({
              endDate: moment(),
              job: oldContract?.job,
              contractor: oldContract?.contractor,
              employer: oldContract?.employer,
              startDate: oldContract?.startDate
            }, oldContract?.id)
              .then(async (res) => {
                await ListingEmailService.terminateContractEmail(oldContract?.id, {
                  type: EmailTypes.TERMINATE_IMMEDIATE_OFFER,
                  reason: `New contract from ${moment().format("DD/MM/YYYY")}`
                })
              })
              .catch((err) => dispatch(setErrorMess(err)))
          }

          JobService.postContract(blob, jobItem?.contractUrl)
            .then(async () => {
              await JobOfferService.acceptContractJobOffer(jobItem?.offerId, {
                job: jobItem?.id,
                contractor: user?.id,
                employer: jobItem?.employer,
                startDate: jobItem?.startDate,
                endDate: jobItem?.endDate,
              })
                .then(async () => {
                  setSignatureForMobile(null);
                  await ListingEmailService.acceptOfferEmail(jobItem.offerId, {
                    type: EmailTypes.CONTRACTOR_JOB_OFFER_ACCEPTED,
                  })
                    // .then(() => {
                    //   UtilChatEngine.updateOffersOfContractor(dispatch, user, {
                    //     jobId: contract?.id,
                    //     employerId: contract?.employer,
                    //   })
                    //     .catch((err) => dispatch(setErrorMess(err)))
                    //     .finally(() => dispatch(setLoading(false)));
                    //   dispatch(getAllOffers(user?.id));
                    // })
                    // .catch((err) => {
                    //   dispatch(setErrorMess(err));
                    //   dispatch(setLoading(false));
                    // });
                })
                .catch((err) => {
                  dispatch(setErrorMess(err));
                  dispatch(setLoading(false));
                });
            })
            .catch((err) => {
              dispatch(setErrorMess(err));
              dispatch(setLoading(false));
            });
        };
      })
      .catch((err) => {
        dispatch(setErrorMess(err));
        dispatch(setLoading(false));
      });
  };

  const handleProgressOfferNotUseNectaContract = (jobItem) => {
    contract.arrayBuffer().then((arrayBuffer) => {
      const blob = new Blob([new Uint8Array(arrayBuffer)], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      JobService.postContract(blob, jobItem?.contractUrl)
        .then(async () => {
          await JobOfferService.acceptContractJobOffer(jobItem?.offerId, {
            job: jobItem?.id,
            contractor: user?.id,
            employer: jobItem?.employer,
            startDate: jobItem?.startDate,
            endDate: jobItem?.endDate,
          })
            .then(async () => {
              await ListingEmailService.acceptOfferEmail(jobItem.offerId, {
                type: EmailTypes.CONTRACTOR_JOB_OFFER_ACCEPTED,
              })
                // .then(() => {
                //   UtilChatEngine.updateOffersOfContractor(dispatch, user, {
                //     jobId: contract?.id,
                //     employerId: contract?.employer,
                //   }).catch((err) => dispatch(setErrorMess(err)));
                //   dispatch(getAllOffers(user?.id));
                // })
                // .catch((err) => dispatch(setErrorMess(err)));
            })
            .catch((err) => dispatch(setErrorMess(err)));
        })
        .catch((err) => dispatch(setErrorMess(err)))
        .finally(() => dispatch(setLoading(false)));
    });
  };

  const handleDeclineOffer = (jobItem, index) => {
    setDeclineModal({
      isOpen: true,
      declineItem: jobItem,
      declineType: "desktop",
      index: index,
    });
  }

  const handleDeclineModal = (jobItem, reason) => {
    dispatch(setLoading(true));
    JobOfferService.rejectContractJobOffer(jobItem?.offerId, {
      job: jobItem?.id,
      contractor: user?.id,
      employer: jobItem?.employer,
      startDate: jobItem?.startDate,
      endDate: jobItem?.endDate,
    })
      .then(() => {
        ListingEmailService.declinedContractEmail(jobItem?.offerId, {
          type: EmailTypes.CONTRACTOR_JOB_OFFER_DECLINED,
          reason: reason
        }).then(() => {
          dispatch(getAllOffers(user?.id));
        });
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleDeclineOfferMobile = (jobItem, reason) => {
    JobOfferService.rejectContractJobOffer(jobItem?.offerId, {
      job: jobItem?.id,
      contractor: user?.id,
      employer: jobItem?.employer,
      startDate: jobItem?.startDate,
      endDate: jobItem?.endDate,
    })
      .then(() => {
        ListingEmailService.declinedContractEmail(jobItem?.offerId, {
          type: EmailTypes.CONTRACTOR_JOB_OFFER_DECLINED,
          reason: reason
        }).then(() => {
          dispatch(getAllOffers(user?.id));
        });
      })
      .catch((err) => dispatch(setErrorMess(err)))
  };

  const downloadContract = (key, identityId) => {
    dispatch(setLoading(true));
    JobService.getContract(key, identityId)
      .then((res) => {
        const myFile = new File([res as BlobPart], `${key}.docx`, {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        saveAs(myFile, `${key}.docx`);
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const filterList = allOffers?.jobOffers?.filter(
      (offer) => !offer.rejected && !offer.revoked && !offer.accepted
    );
    const allOfferJobId = filterList?.map((offer) => offer.job);
    const allOfferJobEmployer = filterList?.map((offer) => offer.employer);
    const allOfferJobStartDate = filterList?.map((offer) => offer.startDate);
    const allOfferJobEndDate = filterList?.map((offer) => offer.endDate);
    const allOfferJobContractUrl = filterList?.map(
      (offer) => offer.contractUrl
    );
    const allOfferJobRateOffered = filterList?.map(
      (offer) => offer.rateOffered
    );
    const allOfferJobSalary = filterList?.map(
      (offer) => offer.salary
    );
    const allOfferJobOfferId = filterList?.map((offer) => offer.id);
    const allOfferJobIsNectaContract = filterList?.map(
      (offer) => offer.isContraContractUsed
    );
    const allOfferJobIsExtendContract = filterList?.map(
      (offer) => offer?.isExtendContract
    );
    const tempOfferJob = [];
    allOffers?.jobs.forEach((job) => {
      const foundJobInOffers = allOfferJobId.findIndex((e) => e === job.id);
      if (foundJobInOffers !== -1) {
        const employer = allOfferJobEmployer[foundJobInOffers];
        const startDate = allOfferJobStartDate[foundJobInOffers];
        const endDate = allOfferJobEndDate[foundJobInOffers];
        const contractUrl = allOfferJobContractUrl[foundJobInOffers];
        const rateOffered = allOfferJobRateOffered[foundJobInOffers];
        const salary = allOfferJobSalary[foundJobInOffers];
        const offerId = allOfferJobOfferId[foundJobInOffers];
        const isContraContractUsed =
          allOfferJobIsNectaContract[foundJobInOffers];
        const isExtendContract = allOfferJobIsExtendContract[foundJobInOffers];
        tempOfferJob.push({
          ...job,
          startDate,
          endDate,
          employer,
          contractUrl,
          rateOffered,
          salary,
          offerId,
          isContraContractUsed,
          isDisplayed: false,
          isExtendContract,
        });
      }
    });

    const promiseArr = [];
    tempOfferJob?.map((item) => {
      if (item?.employerPhotoUrl) {
        promiseArr.push(
          ImageService.getImage(
            item?.employerPhotoUrl,
            item?.employerIdentityId
          )
        );
      }
      return null;
    });

    Promise.all(promiseArr)
      .then((res) => {
        const offerJobs = tempOfferJob.map((item, index) => ({
          ...item,
          photoURL: res[index],
        }));
        return offerJobs;
      })
      .then((offerJobs) => {
        setOfferList([...offerJobs]);
        setTinderList([...offerJobs].reverse());
        if (offerJobs.length !== 0) {
          setActiveItem(offerJobs[offerJobs.length - 1].id);
        }
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  }, [dispatch, user?.id, allOffers]);

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveItem(newIndex);
  };

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const handleAddStamp = (index, direction) => {
    let tinderCardItem = document.getElementById(`tinder-card-${index}`)
      .firstChild as HTMLElement;
    tinderCardItem.classList.add(classes.currentTinderCard);
    if (direction === "right") {
      tinderCardItem.classList.remove(classes.tinderDeclineCard);
      tinderCardItem.classList.add(classes.tinderAcceptCard);
    } else {
      tinderCardItem.classList.remove(classes.tinderAcceptCard);
      tinderCardItem.classList.add(classes.tinderDeclineCard);
    }
  };

  const handleRemoveStamp = (index) => {
    let tinderCardItem = document.getElementById(`tinder-card-${index}`)
      .firstChild as HTMLElement;
    tinderCardItem.classList.remove(classes.tinderAcceptCard);
    tinderCardItem.classList.remove(classes.tinderDeclineCard);
  };

  const handleSetSignatureForMobile = (signatureForMobile) => {
    setSignatureForMobile(signatureForMobile);
  };

  const handleSwipe = (item, index, direction) => {
    const newList = [...offerList];
    newList[offerList.length - 1 - index].isDisplayed = true;
    setActiveItem(item?.id);
    setOfferList([...newList]);
    if (direction === "right") {
      handleProgressOffer(item, signatureForMobile);
    } else {
      setDeclineModal({
        isOpen: true,
        declineItem: item,
        declineType: "mobile",
        index: index,
      });
    }
  };

  const onCloseDeclineModal = () => {
    setDeclineModal({
      isOpen: false,
      declineItem: null,
      declineType: null,
      index: null,
    });
  };

  const handleRestoreCard = (item, index) => {
    const newList = [...offerList];
    newList[offerList.length - 1 - index].isDisplayed = false;
    setActiveItem(item?.id);
    setOfferList([...newList]);
    setCountItem((prev) => prev - 1);
  };

  return (
    <React.Fragment>
      {!signContract?.isSignContract && (
        <div className={classes.containerRight}>
          <Container className={classes.containerCarousel}>
            {offerList.length > 0 && countItem < offerList.length ? (
              <Carousel
                activeIndex={countItem}
                className={classes.carousel}
                interval={false}
                slide={false}
                next={() =>
                  handleDeclineOffer(
                    offerList.findIndex((item) => item?.id === activeItem), null
                  )
                }
                previous={() =>
                  handleOpenSignModal(
                    offerList.findIndex((item) => item?.id === activeItem)
                  )
                }
              >
                <CarouselIndicators
                  items={offerList}
                  activeIndex={countItem}
                  onClickHandler={goToIndex}
                />
                {offerList.map((item, index) => {
                  if (!item?.isDisplayed) {
                    return (
                      <CarouselItem
                        className={classes.displayNone}
                        key={index}
                        onExiting={onExiting}
                        onExited={onExited}
                      >
                        <>
                          <p className={classes.titleHeaderRight}>
                            CONTRACTUAL OFFER
                          </p>
                          <div className={classes.headerRight}>
                            <img
                              src={
                                item?.photoURL ||
                                require("assets/img/placeholder.jpg")
                              }
                              alt="Avatar"
                              className={classes.avatar}
                            />
                            <div className={classes.buttonContainer}>
                              <Button
                                color="success"
                                size="lg"
                                onClick={
                                  item?.isContraContractUsed
                                    ? () => handleOpenSignModal(item)
                                    : () =>
                                      handleProgressOfferNotUseNectaContract(
                                        item
                                      )
                                }
                                className={
                                  item?.isContraContractUsed
                                    ? clsx("d-none d-sm-block")
                                    : contract
                                      ? clsx("d-none d-sm-block")
                                      : clsx(
                                        "d-none d-sm-block",
                                        classes.btnDisabled
                                      )
                                }
                                disabled={
                                  item?.isContraContractUsed
                                    ? false
                                    : contract
                                      ? false
                                      : true
                                }
                              >
                                {item?.isContraContractUsed
                                  ? "Sign & Progress Contract"
                                  : "Progress"}
                              </Button>
                              <Button
                                color="danger"
                                size="lg"
                                onClick={() => handleDeclineOffer(item, index)}
                                className="d-none d-sm-block"
                              >
                                Decline
                              </Button>
                            </div>
                          </div>
                          <div className={classes.descriptionContainer}>
                            <div className={classes.descriptionHeader}>
                              <p className={classes.descriptionTitle}>
                                Summary:
                              </p>
                            </div>
                            <div className={classes.descriptionContent}>
                            - Company name: {item?.client || item?.companyName || ""}
                           <br></br>- Title: {item?.title || ""}
                           <br></br>- Start date:{" "}
                              {item?.startDate
                                ? moment(item?.startDate).format("DD/MM/YYYY")
                                : ""}
                              {item?.endDate && <br></br>}
                              {item?.endDate && (`- End date:${" "}`)}
                              {item?.endDate
                                ? moment(item?.endDate).format("DD/MM/YYYY")
                                : ""}
                              <br></br>- Employment
                              type:{" "}
                              {(item?.isContract && "Contract") ||
                                (item?.isFixed && "Fixed") ||
                                (item?.isPermanent && "Permanent")}{" "}
                              <br></br>-{" "}
                              {item?.isContract
                                ? `Hourly rate: $${item?.rateOffered}`
                                : `Expected salary: $${item?.salary}`}
                              <br></br>
                            </div>
                          </div>
                          <p className={classes.title}>Full contract:</p>
                          <span
                            className={classes.downloadBtn}
                            onClick={() =>
                              downloadContract(
                                item?.contractUrl,
                                item?.employerIdentityId
                              )
                            }
                          >
                            Download
                          </span>
                          {!item?.isContraContractUsed && (
                            <React.Fragment>
                              <p className={clsx(classes.title, "mt-3 mb-2")}>
                                Upload the signed contract (Please upload before
                                "Progress"):
                              </p>
                              <input
                                className={classes.uploadFile}
                                id="upload-contract"
                                type="file"
                                placeholder="Upload..."
                                accept=".doc, .docx, .pdf"
                                onChange={(e) => {
                                  if (e.target?.files?.length === 0) {
                                    setContract(null);
                                  } else {
                                    setContract(e.target?.files[0]);
                                  }
                                }}
                              />
                            </React.Fragment>
                          )}
                        </>
                      </CarouselItem>
                    );
                  } else {
                    return (
                      <CarouselItem
                        className={classes.displayNone}
                        key={index}
                      ></CarouselItem>
                    );
                  }
                })}
              </Carousel>
            ) : (
              <p className={classes.titleHeaderRight}>
                There are no more offers for you at the moment!
              </p>
            )}
          </Container>

          <Container className={classes.containerTinderCard}>
            {offerList.length > 0 && countItem < offerList.length ? (
              tinderList.map((item, index) => {
                if (item?.isDisplayed === false) {
                  return (
                    <div
                      key={index}
                      id={`tinder-card-${index}`}
                      onTouchEndCapture={() => handleRemoveStamp(index)}
                      onMouseUpCapture={() => handleRemoveStamp(index)}
                    >
                      <TinderCard
                        swipeThreshold={180}
                        className={classes.swipe}
                        preventSwipe={
                          item?.isContraContractUsed && signatureForMobile
                            ? ["up", "down"]
                            : contract
                              ? ["up", "down"]
                              : ["up", "down", "right"]
                        }
                        onCardLeftScreen={(direction) =>
                          handleSwipe(item, index, direction)
                        }
                        onSwipeRequirementFulfilled={(direction) =>
                          handleAddStamp(index, direction)
                        }
                      >
                        <div className={classes.cardContainerWrapper}>
                          <p className={classes.progressWord}>Progress</p>
                          <p className={classes.declineWord}>decline</p>
                          <p className={classes.titleHeaderRight}>
                            CONTRACTUAL OFFER
                          </p>
                          <div className={classes.headerRight}>
                            <img
                              src={
                                item?.photoURL || require("assets/img/ryan.jpg")
                              }
                              alt="Avatar"
                              className={classes.avatar}
                            />
                            <div className={classes.buttonContainer}>
                              <Button
                                color="success"
                                size="lg"
                                onClick={() =>
                                  handleProgressOffer(item, signatureForMobile)
                                }
                                className="d-none d-sm-block"
                              >
                                Progress
                              </Button>
                              <Button
                                color="danger"
                                size="lg"
                                onClick={() => handleDeclineOffer(item, index)}
                                className="d-none d-sm-block"
                              >
                                Decline
                              </Button>
                            </div>
                          </div>
                          <div className={classes.descriptionContainer}>
                            <div className={classes.descriptionContainer}>
                            <div className={classes.descriptionHeader}>
                              <p className={classes.descriptionTitle}>
                                Summary:
                              </p>
                            </div>
                            <div className={classes.descriptionContent}>
                            - Company name: {item?.client || item?.companyName || ""}
                           <br></br>- Title: {item?.title || ""}
                           <br></br>- Start date:{" "}
                              {item?.startDate
                                ? moment(item?.startDate).format("DD/MM/YYYY")
                                : ""}
                              {item?.endDate && <br></br>}
                              {item?.endDate && (`- End date:${" "}`)}
                              {item?.endDate
                                ? moment(item?.endDate).format("DD/MM/YYYY")
                                : ""}
                              <br></br>- Employment
                              type:{" "}
                              {(item?.isContract && "Contract") ||
                                (item?.isFixed && "Fixed") ||
                                (item?.isPermanent && "Permanent")}{" "}
                              <br></br>-{" "}
                              {item?.isContract
                                ? `Hourly rate: $${item?.rateOffered}`
                                : `Expected salary: $${item?.salary}`}
                              <br></br>
                            </div>
                          </div>
                          </div>
                          <p className={classes.title}>Full contract:</p>
                          <span
                            className={classes.downloadBtn}
                            onClick={() =>
                              downloadContract(
                                item?.contractUrl,
                                item?.employerIdentityId
                              )
                            }
                          >
                            Download
                          </span>
                          {item?.isContraContractUsed && (
                            <>
                              <div className={classes.btnSignWrapper}>
                                <Button
                                  color="success"
                                  size="md"
                                  className={classes.btnSign}
                                  onClick={() => handleOpenSignModal(item)}
                                  onTouchStart={() => handleOpenSignModal(item)}
                                >
                                  Sign contract
                                </Button>
                              </div>
                            </>
                          )}
                          {!item?.isContraContractUsed && (
                            <React.Fragment>
                              <p className={clsx(classes.title, "mt-3 mb-2")}>
                                Upload the signed contract (Please upload before
                                "Progress"):
                              </p>
                              <input
                                className={classes.uploadFile}
                                id="upload-contract"
                                type="file"
                                placeholder="Upload..."
                                accept=".doc, .docx, .pdf"
                                onChange={(e) => {
                                  if (e.target?.files?.length === 0) {
                                    setContract(null);
                                  } else {
                                    setContract(e.target?.files[0]);
                                  }
                                }}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      </TinderCard>
                      <div className={clsx("d-sm-none", classes.mobileButtons)}>
                        <Button
                          color="danger"
                          className={clsx("rounded-circle", classes.mobileBtn)}
                          onClick={() => handleDeclineOffer(item, index)}
                        >
                          Decline
                        </Button>
                        <Button
                          color="success"
                          onClick={() =>
                            handleProgressOffer(item, signatureForMobile)
                          }
                          className={
                            item?.isContraContractUsed
                              ? clsx("rounded-circle", classes.mobileBtn)
                              : contract
                                ? clsx("rounded-circle", classes.mobileBtn)
                                : clsx(
                                  "rounded-circle",
                                  classes.mobileBtn,
                                  classes.btnDisabled
                                )
                          }
                          disabled={
                            item?.isContraContractUsed && signatureForMobile
                              ? false
                              : contract
                                ? false
                                : true
                          }
                        >
                          Progress
                        </Button>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <TinderCard
                      className="d-none"
                      key={index}
                      preventSwipe={["up", "down"]}
                      onCardLeftScreen={(direction) =>
                        handleSwipe(item, index, direction)
                      }
                      swipeThreshold={180}
                    />
                  );
                }
              })
            ) : (
              <p
                className={clsx(
                  classes.titleHeaderRight,
                  classes.noOfferContainer
                )}
              >
                There are no more offers for you at the moment !
              </p>
            )}
          </Container>
        </div>
      )}

      {signContract?.isSignContract && (
        <SignContract
          contract={signContract?.contract}
          backToContract={() =>
            setSignContract({ isSignContract: false, contract: null })
          }
          handleSetSignatureForMobile={handleSetSignatureForMobile}
          handleProgressOffer={handleProgressOffer}
        />
      )}

      <DeclineReason
        isOpen={declineModal?.isOpen}
        onClose={onCloseDeclineModal}
        declineType={declineModal?.declineType}
        declineItem={declineModal?.declineItem}
        handleDeclineOfferDesktop={handleDeclineModal}
        handleDeclineOfferMobile={handleDeclineOfferMobile}
        handleRestoreCard={handleRestoreCard}
        index={declineModal?.index}
      />
    </React.Fragment>
  );
});

export default Contract;
