import { API } from "aws-amplify";

export class TestimonialService {
  static async postTestimonial(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/testimonials";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getTestimonial() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/testimonials";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putTestimonial(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/testimonials`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async deleteTestimonial(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/testimonials/delete";
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async requestTestimonialEmail(userId, testimonialId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${userId}/testimonial/${testimonialId}/email`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async updateTestimonial(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/testimonial/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
