export const routes = {
  contractor: {
    root: "/contractor",
    login: "/contractor/login",
    signup: "/contractor/sign-up",
    forgotPassword: "/contractor/forgot-password",
    confirmVerificationCode: "/contractor/confirm-verification-code",
    newMember: {
      root: "/contractor/new-member",
      uploadCv: "/contractor/new-member/upload-cv",
      basicProfile: "/contractor/new-member/basic-profile",
      contactProfile: "/contractor/new-member/contact-profile",
      aboutYou: "/contractor/new-member/about-you",
      education: "/contractor/new-member/education",
      experience: "/contractor/new-member/experience",
      skill: "/contractor/new-member/skills",
      preference: "/contractor/new-member/preference",
      futureOpportunities: "/contractor/new-member/future-opportunities",
      testimonials: "/contractor/new-member/testimonials",
      visa: "/contractor/new-member/visa",
      mojCheck: "/contractor/new-member/moj-check",
    },
    listing: {
      root: "/contractor/listings",
      dashboard: "/contractor/listings/dashboard",
      listingJob: "/contractor/listings/listingjob",
      jobProgress: "/contractor/listings/jobprogress",
      settings: "/contractor/listings/settings",
      assistCandidates: "/contractor/listings/assistcandidates",
    },
    profile: "/contractor/profile",
    setting: "/contractor/settings",
    messenger: "/contractor/messenger",
    offer: "/contractor/offers",
    jobs: "/contractor/jobs",
    timesheet: {
      root: "/contractor/timesheets",
      status: "/contractor/timesheets/:status?",
      edit: "/contractor/timesheets/edit/:timesheetId",
    },
    addEditTimesheet: "/contractor/add-edit-timesheet",
    invoice: {
      root: "/contractor/invoices",
      create: "/contractor/invoices/create",
      edit: "/contractor/invoices/edit/:invoiceId",
    },
    expense: {
      root: "/contractor/expenses",
      create: "/contractor/expenses/create",
      edit: "/contractor/expenses/edit/:invoiceId",
    },
    payslip: "/contractor/payslips",
    dashboard: "/contractor/dashboard",
    report: "/contractor/report",
    payroll: "/contractor/payroll",
    documentHistory: "/contractor/document-history",
  },
  employer: {
    root: "/employer",
    login: "/employer/login",
    signup: "/employer/sign-up",
    forgotPassword: "/employer/forgot-password",
    confirmVerificationCode: "/employer/confirm-verification-code",
    newMember: {
      root: "/employer/new-member",
      basicProfile: "/employer/new-member/basic-profile",
      organization: "/employer/new-member/organization",
    },
    membership: "/employer/memberships",
    manage: {
      root: "/employer/manage",
      dashboard: "/employer/manage/dashboard",
      timesheets: "/employer/manage/timesheets",
      billing: "/employer/manage/billing",
    },
    listing: {
      root: "/employer/listings",
      talent: "/employer/listings/talent",
      dashboard: "/employer/listings/dashboard",
      messenger: "/employer/listings/messenger",
      settings: "/employer/listings/settings",
      listingJob: "/employer/listings/listingjob",
      assistCandidates: "/employer/listings/assistcandidates",
    },
    admin: {
      root: "/employer/admin",
      talent: "/employer/admin/talent",
      users: "/employer/admin/users",
      dashboard: "/employer/admin/dashboard",
      contractor: "/employer/admin/contractor",
      myjobs: "/employer/admin/myjobs",
      jobtemplates: "/employer/admin/jobtemplates",
      myClientjobs: "/employer/admin/myclients",
      clientJobDetail: (jobId) => `/employer/admin/myclientjobs/${jobId}`,
      messenger: "/employer/admin/messenger",
      candidates: "/employer/admin/candidates",
      timesheets: "/employer/admin/timesheets",
      organizationchart: "/employer/admin/organizationchart",
      billing: "/employer/admin/billing",
      employees: "/employer/admin/employees",
      settings: "/employer/admin/settings",
      integration: "/employer/admin/integration",
      profile: "/employer/admin/profile",
      payment: "/employer/admin/payment",
      mysetting: "/employer/admin/mysetting",
      listingJob: "/employer/admin/listingjob",
      assistCandidates: "/employer/admin/assistcandidates",
      jobDetail: (jobId) => `/employer/admin/myjobs/${jobId}`,
    },
    accountant: "/employer/accountant",
  },
  signOut: "/sign-out",
  blog: "/blog",
  aboutUs: "/about-us",
  policy: "/policies",
  jobs: {
    contractorProfile: "/jobs/:jobId/candidates/:contractorId",
  },
};

export const routesOutside = {
  contractorBlog: "//necta.nz/blog/talent",
  employerBlog: "//necta.nz/blog/employer",
  aboutUs: "//necta.nz/about-us",
  policy: "//necta.nz/policies",
};

export default routes;
