import { API } from 'aws-amplify'

export class ChatAppService {
	static getMessageInfo(userId, receiverId) {
	  const apiName = process.env.REACT_APP_API_NAME;
	  const path = `/job/messageinfo`;
	  const myInit = {
		response: true,
		queryStringParameters: {
		  userId,
		  receiverId
		}
	  };
	  return API.get(apiName, path, myInit)
		.then(res => {
		  return Promise.resolve(res.data);
		})
		.catch(e => {
		  return Promise.reject(e?.response?.data);
		});
	}
  }
