import { memo, useMemo, useState, useEffect } from "react";
import { Form, Card, CardBody, Modal, Row, Col, ModalHeader, Button } from "reactstrap";
import classes from "./styles.module.scss";
import moment from "moment";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { JobService } from "services/Employer/Listing/Job";
import { JobOfferService } from "services/Contractor/Offer/Offer";
import CustomInput from "components/Common/CustomInput";
import CustomSelect from "components/Common/CustomSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { TypesOfTerminateDate } from "models/Employer/Manage/Timesheet";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import { ListingEmailService } from "services/Employer/Listing/Email";
import { EmailTypes } from "models/Employer/Listings/Dashboard";

interface TerminateContractProps {
  onClose: () => void;
  isOpen: boolean;
  contract?: any;
  onCloseContractModal?: () => void;
}

const TerminateContract = memo(
  ({ onClose, isOpen, contract, onCloseContractModal }: TerminateContractProps) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: ReducerType) => state.user);

    const schema = useMemo(() => {
      return yup.object().shape({
        endDate: yup.object().required("This field is required"),
        weekNotice: yup.number().nullable().typeError("Please type in a number").when("endDate", {
          is: (date) => date?.name === TypesOfTerminateDate[1].name,
          then: yup.number().nullable().typeError("Please type in a number").required("This field is required")
        }),
        reason: yup.string().required("This field is required")
      });
    }, []);

    const {
      register,
      control,
      watch,
      setValue,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        endDate: TypesOfTerminateDate[1],
        reason: "",
        weekNotice: null
      }
    });

    useEffect(() => {
      reset();
    }, [isOpen])


    const onSubmit = (data) => {
      dispatch(setLoading(true));
      JobService.revokeContractJobOffer({
        endDate: data?.endDate?.name === TypesOfTerminateDate[0]?.name ? moment() : moment().add(data?.weekNotice, "weeks"),
        job: contract?.job,
        contractor: contract?.contractor,
        employer: contract?.employer,
        startDate: contract?.startDate,
        rateOffered: Number(contract?.rateOffered)
      }, contract?.id)
      .then(async (res) => {
        if (data?.endDate?.name === TypesOfTerminateDate[0]?.name) {
          await ListingEmailService.terminateContractEmail(contract?.id, {
            type: EmailTypes.TERMINATE_IMMEDIATE_OFFER,
            reason: data?.reason
          })
        }
        else {
          await ListingEmailService.terminateContractEmail(contract?.id, {
            type: EmailTypes.TERMINATE_NOTICE_OFFER
          })
        }
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => {
        dispatch(setLoading(false));
        onClose();
        onCloseContractModal();
      });
    }

    return (
      <Card className={classes.card}>
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          centered
          scrollable
          className={classes.modal}
        >
          <ModalHeader toggle={onClose} className={classes.modalHeader}>
            Terminate Contract
          </ModalHeader>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CardBody className={classes.cardDateBody}>
              <Row className="mb-3">
                <Col className="d-flex" xs={4} md={3}>
                  <p className={classes.commonTitle}>End Date:</p>
                </Col>
                <Col xs={8} md={9}>
                  <CustomSelect
                    className={classes.input}
                    placeholder="Please choose end date"
                    name="endDate"
                    control={control}
                    options={TypesOfTerminateDate}
                    defaultValue={TypesOfTerminateDate[1]}
                    errorMessage={errors.endDate?.message}
                  />
                </Col>
              </Row>
              {
                (watch("endDate")?.name === TypesOfTerminateDate[1]?.name) && (
                  <Row className="mb-3">
                    <Col className="d-flex" xs={4} md={3}>
                      <p className={classes.commonWrapTitle}>Weeks Notice:</p>
                    </Col>
                    <Col xs={8} md={9}>
                      <CustomInput
                        className={classes.input}
                        placeholder="Number of weeks notice"
                        autoComplete="off"
                        inputRef={register("weekNotice")}
                        errorMessage={errors.weekNotice?.message}
                      />
                    </Col>
                  </Row>
                )
              }
              <Row className="mb-1">
                <Col className="d-flex" xs={4} md={3}>
                  <p className={classes.commonTitle}>Reason:</p>
                </Col>
                <Col xs={8} md={9}>
                  <CustomInput
                    className={classes.input}
                    placeholder="What is the reason for termination?"
                    autoComplete="off"
                    inputRef={register("reason")}
                    errorMessage={errors.reason?.message}
                  />
                </Col>
              </Row>
              <Row>
                <Col className={classes.buttonActionContainer}>
                  <Button color="danger" type="submit">Terminate</Button>
                </Col>
              </Row>
            </CardBody>
          </Form>
        </Modal>
      </Card>
    );
  }
);

export default TerminateContract;
