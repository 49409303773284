/**
 * @param {File} file 
 * @returns {string} url
 */
export const readFile = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator.createObjectURL(file);
};

/**
 * @param {string} url 
 * @returns {Promise<HTMLImageElement, ErrorEvent>}
 */
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

/**
 * @param {string} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 *
 * @returns {Object} { file, base64File }
 *
 */
export default async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx?.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  // convert to circle image
  const widthOfImage = canvas.width
  ctx.beginPath();
  ctx.moveTo(0,0);
  ctx.lineTo(widthOfImage/2,0);
  ctx.arcTo(0,0,0,widthOfImage/2,widthOfImage/2);
  ctx.closePath();
  ctx.fillStyle = "rgba(250, 250, 250, 1)";
  ctx.fill();
  ctx.beginPath();
  ctx.moveTo(widthOfImage,0);
  ctx.lineTo(widthOfImage/2,0);
  ctx.arcTo(widthOfImage,0,widthOfImage,widthOfImage/2,widthOfImage/2);
  ctx.closePath();
  ctx.fillStyle = "rgba(250, 250, 250, 1)";
  ctx.fill();
  ctx.beginPath();
  ctx.moveTo(0,widthOfImage);
  ctx.lineTo(0,widthOfImage/2);
  ctx.arcTo(0,widthOfImage,widthOfImage/2,widthOfImage,widthOfImage/2);
  ctx.closePath();
  ctx.fillStyle = "rgba(250, 250, 250, 1)";
  ctx.fill();
  ctx.beginPath();
  ctx.moveTo(widthOfImage,widthOfImage);
  ctx.lineTo(widthOfImage/2,widthOfImage);
  ctx.arcTo(widthOfImage,widthOfImage,widthOfImage,widthOfImage/2,widthOfImage/2);
  ctx.closePath();
  ctx.fillStyle = "rgba(250, 250, 250, 1)";
  ctx.fill();
  ctx.restore();
  // As Base64 string

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob((blob) => {
      try {
        const base64File = canvas.toDataURL("image/jpeg");

        const file = new File([blob], "fileName.jpg", {
          type: "image/jpeg",
        });
        resolve({ file, base64File });
      } catch (error) {
        console.error(error);
      }
    }, "image/jpeg");
  });
}
