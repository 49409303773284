import { Fragment, memo } from "react";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { FormGroup, Input, InputProps, Label } from "reactstrap";
import { Controller, FieldError, FieldErrors, Merge } from "react-hook-form";

interface Props extends InputProps {
  className?: string;
  content?: {
    title?: string;
    value?: any;
    default?: any;
    disabled?: boolean;
  }[];
  inputRef?: any;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrors<any>>;
  name: string;
  control?: any;
  inputName: string;
  title?: string;
}

const CustomRadioButtons = memo(
  ({
    title,
    name,
    inputName,
    control,
    className,
    content,
    inputRef,
    errorMessage,
    ...rest
  }: Props) => {
    const { ref: refInput, ...inputProps } = inputRef || { ref: null };
    return (
      <Fragment>
        <p className={classes.black}>{title}</p>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <FormGroup check className={clsx("form-check-radio", className)}>
                {content?.map((item, index) => (
                  <Label
                    key={index}
                    check
                    className={clsx(classes.black, {
                      [classes.disabled]: item?.disabled,
                    })}
                  >
                    <Input
                      {...inputProps}
                      type="radio"
                      innerRef={refInput}
                      {...rest}
                      checked={field?.value === item.value}
                      onChange={() => {
                        return field.onChange(item.value);
                      }}
                      name={inputName}
                      defaultChecked={item?.default}
                      disabled={item?.disabled}
                    />
                    <span className="form-check-sign" />
                    {item?.title}
                  </Label>
                ))}
              </FormGroup>
            );
          }}
        />
        <span className="text-danger text-left ml-1 mt-1 d-block">
          {errorMessage}
        </span>
      </Fragment>
    );
  }
);

export default CustomRadioButtons;
