import React, { useState, useEffect, memo } from 'react';
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { ReducerType } from 'redux/reducers';
import classes from './styles.module.scss';
import clsx from 'clsx';
import { StripeService } from 'services/Employer/Stripe/Stripe';


const stripePromise = loadStripe('pk_live_51LynwqC0FFvJocjAZLDDN3ExmXnYgl8QFzU9Wr1yUQSQTnKNOnkniVQTXi5mqf7iKdQGCBV6ZoKIZRvR8nyhVvDf00vQi3ryYg');


interface Props {}

const products = [
  {
    id: 'prod_QWcZ40dCK1n0NS',
    name: 'Recruiter Copilot',
    description: 'Enhance your recruiting process with AI-powered tools.',
    priceMonthly: 295.0,
    priceYearly: 295.0 * 12 * 0.85, // 15% discount for yearly subscription
  },
  // Add more products here in the future
];

const priceIds = {
  recruiter_copilot: {
    monthly: 'price_1Q0WzkC0FFvJocjAK5kcuwDr', // Replace with actual monthly price ID
    yearly: 'price_1Q0X0RC0FFvJocjAltVq5GZ3',   // Replace with actual yearly price ID
  },
  // Add more products here
};

const PaymentForm: React.FC = () => {
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  // console.log('org id', setting.idOrganisation);
  const stripe = useStripe();
  const elements = useElements();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
  const [subscriptionData, setSubscriptionData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateCardVisible, setIsUpdateCardVisible] = useState(false); // New state for updating card
  const [products, setProducts] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null); 

  useEffect(() => {
    // Fetch products and prices from the backend
    const fetchProductsAndPrices = async () => {
      try {
        const response = await StripeService.getProducts(); 
        // console.log("products", response)// Assume this is a method in your StripeService that calls the backend
        setProducts(response);
        if (response.length > 0) {
          setSelectedProduct(response[0]); // Select the first product by default
        }
      } catch (error) {
        console.error('Error fetching products and prices:', error);
        setErrorMessage('Failed to fetch products and pricing options.');
      }
    };

    fetchProductsAndPrices();
  }, []);

  useEffect(() => {
    // Fetch the subscription status from the backend
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await StripeService.postSubscription({
          orgid: setting.idOrganisation.toString(),
        });
        // console.log("response", response);
  
        if (response && response.subscriptions && response.subscriptions.length > 0) {
          const subscription = response.subscriptions[0]; // Assuming one subscription per organization
      
          // Safely access nested properties
          const firstItem = subscription.items && subscription.items[0];
          const productName = firstItem?.product?.name;
          const billingPeriod = firstItem?.billing_period;
      
          setSubscriptionStatus(subscription.status);
          setSubscriptionData({
              product: productName || 'Unknown Product', // Fallback value
              billingPeriod: billingPeriod || 'N/A',    // Fallback value
              nextBillingDate: new Date(subscription.current_period_end * 1000).toLocaleDateString('en-NZ'),
              cancelAtPeriodEnd: subscription.cancel_at_period_end, 
          });
        } else {
          // No active subscription
          setSubscriptionStatus('inactive');
          setSubscriptionData(null);
        }
      } catch (error) {
        console.error('Error fetching subscription status:', error);
        setErrorMessage('Failed to fetch subscription status.');
      }
    };
  
    if (setting.idOrganisation) {
      fetchSubscriptionStatus();
    }
  }, [setting.idOrganisation, subscriptionStatus]);

  const handleProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const productId = e.target.value;
    const product = products.find(p => p.id === productId);
    setSelectedProduct(product);
    if (product.prices.length > 0) {
      setSelectedPrice(product.prices[0].id); // Select the first price by default
    }
  };

  const handleBillingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingPeriod(e.target.value);
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPrice(e.target.value);
  };

  


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
        setErrorMessage('Stripe has not loaded yet.');
        return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    const cardElement = elements.getElement(CardElement);
    const fullName = `${setting?.firstName || ''} ${setting?.lastName || ''}`;
    try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement!,
            billing_details: {
                name: fullName,
                email: user?.email,
            },
        });

        if (error) {
            setErrorMessage(error.message || 'An error occurred.');
            setIsLoading(false);
            return;
        }

        // Prepare the payload for the backend
        const payload = {
            company_id: setting.idOrganisation,
            email: user?.email,
            name: fullName,
            payment_method_id: paymentMethod.id,
            price_id: selectedPrice, // Ensure this is the correct price ID
        };

        // Call your backend to create or update the subscription
        const backendResponse = await StripeService.postCreateClient(payload);
        // console.log('Backend response:', backendResponse);

        // At this point, the backend has handled subscription and payment.
        // No need to call `confirmCardPayment`.

        if (backendResponse.subscription_id) {
            setSubscriptionStatus('active');
            setSuccessMessage('Subscription successfully created.');
            setErrorMessage('');
        } else {
            setErrorMessage('Subscription creation failed.');
        }
    } catch (error) {
        setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
        setIsLoading(false);
    }
};


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDeactivateConfirm = async () => {
    setIsLoading(true); // Show the loading spinner or text
    try {
      const response = await StripeService.putCancel(setting.idOrganisation.toString());
  
      // Check the backend response
      if (response.message === 'Subscriptions canceled successfully') {
        setSubscriptionStatus('inactive');
        setSuccessMessage('Subscription successfully canceled.');
        setErrorMessage(''); // Clear error message if successful
      } else {
        setErrorMessage('No active subscriptions to cancel.');
        setSuccessMessage(''); // Clear success message if there's an error
      }
  
      setIsModalOpen(false); // Close the modal after confirmation
    } catch (error) {
      console.log(error);
      setErrorMessage('Failed to cancel subscription. Please try again.');
      setSuccessMessage(''); // Clear success message if there's an error
    } finally {
      setSubscriptionStatus('inactive');
      setIsLoading(false); // Hide the loading spinner or text
    }
  };

  const toggleUpdateCard = () => {
    setIsUpdateCardVisible(!isUpdateCardVisible);
    setErrorMessage('');
    setSuccessMessage('');
  };

  // const handleUpdateCardSubmit = (e: React.FormEvent) => {
  //   e.preventDefault(); 
  //   console.log("hi");
  // };

  const handleUpdateCardSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      setErrorMessage('Stripe has not loaded yet.');
      return;
    }
  
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    const cardElement = elements.getElement(CardElement);
    const fullName = `${setting?.firstName || ''} ${setting?.lastName || ''}`;
    
    try {
      // Create a payment method using the new card details
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        billing_details: {
          name: fullName,
          email: user?.email,
        },
      });
  
      if (error) {
        setErrorMessage(error.message || 'An error occurred.');
        setIsLoading(false);
        return;
      }
  
      // Prepare the payload for the backend
      const payload = {
        orgid: setting.idOrganisation.toString(),
        payment_method_id: paymentMethod.id,
      };
  
      const response = await StripeService.putUpdateCard(payload);
      // console.log('Backend response:', response);
  
      if (response?.status === 200) {
        setSuccessMessage('Your credit card has been updated.');
        setErrorMessage('');
        // setIsUpdateCardVisible(false);
      } else if (response?.status === 500) {
        setErrorMessage('Internal server error. Please try again.');
      } else {
        setErrorMessage('Failed to update credit card.');
      }
    } catch (err: any) {
      // Ensure proper type checking and error handling
      console.error('An unexpected error occurred:', err);
      setErrorMessage(err?.response?.data?.error || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  


  const selectedProductDetails = products.find((product) => product.id === selectedProduct?.id);

  return (
    <>
{subscriptionStatus === 'active' ? (
  <>
    <CardTitle tag="h5">Your Current Subscription</CardTitle>
    <p>
      <strong>Product:</strong> {subscriptionData?.product}
    </p>
    <p>
      <strong>Billing Period:</strong> {subscriptionData?.billingPeriod}
    </p>
    <p>
      <strong>Next Billing Date:</strong> {subscriptionData?.nextBillingDate}
    </p>
    {subscriptionData?.cancelAtPeriodEnd && (
          <Alert color="warning">
            Your subscription is set to cancel at the end of the current billing period.
          </Alert>
        )}
    <Button color="primary" onClick={toggleUpdateCard} className="mr-2">
      Update Credit Card
    </Button>
    <div className="text-right">
      <NavLink
        to="/employer/admin/settings"
        className={`btn-round ${classes.btnSave} btn btn-success mr-2`}
      >
        <i className="fa fa-cog" /> Org Settings
      </NavLink>
      <Button color="danger" onClick={toggleModal}>
        Deactivate Subscription
      </Button>
    </div>

    {/* Update Credit Card Form */}
    {isUpdateCardVisible && (
      <Form onSubmit={handleUpdateCardSubmit} className="mt-4">
        <CardTitle tag="h5">Update Your Credit Card</CardTitle>
        <FormGroup>
          <Label for="cardDetails">Credit Card Details</Label>
          <div className="p-2 border rounded">
            <CardElement id="cardDetails" options={{ hidePostalCode: true }} />
          </div>
        </FormGroup>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        {successMessage && <Alert color="success">{successMessage}</Alert>}
        <div className="text-right">
          <Button
            type="button"
            color="secondary"
            onClick={toggleUpdateCard}
            className="mr-2"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isLoading || !stripe}
          >
            {isLoading ? 'Updating...' : 'Confirm'}
          </Button>
        </div>
      </Form>
    )}
  </>
) : (
  <Form onSubmit={handleSubmit}>
    <CardTitle tag="h5">Choose Your Product</CardTitle>
    <FormGroup>
      {products.map((product) => (
        <FormGroup
          check
          key={product.id}
          className={
            selectedProduct?.id === product.id ? 'selected-product' : ''
          }
          style={{
            border:
              selectedProduct?.id === product.id
                ? '2px solid #E86C42'
                : '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
            marginBottom: '10px',
            backgroundColor:
              selectedProduct?.id === product.id ? '#fbe7e0' : 'transparent',
            position: 'relative',
          }}
        >
          <Label
            check
            style={{
              width: '100%',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Input
              type="radio"
              name="product"
              className={classes.input}
              value={product.id}
              checked={selectedProduct?.id === product.id}
              onChange={handleProductChange}
              style={{ backgroundColor: 'black' }} // Hide the native radio button
            />
            {/* Custom Radio Button */}
            <span className="custom-radio"></span>
            {product.name}
          </Label>
        </FormGroup>
      ))}
    </FormGroup>

    <CardTitle tag="h5">Choose Your Billing Period</CardTitle>
    <FormGroup>
      <Row>
        {selectedProductDetails?.prices.map((price) => (
          <Col sm="6" key={price.id}>
            <FormGroup
              check
              key={price.id}
              className={
                selectedPrice === price.id ? 'selected-product' : ''
              }
              style={{
                border:
                  selectedPrice === price.id
                    ? '2px solid #E86C42'
                    : '1px solid #ccc',
                padding: '10px',
                borderRadius: '5px',
                marginBottom: '10px',
                backgroundColor:
                  selectedPrice === price.id ? '#fbe7e0' : 'transparent',
                position: 'relative',
              }}
            >
              <Label check>
                <Input
                  type="radio"
                  name="price"
                  value={price.id}
                  checked={selectedPrice === price.id}
                  onChange={handlePriceChange}
                />
                {price.interval.charAt(0).toUpperCase() +
                  price.interval.slice(1)}{' '}
                Billing - ${price.amount}
                {price.interval === 'year' && ' (15% off)'}
              </Label>
            </FormGroup>
          </Col>
        ))}
      </Row>
    </FormGroup>

    <CardTitle tag="h5">Enter Your Payment Details</CardTitle>
    <FormGroup>
      <Label for="cardDetails">Credit Card Details</Label>
      <div className="p-2 border rounded">
        <CardElement id="cardDetails" options={{ hidePostalCode: true }} />
      </div>
    </FormGroup>
    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
    {successMessage && <Alert color="success">{successMessage}</Alert>}
    <div className="text-right">
      <Button type="submit" color="primary" disabled={isLoading || !stripe}>
        {isLoading ? 'Processing...' : 'Start Subscription'}
      </Button>
    </div>
  </Form>
)}


      {/* Modal for Deactivation Confirmation */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
      <ModalHeader toggle={toggleModal}>Confirm Deactivation</ModalHeader>
      <ModalBody>
        <p>It's sad to see you go. Did you mean to leave us?</p>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        {successMessage && <Alert color="success">{successMessage}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          No!
        </Button>
        <Button color="danger" onClick={handleDeactivateConfirm} disabled={isLoading}>
          {isLoading ? 'Updating...' : 'Confirm'}
        </Button>
            {/* Displaying success or error messages */}

      </ModalFooter>
    </Modal>


    </>
  );
};

const PaymentPage = memo((props: Props) => {
  const { setting } = useSelector((state: ReducerType) => state.employer);
  return (
    <Elements stripe={stripePromise}>
      <Container className={clsx(classes.container, 'my-5')}>
        <Card>
          <CardBody>
            <CardTitle tag="h3">Manage Your Subscription</CardTitle>
            {setting?.admin ? (
              <PaymentForm />
            ) : (
              <Alert color="warning">Please contact your Necta Administrator for access.</Alert>
            )}
          </CardBody>
        </Card>
      </Container>
    </Elements>
  );
});

export default PaymentPage;