import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import _ from 'lodash';
import classes from '../styles.module.scss';
import { useWebSocket } from 'services/Message/User/WebSocketProvider';
import { useSelector } from 'react-redux';
import { ReducerType } from 'redux/reducers';

const ChatList = ({ user, rooms, onSelectChatRoom, activeRoom }) => {
  const userId = useSelector((state: ReducerType) => state.user.user.id);
  const { webSocket } = useWebSocket();

  return (
    <ListGroup>
      {_.map(rooms, (room) => {
        const info = room.messageInfo[0] || {};
        const unreadMessage = room.messages.some(
          (message) => message.userId !== userId && message.read === null
        );

        return (
          <ListGroupItem
            className={`${
              room.id === activeRoom?.id
                ? classes.chatCardChosen
                : unreadMessage
                ? classes.chatCardContainerNew
                : classes.chatCardContainer
            }`}
            action
            key={room.id}
            onClick={() => {
              onSelectChatRoom(room);

              // Update the client-side state immediately
              room.messages.forEach((message) => {
                if (message.userId !== userId) {
                  message.read = true; // Or whatever the value should be to indicate it's been read
                }
              });

              const payload = {
                action: 'updateRead',
                data: {
                  userId: userId,
                  roomId: room.id,
                },
              };

              // console.log('WebSocket message being sent:', payload);

              if (webSocket) {
                webSocket.send(JSON.stringify(payload));
              }
            }}
          >
            {user.type === 'contractor' ? (
              <>
                <img
                  alt='chat_image'
                  className={classes.avatar}
                  src={
                    info.employerAvatarUrl ||
                    'https://www.app.necta.nz/static/media/logo.62ed95804f7ff2f63dd7.png'
                  }
                />
                <div className={classes.chatCardInformation}>
                  <div className='ce-chat-title-text'>
                    {info.employer_name || ''}
                  </div>
                  <div className='ce-chat-subtitle-text'>
                    {info.job_title || ''}
                  </div>
                  <small className='ce-chat-subtitle-text'>
                    {info.employer_job_title} at {info.employer_company}
                  </small>
                </div>
              </>
            ) : (
              <>
                <img
                  alt='chat_image'
                  className={classes.avatar}
                  src={
                    info.candidateAvatarUrl ||
                    'https://www.app.necta.nz/static/media/logo.62ed95804f7ff2f63dd7.png'
                  }
                />
                <div className={classes.chatCardInformation}>
                  <div className='ce-chat-title-text'>
                    {info.candidate_name || ''}
                  </div>
                  <div className='ce-chat-subtitle-text'>
                    {info.candidate_title || ''}
                  </div>
                  {info.employer_job_title && (
                    <small className='ce-chat-subtitle-text'>
                      {info.employer_job_title} at {info.employer_company}
                    </small>
                  )}
                </div>
              </>
            )}

            {unreadMessage && <div className={classes.haveNewMessage} />}
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
};

export default ChatList;
