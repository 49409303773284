import { memo, useMemo, useEffect, useState } from "react";
import { Form, Button, Card, CardBody, Modal, CardTitle } from "reactstrap";
import classes from "./styles.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomDatePicker from "components/Common/CustomDatePicker";
import { EmailTypes, TypesOfInterview } from "models/Employer/Listings/Dashboard";
import CustomSelect from "components/Common/CustomSelect";
import CustomInput from "components/Common/CustomInput";
import { JobService } from "services/Employer/Listing/Job";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { ReducerType } from "redux/reducers";
import { ListingEmailService } from "services/Employer/Listing/Email";
import { URL_REGREX } from "config/constants";
import moment from "moment";

interface Props {
  onClose: (isSubmitted?: boolean) => void;
  isOpen: boolean;
  selectedItem?: any; // Adjust the type as per your actual data structure
  isSendAgain: boolean;
  contractorId: string | null;
  jobId: any; // Add jobId
}

const SetInterviewDateTime = memo(({ onClose, isOpen, selectedItem, contractorId, jobId, isSendAgain }: Props) => {
  const { user } = useSelector((state: ReducerType) => state.user);
  // console.log("here aghain", contractorId)
  const dispatch = useDispatch();
  const schema = useMemo(() => {
    return yup.object().shape({
      datetime: yup
        .date()
        .nullable()
        .typeError("Please enter a valid date")
        .required("This field is required"),
      interviewTypes: yup
        .object()
        .nullable()
        .required("This field is required"),
      interviewLocation: yup
        .string()
        .required("This field is required")
        .typeError("This field is required")
        .when("interviewTypes", {
          is: (type) => type?.id === 2,
          then: yup.string().matches(URL_REGREX, "Interview link is not valid"),
        }),
    });
  }, []);

  const [additionalEmailsInput, setAdditionalEmailsInput] = useState("");

  const onSubmit = (data) => {
    const newContractorId = contractorId.replace('task-', '')

    if (!selectedItem) {
      console.error("Invalid selectedItem structure", selectedItem);
      return;
    }

    const submitData = {
      job: jobId,
      contractor: newContractorId,
      employer: user.id,
      type: data.interviewTypes.value,
      interviewTime: data.datetime,
      interviewAddress: data.interviewTypes.value === TypesOfInterview[0].value ? data?.interviewLocation : null,
      interviewLink: data.interviewTypes.value === TypesOfInterview[1].value ? data?.interviewLocation : null,
      offered: true,
      additionalEmails: additionalEmailsInput.split(",").map((email) => email.trim()).join("; "),
    };

    dispatch(setLoading(true));
    const jobServiceCall = isSendAgain
      ? JobService.putInterview(submitData, contractorId)
      : JobService.postInterview(submitData);

    jobServiceCall
      .then((res) => {
        ListingEmailService.offerCreateEmail(res?.id, {
          type: EmailTypes.NEW_INTERVIEW_OFFER_FOR_CONTRACTOR,
        });
        onClose(true); // Pass true to indicate successful submission
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const { handleSubmit, formState: { errors }, control, reset, register, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    reset({
      datetime: null,
      interviewTypes: null,
      interviewLocation: '',
      email: "",
    });
  }, [isOpen, reset]);

  const closeModal = () => onClose();

  return (
    <Card className={classes.card}>
      <Modal isOpen={isOpen} toggle={closeModal} centered scrollable className={classes.modal}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CardBody className={classes.cardBody}>
            <CardTitle className={classes.title}>SEND INTERVIEW REQUEST</CardTitle>
          </CardBody>
          <CardBody className={classes.cardDateBody}>
            <CustomDatePicker
              name={"datetime"}
              control={control}
              showTimeInput
              errorMessage={errors.datetime?.message}
              placeholder={"Select date & time"}
              dateFormat="dd/MM/yyyy h:mm aa"
              timeClassName={classes.timeStyle}
              minDate={moment().toDate()}
              maxDate={moment().add(1, "y").toDate()}
            />
            <CustomSelect
              placeholder="Interview type"
              name="interviewTypes"
              control={control}
              options={TypesOfInterview}
              errorMessage={errors.interviewTypes?.message}
            />
            <CustomInput
              placeholder={watch("interviewTypes")
                ? watch("interviewTypes").value === "inPerson"
                  ? "Interview address"
                  : "Interview link"
                : ""}
              type="text"
              autoComplete="off"
              inputRef={register(`interviewLocation`)}
              disabled={!watch("interviewTypes")}
              errorMessage={errors.interviewLocation && errors.interviewLocation?.message}
            />
            <CustomInput
              placeholder="Email of Additional Interview Attendees"
              type="text"
              autoComplete="off"
              value={additionalEmailsInput}
              onChange={(e) => {
                setAdditionalEmailsInput(e.target.value);
              }}
            />
          </CardBody>
          <CardBody className={classes.cardBody}>
            <div className={classes.buttonContainer}>
              <Button size="md" color="default" type="button" className={classes.btn} onClick={closeModal}>
                Cancel
              </Button>
              <Button size="md" color="primary" type="submit" className={classes.btn}>
                Send
              </Button>
            </div>
          </CardBody>
        </Form>
      </Modal>
    </Card>
  );
});

export default SetInterviewDateTime;
