/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useMemo, useEffect, useState, Fragment } from 'react';
import classes from './styles.module.scss';
import FooterDefault from 'components/Footers/FooterDefault';
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import ScrollNavbar from 'components/Navbars/Contractor/ScrollNavbar';
import CustomInput from 'components/Common/CustomInput';
import CustomSelect from 'components/Common/CustomSelect';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import { SET_TIME, NOTICE_PERIOD, WORK_FROM_HOME } from 'config/constants';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import SearchLocationInput from 'components/Common/GoogleAddress';
import { ContactProfileService } from 'services/Contractor/NewMember/ContactProfile';
import { useDispatch, useSelector } from 'react-redux';
import {
  setErrorMess,
  setLoading,
  setSuccessMess,
} from 'redux/reducers/Status/actionTypes';
import { SkillService } from 'services/Contractor/NewMember/Skill';
import { PreferenceService } from 'services/Contractor/NewMember/Preference';
import { TestimonialService } from 'services/Contractor/NewMember/Testimonial';
import { UtilContractor } from 'utils/contractor';
import { UtilCvParsing } from 'utils/cvParsing';
import { ReducerType } from 'redux/reducers';
import { getProfile } from 'redux/reducers/Contractor/actionTypes';
import CustomRadioButtons from 'components/Common/CustomRadioButtons';
import {
  PreferenceAvailableTypes,
  PreferencePolicyTypes,
  PreferenceTravelingRadius,
} from 'models/Contractor/NewMember/Preference';
import {
  gstFilingFrequency,
  gstStartingMonth,
  GstTypes,
  TaxCode,
  WithholdingTax,
} from 'models/Contractor/NewMember/Tax';
import FooterMobile from 'components/Footers/FooterMobile';
//import { UtilChatEngine } from "utils/chatEngine";
import CustomSwitch from 'components/Common/CustomSwitch';
import CustomCheckbox from 'components/Common/CustomCheckbox'; 
import { ContractorSettingService } from 'services/Contractor/Settings/Email';
import { EmailTypes } from 'models/Employer/Listings/Dashboard';
import { MojCheckService } from 'services/Contractor/NewMember/MojCheck';
import { VisaTypes } from 'models/Contractor/NewMember/MojCheck';
import CustomSlider from 'components/Common/CustomSlider';
import moment from 'moment';
import { setUserReducer } from 'redux/reducers/User/actionTypes';
import { convertVisaType } from 'utils/moj';
import { TaxService } from 'services/Contractor/Tax/Tax';
import CustomInputMask from 'components/Common/CustomInputMask';

enum SettingTabs {
  PROFILE,
  FINANCIAL,
}

interface Props {}

const Setting = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const [testimonialsRemoveList, setTestimonialsRemoveList] = useState([]);
  const [modal, setModal] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [settingId, setSettingId] = useState();
  const [tab, setTab] = useState<SettingTabs>(SettingTabs.PROFILE);

  const schema = useMemo(() => {
    return yup.object().shape({
      idContact: yup.string(),
      phoneNumber: yup.string().nullable(),
      preferenceId: yup.string().nullable(),
      minHourlyRate: yup.number().typeError('Please provide your Minimum Hourly Rate'),
      hourlyRate: yup.number().typeError('Please provide your Preferred Hourly Rate'),
      address: yup.string().required('Please Select your Nearest Town or City'),
      country: yup.string(),
      city: yup.string(),
      noticePeriod: yup
        .number()
        .nullable()
        .when('availableType', {
          is: (type) => type?.id === NOTICE_PERIOD,
          then: yup
            .number()
            .required('Please select a notice period')
            .typeError('Please enter a valid notice period'),
        }),
      region: yup.string(),
      countryCode: yup.string(),
      availableType: yup.object().nullable().required('Please select your availability'),
      setTime: yup.date().nullable().when('availableType', {
        is: (type) => type?.id === SET_TIME,
        then: yup.date().typeError('Please enter a valid date').required('This field is required'),
      }),
      policy: yup.object().nullable().required('Please select a policy'),
      travellingRadius: yup.object().nullable().when('policy', {
        is: (type) => !!type && type?.id !== WORK_FROM_HOME,
        then: yup.object().nullable().required('Please select your maximum travelling radius'),
      }),
      skillsId: yup.string().nullable(),
      // skillList: yup.array().of(
      //   yup.object().shape({
      //     name: yup.string().min(2, 'At least 2 characters').nullable(true),
      //   })
      // ).nullable(true),
      testimonialsList: yup.array().of(
        yup.object().shape({
          testimonialId: yup.number(),
          name: yup.string().max(100, 'At least 100 characters'),
          email: yup.string().email('Please enter a valid email'),
          providersTitle: yup.string().max(200, 'At least 200 characters'),
          experience: yup.object(),
          isRequestEmailSent: yup.boolean(),
        })
      ),
      preferFixedTerm: yup.boolean().nullable(),
      preferPermanent: yup.boolean().nullable(),
      minSalary: yup.number().nullable(),
      maxSalary: yup.number().nullable(),
      mojId: yup.string(),
      visa: yup.object().nullable().required('Please select your visa'),
      mojUpload: yup.mixed(),
      cvUpload: yup.mixed().required('File is required'),
      cvUploadFileBase64: yup.string(),
    });
  }, [tab]);
  
  

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
    getValues,
    resetField,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const {
    fields: fieldsSkillList,
    append: appendSkillList,
    remove: removeSkillList,
  } = useFieldArray({
    control,
    name: 'skillList',
  });
  const {
    fields: fieldsTestimonialsList,
    append: appendTestimonialsList,
    remove: removeTestimonialsList,
  } = useFieldArray({
    control,
    name: 'testimonialsList',
  });

  const availableType = watch('availableType');
  const policy = watch('policy');
  const travellingRadius = watch('travellingRadius');
  const watchPreferPermanent = watch('preferPermanent');
  const watchPreferFixedTerm = watch('preferFixedTerm');
  const watchTestimonialList = watch('testimonialsList');

  const getData = () => {
    dispatch(setLoading(true));
    Promise.all([
      ContactProfileService.getContactProfile(),
      SkillService.getSkill(),
      PreferenceService.getPreference(),
      TestimonialService.getTestimonial(),
      ContractorSettingService.getContractorSettingService(user?.id),
      MojCheckService.getMojCheck(),
      TaxService.getTax(),
    ])
      .then((res) => {
        const [
          contactProfileData,
          skillsData,
          preferenceData,
          testimonialsData,
          settingData,
          mojCheckData,
          taxDetails,
        ] = res;
        setSettingId(settingData?.id);
        setDeactivate(settingData?.deactivateAccount);
        setValue('EmailNotification', !settingData?.turnOffEmails);
        reset({
          idContact: contactProfileData?.id,
          phoneNumber: contactProfileData?.mobile,
          address:
            contactProfileData?.region && contactProfileData?.country
              ? contactProfileData?.city
                ? `${contactProfileData?.city}, ${contactProfileData?.region}, ${contactProfileData?.country}`
                : `${contactProfileData?.region}, ${contactProfileData?.country}`
              : '',
          countryCode: contactProfileData?.countryCode,

          preferenceId: preferenceData?.id,
          minHourlyRate: +preferenceData?.minRate || 30,
          hourlyRate: +preferenceData?.maxRate || 30,
          availableType: UtilContractor.getCurrentAvailableType(
            preferenceData?.availableDate,
            preferenceData?.noticePeriod
          ),
          setTime: preferenceData?.availableDate
            ? new Date(preferenceData?.availableDate)
            : undefined,
          noticePeriod: preferenceData?.noticePeriod,
          policy: UtilContractor.getCurrentWorkPolicy(
            preferenceData?.workPolicy
            
          ),
          travellingRadius: UtilContractor.getCurrentTravellingRadius(
            preferenceData?.radius
          ),

          preferFixedTerm: !!preferenceData?.preferFixedTerm,
          preferPermanent: !!preferenceData?.preferPermanent,
          minSalary: preferenceData?.minSalary || 40000,
          maxSalary: preferenceData?.maxSalary || 40000,

          mojId: mojCheckData?.id,
          visa: convertVisaType(mojCheckData?.visaType),
          mojUpload: undefined,

          idTaxDetails: taxDetails?.id || '',
          irdNumber: taxDetails?.irdNumber || '',
          bankAccountName: taxDetails?.nameOnAccount || '',
          bankAccountNumber:
            taxDetails?.bank &&
            taxDetails?.branch &&
            taxDetails?.account &&
            taxDetails?.suffix &&
            `${taxDetails?.bank}-${taxDetails?.branch}-${taxDetails?.account}-${taxDetails?.suffix}`,
          gstNumber: taxDetails?.gstNumber || '',
          gstType: UtilContractor.convertGstType(taxDetails?.gstType),
          taxCode: taxDetails?.taxCode
            ? UtilContractor?.getCurrentTaxCode(taxDetails?.taxCode)
            : null,
          withholdingTax: UtilContractor?.getCurrentHoldingTax(
            taxDetails?.withholdingTax
          ),
          limitedCompanyName: taxDetails?.companyName || '',
          businessNumber: taxDetails?.businessNumber || '',
          isLimitedCompany: taxDetails?.isLimitedCompany || false,
          gstFilingFrequency: UtilContractor?.getCurrentGstFilingFrequency(
            taxDetails?.gstFilingFrequency
          ),
          gstStartingMonth: UtilContractor?.getCurrentGstStartingMonth(
            taxDetails?.gstStartingMonth
          ),
          // workType: UtilContractor?.getCurrenJobType(taxDetails?.workType),
          bicCode: taxDetails?.bicCode || '',
          isUseHomeOffice: taxDetails?.usingHomeOffice || false,
          homeOfficeRate: taxDetails?.homeOfficeRate,
          isGstRegistered: taxDetails?.isGstRegistered || false,
          taxType: taxDetails?.isPaye,
        });

        if (skillsData === null || skillsData?.skillList?.length === 0) {
          appendSkillList({});
        } else {
          skillsData?.skillList.forEach((item) => {
            appendSkillList({ name: item });
          });
          setValue('skillsId', skillsData?.id);
        }

        if (testimonialsData === null || testimonialsData?.length === 0) {
          appendTestimonialsList({});
        } else {
          testimonialsData.forEach((item) => {
            appendTestimonialsList({
              ...item,
              testimonialId: item?.id,
              experience: item?.experience,
            });
          });
        }
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    if (!watchPreferFixedTerm && !watchPreferPermanent) {
      setValue('minSalary', null);
      setValue('maxSalary', null);
    }
  }, [watchPreferPermanent, watchPreferFixedTerm, setValue]);

  useEffect(() => {
    getData();
    dispatch(getProfile());
  }, [dispatch, reset]);

  useEffect(() => {
    if (availableType?.id === SET_TIME) {
      resetField('noticePeriod');
      setValue('noticePeriod', null);
    } else if (availableType?.id === NOTICE_PERIOD) {
      resetField('setTime');
      setValue('setTime', null);
    }
  }, [availableType, resetField, setValue]);

  useEffect(() => {
    if (policy?.id === WORK_FROM_HOME && travellingRadius) {
      resetField('travellingRadius');
    }
    // console.log(policy)
  }, [policy]);

  const renderAvailableInput = () => {
    switch (availableType?.id) {
      case SET_TIME:
        return (
          <CustomDatePicker
            name='setTime'
            control={control}
            placeholder='Pick a date...'
            dateFormat='dd/MM/yyyy'
            errorMessage={errors.setTime?.message}
            minDate={moment().toDate()}
            maxDate={moment().add(1, 'y').toDate()}
          />
        );
      default:
        return <></>;
    }
  };

  const handleRemoveTestimonialsList = (item, index) => {
    removeTestimonialsList(index);
    if (item?.testimonialId) {
      setTestimonialsRemoveList((prev) => [...prev, item?.testimonialId]);
    }
  };

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    if (tab === SettingTabs.PROFILE) {
      ContractorSettingService.putContractorSettingService(
        user?.id,
        settingId,
        {
          turnOffEmails: !data?.EmailNotification,
        }
      ).catch((error) => dispatch(setErrorMess(error)));
  
      const skillsData = [];
      data?.skillList.map((item) => skillsData.push(item?.name));
  
      let availableDate;
      let noticePeriod;
      if (data?.availableType?.id === 2) {
        availableDate = null;
        noticePeriod = 2;
      } else if (data?.availableType?.id === 4) {
        availableDate = null;
        noticePeriod = 4;
      } else {
        noticePeriod = null;
        availableDate = data?.setTime?.toISOString();
      }
  
      const arrSubmit = [];
      let isUpdateTestimonials = true;
      const testimonialsListLength = data?.testimonialsList?.length;
      for (let i = 0; i < testimonialsListLength; i++) {
        if (
          !data?.testimonialsList[i]?.name ||
          !data?.testimonialsList[i]?.email ||
          !data?.testimonialsList[i]?.experience ||
          !data?.testimonialsList[i]?.providersTitle
        ) {
          isUpdateTestimonials = false;
          break;
        }
      }
  
      if (isUpdateTestimonials) {
        const updateTestimonialList = data?.testimonialsList.map((item) => ({
          id: item?.id,
          email: item?.email,
          name: item?.name,
          experience: item?.experience.id,
          providersTitle: item?.providersTitle,
        }));
  
        arrSubmit.push(TestimonialService.putTestimonial(updateTestimonialList));
      }
  
      if (data?.idContact === '' || !data?.idContact) {
        arrSubmit.push(
          ContactProfileService.postContactProfile({
            mobile: data?.phoneNumber,
            countryCode: data?.countryCode || '+64',
            city: data?.city,
            country: data?.country,
            region: data?.region,
          })
        );
      } else {
        arrSubmit.push(
          ContactProfileService.putContactProfile(
            {
              mobile: data?.phoneNumber,
              countryCode: data?.countryCode || '+64',
              city: data?.city,
              country: data?.country,
              region: data?.region,
            },
            data?.idContact
          )
        );
      }
  
      if (skillsData.length > 1) {
        if (data?.skillsId === '' || !data?.skillsId) {
          arrSubmit.push(SkillService.postSkill(skillsData));
        } else {
          arrSubmit.push(SkillService.putSkill(skillsData, data?.skillsId));
        }
      }
  
      const preferenceSubmit = {
        id: data?.preferenceId,
        availableDate: availableDate,
        minRate: data?.minHourlyRate,
        maxRate: data?.hourlyRate,
        radius: data?.travellingRadius?.value,
        noticePeriod: data?.availableType?.id,
        workPolicy: data?.policy?.value,
        preferFixedTerm: data?.preferFixedTerm,
        preferPermanent: data?.preferPermanent,
        minSalary: data?.minSalary || 0,
        maxSalary: data?.maxSalary || 0,
      };
  
      if (!data?.preferFixedTerm && !data?.preferPermanent) {
        preferenceSubmit.minSalary = 0;
        preferenceSubmit.maxSalary = 0;
      } else {
        if (preferenceSubmit.minSalary === 0) {
          preferenceSubmit.minSalary = 40000;
        }
        if (preferenceSubmit.maxSalary === 0) {
          preferenceSubmit.maxSalary = 40000;
        }
      }
  
      if (data?.preferenceId === '' || !data?.preferenceId) {
        arrSubmit.push(PreferenceService.postPreference(preferenceSubmit));
      } else {
        arrSubmit.push(
          PreferenceService.putPreference(preferenceSubmit, data?.preferenceId)
        );
      }
  
      if (data?.visa) {
        if (!data?.mojId) {
          MojCheckService.postMojCheck({ visaType: data?.visa?.value });
        } else {
          MojCheckService.putMojCheck(data.mojId, {
            visaType: data?.visa?.value,
          });
        }
      }
  
      Promise.all(arrSubmit)
        .then((res) => {
          const [contactData, skillsData, preferenceData] = res;
          if (testimonialsRemoveList.length > 0) {
            TestimonialService.deleteTestimonial({
              ids: testimonialsRemoveList,
            })
              .then(() => {
                dispatch(setSuccessMess('Successfully update'));
                setTestimonialsRemoveList([]);
                reset();
                getData();
                dispatch(getProfile());
              })
              .catch((e) => dispatch(setErrorMess(e)))
              .finally(() => dispatch(setLoading(false)));
          } else {
            reset();
            getData();
            dispatch(getProfile());
            dispatch(setSuccessMess('Successfully update'));
          }
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => {
          dispatch(setLoading(false));
        });
    } else if (tab === SettingTabs.FINANCIAL) {
      const bankInfo = data?.bankAccountNumber.split('-');
      const isLimitedCompany = !data?.taxType ? data?.isLimitedCompany : false;
      const taxDetailsSubmit = {
        id: data?.idTaxDetails || null,
        irdNumber: data?.irdNumber,
        nameOnAccount: data?.bankAccountName,
        bank: bankInfo[0],
        branch: bankInfo[1],
        account: bankInfo[2],
        suffix: bankInfo[3].split('_')[0],
        gstNumber: data?.isGstRegistered ? data?.gstNumber : null,
        gstType: data?.gstType ? data?.gstType?.value : null,
        taxCode: data?.taxType ? data?.taxCode?.value : null,
        isLimitedCompany: isLimitedCompany,
        isGstRegistered: data?.isGstRegistered || false,
        companyName: isLimitedCompany ? data?.limitedCompanyName : null,
        businessNumber: isLimitedCompany ? data?.businessNumber : null,
        withholdingTax: data?.withholdingTax?.value,
        isPaye: data?.taxType,
        userId: user?.id,
        gstFilingFrequency: data?.isGstRegistered
          ? data?.gstFilingFrequency?.value
          : null,
        gstStartingMonth: data?.isGstRegistered
          ? data?.gstStartingMonth?.value
          : null,
        usingHomeOffice: data?.isUseHomeOffice || false,
        homeOfficeRate: data?.isUseHomeOffice ? data?.homeOfficeRate : null,
        bicCode: data?.bicCode,
      };
  
      if (taxDetailsSubmit?.id) {
        TaxService.putTax(taxDetailsSubmit, taxDetailsSubmit?.id)
          .then(() => {
            dispatch(setSuccessMess('Successfully update'));
            reset();
            getData();
            dispatch(getProfile());
          })
          .catch((e) => dispatch(setErrorMess(e)))
          .finally(() => dispatch(setLoading(false)));
      } else {
        TaxService.postTax(taxDetailsSubmit)
          .then(() => {
            dispatch(setSuccessMess('Successfully update'));
            reset();
            getData();
            dispatch(getProfile());
          })
          .catch((e) => dispatch(setErrorMess(e)))
          .finally(() => dispatch(setLoading(false)));
      }
    }
  };
  

  const handleFileInputChange = ({ target }) => {
    if (target?.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(target.files[0]);
      reader.onload = function () {
        const temp = reader.result as string;
        const arr = temp.split(',');
        setValue('cvUploadFileBase64', arr[1]); // Only the base64 part
        setValue('cvFileName', target.files[0].name); // Store file name
        handleShowModal();
      };
    } else {
      setValue('cvUploadFileBase64', '');
      setValue('cvFileName', '');
    }
  };

  const handleShowModal = () => {
    setModal(!modal);
    if (modal === true) {
      resetField('cvUpload');
    }
  };
 
  const editProfileByUploadCV = () => {
    reset();
    getData();
  };

  const handleAcceptParsing = async () => {
    try {
      dispatch(setLoading(true));
      
      const result = await UtilCvParsing.cvParsing(
        dispatch,
        user,
        profile,
        {
          cvUploadFileBase64: getValues("cvUploadFileBase64"),
          cvFileName: getValues("cvFileName"),
        },
        editProfileByUploadCV
      );
  
      if (result) {
        dispatch(
          setSuccessMess("Your CV has been parsed successfully and your profile has been updated.")
        );
        reset(); // Reset the form state
        getData(); // Refresh profile data
      } else {
        // Provide a specific commentary for parsing failures
        dispatch(
          setErrorMess({
            detail: "We couldn't parse your CV. Please simplify your CV or update your profile manually.",
          })
        );
      }
    } catch (error) {
      // Ensure a structured error object for setErrorMess
      const errorMessage = error instanceof Error
        ? { detail: error.message }
        : { detail: "An unexpected error occurred while parsing your CV." };
  
      console.error("Error during CV parsing:", error);
      dispatch(setErrorMess(errorMessage));
    } finally {
      dispatch(setLoading(false));
      handleShowModal(); // Close the modal
    }
  };
  
  
  
  
  

  const handleDeactivate = () => {
    dispatch(setLoading(true));
    ContractorSettingService.putContractorSettingService(user?.id, settingId, {
      deactivateAccount: !deactivate,
    })
      .then((res) => {
        if (res?.deactivateAccount) {
          dispatch(setSuccessMess('Successfully deactivate account!'));
        } else {
          dispatch(setSuccessMess('Successfully activate account!'));
        }
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
    setDeactivate(!deactivate);
  };

  const handleRequestTestimonial = (testimonialId, index) => {
    dispatch(setLoading(true));

    const name = getValues(`testimonialsList.${index}.name`);
    const email = getValues(`testimonialsList.${index}.email`);
    const providersTitle = getValues(
      `testimonialsList.${index}.providersTitle`
    );
    const experience = getValues(`testimonialsList.${index}.experience`);

    const newTestimonial = {
      id: testimonialId || undefined,
      name,
      email,
      providersTitle,
      experience,
      isRequestEmailSent: true,
    };

    const list = getValues('testimonialsList');
    let newList = [...list];
    newList.splice(
      list?.findIndex((item) => item?.id === newTestimonial?.id),
      1,
      newTestimonial
    );

    newList = newList.map((item) => ({
      ...item,
      experience: item?.experience?.id,
      agreedToTerms: false,
    }));

    TestimonialService.putTestimonial(newList)
      .then(async (res) => {
        if (testimonialId) {
          await TestimonialService.requestTestimonialEmail(
            user?.id,
            testimonialId,
            {
              type: EmailTypes.TESTIMONIAL_REQUEST,
            }
          )
            .then(() => {
              setValue(`testimonialsList.${index}`, newTestimonial);
              dispatch(
                setSuccessMess(
                  ' An Email has been sent requesting your Testimonial.'
                )
              );
            })
            .catch((e) => dispatch(setErrorMess(e)));
        } else {
          await TestimonialService.requestTestimonialEmail(
            user?.id,
            res[res.length - 1]?.id,
            {
              type: EmailTypes.TESTIMONIAL_REQUEST,
            }
          )
            .then(() => {
              setValue(`testimonialsList.${index}`, newTestimonial);
              dispatch(
                setSuccessMess(
                  ' An Email has been sent requesting your Testimonial.'
                )
              );
            })
            .catch((e) => dispatch(setErrorMess(e)));
        }
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <Fragment>
      <ScrollNavbar />
      <div className='wrapper'>
        <div className={classes.header} />
        <div className={classes.section}>
          <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.content}>
                <Nav className='nav-pills-primary' pills>
                  <NavItem>
                    <NavLink
                      className={
                        tab === SettingTabs.PROFILE
                          ? clsx('active', classes.navLinkItem)
                          : classes.navLinkItem
                      }
                      onClick={() => {
                        setTab(SettingTabs.PROFILE);
                      }}
                    >
                      PROFILE
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        tab === SettingTabs.FINANCIAL
                          ? clsx('active', classes.navLinkItem)
                          : classes.navLinkItem
                      }
                      onClick={() => {
                        setTab(SettingTabs.FINANCIAL);
                      }}
                    >
                      FINANCIAL
                    </NavLink>
                  </NavItem>
                </Nav>

                {tab === SettingTabs.PROFILE && (
                  <Fragment>
                    {/* ------------------------ CONTACT PROFILE ------------------------ */}
                    <div className={classes.commonContainer}>
                      <p className={classes.commonTitle}>Contact profile:</p>
                      <Row className={classes.commonContent}>
                        <Col md='6' xs='12' className='d-none'>
                          <CustomInput
                            className={classes.commonInput}
                            placeholder='Contact ID'
                            autoComplete='off'
                            inputRef={register('idContact')}
                            errorMessage={errors.idContact?.message}
                          />
                        </Col>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>Phone number</p>
                          <CustomInput
                            className={classes.commonInput}
                            placeholder='Your phone number'
                            type='text'
                            autoComplete='off'
                            inputRef={register('phoneNumber')}
                            errorMessage={errors.phoneNumber?.message}
                          />
                        </Col>

                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Nearest Town or City
                          </p>
                          <Controller
                            name='address'
                            control={control}
                            render={({ field }) => (
                              <SearchLocationInput
                                {...field}
                                ref={null}
                                placeholder='Your nearest town or city'
                                errorMessage={errors.address?.message}
                                value={field.value}
                                onChange={(value: any) => {
                                  setValue('region', value?.address?.region);
                                  setValue('city', value?.address?.city);
                                  setValue('country', value?.address?.country);
                                  return field.onChange(
                                    value?.address?.formattedAddress
                                  );
                                }}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    {/* ------------------------ SKILL ------------------------ */}
                    <div className={classes.commonContainer}>
                      <p className={classes.commonTitle}>
                        Skills &amp; Capabilities:
                      </p>
                      <div className={classes.oneRowContent}>
                        <Row>
                          <Col>
                            <CustomInput
                              className='d-none'
                              placeholder='Skills ID'
                              autoComplete='off'
                              inputRef={register('skillsId')}
                              errorMessage={errors.skillsId?.message}
                            />
                          </Col>
                        </Row>
                        <Row>
                          {fieldsSkillList?.map((item, index) => {
                            return (
                              <Col
                                md='6'
                                xs='12'
                                className={classes.commonItem}
                                key={item?.id}
                              >
                                <CustomInput
                                  endIcon={
                                    fieldsSkillList?.length > 1 && (
                                      <Button
                                        className={classes.btnDeleteSkill}
                                        onClick={() => removeSkillList(index)}
                                      >
                                        <i className='fa fa-trash' />
                                      </Button>
                                    )
                                  }
                                  placeholder='Skill, capability, tag,...'
                                  type='text'
                                  autoComplete='off'
                                  inputRef={register(`skillList.${index}.name`)}
                                  errorMessage={
                                    errors.skillList &&
                                    errors.skillList[index]?.name?.message
                                  }
                                />
                              </Col>
                            );
                          })}
                        </Row>
                        <div className={classes.btnAddMoreContainer}>
                          <Button
                            type='button'
                            className={classes.btnAddMore}
                            onClick={() => appendSkillList({})}
                          >
                            <span className='text-dark'>
                              Add more
                              <i className='fa fa-plus ml-2' />
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------ PREFERENCE ------------------------ */}
                    <div className={classes.commonContainer}>
                      <p className={classes.commonTitle}>Preference:</p>
                      <Row className={classes.commonContent}>
                        <Col md='6' xs='12' className='d-none'>
                          <CustomInput
                            className={classes.commonInput}
                            placeholder='Preference ID'
                            autoComplete='off'
                            inputRef={register('preferenceId')}
                            errorMessage={errors.preferenceId?.message}
                          />
                        </Col>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Minimum hourly rate
                          </p>
                          <CustomSlider
                            name='minHourlyRate'
                            min={30}
                            max={300}
                            control={control}
                            className={classes.sliderRate}
                            errorMessage={errors.minHourlyRate?.message}
                          />
                        </Col>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Preferred hourly rate
                          </p>
                          <CustomSlider
                            name='hourlyRate'
                            min={30}
                            max={300}
                            control={control}
                            className={classes.sliderRate}
                            errorMessage={errors.hourlyRate?.message}
                          />
                        </Col>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>Policy</p>
                          <CustomSelect
                            className={classes.commonSelect}
                            placeholder='Your work policy'
                            name='policy'
                            control={control}
                            options={PreferencePolicyTypes}
                            errorMessage={errors?.policy?.message}
                            tooltipText="Select 'Office' if you prefer working exclusively from the office. Choose 'Hybrid' if you'd like to negotiate a blend of remote and office work. Opt for 'Work From Home' if you exclusively prefer remote work. 'All' indicates openness to any work arrangement, subject to mutual agreement."
                          />
                        </Col>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Travelling radius
                          </p>
                          <CustomSelect
                            className={classes.commonSelect}
                            isDisabled={
                              !policy || policy?.id === WORK_FROM_HOME
                            }
                            placeholder='Your travelling radius'
                            name='travellingRadius'
                            control={control}
                            value={
                              policy?.id === WORK_FROM_HOME || !travellingRadius
                                ? null
                                : travellingRadius
                            }
                            options={PreferenceTravelingRadius}
                            errorMessage={errors.travellingRadius?.message}
                            tooltipText='Please indicate the maximum distance you are willing to travel to work each day.'
                          />
                        </Col>

                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Availability
                          </p>
                          <CustomSelect
                            className={classes.commonSelect}
                            placeholder='Your available time'
                            name='availableType'
                            control={control}
                            options={PreferenceAvailableTypes}
                            errorMessage={errors.availableType?.message}
                          />
                          {renderAvailableInput()}
                        </Col>
                      </Row>
                    </div>
                     {/* ------------------------ Future Opportunities ------------------------ */}
                     <div className={classes.commonContainer}>
                      <p className={classes.commonTitle}>
                        Future Opportunities:
                      </p>
                      <Row className={classes.commonContent}>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p
                            className={clsx(
                              classes.commonSubtitle,
                              classes.commonSubtitleFutureOpportunities
                            )}
                          >
                            Would you consider permanent work?
                          </p>
                          <CustomRadioButtons
                            content={[
                              { title: 'Yes', value: true },
                              { title: 'No', value: false },
                            ]}
                            name='preferPermanent'
                            inputName='preferPermanent'
                            control={control}
                            className={classes.customRadio}
                            errorMessage={errors.preferPermanent?.message}
                          />
                        </Col>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p
                            className={clsx(
                              classes.commonSubtitle,
                              classes.commonSubtitleFutureOpportunities
                            )}
                          >
                            Would you consider fixed-term work?
                          </p>
                          <CustomRadioButtons
                            content={[
                              { title: 'Yes', value: true },
                              { title: 'No', value: false },
                            ]}
                            name='preferFixedTerm'
                            inputName='preferFixedTerm'
                            control={control}
                            className={classes.customRadio}
                            errorMessage={errors.preferFixedTerm?.message}
                          />
                        </Col>
                        {(watchPreferPermanent || watchPreferFixedTerm) && (
                          <>
                            <Col md='6' xs='12' className={classes.commonItem}>
                              <p className={classes.commonSubtitle}>
                                Salary no lower than $...
                              </p>
                              <CustomSlider
                                name='minSalary'
                                min={40000}
                                max={500000}
                                step={1000}
                                control={control}
                                className={classes.sliderRate}
                                errorMessage={errors.minSalary?.message}
                              />
                            </Col>
                            <Col md='6' xs='12' className={classes.commonItem}>
                              <p className={classes.commonSubtitle}>
                                Preferred salary $...
                              </p>
                              <CustomSlider
                                name='maxSalary'
                                min={40000}
                                max={500000}
                                step={1000}
                                control={control}
                                className={classes.sliderRate}
                                errorMessage={errors.maxSalary?.message}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                    {/* ------------------------ TESTIMONIALS ------------------------ */}
                    <div className={classes.commonContainer}>
                      <p className={classes.commonTitle}>Testimonials:</p>
                      <div className={classes.oneRowContent}>
                        {watchTestimonialList?.map((item, index) => {
                          return (
                            <div key={index}>
                              {watchTestimonialList?.length > 1 && (
                                <div className={classes.btnDelete}>
                                  <Button
                                    onClick={() =>
                                      handleRemoveTestimonialsList(item, index)
                                    }
                                  >
                                    <i className='fa fa-trash' />
                                  </Button>
                                </div>
                              )}
                              <Row>
                                <Col
                                  md='6'
                                  xs='12'
                                  className={classes.commonItem}
                                >
                                  <p className={classes.commonSubtitle}>Name</p>
                                  <CustomInput
                                    className={classes.commonInput}
                                    placeholder='Your testimonial name'
                                    type='text'
                                    autoComplete='off'
                                    inputRef={register(
                                      `testimonialsList.${index}.name`
                                    )}
                                    errorMessage={
                                      errors.testimonialsList &&
                                      errors.testimonialsList[index]?.name
                                        ?.message
                                    }
                                  />
                                </Col>
                                <Col
                                  md='6'
                                  xs='12'
                                  className={classes.commonItem}
                                >
                                  <p className={classes.commonSubtitle}>
                                    Title
                                  </p>
                                  <CustomInput
                                    className={classes.commonInput}
                                    placeholder='Job title'
                                    type='text'
                                    autoComplete='off'
                                    inputRef={register(
                                      `testimonialsList.${index}.providersTitle`
                                    )}
                                    errorMessage={
                                      errors.testimonialsList &&
                                      errors.testimonialsList[index]
                                        ?.providersTitle?.message
                                    }
                                  />
                                </Col>
                                <Col
                                  md='6'
                                  xs='12'
                                  className={classes.commonItem}
                                >
                                  <p className={classes.commonSubtitle}>
                                    Email
                                  </p>
                                  <CustomInput
                                    className={classes.commonInput}
                                    placeholder='Testimonial email'
                                    type='email'
                                    autoComplete='off'
                                    inputRef={register(
                                      `testimonialsList.${index}.email`
                                    )}
                                    errorMessage={
                                      errors.testimonialsList &&
                                      errors.testimonialsList[index]?.email
                                        ?.message
                                    }
                                  />
                                </Col>
                                <Col
                                  md='6'
                                  xs='12'
                                  className={classes.commonItem}
                                >
                                  <p className={classes.commonSubtitle}>
                                    Provider's company
                                  </p>
                                  <CustomSelect
                                    className={classes.commonSelect}
                                    placeholder="Testimonial provider's company"
                                    name={`testimonialsList.${index}.experience`}
                                    bindLabel={'company'}
                                    control={control}
                                    options={
                                      profile?.experience?.length
                                        ? profile?.experience
                                        : []
                                    }
                                    errorMessage={
                                      errors.testimonialsList &&
                                      errors.testimonialsList[index]?.experience
                                        ?.message
                                    }
                                  />
                                </Col>
                                <Col xs='12 d-flex align-items-center justify-content-center'>
                                  <Button
                                    className={
                                      (item as any)?.isRequestEmailSent
                                        ? clsx('btn-round', classes.btnDisabled)
                                        : 'btn-round'
                                    }
                                    disabled={
                                      (item as any)?.isRequestEmailSent
                                        ? true
                                        : false
                                    }
                                    color='primary'
                                    onClick={() =>
                                      handleRequestTestimonial(
                                        (item as any)?.testimonialId,
                                        index
                                      )
                                    }
                                  >
                                    {((item as any)?.response &&
                                      'Testimonial Received') ||
                                      ((item as any)?.isRequestEmailSent &&
                                        'Testimonial Requested') ||
                                      'Request Testimonial'}
                                  </Button>
                                </Col>
                              </Row>
                              {index < fieldsTestimonialsList?.length - 1 && (
                                <div className={classes.divider} />
                              )}
                            </div>
                          );
                        })}
                        <div className={classes.btnAddMoreContainer}>
                          <Button
                            type='button'
                            className={classes.btnAddMore}
                            onClick={() => appendTestimonialsList({})}
                          >
                            <span className='text-dark'>
                              Add more
                              <i className='fa fa-plus ml-2' />
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                   
                    {/* ------------------------ EMAIL PREFERENCES ------------------------ */}
                    <div className={classes.commonContainer}>
                      <p className={classes.commonTitle}>Email preferences:</p>
                      <Row className={classes.commonContent}>
                        <Col md='12' xs='12' className={classes.commonItem}>
                          <CustomCheckbox
                            content='Receive Emails alerting of New Messages and Offer Updates'
                            inputRef={register(`EmailNotification`)}
                            errorMessage={errors.EmailNotification}
                            className={clsx('my-2', classes.customCheckbox)}
                          />
                        </Col>
                      </Row>
                    </div>
                    {/* ------------------------ MOJ CHECK ------------------------ */}
                    <div className={classes.mojContainer}>
                      <p className={classes.commonTitle}>Authority to work:</p>
                      <CustomSelect
                        className={classes.commonSelect}
                        placeholder='Set Visa Type'
                        name='visa'
                        control={control}
                        options={VisaTypes}
                        errorMessage={errors.visa?.message}
                        tooltipText='Please specify the type of visa you currently hold, if applicable. This information helps us ensure that you are eligible to work in the desired location.'
                      />
                      {/* <div
                        className={clsx(
                          classes.oneRowContainer,
                          classes.hideBorder
                        )}
                      >
                        <p className={classes.commonTitle}>MOJ check:</p>
                        <input
                          className={classes.uploadFile}
                          type="file"
                          placeholder="Upload..."
                          {...register("mojUpload")}
                        />
                      </div>
                      <div
                        className={clsx(
                          classes.oneRowContainer,
                          classes.hideBorder
                        )}
                      >
                        <p className={classes.commonTitle}>
                          Identification Check:
                        </p>
                        <input
                          className={classes.uploadFile}
                          type="file"
                          placeholder="Upload..."
                          {...register("identification")}
                        />
                      </div> */}
                      <div
                        className={clsx(
                          classes.oneRowContainer,
                          classes.hideBorder
                        )}
                      >
                        <p className={classes.commonTitle}>Upload CV:</p>
                        <input
                          className={classes.uploadFile}
                          type='file'
                          placeholder='Upload...'
                          {...register('cvUpload')}
                          accept='.doc, .docx, .pdf'
                          onChange={handleFileInputChange}
                        />
                      </div>
                    </div>
                    {/* ------------------------ SAVE BUTTON ------------------------ */}
                    <div className={classes.btnSaveContainer}>
                      <Button
                        className={`btn-round ${classes.btnSave}`}
                        color='primary'
                        type='submit'
                      >
                        <i className='fa fa-save' /> Save
                      </Button>
                    </div>
                    {/* ------------------------ DEACTIVATE ACCOUNT ------------------------ */}
                    <div>
                      <p className={classes.deactivateTitle}>
                        Deactivate account
                      </p>
                      <div className={classes.deactivateContent}>
                        <CustomSwitch
                          value={deactivate}
                          onChange={handleDeactivate}
                        />
                        {deactivate && (
                          <p>
                            Your account is now deactivated, you will no longer
                            be able to receive offers.
                          </p>
                        )}
                      </div>
                    </div>
                  </Fragment>
                )}

                {tab === SettingTabs.FINANCIAL && (
                  <Fragment>
                    {/* ------------------------ TAX ------------------------ */}
                    <div className={classes.commonContainer}>
                      <p className={classes.commonTitle}>Financial:</p>
                      <Row className={classes.commonContent}>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p
                            className={clsx(
                              classes.commonSubtitle,
                              classes.commonSubtitleFutureOpportunities
                            )}
                          >
                            Tax type:
                          </p>
                          <CustomRadioButtons
                            content={[
                              {
                                title: 'PAYE',
                                value: true,

                                disabled: true,
                              },
                              {
                                title: 'DIY',
                                value: false,

                                disabled: false,
                              },
                            ]}
                            name='taxType'
                            inputName='taxType'
                            control={control}
                            className={classes.customRadio}
                            errorMessage={errors.taxType?.message}
                          />
                        </Col>
                        {!watch('taxType') && (
                          <>
                            <Col md='6' xs='12' className={classes.commonItem}>
                              <p
                                className={clsx(
                                  classes.commonSubtitle,
                                  classes.commonSubtitleFutureOpportunities
                                )}
                              >
                                Are you a Limited Company?
                              </p>
                              <CustomRadioButtons
                                content={[
                                  {
                                    title: 'Yes',
                                    value: true,
                                    default: false,
                                  },
                                  {
                                    title: 'No',
                                    value: false,
                                    default: true,
                                  },
                                ]}
                                name='isLimitedCompany'
                                inputName='isLimitedCompany'
                                control={control}
                                className={classes.customRadio}
                                errorMessage={errors.isLimitedCompany?.message}
                              />
                            </Col>
                            {watch('isLimitedCompany') && (
                              <>
                                <Col
                                  md='6'
                                  xs='12'
                                  className={classes.commonItem}
                                >
                                  <p className={classes.commonSubtitle}>
                                    Your Company Legal Name:
                                  </p>
                                  <CustomInput
                                    className={classes.commonInput}
                                    placeholder='Your Company Legal Name'
                                    type='text'
                                    autoComplete='off'
                                    inputRef={register('limitedCompanyName')}
                                    errorMessage={
                                      errors.limitedCompanyName?.message
                                    }
                                  />
                                </Col>
                                <Col
                                  md='6'
                                  xs='12'
                                  className={classes.commonItem}
                                >
                                  <p className={classes.commonSubtitle}>
                                    New Zealand Business Number:
                                  </p>
                                  <CustomInput
                                    className={classes.commonInput}
                                    placeholder='New Zealand Business Number'
                                    type='text'
                                    autoComplete='off'
                                    inputRef={register('businessNumber')}
                                    errorMessage={
                                      errors.businessNumber?.message
                                    }
                                  />
                                </Col>
                              </>
                            )}
                          </>
                        )}
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p
                            className={clsx(
                              classes.commonSubtitle,
                              classes.commonSubtitleFutureOpportunities
                            )}
                          >
                            Are you GST registered ?
                          </p>
                          <CustomRadioButtons
                            content={[
                              { title: 'Yes', value: true },
                              { title: 'No', value: false },
                            ]}
                            name='isGstRegistered'
                            inputName='isGstRegistered'
                            control={control}
                            className={classes.customRadio}
                            errorMessage={errors.isGstRegistered?.message}
                          />
                        </Col>
                        {watch('isGstRegistered') && (
                          <>
                            <Col md='6' xs='12' className={classes.commonItem}>
                              <p className={classes.commonSubtitle}>
                                GST Number:
                              </p>
                              <CustomInput
                                className={classes.commonInput}
                                placeholder='Your GST Number'
                                type='text'
                                autoComplete='off'
                                inputRef={register('gstNumber', {
                                  required: 'GST Number is required', // if it's required
                                  validate: (value) =>
                                    /^\d+$/.test(value) ||
                                    'GST Number must be a numeric string',
                                })}
                                errorMessage={errors.gstNumber?.message}
                              />
                            </Col>
                            <Col md='6' xs='12' className={classes.commonItem}>
                              <p className={classes.commonSubtitle}>
                                GST Type:
                              </p>
                              <CustomSelect
                                className={classes.commonSelect}
                                placeholder='Your GST Type'
                                name='gstType'
                                control={control}
                                options={GstTypes}
                                errorMessage={errors?.gstType?.message}
                              />
                            </Col>
                            <Col md='6' xs='12' className={classes.commonItem}>
                              <p className={classes.commonSubtitle}>
                                How often do you file GST?
                              </p>
                              <CustomSelect
                                className={classes.commonSelect}
                                placeholder='How often do you file GST'
                                name='gstFilingFrequency'
                                control={control}
                                options={gstFilingFrequency}
                                errorMessage={
                                  errors?.gstFilingFrequency?.message
                                }
                              />
                            </Col>
                            <Col md='6' xs='12' className={classes.commonItem}>
                              <p className={classes.commonSubtitle}>
                                Starting Month
                              </p>
                              <CustomSelect
                                className={classes.commonSelect}
                                placeholder='Starting Month'
                                name='gstStartingMonth'
                                control={control}
                                options={gstStartingMonth}
                                errorMessage={errors?.gstStartingMonth?.message}
                              />
                            </Col>
                          </>
                        )}
                        {/* <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>Work Type:</p>
                          <CustomSelect
                            className={classes.commonSelect}
                            placeholder="Work Type"
                            name="workType"
                            control={control}
                            options={WorkTypes}
                            errorMessage={errors?.workType?.message}
                          />
                        </Col> */}
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>BIC Code:</p>
                          <CustomInput
                            className={classes.commonInput}
                            placeholder='Your BIC Code'
                            autoComplete='off'
                            type='text'
                            inputRef={register('bicCode')}
                            errorMessage={errors?.bicCode?.message}
                            endIcon={
                              <a
                                href='https://www.businessdescription.co.nz'
                                target='_blank'
                                rel='noreferrer'
                              >
                                <i
                                  className={clsx(
                                    'fa fa-search',
                                    classes.searchIcon
                                  )}
                                />
                              </a>
                            }
                          />
                        </Col>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p
                            className={clsx(
                              classes.commonSubtitle,
                              classes.commonSubtitleFutureOpportunities
                            )}
                          >
                            Do you use a Home Office?
                          </p>
                          <CustomRadioButtons
                            content={[
                              { title: 'Yes', value: true },
                              { title: 'No', value: false },
                            ]}
                            name='isUseHomeOffice'
                            inputName='isUseHomeOffice'
                            control={control}
                            className={classes.customRadio}
                            errorMessage={errors.isUseHomeOffice?.message}
                          />
                        </Col>
                        {watch('isUseHomeOffice') && (
                          <Col md='6' xs='12' className={classes.commonItem}>
                            <p className={classes.commonSubtitle}>
                              What percentage of your home office do you use?
                            </p>
                            <CustomInput
                              className={classes.commonInput}
                              placeholder='Your percentage of your home office used'
                              autoComplete='off'
                              inputRef={register('homeOfficeRate')}
                              errorMessage={errors.homeOfficeRate?.message}
                            />
                          </Col>
                        )}
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>IRD Number:</p>
                          <CustomInput
                            className={classes.commonInput}
                            placeholder='Your IRD Number'
                            autoComplete='off'
                            inputRef={register('irdNumber')}
                            errorMessage={errors.irdNumber?.message}
                          />
                        </Col>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Bank Account Name:
                          </p>
                          <CustomInput
                            className={classes.commonInput}
                            placeholder='Your Bank Account Name'
                            autoComplete='off'
                            inputRef={register('bankAccountName')}
                            errorMessage={errors.bankAccountName?.message}
                          />
                        </Col>

                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Bank Account Number:
                          </p>
                          <CustomInputMask
                            placeHolder='Your Bank Account Number'
                            name='bankAccountNumber'
                            format='99-9999-9999999-999'
                            control={control}
                            errorMessage={errors.bankAccountNumber?.message}
                          />
                        </Col>
                        <Col md='6' xs='12' className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Withholding Tax:
                          </p>
                          <CustomSelect
                            className={classes.commonSelect}
                            placeholder='Your Withholding Tax'
                            name='withholdingTax'
                            control={control}
                            options={WithholdingTax}
                            errorMessage={errors?.withholdingTax?.message}
                          />
                          {watch('withholdingTax')?.id === 1 && (
                            <p
                              className={clsx(
                                classes.commonSubtitle,
                                'text-danger'
                              )}
                            >
                              Please send 0% letter from IRD to michael@necta.nz
                            </p>
                          )}
                        </Col>
                        {watch('taxType') && (
                          <Col md='6' xs='12' className={classes.commonItem}>
                            <p className={classes.commonSubtitle}>Tax Code:</p>
                            <CustomSelect
                              className={classes.commonSelect}
                              placeholder='Tax Code'
                              name='taxCode'
                              control={control}
                              options={TaxCode}
                              errorMessage={errors?.taxCode?.message}
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                    {/* ------------------------ SAVE BUTTON ------------------------ */}
                    <div className={classes.btnSaveContainer}>
                      <Button
                        className={`btn-round ${classes.btnSave}`}
                        color='primary'
                        type='submit'
                      >
                        <i className='fa fa-save' /> Save
                      </Button>
                    </div>
                  </Fragment>
                )}

                <Modal
                  isOpen={modal}
                  toggle={handleShowModal}
                  className={classes.termModal}
                >
                  <ModalHeader toggle={handleShowModal}>Warning</ModalHeader>
                  <ModalBody>
                    This will delete and update your current profile information based on the uploaded CV. Do you want to continue?
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-end">
                    <Button color="primary" onClick={handleAcceptParsing}>
                      Accept
                    </Button>
                    <Button color="default" onClick={handleShowModal} className="ml-3">
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </Form>
          </Container>
        </div>
        <FooterDefault />
      </div>
      <FooterMobile />
    </Fragment>
  );
});

export default Setting;
