import { memo, useMemo, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import FooterDefault from "components/Footers/FooterDefault";
import { Button, Col, Container, Form, Row } from "reactstrap";
import CustomInput from "components/Common/CustomInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import ImageUpload from "components/CustomUpload";
import CustomTextarea from "components/CustomTextarea";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  setErrorMess,
  setLoading,
  setSuccessMess,
} from "redux/reducers/Status/actionTypes";
import { BasicProfileService } from "services/Employer/NewMember/BasicProfile";
import { ImageService } from "services/Image";
import { OrganizationService } from "services/Employer/NewMember/Organization";
import CustomTagsInput from "components/Common/CustomTagsInput";
import { ReducerType } from "redux/reducers";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
// import { UserChatEngineService } from "services/Message/User/UserChatEngine";
import password from "secure-random-password";
import { setUserReducer,setUserAvatarReducer } from "redux/reducers/User/actionTypes";
import { UserService } from "services/User";
import { setSettingReducer, getSetting } from "redux/reducers/Employer/actionTypes";
import { UtilDefaultAvatar } from "utils/defaultAvatar";
import CustomSwitch from "components/Common/CustomSwitch";
import SearchLocationInput from "components/Common/GoogleAddress";
import CustomCheckbox from "components/Common/CustomCheckbox";
import { ContractorSettingService } from "services/Contractor/Settings/Email";
import CustomCheckboxFlip from 'components/Common/CustomCheckBoxFlip';


interface Props {}
interface ImageFile extends File {}

interface SettingFormData {
  idBasicProfile?: number;
  firstName: string;
  lastName: string;
  title: string;
  workPhone?: string;
  avatar?: File;
  companyAvatar?: ImageFile;
  recruitOnBehalf?: boolean;
  workPhoneCountryCode?: string;
  headHunter?: boolean;
  idOrganisation?: number;
  name?: string;
  industry?: string;
  numberOfWorkers?: number[];
  mission?: string;
  culture?: string;
  behaviouralSklls?: string[];
  nzbn?: string;
  email?: string;
  phone?: string;
  countryCode?: string;
  uniqueCode?: string;
  value?: any;
  city?: string;
  country?: string;
  address?: string;
  EmailNotification?: boolean;
}

// eslint-disable-next-line no-empty-pattern
const Setting = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  // console.log("here",setting, user);

  const [deactivate, setDeactivate] = useState(false);
  const [settingId, setSettingId] = useState();

  const schema = useMemo(() => {
    return yup.object().shape({
      idBasicProfile: yup.string(),
      avatar: yup.mixed(),
      companyAvatar: yup.mixed(),
      firstName: yup
        .string()
        .min(2, "At least 2 characters")
        .max(64, "At least 64 characters")
        .required("This field is required"),
      lastName: yup
        .string()
        .min(2, "At least 2 characters")
        .max(64, "At least 64 characters")
        .required("This field is required"),
      title: yup.string().required("This field is required"),
      workPhone: yup.string().required("This field is required"),
      recruitOnBehalf: yup.boolean().oneOf([true, false]),

      idOrganisation: yup.string().nullable(),
      name: yup.string().required("This field is required"),
      // mission: yup
      //   .string()
      //   .nullable()
      //   .when("recruitOnBehalf", {
      //     is: false,
      //     then: yup.string().nullable().required("This field is required"),
      //   }),
      // culture: yup
      //   .string()
      //   .nullable()
      //   .when("recruitOnBehalf", {
      //     is: false,
      //     then: yup.string().nullable().required("This field is required"),
      //   }),
      // industry: yup.string().nullable(),
      numberOfWorkers: yup
        .array()
        .of(yup.number())
        .typeError("That doesn't look like the number")
        .nullable(),
      behaviouralSklls: yup.array().of(yup.string()).nullable(),
      nzbn: yup.string().nullable(),
      email: yup.string().email("Please enter a valid email").nullable(),
      address: yup.string(),
      country: yup.string().nullable(),
      city: yup.string().nullable(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
  } = useForm<SettingFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const recruitOnBehalf = watch("recruitOnBehalf");
  const watchedCompanyAvatar = watch("companyAvatar");
  // console.log(watchedCompanyAvatar);

  const [initialImage, setInitialImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const imageKey = `${setting?.idOrganisation}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`;
      console.log(imageKey)
      try {
        if (imageKey) {
          const imageUrl = await ImageService.getCompanyAvatar(imageKey);
          setInitialImage(imageUrl);
        }
      } catch (error) {
        console.error("Failed to load initial image", error);
      }
    };
  
    fetchImage();
  }, [setting]);
  
  

  useEffect(() => {
    if (setting && user) {
      reset({
        ...setting,
        idBasicProfile: setting.idBasicProfile,
        firstName: setting.firstName,
        lastName: setting.lastName,
        title: setting.title,
        workPhone: setting.workPhone,
        recruitOnBehalf: setting.recruitOnBehalf,
        workPhoneCountryCode: setting.countryCode,
      });

      const isRecruit = setting.recruitOnBehalf;
      setValue("email", user.email);
      // if (!isRecruit) {
      //   reset({
      //     ...setting,
      //     idOrganisation: setting.idOrganisation,
      //     name: setting.name,
      //     mission: setting.mission,
      //     culture: setting.culture,
      //     industry: setting.industry,
      //     numberOfWorkers: setting.numberOfWorkers,
      //     behaviouralSklls: setting.behaviouralSklls,
      //     nzbn: setting.nzbn,
      //     email: setting.email,
      //     address:
      //       setting.city && setting.country
      //         ? `${setting.city}, ${setting.country}`
      //         : "",
      //   });
      // }

      ContractorSettingService.getContractorSettingService(user?.id)
        .then((res) => {
          setSettingId(res?.id);
          setDeactivate(res?.deactivateAccount);
          setValue("EmailNotification", !res?.turnOffEmails);
        })
        .catch((err) => dispatch(setErrorMess(err)));

      if (setting.photoUrl) {
        ImageService.getImageBlob(setting.photoUrl)
          .then((res) => {
            setValue("avatar", blobToFile(res as Blob, "avatar.png"));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      }
    }
  }, [dispatch, reset, setValue, setting, user]);

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob as any], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const onSubmit = (data: SettingFormData) => {
    // console.log(data);
    dispatch(setLoading(true));

    ContractorSettingService.putContractorSettingService(user?.id, settingId, {
      turnOffEmails: !data?.EmailNotification,
    }).catch((error) => dispatch(setErrorMess(error)));

    if (data?.companyAvatar) {
      // console.log(data?.companyAvatar);
      // console.log(data);
      try {
        ImageService.postCompanyAvatar(
          data?.companyAvatar,
          `${data?.idOrganisation}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`
        )
      } catch (error) {
        console.error("Upload error:", error);
        throw error; // Or handle the error appropriately
      }

    }
    ImageService.postImage(
      data?.avatar,
      `${user?.id}-${S3_UPLOAD_TYPE_PRIFIX.AVATAR}`
    )
      .then(async (res) => {
        dispatch(setUserAvatarReducer(res));
        const arrSubmit = [];
        let chatSecret = null;
        let form_data = new FormData();
        form_data.append("username", user?.id.toString());
        form_data.append("first_name", data?.firstName);
        form_data.append("last_name", data?.lastName);
        if (data?.avatar) {
          form_data.append("avatar", data?.avatar, data?.avatar.name);
        } else {
          const defaultAvatar = await UtilDefaultAvatar.getFile();
          form_data.append("avatar", defaultAvatar, defaultAvatar.name);
        }
        if (data?.idBasicProfile) {
          form_data.append("secret", user?.chatSecret);
          arrSubmit.push(
            BasicProfileService.putBasicProfile(
              {
                photoUrl: res,
                firstName: data?.firstName,
                lastName: data?.lastName,
                title: data?.title,
                workPhone: data?.workPhone,
                countryCode: data?.workPhoneCountryCode,
                isRecruiter: data?.recruitOnBehalf,
              },
              data?.idBasicProfile
            )
            // UserChatEngineService.patchUserChatEngine(
            //   user?.chatUserId,
            //   form_data
            // )
          );
        } else {
          chatSecret = password.randomPassword({
            characters: [password.lower, password.upper, password.digits],
          });
          form_data.append("secret", chatSecret);
          dispatch(
            setUserReducer({
              ...user,
              chatSecret: chatSecret,
            })
          );
          arrSubmit.push(
            BasicProfileService.postBasicProfile({
              photoUrl: res,
              firstName: data?.firstName,
              lastName: data?.lastName,
              title: data?.title,
              workPhone: data?.workPhone,
              countryCode: "+64",
              isRecruiter: data?.recruitOnBehalf,
            })
            // UserChatEngineService.postUserChatEngine(form_data)
          );
        }
        if (data?.idOrganisation) {
          arrSubmit.push(
            OrganizationService.putOrganization(
              {
                name: data?.name,
                mission: data?.mission,
                culture: data?.culture,
                industry: data?.industry,
                numberOfWorkers: data?.numberOfWorkers,
                behaviouralSklls: data?.behaviouralSklls,
                nzbn: data?.nzbn,
                email: data?.email,
                city: data?.city,
                country: data?.country,
              },
              data?.idOrganisation
            )
          );
        } else {
          arrSubmit.push(
            OrganizationService.postOrganization({
              name: data?.name,
              mission: data?.mission,
              culture: data?.culture,
              industry: data?.industry,
              numberOfWorkers: data?.numberOfWorkers,
              behaviouralSklls: data?.behaviouralSklls,
              nzbn: data?.nzbn,
              email: data?.email,
              city: data?.city,
              country: data?.country,
            })
          );
        }
        await Promise.all(arrSubmit)
          .then(async (res) => {
            dispatch(getSetting());
            if (!data?.idBasicProfile) {
              await UserService.putUser(user?.id, {
                ...user,
                chatUserId: res[1]?.id,
                chatSecret: chatSecret,
              })
                .then(() => {
                  dispatch(
                    setUserReducer({
                      ...user,
                      chatUserId: res[1]?.id,
                      chatSecret: chatSecret,
                    })
                  );
                })
                .catch((e) => dispatch(setErrorMess(e)));
            }
            dispatch(setSuccessMess("Successfully update"));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };
  
  const isRecruit = setting?.recruitOnBehalf;
  const [isAssist, setIsAssist] = useState(false);

  useEffect(() => {
    if (typeof setting?.recruitOnBehalf !== 'undefined') {
      setIsAssist(setting?.recruitOnBehalf);
    }
  }, [setting?.recruitOnBehalf]);
  
  const handleCheckboxChange = (isChecked: boolean) => {
    setIsAssist(isChecked);
    setValue("recruitOnBehalf", isChecked);  
  };
  

  const handleDeactivate = () => {
    dispatch(setLoading(true));
    ContractorSettingService.putContractorSettingService(user?.id, settingId, {
      deactivateAccount: !deactivate,
    })
      .then((res) => {
        if (res?.deactivateAccount) {
          dispatch(setSuccessMess("Successfully deactivate account!"));
        } else {
          dispatch(setSuccessMess("Successfully activate account!"));
        }
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
    setDeactivate(!deactivate);
  };

  return (
    <>
      <div className="wrapper">
        <div className={classes.header} />
        <div className={classes.section}>
          <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.content}>
                {/* ------------------------ BASIC PROFILE ------------------------ */}
                <div
                  className={clsx(
                    classes.commonContainer,
                    classes.hideBorder,
                    "pt-0"
                  )}
                >
                  <p className={classes.commonTitle}>Basic profile:</p>
                  <Row className={classes.commonContent}>
                    <Col xs="12" className={classes.uploadAvatar}>
                      <Controller
                        name="avatar"
                        control={control}
                        render={({ field }) => (
                          <ImageUpload
                            avatar
                            value={field.value}
                            onChange={(file) => field.onChange(file)}
                            isDisabled={false}
                          />
                        )}
                      />
                    </Col>
                    <Col xs="12" className="d-none">
                      <CustomInput
                        placeholder="ID BasicProfile"
                        type="text"
                        autoComplete="off"
                        inputRef={register("idBasicProfile")}
                        errorMessage={errors.idBasicProfile?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>First name</p>
                      <CustomInput
                        className={classes.commonInput}
                        placeholder="Your first name"
                        type="text"
                        autoComplete="off"
                        inputRef={register("firstName")}
                        errorMessage={errors.firstName?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>Last name</p>
                      <CustomInput
                        className={classes.commonInput}
                        placeholder="Your last name"
                        type="text"
                        autoComplete="off"
                        inputRef={register("lastName")}
                        errorMessage={errors.lastName?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>Title</p>
                      <CustomInput
                        className={classes.commonInput}
                        placeholder="Title"
                        type="text"
                        autoComplete="off"
                        inputRef={register("title")}
                        errorMessage={errors.title?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>
                        Work phone number
                      </p>
                      <CustomInput
                        className={classes.commonInput}
                        placeholder="Your work phone number"
                        type="text"
                        autoComplete="off"
                        inputRef={register("workPhone")}
                        errorMessage={errors.workPhone?.message}
                      />
                    </Col>
                    <Col md="12" xs="12" className={classes.commonItem}>
                    {
                        user?.role !== 7 && (
                      <CustomCheckbox
                        content="Receive Emails alerting of New Messages and Offer Updates"
                        inputRef={register(`EmailNotification`)}
                        errorMessage={errors.EmailNotification?.message}
                        className={clsx("my-2", classes.customCheckbox)}
                      />
                        )}
                    </Col>
                    <Col
                      xs="12"
                      className={clsx(classes.commonItem, classes.centerItem)}
                    >
                      
             
                   
                    </Col>
                  </Row>
                </div>
                {/* ------------------------ ORGANISATION ------------------------ */}
                <div className={classes.commonContainer}>
      
                          <>
                        
  
                  
                  <p className={classes.commonTitle}>Your organisation:</p>
                  <Controller
                      name="companyAvatar"
                      control={control}
                      render={({ field }) => (
                        <ImageUpload
                          avatar
                          value={initialImage || field.value}
                          onChange={(file) => {
                            field.onChange(file);
                          }}
                          isDisabled={!setting?.admin} 
                          
                        />
                      )}
                    />
                  <Row className={classes.commonContent}>
                    
                    <Col xs="12" className="d-none">
                      <CustomInput
                        placeholder="ID Organisation"
                        type="text"
                        autoComplete="off"
                        inputRef={register("idOrganisation")}
                        errorMessage={errors.idOrganisation?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>Name</p>
                      <CustomInput
                        placeholder="Organisation name"
                        type="text"
                        autoComplete="off"
                        inputRef={register("name")}
                        errorMessage={errors.name?.message}
                        isDisabled={!setting?.admin}
                      />
                    </Col>
                   
                      <>
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>Industry</p>
                          <CustomInput
                            placeholder="Industry"
                            type="text"
                            autoComplete="off"
                            inputRef={register("industry")}
                            errorMessage={errors.industry?.message}
                            isDisabled={!setting?.admin}
                          />
                        </Col>
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            About Your Company
                          </p>
                          <CustomTextarea
                            className={classes.textarea}
                            placeholder="What is your mission statement?"
                            autoComplete="off"
                            name="mission"
                            inputRef={register("mission")}
                            errorMessage={errors.mission?.message}
                            tooltipText='This section will appear on job listings if populated.'
                            isDisabled={!setting?.admin}
                          />
                        </Col>
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>Benefits & Culture</p>
                          <CustomTextarea
                            className={classes.textarea}
                            placeholder="What culture do you look for?"
                            name="culture"
                            autoComplete="off"
                            inputRef={register("culture")}
                            errorMessage={errors.culture?.message}
                            tooltipText='This section will appear below about your company on job listings, if populated.'
                            isDisabled={!setting?.admin}
                          />
                        </Col>
                        {setting?.admin && (
                          <>
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Number of workers
                          </p>
                          <Controller
                            name="numberOfWorkers"
                            control={control}
                            render={({ field }) => (
                              <CustomTagsInput
                                {...field}
                                name="numberOfWorkers"
                                placeholder="Number of workers"
                                onChange={(value: any) => {
                                  return field.onChange(value);
                                }}
                                value={field.value ? field.value : []}
                                control={control}
                                errorMessage={errors.numberOfWorkers?.message}
                              />
                            )}
                          />
                        </Col>

                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Behavioral skills
                          </p>
                          <Controller
                            name="behaviouralSklls"
                            control={control}
                            render={({ field }) => (
                              <CustomTagsInput
                                {...field}
                                placeholder="Behavioral skills"
                                onChange={(value: any) => {
                                  return field.onChange(value);
                                }}
                                value={field.value ? field.value : []}
                                control={control}
                                errorMessage={errors.behaviouralSklls?.message}
                              />
                            )}
                          />
                        </Col>
               
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>nzbn</p>
                          <CustomInput
                            placeholder="nzbn"
                            type="text"
                            autoComplete="off"
                            inputRef={register("nzbn")}
                            errorMessage={errors.nzbn?.message}
                            isDisabled={!setting?.admin}
                          />
                        </Col>
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>Email</p>
                          <CustomInput
                            placeholder="Email"
                            type="text"
                            autoComplete="off"
                            inputRef={register("email")}
                            errorMessage={errors.email?.message}
                            isDisabled={!setting?.admin}
                          />
                        </Col>
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>
                            Nearest Town or City
                          </p>
                          <Controller
                            name="address"
                            control={control}
                            render={({ field }) => (
                              <SearchLocationInput
                                {...field}
                                ref={null}
                                placeholder="Your nearest town or city"
                                errorMessage={errors.address?.message}
                                value={field.value}
                                onChange={(value: any) => {
                                  setValue("city", value?.address?.city);
                                  setValue("country", value?.address?.country);
                                  return field.onChange(
                                    value?.address?.formattedAddress
                                  );
                                }}
                              />
                            )}
                          />
                        </Col>
                        </>
                                 )}
                      </>
                
                  </Row>
                  </>

                  
                        
                </div>
                {user?.role !== 7 && (
                <>
                <CustomCheckboxFlip
                    firstLabel='DIY'
                    secondLabel='Assist'
                    onChange={handleCheckboxChange}
                    checked={isAssist}
                    inputRef={register("recruitOnBehalf")}
                />
       

              <h6 style={{ color: 'black' }}>
                  {isAssist ? "End To End Talent Acquisition" : "Find and Maintain Talent"}
              </h6>
              
              <p style={{ color: 'black' }}>
                  {isAssist 
                      ? "You have chosen the Assist mode. Our team will assist you in every step of the process."
                      : "You have chosen the DIY mode. You will manage everything on your own."
                  }
              </p>
          </>
                )}
                <div className={classes.btnSaveContainer}>
                  <Button
                    className={`btn-round ${classes.btnSave}`}
                    color="primary"
                  >
                    <i className="fa fa-save" /> Save
                  </Button>
                </div>
                <div>
                  <p className={classes.deactivateTitle}>Deactivate account</p>
                  <div className={classes.deactivateContent}>
                    <CustomSwitch
                      value={deactivate}
                      onChange={handleDeactivate}
                    />
                    {deactivate && (
                      <p>
                        Your account is now deactivated, you will no longer be
                        able to send offers.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </Container>
        </div>
        <FooterDefault />
      </div>
    </>
  );
});

export default Setting;
