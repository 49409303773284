import { IAllOffers, IExpense, IProfile, ITimesheet } from ".";
import { IUser } from "../User";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";

export const GET_ALL_OFFERS_REQUEST = "GET_ALL_OFFERS_REQUEST";

export const GET_ALL_TIMESHEETS_CONTRACTOR_REQUEST =
  "GET_ALL_TIMESHEETS_CONTRACTOR_REQUEST";

export const GET_ALL_CHATS_REQUEST = "GET_ALL_CHATS_REQUEST";

export const GET_ALL_EXPENSES_REQUEST = "GET_ALL_EXPENSES_REQUEST";

export const SET_PROFILE_REDUCER = "SET_PROFILE_REDUCER";

export const SET_ALL_OFFERS_REDUCER = "SET_ALL_OFFERS_REDUCER";

export const SET_ALL_TIMESHEETS_CONTRACTOR_REDUCER =
  "SET_ALL_TIMESHEETS_CONTRACTOR_REDUCER";

export const SET_ALL_EXPENSES_REDUCER = "SET_ALL_EXPENSES_REDUCER";

export const getProfile = () => {
  return {
    type: GET_PROFILE_REQUEST,
  };
};

export const getAllOffers = (contractorId: number) => {
  return {
    type: GET_ALL_OFFERS_REQUEST,
    contractorId,
  };
};

export const getAllTimesheets = (contractorId: number) => {
  return {
    type: GET_ALL_TIMESHEETS_CONTRACTOR_REQUEST,
    contractorId,
  };
};

// export const getAllChats = (user: IUser, exceptionChatId = null) => {
//   return {
//     type: GET_ALL_CHATS_REQUEST,
//     user,
//     exceptionChatId,
//   };
// };

export const getAllExpenses = () => {
  return {
    type: GET_ALL_EXPENSES_REQUEST,
  };
};

export const setProfileReducer = (data: IProfile) => {
  return {
    type: SET_PROFILE_REDUCER,
    data: data,
  };
};

export const setAllOffersReducer = (data: IAllOffers) => {
  return {
    type: SET_ALL_OFFERS_REDUCER,
    data: data,
  };
};

export const setAllTimesheetsReducer = (data: ITimesheet[]) => {
  return {
    type: SET_ALL_TIMESHEETS_CONTRACTOR_REDUCER,
    data: data,
  };
};

export const setAllExpensesReducer = (data: IExpense[]) => {
  return {
    type: SET_ALL_EXPENSES_REDUCER,
    data: data,
  };
};
