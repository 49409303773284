import { Fragment, memo, useEffect, useMemo, useState } from "react";
import Logo from "../../../assets/img/logo.png";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import ScrollNavbar from "components/Navbars/Employer/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "./styles.module.scss";
import { useForm } from "react-hook-form";
import CustomInput from "components/Common/CustomInput";
import { Link, useParams  } from "react-router-dom";
import routes from "routers/routes";
import authFactory from "services/authService/AuthFactory";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { UserService } from "services/User";
import {
  setUserReducer,
  setUserVerificationReducer,
} from "redux/reducers/User/actionTypes";
import { ReducerType } from "redux/reducers";
import {
  // getAllChats,
  getAllTimesheets,
  getInterviewOffers,
  getJobList,
  getJobOffers,
  getSetting,
} from "redux/reducers/Employer/actionTypes";
import { ImageService } from "services/Image";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
// the following import was causing a warning:
// import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";

interface Props {}

const Login = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { organisationID } = useParams<{ organisationID: string }>(); 
  // console.log("org org", organisationID)
  const [errorMessage, setErrorMessage] = useState("");
  const [customLogo, setCustomLogo] = useState<string>(Logo);
  const [customTitle, setCustomTitle] = useState("Employer Login");
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (user) {
      if (user?.profileCompleted) {
        dispatch(push(routes.employer.admin.dashboard));
      } else {
        dispatch(push(routes.employer.newMember.root));
      }
    }
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [dispatch, user]);

  useEffect(() => {
    

    const fetchCustomization = async (id: string) => {
      console.log("org id", id);
      const imageKey = `${id}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`;
      console.log("imageKey", imageKey);
      try {
        const imageBlob = await ImageService.getCompanyAvatar(imageKey);
        console.log("image blob", imageBlob);
        const imageFile = blobToFile(imageBlob, "avatar.png");
        setImagePreviewUrl(URL.createObjectURL(imageFile));
      } catch (error) {
        console.error("Failed to load company avatar, falling back to default avatar");
        setImagePreviewUrl(Logo);
      }
    };

    if (organisationID) {
      fetchCustomization(organisationID);
    } else {
      setImagePreviewUrl(Logo);
    }
  }, [organisationID]);
  

  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const onSubmit = async (data: any) => {
    dispatch(setLoading(true));
    await authFactory
      .generate()
      .login(data?.email, data?.password)
      .then(async () => {
        await UserService.getUser()
          .then((res) => {
            if (res?.type === "contractor") {
              setErrorMessage("This is not a valid login");
            } else {
              dispatch(setUserReducer(res));
              if (res?.role === 9) {
                dispatch(push(routes.employer.accountant));
              } else {
                dispatch(getJobList());
                dispatch(getSetting());
                dispatch(getJobOffers());
                dispatch(getInterviewOffers());
                dispatch(getAllTimesheets(res?.id));
                if (res?.chatSecret) {
                  //dispatch(getAllChats(res));
                }
                if (res?.profileCompleted) {
                  dispatch(push(routes.employer.admin.dashboard));
                } else {
                  dispatch(push(routes.employer.newMember.root));
                }
              }
            }
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e: any) => {
        if (e?.message === "User is not confirmed.") {
          dispatch(
            setUserVerificationReducer({
              username: data?.email,
            })
          );
          dispatch(push(routes.employer.confirmVerificationCode));
        }
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <Fragment>
      {!organisationID && <ScrollNavbar name="Talent" to={routes.contractor.login} />}
      <div className="page-header header-filter" filter-color="yellow">
        <div
          className="page-header-image"
          style={{
            backgroundImage: organisationID
              ? "none"
              : "url(" + require("assets/img/employer_login.jpg") + ")",
            backgroundColor: organisationID ? "#333" : "transparent",
          }}
        />
        <div className="content">
          <Container>
            <Row>
            {!organisationID && 
            <>
              <Col
                className="ml-auto mr-auto d-none d-md-block"
                md="6"
                lg="4"
                style={{ background: "rgba(0, 0, 0, 0.8)" }}
              >
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons media-2_sound-wave"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Connecting the right people</h5>
                    <p className="description" style={{ fontWeight: "400" }}>
                      We simplify the finding talent experience. Our software
                      allows you to enter your requirements and automatically
                      recieve offers that match the closest
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons media-1_button-pause"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">
                      End to End Technology Partner
                    </h5>
                    <p className="description" style={{ fontWeight: "400" }}>
                      Continuously updating new features to simplify finding
                      talent, managing contracts, approving timesheets and
                      creating a single payroll invoice for all.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons users_single-02"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Simple Pricing</h5>
                    <p className="description" style={{ fontWeight: "400" }}>
                      Necta provides free membership for all users, with
                      affordable options for additional services to meet varying
                      needs and a $2 payroll to maintain talent through our
                      automated system.
                    </p>
                  </div>
                </div>
              </Col>
              </>
}             

                <Col
                  className={`mr-auto my-auto ${organisationID ? "ml-auto" : ""}`}
                  md={organisationID ? "6" : "6"}
                  lg={organisationID ? "4" : "4"}
                >
              
                <Card className="card-signup">
                  <CardBody>
                  {imagePreviewUrl && (
                    <img
                      alt="Logo"
                      src={organisationID ? imagePreviewUrl : Logo}
                      width={150}
                    />
                  )}
                    <CardTitle className="text-center" tag="h4">
                      {!organisationID && "Employer" } Login
                    </CardTitle>

                    <Form
                      action=""
                      className="form"
                      method=""
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className={classes.inputContainer}>
                        <CustomInput
                          startIcon={
                            <i className="now-ui-icons users_circle-08" />
                          }
                          placeholder="Email"
                          name="email"
                          type="email"
                          autoComplete="off"
                          inputRef={register("email")}
                          errorMessage={errors.email?.message}
                        />
                        <CustomInput
                          startIcon={<i className="fa fa-text-height" />}
                          placeholder="Password"
                          name="password"
                          type="password"
                          inputRef={register("password")}
                          errorMessage={errors.password?.message}
                        />
                        <span className={`text-danger ${classes.text}`}>
                          {errorMessage}
                        </span>
                      </div>
                      <CardFooter className={classes.cardFooter}>
                        <Button
                          block
                          color={organisationID ? "secondary" : "primary"}
                          type="submit"
                          size="mg"
                          className="mt-0"
                        >
                          Login
                        </Button>
                        <div className={classes.btnContainer}>
                         <Button
                            className={classes.createAccount}
                            color="neutral"
                            tag={Link}
                            to={    organisationID
                              ? `${routes.employer.signup}?organisationID=${organisationID}`
                              : routes.employer.signup
                          }
                          >
                            {!organisationID &&  "Create Account"  }
                            {organisationID &&  "Create User"  }
                          </Button>
                          <Button
                            className={classes.forgotPassword}
                            color="neutral"
                            tag={Link}
                            to={    organisationID
                              ? `${routes.employer.forgotPassword}?organisationID=${organisationID}`
                              : routes.employer.forgotPassword
                          }
                          >
                            Forgot Password?
                          </Button>
                        </div>
                      </CardFooter>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {!organisationID &&  <Footer />}
      </div>
    </Fragment>
  );
});

export default Login;
