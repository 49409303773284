import { useSelector } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { ReducerType } from "redux/reducers";
import routes from "./routes";

interface IContractorAuthRoute extends RouteProps {}

const ContractorAuthRoute = ({
  path,
  component: Component,
  render,
  ...rest
}: IContractorAuthRoute) => {
  const { user } = useSelector((state: ReducerType) => state.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: routes.contractor.login,
              }}
            />
          );
        }
        return render ? render(props) : <Component {...props} />;
      }}
    />
  );
};

export default ContractorAuthRoute;
