import { Fragment, memo, useEffect, useMemo, useState } from "react";
import Logo from "../../../assets/img/logo.png";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Col,
  Row,
} from "reactstrap";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Footer from "components/Footers/FooterTransparent";
import classes from "./styles.module.scss";
import CustomInput from "components/Common/CustomInput";
import userFactory from "services/userService/UserFactory";
import { FORGOT_PASSWORD_MODE, PASSWORD_REGREX } from "config/constants";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import routes from "routers/routes";

interface Props {}

const ForgotPassword = memo((props: Props) => {
  const [mode, setMode] = useState(FORGOT_PASSWORD_MODE.EMAIL);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const schema = useMemo(() => {
    return mode === FORGOT_PASSWORD_MODE.EMAIL
      ? yup.object().shape({
          email: yup
            .string()
            .email("Please enter a valid email")
            .required("Email is required"),
        })
      : yup.object().shape({
          code: yup.string().required("This field is required"),
          password: yup
            .string()
            .required("This field is required")
            .matches(
              PASSWORD_REGREX,
              "At least 8 characters, must include 1 upper case, 1 lower case, 1 number, and 1 special character"
            ),
          confirmPassword: yup
            .string()
            .required("This field is required")
            .oneOf([yup.ref("password")], "Your password does not not match"),
        });
  }, [mode]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const onSubmitEmail = async (data) => {
    try {
      await userFactory.generate().forgotPassword(data?.email);
      setMode(FORGOT_PASSWORD_MODE.CONFIRM_NEW_PASSWORD);
    } catch (error) {
      setErrorMessage((error as any)?.message);
    }
  };

  const onSubmitNewPassword = async (data) => {
    console.log(data)
    try {
      await userFactory
        .generate()
        .forgotPasswordSubmit(data?.email, data?.code, data?.password);
      dispatch(push(routes.contractor.login));
    } catch (error) {
      setErrorMessage((error as any)?.message);
    }
  };

  return (
    <Fragment>
      <ScrollNavbar />
      <div className="page-header header-filter" filter-color="yellow">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/login.jpg") + ")",
          }}
        />
        <div className={classes.content}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className={`card-login ${classes.card}`}>
                  {mode === FORGOT_PASSWORD_MODE.EMAIL ? (
                    <Form
                      action=""
                      className="form"
                      method=""
                      onSubmit={handleSubmit(onSubmitEmail)}
                    >
                      <CardHeader className="text-center">
                        <div className={classes.logoContainer}>
                          <img alt="Logo" src={Logo} width={150} />
                        </div>
                        <p className={classes.title}>Forgot Password</p>
                      </CardHeader>
                      <CardBody>
                        <div className={classes.inputContainer}>
                          <CustomInput
                            startIcon={
                              <i className="now-ui-icons users_circle-08" />
                            }
                            placeholder="Email"
                            type="email"
                            inputRef={register("email")}
                            errorMessage={errors.email?.message}
                          />
                          <span className={`text-danger ${classes.text}`}>
                            {errorMessage}
                          </span>
                        </div>
                        <Button block color="primary" type="submit" size="md">
                          Submit
                        </Button>
                      </CardBody>
                    </Form>
                  ) : (
                    <Form
                      action=""
                      className="form"
                      method=""
                      onSubmit={handleSubmit(onSubmitNewPassword)}
                    >
                      <CardHeader className="text-center">
                        <div className="logo-container">
                          <img alt="Logo" src={Logo} />
                        </div>
                        <p className={classes.title}>New Password</p>
                      </CardHeader>
                      <CardBody>
                        <p className={classes.text}>
                          A code has been sent to your email
                        </p>
                        <div className={classes.inputCodeContainer}>
                          <CustomInput
                            placeholder="Verification code"
                            type="text"
                            inputRef={register("code")}
                            errorMessage={errors.code?.message}
                          />
                          <CustomInput
                            placeholder="New password"
                            type="password"
                            inputRef={register("password")}
                            errorMessage={errors.password?.message}
                          />
                          <CustomInput
                            placeholder="Confirm new password"
                            type="password"
                            inputRef={register("confirmPassword")}
                            errorMessage={errors.confirmPassword?.message}
                          />
                          <span className={`text-danger ${classes.text}`}>
                            {errorMessage}
                          </span>
                        </div>
                        <Button block color="primary" type="submit" size="md">
                          Submit
                        </Button>
                      </CardBody>
                    </Form>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
});

export default ForgotPassword;
