import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Collapse, Input, FormGroup, Label } from 'reactstrap';
import { useState, useEffect } from 'react';
import classes from './styles.module.scss';
import { jobBoards as initialJobBoards } from './jobBoardData'; // Import jobBoards with a different name to modify
import { useSelector } from 'react-redux';
import { ReducerType } from 'redux/reducers';
import { OrganizationService } from 'services/Employer/NewMember/Organization';

// Define the type for the billing agreement state
type BillingAgreementType = {
  trademe: boolean;
  linkedin: boolean;
  seek: boolean;
  dogoodjobs: boolean;
};

const JobBoardForm = ({ control, errors, watch, setValue, itemEdit, register, data }) => {
  const [selectedJobBoards, setSelectedJobBoards] = useState([]);
  const [contentVisibility, setContentVisibility] = useState({});
  const [billingAgreement, setBillingAgreement] = useState<BillingAgreementType>({
    trademe: false,
    linkedin: false,
    seek: false,
    dogoodjobs: false,
  });
  const [jobBoards, setJobBoards] = useState(initialJobBoards); // Add jobBoards state to modify
  const [enabledIntegrations, setEnabledIntegrations] = useState<{ [key: string]: boolean }>({});
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});
  const [initialValues, setInitialValues] = useState<{ [key: string]: string }>({});
  const { setting } = useSelector((state: ReducerType) => state.employer);
  const [hasEnabledJobBoards, setHasEnabledJobBoards] = useState(false); // New state to track if any job boards are enabled

  useEffect(() => {
    const getOrgIntegration = async () => {
      try {
        const response = await OrganizationService.getIntegration(setting?.idOrganisation);
        console.log('Response:', response);

        // Initialize enabled integrations based on the response
        const initialIntegrations: { [key: string]: boolean } = {
          ats: response.ats || false,
          linkedin: response.linkedin || false,
          seek: response.seek || false,
          trademe: response.trademe || false,
          dogoodjobs: response.dogoodjobs || false,
          talent: response.talent || false,
          jobsora: response.jobsora || false,
          teams: response.teams || false,
        };

        // Determine if any job boards are enabled
        const anyJobBoardsEnabled = Object.keys(initialIntegrations).some(
          (key) => ['linkedin', 'seek', 'trademe', 'dogoodjobs'].includes(key) && initialIntegrations[key]
        );
        setHasEnabledJobBoards(anyJobBoardsEnabled);

        // Initialize selected values for the fields
        const initialValues: { [key: string]: string } = {
          ats_email: response.ats_email || '',
          ats_chosen: response.ats_chosen || '',
          linkedin_max_budget: response.linkedin_max_budget || '0',
          linkedin_option: response.linkedin_option || '',
          seek_option: response.seek_option || '',
          trademe_option: response.trademe_option || '',
          dogoodjobs_option: response.dogoodjobs_option || '',
          teamswebhook: response.teamswebhook || '',
        };

        // Update LinkedIn price based on linkedin_max_budget
        const updatedJobBoards = initialJobBoards.map((jobBoard) => {
          if (jobBoard.id === 'linkedin') {
            return { ...jobBoard, price: `$${initialValues.linkedin_max_budget}` };
          }
          return jobBoard;
        });

        // Set billing agreements automatically if option is "All Jobs"
        const initialBillingAgreements = {
          trademe: response.trademe_option === 'All Jobs' ? true : itemEdit?.trademe || false,
          linkedin: response.linkedin_option === 'All Jobs' ? true : itemEdit?.linkedin || false,
          seek: response.seek_option === 'All Jobs' ? true : itemEdit?.seek || false,
          dogoodjobs: response.dogoodjobs_option === 'All Jobs' ? true : itemEdit?.dogoodjobs || false,
        };

        setJobBoards(updatedJobBoards); // Update jobBoards state
        setEnabledIntegrations(initialIntegrations); // Set enabled integrations
        setSelectedValues(initialValues); // Set the values to the selectedValues state
        setInitialValues(initialValues); // For comparison
        setBillingAgreement(initialBillingAgreements); // Set initial billing agreements
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };

    if (setting?.idOrganisation) {
      getOrgIntegration();
    }
  }, [setting?.idOrganisation]);

  // Pre-populate form with data from parent (data.jobBoards)
  useEffect(() => {
    if (itemEdit) {
      // Populate the billing agreement state based on itemEdit values
      setBillingAgreement((prev) => ({
        ...prev,
        trademe: itemEdit?.trademe || prev.trademe,
        linkedin: itemEdit?.linkedin || prev.linkedin,
        seek: itemEdit?.seek || prev.seek,
        dogoodjobs: itemEdit?.dogoodjobs || prev.dogoodjobs,
      }));

      // Populate selectedJobBoards array with the job boards that are marked true
      const selectedBoards = ['trademe', 'linkedin', 'seek', 'dogoodjobs'].filter((board) => itemEdit[board]);
      setSelectedJobBoards(selectedBoards); // Only add boards that are set to true in itemEdit
    }
  }, [itemEdit]);

  // Update form state whenever selectedJobBoards or billingAgreement changes
  useEffect(() => {
    const jobBoardsData = jobBoards.map((jobBoard) => ({
      name: jobBoard.name,
      agreement: billingAgreement[jobBoard.id] || false, // Default to false if not set
    }));
    setValue('jobBoards', jobBoardsData);
  }, [selectedJobBoards, billingAgreement, setValue]);

  const toggleContentVisibility = (jobBoardId) => {
    setContentVisibility((prev) => ({
      ...prev,
      [jobBoardId]: !prev[jobBoardId],
    }));
  };

  const handleJobBoardChange = (jobBoardId) => {
    setSelectedJobBoards((prev) =>
      prev.includes(jobBoardId) ? prev.filter((id) => id !== jobBoardId) : [...prev, jobBoardId]
    );
  };

  const handleBillingAgreement = (jobBoardId) => {
    if (itemEdit?.[jobBoardId] || selectedValues[`${jobBoardId}_option`] === 'All Jobs') {
      return; // Do nothing if already paid or if the option is "All Jobs"
    }
    setBillingAgreement((prev) => ({
      ...prev,
      [jobBoardId]: !prev[jobBoardId], // Toggle agreement state for the job board
    }));
  };

  const handleCloseListing = (jobBoardId) => {
    // Override and ensure the job board is marked as false (unpaid)
    setBillingAgreement((prev) => ({
      ...prev,
      [jobBoardId]: false, // Ensure the job board is set to false
    }));

    // Update the form state to reflect the job board is no longer selected
    setSelectedJobBoards((prev) => prev.filter((id) => id !== jobBoardId));

    // Ensure the form data is updated to reflect the change
    setValue(
      'jobBoards',
      jobBoards.map((jobBoard) => ({
        name: jobBoard.name,
        agreement: jobBoard.id === jobBoardId ? false : billingAgreement[jobBoard.id] || false,
      }))
    );
  };

  return (
    <>
      <Row className={classes.outreachContainer}>
        {!hasEnabledJobBoards ? (
          <p>You have no Upgrades available, please contact your admin to enable Upgrades within Integrations.</p>
        ) : (
          <>
            <p>
              Choose premium job boards to upgrade your opportunity. Each job board offers different features and pricing.
              Posts are created under the NECTA account and all candidates will come to your opportunity automatically.
            </p>
            {jobBoards.map(
              (jobBoard) =>
                enabledIntegrations[jobBoard.id] && (
                  <Col md={12} key={jobBoard.id} className={classes.platformRow}>
                    <div className={classes.platformItem}>
                      <FormGroup check inline className={classes.platformCheckbox}>
                        <Label check className={classes.platformLabel}>
                          <Input
                            type="checkbox"
                            checked={selectedJobBoards.includes(jobBoard.id)}
                            onChange={() => handleJobBoardChange(jobBoard.id)}
                            className={classes.platformCheckboxInput}
                          />
                          <FontAwesomeIcon icon={jobBoard.icon} className={classes.platformIcon} />
                          {jobBoard.name}
                        </Label>
                        <Button
                          color="link"
                          onClick={() => toggleContentVisibility(jobBoard.id)}
                          className={classes.toggleButton}
                        >
                          {contentVisibility[jobBoard.id] ? 'Do Not Upgrade' : 'Upgrade'}
                        </Button>
                      </FormGroup>
                    </div>
                    <Collapse isOpen={contentVisibility[jobBoard.id]}>
                      <div className={classes.contentArea}>
                        <p>
                          <strong>Price: </strong>
                          {jobBoard.price}
                        </p>
                        <p>
                          <strong>Details: </strong>
                          {jobBoard.details}
                        </p>

                        {/* Billing Agreement Button */}
                        <Button
                          color={
                            itemEdit?.[jobBoard.id] || selectedValues[`${jobBoard.id}_option`] === 'All Jobs'
                              ? 'success'
                              : billingAgreement[jobBoard.id]
                              ? 'success'
                              : 'primary'
                          }
                          onClick={() => handleBillingAgreement(jobBoard.id)}
                          className={classes.billingButton}
                          disabled={
                            itemEdit?.[jobBoard.id] || selectedValues[`${jobBoard.id}_option`] === 'All Jobs'
                          }
                        >
                          {itemEdit?.[jobBoard.id] || selectedValues[`${jobBoard.id}_option`] === 'All Jobs'
                            ? 'Agreed to be Billed'
                            : billingAgreement[jobBoard.id]
                            ? 'Agreed to be Billed'
                            : 'Agree to be Billed'}
                        </Button>

                        {/* Show agreement status */}
                        {(itemEdit?.[jobBoard.id] || billingAgreement[jobBoard.id]) && (
                          <p className={classes.billingText}>
                            {itemEdit?.[jobBoard.id] || selectedValues[`${jobBoard.id}_option`] === 'All Jobs'
                              ? 'This job board has been set to "Agreed to be Billed."'
                              : 'You have agreed to be billed for this job board.'}
                          </p>
                        )}
                      </div>
                    </Collapse>
                  </Col>
                )
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default JobBoardForm;
