import { createRef, memo, RefObject, useEffect, useState } from "react";
import clsx from "clsx";
import { Col, Container, Row, Button, UncontrolledTooltip } from "reactstrap";
import classes from "./styles.module.scss";
import defaultAvatar from "assets/img/placeholder.jpg";
import routes from "routers/routes";
import moment from "moment";
import { WORK_POLICY } from "config/constants";
import { useSelector, useDispatch } from "react-redux";
import { ReducerType } from "redux/reducers";
import { setUserOfChatReducer } from "redux/reducers/Message/actionTypes";
import { push } from "connected-react-router";
import TestimonialDetails from "./components/TestimonialDetails";
import { convertVisaType } from "utils/moj";

interface Props {
  profile: any;
  experience: any;
  preference: any;
  avatar: any;
  contact: any;
  verification: any;
  testimonials: any;
  contractorId: any;
  goBack: () => void; // Add the goBack prop
}

const ProfilePageHeader = memo(
  ({
    profile,
    experience,
    preference,
    avatar,
    contact,
    verification,
    testimonials,
    contractorId,
    goBack, // Destructure the goBack prop
  }: Props) => {
    let pageHeader = createRef();
    const [yearsExperience, setYearsExperience] = useState(null);
    const dispatch = useDispatch();
    const { user } = useSelector((state: ReducerType) => state.user);
    const [modalTestimonial, setTestimonial] = useState(false);

    useEffect(() => {
      if (experience?.length) {
        let minDate = new Date(experience[0]?.startDate);
        let maxDate = new Date(experience[0]?.endDate);
        experience.forEach((item) => {
          if (new Date(item?.startDate) < minDate)
            minDate = new Date(item?.startDate);
          if ((item as any)?.endDateIsPresent) {
            maxDate = new Date();
          } else if (new Date(item?.endDate) > maxDate)
            maxDate = new Date(item?.endDate);
        });
        const totalNumberOfMonths =
          maxDate.getMonth() -
          minDate.getMonth() +
          12 * (maxDate.getFullYear() - minDate.getFullYear());
        setYearsExperience((totalNumberOfMonths / 12).toFixed());
      }
    }, [experience]);

    const handleClickTestimonial = () => {
      if (testimonials.filter((e) => e?.response)?.length) {
        setTestimonial(true);
      }
    };

    return (
      <>
        <div
          className={clsx(classes.pageHeader, "page-header clear-filter ")}
          filter-color="yellow"
        >
          <div
            className="page-header-image"
            style={{
              backgroundColor: "black !important",
            }}
            ref={pageHeader as RefObject<HTMLDivElement>}
          />

          <Container className={`position-relative ${classes.container}`}>
          <Button color="primary" onClick={goBack} className={classes.backButton}>
            Back
          </Button>
            <div className="photo-container">
              <img alt="Avatar" src={avatar ? avatar : defaultAvatar}></img>
            </div> 
            <h3 className={`title ${classes.title}`}>
              {profile?.firstName} {profile?.lastName}
            </h3>
            <p className="category">{profile?.title || "..."}</p>

            <div className="content mb-3">
              <div className="social-description">
                <h2>{yearsExperience || "..."}</h2>
                <p>Years Experience </p>
              </div>
              <div className="social-description">
                <h2
                  className={classes.testimonialNumbers}
                  onClick={handleClickTestimonial}
                >
                  {`${testimonials.filter((e) => e?.response)?.length}` ||
                    "..."}
                </h2>
                <p>Testimonials</p>
              </div>
              <div className="social-description">
                <h2>
                  <span>$</span>
                  {preference?.maxRate || "..."}
                </h2>
                <p>Preferred Rate</p>
              </div>
            </div>

            <Row className={classes.infoRow}>
              <Col xs="0" sm="2" />
              <Col xs="6" sm="4" className={classes.infoGroup}>
                <p className="mb-0">Available</p>
                <h2>
                  {preference?.availableDate
                    ? moment(preference?.availableDate).format("DD/MM/YYYY")
                    : preference?.noticePeriod
                    ? `${preference?.noticePeriod} week(s)`
                    : "..."}
                </h2>
              </Col>
              <Col xs="6" sm="4" className={classes.infoGroup}>
                <p className="mb-0">Work Style:</p>
                <h2>
                {(profile?.preference?.workPolicy ===
                  WORK_POLICY.WORK_FROM_HOME &&
                  "Work from Home") ||
                  (profile?.preference?.workPolicy === WORK_POLICY.MIXED &&
                    "Hybrid") ||
                  (profile?.preference?.workPolicy === WORK_POLICY.OFFICE &&
                    "Office") ||
                  (profile?.preference?.workPolicy === WORK_POLICY.ALL &&
                    "All") ||
                  "..."}
                </h2>
              </Col>
              <Col xs="0" sm="2" />
              <Col xs="0" sm="2" />
              <Col xs="6" sm="4" className={classes.infoGroup}>
                <p className="mb-0">Visa Type:</p>
                <h2>
                  {verification?.visaType
                    ? convertVisaType(verification?.visaType)?.name
                    : "..."}
                </h2>
              </Col>
              <Col xs="6" sm="4" className={classes.infoGroup}>
                <p className="mb-0">Location:</p>
                <h2>
                  {contact?.city && contact?.country
                    ? `${contact?.city}, ${contact?.country}`
                    : "..."}
                </h2>
              </Col>
              <Col xs="0" sm="2" />
            </Row>
          </Container>
        </div>
        <div className="section">
          <Container className="position-relative">
            <div className="button-container">
              {/* <Button
                className="btn-round mr-1"
                color="primary"
                size="lg"
                onClick={() => handleRedirectToMessage()}
              >
                Message
              </Button> */}
              
            </div>
            <h2 className="title text-center">About me</h2>
            <p className={`description ${classes.description}`}>
              {profile?.about || "..."}
            </p>
          </Container>
        </div>

        <TestimonialDetails
          onClose={() => setTestimonial(false)}
          isOpen={modalTestimonial}
          testimonials={testimonials}
        />
      </>
    );
  }
);

export default ProfilePageHeader;
