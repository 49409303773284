import React, { memo, useEffect, useRef } from "react";
import noUiSlider from "nouislider";
import classes from "./styles.module.scss";

interface Props {
  id: string;
  start: number;
  min: number;
  max: number;
  step: number;
  value: number;
  setValueInput: (value: any) => void;
  className: string;
}

const SliderBar = memo(
  ({
    id = "slider",
    min,
    max,
    start,
    step,
    value,
    setValueInput,
    ...props
  }: Props) => {
    const sliderRef = useRef(null);

    useEffect(() => {
      if (sliderRef.current === null)
        sliderRef.current = document.getElementById(id);

      noUiSlider.create(sliderRef.current, {
        start: [start],
        connect: [true, false],
        step,
        range: {
          min,
          max,
        },
      });
      sliderRef.current.noUiSlider.on("update", (values, handle) => {
        setValueInput(+values[handle]);
      });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (+sliderRef.current.noUiSlider.get() !== value) {
        sliderRef.current.noUiSlider.set(value);
      }
      // eslint-disable-next-line
    }, [value]);

    return <div className={classes.slider} id={id} {...props} />;
  }
);

export default SliderBar;
