import { memo, useEffect, useMemo, useState } from "react";
import Logo from "../../../assets/img/logo.png";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
} from "reactstrap";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/Common/CustomInput";
import classes from "./styles.module.scss";
import userFactory from "services/userService/UserFactory";
import { Link } from "react-router-dom";
import routes from "routers/routes";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { push } from "connected-react-router";
import { ReducerType } from "redux/reducers";
import { setUserVerificationReducer } from "redux/reducers/User/actionTypes";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const ConfirmVerificationCode = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user, userVerification } = useSelector(
    (state: ReducerType) => state.user
  );

  const [isResendEmail, setIsResendEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({ code: yup.string().required("This field is required") });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!userVerification) {
      dispatch(push(routes.contractor.login));
    }
    handleResendEmail();
    if (user) {
      if (user?.profileCompleted) {
        dispatch(push(routes.contractor.profile));
      } else {
        dispatch(push(routes.contractor.newMember.root));
      }
    }
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendEmail = async () => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .resendConfimationCode(userVerification?.username)
      .then(() => {
        setIsResendEmail(true);
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const handleConfirm = async (data) => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .confirm(userVerification?.username, data?.code)
      .then(() => {
        dispatch(push(routes.contractor.login));
        dispatch(setUserVerificationReducer(null));
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <>
      <ScrollNavbar />
      <div className="page-header header-filter" filter-color="yellow">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/login.jpg") + ")",
          }}
        />
        <div className={classes.content}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className={`card-login ${classes.card}`}>
                  <Form
                    action=""
                    className="form"
                    method=""
                    onSubmit={handleSubmit(handleConfirm)}
                  >
                    <CardHeader className="text-center">
                      <div className="logo-container">
                        <img alt="Logo" src={Logo} width={150}/>
                      </div>
                      <p className={classes.signupTitle}>Confirm your email</p>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                      <div className={classes.inputCodeContainer}>
                        <p className={classes.text}>
                          {isResendEmail
                            ? "New email has been sent"
                            : "The code has been sent to your email"}
                        </p>
                        <CustomInput
                          placeholder="Verification code"
                          type="text"
                          autoComplete="off"
                          inputRef={register("code")}
                          errorMessage={errors.code?.message}
                        />
                        <p className={classes.text}>
                          <span onClick={handleResendEmail}>Resend email?</span>
                        </p>
                        <span className={`text-danger ${classes.text}`}>
                          {errorMessage}
                        </span>
                      </div>
                      <CardFooter className={classes.cardFooter}>
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          size="lg"
                        >
                          Submit
                        </Button>
                        <div className={classes.btnHaveAccountContainer}>
                          <Button
                            className={classes.btnHaveAccount}
                            tag={Link}
                            to={routes.contractor.login}
                            color="neutral"
                          >
                            I have an account
                          </Button>
                        </div>
                      </CardFooter>
                    </CardBody>
                    <CardFooter className="text-center"></CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
});

export default ConfirmVerificationCode;
