import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_ALL_TIMESHEETS_EMPLOYER_REQUEST,
  setAllTimesheetsReducer,
} from "redux/reducers/Employer/actionTypes";
import { EmployerTimesheetService } from "services/Employer/Timesheet/Timesheet";

function* requestGetAllTimesheets(data: { type: string; employerId: number }) {
  try {
    yield put(setLoading(true));
    const [timesheets] = yield all([
      call(EmployerTimesheetService.getAllTimesheet, data?.employerId),
    ]);
    yield put(setAllTimesheetsReducer(timesheets));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getAllTimesheets() {
  yield takeLatest(GET_ALL_TIMESHEETS_EMPLOYER_REQUEST, requestGetAllTimesheets);
}

export default getAllTimesheets;
