import produce from "immer";
import * as types from "./actionTypes";

export interface IUser {
  userId: number;
  chatId: number;
}

export interface MessageState {
  userOfChat: IUser;
}

const initial: MessageState = {
  userOfChat: null,
};

export const messageReducer = (state = initial, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_USER_OF_CHAT_REDUCER:
        draft.userOfChat = action?.data;
        break;
      default:
        return state;
    }
  });
