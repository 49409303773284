import { memo, useState, useEffect, Fragment } from "react";
import { Button, Container, Table } from "reactstrap";
import FooterTransparentEmployer from "components/Footers/FooterTransparentEmployer";
import classes from "./styles.module.scss";
import clsx from "clsx";
import CustomInput from "components/Common/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { XeroInvoiceService } from "services/Xero/XeroInvoice";
import { ReducerType } from "redux/reducers";
import moment from "moment";
import saveAs from "file-saver";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { InvoiceService } from "services/Employer/Manage/Invoice";
import { InvoiceStatus } from "models/Employer/Listings/Xero";

interface Props {}

const Billing = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const [billingList, setBillingList] = useState([]);

  useEffect(() => {
    dispatch(setLoading(true));
    XeroInvoiceService.getEmployerInvoices(user?.id)
      .then((res) => {
        if (res?.Invoices?.length) {
          // console.log("result", res)
          const tempBillingList = res.Invoices.filter(
            (item) => item?.Type === "ACCREC" && item?.Status !== InvoiceStatus.VOID
          );
          InvoiceService.getAllEmployerInvoice(user?.id)
            .then((invoices) => {
              const tempMainInvoices = tempBillingList.map((item) => {
                const invoice = invoices.find(
                  (i) => i?.xeroInvoiceId === item?.InvoiceID
                );
                if (invoice) {
                  return {
                    ...item,
                    contractorName: `${invoice?.contractorFirstName} ${invoice?.contractorLastName}`,
                  };
                } else {
                  return item;
                }
              });
              const sortInvoices = (a, b) => {
                if (moment(a?.DueDateString) < moment(b?.DueDateString)) {
                  return -1;
                } else if (
                  moment(a?.DueDateString) > moment(b?.DueDateString)
                ) {
                  return 1;
                } else {
                  return 0;
                }
              };
              setBillingList(tempMainInvoices.sort(sortInvoices));
              console.log(billingList) 
            })
            .catch((err) => dispatch(setErrorMess(err)));
        }
      })
      .catch((err) => console.log("No Account set up")) //risky that we assume error here is no account set up. Should technically look for 403 but the lambda is not responding with it. 
      .finally(() => dispatch(setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleDownloadInvoice = (id) => {
    dispatch(setLoading(true));
    XeroInvoiceService.downloadPDFInvoice(id)
      .then((res) => {
        const temp = Uint8Array.from(res?.data);
        const blob = new Blob([temp.buffer], { type: "application/pdf" });
        saveAs(blob, `${id}.pdf`);
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleSearch = () => {
    console.log("Searching...");
  };

  return (
    <Fragment>
      <div
        className={clsx("page-header header-filter", classes.pageHeader)}
        filter-color="yellow"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project15.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.container}>
            <p className={classes.title}>Transaction History</p>
            <div className={classes.tableContainer}>
              <div className={classes.tableHeaderContainer}>
                <CustomInput
                  endIcon={
                    <i className="fa fa-search" onClick={handleSearch} />
                  }
                  placeholder="Search..."
                />
                <Button color="primary" className="d-none">
                  Require Approval
                </Button>
              </div>
              <Table className={classes.table} responsive>
                <thead>
                  <tr>
                    <th className={classes.hideOnMobile}>Inv Number</th>
                    <th>Date</th>
                    <th>Net</th>
                    <th>Description</th>
                    <th className={clsx(classes.stage, classes.hideOnMobile)}>
                      <div>
                        Stage{" "}
                        <i className="now-ui-icons arrows-1_minimal-down" />
                      </div>
                    </th>
                    <th className={classes.hideOnMobile}></th>
                  </tr>
                </thead>
                <tbody>
                  {billingList.map((item, index) => (
                    <tr
                      key={index}
                      className={
                        item?.Status === InvoiceStatus.PAID
                          ? classes.billPaid
                          : moment(item?.DueDateString).isSameOrAfter(
                              moment().startOf("day")
                            )
                          ? ""
                          : classes.billOverdue
                      }
                    >
                      <th scope="row" className={classes.hideOnMobile}>
                        {item?.InvoiceNumber}
                      </th>
                      <td>
                        {moment(item?.DueDateString).format("DD/MM/YYYY")}
                      </td>
                      <td>${item?.SubTotal}</td>
                      <td>{item?.contractorName ? item?.contractorName : "CoPilot"}</td>
                      <td
                        className={clsx(
                          classes.upperCase,
                          classes.hideOnMobile
                        )}
                      >
                        {item?.Status === InvoiceStatus.PAID
                          ? InvoiceStatus.PAID
                          : item?.Status === InvoiceStatus.VOID
                            ? InvoiceStatus.VOID
                            : "TO BE PAID"}
                      </td>
                      <td className={classes.hideOnMobile}>
                        <Button
                          onClick={() => handleDownloadInvoice(item?.InvoiceID)}
                          color="info"
                        >
                          Download Details
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Container>
        </div>
        {/* <FooterTransparentEmployer /> */}
      </div>
    </Fragment>
  );
});

export default Billing;
