import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import routes from "routers/routes";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const FooterEmployerMobile = memo(({}: Props) => {
  const { timesheets } = useSelector((state: ReducerType) => state.employer);
  const { isHaveNewMessage } = useSelector(
    (state: ReducerType) => state.status
  );

  const [numberOfPendingTimesheets, setNumberOfPendingTimesheets] = useState(0);

  useEffect(() => {
    if (timesheets) {
      const pendingTimesheets = timesheets.filter((item) => item?.pending);
      setNumberOfPendingTimesheets(pendingTimesheets.length);
    }
  }, [timesheets]);

  return (
    <div className={classes.wrapper}>
      <Container>
        <div className={classes.content}>
          <Button
            className={classes.button}
            color="neutral"
            to={routes.employer.listing.root}
            tag={Link}
          >
            <i
              className={clsx(
                "now-ui-icons design_bullet-list-67",
                classes.footerIcon
              )}
            />
          </Button>
          <Button
            className={classes.button}
            color="neutral"
            to={routes.employer.manage.root}
            tag={Link}
          >
            <i
              className={clsx(
                "now-ui-icons business_chart-pie-36",
                classes.footerIcon
              )}
            ></i>
          </Button>
          <Button
            className={classes.button}
            color="neutral"
            to={routes.employer.listing.messenger}
            tag={Link}
          >
            <i
              className={clsx(
                "now-ui-icons ui-2_chat-round",
                classes.footerIcon,
                classes.messageIcon
              )}
            >
              <div
                className={
                  isHaveNewMessage
                    ? clsx(classes.messageDot)
                    : clsx(classes.messageDot, "d-none")
                }
              ></div>
            </i>
          </Button>
          <Button
            className={classes.button}
            color="neutral"
            to={routes.employer.manage.timesheets}
            tag={Link}
          >
            <i
              className={clsx(
                "now-ui-icons shopping_credit-card",
                classes.footerIcon
              )}
            >
              {numberOfPendingTimesheets > 0 && (
                <div
                  id="messenger-employer-dot"
                  className={clsx(classes.timesheetNotify)}
                >
                  <span className={classes.numberOfPendingTimesheets}>
                    {numberOfPendingTimesheets}
                  </span>
                </div>
              )}
            </i>
          </Button>
        </div>
      </Container>
    </div>
  );
});

export default FooterEmployerMobile;
