import { Fragment, memo, useEffect, useState, useRef } from "react";
import ProfilePageHeader from "./components/ProfilePageHeader";
import { Button } from "reactstrap";
import MyExperience from "./components/MyExperience";
import Education from "./components/Education";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import FooterTransparentEmployer from "components/Footers/FooterTransparentEmployer";
import ScrollNavbar from "components/Navbars/Employer/ScrollNavbar";
import routes from "routers/routes";
import { JobService } from "services/Employer/Listing/Job";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { ImageService } from "services/Image";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import Sidebar from "components/Sidebar/Sidebar.js";
import clsx from 'clsx';
import classes from './styles.module.scss';

interface Props {}

type UrlParams = {
  jobId: string;
  contractorId: string;
};

const JobContractorProfile = memo((props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mainPanel = useRef<HTMLDivElement>(null);
  const [avatar, setAvatar] = useState(null);
  const { jobId, contractorId } = useParams<UrlParams>();
  const [backgroundColor, setBackgroundColor] = useState('');
  const { setting } = useSelector((state: ReducerType) => state.employer);
  
  useEffect(() => {
    if (setting?.colour) {
      setBackgroundColor(setting?.colour);
    }
  }, [setting]); 

  useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const getActiveRoute = (routes) => {
    let activeRoute = "NECTA";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const [contractorProfile, setContractorProfile] = useState({
    profile: {
      firstName: "",
      lastName: "",
      photoUrl: "",
      title: "",
      about: "",
    },
    preference: {
      availableDate: "",
      maxRate: "",
      maxSalary: "",
      minRate: "",
      minSalary: "",
      preferFixedTerm: "",
      preferPermanent: "",
      radius: "",
      workPolicy: "",
      noticePeriod: "",
    },
    experience: [],
    education: [],
    contact: {
      city: "",
      country: "",
      countryCode: "",
      mobile: "",
    },
    testimonials: [],
    verification: {
      visaType: "",
    },
  });

  useEffect(() => {
    dispatch(setLoading(true));
    Promise.all([
      JobService.getContractorProfileJobOffer(jobId, contractorId),
      JobService.getContractorPreferenceJobOffer(jobId, contractorId),
      JobService.getContractorExperiencesJobOffer(jobId, contractorId),
      JobService.getContractorEducationsJobOffer(jobId, contractorId),
      JobService.getContractorContactJobOffer(jobId, contractorId),
      JobService.getContractorTestimonialsJobOffer(jobId, contractorId),
      JobService.getContractorVerificationJobOffer(jobId, contractorId),
    ])
      .then((res) => {
        const [
          Profile,
          Preference,
          Experience,
          Education,
          Contact,
          Testimonials,
          verification,
        ] = res;
        if (Profile) {
          setContractorProfile({
            profile: {
              firstName: Profile?.firstName,
              lastName: Profile?.lastName,
              photoUrl: Profile?.photoUrl,
              title: Profile?.title,
              about: Profile?.about,
            },
            preference: {
              availableDate: Preference?.availableDate,
              maxRate: Preference?.maxRate,
              maxSalary: Preference?.maxSalary,
              minRate: Preference?.minRate,
              minSalary: Preference?.minSalary,
              preferFixedTerm: Preference?.preferFixedTerm,
              preferPermanent: Preference?.preferPermanent,
              radius: Preference?.radius,
              workPolicy: Preference?.workPolicy,
              noticePeriod: Preference?.noticePeriod,
            },
            experience: [...Experience],
            education: [...Education],
            contact: {
              city: Contact?.city,
              country: Contact?.country,
              countryCode: Contact?.countryCode,
              mobile: Contact?.mobile,
            },
            testimonials: [...Testimonials],
            verification: {
              visaType: verification?.visaType,
            },
          });
          if (Profile?.photoUrl) {
            ImageService.getImage(Profile?.photoUrl, Profile.identityId)
              .then((res) => {
                setAvatar(res);
              })
              .catch((err) => dispatch(setErrorMess(err)));
          }
        } else {
          dispatch(setErrorMess("Sorry! An error occurred."));
        }
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  }, [contractorId, dispatch, jobId]);

  return (
    <Fragment>
      {backgroundColor && (
        <Sidebar
          {...props}
          backgroundColor={backgroundColor}
        />
      )}
      <div className={clsx("main-panel", classes['main-panel'])} ref={mainPanel}>
        <AdminNavbar {...props} brandText={getActiveRoute(routes)} />
        <div className="wrapper">
          <ProfilePageHeader
            avatar={avatar}
            profile={contractorProfile.profile}
            preference={contractorProfile.preference}
            experience={contractorProfile.experience}
            contact={contractorProfile.contact}
            testimonials={contractorProfile.testimonials}
            verification={contractorProfile.verification}
            contractorId={contractorId}
            goBack={history.goBack} 
          />
          <Education education={contractorProfile.education} />
          <MyExperience
            experience={contractorProfile.experience}
            testimonials={contractorProfile.testimonials}
          />
        </div>
      </div>
    </Fragment>
  );
});

export default JobContractorProfile;
