import { memo, useEffect, useMemo, useState } from 'react';
import { Form, Button, Card, CardBody, Modal } from 'reactstrap';
import CustomInput from 'components/Common/CustomInput';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import classes from './styles.module.scss';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import CustomCheckbox from 'components/Common/CustomCheckbox';
import clsx from 'clsx';
import SearchLocationInput from 'components/Common/GoogleAddress';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  data: any;
  handleUpdateEducation: (
    dataUpdate,
    removeList,
    callback?: () => void
  ) => void;
}

interface EducationFormData {
  educationList: {
    id: number;
    degreeName: string;
    schoolName: string;
    endDate: string;
    country: string;
    endDateIsPresent: boolean;
  }[];
}

// eslint-disable-next-line no-empty-pattern
const EditEducation = memo(
  ({ onClose, isOpen, data, handleUpdateEducation }: Props) => {
    const [itemRemoveList, setItemRemoveList] = useState([]);
    const schema = useMemo(() => {
      return yup.object().shape({
        educationList: yup.array().of(
          yup.object().shape({
            id: yup.number(),
            degreeName: yup.string().required('This field is required'),
            schoolName: yup.string().required('This field is required'),
            endDate: yup.date().typeError('Please enter a valid date'),
            country: yup.string().required('This field is required'),
            endDateIsPresent: yup.bool(),
          })
        ),
      });
    }, []);

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      watch,
      getValues,
      setValue,
      control,
    } = useForm<EducationFormData>({
      resolver: yupResolver(schema),
      mode: 'onChange',
    });
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'educationList',
      keyName: 'fieldID',
    });

    // useEffect(() => {
    //   if (isOpen) {
    //     data.forEach((item) => {
    //       append({ ...item, endDate: new Date(item?.endDate) });
    //     });
    //   }
    // }, [append, data, reset, isOpen]);
    useEffect(() => {
      if (isOpen) {
        if (data && data.length > 0) {
          data.forEach((item) => {
            append({ ...item, endDate: new Date(item?.endDate) });
          });
        } else {
          append({ endDateIsPresent: true });
        }
      }
    }, [append, data, isOpen]);

    const handleRemove = (id, index) => {
      remove(index);
      if (id) {
        setItemRemoveList((prev) => [...prev, id]);
      }
    };

    const handleChangeEndDateIsPresent = (index) => {
      const currentEndDateIsPresent = getValues(
        `educationList.${index}.endDateIsPresent`
      );
      setValue(
        `educationList.${index}.endDateIsPresent`,
        !currentEndDateIsPresent
      );
    };

    const onSubmit = (data: EducationFormData) => {
      const dataEducationList = data?.educationList.map((item) => {
        if (item?.endDateIsPresent) {
          return {
            ...item,
            endDate: new Date().toISOString(),
          };
        } else {
          return {
            ...item,
          };
        }
      });
      handleUpdateEducation(dataEducationList, itemRemoveList, _onClose);
    };

    const _onClose = () => {
      remove();
      setItemRemoveList([]);
      onClose();
    };

    return (
      <Card className={classes.card}>
        <Modal isOpen={isOpen} toggle={_onClose} centered scrollable>
          <CardBody className={classes.cardBody}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <p className={classes.title}>
                Edit Education &amp; Qualifications
              </p>
              <div className={classes.content}>
                {fields?.map((field: any, index) => (
                  <div key={field?.fieldID}>
                    {/* {fields?.length > 1 && ( */}
                      <div className={classes.btnDelete}>
                        <Button onClick={() => handleRemove(field?.id, index)}>
                          <i className='fa fa-trash' />
                        </Button>
                      </div>
                    {/* )} */}
                    <CustomInput
                      placeholder='Name of Qualification'
                      type='text'
                      autoComplete='off'
                      inputRef={register(`educationList.${index}.degreeName`)}
                      errorMessage={
                        errors.educationList &&
                        errors.educationList[index]?.degreeName?.message
                      }
                    />
                    <CustomInput
                      placeholder='Provider'
                      type='text'
                      autoComplete='off'
                      inputRef={register(`educationList.${index}.schoolName`)}
                      errorMessage={
                        errors.educationList &&
                        errors.educationList[index]?.schoolName?.message
                      }
                    />
                    <CustomCheckbox
                      content='I am currently in this education'
                      inputRef={register(
                        `educationList.${index}.endDateIsPresent`
                      )}
                      errorMessage={
                        errors.educationList &&
                        errors.educationList[index]?.endDateIsPresent?.message
                      }
                      className={clsx('my-2', classes.customCheckbox)}
                      onChange={() => handleChangeEndDateIsPresent(index)}
                    />
                    <CustomDatePicker
                      className={
                        watch(`educationList.${index}.endDateIsPresent`)
                          ? classes.disabled
                          : ''
                      }
                      readOnly={watch(
                        `educationList.${index}.endDateIsPresent`
                      )}
                      name={`educationList.${index}.endDate`}
                      control={control}
                      placeholder='Date Finished'
                      dateFormat='dd/MM/yyyy'
                      errorMessage={
                        errors.educationList &&
                        errors.educationList[index]?.endDate?.message
                      }
                    />
                    <Controller
                      name={`educationList.${index}.country`}
                      control={control}
                      render={({ field }) => (
                        <SearchLocationInput
                          {...field}
                          ref={null}
                          placeholder='Country'
                          errorMessage={
                            errors.educationList &&
                            errors.educationList[index]?.country?.message
                          }
                          indexx={1}
                          value={field.value}
                          onChange={(value: any) => {
                            return field.onChange(value?.address?.country);
                          }}
                        />
                      )}
                    />
                    {index !== fields.length - 1 && (
                      <hr className={classes.divider} />
                    )}
                  </div>
                ))}
              </div>
              <div className={classes.buttonContainer}>
                <Button color='default' type='button' onClick={_onClose}>
                  Cancel
                </Button>
                <Button
                  type='button'
                  className={classes.btnAddMore}
                  onClick={() => append({ endDateIsPresent: true })}
                >
                  <span className='text-dark'>
                    Add more
                    <i className='fa fa-plus ml-2' />
                  </span>
                </Button>
                <Button color='primary' type='submit'>
                  Ok
                </Button>
              </div>
            </Form>
          </CardBody>
        </Modal>
      </Card>
    );
  }
);

export default EditEducation;
