import { memo } from "react";
import { Button, Card, CardBody, Modal, CardTitle } from "reactstrap";
import { TypesOfInterview } from "models/Employer/Listings/Dashboard";
import classes from "./styles.module.scss";
interface Props {
    onClose: () => void;
    isOpen: boolean;
    testimonials?: any;
}

const TestimonialDetails = memo(({ onClose, isOpen, testimonials }: Props) => {

    const testimonialsAvailable = testimonials.filter((e) => e?.response)

    return (
        <Card className={classes.card}>
            <Modal
                isOpen={isOpen}
                toggle={onClose}
                centered
                scrollable
                className={classes.modal}
            >
                <CardBody className={classes.cardBody}>
                    <CardTitle className={classes.title}>TESTIMONIALS</CardTitle>
                </CardBody>
                <CardBody className={classes.cardDateBody}>
                    {
                        testimonialsAvailable.map((testimonial, index) => (
                            <ul className={classes.commonTitle} key={index}>
                                <li>
                                    <h1>
                                        {testimonial?.name}, {testimonial?.providersTitle} - {testimonial?.experience?.company}
                                    </h1>
                                    <h5>
                                        {testimonial?.response}
                                    </h5>
                                </li>
                            </ul>
                        ))
                    }
                </CardBody>
                <CardBody className={classes.cardBody}>
                    <div className={classes.buttonContainer}>
                        <Button
                            size="md"
                            color="default"
                            type="button"
                            className={classes.btn}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </CardBody>
            </Modal>
        </Card>
    );
});

export default TestimonialDetails;
