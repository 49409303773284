import React, { Fragment, memo, useEffect, useState, useRef } from 'react';
import {
  Button,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Progress,
  Row,
  Col,
  CardText,
  CardColumns,
  Badge,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faBriefcase, faUserTie, faHandshake, faListUl, faUserCheck, faFileContract } from '@fortawesome/free-solid-svg-icons';
import classes from './styles.module.scss';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from 'redux/reducers';
import { useModal } from 'components/Modals';
import { Bar, Pie, Line, Radar, Bubble  } from 'react-chartjs-2';
import { VectorMap } from 'react-jvectormap';
import { table_data } from "variables/general.js";
import { format } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  RadialLinearScale
} from 'chart.js';
import { ImageService } from "services/Image";
import { JobService } from "services/Employer/Listing/Job";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  RadialLinearScale
);

interface DashboardProps {}

const colors = {
  orangeColor: '#E86C42',
  brandPrimary: '#E86C42',
  brandInfo: '#42BEE8',
  brandSuccess: '#3AAE39',
  brandWarning: '#FFB236',
  brandDanger: '#D32816',
  brandInverse: '#2c2c2c',
  linkDisabledColor: '#666666',
  Blue: 'rgba(232, 108, 66, .8)',
  Azure: 'rgba(66, 190, 232, .8)',
  Green: 'rgba(58, 174, 57, .8)',
  Orange: 'rgba(255, 178, 54, .8)',
  Red: 'rgba(211, 40, 22, .8)',
  lightBlue: 'rgba(232, 108, 66, .4)',
  lightAzure: 'rgba(66, 190, 232, .4)',
  lightGreen: 'rgba(58, 174, 57, .4)',
  lightOrange: 'rgba(255, 178, 54, .4)',
  lightRed: 'rgba(211, 40, 22, .4)',
};


const extractChartData = (jobList, interviewOffers, jobOffers, allCandidatesList, timesheets) => {
  // Extract stats
  const totalOpenPositions = jobList.filter(job => !job.isArchived).length;
  const totalInterviews = interviewOffers.length;
  const activeContractors = jobOffers.filter(offer => offer.accepted).length;

  // Filter candidates
  const longListCandidates = allCandidatesList.filter(candidate => 
    !candidate.offered && 
    !jobOffers.some(offer => offer.candidateId === candidate.id) &&
    candidate.source !== 'headhunter'
  );
  const shortListCandidates = allCandidatesList.filter(candidate => 
    candidate.offered && 
    !interviewOffers.some(interview => interview.candidateId === candidate.id) && 
    !jobOffers.some(offer => offer.candidateId === candidate.id)
  );
  const interviewCandidates = interviewOffers.filter(interview => 
    !jobOffers.some(offer => offer.candidateId === interview.candidateId)
  ).length;
  const contractCandidates = jobOffers.filter(offer => offer.accepted).length;

  // Filter out candidates with source 'headhunter'
  const totalCandidates = allCandidatesList.filter(candidate => candidate.source !== 'headhunter').length;

  // Calculate contracts approved per month
  const now = new Date();
  const last12Months = Array.from({ length: 12 }, (v, i) => {
    const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
    return format(date, 'MMM yy');
  }).reverse();

  const contractsApprovedPerMonth = Array(12).fill(0); // Initialize an array for 12 months

  jobOffers.forEach(offer => {
    if (offer.accepted) {
      const approvalDate = new Date(offer.created); 
      const monthDiff = (now.getFullYear() - approvalDate.getFullYear()) * 12 + (now.getMonth() - approvalDate.getMonth());
      if (monthDiff >= 0 && monthDiff < 12) {
        contractsApprovedPerMonth[11 - monthDiff]++;
      }
    }
  });

  // Calculate time to hire
  const timeToHirePerMonth = Array(12).fill([]).map(() => []);
  const runningAverageTimeToHirePerMonth = Array(12).fill(0);
  const jobOffersByJobId = jobOffers.reduce((acc, offer) => {
    if (!acc[offer.job]) acc[offer.job] = [];
    acc[offer.job].push(offer);
    return acc;
  }, {});
  
  // Calculate interview to offer ratio for the last three months
  const interviewToOfferRatio = [];
  for (let i = 2; i >= 0; i--) {
    const month = new Date(now.getFullYear(), now.getMonth() - i, 1);
    const monthStart = new Date(month.getFullYear(), month.getMonth(), 1).getTime();
    const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0).getTime();

    const monthlyInterviews = interviewOffers.filter(interview => {
      if (!interview.interviewTime) return false;
      const interviewDate = new Date(interview.interviewTime).getTime();
      return interviewDate >= monthStart && interviewDate <= monthEnd;
    }).length;

    const monthlyOffers = jobOffers.filter(offer => {
      if (!offer.created) return false;
      const offerDate = new Date(offer.created).getTime();
      return offerDate >= monthStart && offerDate <= monthEnd;
    }).length;

    const ratio = monthlyOffers === 0 ? 0 : (monthlyInterviews / monthlyOffers);
    interviewToOfferRatio.push(ratio);
  }

  jobList.forEach(job => {
    const jobId = job.id;
    if (jobOffersByJobId[jobId]) {
      jobOffersByJobId[jobId].forEach(offer => {
        if (offer.created) {
          const jobCreatedDate = new Date(job.created).getTime();
          const offerCreatedDate = new Date(offer.created).getTime();
          const timeToHire = Math.round((offerCreatedDate - jobCreatedDate) / (1000 * 60 * 60 * 24));
          const monthDiff = (now.getFullYear() - new Date(offer.created).getFullYear()) * 12 + (now.getMonth() - new Date(offer.created).getMonth());
          if (monthDiff >= 0 && monthDiff < 12) {
            timeToHirePerMonth[11 - monthDiff].push(timeToHire);
          }
        }
      });
    }
  });

  for (let i = 0; i < 12; i++) {
    const monthlyTimes = timeToHirePerMonth[i];
    if (monthlyTimes.length) {
      runningAverageTimeToHirePerMonth[i] = Math.round(monthlyTimes.reduce((acc, val) => acc + val, 0) / monthlyTimes.length);
    } else if (i > 0) {
      runningAverageTimeToHirePerMonth[i] = runningAverageTimeToHirePerMonth[i - 1];
    }
  }

  // Calculate shortlist to interview ratio by source
  const sources = ['internal', 'recruiter', '']; // '' represents necta
  const shortlistToInterviewRatio = sources.map(source => {
    const shortlists = shortListCandidates.filter(candidate => {
      const isSourceMatch = candidate.source === source || (source === '' && !candidate.source);
      return isSourceMatch;
    }).length;

    const interviews = interviewOffers.filter(interview => {
      if (!interview.contractor) return false;
      const candidate = allCandidatesList.find(c => c.id === interview.contractor);
      if (!candidate) return false;
      const isSourceMatch = candidate.source === source || (source === '' && !candidate.source);
      return isSourceMatch;
    }).length;

    const ratio = shortlists === 0 ? 0 : (interviews / shortlists) * 100; // Convert to percentage
    return ratio;
  });


  // Calculate candidate demographics data
  const allSources = ['internal', 'recruiter', '', 'jobBoard', 'headhunter', 'referral']; // '' represents necta
  const totalCandidatesBySource = allSources.map(source => 
    source === 'jobBoard' 
      ? allCandidatesList.filter(candidate => candidate.applied).length
      : allCandidatesList.filter(candidate => candidate.source === source || (source === '' && !candidate.source)).length
  );
  const shortlistedCandidatesBySource = allSources.map(source => 
    source === 'jobBoard' 
      ? shortListCandidates.filter(candidate => candidate.applied).length
      : shortListCandidates.filter(candidate => candidate.source === source || (source === '' && !candidate.source)).length
  );
  const candidateDemographicsPercentages = shortlistedCandidatesBySource.map((count, index) => 
    totalCandidatesBySource[index] === 0 ? 0 : (count / totalCandidatesBySource[index]) * 100
  );

  // Extract bar chart data
  const statsCounts = {
    jobsCreated: jobList.length,
    applicants: totalCandidates,
    shortList: shortListCandidates.length,
    interviews: interviewOffers.length,
    openJobs: jobList.filter(job => !job.isArchived).length,
    contractors: jobOffers.length,
  };

  const barChartData = {
    labels: ["Jobs Created", "Short Listed"],
    datasets: [
      {
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: [
          statsCounts.jobsCreated,
          statsCounts.shortList,
        ],
      },
    ],
  };

  // Extract source of hire data
  const sourceOfHire = {
    necta: allCandidatesList.filter(candidate => !candidate.source && !candidate.applied).length,
    internal: allCandidatesList.filter(candidate => candidate.source === 'internal').length,
    nelly: allCandidatesList.filter(candidate => candidate.source === 'headhunter').length,
    recruitment: allCandidatesList.filter(candidate => candidate.source === 'recruiter').length,
    applied: allCandidatesList.filter(candidate => candidate.applied).length,
  };

  const sourceOfHireBarChart = {
    labels: ["NECTA", "INTERNAL", "Recruiters", "APPLIED"],
    datasets: [
      {
        data: [
          sourceOfHire.necta, 
          sourceOfHire.internal, 
          sourceOfHire.recruitment, 
          sourceOfHire.applied,
        ],
        backgroundColor: [
          colors.lightOrange, 
          colors.lightAzure, 
          colors.lightBlue, 
          colors.lightGreen, 
          colors.lightRed
        ],  
        hoverBackgroundColor: [
          colors.Orange, 
          colors.Azure, 
          colors.Blue, 
          colors.Green, 
          colors.Red
        ]  
      },
    ],
  };

  // Extract pie chart data
  const timesheetStatusCounts = {
    pending: timesheets.filter(timesheet => !timesheet.approved && !timesheet.declined).length,
    declined: timesheets.filter(timesheet => timesheet.declined).length,
    approved: timesheets.filter(timesheet => timesheet.approved).length,
  };

  const pieChartData = {
    labels: ["Pending", "Declined", "Approved"],
    datasets: [
      {
        data: [timesheetStatusCounts.pending, timesheetStatusCounts.declined, timesheetStatusCounts.approved],
        backgroundColor: [
          colors.lightOrange, 
          colors.lightAzure, 
          colors.lightBlue, 
          colors.lightGreen, 
          colors.lightRed
        ],  
        hoverBackgroundColor: [
          colors.Orange, 
          colors.Azure, 
          colors.Blue, 
          colors.Green, 
          colors.Red
        ] 
      },
    ],
  };

  return { 
    barChartData, 
    pieChartData, 
    totalOpenPositions, 
    totalInterviews, 
    activeContractors, 
    sourceOfHireBarChart, 
    longListCandidates, 
    shortListCandidates, 
    interviewCandidates, 
    contractCandidates, 
    totalCandidates, 
    contractsApprovedPerMonth, 
    last12Months, 
    runningAverageTimeToHirePerMonth, 
    interviewToOfferRatio, 
    shortlistToInterviewRatio,
    candidateDemographicsPercentages
  };
};


const Dashboard = memo((props: DashboardProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { jobList, interviewOffers, jobOffers, setting, allCandidatesList, timesheets } = useSelector(
    (state: ReducerType) => state.employer
  );

  // console.log("joboffers", jobOffers)
  // console.log("jobList", jobList)
  // console.log("allCandidatesList", allCandidatesList)
  // console.log("interviewOffers", interviewOffers)

  const isDataEmpty = (chartData) => {
    return chartData.datasets.every(dataset => dataset.data.every(value => value === 0));
  };

  const [chartData, setChartData] = useState({
    barChartData: {
      labels: [],
      datasets: []
    },
    pieChartData: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: []
      }]
    },
    sourceOfHireBarChart: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: []
      }]   
    },
    totalOpenPositions: 0,
    totalInterviews: 0,
    activeContractors: 0,
    longListCandidates: 0,
    shortListCandidates: 0,
    interviewCandidates: 0,
    contractCandidates: 0,
    totalCandidates: 0,
    contractsApprovedPerMonth: [],
    last12Months: [],
    runningAverageTimeToHirePerMonth: [],
    interviewToOfferRatio: [],
    shortlistToInterviewRatio: [],
    candidateDemographicsPercentages: []
  });

  useEffect(() => {
    if (jobList.length && interviewOffers.length && jobOffers.length && allCandidatesList.length && timesheets.length) {
      const { 
        barChartData, 
        pieChartData, 
        totalOpenPositions, 
        totalInterviews, 
        activeContractors, 
        sourceOfHireBarChart, 
        longListCandidates, 
        shortListCandidates, 
        interviewCandidates, 
        contractCandidates, 
        totalCandidates, 
        contractsApprovedPerMonth, 
        last12Months, 
        runningAverageTimeToHirePerMonth, 
        interviewToOfferRatio, 
        shortlistToInterviewRatio,
        candidateDemographicsPercentages 
      } = extractChartData(
        jobList,
        interviewOffers,
        jobOffers,
        allCandidatesList,
        timesheets,
      );
      setChartData({ 
        barChartData, 
        pieChartData, 
        totalOpenPositions, 
        totalInterviews, 
        activeContractors, 
        sourceOfHireBarChart, 
        longListCandidates: longListCandidates.length, 
        shortListCandidates: shortListCandidates.length, 
        interviewCandidates, 
        contractCandidates, 
        totalCandidates, 
        contractsApprovedPerMonth, 
        last12Months, 
        runningAverageTimeToHirePerMonth, 
        interviewToOfferRatio, 
        shortlistToInterviewRatio,
        candidateDemographicsPercentages
      });
    }
  }, [jobList, interviewOffers, jobOffers, allCandidatesList, timesheets]);

  const handleUpgrade = ({ data }) => {
    // console.log(data)
    openUpgrade({ data: data.id });
  };

  const { onOpen: openUpgrade} = useModal('Upgrade');

  const [chosenRawClientData, setChosenRawClientData] = useState(null);
  const updateChosenRawClientData = (updatedData) => {
    setChosenRawClientData({ ...chosenRawClientData, ...updatedData });
  };


  const { onOpen: openRatesChoice } = useModal('RatesChoice');
  const handleRatesChoice = (item) => {
    openRatesChoice({ item: item });
  };

  const { onOpen: openNewProject } = useModal('NewProject');
  const handleOpenAddProjectForm = (item) => {
    openNewProject({ item: item });
  };

  const { onOpen: openNewClient } = useModal('NewClient');
  const handleOpenAddClientForm = (item) => {
    openNewClient({ item: item });
  };


  const averageTimeToHire = chartData.runningAverageTimeToHirePerMonth.filter(Boolean).slice(-1)[0] ? `${chartData.runningAverageTimeToHirePerMonth.filter(Boolean).slice(-1)[0]} Days` : "No Hires";

//chart data
  const statsData = [
    { title: "Average Time to Hire", value: averageTimeToHire, icon: faClock },
    { title: "Total Open Positions", value: chartData.totalOpenPositions, icon: faBriefcase },
    { title: "Total Candidates", value: chartData.totalCandidates, icon: faUserTie }, // Updated
    { title: "Active Contractors", value: chartData.activeContractors, icon: faHandshake },
  ];




  const monthlyLineChartData = {
    labels: chartData.last12Months,
    datasets: [
      {
        label: "Contracts Approved Per Month",
        backgroundColor: colors.Red,
        borderColor: colors.Red,
        borderWidth: 1,
        hoverBackgroundColor: colors.Red,
        hoverBorderColor: colors.lightRed,
        fill: false,
        data: chartData.contractsApprovedPerMonth,
        tension: 0.1,
      },
    ],
  };


//statistics bar chart data 
const pipelineFunnelData2 = {
  labels: [
    'Long-List', 
    'Short-List', 
    'Interview', 
    'Contract'
  ],
  datasets: [
    {
      label: 'Candidates',
      data: [
          chartData.longListCandidates, 
          chartData.shortListCandidates, 
          chartData.totalInterviews, 
          chartData.contractCandidates
        ],
      backgroundColor: [
          colors.lightOrange, 
          colors.lightAzure, 
          colors.lightBlue, 
          colors.lightGreen, 
          colors.lightRed
        ]  
    }
  ]
};

const pipelineFunnelOptions2 = {
  scales: {
    y: {
      beginAtZero: true,
      stacked: false,
      ticks: {
        callback: function(value) {
          return value;
        }
      }
    },
    x: {
      stacked: false
    }
  },
  plugins: {
    legend: {
      display: false // This disables the legend
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y;
          }
          return label;
        }
      }
    }
  }
};


const interviewFeedbackScoresData2 = {
  labels: ['Candidate A', 'Candidate B', 'Candidate C'],
  datasets: [
    {
      label: 'Feedback Score',
      data: [
        [4, 5, 3],
        [3, 4, 5],
        [5, 3, 4]
      ],
      backgroundColor: [
        colors.lightOrange, 
        colors.lightAzure, 
        colors.lightBlue, 
        colors.lightGreen, 
        colors.lightRed
      ],  
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1
    }
  ]
};

const interviewFeedbackScoresOptions = {
  scales: {
    x: {
      type: 'category'
    },
    y: {
      type: 'category'
    }
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed !== null) {
            label += context.parsed + ' NPS';
          }
          return label;
        }
      }
    }
  }
};

const pipelineFunnelData = {
  labels: [
    'Long-List', 
    'Short-List', 
    'Interview', 
    'Contract', 
    'Hired'
  ],
  datasets: [
    {
      label: 'Candidates',
      data: [
        100, // Long-List is always 100%
        Math.round((chartData.shortListCandidates / chartData.longListCandidates) * 100),
        Math.round((chartData.totalInterviews / chartData.longListCandidates) * 100),
        Math.round((chartData.contractCandidates / chartData.longListCandidates) * 100),
        Math.round((jobOffers.filter(offer => offer.accepted).length / chartData.longListCandidates) * 100)
      ],
      backgroundColor: [
        colors.lightOrange, 
        colors.lightAzure, 
        colors.lightBlue, 
        colors.lightGreen, 
        colors.lightRed
      ]
    }
  ]
};

const pipelineFunnelOptions = {
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: function(value) {
          return value + '%'; // Appends '%' to y-axis labels
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false // This disables the legend
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y + '%'; // Appends '%' to tooltip labels
          }
          return label;
        }
      }
    }
  }
};

const timeToHireData = {
  labels: chartData.last12Months,
  datasets: [{
    label: 'Average Time to Hire (Days)',
    data: chartData.runningAverageTimeToHirePerMonth,
    fill: false,
    backgroundColor: colors.lightAzure,
    borderColor: colors.Azure,
    tension: 0.1,
  }]
};

const sourceOfHireData = {
  labels: ['Applications', 'Referral', 'Social Media', 'HeadHunter'],
  datasets: [{
    data: [120, 60, 30, 90],
    backgroundColor: [
      colors.lightOrange, 
      colors.lightAzure, 
      colors.lightBlue, 
      colors.lightGreen, 
      colors.lightRed
    ]  
  }]
};

const acceptedOffers = jobOffers.filter(offer => offer.accepted).length;
const declinedOffers = jobOffers.length - acceptedOffers;

// Create offerAcceptanceRateData
const offerAcceptanceRateData = {
  labels: ['Accepted', 'Declined'],
  datasets: [{
    data: [acceptedOffers, declinedOffers],
    backgroundColor: [      
      colors.lightOrange, 
      colors.lightAzure, 
    ],
    hoverBackgroundColor: [
      colors.Orange, 
      colors.Azure, 
    ]  
  }]
};
const sources = ['headhunter', 'jobBoard', 'recruiter', 'referral', 'socialMedia', 'other'];

// Calculate the total number of candidates for each source
const sourceCounts = sources.map(source => {
  return allCandidatesList.filter(candidate => candidate.source === source).length;
});

// Calculate the total number of candidates
const totalCandidates = sourceCounts.reduce((total, count) => total + count, 0);

// Calculate the percentage for each source
const sourcePercentages = sourceCounts.map(count => (count / totalCandidates) * 100);

// Create candidateDemographicsData
const candidateDemographicsData = {
  labels: ['Internal', 'Recruiter', 'Necta', 'Job Boards', 'Headhunt', 'Referrals'],
  datasets: [{
    data: chartData.candidateDemographicsPercentages.map(ratio => ratio.toFixed(2)),
    backgroundColor: [
      colors.lightOrange, 
      colors.lightAzure, 
      colors.lightBlue, 
      colors.lightGreen, 
      colors.lightRed
    ],
    hoverBackgroundColor: [
      colors.Orange, 
      colors.Azure, 
      colors.Blue, 
      colors.Green, 
      colors.Red
    ]  
  }]
};

const candidateDemographicsOptions = {
  scales: {
    r: {
      ticks: {
        callback: function(value) {
          return value + '%'; // Adds percentage symbol to axis labels
        },
        beginAtZero: true,
        max: 100,
        stepSize: 20
      },
    }
  },
  plugins: {
    legend: {
      display: false // Disables the legend
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.raw !== null) {
            label += context.raw + '%'; // Adds percentage symbol to tooltip
          }
          return label;
        }
      }
    }
  }
};


const interviewToOfferRatioData = {
  labels: chartData.last12Months.slice(-3),
  datasets: [{
    label: 'Interviews per Offer',
    data: chartData.interviewToOfferRatio.map(ratio => ratio.toFixed(2)),
    backgroundColor: [
      colors.lightOrange, 
      colors.lightAzure, 
      colors.lightBlue, 
      colors.lightGreen, 
      colors.lightRed
    ]  
  }]
};

const interviewToOfferRatioOptions = {
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: function(value) {
          return value;
        }
      }
    },
    x: {
      beginAtZero: true,
    }
  },
  plugins: {
    legend: {
      display: false // This disables the legend
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y;
          }
          return label;
        }
      }
    }
  }
};

const applicationTrendsData = {
  labels: ['January', 'February', 'March', 'April', 'May'],
  datasets: [{
    label: 'Applications',
    data: [200, 250, 300, 350, 400],
    fill: false,
    backgroundColor: [
      colors.lightOrange, 
      colors.lightAzure, 
      colors.lightBlue, 
      colors.lightGreen, 
      colors.lightRed
    ],
    borderColor: '#4BC0C0'
  }]
};

const recruiterPerformanceData = {
  labels: ['Internal', 'Recruiter', 'NECTA'],
  datasets: [{
    label: 'Shortlist to Interview Ratio',
    data: chartData.shortlistToInterviewRatio.map(ratio => ratio.toFixed(2)),
    backgroundColor: [
      colors.lightOrange, 
      colors.lightAzure, 
      colors.lightBlue, 
      colors.lightGreen, 
      colors.lightRed
    ]  
  }]
};

const jobOpeningsStatusData = {
  labels: ['Open', 'In Progress', 'Closed'],
  datasets: [{
    label: 'Job Status',
    data: [10, 5, 15],
    backgroundColor: [
      colors.lightOrange, 
      colors.lightAzure, 
      colors.lightBlue, 
      colors.lightGreen, 
      colors.lightRed
    ]  
  }]
};

const categories = ['Job Description Clarity', 'Interview Process', 'Feedback Timeliness', 'Interviewer Professionalism', 'Overall Experience'];

const generateRandomFeedbackData = () => {
  const feedbackData = [];
  const categorySums = Array(categories.length).fill(0);
  const categoryCounts = Array(categories.length).fill(0);

  for (let i = 0; i < 50; i++) {
    const x = Math.floor(Math.random() * categories.length) + 1; // Random category index between 1 and number of categories
    const y = Math.floor(Math.random() * 10) + 1; // Random feedback score between 1 and 10
    const r = Math.floor(Math.random() * 20) + 5; // Random radius between 5 and 25
    feedbackData.push({ x, y, r });

    // Accumulate sums and counts for average calculation
    categorySums[x - 1] += y;
    categoryCounts[x - 1] += 1;
  }

  // Calculate averages
  const averageData = categorySums.map((sum, index) => ({
    x: index + 1,
    y: sum / categoryCounts[index],
    r: 15, // Fixed radius for average
  }));

  return { feedbackData, averageData };
};

const { feedbackData, averageData } = generateRandomFeedbackData();

const interviewFeedbackData = {
  datasets: [
    {
      label: 'Feedback',
      data: feedbackData,
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    },
    {
      label: 'Average',
      data: averageData,
      backgroundColor: 'rgba(255, 99, 132, 0.6)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 2,
    },
  ],
};



const interviewFeedbackOptions = {
  scales: {
    x: {
      type: 'linear' as const,
      position: 'bottom' as const,
      ticks: {
        beginAtZero: true,
        max: categories.length,
        callback: function(value) {
          return categories[value - 1]; // Converts index to category name
        },
        stepSize: 1,
      },
      title: {
        display: true,
        text: 'Feedback Category',
      },
    },
    y: {
      beginAtZero: true,
      max: 10,
      title: {
        display: true,
        text: 'Feedback Score',
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.raw.y !== null) {
            label += `Score ${context.raw.y}, Radius ${context.raw.r}`;
          }
          return label;
        }
      }
    }
  }
};

const getGreeting = () => {
  const currentHour = new Date().getHours();
  let greeting = '';
  let emoji = '';

  if (currentHour < 12) {
    greeting = `Good Morning, ${setting?.firstName}!`;
    emoji = '☀️'; // Morning emoji
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = `Good Afternoon, ${setting?.firstName}!`;
    emoji = '🌤️'; // Afternoon emoji
  } else {
    greeting = `Good Evening, ${setting?.firstName}!`;
    emoji = '🌙'; // Evening emoji
  }

  return (
    <div>
      {greeting}
      <span>{emoji}</span>
    </div>
  );
};

const [showAllPeople, setShowAllPeople] = useState(false);

const toggleShowAllPeople = () => setShowAllPeople(!showAllPeople);

// Filter non-archived jobs
const nonArchivedJobs = jobList.filter(job => !job.isArchived);





  //     // Fetch the image for each candidate
  const [candidateImages, setCandidateImages] = useState({}); 
  // Match candidates to jobs and sort them by modified time
  const matchedCandidates = allCandidatesList
    .filter(candidate => {
      return (
        candidate.accepted === true &&
        candidate.offered === true &&
        candidate.revoked === false &&
        candidate.rejected === false &&
        nonArchivedJobs.some(job => job.id === candidate.jobId)
      );
    })
    .sort((a, b) => {
      const dateA = new Date(a.modified);
      const dateB = new Date(b.modified);
      return (dateB.getTime() || 0) - (dateA.getTime() || 0);
    });

  // Only retrieve the top 5 candidates (or all if showing all)
  const topCandidates = matchedCandidates.slice(0, showAllPeople ? matchedCandidates.length : 5);

  // Synchronously fetch the profile and image for each candidate
  topCandidates.forEach(async (person) => {
    // Only fetch if image is not already cached
    if (!candidateImages[person.id]) {
      const profile = await JobService.getContractorProfileJobOffer(person.jobId, person.id);

      // If the profile has photoUrl and identityId, fetch the image
      if (profile?.photoUrl && profile?.identityId) {
        const imageUrl = await ImageService.getImage(profile.photoUrl, profile.identityId);
        setCandidateImages(prevImages => ({
          ...prevImages,
          [person.id]: imageUrl || null,
        }));
      }
    }
  });
  // Helper function to display initials fallback
  const getInitials = (firstName, lastName) => {
    return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
  };

return (
  <Fragment>
    <Container className={classes.container}>
    <div className={classes.greeting}>{getGreeting()}</div>

      <Row>
        {statsData.map((stat, index) => (
          <Col key={index} sm="3">
            <Card className={classes.statcard}>
              <CardBody>
                <div className={classes.staticon}>
                  <FontAwesomeIcon icon={stat.icon} size="2x" />
                </div>
                <h2 className={classes.statvalue}>{stat.value}</h2>
                <CardTitle tag="h5" className={classes.stattitle}>{stat.title}</CardTitle>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Row>
      {/* First Column: People */}
      <Col sm="4">
      <Card className={classes.card}>
  <CardHeader className={classes.columnTitle}>Active Jobs</CardHeader>
  
  {nonArchivedJobs.length > 0 ? (
    nonArchivedJobs.map((job, index) => {
      let stageIcon;
      let stageName;

      // Determine the stage and set corresponding icon and name
      if (jobOffers.some(offer => offer.job === job.id)) {
        stageIcon = <FontAwesomeIcon icon={faFileContract} size="lg" />;
        stageName = "Contract";
      } else if (interviewOffers.some(offer => offer.job === job.id)) {
        stageIcon = <FontAwesomeIcon icon={faUserTie} size="lg" />;
        stageName = "Interview";
      } else if (matchedCandidates.some(candidate => candidate.jobId === job.id)) {
        stageIcon = <FontAwesomeIcon icon={faUserCheck} size="lg" />;
        stageName = "Short List";
      } else {
        stageIcon = <FontAwesomeIcon icon={faListUl} size="lg" />;
        stageName = "Long List";
      }

      return (
        <div key={index} className={classes.jobContainer}>
          <div className={classes.jobDetails}>
            {/* Job Title */}
            <div className={classes.personDetails}>
              <h4 className={classes.jobTitle}>{job.title}</h4>
            </div>
            {/* Job Stage with Icon */}
            <Badge className={classes.jobStageBadge} title={stageName}>
              {stageIcon}
            </Badge>
          </div>
        </div>
      );
    })
  ) : (
<div className={classes.noJobsMessage}>
  <p>Create your first job to start getting insights</p>
  <NavLink
                to={"/employer/admin/myjobs"}
              >
  <Button 
    color="primary" 
    className={classes.createJobButton}
  >
    Create
  </Button>
  </NavLink>
</div>
  )}

  {/* Optional 'Show All' or 'Show Less' functionality can be added here */}
  {/* <div className={classes.showAllButton} onClick={toggleShowAllPeople}>
    {showAllPeople ? 'Show Less' : 'Show All'}
  </div> */}
</Card>

          {!isDataEmpty(pipelineFunnelData) ? (
            <Card>
            <CardBody>
              <CardTitle tag="h5">Transition Flow Rates</CardTitle>
              <p>Percentage of candidates that move onto the next stage.</p>
              {isDataEmpty(pipelineFunnelData) ? (
                <p>No Data To Display Yet</p>
              ) : (
                <Bar data={pipelineFunnelData} options={pipelineFunnelOptions} />
              )}
            </CardBody>
          </Card>
          ) : null}

          {!isDataEmpty(offerAcceptanceRateData) ? (
          <Card>
            <CardBody>
              <CardTitle tag="h5">Offer Acceptance Rate</CardTitle>
              {/* <p>Percentage of offers accepted vs declined.</p> */}
              {isDataEmpty(offerAcceptanceRateData) ? (
                <p>No Data To Display Yet</p>
              ) : (
                <Pie data={offerAcceptanceRateData} />
              )}
            </CardBody>
          </Card>

            ) : null}

       </Col>
      <Col sm="4">
      {!showAllPeople ? (
            <Card className={classes.card}>
              <CardHeader className={classes.columnTitle}>Latest Shortlist</CardHeader>
              {matchedCandidates.slice(0, showAllPeople ? matchedCandidates.length : 5).map((person, index) => (
                <div key={index} className={classes.personContainer}>
                  {candidateImages[person.id] ? (
                    <img
                      src={candidateImages[person.id]}
                      alt={person.firstName}
                      className={classes.personPicture}
                    />
                  ) : (
                    <div className={classes.initialsFallback}>
                      {getInitials(person.firstName, person.lastName)}
                    </div>
                  )}
                  <div className={classes.personDetails}>
                    <h4>{person.firstName} {person.lastName}</h4>
                    <p>{person.jobTitle}</p>
                  </div>
                </div>
              ))}
            </Card>  
      ) : null}

            {!isDataEmpty(chartData.sourceOfHireBarChart) ? (
            <Card className={classes.equalHeight}>
                <CardBody>
                  <CardTitle tag="h5">Source of Acquisition</CardTitle>
                  {isDataEmpty(chartData.sourceOfHireBarChart) ? (
                    <p>No Data To Display Yet</p>
                  ) : (
                    <Pie data={chartData.sourceOfHireBarChart} />
                  )}
                </CardBody>
              </Card>
               ) : null}

            {!isDataEmpty(interviewToOfferRatioData) ? (
              <Card>
            <CardBody>
              <CardTitle tag="h5">Interview-to-Offer Ratio</CardTitle>
              <p>How many interviews on average before offer.</p>
              {isDataEmpty(interviewToOfferRatioData) ? (
                <p>No Data To Display Yet</p>
              ) : (
                <Bar data={interviewToOfferRatioData} options={interviewToOfferRatioOptions} />
              )}
            </CardBody>
          </Card>
            ) : null}

        {/* {!isDataEmpty(recruiterPerformanceData) ? (
          <Card>
            <CardBody>
              <CardTitle tag="h5">Performance</CardTitle>
              <p>Percentage of interview from source.</p>
              {isDataEmpty(recruiterPerformanceData) ? (
                <p>No Data To Display Yet</p>
              ) : (
                <Bar data={recruiterPerformanceData} options={pipelineFunnelOptions}/>
              )}
            </CardBody>
          </Card>
        ) : null} */}





      </Col>

      <Col sm="4">

      {!isDataEmpty(pipelineFunnelData2) ? (
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Statistics</CardTitle>
                  <p>Total candidates at each stage.</p>
                  {isDataEmpty(pipelineFunnelData2) ? (
                    <p>No Data To Display Yet</p>
                  ) : (
                    <Bar data={pipelineFunnelData2} options={pipelineFunnelOptions2} />
                  )}
                </CardBody>
              </Card>
      ) : null}

            {!isDataEmpty(chartData.pieChartData) ? (

              <Card className={classes.equalHeight}>
                <CardBody>
                  <CardTitle tag="h5">Timesheet Status</CardTitle>
                  {isDataEmpty(chartData.pieChartData) ? (
                    <p>No Data To Display Yet</p>
                  ) : (
                    <Pie data={chartData.pieChartData} />
                  )}
                </CardBody>
              </Card>

                ) : null}
        {!isDataEmpty(timeToHireData) ? (        
          <Card>
            <CardBody>
              <CardTitle tag="h5">Time-to-Fill</CardTitle>
              <p>Monitoring the average time from creation to contract.</p>
              {isDataEmpty(timeToHireData) ? (
                <p>No Data To Display Yet</p>
              ) : (
                <Line data={timeToHireData} options={pipelineFunnelOptions2}/>
              )}
            </CardBody>
          </Card>
        ) : null}

      {!isDataEmpty(monthlyLineChartData) ? (
              <Card className={classes.equalHeight}>
            <CardBody>
              <CardTitle tag="h5">Monthly Hiring</CardTitle>
              <p>Number of contracts offered per month.</p>
              {isDataEmpty(monthlyLineChartData) ? (
                <p>No Data To Display Yet</p>
              ) : (
                <Line data={monthlyLineChartData} options={pipelineFunnelOptions2} />
              )}
            </CardBody>
          </Card>

            ) : null}

        {/* {!isDataEmpty(candidateDemographicsData) ? (            
          <Card>
            <CardBody>
              <CardTitle tag="h5">Candidate Shortlisting</CardTitle>
              <p>Percentage of shortlist per type of application.</p>
              {isDataEmpty(candidateDemographicsData) ? (
                <p>No Data To Display Yet</p>
              ) : (
                <Radar data={candidateDemographicsData} options={candidateDemographicsOptions} />
              )}
            </CardBody>
          </Card>
        ) : null} */}

        </Col>
    

      {/* Second Column: My Jobs */}
      {/* <Col sm="4">
        <h2 className={classes.columnTitle}>My Jobs</h2>
        {jobData.slice(0, showAllJobs ? jobData.length : 3).map((job, index) => (
          <div key={index} className={classes.jobContainer}>
            <h4>{job.title}</h4>
            <p className={classes.jobTime}>{job.timeSinceCreation}</p>
          </div>
        ))}
        <Button color="primary" onClick={toggleShowAllJobs}>
          {showAllJobs ? 'Show Less' : 'Show All'}
        </Button>
      </Col>

      {/* Third Column: Actions 
      <Col sm="4">
        <h2 className={classes.columnTitle}>Actions</h2>
        <ul className={classes.actionsList}>
          {actionsData.map((action, index) => (
            <li key={index} className={classes.actionItem}>{action}</li>
          ))}
        </ul>
      </Col> */}
    </Row>
 
    </Container>
  </Fragment>
);

});

export default Dashboard;
