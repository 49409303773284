import {
  ICandidatesList,
  IJob,
  IJobOffers,
  ITimesheet,
  IDeclinedCandidates,
  IAllCandidatesList,
} from ".";
import { IUser } from "../User";

export const GET_SETTING_REQUEST = "GET_SETTING_REQUEST";

export const GET_JOB_OF_LISTING_REQUEST = "GET_JOB_OF_LISTING_REQUEST";

export const GET_DECLINED_CANDIDATES_REQUEST =
  "GET_DECLINED_CANDIDATES_REQUEST";

export const GET_JOB_LIST_REQUEST = "GET_JOB_LIST_REQUEST";

export const GET_JOB_TEMPLATES_LIST_REQUEST = "GET_JOB_TEMPLATES_LIST_REQUEST";

export const GET_JOB_CLIENT_LIST_REQUEST = "GET_JOB_CLIENT_LIST_REQUEST";

export const GET_ALL_CANDIDATE_LIST_REQUEST = "GET_ALL_CANDIDATE_LIST_REQUEST";

export const GET_ALL_CLIENT_CANDIDATE_LIST_REQUEST = "GET_ALL_CLIENT_CANDIDATE_LIST_REQUEST";

export const GET_JOB_OFFERS_REQUEST = "GET_JOB_OFFERS_REQUEST";

export const GET_ALL_TIMESHEETS_EMPLOYER_REQUEST =
  "GET_ALL_TIMESHEETS_EMPLOYER_REQUEST";

export const GET_ALL_TIMESHEETS_APPROVER_REQUEST =
"GET_ALL_TIMESHEETS_APPROVER_REQUEST";
  
export const GET_ALL_INTERVIEWS_EMPLOYER_REQUEST =
"GET_ALL_INTERVIEWS_EMPLOYER_REQUEST";

export const GET_ALL_CLIENT_INTERVIEWS_EMPLOYER_REQUEST =
"GET_ALL_CLIENT_INTERVIEWS_EMPLOYER_REQUEST";

export const GET_ALL_CHATS_REQUEST = "GET_ALL_CHATS_REQUEST";

export const GET_TALENTS_REQUEST = "GET_TALENTS_REQUEST";

export const GET_ALL_TALENTS_REQUEST = "GET_ALL_TALENTS_REQUEST";

export const SET_SETTING_REDUCER = "SET_SETTING_REDUCER";

export const SET_JOB_OF_LISTING_REDUCER = "SET_JOB_OF_LISTING_REDUCER";

export const SET_DECLINED_CANDIDATES_REDUCER =
  "SET_DECLINED_CANDIDATES_REDUCER";

export const SET_JOB_LIST_REDUCER = "SET_JOB_LIST_REDUCER";

export const SET_JOB_TEMPLATES_LIST_REDUCER= "SET_JOB_TEMPLATES_LIST_REDUCER";

export const SET_JOB_CLIENT_LIST_REDUCER = "SET_JOB_CLIENT_LIST_REDUCER";

export const SET_CANDIDATES_LIST_REDUCER = "SET_CANDIDATES_LIST_REDUCER";

export const SET_ALL_CLIENT_CANDIDATES_LIST_REDUCER = "SET_ALL_CLIENT_CANDIDATES_LIST_REDUCER";

export const SET_ALL_CANDIDATES_LIST_REDUCER = "SET_ALL_CANDIDATES_LIST_REDUCER";

export const SET_JOB_OFFERS_REDUCER = "SET_JOB_OFFERS_REDUCER";

export const SET_ALL_TIMESHEETS_EMPLOYER_REDUCER =
  "SET_ALL_TIMESHEETS_EMPLOYER_REDUCER";

  export const SET_ALL_TIMESHEETS_APPROVER_REDUCER =
  "SET_ALL_TIMESHEETS_APPROVER_REDUCER";
  
export const SET_ALL_INTERVIEWS_EMPLOYER_REDUCER =
"SET_ALL_INTERVIEWS_EMPLOYER_REDUCER";


export const SET_ALL_CLIENT_INTERVIEWS_EMPLOYER_REDUCER =
"SET_ALL_CLIENT_INTERVIEWS_EMPLOYER_REDUCER";

export const SET_ALL_CHATS_REDUCER = "SET_ALL_CHATS_REDUCER";

export const SET_TALENTS_REDUCER = "SET_TALENTS_REDUCER";

export const getSetting = () => {
  return {
    type: GET_SETTING_REQUEST,
  };
};

export const getJobOfListing = () => {
  return {
    type: GET_JOB_OF_LISTING_REQUEST,
  };
};

export const getDeclinedCandidates = (jobId: number) => {
  return {
    type: GET_DECLINED_CANDIDATES_REQUEST,
  };
};

export const getJobList = () => {
  return {
    type: GET_JOB_LIST_REQUEST,
  };
};

export const getJobTemplatesList = () => {
  return {
    type: GET_JOB_TEMPLATES_LIST_REQUEST,
  };
};




export const getJobClientList = () => {
  return {
    type: GET_JOB_CLIENT_LIST_REQUEST,
  };
};

export const getAllCandidateList = () => {
  return {
    type: GET_ALL_CANDIDATE_LIST_REQUEST,
  };
};


export const getAllClientCandidateList = () => {
  return {
    type: GET_ALL_CLIENT_CANDIDATE_LIST_REQUEST,
  };
};
export const getTalentList = () => {
  return {
    type: GET_TALENTS_REQUEST,
  };
};

export const requestGetTalentList = () => {
  return {
    type: GET_ALL_TALENTS_REQUEST,
  };
};

export const getJobOffers = () => {
  return {
    type: GET_JOB_OFFERS_REQUEST,
  };
};

export const getAllTimesheets = (employerId: number) => {
  return {
    type: GET_ALL_TIMESHEETS_EMPLOYER_REQUEST,
    employerId,
  };
};

export const getAllApproverTimesheets = (employerId: number, approverEmail: string) => {
  return {
    type: GET_ALL_TIMESHEETS_APPROVER_REQUEST,
    employerId,
    approverEmail
  };
};

export const getInterviewOffers = () => {
  return {
    type: GET_ALL_INTERVIEWS_EMPLOYER_REQUEST,
  };
};

export const getClientInterviewOffers = () => {
  return {
    type: SET_ALL_CLIENT_INTERVIEWS_EMPLOYER_REDUCER,
  };
};



// export const getAllChats = (user: IUser, exceptionChatId = null) => {
//   return {
//     type: GET_ALL_CHATS_REQUEST,
//     user,
//     exceptionChatId,
//   };
// };

export const setSettingReducer = (data: any) => {
  return {
    type: SET_SETTING_REDUCER,
    data: data,
  };
};

export const setJobOfListingReducer = (data: IJob) => {
  return {
    type: SET_JOB_OF_LISTING_REDUCER,
    data: data,
  };
};

export const setDeclinedCandidatesReducer = (data: IDeclinedCandidates) => {
  return {
    type: SET_DECLINED_CANDIDATES_REDUCER,
    data: data,
  };
};

export const setJobListReducer = (data: IJob[]) => {
  return {
    type: SET_JOB_LIST_REDUCER, 
    data: data,
  };
};

export const setJobTemplatesListReducer = (data: IJob[]) => {
  return {
    type: SET_JOB_TEMPLATES_LIST_REDUCER, 
    data: data,
  };
};


export const setJobClientListReducer = (data: IJob[]) => {
  return {
    type: SET_JOB_CLIENT_LIST_REDUCER,
    data: data,
  };
};

export const setCandidateListReducer = (data: IAllCandidatesList[]) => {
  return {
    type: SET_ALL_CANDIDATES_LIST_REDUCER,
    data: data,
  };
};

export const setClientCandidateListReducer = (data: IAllCandidatesList[]) => {
  return {
    type: SET_ALL_CLIENT_CANDIDATES_LIST_REDUCER,
    data: data,
  };
};

export const setJobOffersReducer = (data: IJobOffers[]) => {
  return {
    type: SET_JOB_OFFERS_REDUCER,
    data: data,
  };
};

export const setCandidatesReducer = (data: ICandidatesList) => {

  return {
    type: SET_CANDIDATES_LIST_REDUCER,
    data: data,
  };
};

export const setClientCandidatesReducer = (data: ICandidatesList) => {

  return {
    type: SET_ALL_CLIENT_CANDIDATES_LIST_REDUCER,
    data: data,
  };
};

export const setAllTimesheetsReducer = (data: ITimesheet[]) => {
  return {
    type: SET_ALL_TIMESHEETS_EMPLOYER_REDUCER,
    data: data,
  };
};

export const setAllTimesheetsApproverReducer = (data: ITimesheet[]) => {
  return {
    type: SET_ALL_TIMESHEETS_APPROVER_REDUCER,
    data: data,
  };
};

export const setClientInterviewOffersReducer = (data: ITimesheet[]) => {
  return {
    type: SET_ALL_CLIENT_INTERVIEWS_EMPLOYER_REDUCER,
    data: data,
  };
};

export const setInterviewOffersReducer = (data: ITimesheet[]) => {
  return {
    type: SET_ALL_INTERVIEWS_EMPLOYER_REDUCER,
    data: data,
  };
};



export const setAllChatsReducer = (data: any) => {
  return {
    type: SET_ALL_CHATS_REDUCER,
    data: data,
  };
};

export const setTalentReducer = (data: any) => {
  return {
    type: SET_TALENTS_REDUCER,
    data: data,
  };
};