import { API } from "aws-amplify";

export class JobOfferService {
  static async getAllOffers(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/connectoffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
      
  static async getOneOffer(id, offerId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/connectoffers/${offerId}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
//this one is for necta assist to use to force an offer sent and approve without emails
  static async updateOfferStatus(offerId, updateData) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffer/${offerId}/forcedoffer`;
    const myInit = {
      body: updateData,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  

  static async acceptOffer(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffer/${offerId}/accept`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async revoke(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffer/${offerId}/revoke`;
    const myInit = {
      response: true,
      body: data,
    };
    // console.log('Sending data:', data);
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async shortlist(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffer/${offerId}/shortlist`;
    const myInit = {
      response: true,
      body: data,
    };
    console.log('Sending data:', data);
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }



//   static async forcedAcceptOffer(offerId, contractorId, jobId, employerId) {
//     const apiName = process.env.REACT_APP_API_NAME;
//     const path = `/connectoffer/${offerId}/accept`;
//     const myInit = {
//         response: true,
//         body: { 
//             contractor: contractorId,
//             job: jobId,
//             employer: employerId
//         }
//     };
//     return API.put(apiName, path, myInit)
//         .then((res) => {
//             return Promise.resolve(res.data);
//         })
//         .catch((e) => {
//             return Promise.reject(e?.response?.data);
//         });
// }



  static async rejectOffer(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffer/${offerId}/reject`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllOffersJobs(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/jobs`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getOneOfferJob(id, jobId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/jobs/${jobId}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Interview offer
  static async getAllInterviewOffers(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/interviewoffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async rejectInterviewOffer(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/interviewoffer/${id}/reject`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async acceptInterviewOffer(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/interviewoffer/${id}/accept`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Contract offer

  static async getAllContractJobOffers(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/joboffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async rejectContractJobOffer(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/joboffer/${id}/reject`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async acceptContractJobOffer(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/joboffer/${id}/accept`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Get employer profile
  static async getEmployerProfile(employerId: number): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${employerId}/profile`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async interviewContractorDeclined(id: number, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${id}/offerdeclinedemail`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
