import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Badge,
  Input,
  FormGroup,
  Label,
  Form
} from 'reactstrap';
import * as yup from 'yup';
import classes from "./styles.module.scss";
import CustomTextarea from 'components/CustomTextarea';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import GanttChart from 'components/Common/charts/ganttChart';
import { ProjectService } from 'services/Employer/Listing/Project';
import { JobService } from 'services/Employer/Listing/Job';
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchLocationInput from "components/Common/GoogleAddress";
import { ReducerType } from "redux/reducers";
import CustomSelect from 'components/Common/CustomSelect';
import { PreferencePolicyTypes } from 'models/Contractor/NewMember/Preference';
import {
  getJobList,
} from "redux/reducers/Employer/actionTypes";
import { setSettingReducer, getSetting } from "redux/reducers/Employer/actionTypes";

export default function CreateProject({ onClose }) {
  const dispatch = useDispatch();
  const { setting } = useSelector((state: ReducerType) => state.employer);

  interface ResourceDetails {
    positionTitle: string;
    positionDescription: string;
    duration: number;
    startOffset: number;
    quantity: number;
    cost: number;
  }

  const [resources, setResources] = useState<ResourceDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newResourceDetails, setNewResourceDetails] = useState<ResourceDetails>({
    positionTitle: '',
    positionDescription: '',
    duration: 0,
    startOffset: 0,
    quantity: 0,
    cost: 0,
  });
  const [showForm, setShowForm] = useState(true);
  const [internalPositions, setInternalPositions] = useState([]);
  const [showAddResourceForm, setShowAddResourceForm] = useState(false);
  const [showAddCost, setShowAddCost] = useState(false);
  const [editingResourceIndex, setEditingResourceIndex] = useState(null);

  const schema = useMemo(() => {
    return yup.object().shape({
      projectTitle: yup.string().required("Project title is required"),
      endDate: yup.date().required("End date is required"),
      address: yup.string().required("Location is required"),
      projectScope: yup.string().required("Project scope and objectives are required"),
    });
  }, []);

  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    register('city');
    register('region');
    register('country');
    register('projectTitle');
    register('projectScope');
  }, [register]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewResourceDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAddResource = () => {
    setShowAddResourceForm(!showAddResourceForm)
    const newResource = {
      ...newResourceDetails,
      duration: parseInt(newResourceDetails.duration.toString(), 10),
      quantity: parseInt(newResourceDetails.quantity.toString(), 10),
      cost: parseFloat(newResourceDetails.cost.toString()),
      startOffset: parseInt(newResourceDetails.startOffset.toString(), 10),
    };
    setResources([newResource, ...resources]);
    setNewResourceDetails({
      positionTitle: '',
      positionDescription: '',
      duration: 0,
      quantity: 0,
      cost: 0,
      startOffset: 0,
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (showForm) {
      try {
        const response = await JobService.postProject(data);
        setResources(response.resources);
      } catch (error) {
        console.error('Error creating project', error);
      } finally {
        setShowForm(false);
        setIsLoading(false);
      }
    } else {
      const startDate = calculateStartDate();
      data.startDate = new Date(startDate);
      await postJobsForResources(resources, data, setting);
      triggerGitHubAction();
      dispatch(getJobList());
      if (onClose) {
        onClose();
      }
      setIsLoading(false);
    }
  };

  const postJobsForResources = async (resources, projectData, setting) => {
    setIsLoading(true);
    const jobsPosted = resources.map(async (resource) => {
      const jobData = {
        title: resource.positionTitle || "example",
        isContract: true,
        isPermanent: false,
        isFixed: false,
        organization: setting?.idOrganisation,
        companyName: setting?.name,
        city: projectData.city,
        country: projectData.country,
        region: projectData.region,
        headhunter: true,
        maxRate: resource.cost,
        startDate: new Date(projectData.startDate.getTime() + (resource.startOffset * 30 * 24 * 60 * 60 * 1000)).toISOString(),
        workPolicy: projectData.policy?.value,
        experienceYears: 7,
        description: projectData?.projectScope,
        responsibilities: [
          resource.positionDescription,
          projectData.projectTechnologies && `Project Technologies: ${projectData.projectTechnologies}`,
          projectData.projectIntegrations && `Project Integrations: ${projectData.projectIntegrations}`,
        ].filter(Boolean).join('. ') + '.',
        visaType: 'NZWorkVisa',
        listings: true,
        private: false,
      };

      try {
        const response = await JobService.postJob(jobData);
        const send = { job_id: response.id, region: jobData?.region?.toLowerCase() };
        JobService.headHunter(send);
        return response;
      } catch (error) {
        console.error(`Error posting job for ${resource.positionTitle}`, error);
        return null;
      }
    });

    try {
      const results = await Promise.all(jobsPosted);
    } catch (error) {
      console.error('Error posting one or more jobs', error);
    } finally {
      setIsLoading(false);
    }
  };

  const triggerGitHubAction = async () => {
    const githubApiUrl = 'https://api.github.com/repos/Surge-NZ/necta/dispatches';
    const token = 'YOUR_GITHUB_TOKEN'; // Replace with your actual GitHub token

    const payload = {
      event_type: 'listings',
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/vnd.github.v3+json',
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(githubApiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('GitHub Actions trigger failed');
      }
    } catch (error) {
      console.error('Error triggering GitHub Actions:', error);
    }
  };

  const toggleInternalStatus = (positionTitle) => {
    setInternalPositions((prev) =>
      prev.includes(positionTitle) ? prev.filter((title) => title !== positionTitle) : [...prev, positionTitle]
    );
  };

  const endDateValue = watch("endDate");
  const handleDateChange = (date) => {
    setValue('endDate', date);
  };

  const calculateStartDate = () => {
    const maxDuration = Math.max(...resources.map((resource) => resource.duration));
    const startDate = new Date(endDateValue);
    startDate.setMonth(startDate.getMonth() - maxDuration);
    return startDate.toLocaleDateString('en-NZ', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const endDateFormatted = endDateValue
    ? new Date(endDateValue).toLocaleDateString('en-NZ', { day: '2-digit', month: '2-digit', year: 'numeric' })
    : 'Not set';

  const calculateTotalCost = () => {
    return resources
      .reduce((total, resource) => {
        if (!internalPositions.includes(resource.positionTitle)) {
          return total + resource.cost * 40 * 4 * resource.duration * resource.quantity;
        }
        return total;
      }, 0)
      .toLocaleString('en-US', { maximumFractionDigits: 0 });
  };

  const calculateJobOffers = () => {
    return resources.filter((resource) => !internalPositions.includes(resource.positionTitle)).length;
  };

  const handleEditChange = (e, index, field) => {
    const updatedResources = [...resources];
    updatedResources[index][field] = e.target.value;
    setResources(updatedResources);
  };

  const updateResource = (index) => {
    setEditingResourceIndex(null);
  };

  const removeResource = (indexToRemove) => {
    setResources((currentResources) => currentResources.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className={classes.body}>
      {showForm ? (
        <div>
          <p className={classes.title}>Create New Project</p>
         
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p>Our AI is currently only set up for technology-based projects.</p>
            <Button color="danger" onClick={onClose}>Close</Button>
            <CustomTextarea
              placeholder="Project Title"
              autoComplete="off"
              inputRef={register('projectTitle')}
              errorMessage={errors.projectTitle?.message}
              tooltipText='Enter a Project Title that represents the end outcome.'
            />
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <SearchLocationInput
                  {...field}
                  ref={null}
                  placeholder="Projects nearest town or city"
                  errorMessage={errors.address?.message}
                  value={field.value}
                  onChange={(value) => {
                    setValue("city", value?.address?.city);
                    setValue("region", value?.address?.region);
                    setValue("country", value?.address?.country);
                    return field.onChange(value?.address?.formattedAddress);
                  }}
                />
              )}
            />
            <CustomTextarea
              autoComplete="off"
              placeholder="Project Scope and Objectives"
              inputRef={register('projectScope')}
              errorMessage={errors.projectScope?.message}
              tooltipText='You can enter in objectives, outcomes and scope here. You can even copy and paste a link of functional and non-functional requirements.'
            />
            <CustomDatePicker
              placeholder="Enter Your Go Live Date"
              control={control}
              dateFormat="dd/MM/yyyy"
              name="endDate"
              inputRef={register('endDate')}
              selected={endDateValue ? new Date(endDateValue) : null}
              onChange={handleDateChange}
              errorMessage={errors.endDate?.message}
            />
            <CustomSelect
              placeholder='Policy'
              name='policy'
              control={control}
              options={PreferencePolicyTypes}
              tooltipText='Choose "Office" if you prefer contractors solely in the Office. Opt for "Hybrid" if you would like to negotiate a mix of remote and office work. Select "Work From Home" if you exclusively want Remote Workers. All signifies openness to any work arrangement based on mutual agreement.'
            />
            <CustomTextarea placeholder="Technologies and Frameworks" {...register('projectTechnologies')} errorMessage={errors.projectTechnologies?.message} tooltipText='If you know what technology you wish to use, place it here.' />
            <CustomTextarea placeholder="Integration Requirements" {...register('projectIntegrations')} errorMessage={errors.projectIntegrations?.message} tooltipText='If you have known requirements for integrations, place them here. i.e AWS or Azure cloud' />
            <CustomTextarea placeholder="Security and Compliance Standards" {...register('projectSecurity')} errorMessage={errors.projectSecurity?.message} tooltipText='If there is any specific compliance or security measures, note them.' />
            <CustomTextarea placeholder="Testing and QA Requirements" {...register('projectQA')} errorMessage={errors.projectQA?.message} tooltipText='What level of testing do you require?' />
            <div className="d-flex justify-content-between">
              <Button color="secondary" onClick={onClose}>Back</Button>
              <Button color="primary" type="submit" disabled={isLoading}>Next</Button>
            </div>
          </Form>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <Button color="primary" onClick={() => setShowAddResourceForm(!showAddResourceForm)}>Add Resource</Button>
            <Button color="success" onClick={() => setShowAddCost(!showAddCost)}>Get Costs</Button>
          </div>
          {showAddResourceForm && (
            <FormGroup>
              <Label for="positionTitle">Position Title</Label>
              <Input id="positionTitle" type="text" name="positionTitle" value={newResourceDetails.positionTitle} onChange={handleInputChange} placeholder="Enter Position Title" />
              <Label for="positionDescription">Position Description</Label>
              <Input id="positionDescription" type="text" name="positionDescription" value={newResourceDetails.positionDescription} onChange={handleInputChange} placeholder="Enter Position Description" />
              <Label for="duration">Duration (months)</Label>
              <Input id="duration" type="number" name="duration" value={newResourceDetails.duration} onChange={handleInputChange} placeholder="Enter Duration in Months" />
              <Label for="quantity">Quantity</Label>
              <Input id="quantity" type="number" name="quantity" value={newResourceDetails.quantity} onChange={handleInputChange} placeholder="Enter Quantity" />
              <Button color="primary" onClick={handleAddResource}>Add</Button>
            </FormGroup>
          )}
          {showAddCost && (
            <Card>
              <CardBody>
                <CardTitle tag="h5">Project Summary</CardTitle>
                <CardText><strong>Start Date:</strong> {calculateStartDate()}</CardText>
                <CardText><strong>End Date:</strong> {endDateFormatted}</CardText>
                <CardText><strong>Total Project Cost:</strong> ${calculateTotalCost()}</CardText>
                <CardText><strong>Total Number of Positions:</strong> {calculateJobOffers()}</CardText>
                <CardText><strong>Project Timeline:</strong></CardText>
                <GanttChart resources={resources} />
              </CardBody>
            </Card>
          )}
          <CardText>
            When you click OK, Necta will list all your opportunities and automatically shortlist active/passive candidates. You can edit each individual position below, delete, or set as person not required because it's handled internally. After, you can edit the individual opportunities on the dashboard.
          </CardText>
          {resources.map((resource, index) => (
            <Card key={index} style={{ marginBottom: '1rem', opacity: internalPositions.includes(resource.positionTitle) ? 0.5 : 1 }}>
              <CardBody>
                <FontAwesomeIcon icon={faTimes} onClick={() => removeResource(index)} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
                <CardTitle tag="h5">{resource.positionTitle}</CardTitle>
                <CardText>{resource.positionDescription}</CardText>
                <CardText>
                  Duration: <Badge color="success">{resource.duration} months</Badge><br />
                  Resource: <Badge color="success">{resource.quantity} {resource.positionTitle}{resource.quantity > 1 ? 's' : ''}</Badge><br />
                  {showAddCost && (
                    <>
                      Total Cost: <Badge color="info">${new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(resource.cost * 40 * 4 * resource.duration * resource.quantity)}</Badge><br />
                      Average: <Badge color="danger">${resource.cost} p.h</Badge>
                    </>
                  )}
                </CardText>
                <Button color={internalPositions.includes(resource.positionTitle) ? 'secondary' : 'primary'} onClick={() => toggleInternalStatus(resource.positionTitle)}>
                  {internalPositions.includes(resource.positionTitle) ? 'Set as External' : 'Set as Internal'}
                </Button>
                <Button color="info" onClick={() => setEditingResourceIndex(index)}>Edit</Button>
                {editingResourceIndex === index && (
                  <div>
                    <h5>Edit Resource</h5>
                    <FormGroup>
                      <Label for="editPositionTitle">Position Title</Label>
                      <Input id="editPositionTitle" type="text" value={resources[editingResourceIndex]?.positionTitle} onChange={(e) => handleEditChange(e, editingResourceIndex, 'positionTitle')} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="editPositionDescription">Position Description</Label>
                      <Input id="editPositionDescription" type="text" value={resources[editingResourceIndex]?.positionDescription} onChange={(e) => handleEditChange(e, editingResourceIndex, 'positionDescription')} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="editQuantity">Quantity</Label>
                      <Input id="editQuantity" type="number" value={resources[editingResourceIndex]?.quantity.toString()} onChange={(e) => handleEditChange(e, editingResourceIndex, 'quantity')} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="editDuration">Duration (months)</Label>
                      <Input id="editDuration" type="number" value={resources[editingResourceIndex]?.duration.toString()} onChange={(e) => handleEditChange(e, editingResourceIndex, 'duration')} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="editStartOffset">Start Offset (months from project start)</Label>
                      <Input id="editStartOffset" type="number" value={resources[editingResourceIndex]?.startOffset.toString()} onChange={(e) => handleEditChange(e, editingResourceIndex, 'startOffset')} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="editCost">Hourly Cost</Label>
                      <Input id="editCost" type="number" value={resources[editingResourceIndex]?.cost.toString()} onChange={(e) => handleEditChange(e, editingResourceIndex, 'cost')} />
                    </FormGroup>
                    <Button color="primary" onClick={() => updateResource(editingResourceIndex)}>Save Changes</Button>
                  </div>
                )}
              </CardBody>
            </Card>
          ))}
          <div className="d-flex justify-content-between">
            <Button color="secondary" onClick={() => setShowForm(true)}>Back to Project Details</Button>
            <Button color="primary" onClick={handleSubmit(onSubmit)} disabled={isLoading}>OK</Button>
          </div>
        </div>
      )}
    </div>
  );
}
