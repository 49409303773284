import { useState } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import classes from '../styles.module.scss';
import Check_circle from 'components/Common/Icons/Check_circle'

const Message = ({ message, user, info }) => {
  const { content, created, userId, read } = message || {};
  const [isHovering, setIsHovering] = useState(false);

  const messageDate = new Date(created);
  const currentDate = new Date();
  let formattedTime;
  const isLatestRead = read && messageDate > new Date(read);
  // if the message is from today
  if (messageDate.toDateString() === currentDate.toDateString()) {
    formattedTime = messageDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  } else if (messageDate.getFullYear() === currentDate.getFullYear()) {
    // if the message is from the current year
    formattedTime = messageDate.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    // if the message is from a different year
    formattedTime = messageDate.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  return (
    <Row
      className={
        userId === user.id ? classes.rightAlignedListItem : classes.listItem
      }
    >
      <Col className={classes.messageAvatarContainer}>
        {userId !== user.id && (
          <>
            <img
                className={classes.avatar}
                src={
                    info && (user.type === 'contractor' ? info.employerAvatarUrl : info.candidateAvatarUrl)
                        ? user.type === 'contractor'
                          ? info.employerAvatarUrl
                          : info.candidateAvatarUrl
                        : 'https://www.app.necta.nz/static/media/logo.62ed95804f7ff2f63dd7.png'
                    }
                alt='Profile'
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              />
            {/* {isHovering && <UncontrolledTooltip>{user.type === 'contractor' ? info.employer_name : info.candidate_name}</UncontrolledTooltip>} */}
          </>
        )}
      </Col>
      <Col style={{ display: 'flex', justifyContent: userId === user.id ? 'flex-end' : 'flex-start' }}>
  <div style={{ display: "inline-block", width: 'fit-content' }}>
    <Row>
      <Col className={classes.messageSender}>
        <div
          className={
            userId === user.id
              ? classes.messageTimeRight
              : classes.messageTime
          }
        >
          {formattedTime}
        </div>
      </Col>
    </Row>
    <Row>
      <Col
        className={
          userId === user.id
            ? classes.messageContentRight
            : classes.messageContent
        }
      >
        {content}
      </Col>
    </Row>
  </div>
</Col> 
  
      <Col className={classes.messageAvatarContainer}>
        {/* {isLatestRead && ( */}
          <>
            {userId === user.id && read && (
              // <img
              //   className={classes.tinyavatar}
              //   src='https://www.app.necta.nz/static/media/logo.62ed95804f7ff2f63dd7.png'
              //   alt='Read'
              // />
              <Check_circle />
            )}
          
          </>
        {/* )} */}
      </Col>
    </Row>

  );
};

export default Message;
