import { API } from "aws-amplify";

export class ContractorSettingService {
  static async getContractorSettingService (id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${id}/settings`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postContractorSettingService (id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${id}/settings`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putContractorSettingService (id, settingsId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${id}/settings/${settingsId}`;
    const myInit = {
      response: true,
      body: data
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
