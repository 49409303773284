//The ModalProvider is mapping over the modals from the Redux state and for each modal,
// it's passing the filename as a prop to LazyComponent. 
//LazyComponent is then using this filename to dynamically 
//import the corresponding modal component.

import { useSelector } from 'react-redux';
import { LazyComponent } from './LazyComponent';
import { getOpenModalsList } from '../../redux/store/selector';

interface IModalProviderProps {
  children: React.ReactNode;
}

export function ModalProvider(props: IModalProviderProps) {
  const modals = useSelector(getOpenModalsList);

  // console.log('ModalProvider is being rendered', modals);
  
  return (
    <>
      {modals.map((filename) => (
        <LazyComponent key={filename} filename={filename} />
      ))}
      {props.children}
    </>
  );
}
