import { all } from "redux-saga/effects";
//import getAllChats from "./getAllChats";
import getAllExpenses from "./getAllExpenses";
import getAllOffers from "./getAllOffers";
import getAllTimesheets from "./getAllTimesheets";
import getProfile from "./getProfile";

export const contractorSagas = function* root() {
  // yield all([getProfile(), getAllOffers(), getAllTimesheets(), getAllChats(), getAllExpenses()]);
  yield all([getProfile(), getAllOffers(), getAllTimesheets(), getAllExpenses()]);
};
