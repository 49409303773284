import { Chart } from "react-google-charts";

const generateGanttChartData = (resources) => {
  const chartData = [
    [
      { type: 'string', label: 'Task ID' },
      { type: 'string', label: 'Task Name' },
      { type: 'string', label: 'Resource' },
      { type: 'date', label: 'Start Date' },
      { type: 'date', label: 'End Date' },
      { type: 'number', label: 'Duration' },
      { type: 'number', label: 'Percent Complete' },
      { type: 'string', label: 'Dependencies' },
    ],
  ];

  // Assuming you have a project start date, you can define it here
  // For example, let's assume the project starts on January 1st, 2023
  const projectStartDate = new Date(2024, 5, 1); // January 1st, 2023

  resources.forEach((resource, index) => {
    const startDate = new Date(projectStartDate.getTime());
    // Adjust the start date based on the startOffset
    startDate.setMonth(startDate.getMonth() + resource.startOffset);

    const endDate = new Date(startDate.getTime());
    // End date based on the duration of the task
    endDate.setMonth(endDate.getMonth() + resource.duration);

    chartData.push([
      `Task ${index}`,
      resource.positionTitle,
      null, // Resource name not used in this simple example
      startDate,
      endDate,
      null, // Duration automatically calculated by the chart
      100, // Assuming task is completed for the example
      null, // Assuming no dependencies for simplicity
    ]);
  });

  return chartData;
};


const GanttChart = ({ resources }) => {
  return (
    <Chart
      chartType="Gantt"
      width="100%"
      height="400px"
      data={generateGanttChartData(resources)}
      options={{
        gantt: {
          trackHeight: 30,
          // Define your color scheme here
          palette: [
            {
              color: '#f96332', // Primary color for the bars
              dark: '#c55122', // Optionally, specify a darker shade for selected bars or details
              light: '#ffd8c2' // Optionally, a lighter shade for hover or additional detail
            },
            // You can define more colors if your chart has multiple types of tasks
          ],
        },
        // Additional options can be added here
      }}
    />
  );
};

export default GanttChart;