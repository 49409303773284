import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_ALL_CLIENT_INTERVIEWS_EMPLOYER_REQUEST,
  setClientInterviewOffersReducer,
} from "redux/reducers/Employer/actionTypes";
import { JobService } from "services/Employer/Manage/Job";

function* requestGetInterviewOffers() {
  try {
    yield put(setLoading(true));
    // console.log("ummm")
    const [interviewOffers] = yield all([call(JobService.getAllClientInterviewOfferOfAEmployer)]);
    yield put(setClientInterviewOffersReducer(interviewOffers));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getClientInterviewOffers() {
  yield takeLatest(GET_ALL_CLIENT_INTERVIEWS_EMPLOYER_REQUEST, requestGetInterviewOffers);
}

export default getClientInterviewOffers;
