import { JOB_TYPE } from "config/constants";

export interface TaxParams {
  id?: number;
  irdNumber?: string;
  jobOffer?: number;
  nameOnAccount?: string;
  bank?: string;
  branch?: string;
  account?: string;
  suffix?: string;
  gstNumber?: string;
  gstType?: string;
  taxCode?: string;
  userId?: number;
  isLimitedCompany?: boolean;
  companyName?: string;
  withholdingTax?: number;
  isPaye?: number;
  agreedToTerms?: number;
}

export enum EGstTypes {
  INVOICE_BASIS = "invoiceBasis",
  CASH_BASIS = "cashBasis",
}

export const GstTypes = [
  { id: 1, name: "Invoice Basis", value: "invoiceBasis" },
  { id: 2, name: "Cash Basis", value: "cashBasis" },
];

export const WithholdingTax = [
  { id: 1, name: "0%", value: 0 },
  { id: 2, name: "5%", value: 5 },
  { id: 3, name: "10%", value: 10 },
  { id: 4, name: "15%", value: 15 },
  { id: 5, name: "20%", value: 20 },
  { id: 6, name: "25%", value: 25 },
  { id: 7, name: "30%", value: 30 },
  { id: 8, name: "33%", value: 33 },
  { id: 9, name: "35%", value: 35 },
  { id: 10, name: "40%", value: 40 },
  { id: 11, name: "45%", value: 45 },
];

export const TaxTypes = [
  { id: 1, name: "PAYE", value: "paye" },
  { id: 2, name: "DIY", value: "diy" },
];

export const TaxCode = [
  { id: 1, name: "M", value: "M" },
  { id: 2, name: "M SL", value: "M SL" },
  { id: 3, name: "ME", value: "ME" },
  { id: 4, name: "ME SL", value: "ME SL" },
  { id: 5, name: "S", value: "S" },
  { id: 6, name: "S SL", value: "S SL" },
  { id: 7, name: "SH", value: "SH" },
  { id: 8, name: "SH SL", value: "SH SL" },
  { id: 9, name: "ST", value: "ST" },
  { id: 10, name: "ST SL", value: "ST SL" },
  { id: 11, name: "SB", value: "SB" },
  { id: 12, name: "SB SL", value: "SB SL" },
  { id: 13, name: "WT", value: "WT" },
  { id: 14, name: "CAE", value: "CAE" },
  { id: 15, name: "EDW", value: "EDW" },
  { id: 16, name: "NSW", value: "NSW" },
  { id: 17, name: "ND", value: "ND" },
];

export const gstFilingFrequency = [
  { id: 1, name: "Monthly", value: "monthly" },
  { id: 2, name: "Two-Monthly", value: "twoMonthly" },
  { id: 3, name: "Six-Monthly", value: "sixMonthly" },
];

export const gstStartingMonth = [
  { id: 1, name: "January", value: 1 },
  { id: 2, name: "February", value: 2 },
  { id: 3, name: "March", value: 3 },
  { id: 4, name: "April", value: 4 },
  { id: 5, name: "May", value: 5 },
  { id: 6, name: "June", value: 6 },
  { id: 7, name: "July", value: 7 },
  { id: 8, name: "August", value: 8 },
  { id: 9, name: "September", value: 9 },
  { id: 10, name: "October", value: 10 },
  { id: 11, name: "November", value: 11 },
  { id: 12, name: "December", value: 12 },
];

export const WorkTypes = [
  { id: 1, name: "Fixed", value: JOB_TYPE.FIXED },
  { id: 2, name: "Permanent", value: JOB_TYPE.PERMANENT },
  { id: 3, name: "Contract", value: JOB_TYPE.CONTRACT },
];
