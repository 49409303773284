import React, { useState } from 'react';
import clsx from 'clsx';
import { InputGroup, InputGroupAddon, InputGroupText, FormGroup, Input } from 'reactstrap';
import classes from './styles.module.scss';

const CustomDropdown = ({ title, options, selectedOption, onChange }) => {

  const handleSelectOption = (event) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <FormGroup className={clsx(classes.root)}>
      <InputGroup className={clsx(classes.centeredInputGroup)}>
        <Input type="select" value={selectedOption} onChange={handleSelectOption} className={clsx(classes.root)}>
          <option value="" disabled selected>{title}</option>
          {options.sort().map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </Input>
      </InputGroup>
    </FormGroup>
  );
};

export default CustomDropdown;
