import moment from "moment";
import jwt_decode from "jwt-decode";
import { Auth } from "aws-amplify";
import { removeAuthCredentials } from "services/LocalStorageService";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

class AmplifyAuthService {
  /**
   * login with strapi using email and password
   * @param {*} username
   * @param {*} password
   */
  async login(username, password) {
    try {
      const user = await Auth.signIn(username, password);
      localStorage.setItem(
        "jwt",
        user?.signInUserSession?.accessToken?.jwtToken
      );
      return user;
    } catch (error) {
      throw error;
    }
  }

  /**
   * login with google
   */
  loginGoogle() {
    try {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (error) {
      console.log("error signing with google in", error);
    }
  }

  /**
   * log out with amplify
   */
  async logout() {
    try {
      removeAuthCredentials();
      const response = await Auth.signOut();
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * login in to strapi with federated callback token
   * @param {*} provider
   * @param {*} token
   */
  async loginWithCode(token, provider) {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      localStorage.setItem(
        "jwt",
        user?.signInUserSession?.accessToken?.jwtToken
      );
      // const tokens = user && user.signInUserSession;
      // localStorage.setItem('tokens', JSON.stringify(tokens));
    } catch (error) {
      throw error;
    }
  }

  /**
   * get current users jwt token from the local storage
   * @returns
   */
  getCurrentUser() {
    try {
      const jwt = localStorage.getItem("jwt");
      const decoded: any = jwt_decode(jwt);
      if (moment().isBefore(moment(decoded.exp))) {
        return null;
      } else {
        return jwt;
      }
    } catch (e) {
      return null;
    }
  }
}

const amplifyAuthService = new AmplifyAuthService();

export default amplifyAuthService;
