import { Fragment, memo, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "reactstrap";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import classes from "./styles.module.scss";
import clsx from "clsx";
import FooterMobile from "components/Footers/FooterMobile";
import CustomSelect from "components/Common/CustomSelect";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import { BillService } from "services/Contractor/Bill/Bill";
import { XeroInvoiceService } from "services/Xero/XeroInvoice";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import DownloadGstReport from "./GstReport";

interface Props {}

const Report = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile, allExpenses } = useSelector(
    (state: ReducerType) => state.contractor
  );
  const reportList = [
    {
      id: 1,
      col1: "",
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
    {
      id: 2,
      col1: "",
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
    {
      id: 3,
      col1: "",
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
    {
      id: 4,
      col1: "",
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
    {
      id: 5,
      col1: "",
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
    {
      id: 6,
      col1: "",
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
    {
      id: 7,
      col1: "",
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
      col8: "",
      col9: "",
      col10: "",
      col11: "",
      col12: "",
    },
  ];
  const [reportOption, setReportOption] = useState([
    { id: 1, name: "Profit and Loss" },
    { id: 2, name: "GST report" },
    { id: 3, name: "Withholding tax Report" },
    { id: 4, name: "IR3" },
  ]);
  const [reportType, setReportType] = useState(reportOption[0]);
  const [startDate, setStartDate] = useState(new Date(2022, 0));
  const [endDate, setEndDate] = useState(new Date(2023, 0));
  const [modalDownloadGstReport, setModalDownloadGstReport] = useState({
    isOpenModal: false,
    data: null,
  });

  useEffect(() => {
    if (profile?.taxDetails?.isLimitedCompany) {
      setReportOption([
        { id: 1, name: "Profit and Loss" },
        { id: 2, name: "GST report" },
        { id: 3, name: "Withholding tax Report" },
        { id: 5, name: "IR4" },
      ]);
    }
  }, [profile]);

  const onCloseModalDownloadGstReport = () => {
    setModalDownloadGstReport({
      isOpenModal: false,
      data: null,
    });
  };

  const handleDownloadReport = () => {
    dispatch(setLoading(true));
    BillService.getAllContractorBill(user?.id).then((allBills) => {
      if (allBills?.length) {
        let _mainInvoiceList = [];
        const xeroBillIds = allBills.map((item) => item?.xeroBillId);
        XeroInvoiceService.getInvoicesByInvoiceIds(
          user?.id,
          xeroBillIds.join(", ")
        )
          .then((xeroInvoices) => {
            let totalOfInvoices = 0;
            let totalInvoiceWithoutGst = 0;
            let netOfIncome = 0;
            let totalGstCollected = 0;
            let totalGstOnSalesAndIncome = 0;

            let totalExpenses = 0;
            let GstPaid = 0;
            let totalGetPaid = 0;
            let totalGstToPaid = 0;

            if (xeroInvoices?.Invoices?.length) {
              xeroInvoices.Invoices.map((invoice) => {
                totalOfInvoices += invoice?.Total;
                totalInvoiceWithoutGst += invoice?.SubTotal;
                netOfIncome += invoice?.TotalTax;
                totalGstCollected += invoice?.TotalTax;
                totalGstOnSalesAndIncome += invoice?.TotalTax;
              });
            }
            allExpenses.map((expense) => {
              totalExpenses += Number(expense?.total);
              GstPaid += Number(expense?.total) - expense?.net;
              totalGetPaid += Number(expense?.total) - expense?.net;
            });
            totalGstToPaid = totalOfInvoices - totalExpenses;

            setModalDownloadGstReport({
              isOpenModal: true,
              data: {
                totalOfInvoices: totalOfInvoices,
                totalInvoiceWithoutGst: totalInvoiceWithoutGst,
                netOfIncome: netOfIncome,
                totalGstCollected: totalGstCollected,
                totalGstOnSalesAndIncome: totalGstOnSalesAndIncome,

                totalExpenses: totalExpenses,
                GstPaid: GstPaid,
                totalGetPaid: totalGetPaid,
                totalGstToPaid: totalGstToPaid,
              },
            });
          })
          .catch((error) => dispatch(setErrorMess(error)))
          .finally(() => dispatch(setLoading(false)));
      }
    });
  };

  return (
    <Fragment>
      <ScrollNavbar />
      <div
        className={clsx("page-header header-filter", classes.pageHeader)}
        filter-color="yellow"
      >
        <div className={classes.header} />
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project15.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.container}>
            <Card className={classes.card}>
              <p className={classes.title}>REPORT</p>
              <Row className="d-flex align-items-center mb-2">
                <Col
                  lg="2"
                  md="3 text-left"
                  className={classes.mobileHideTable}
                >
                  Select Report:
                </Col>
                <Col md="4">
                  <CustomSelect
                    className="mb-0"
                    placeholder="Select Report"
                    name="reportOption"
                    options={reportOption}
                    defaultValue={reportType}
                    onChange={(val) => setReportType(val as any)}
                  />
                </Col>
              </Row>
              <Row className="d-flex align-items-center mb-2">
                <Col
                  lg="2"
                  md="3 text-left"
                  className={classes.mobileHideTable}
                >
                  Start Date:
                </Col>
                <Col md="4">
                  <div className={classes.datePickerWrapper}>
                    <DatePicker
                      className={classes.datePickerInput}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>
                </Col>
              </Row>
              <Row className="d-flex align-items-center mb-2 mb-md-0">
                <Col
                  lg="2"
                  md="3 text-left"
                  className={classes.mobileHideTable}
                >
                  End Date:
                </Col>
                <Col md="4">
                  <div className={classes.datePickerWrapper}>
                    <DatePicker
                      className={classes.datePickerInput}
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end mr-1">
                <Button
                  color="primary"
                  className="mt-0"
                  onClick={() => handleDownloadReport()}
                >
                  EXPORT/DOWNLOAD
                </Button>
              </Row>
              <div className={classes.tableContainer}>
                <h4 className={classes.reportTitle}>{reportType?.name}</h4>
                <h5 className={classes.reportSubTitle}>
                  {moment(startDate).format("MMM YY")} -{" "}
                  {moment(endDate).format("MMM YY")}
                </h5>
                <Table className={classes.table} responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Column 1</th>
                      <th>Column 2</th>
                      <th>Column 3</th>
                      <th>Column 4</th>
                      <th>Column 5</th>
                      <th>Column 6</th>
                      <th>Column 7</th>
                      <th>Column 8</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList.map((item, index) => (
                      <tr key={item.id}>
                        <th scope="row">Row {index}</th>
                        <th>{item.col1}</th>
                        <td>{item.col2}</td>
                        <td>{item.col3}</td>
                        <td>{item.col4}</td>
                        <td>{item.col5}</td>
                        <td>{item.col6}</td>
                        <td>{item.col7}</td>
                        <td>{item.col8}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Container>
        </div>
        <Footer />
      </div>
      <FooterMobile />

      <DownloadGstReport
        onClose={onCloseModalDownloadGstReport}
        isOpen={modalDownloadGstReport.isOpenModal}
        data={modalDownloadGstReport.data}
      />
    </Fragment>
  );
});

export default Report;
