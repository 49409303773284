import { Fragment, memo, useState } from "react";
import {
  Form,
  Card,
  CardBody,
  Modal,
  Row,
  Col,
  ModalHeader,
  Button,
} from "reactstrap";
import classes from "./styles.module.scss";
import moment from "moment";
import TerminateContract from "./TerminateContract";

interface SendContractProps {
  onClose: () => void;
  isOpen: boolean;
  contractor?: any;
  contract?: any;
  job: any;
}

const SeeContract = memo(
  ({ onClose, isOpen, contractor, contract, job }: SendContractProps) => {
    const [modalTerminateContract, setModalTerminateContract] = useState({
      isOpenModal: false,
      contract: null,
    });

    const handleOpenTerminateContract = (contract) => {
      setModalTerminateContract({
        isOpenModal: true,
        contract: contract,
      });
    };
    const handleCloseTerminateContract = () => {
      setModalTerminateContract({
        isOpenModal: false,
        contract: null,
      });
    };

    return (
      <Fragment>
        <Card className={classes.card}>
          <Modal
            isOpen={isOpen}
            toggle={onClose}
            centered
            scrollable
            className={classes.modal}
          >
            <ModalHeader toggle={onClose} className={classes.modalHeader}>
              THE OFFER'S INFORMATION
            </ModalHeader>
            <Form>
              <CardBody className={classes.cardDateBody}>
                <Row className="mb-1">
                  <Col xs={12}>
                    <p className={classes.commonTitle}>
                      {contract?.offered && contract?.accepted
                        ? "The contract has been accepted!"
                        : contract?.offered && contract?.rejected
                        ? "The contract has been declined!"
                        : "The contract has not been responded yet!"}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    <p className={classes.commonTitle}>Name:</p>
                  </Col>
                  <Col xs={6}>
                    <p className={classes.commonTitle}>
                      {contractor?.firstName} {contractor?.lastName}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    <p className={classes.commonTitle}>Start date:</p>
                  </Col>
                  <Col xs={6}>
                    <p className={classes.commonTitle}>
                      {moment(contract?.startDate).format("DD/MM/YYYY")}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    <p className={classes.commonTitle}>End date:</p>
                  </Col>
                  <Col xs={6}>
                    <p className={classes.commonTitle}>
                      {moment(contract?.endDate).format("DD/MM/YYYY")}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    <p className={classes.commonTitle}>Rate offered:</p>
                  </Col>
                  <Col xs={6}>
                    <p className={classes.commonTitle}>
                      {contract?.rateOffered}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    <p className={classes.commonTitle}>Manager name:</p>
                  </Col>
                  <Col xs={6}>
                    <p className={classes.commonTitle}>
                      {contract?.managerName}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    <p className={classes.commonTitle}>Approval name:</p>
                  </Col>
                  <Col xs={6}>
                    <p className={classes.commonTitle}>
                      {contract?.approvalName}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    <p className={classes.commonTitle}>
                      Purchase Order Number:
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p className={classes.commonTitle}>
                      {contract?.purchaseOrderNumber}
                    </p>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center mt-2">
                  <Button
                    color="primary"
                    onClick={() => handleOpenTerminateContract(contract)}
                  >
                    GIVE NOTICE
                  </Button>
                </Row>
              </CardBody>
            </Form>
          </Modal>
        </Card>

        <TerminateContract
          isOpen={modalTerminateContract?.isOpenModal}
          onClose={handleCloseTerminateContract}
          contract={modalTerminateContract?.contract}
          onCloseContractModal={onClose}
        />
      </Fragment>
    );
  }
);

export default SeeContract;
