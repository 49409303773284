import { API } from "aws-amplify";

export class UserService {
  static async getUser() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/user";
    const myInit = {
      response: true,
    };
    return await API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putUser(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return await API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
