import { Slider } from '@mui/material';

import { memo, useState, useEffect  } from 'react';
import { Controller, FieldError, FieldErrors, Merge } from 'react-hook-form';
import clsx from 'clsx';
import classes from './styles.module.scss';
import { FormGroup } from 'reactstrap';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { styled } from '@mui/material/styles';
import MuiInput from '@mui/material/Input';
interface Props extends StateManagerProps {
  className?: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrors<any>>;
  name?: string;
  control?: any;
  min: number;
  max?: number;
  step?: number;
  defaultValue?: number;
  valueLabelDisplay?: 'on' | 'off' | 'auto';
  showPercentage?: boolean;
  isPercentage?: boolean;
  disabled?: boolean;
}
const Input = styled(MuiInput)`
  width: 42px;
`;
const CustomStyleSlider = styled(Slider)({
  color: '#f96332',
});
const CustomSlider = memo(
  ({
    className,
    name,
    control,
    defaultValue,
    min,
    max,
    step,
    errorMessage,
    valueLabelDisplay,
    showPercentage,
    isPercentage,
    disabled, // Default to false if not provided
    ...rest
  }: Props) => {
    const [currentValue, setCurrentValue] = useState<number | null>(defaultValue);
    useEffect(() => {
      // Reset value when defaultValue changes
      // console.log('defaultValue', defaultValue, currentValue)
      setCurrentValue(defaultValue);
    }, [defaultValue]);
    return (
      <FormGroup className={clsx(classes.root, className)}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            // console.log("Current value for " + name, field.value); 
            // const inputValue = `$${field.value || min}`; // Adding $ symbol
            const inputValue = isPercentage
              ? `${field.value || min}%`
              : `$${field.value || min}`; // Adding $ symbol only when isPercentage is false
            return (
              <div className={classes.sliderWrapper}>
                {typeof defaultValue !== 'undefined' ? (
                  // Original Code: Using internal state (currentValue)
                  <>
                <CustomStyleSlider
                  valueLabelDisplay={valueLabelDisplay || 'off'}
                  min={min}
                  max={max}
                  step={step}

                  defaultValue={defaultValue}
                  className={classes.slider}
                  disabled={disabled} 

                  // value={currentValue || min}
                  onChange={(_, value) => {
                    setCurrentValue(value as number);
                    field.onChange(value);
                  }}
                  getAriaValueText={(value: number) =>
                    `${value}${showPercentage ? '%' : '$'}`
                  }
                  valueLabelFormat={(value: number) =>
                    `${value}${showPercentage ? '%' : '$'}`
                  }
                />
                <Input
                // value={field.value || min}

                value={isPercentage ? `${currentValue || min}%` : `$${currentValue || min}`}
                size='small'
                disabled={disabled} 
                // onChange={(value) => field.onChange(value)}
                onChange={(e) => {
                  const value = e.target.value.replace(isPercentage ? '%' : '$', '');
                  const parsedValue = parseFloat(value);
                  
                  if (!isNaN(parsedValue)) {
                      setCurrentValue(parsedValue);
                      field.onChange(parsedValue);
                  } else {
                      setCurrentValue(null); // Set to null if the input is not a valid number
                  }
              }}
              onBlur={(e) => {
                  let parsedValue = parseFloat(e.target.value.replace(isPercentage ? '%' : '$', ''));
                  
                  if (isNaN(parsedValue)) {
                      parsedValue = min; // Default to min if the input is not a valid number
                  } else if (parsedValue < min) {
                      parsedValue = min;
                  } else if (parsedValue > max) {
                      parsedValue = max;
                  }
                  
                  setCurrentValue(parsedValue);
                  field.onChange(parsedValue);
              }}
              
                // inputProps={{
                //   step: step || 1,
                //   min: min,
                //   max: max,
                //   type: 'number',
                //   'aria-labelledby': 'input-slider',
                // }}
                inputProps={{
                  step: step || 1,
                  min: min,
                  max: max,
                  type: 'text',
                  'aria-labelledby': 'input-slider',
                }}
              />
              </>
                ) : (
                  // New Code: Directly using field.value
                  <>
              <CustomStyleSlider
                valueLabelDisplay={valueLabelDisplay || 'off'}
                min={min}
                max={max}
                step={step}
                className={classes.slider}
                disabled={disabled}
                value={field.value || min}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getAriaValueText={(value: number) => `${value}${showPercentage ? '%' : '$'}`}
                valueLabelFormat={(value: number) => `${value}${showPercentage ? '%' : '$'}`}
              />
                <Input
                  value={field.value || min}

                  // value={isPercentage ? `${currentValue || min}%` : `$${currentValue || min}`}
                  size='small'
                  disabled={disabled} 
                  onChange={(value) => field.onChange(value)}
                  // onChange={(e) => {
                  //   const value = e.target.value.replace(isPercentage ? '%' : '$', '');
                  //   const parsedValue = parseFloat(value);
                    
                //     if (!isNaN(parsedValue)) {
                //         setCurrentValue(parsedValue);
                //         field.onChange(parsedValue);
                //     } else {
                //         setCurrentValue(null); // Set to null if the input is not a valid number
                //     }
                // }}
                onBlur={(e) => {
                    let parsedValue = parseFloat(e.target.value.replace(isPercentage ? '%' : '$', ''));
                    
                    if (isNaN(parsedValue)) {
                        parsedValue = min; // Default to min if the input is not a valid number
                    } else if (parsedValue < min) {
                        parsedValue = min;
                    } else if (parsedValue > max) {
                        parsedValue = max;
                    }
                    
                    setCurrentValue(parsedValue);
                    field.onChange(parsedValue);
                }}
                
                  // inputProps={{
                  //   step: step || 1,
                  //   min: min,
                  //   max: max,
                  //   type: 'number',
                  //   'aria-labelledby': 'input-slider',
                  // }}
                  inputProps={{
                    step: step || 1,
                    min: min,
                    max: max,
                    type: 'text',
                    'aria-labelledby': 'input-slider',
                  }}
                />
              </>
              )}

              </div>
            );
          }}
        />
        {errorMessage && (
          <span className='text-danger ml-2 mt-1 d-block'>{errorMessage}</span>
        )}
      </FormGroup>
    );
  }
);
export default CustomSlider;