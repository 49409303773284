import { API, Storage } from "aws-amplify";

export class JobService {
  static async getAllSentContracts() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/joboffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorProfileOfJobOffer(jobId, contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/contractors/${contractorId}/profile`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllInterviewOfferOfAEmployer() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/interviewoffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        // console.log("inview", res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllClientInterviewOfferOfAEmployer() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/interviewoffers/client`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        // console.log("inview", res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
  static async getAllOffersForOneJob(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}/connectoffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
  static async getAllConnectOffers() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
  static async getAllInterviewOfferOfAJob(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}/interviewoffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async extendContractEmailToContractor(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${id}/extendofferemail`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
