import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Button,
} from "reactstrap";
import ContractorButton from "components/Common/ContractorButton";
import Logo from "assets/img/logo.png";
import routes, { routesOutside } from "routers/routes";
import classes from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import authFactory from "services/authService/AuthFactory";
import { setUserLogoutReducer } from "redux/reducers/User/actionTypes";
import { push } from "connected-react-router";
import clsx from "clsx";
import { USER_TYPE } from "config/constants";
import { useWebSocket } from 'services/Message/User/WebSocketProvider.js';

interface Props {}

const DropdownScrollNavbar = memo((props: Props) => {
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);
  const { isOffersStillExist, isHaveNewMessage } = useSelector(
    (state: ReducerType) => state.status
  );
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [navbarColor, setNavbarColor] = useState(" navbar-transparent");
  const dispatch = useDispatch();
  const { hasUnreadMessages, clearHasUnreadMessages } = useWebSocket();

  // useEffect(() => {
  //                     console.log('Has Unread Messages:', hasUnreadMessages);
  //                 }, [hasUnreadMessages]);
   
 useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 10 ||
        document.body.scrollTop > 10
      ) {
        setNavbarColor("");
      } else {
        setNavbarColor(" navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return () => {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, [dispatch]);

  const handleCollapseNavbar = () => {
    if (window.innerWidth <= 991) {
        const navOpenClass = classes['nav-open']; // Accessing class from the module
        document.documentElement.classList.toggle(navOpenClass);
        setCollapseOpen(!collapseOpen);
    }
};

  const handleSignOut = async () => {
    dispatch(setLoading(true));
    await authFactory
      .generate()
      .logout()
      .then(() => {
        dispatch(setUserLogoutReducer());
        dispatch(push(routes.contractor.login));
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <>
      {collapseOpen && <div id="bodyClick" onClick={handleCollapseNavbar} />}
      <Navbar
        className={clsx("fixed-top", `${classes['navbar-absolute']} ${classes['navbar-transparent']}`, classes.navbar)}
        color="white"
        expand="lg"
      >
        <Container>
        <div className={classes['navbar-translate']}>
        <NavbarBrand 
              to="/" tag={Link} 
              id="navbar-brand" 
              className={classes['navbar-brand']}>
              <img src={Logo} alt="Necta Logo" width={50} className="mr-3" />
              <span className={classes.logoWord}>Necta</span>
            </NavbarBrand>
              <button
                onClick={handleCollapseNavbar} 
                aria-expanded={collapseOpen}
                className={clsx(classes["navbar-toggler"])}
              >
              <span className={clsx(classes["navbar-toggler-bar"], classes["top-bar"])}></span>
              <span className={clsx(classes["navbar-toggler-bar"], classes["middle-bar"])}></span>
              <span className={clsx(classes["navbar-toggler-bar"], classes["bottom-bar"])}></span>
              <div
                className={
                  isOffersStillExist || isHaveNewMessage
                    ? clsx(classes.navbarMenuDot)
                    : clsx(classes.navbarMenuDot, "d-none")
                }
              ></div>
            </button>
          </div>
          <Collapse
            isOpen={collapseOpen}
            navbar
            className={classes['navbar-collapse']}
          >
            <Nav className={clsx("ml-auto", classes['navbar-nav'])} id="ceva" navbar>
            {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  color="default"
                  id="navbarDropdownMenuLink"
                  nav
                  to={routes.contractor.listing.dashboard}
                  tag={Link}
                  onClick={() => {
                    handleCollapseNavbar();
                    clearHasUnreadMessages();
                  }}
                >
                  <i aria-hidden={true} 

                  className={clsx("now-ui-icons business_briefcase-24", classes['icon-badge'], classes['now-ui-icons'])}>
                  <div
                      className={
                        hasUnreadMessages > 0
                          ? clsx(classes.offersDot)
                          : clsx(classes.offersDot, "d-none")
                      }
                    ></div>
                  </i>
                  <p>Dashboard</p>
                </DropdownToggle>
              </UncontrolledDropdown> */}
              <UncontrolledDropdown nav>
              {user && (
                <DropdownToggle
                  className={classes["dropdown-menu"]}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i
                    aria-hidden={true}
                    className={clsx("now-ui-icons users_circle-08", classes['icon-badge'], classes['now-ui-icons'])}
                  />

                  <p>Profile</p>
                </DropdownToggle>
              )}
                <DropdownMenu
                  aria-labelledby="navbarDropdownMenuLink1"
                  right
                  className={classes.dropdownMenu}
                >
                  <DropdownItem
                    className={classes["dropdown-item"]}
                    to={routes.contractor.profile}
                    tag={Link}
                    onClick={handleCollapseNavbar}
                  >
                    <i
                    aria-hidden={true}
                    className={clsx("now-ui-icons users_single-02", classes['icon-badge'], classes['now-ui-icons'])}
                  />
                    My Profile
                  </DropdownItem>
                  <DropdownItem
                    className={classes["dropdown-item"]}
                    to={routes.contractor.setting}
                    tag={Link}
                    onClick={handleCollapseNavbar}
                  >
                    <i className={clsx("now-ui-icons ui-1_settings-gear-63", classes['icon-badge'], classes['now-ui-icons'])} />
                    Settings
                  </DropdownItem>
                  {user && (
                    <DropdownItem
                      className={classes["dropdown-item"]}
                      onClick={() => {
                        handleCollapseNavbar();
                        handleSignOut();
                      }}
                    >
                      
                      <i className={clsx("now-ui-icons design_bullet-list-67", classes['icon-badge'], classes['now-ui-icons'])} />
                      Logout
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav>
              {user && (
                <DropdownToggle
                  color="default"
                  id="navbarDropdownMenuLink"
                  nav
                  to={routes.contractor.messenger}
                  tag={Link}
                  onClick={() => {
                    handleCollapseNavbar();
                    clearHasUnreadMessages();
                  }}
                >
                  <i aria-hidden={true} className={clsx("now-ui-icons ui-2_chat-round", classes['icon-badge'], classes['now-ui-icons'])}>
                    
                  <div
                      className={
                        hasUnreadMessages > 0
                          ? clsx(classes.offersDot)
                          : clsx(classes.offersDot, "d-none")
                      }
                    ></div>
                  </i>
                  <p>Messages</p>
                </DropdownToggle>
              )}
              </UncontrolledDropdown>

              {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  color="default"
                  id="navbarDropdownMenuLink"
                  nav
                  to={routes.contractor.jobs}
                  tag={Link}
                  onClick={handleCollapseNavbar}
                >
                  <i
                    aria-hidden={true}
                    className={clsx(
                      "now-ui-icons business_badge",
                      classes.offersIcon
                    )}
                  >
                     <div
                      className={
                        isOffersStillExist
                          ? clsx(classes.offersDot)
                          : clsx(classes.offersDot, "d-none")
                      }
                    ></div> 
                  </i>
                  <p>Jobs</p>
                </DropdownToggle>
              </UncontrolledDropdown>*/}

              <UncontrolledDropdown nav>
              {user && (
                <DropdownToggle
                  className={classes["nav-item"]}
                  color="default"
                  id="navbarDropdownMenuLink"
                  nav
                  to={routes.contractor.offer}
                  tag={Link}
                  onClick={handleCollapseNavbar}
                >
                  <i aria-hidden={true} className={clsx("now-ui-icons business_briefcase-24", classes['icon-badge'], classes['now-ui-icons'], classes.offersIcon)}>
                    <div
                      className={
                        isOffersStillExist
                          ? clsx(classes.offersDot)
                          : clsx(classes.offersDot, "d-none")
                      }
                    ></div>
                  </i>
                  <p>Offers</p>
                </DropdownToggle>
              )}
              </UncontrolledDropdown>

              <UncontrolledDropdown nav>
              {user && (
                <DropdownToggle
                  color="default"
                  id="navbarDropdownMenuLink"
                  nav
                  to={routes.contractor.timesheet.root}
                  tag={Link}
                  onClick={handleCollapseNavbar}
                >
                  <i aria-hidden={true} className={clsx("now-ui-icons shopping_credit-card", classes['icon-badge'], classes['now-ui-icons'])}></i>
                  <p>Timesheets</p>
                </DropdownToggle>
              )}
              </UncontrolledDropdown>

              {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                >
                  <i
                    aria-hidden={true}
                    className="now-ui-icons design-2_ruler-pencil"
                  />
                  <p>Books</p>
                </DropdownToggle>
                <DropdownMenu
                  aria-labelledby="navbarDropdownMenuLink"
                  right
                  className={classes.dropdownMenu}
                >
                  <DropdownItem
                    tag={Link}
                    to={routes.contractor.dashboard}
                    onClick={handleCollapseNavbar}
                  >
                    <i className="now-ui-icons business_briefcase-24" />
                    Dashboard
                  </DropdownItem>
                  <DropdownItem
                    tag={Link}
                    to={routes.contractor.invoice.root}
                    onClick={handleCollapseNavbar}
                  >
                    <i className="now-ui-icons design_bullet-list-67" />
                    Invoices
                  </DropdownItem>
                  <DropdownItem
                    tag={Link}
                    to={routes.contractor.expense.root}
                    onClick={handleCollapseNavbar}
                  >
                    <i className="now-ui-icons business_money-coins" />
                    Expenses
                  </DropdownItem>
                  <DropdownItem
                    tag={Link}
                    to={routes.contractor.payslip}
                    onClick={handleCollapseNavbar}
                  >
                    <i className="now-ui-icons files_paper" />
                    Payslips
                  </DropdownItem>
                  <DropdownItem
                    tag={Link}
                    to={routes.contractor.report}
                    onClick={handleCollapseNavbar}
                    className={classes.hideOnMobile}
                  >
                    <i className="now-ui-icons education_paper" />
                    Reports
                  </DropdownItem>
                  {profile &&
                    profile?.taxDetails &&
                    profile?.taxDetails?.isPaye && (
                      <DropdownItem
                        tag={Link}
                        to={routes.contractor.payroll}
                        onClick={handleCollapseNavbar}
                      >
                        <i className="now-ui-icons business_bank" />
                        Payroll
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown> */}

              <UncontrolledDropdown nav className={classes.other}>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                  tag={Link}
                  to={routes.contractor.documentHistory}
                  onClick={handleCollapseNavbar}
                >
                  <i aria-hidden={true} className={clsx("now-ui-icons objects_spaceship", classes['icon-badge'], classes['now-ui-icons'])}></i>

                  <p>Document History</p>
                </DropdownToggle>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav className={classes.other}>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                  tag="a"
                  href={
                    window?.location?.pathname?.split("/")[1] ===
                    USER_TYPE.CONTRACTOR
                      ? routesOutside.contractorBlog
                      : routesOutside.employerBlog
                  }
                  onClick={handleCollapseNavbar}
                >
                                    <i aria-hidden={true} className={clsx("now-ui-icons text_align-left", classes['icon-badge'], classes['now-ui-icons'])}></i>

                  <p>Blog</p>
                </DropdownToggle>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav className={classes.other}>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                  tag="a"
                  href={routesOutside.aboutUs}
                  onClick={handleCollapseNavbar}
                >
                <i aria-hidden={true} className={clsx("now-ui-icons business_bulb-63", classes['icon-badge'], classes['now-ui-icons'])}></i>

                  <p>About Us</p>
                </DropdownToggle>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav className={classes.other}>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                  tag="a"
                  href={routesOutside.policy}
                  onClick={handleCollapseNavbar}
                >
                <i aria-hidden={true} className={clsx("now-ui-icons objects_globe", classes['icon-badge'], classes['now-ui-icons'])}></i>
                  <p>Policies</p>
                </DropdownToggle>
              </UncontrolledDropdown>
              <NavItem>
              {user && (
                // <ContractorButton />
                <Button
                className={clsx("nav-link btn-default ml-3", classes['nav-link'], classes['btn'])}
                color="primary"
                onClick={handleSignOut}
              >
                <p>Sign Out</p>
              </Button>
              )}
              {!user && (
                <Button
                  className={clsx("nav-link btn-default ml-3", classes['nav-link'], classes['btn'])}
                  color="primary"
                  onClick={() => dispatch(push(routes.employer.login))}
                >
                  <p>Employer</p>
                </Button>
              )}

              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
});

export default DropdownScrollNavbar;
