export const SET_LOADING_REDUCER = "SET_LOADING_REDUCER";
export const SET_LOADING_AUTH_REDUCER = "SET_LOADING_AUTH_REDUCER";
export const SET_ERROR_REDUCER: string = "SET_ERROR_REDUCER";
export const SET_SUCCESS_REDUCER: string = "SET_SUCCESS_REDUCER";
export const SET_WELCOME_REDUCER: string = "SET_WELCOME_REDUCER";
export const SET_OFFERS_STILL_EXIST_REDUCER = "SET_OFFERS_STILL_EXIST_REDUCER";
export const SET_OFFERS_TO_CONNECT_STILL_EXIST_REDUCER = "SET_OFFERS_TO_CONNECT_STILL_EXIST_REDUCER";
export const SET_OFFERS_TO_INTERVIEW_STILL_EXIST_REDUCER = "SET_OFFERS_TO_INTERVIEW_STILL_EXIST_REDUCER";
export const SET_CONTRACTUAL_OFFERS_STILL_EXIST_REDUCER = "SET_CONTRACTUAL_OFFERS_STILL_EXIST_REDUCER";
export const SET_IS_HAVE_NEW_MESSAGE_REDUCER = "SET_IS_HAVE_NEW_MESSAGE_REDUCER";

export const setLoading = (isLoading: boolean) => {
  return {
    type: SET_LOADING_REDUCER,
    isLoading,
  };
};

export const setLoadingAuth = (isLoading: boolean) => {
  return {
    type: SET_LOADING_AUTH_REDUCER,
    isLoading,
  };
};

export const setSuccessMess = (mess: string) => {
  return {
    type: SET_SUCCESS_REDUCER,
    success: mess,
  };
};

export const setErrorMess = (e: any) => {
  return {
    type: SET_ERROR_REDUCER,
    error: e.detail || e.message || e.error || "Unknown error",
  };
};

export const clearErrorMess = (e: any) => {
  return {
    type: SET_ERROR_REDUCER,
    error: undefined,
  };
};

export const setWelcome = (isWelcome: any) => {
  return {
    type: SET_WELCOME_REDUCER,
    isWelcome,
  };
};

export const setOffersStillExist = (isOffersStillExist: boolean) => {
  return {
    type: SET_OFFERS_STILL_EXIST_REDUCER,
    isOffersStillExist,
  };
};

export const setOffersToConnectStillExist = (isOffersToConnectStillExist: boolean) => {
  return {
    type: SET_OFFERS_TO_CONNECT_STILL_EXIST_REDUCER,
    isOffersToConnectStillExist,
  };
};

export const setOffersToInterviewStillExist = (isOffersToInterviewStillExist: boolean) => {
  return {
    type: SET_OFFERS_TO_INTERVIEW_STILL_EXIST_REDUCER,
    isOffersToInterviewStillExist,
  };
};

export const setContractualOffersStillExist = (isContractualOffersStillExist: boolean) => {
  return {
    type: SET_CONTRACTUAL_OFFERS_STILL_EXIST_REDUCER,
    isContractualOffersStillExist,
  };
};

export const setIsHaveNewMessage = (isHaveNewMessage: boolean) => {
  return {
    type: SET_IS_HAVE_NEW_MESSAGE_REDUCER,
    isHaveNewMessage,
  };
};
