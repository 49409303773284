/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, useEffect, useState, useContext } from "react";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { Button, Form } from "reactstrap";
// import {
//   ChatEngine,
//   sendMessage,
//   ChatEngineContext,
//   ChatFeed,
// } from "react-chat-engine";
import { ReducerType } from "redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setLoading } from "redux/reducers/Status/actionTypes";
import CustomInput from "components/Common/CustomInput";
//import { getAllChats } from "redux/reducers/Employer/actionTypes";
import ChatApp from "components/ChatApp/ChatApp";

interface MessengerProps {}

const Messenger = memo((props: MessengerProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { userOfChat } = useSelector((state: ReducerType) => state.message);

  const [showDescription, setShowDescription] = useState(true);
  //const { activeChat, setActiveChat } = useContext<any>(ChatEngineContext);
  const [firstRender, setFirstRender] = useState(true);
  const [contractor, setContractor] = useState(null);


  return (
    <div className={clsx(classes.chatWrapper)}>
      <ChatApp /> 
    </div>
  );
});

export default Messenger;
