import React, { useEffect, forwardRef, useImperativeHandle } from 'react';

// Declare the global FB variable and its types
declare global {
  interface Window {
    FB: any;
  }
}

const FacebookLogin = forwardRef((props, ref) => {
  useEffect(() => {
    // Load the Facebook SDK for JavaScript
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '426842330383396',  // Your Facebook app ID
        cookie: true,
        xfbml: true,
        version: 'v20.0',  // Facebook API version
      });

      window.FB.AppEvents.logPageView();
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  // Expose handleFacebookLogin via ref to parent component
  useImperativeHandle(ref, () => ({
    handleFacebookLogin: () => {
      window.FB.login(function (response) {
        if (response.authResponse) {
          console.log('You are logged in:', response);
          // Handle successful login response here
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, { scope: 'email,public_profile,pages_manage_posts,pages_show_list' });
    }
  }));

  return null; // No UI element to render
});

export default FacebookLogin;
