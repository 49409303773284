import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_ALL_TIMESHEETS_APPROVER_REQUEST,
  setAllTimesheetsApproverReducer,
} from "redux/reducers/Employer/actionTypes";
import { EmployerTimesheetService } from "services/Employer/Timesheet/Timesheet";

function* requestGetAllTimesheetsApprover(data: { type: string; employerId: number, approverEmail: string }) {
  try {
    yield put(setLoading(true));
    const [timesheets] = yield all([
      call(EmployerTimesheetService.getAllTimesheetApprover, data?.employerId, data?.approverEmail),
    ]);
    yield put(setAllTimesheetsApproverReducer(timesheets));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getAllTimesheetsApprover() {
  yield takeLatest(GET_ALL_TIMESHEETS_APPROVER_REQUEST, requestGetAllTimesheetsApprover);
}

export default getAllTimesheetsApprover;
