import { memo, useEffect, useMemo, useState } from 'react';
import { Form, Button, Card, CardBody, Row, Col } from 'reactstrap';
import classes from './styles.module.scss';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/Common/CustomInput';
import CustomMultiSelect from 'components/Common/CustomMultiSelect';
import CustomSelect from 'components/Common/CustomSelect';
import CustomTextarea from 'components/CustomTextarea';
import CustomHtml from 'components/CustomHtml';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMess, setLoading, setSuccessMess } from 'redux/reducers/Status/actionTypes';
import SearchLocationInput from 'components/Common/GoogleAddress';
import CustomTagsInput from 'components/Common/CustomTagsInput';
import { JobTypes, NumberOfExperience } from 'models/Employer/Listings/Dashboard';
import { PreferencePolicyTypes } from 'models/Contractor/NewMember/Preference';
import ArchiveWarningModal from './PopupDeleteWarning';
import { JobService } from 'services/Employer/Listing/Job';
import { ReducerType } from 'redux/reducers';
import { setJobListReducer } from 'redux/reducers/Employer/actionTypes';
import CustomSlider from 'components/Common/CustomSlider';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import moment from 'moment';
import { VisaTypes } from 'models/Contractor/NewMember/MojCheck';
import { downloadJD } from 'components/docxtemplater/downloadJD';
import CustomCheckboxFlip from 'components/Common/CustomCheckBoxFlip';
import CustomCheckbox from 'components/Common/CustomCheckbox';



function processSelectedVisaTypes(selectedOptions) {
  if (!selectedOptions || selectedOptions.length === 0) {
    return null;
  }

  const hasCitizen = selectedOptions.some((option) => option.value === "NZAUSCitizen");
  const hasPermanentResident = selectedOptions.some((option) => option.value === "NZPermanentVisa");
  const hasWorkVisa = selectedOptions.some((option) => option.value === "NZWorkVisa");
  const hasOther = selectedOptions.some((option) => option.value === "Other");

  if (hasOther) {
    return "Other";
  } else if (hasWorkVisa) {
    return "NZWorkVisa";
  } else if (hasPermanentResident) {
    return "NZPermanentVisa";
  } else if (hasCitizen) {
    return "NZAUSCitizen";
  } else {
    return null;
  }
}

interface OptionType {
  value: string;
  label: string;
}
interface Props {
  onClose: () => void;
  isOpen: boolean;
  itemEdit: any;
  handleAddJob: (data: any) => void;
  handleUpdateJob: (data: any, id: any) => void;
}
interface IClientOption {
  value: string;
  label: string;
}

const AddNewJob = memo(
  ({ onClose, isOpen, itemEdit, handleAddJob, handleUpdateJob }: Props) => {
    const dispatch = useDispatch();
    const { setting, jobList } = useSelector((state: ReducerType) => state.employer);
    const { user } = useSelector((state: ReducerType) => state.user);
    const [warningModalOpen, setWarningModalOpen] = useState({ isOpen: false, item: null });
    const [selectedClient, setSelectedClient] = useState(null);
    const [isPercentage, setIsPercentage] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);
    const [isPayrollIncluded, setIsPayrollIncluded] = useState(false);
    const [clients, setClients] = useState([]);
    const [error, setError] = useState(null);
    const [selectedClientObject, setSelectedClientObject] = useState(null);
    const [fullClientDetails, setFullClientDetails] = useState(null);

    const handleCheckboxChange = (isChecked: boolean) => {
      setIsPercentage(isChecked);
    };

    const handlePayrollCheckboxChange = (isChecked: boolean) => {
      setIsPayrollIncluded(isChecked);
    };

    const [isListings, setIsListings] = useState(false);
    const handleListingsChange = (isChecked: boolean) => {
      setIsListings(isChecked);
    };

    const [isPrivate, setIsPrivate] = useState(false);
    const handlePrivateChange = (isChecked: boolean) => {
      setIsPrivate(isChecked);
    };

    const [onBehalf, setOnBehalf] = useState<string>('No');

    const schema = useMemo(() => {
      return yup.object().shape({
        id: yup.number(),
        title: yup.string().min(2, 'At least 2 characters').max(64, 'At least 64 characters').required('This field is required'),
        email: yup.string().email('Please enter a valid email').nullable().notRequired(),
        jobType: yup.object().nullable().required('This field is required'),
        company: yup.string().required('This field is required'),
        hourlyRate: yup.number().typeError('Please enter a valid value'),
        startDate: yup.date().typeError('Please enter a valid date').required('This field is required'),
        policy: yup.object().nullable().required('This field is required'),
        numberOfExperience: yup.object().nullable().required('This field is required'),
        skill: yup.array().of(yup.string()),
        description: yup.string().max(5000, 'Maximum 5000 characters').required('This field is required'),
        responsibilities: yup.string().max(5000, 'Maximum 5000 characters').required('This field is required'),
        address: yup.string().required('This field is required'),
        country: yup.string(),
        city: yup.string(),
        region: yup.string(),
      });
    }, []);

    const { register, handleSubmit, formState: { errors }, reset, setValue, watch, control } = useForm({
      resolver: yupResolver(schema),
      mode: 'onChange',
    });

    const jobTypeField = watch('jobType');

    const [htmlContent, setHtmlContent] = useState('');

    const handleContentChange = (content: string) => {
      setHtmlContent(content);
    };

    const onSubmit = (data: any) => {
      console.log(data);
      const selectedVisaType = processSelectedVisaTypes(data.visaType);
      const rate = data?.hourlyRate;
      let salary = 0;
      if (data?.jobType?.id === 1) {
        salary = rate === undefined ? 30 : (rate > 300 ? 300 : rate);
      } else {
        salary = (rate === undefined || rate < 40000) ? 40000 : rate;
      }
      let chargeAmount;
      if (isPercentage) {
        chargeAmount = data.percentageOffered;
      } else {
        chargeAmount = data.fixedPriceOffer;
      }
      const clientData = {
        firstName: chosenRawClientData?.firstName,
        lastName: chosenRawClientData?.lastName,
        email: chosenRawClientData?.email,
        phone: chosenRawClientData?.phone,
        company: chosenRawClientData?.company,
        fixedPrice: !isPercentage,
        chargeAmount: chargeAmount,
        payrollInclusive: isPayrollIncluded,
        stage: "lead"
      };

      const submitJobData = (clientId: any) => {
        const jobData = {
          title: data?.title,
          client: clientId,
          isContract: data?.jobType?.id === 1,
          isPermanent: data?.jobType?.id === 2,
          isFixed: data?.jobType?.id === 3,
          city: data?.city,
          country: data?.country,
          region: data?.region,
          maxRate: data?.jobType?.id === 1 ? salary : null,
          maxSalary: data?.jobType?.id !== 1 ? salary : null,
          startDate: data?.startDate?.toISOString(),
          workPolicy: data?.policy?.value,
          technicalSkills: data?.skill,
          experienceYears: data?.numberOfExperience ? NumberOfExperience[data.numberOfExperience.id - 1].value : null,
          description: data?.description,
          responsibilities: data?.responsibilities,
          visaType: selectedVisaType,
          listings: !isListings,
          private: isPrivate,
          organization: setting?.idOrganisation,
          ...(onBehalf === 'No' && { companyName: data?.company || setting?.name }),
        };

        if (!itemEdit) {
          handleAddJob(jobData);
        } else {
          handleUpdateJob(jobData, itemEdit?.id);
        }
      };

      if (selectedClientObject?.value === "new") {
        fetch('https://j9z0sb36kk.execute-api.ap-southeast-2.amazonaws.com/prod/assistcreateprofile/user', {
          method: 'POST',
          body: JSON.stringify({
            email: clientData?.email,
            username: clientData?.email
          }),
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(userResponse => {
            const clientDataWithUsername = {
              ...clientData,
              username: userResponse.userid
            };
            return JobService.postClient(clientDataWithUsername);
          })
          .then(response => {
            const newClientId = response.id;
            submitJobData(newClientId);
          })
          .catch(error => console.error('Error:', error));
      } else if (selectedClientObject) {
        const clientId = parseInt(selectedClientObject.value);
        JobService.putClient(clientData, clientId)
          .then(response => submitJobData(clientId))
          .catch(error => console.error('Error updating client:', error));
      } else {
        submitJobData(selectedClientObject ? selectedClientObject.value : null);
      }

      onClose();
    };

    useEffect(() => {
      if (selectedClientObject && selectedClientObject.value === 'new') {
        setFullClientDetails(null);
      }
    }, [selectedClientObject]);

    const [rawClientData, setRawClientData] = useState([]);
    const [chosenRawClientData, setChosenRawClientData] = useState(null);
    const updateChosenRawClientData = (updatedData: any) => {
      setChosenRawClientData({ ...chosenRawClientData, ...updatedData });
    };

    useEffect(() => {
      JobService.getClient()
        .then(data => {
          setRawClientData(data);
          const mappedClients = data.map((client: any) => ({
            value: client.id.toString(),
            label: `${client.firstName} ${client.lastName} | ${client.company}`
          }));
          setClients(mappedClients);
        })
        .catch(error => {
          console.error("Error fetching clients:", error);
        });
      if (!isOpen) {
        reset({
          title: '',
          jobType: null,
          company: setting?.name,
          region: '',
          city: '',
          country: '',
          address: '',
          hourlyRate: '',
          startDate: null,
          policy: null,
          numberOfExperience: null,
          skill: [],
          description: '',
          responsibilities: '',
          visaType: null,
        });
      } else {
        if (itemEdit) {
          let selectedClientObject;

          if (itemEdit?.client) {
            selectedClientObject = clients.find(client => client.value === itemEdit.client.toString());
          } else {
            selectedClientObject = null;
          }

          reset({
            id: itemEdit?.id,
            title: itemEdit?.title,
            jobType: itemEdit?.isContract ? JobTypes[0] : itemEdit?.isPermanent ? JobTypes[1] : itemEdit?.isFixed ? JobTypes[2] : null,
            company: itemEdit?.company,
            email: itemEdit?.email,
            address: itemEdit?.region && itemEdit?.country ? itemEdit?.city ? `${itemEdit?.city}, ${itemEdit?.region}, ${itemEdit?.country}` : `${itemEdit?.region}, ${itemEdit?.country}` : '',
            hourlyRate: itemEdit?.maxSalary || itemEdit?.maxRate,
            startDate: new Date(itemEdit?.startDate),
            policy: itemEdit?.workPolicy === 'office' ? PreferencePolicyTypes[0] : itemEdit?.workPolicy === 'mixed' ? PreferencePolicyTypes[1] : itemEdit?.workPolicy === 'all' ? PreferencePolicyTypes[3] : PreferencePolicyTypes[2],
            numberOfExperience: itemEdit?.experienceYears === NumberOfExperience[0].value ? NumberOfExperience[0] : itemEdit?.experienceYears === NumberOfExperience[1].value ? NumberOfExperience[1] : NumberOfExperience[2].value,
            skill: itemEdit?.technicalSkills?.length ? [...itemEdit.technicalSkills] : [],
            description: itemEdit?.description,
            headhunter: itemEdit?.headhunter,
            responsibilities: itemEdit?.responsibilities,
            listings: itemEdit?.listings,
            private: itemEdit?.private,
            visaType: itemEdit?.visaType === VisaTypes[0].value ? [VisaTypes[0]] : itemEdit?.visaType === VisaTypes[1].value ? [VisaTypes[0], VisaTypes[1]] : itemEdit?.visaType === VisaTypes[2].value ? [VisaTypes[0], VisaTypes[1], VisaTypes[2]] : itemEdit?.visaType === VisaTypes[3].value ? [VisaTypes[0], VisaTypes[1], VisaTypes[2], VisaTypes[3]] : null,
          });
        } else {
          reset({
            company: setting?.name,
          });
        }
      }
    }, [
      dispatch,
      isOpen,
      itemEdit,
      reset,
      setting?.city,
      setting?.country,
      setting?.name,
    ]);

    const handleOpenArchiveModal = (id: any) => {
      setWarningModalOpen({
        isOpen: true,
        item: id,
      });
    };

    const triggerGitHubAction = async () => {
      const githubApiUrl = 'https://api.github.com/repos/Surge-NZ/necta/dispatches';
      const token = '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';

      const payload = {
        event_type: 'listings',
      };

      const headers = {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/vnd.github.v3+json',
        'Content-Type': 'application/json',
      };

      try {
        const response = await fetch(githubApiUrl, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('GitHub Actions trigger failed');
        }
      } catch (error) {
        console.error('Error triggering GitHub Actions:', error);
      }
    };

    const handleArchive = () => {
      dispatch(setLoading(true));
      JobService.archiveJob(itemEdit?.id)
        .then((res) => {
          dispatch(
            setJobListReducer([
              ...jobList.map((item) => (item?.id === res?.id ? res : item)),
            ])
          );
          dispatch(setSuccessMess('Successfully archived job!'));
        })
        .catch((err) => {
          dispatch(setErrorMess(err));
        })
        .finally(() => {
          triggerGitHubAction();
          warningOnClose();
          onClose();
          dispatch(setLoading(false));
        });
    };

    const warningOnClose = () => {
      setWarningModalOpen({
        isOpen: false,
        item: null,
      });
    };

    const options = [
      { id: "1", name: "Option 1" },
      { id: "2", name: "Option 2" },
      { id: "3", name: "Option 3" },
    ];

    const handleButtonClick = () => {
      const formFields = [
        'id',
        'title',
        'client',
        'jobType',
        'company',
        'hourlyRate',
        'startDate',
        'policy',
        'numberOfExperience',
        'skill',
        'description',
        'responsibilities',
        'address',
        'country',
        'city',
        'region',
        'visaType',
      ];

      const formData = formFields.reduce((acc, field) => {
        acc[field] = watch(field);
        return acc;
      }, {});

      downloadJD(formData, setting)
        .then(() => {
        })
        .catch((err) => {
          dispatch(setErrorMess(err));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    };

    const modifiedClients = [
      ...clients
    ];

    const onBehalfOptions: OptionType[] = [
      { value: 'Yes', label: 'Yes' },
      { value: 'No', label: 'No' }
    ];

    useEffect(() => {
      if (itemEdit && itemEdit.client) {
        const foundClientObject = modifiedClients.find(client => client.value === itemEdit.client.toString());
        setSelectedClientObject(foundClientObject);

        const foundClientRaw = rawClientData.find(client => client.id.toString() === itemEdit.client.toString());
        setChosenRawClientData(foundClientRaw);
      } else {
        setSelectedClientObject(null);
        setChosenRawClientData(null);
      }
    }, [itemEdit]);

    useEffect(() => {
      if (chosenRawClientData) {
        if (chosenRawClientData.fixedPrice !== null && selectedClientObject?.value !== 'new') {
          setIsPercentage(chosenRawClientData.fixedPrice === false);
        }
        const chargeAmount = chosenRawClientData.chargeAmount != null ? chosenRawClientData.chargeAmount : (isPercentage ? 6.0 : 6000);
        setSliderValue(chargeAmount);

        const payrollInclusive = chosenRawClientData.payrollInclusive != null ? chosenRawClientData.payrollInclusive : false;
        setIsPayrollIncluded(payrollInclusive);
      } else {
        setSliderValue(isPercentage ? 6.0 : 6000);
      }
    }, [chosenRawClientData, isPercentage]);

    return (
      <Card className={classes.card}>
        <CardBody className={classes.cardBody}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes['title-container']}>
              <Button
                size='md'
                color='default'
                type='button'
                className={classes.btn}
                onClick={onClose}
              >
                {itemEdit ? "Back" : "Cancel"}
              </Button>
              <p className={classes.title}>
                {!itemEdit ? 'Add New Opportunity' : 'Edit Opportunity'}
              </p>
              <div className={classes.spacer}></div>
            </div>
            
            <div className={classes.content}>
              <CustomInput
                placeholder='Title'
                type='text'
                autoComplete='off'
                inputRef={register('title')}
                errorMessage={errors.title?.message}
              />
              {user?.role === 7 && user?.email && (
                <>
                  <Row>
                    <Col md={8}>
                      <CustomSelect
                        placeholder='Clients'
                        name='client'
                        control={control}
                        options={modifiedClients}
                        bindKey="value"
                        bindLabel="label"
                        tooltipText={
                          itemEdit
                            ? 'Once posted, you cannot change this option.'
                            : 'Choose the client to link them to your profile.'
                        }
                        isDisabled= {itemEdit}
                        errorMessage={errors.jobType?.message}
                        value={selectedClientObject || ''} 
                        onChange={(newValue) => {
                          if (newValue && typeof newValue === 'object' && 'value' in newValue) {
                            const selectedValue = newValue as IClientOption;
                            setSelectedClientObject(selectedValue);

                            const fullRawClientData = rawClientData.find(client => client.id.toString() === selectedValue.value);
                            setChosenRawClientData(fullRawClientData);
                          }
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <CustomSelect
                        placeholder='On Behalf'
                        name='onBehalf'
                        control={control}
                        options={onBehalfOptions}
                        bindKey="value"
                        bindLabel="label"
                        errorMessage={errors.onBehalf?.message}
                        value={{ value: onBehalf, label: onBehalf }}
                        tooltipText={
                          itemEdit
                            ? 'Once posted, you cannot change this option.'
                            : 'Yes will post as the client, No will post normally.'
                        }
                        isDisabled= {itemEdit}
                        onChange={(newValue, actionMeta) => {
                          const valueOption = newValue as OptionType;
                          if (valueOption && typeof valueOption === 'object' && 'value' in valueOption) {
                            setOnBehalf(valueOption.value);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Controller
                name='address'
                control={control}
                render={({ field }) => (
                  <SearchLocationInput
                    {...field}
                    ref={null}
                    placeholder='Nearest town or city'
                    errorMessage={errors.address?.message}
                    value={field.value}
                    onChange={(value: any) => {
                      setValue('region', value?.address?.region);
                      setValue('city', value?.address?.city);
                      setValue('country', value?.address?.country);
                      return field.onChange(value?.address?.formattedAddress);
                    }}
                  />
                )}
              />
              <CustomSelect
                placeholder='Job type'
                name='jobType'
                control={control}
                options={JobTypes}
                errorMessage={errors.jobType?.message}
              />
              {jobTypeField?.name && (
                <>
                  <Row>
                    <Col md={9}>
                      <span className='ml-1'>
                        {jobTypeField?.name === 'Contract'
                          ? 'Hourly Rate'
                          : 'Expected Salary'}
                      </span>
                      <CustomSlider
                        name='hourlyRate'
                        min={jobTypeField?.name === 'Contract' ? 30 : 40000}
                        max={jobTypeField?.name === 'Contract' ? 300 : 500000}
                        step={jobTypeField?.name === 'Contract' ? 1 : 1000}
                        control={control}
                        className={classes.sliderHourlyRate}
                        errorMessage={errors.hourlyRate?.message}
                        defaultValue={itemEdit?.maxSalary || itemEdit?.maxRate}
                      />
                    </Col>
                    <Col md={3}>
                      <span className='ml-1'>
                        {jobTypeField?.name === 'Contract'
                          ? 'Keep Rate Private'
                          : 'Keep Salary Private'}
                      </span>
                      <CustomCheckboxFlip
                        firstLabel=''
                        secondLabel=''
                        onChange={handlePrivateChange}
                        checked={itemEdit?.private}
                        tooltipText='blah algbh'
                      />
                    </Col>
                  </Row>
                  <span className='ml-1'>
                    {isPrivate 
                      ? jobTypeField?.name === 'Contract'
                          ? 'Necta will not publish the maximum rate on our listings page or share it with candidates. It will however, be used to match the right candidates, meaning candidates will know its above their minimum rate.'
                          : 'Necta will not publish the maximum salary on our listings page or share it with candidates. It will however, be used to match the right candidates, meaning candidates will know its above their minimum salary.'
                      : ""
                    }
                  </span>
                </>
              )}
              <CustomDatePicker
                name='startDate'
                control={control}
                placeholder='Start date.'
                dateFormat='dd/MM/yyyy'
                minDate={moment().toDate()}
                maxDate={moment().add(1, 'y').toDate()}
                errorMessage={errors.startDate?.message}
              />
              <CustomSelect
                placeholder='Policy'
                name='policy'
                control={control}
                options={PreferencePolicyTypes}
                errorMessage={errors.policy?.message}
                tooltipText='Choose "Office" if you prefer workers solely in the Office. Opt for "Hybrid" is you would like to negotiate a mix of remote and office work. Select "Work From Home" if you exclusively want Remote Workers. All signifies openness to any work arrangement based on mutual agreement.'
              />
              <CustomSelect
                placeholder='Number of relevant experience'
                name='numberOfExperience'
                control={control}
                options={NumberOfExperience}
                errorMessage={errors.numberOfExperience?.message}
                tooltipText='In general, a "Junior" is someone with less than three years of experience, while a "Senior" role is someone with over five years of experience.'
              />
              <CustomMultiSelect
                placeholder='Select visa type'
                name='visaType'
                control={control}
                isMulti={true}
                options={VisaTypes}
                errorMessage={errors.visaType?.message}
                tooltipText='Select what visa you need, let us connect the hierarchy. i.e Permanent Resident will also bring back Citizens.'
              />
              <Controller
                name='skill'
                control={control}
                render={({ field }) => (
                  <CustomTagsInput
                    {...field}
                    name='skill'
                    placeholder='Skills'
                    onChange={(value: any) => {
                      return field.onChange(value);
                    }}
                    value={field.value ? field.value : []}
                    control={control}
                    errorMessage={errors.skill?.message}
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                defaultValue=""
                rules={{ required: 'This field is required' }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomHtml
                    value={value}
                    onChange={onChange}
                    placeholder='Write the description of the position you are hiring for'
                    errorMessage={errors.description?.message}
                  />
                )}
              />
              <Controller
                name="responsibilities"
                control={control}
                defaultValue=""
                rules={{ required: 'This field is required' }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomHtml
                    value={value}
                    onChange={onChange}
                    placeholder='Write the responsibilities of the position you are hiring for'
                    errorMessage={errors.responsibilities?.message}
                  />
                )}
              />
              {user?.role !== 2 && (
                <>
                  <Row style={{ margin: '4px' }}>
                    <CustomCheckboxFlip
                      firstLabel='Live'
                      secondLabel='Draft'
                      onChange={handleListingsChange}
                      {...(itemEdit?.listings === undefined ? { checked: false } : { checked: !itemEdit?.listings })}
                    />
                    <p style={{
                      color: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      fontWeight: '400',
                      fontSize: '1.0em',
                      marginTop: '12px'
                    }}>
                      {isListings
                      ? "Draft means your opportunity will only be shown to those that you choose to send to."
                      : "Live allow applications and recruiter copilot to activate."
                      }
                    </p>
                  </Row>
                </>
              )}
              {itemEdit && (
                <div className={classes.btnDeleteWrapper}>
                  <Button
                    size='md'
                    color='danger'
                    type='button'
                    className={classes.btnDelete}
                    onClick={() => handleOpenArchiveModal(itemEdit?.id)}
                  >
                    Archive Opportunity
                  </Button>
                </div>
              )}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                size='md'
                color='default'
                type='button'
                className={classes.btn}
                onClick={onClose}
              >
                {itemEdit && (
                  "Back"
                )}
                {!itemEdit && (
                  "Cancel"
                )}
              </Button>
              {setting?.recruitOnBehalf !== true && (
                <Button
                  size='md'
                  type='submit'
                  className={classes.btn}
                  onClick={handleButtonClick}
                  style={{ backgroundColor: '#EDDD0F', color: '#000000' }}
                >
                  Generate JD
                </Button>
              )}
              <Button
                size='md'
                color='primary'
                type='submit'
                className={classes.btn}
              >
                {itemEdit ? "Update" : "Submit"}
              </Button>
            </div>
          </Form>
        </CardBody>
        <ArchiveWarningModal
          isOpen={warningModalOpen.isOpen}
          onClose={warningOnClose}
          handleArchive={handleArchive}
        />
      </Card>
    );
  }
);

export default AddNewJob;