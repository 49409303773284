import { API } from "aws-amplify";
import { AssistCreateParams } from "models/Contractor/NewMember/AssistCreate";

export class AssistCreate {
  static async postAssistCreate(data: AssistCreateParams): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/user/assistcreate";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postAssistCreateEmployer(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/user/assistcreate/employer";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }



  static async putAssistEmployer(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/assistcreate/employer/${id}`; 
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

static async getAssistEmployer() {
  const apiName = process.env.REACT_APP_API_NAME;
  const path = "/user/assistcreate/employer";
  const myInit = {
    response: true,
  };
  return API.get(apiName, path, myInit)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((e) => {
      return Promise.reject(e?.response?.data);
    });
}
}
