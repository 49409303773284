import { memo, useEffect, useMemo, useState } from "react";
import Logo from "../../../assets/img/logo.png";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Col,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/Common/CustomInput";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import authFactory from "../../../services/authService/AuthFactory";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { UserService } from "services/User";
import {
  setUserReducer,
  setUserVerificationReducer,
} from "redux/reducers/User/actionTypes";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { ReducerType } from "redux/reducers";
import {
  // getAllChats,
  getAllExpenses,
  getAllOffers,
  getAllTimesheets,
  getProfile,
} from "redux/reducers/Contractor/actionTypes";

interface Props {}

const Login = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (user) {
      if (user?.profileCompleted) {
        dispatch(push(routes.contractor.profile));
      } else {
        dispatch(push(routes.contractor.newMember.root));
      }
    }
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [dispatch, user]);

  const onSubmit = async (data: any) => {
    dispatch(setLoading(true));
    await authFactory
      .generate()
      .login(data?.email, data?.password)
      .then(async () => {
        await UserService.getUser()
          .then((res) => {
            if (res?.type === "employer") {
              setErrorMessage("This is not a valid login");
            } else {
              dispatch(setUserReducer(res));
              dispatch(getProfile());
              dispatch(getAllOffers(res?.id));
              dispatch(getAllTimesheets(res?.id));
              dispatch(getAllExpenses());
              if (res?.chatSecret) {
                //dispatch(getAllChats(res));
              }
              if (res?.profileCompleted) {
                dispatch(push(routes.contractor.profile));
              } else {
                dispatch(push(routes.contractor.newMember.root));
              }
            }
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e: any) => {
        if (e?.message === "User is not confirmed.") {
          dispatch(
            setUserVerificationReducer({
              username: data?.email,
            })
          );
          dispatch(push(routes.contractor.confirmVerificationCode));
        }
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <>
      <ScrollNavbar />
      <div className="page-header header-filter" filter-color="yellow">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/login.jpg") + ")",
          }}
        />
        <div className={classes.content}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className={`card-login ${classes.card}`}>
                  <Form
                    action=""
                    className="form"
                    method=""
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <CardHeader className="text-center">
                      <div className={classes.logoContainer}>
                        <img alt="Logo" src={Logo} width={150}/>
                      </div>
                      <p className={classes.signupTitle}>Talent Login</p>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                      <div className={classes.inputContainer}>
                        <CustomInput
                          startIcon={
                            <i className="now-ui-icons users_circle-08" />
                          }
                          placeholder="Email"
                          name="email"
                          type="email"
                          autoComplete="off"
                          inputRef={register("email")}
                          errorMessage={errors.email?.message}
                        />
                        <CustomInput
                          startIcon={<i className="fa fa-text-height" />}
                          placeholder="Password"
                          name="password"
                          type="password"
                          inputRef={register("password")}
                          errorMessage={errors.password?.message}
                        />
                        <span className={`text-danger ${classes.text}`}>
                          {errorMessage}
                        </span>
                      </div>
                      <Button block color="primary" type="submit" size="mg">
                        Login
                      </Button>
                      <Button
                        block
                        size="mg"
                        onClick={() => authFactory.generate().loginGoogle()}
                      >
                        Sign in with Google
                      </Button>
                      <div className={classes.cardFooter}>
                        <Button
                          className={classes.createAccount}
                          color="neutral"
                          tag={Link}
                          to={routes.contractor.signup}
                        >
                          Create Account
                        </Button>
                        <Button
                          className={classes.forgotPassword}
                          color="neutral"
                          tag={Link}
                          to={routes.contractor.forgotPassword}
                        >
                          Forgot Password? 
                        </Button>
                      </div>
                    </CardBody>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
});

export default Login;
