import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_JOB_LIST_REQUEST,
  setJobListReducer,
} from "redux/reducers/Employer/actionTypes";
import { JobService } from "services/Employer/Listing/Job";

function* requestGetJobList() {
  try {
    yield put(setLoading(true));
    const [jobList] = yield all([call(JobService.getJob)]);
    yield put(setJobListReducer(jobList));
    // console.log("joblist", jobList)
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getJobList() {
  // console.log("jobs calling")
  yield takeLatest(GET_JOB_LIST_REQUEST, requestGetJobList);
}

export default getJobList;
