import { API } from "aws-amplify";
export class XeroInvoiceService {

  static async getEmployerInvoices(id): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/xero/invoices`;
    const myInit = {
      response: true
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getOneEmployerInvoice(id, invoiceId): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/xero/invoices/${invoiceId}`;
    const myInit = {
      response: true
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getInvoicesByInvoiceIds(id, invoiceIds): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/xero/invoices?invoiceIds=${invoiceIds}`;
    const myInit = {
      response: true
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Create or update
  static async updateEmployerInvoice(id, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/xero/invoices`;
    const myInit = {
      response: true,
      body: data
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Download invoice as pdf
  static async downloadPDFInvoice(id): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/xero/invoices/${id}/pdf`;
    const myInit = {
      response: true
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
