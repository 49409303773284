import clsx from "clsx";
import { memo } from "react";
import { Controller, FieldError, FieldErrors, Merge } from "react-hook-form";
import { FormGroup } from "reactstrap";
import classes from "./styles.module.scss";
import DatePicker from "react-datepicker";

interface Props {
  className?: string;
  placeholder?: string;
  errorMessage: string | FieldError | Merge<FieldError, FieldErrors<any>>;
  name: string;
  control?: any;
  [key: string]: any;
}

const CustomDatePicker = memo(
  ({ className, placeholder, errorMessage, name, control, ...rest }: Props) => {
    return (
      <FormGroup
        className={clsx(
          classes.root,
          { "has-danger": !!errorMessage },
          className
        )}
      >
        {control ? (
          <>
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <DatePicker
                    {...field}
                    className={classes.datePickerInput}
                    placeholderText={placeholder}
                    selected={field?.value}
                    onChange={(date) => field?.onChange(date)}
                    autoComplete="off"
                    fixedHeight
                    popperProps={{
                      strategy: "fixed",
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 10], // Adjusts the offset to ensure the pop-up doesn't overlap or cause layout issues
                          },
                        },
                        {
                          name: 'preventOverflow',
                          options: {
                            boundary: 'viewport', // Ensures the pop-up stays within the bounds of the viewport
                          },
                        },
                      ],
                    }}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    {...rest}
                  />
                );
              }}
            />
          </>
        ) : (
          <>
            <DatePicker
              className={classes.datePickerInput}
              placeholderText={placeholder}
              autoComplete="off"
              fixedHeight
              showYearDropdown
              scrollableYearDropdown
              {...rest}
            />
          </>
        )}
        {errorMessage && (
          <span className="text-danger ml-2 mt-1 d-block">{errorMessage}</span>
        )}
      </FormGroup>
    );
  }
);

export default CustomDatePicker;
