import { combineReducers } from "redux";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import { AuthState, authReducer } from "./Auth";
import { userReducer, UserState } from "./User";
import { statusReducer, StatusState } from "./Status";
import { contractorReducer, ContractorState } from "./Contractor";
import { employerReducer, EmployerState } from "./Employer";
import { messageReducer, MessageState } from "./Message";
import uiReducer from '../store/reducer';

const createRootReducer = (history: History) => {
  const reducers = combineReducers({
    auth: authReducer,
    status: statusReducer,
    user: userReducer,
    contractor: contractorReducer,
    employer: employerReducer,
    message: messageReducer,
    ui: uiReducer,
    router: connectRouter(history),
  });
  return reducers;
};

export interface ReducerType {
  auth: AuthState;
  status: StatusState;
  user: UserState;
  contractor: ContractorState;
  employer: EmployerState;
  message: MessageState;
  router: {
    location: {
      pathname: string;
      search: string;
      hash: string;
    };
    action: string;
  };
}

export default createRootReducer;
