import { memo, useState, useEffect } from "react";
import { Button, Col, Container, Form, Row, Table } from "reactstrap";
import CustomInput from "components/Common/CustomInput";
import CustomDropdown from "components/Common/CustomDropdown/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setErrorMess } from "redux/reducers/Status/actionTypes";
import { AssistCreate } from 'services/Contractor/NewMember/AssistCreate';
import { ReducerType } from "redux/reducers";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

interface Props {}

interface User {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  title?: string;
  number?: string;
  access: string;
}

const Users = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);

  const [users, setUsers] = useState<User[]>([]);
  const [newUser, setNewUser] = useState<User>({
    userId: 0,
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    number: "",
    access: "User",
  });

  const [editMode, setEditMode] = useState<number | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [formUser, setFormUser] = useState<User>(newUser);

  useEffect(() => {
    const fetchUsers = async () => {
      dispatch(setLoading(true));
      try {
        const response = await AssistCreate.getAssistEmployer();
        
        // Translate access numbers to strings
        const translatedUsers = response.map(user => ({
          ...user,
          access: user.access === 3 ? 'Admin' : user.access === 2 ? 'User' : 'Reviewer'
        }));
  
        setUsers(translatedUsers);
        console.log(users)
      } catch (error) {
        dispatch(setErrorMess(error));
      } finally {
        dispatch(setLoading(false));
      }
    };
  
    fetchUsers();
  }, [dispatch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormUser({ ...formUser, [name]: value });
  };

  const handleDropdownChange = (name: string, value: string) => {
    setFormUser({ ...formUser, [name]: value });
  };

  const handleAddUser = () => {
    setShowForm(true);
    setEditMode(null);
    setFormUser({ userId: 0, firstName: "", lastName: "", email: "", title: "", number: "", access: "User" });
  };

  const handleEditUser = (userId: number) => {
    console.log(userId)
    const userToEdit = users.find(user => user.userId === userId);
    if (userToEdit) {
      setFormUser(userToEdit);
      setEditMode(userId);
      setShowForm(true);
    }
  };

  const handleSave = async () => {
    const organizationData = {
      idOrganisation: setting?.idOrganisation,
      headHunter: user?.role === 7,
    };

    if (editMode !== null) {
      // console.log("Edit the user", formUser, organizationData);
      await handleEditUserToBackend(formUser, organizationData);
    } else {
      // console.log("Add the user", formUser, organizationData);
      await handleAddUserToBackend(formUser, organizationData);
    }

    setEditMode(null);
    setShowForm(false);
    await fetchUsers();
  };

  const handleAddUserToBackend = async (profileData, organizationData) => {
    dispatch(setLoading(true));
    try {
      const accessMapping = {
        'Reviewer': 1,
        'User': 2,
        'Admin': 3,
      };

      const combinedData = {
        ...profileData,
        ...organizationData,
        access: accessMapping[profileData.access] || profileData.access,
      };

      await AssistCreate.postAssistCreate({
        username: profileData.email,
        email: profileData.email,
      });

      // console.log("This is the data sent to post", combinedData);
      await AssistCreate.postAssistCreateEmployer(combinedData);

      // Update the state to reflect the new user
      setUsers([...users, { ...profileData, id: users.length + 1, access: profileData.access === 3 ? 'Admin' : profileData.access === 2 ? 'User' : 'Reviewer' }]);

    } catch (err) {
      dispatch(setErrorMess(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleEditUserToBackend = async (profileData, organizationData) => {
    dispatch(setLoading(true));
    try {
      const accessMapping = {
        'Reviewer': 1,
        'User': 2,
        'Admin': 3,
      };
  
      const combinedData = {
        ...profileData,
        ...organizationData,
        access: accessMapping[profileData.access] || profileData.access,
      };
      // console.log("hello", combinedData);
      await AssistCreate.putAssistEmployer(combinedData, profileData.userId);
  
      // Update the state to reflect the edited user
      setUsers(users.map(user => user.userId === profileData.userId ? { ...user, ...profileData, access: profileData.access === 3 ? 'Admin' : profileData.access === 2 ? 'User' : 'Reviewer' } : user));
    } catch (err) {
      dispatch(setErrorMess(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchUsers = async () => {
    dispatch(setLoading(true));
    try {
      const response = await AssistCreate.getAssistEmployer();
  
      // Translate access numbers to strings
      const translatedUsers = response.map(user => ({
        ...user,
        access: user.access === 3 ? 'Admin' : user.access === 2 ? 'User' : 'Reviewer'
      }));
  
      setUsers(translatedUsers);
    } catch (error) {
      dispatch(setErrorMess(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Container className={classes.container}>
      <h1 className={classes.title}>User Management</h1>
      <p>Add or Edit users within your organisation. To edit emails or delete users, please contact support.</p>
      <Table striped responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Title</th>
            <th>Work Number</th>
            <th>Email</th>
            <th>Access</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {users.map((user) => (
            <tr key={user.userId}>
              <th scope="row">{user.userId}</th>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.title}</td>
              <td>{user.number}</td>
              <td>{user.email}</td>
              <td>{user.access}</td>
              <td>
                <FontAwesomeIcon 
                  icon={faEdit} 
                  onClick={() => handleEditUser(user.userId)} 
                  className="cursor-pointer text-warning" 
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showForm && (
        <Form>
          <Row form className={classes.formRow}>
            <Col md={2}>
              <CustomInput
                placeholder="First Name"
                type="text"
                autoComplete="off"
                name="firstName"
                value={formUser.firstName}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={2}>
              <CustomInput
                placeholder="Last Name"
                type="text"
                autoComplete="off"
                name="lastName"
                value={formUser.lastName}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={2}>
              <CustomInput
                placeholder="Title"
                type="text"
                autoComplete="off"
                name="title"
                value={formUser.title}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={2}>
              <CustomInput
                placeholder="Work Number"
                type="text"
                autoComplete="off"
                name="number"
                value={formUser.number}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={2}>
              <CustomInput
                placeholder="Email"
                type="email"
                autoComplete="off"
                name="email"
                value={formUser.email}
                onChange={handleInputChange}
                disabled={editMode !== null} // Disable email field when editing
              />
            </Col>
            <Col md={2}>
              <CustomDropdown
                title="Access"
                options={["User", "Admin", "Reviewer"]}
                selectedOption={formUser.access}
                onChange={(value) => handleDropdownChange("access", value)}
              />
            </Col>
          </Row>
          <Button color="primary" onClick={handleSave} className={clsx(classes.saveButton, "mt-3", "ml-2")}>
            Save
          </Button>
        </Form>
      )}
      {!showForm && (
        <Button color="secondary" onClick={handleAddUser} className={clsx(classes.addButton, "mt-3")}>
          Add User
        </Button>
      )}
    </Container>
  );
});

export default Users;
