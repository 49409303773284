import { Row, Col } from 'reactstrap';
import { Controller } from 'react-hook-form';
import CustomInput from 'components/Common/CustomInput';
import SearchLocationInput from 'components/Common/GoogleAddress';
import CustomSelect from 'components/Common/CustomSelect';
import CustomSlider from 'components/Common/CustomSlider';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import CustomCheckboxFlip from 'components/Common/CustomCheckBoxFlip';
import CustomMultiSelect from 'components/Common/CustomMultiSelect';
import CustomTagsInput from 'components/Common/CustomTagsInput';
import { JobTypes, NumberOfExperience } from 'models/Employer/Listings/Dashboard';
import { PreferencePolicyTypes } from 'models/Contractor/NewMember/Preference';
import { VisaTypes } from 'models/Contractor/NewMember/MojCheck';
import moment from 'moment';
import CustomHtml from 'components/CustomHtml';

const JobDescriptionForm = ({ control, errors, watch, setValue, itemEdit, register }) => {
    const jobTypeField = watch('jobType');
    const isPrivate = watch('private');

    const handlePrivateChange = (isChecked) => {
        setValue('private', isChecked); // Update the 'private' field in the form state
    };
  
  return (
    <>
    <p>Description</p>
          <Controller
            name="description"
            control={control}
            defaultValue=""
            rules={{ required: 'This field is required' }}
            render={({ field: { onChange, value } }) => (
              <CustomHtml
                value={value}
                onChange={onChange}
                placeholder="Write the description of the position you are hiring for"
                errorMessage={errors.description?.message}
                // style={{ height: '400px', minHeight: '400px' }}  // Setting height and minimum height
              />
            )}
          />
          <br></br>
          <p>Responsibilities</p>
          <Controller
            name="responsibilities"
            control={control}
            defaultValue=""
            rules={{ required: 'This field is required' }}
            render={({ field: { onChange, value } }) => (
              <CustomHtml
                value={value}
                onChange={onChange}
                placeholder="Write the responsibilities of the position you are hiring for"
                errorMessage={errors.responsibilities?.message}
                // style={{ height: '400px', minHeight: '400px' }} 
              />
            )}
          />
    </>
  );
};

export default JobDescriptionForm;


