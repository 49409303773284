import { memo, useMemo, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import FooterDefault from "components/Footers/FooterDefault";
import { Button, Col, Container, Form, Row } from "reactstrap";
import CustomInput from "components/Common/CustomInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import ImageUpload from "components/CustomUpload";
import CustomTextarea from "components/CustomTextarea";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  setErrorMess,
  setLoading,
  setSuccessMess,
} from "redux/reducers/Status/actionTypes";
import { BasicProfileService } from "services/Employer/NewMember/BasicProfile";
import { ImageService } from "services/Image";
import { OrganizationService } from "services/Employer/NewMember/Organization";
import CustomTagsInput from "components/Common/CustomTagsInput";
import { ReducerType } from "redux/reducers";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
// import { UserChatEngineService } from "services/Message/User/UserChatEngine";
import password from "secure-random-password";
import { setUserReducer,setUserAvatarReducer } from "redux/reducers/User/actionTypes";
import { UserService } from "services/User";
import { setSettingReducer, getSetting } from "redux/reducers/Employer/actionTypes";
import { UtilDefaultAvatar } from "utils/defaultAvatar";
import CustomSwitch from "components/Common/CustomSwitch";
import SearchLocationInput from "components/Common/GoogleAddress";
import CustomCheckbox from "components/Common/CustomCheckbox";
import { ContractorSettingService } from "services/Contractor/Settings/Email";
import CustomCheckboxFlip from 'components/Common/CustomCheckBoxFlip';


interface Props {}
interface ImageFile extends File {}

interface SettingFormData {
  idBasicProfile?: number;
  firstName: string;
  lastName: string;
  title: string;
  workPhone?: string;
  avatar?: File;
  companyAvatar?: ImageFile;
  recruitOnBehalf?: boolean;
  workPhoneCountryCode?: string;
  headHunter?: boolean;
  idOrganisation?: number;
  name?: string;
  industry?: string;
  numberOfWorkers?: number[];
  mission?: string;
  culture?: string;
  behaviouralSklls?: string[];
  nzbn?: string;
  email?: string;
  phone?: string;
  countryCode?: string;
  uniqueCode?: string;
  value?: any;
  city?: string;
  country?: string;
  address?: string;
  EmailNotification?: boolean;
}

// eslint-disable-next-line no-empty-pattern
const Setting = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  // console.log("here",setting, user);

  const [deactivate, setDeactivate] = useState(false);
  const [settingId, setSettingId] = useState();

  const schema = useMemo(() => {
    return yup.object().shape({
      idBasicProfile: yup.string(),
      avatar: yup.mixed(),
      firstName: yup
        .string()
        .min(2, "At least 2 characters")
        .max(64, "At least 64 characters")
        .required("This field is required"),
      lastName: yup
        .string()
        .min(2, "At least 2 characters")
        .max(64, "At least 64 characters")
        .required("This field is required"),
      title: yup.string().required("This field is required"),
      workPhone: yup.string().required("This field is required"),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
  } = useForm<SettingFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  

  useEffect(() => {
    if (setting && user) {
      reset({
        ...setting,
        idBasicProfile: setting.idBasicProfile,
        firstName: setting.firstName,
        lastName: setting.lastName,
        title: setting.title,
        workPhone: setting.workPhone,
        recruitOnBehalf: setting.recruitOnBehalf,
        workPhoneCountryCode: setting.countryCode,
      });

      const isRecruit = setting.recruitOnBehalf;
      setValue("email", user.email);

      ContractorSettingService.getContractorSettingService(user?.id)
        .then((res) => {
          setSettingId(res?.id);
          setDeactivate(res?.deactivateAccount);
          setValue("EmailNotification", !res?.turnOffEmails);
        })
        .catch((err) => dispatch(setErrorMess(err)));

      if (setting.photoUrl) {
        ImageService.getImageBlob(setting.photoUrl)
          .then((res) => {
            setValue("avatar", blobToFile(res as Blob, "avatar.png"));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      }
    }
  }, [dispatch, reset, setValue, setting, user]);

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob as any], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const onSubmit = (data: SettingFormData) => {
    // console.log(data);
    dispatch(setLoading(true));

    ContractorSettingService.putContractorSettingService(user?.id, settingId, {
      turnOffEmails: !data?.EmailNotification,
    }).catch((error) => dispatch(setErrorMess(error)));

    if (data?.companyAvatar) {
      // console.log(data?.companyAvatar);
      // console.log(data);
      try {
        ImageService.postCompanyAvatar(
          data?.companyAvatar,
          `${data?.idOrganisation}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`
        )
      } catch (error) {
        console.error("Upload error:", error);
        throw error; // Or handle the error appropriately
      }

    }
    ImageService.postImage(
      data?.avatar,
      `${user?.id}-${S3_UPLOAD_TYPE_PRIFIX.AVATAR}`
    )
      .then(async (res) => {
        dispatch(setUserAvatarReducer(res));
        const arrSubmit = [];
        let chatSecret = null;
        let form_data = new FormData();
        form_data.append("username", user?.id.toString());
        form_data.append("first_name", data?.firstName);
        form_data.append("last_name", data?.lastName);
        if (data?.avatar) {
          form_data.append("avatar", data?.avatar, data?.avatar.name);
        } else {
          const defaultAvatar = await UtilDefaultAvatar.getFile();
          form_data.append("avatar", defaultAvatar, defaultAvatar.name);
        }
        if (data?.idBasicProfile) {
          form_data.append("secret", user?.chatSecret);
          console.log("recruiter",data?.recruitOnBehalf)
          arrSubmit.push(
            BasicProfileService.putBasicProfile(
              {
                photoUrl: res,
                firstName: data?.firstName,
                lastName: data?.lastName,
                title: data?.title,
                workPhone: data?.workPhone,
                countryCode: data?.workPhoneCountryCode,
                isRecruiter: !!data?.recruitOnBehalf
              },
              data?.idBasicProfile
            )
            // UserChatEngineService.patchUserChatEngine(
            //   user?.chatUserId,
            //   form_data
            // )
          );
        } else {
          chatSecret = password.randomPassword({
            characters: [password.lower, password.upper, password.digits],
          });
          form_data.append("secret", chatSecret);
          dispatch(
            setUserReducer({
              ...user,
              chatSecret: chatSecret,
            })
          );
          arrSubmit.push(
            BasicProfileService.postBasicProfile({
              photoUrl: res,
              firstName: data?.firstName,
              lastName: data?.lastName,
              title: data?.title,
              workPhone: data?.workPhone,
              countryCode: "+64",
              isRecruiter: data?.recruitOnBehalf,
            })
            // UserChatEngineService.postUserChatEngine(form_data)
          );
        }
        if (data?.idOrganisation) {
          arrSubmit.push(
            OrganizationService.putOrganization(
              {
                name: data?.name,
                mission: data?.mission,
                culture: data?.culture,
                industry: data?.industry,
                numberOfWorkers: data?.numberOfWorkers,
                behaviouralSklls: data?.behaviouralSklls,
                nzbn: data?.nzbn,
                email: data?.email,
                city: data?.city,
                country: data?.country,
              },
              data?.idOrganisation
            )
          );
        } else {
          arrSubmit.push(
            OrganizationService.postOrganization({
              name: data?.name,
              mission: data?.mission,
              culture: data?.culture,
              industry: data?.industry,
              numberOfWorkers: data?.numberOfWorkers,
              behaviouralSklls: data?.behaviouralSklls,
              nzbn: data?.nzbn,
              email: data?.email,
              city: data?.city,
              country: data?.country,
            })
          );
        }
        await Promise.all(arrSubmit)
          .then(async (res) => {
            dispatch(getSetting());
            if (!data?.idBasicProfile) {
              await UserService.putUser(user?.id, {
                ...user,
                chatUserId: res[1]?.id,
                chatSecret: chatSecret,
              })
                .then(() => {
                  dispatch(
                    setUserReducer({
                      ...user,
                      chatUserId: res[1]?.id,
                      chatSecret: chatSecret,
                    })
                  );
                })
                .catch((e) => dispatch(setErrorMess(e)));
            }
            dispatch(setSuccessMess("Successfully update"));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };
  
  const isRecruit = setting?.recruitOnBehalf;
  const [isAssist, setIsAssist] = useState(false);

  useEffect(() => {
    if (typeof setting?.recruitOnBehalf !== 'undefined') {
      setIsAssist(setting?.recruitOnBehalf);
    }
  }, [setting?.recruitOnBehalf]);
  
  const handleCheckboxChange = (isChecked: boolean) => {
    setIsAssist(isChecked);
    setValue("recruitOnBehalf", isChecked);  
  };
  

  const handleDeactivate = () => {
    dispatch(setLoading(true));
    ContractorSettingService.putContractorSettingService(user?.id, settingId, {
      deactivateAccount: !deactivate,
    })
      .then((res) => {
        if (res?.deactivateAccount) {
          dispatch(setSuccessMess("Successfully deactivate account!"));
        } else {
          dispatch(setSuccessMess("Successfully activate account!"));
        }
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
    setDeactivate(!deactivate);
  };

  return (
    <>
      <div className="wrapper">

        <div className={classes.section}>
          <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.content}>
                {/* ------------------------ BASIC PROFILE ------------------------ */}
                <div
                  className={clsx(
                    classes.commonContainer,
                    classes.hideBorder,
                    "pt-0"
                  )}
                >
                  <p className={classes.commonTitle}></p>
                  <Row className={classes.commonContent}>
                    <Col xs="12" className={classes.uploadAvatar}>
                      <Controller
                        name="avatar"
                        control={control}
                        render={({ field }) => (
                          <ImageUpload
                            avatar
                            value={field.value}
                            onChange={(file) => field.onChange(file)}
                            isDisabled={false}
                          />
                        )}
                      />
                    </Col>
                    <Col xs="12" className="d-none">
                      <CustomInput
                        placeholder="ID BasicProfile"
                        type="text"
                        autoComplete="off"
                        inputRef={register("idBasicProfile")}
                        errorMessage={errors.idBasicProfile?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>First name</p>
                      <CustomInput
                        className={classes.commonInput}
                        placeholder="Your first name"
                        type="text"
                        autoComplete="off"
                        inputRef={register("firstName")}
                        errorMessage={errors.firstName?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>Last name</p>
                      <CustomInput
                        className={classes.commonInput}
                        placeholder="Your last name"
                        type="text"
                        autoComplete="off"
                        inputRef={register("lastName")}
                        errorMessage={errors.lastName?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>Title</p>
                      <CustomInput
                        className={classes.commonInput}
                        placeholder="Title"
                        type="text"
                        autoComplete="off"
                        inputRef={register("title")}
                        errorMessage={errors.title?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>
                        Work phone number
                      </p>
                      <CustomInput
                        className={classes.commonInput}
                        placeholder="Your work phone number"
                        type="text"
                        autoComplete="off"
                        inputRef={register("workPhone")}
                        errorMessage={errors.workPhone?.message}
                      />
                    </Col>
                    <Col md="12" xs="12" className={classes.commonItem}>
                    {
                        user?.role !== 7 && (
                      <CustomCheckbox
                        content="Receive Emails alerting of New Messages and Offer Updates"
                        inputRef={register(`EmailNotification`)}
                        errorMessage={errors.EmailNotification?.message}
                        className={clsx("my-2", classes.customCheckbox)}
                      />
                        )}
                    </Col>
                    <Col
                      xs="12"
                      className={clsx(classes.commonItem, classes.centerItem)}
                    >
                      
             
                   
                    </Col>
                  </Row>
                </div>

                <div className={classes.btnSaveContainer}>
                  <Button
                    className={`btn-round ${classes.btnSave}`}
                    color="primary"
                  >
                    <i className="fa fa-save" /> Save
                  </Button>
                </div>

              </div>
            </Form>
          </Container>
        </div>

      </div>
    </>
  );
});

export default Setting;
