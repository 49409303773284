import { API } from "aws-amplify";
import { ContactProfileParams } from "models/Contractor/NewMember/ContactProfile";

export class ContactProfileService {
  static async postContactProfile(data: ContactProfileParams): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/contact";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContactProfile(): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/contact";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putContactProfile(
    data: ContactProfileParams,
    id: number
  ): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contact/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
