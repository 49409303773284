import { API, Storage } from "aws-amplify";

export class TalentProfileService {
  // Get all
  static getTalentProfiles() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/talentprofiles";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static getAllCandidates() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/allcandidates";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }


  static getAllClientCandidates() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/allcandidates/allclientcandidates";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }


  // Get a talent profile
  static getTalentProfile(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/talentprofile/${id}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

// Get all talents (profiles and preferences)
static getTalents() {
  const apiName = process.env.REACT_APP_API_NAME;
  const path = "/talents";
  const myInit = {
    response: true,
  };
  return API.get(apiName, path, myInit)
    .then((res) => {
      // console.log("LOOK HERE!" + res.data); // Log the response data
      return Promise.resolve(res.data);
    })
    .catch((e) => {
      return Promise.reject(e?.response?.data);
    });
}


    // REALLY get all talent (profiles and preferences)
  static getAllTalents() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/alltalents";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);

      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static getFilterNameTalents(name) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/talents?name=${name}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static getFilterNameAllTalents(name) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/alltalents?name=${name}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Get a talent (profile and preference)
  static getTalent(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/talent/${id}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Create talent profile
  static postTalentProfile(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/talentprofile";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Create talent profile
  static postTalentAssistProfile(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/assistcreateprofile";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Edit talent profile
  static putTalentProfile(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/talentprofile/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

    // Edit talent profile
    static putAssistUpdateProfile(data, id) {
      const apiName = process.env.REACT_APP_API_NAME;
      const path = `/assistupdateprofile/${id}`;
      const myInit = {
        response: true,
        body: data,
      };
      return API.put(apiName, path, myInit)
        .then((res) => {
          return Promise.resolve(res.data);
        })
        .catch((e) => {
          return Promise.reject(e?.response?.data);
        });
    }

  static deleteTalentProfile(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/talentprofile/${id}`;
    const myInit = {
      response: true,
    };
    return API.del(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  //no api written yet
  static deactivateTalentProfile(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/assistupdateprofile/deactivatetalent/${id}`;
    const myInit = {
      response: true,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }


  

  static async postCv(file, key) {
    try {
      if (file) {
        const response = await Storage.put(key, file, {
          level: "protected",
          contentType: file?.type,
        });
        return response?.key;
      }
      return "";
    } catch (error) {
      console.log(error);
    }
  }

  static async getCv(key, identityId = null) {
    if (identityId) {
      try {
        const response = await Storage.get(key, {
          download: true,
          level: "protected",
          identityId: identityId,
        });
        return response?.Body;
      } catch (error) {
        console.log(error, "fail");
      }
    } else {
      try {
        const response = await Storage.get(key, {
          download: true,
          level: "protected",
        });
        return response?.Body;
      } catch (error) {
        console.log(error, "fail");
      }
    }
  }
}
