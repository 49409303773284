import { memo } from "react";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import FooterDefault from "components/Footers/FooterDefault";
import FooterMobile from "components/Footers/FooterMobile";
import classes from "./styles.module.scss";
import { Container } from "reactstrap";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const DocumentHistory = memo(({}: Props) => {
  return (
    <>
      <ScrollNavbar />
      <div className="wrapper">
        <div className={classes.header} />
        <div className={classes.section}>
          <Container>
            <p className="pt-5">Document History</p>
          </Container>
        </div>
        <FooterDefault />
        <FooterMobile />
      </div>
    </>
  );
});

export default DocumentHistory;
