import { Fragment, memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Button,
} from "reactstrap";
import Logo from "assets/img/logo.png";
import routes from "routers/routes";
import classes from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { setUserLogoutReducer } from "redux/reducers/User/actionTypes";
import authFactory from "services/authService/AuthFactory";
import { ReducerType } from "redux/reducers";
import clsx from "clsx";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import {
  setAllChatsReducer,
  setAllTimesheetsReducer,
  setInterviewOffersReducer,
  setJobListReducer,
  setJobOffersReducer,
  setSettingReducer,
  setTalentReducer,
} from "redux/reducers/Employer/actionTypes";

interface Props {}

const AccountantNavbar = memo((props: Props) => {
  const { user } = useSelector((state: ReducerType) => state.user);
  const dispatch = useDispatch();

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [navbarColor, setNavbarColor] = useState(" navbar-transparent");

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 10 ||
        document.body.scrollTop > 10
      ) {
        setNavbarColor("");
      } else {
        setNavbarColor(" navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return () => {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  const handleCollapseNavbar = () => {
    if (window.innerWidth <= 991) {
      document.documentElement.classList.toggle("nav-open");
      setCollapseOpen(!collapseOpen);
    }
  };

  const handleSignOut = async () => {
    dispatch(setLoading(true));
    await authFactory
      .generate()
      .logout()
      .then(() => {
        dispatch(setUserLogoutReducer());
        dispatch(setSettingReducer(null));
        dispatch(setJobListReducer([]));
        dispatch(setTalentReducer([]));
        dispatch(setJobOffersReducer([]));
        dispatch(setInterviewOffersReducer([]));
        dispatch(setAllTimesheetsReducer([]));
        dispatch(setAllChatsReducer(null));
        dispatch(push(routes.employer.login));
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <Fragment>
      {collapseOpen && <div id="bodyClick" onClick={handleCollapseNavbar} />}
      <Navbar
        className={clsx("fixed-top", navbarColor && classes.bgBlack)}
        color="white"
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              to={routes.employer.accountant}
              tag={Link}
              id="navbar-brand"
            >
              <img src={Logo} alt="Necta Logo" width={50} className="mr-3" />
              <span className={classes.logoWord}>Necta</span>
            </NavbarBrand>
            <button
              onClick={handleCollapseNavbar}
              aria-expanded={collapseOpen}
              className={clsx("navbar-toggler", classes.navbarMenu)}
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            isOpen={collapseOpen}
            navbar
            className={classes.navbarCollapse}
          >
            <Nav className="ml-auto" id="ceva" navbar>
              {user && (
                <UncontrolledDropdown nav className={classes.other}>
                  <DropdownToggle
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                    onClick={handleSignOut}
                  >
                    <i
                      aria-hidden={true}
                      className="now-ui-icons media-1_button-power"
                    />
                    <p>Sign Out</p>
                  </DropdownToggle>
                </UncontrolledDropdown>
              )}
              {user && (
                <>
                  <NavItem className={classes.signOutDesktop}>
                    <Button
                      className="nav-link btn-default ml-3"
                      color="secondary"
                      onClick={handleSignOut}
                    >
                      <p>Sign Out</p>
                    </Button>
                  </NavItem>
                </>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
});

export default AccountantNavbar;
