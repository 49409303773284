import { API } from "aws-amplify";

export class EmployerTimesheetService {
  static async getAllTimesheet(employerId): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${employerId}/timesheets`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllTimesheetApprover(employerId, approverEmail): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${employerId}/external/timesheets?approverEmail=${approverEmail}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getOneTimesheet(employerId, timesheetId): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${employerId}/timesheets/${timesheetId}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async approveTimesheet(employerId, timesheetId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${employerId}/timesheets/${timesheetId}/approved`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async declineTimesheet(employerId, timesheetId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${employerId}/timesheets/${timesheetId}/declined`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async payTimesheet(employerId, timesheetId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${employerId}/timesheets/${timesheetId}/paid`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
  static async searchTimesheet(employerId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${employerId}/timesheets?name=${data}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
