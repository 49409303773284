import { createRef, memo, useState, useEffect } from "react";
import { Button } from "reactstrap";
import ImageCropper from "../Common/ImageCropper";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import classes from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";

interface Props {
  avatar: boolean;
  value: any;
  onChange: (file: any) => void;
  isDisabled?: boolean; 
}

const ImageUpload = memo((props: Props) => {
  const { value, onChange, isDisabled = true } = props;
  
  const dispatch = useDispatch();

  const fileInput: any = createRef();

  const [file, setFile] = useState(null);
  const [openCropper, setOpenCropper] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    props.avatar ? defaultAvatar : defaultImage
  );

  useEffect(() => {
    if (value) {
      dispatch(setLoading(true));
      setFile(value);
      fileToBase64(value)
        .then((result) => setImagePreviewUrl(result as any))
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    }
  }, [dispatch, value]);

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) return;
    setFile(file);
    onChange(file);
    setOpenCropper(true);
  };

  const handleCloseCropper = () => {
    fileInput.current.value = ""; // reset file input
    setFile(null);
    onChange(null);
    setOpenCropper(false);
  };

  const handleConfirmCropper = (file, base64File) => {
    fileInput.current.value = "";
    setFile(file);
    onChange(file);
    setOpenCropper(false);
    setImagePreviewUrl(base64File);
  };

  // const handleSubmit = e => {
  // e.preventDefault();
  // this.state.file is the file/image uploaded
  // in this function you can save the image (this.state.file) on form submit
  // you have to call it yourself
  // };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    onChange(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };

  return (
    <>
      <div className="fileinput text-center">
        <input type="file" onChange={handleImageChange} ref={fileInput} />
        <div
          className={
            "fileinput-new thumbnail img-raised" +
            (props.avatar ? " img-circle" : "")
          }
        >
          <img
            src={imagePreviewUrl}
            alt="Avatar"
            className={classes.avtCircle}
          />
        </div>
        <div>
  {!isDisabled && ( // Only show buttons if isDisabled is false
    <>
      {file ? (
        <span>
          <Button
            className="btn-round"
            color="default"
            onClick={handleClick}
          >
            Change
          </Button>
          {props.avatar ? <br /> : null}
          <Button
            color="danger"
            className="btn-round"
            onClick={handleRemove}
          >
            <i className="fa fa-times" /> Remove
          </Button>
        </span>
      ) : (
        <Button className="btn-round" color="default" onClick={handleClick}>
          {props.avatar ? "Add Photo" : "Select image"}
        </Button>
      )}
    </>
  )}
</div>
      </div>
      <ImageCropper
        image={file}
        isOpen={openCropper}
        onConfirm={handleConfirmCropper}
        onClose={handleCloseCropper}
        shape="round"
      />
    </>
  );
});

export default ImageUpload;
