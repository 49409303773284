import { put, takeLatest, all, call } from "redux-saga/effects";
import { IProfile } from "redux/reducers/Contractor";
import {
  GET_PROFILE_REQUEST,
  setProfileReducer,
} from "redux/reducers/Contractor/actionTypes";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { BasicProfileService } from "services/Contractor/NewMember/BasicProfile";
import { ContactProfileService } from "services/Contractor/NewMember/ContactProfile";
import { EducationService } from "services/Contractor/NewMember/Education";
import { ExperienceService } from "services/Contractor/NewMember/Experience";
import { MojCheckService } from "services/Contractor/NewMember/MojCheck";
import { PreferenceService } from "services/Contractor/NewMember/Preference";
import { SkillService } from "services/Contractor/NewMember/Skill";
import { TestimonialService } from "services/Contractor/NewMember/Testimonial";
import { TaxService } from "services/Contractor/Tax/Tax";

function* requestGetProfile() {
  try {
    yield put(setLoading(true));
    const [
      basicProfile,
      contact,
      preference,
      testimonial,
      experience,
      education,
      skill,
      mojCheck,
      taxDetails,
    ] = yield all([
      call(BasicProfileService.getBasicProfile),
      call(ContactProfileService.getContactProfile),
      call(PreferenceService.getPreference),
      call(TestimonialService.getTestimonial),
      call(ExperienceService.getExperience),
      call(EducationService.getEducation),
      call(SkillService.getSkill),
      call(MojCheckService.getMojCheck),
      call(TaxService.getTax),
    ]);
    const profile: IProfile = {
      basicProfile: basicProfile,
      contact: contact,
      preference: preference,
      testimonial: testimonial || [],
      experience: experience || [],
      education: education || [],
      skill: skill,
      mojCheck: {
        id: mojCheck?.id,
        mojUrl: mojCheck?.mojUrl,
        visaType: mojCheck?.visaType,
      },
      taxDetails: taxDetails,
    };
    yield put(setProfileReducer(profile));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getProfile() {
  yield takeLatest(GET_PROFILE_REQUEST, requestGetProfile);
}

export default getProfile;
