export const GET_USER_REQUEST = "GET_USER_REQUEST";

export const SET_USER_REDUCER = "SET_USER_REDUCER";

export const SET_USER_VERIFICATION_REDUCER = "SET_USER_VERIFICATION_REDUCER";

export const SET_USER_LOGOUT_REDUCER = "SET_USER_LOGOUT_REDUCER";

export const SET_USER_AVATAR_REDUCER = "SET_USER_AVATAR_REDUCER";

export const UPDATE_USER_AVATAR_REQUEST = "UPDATE_USER_AVATAR_REQUEST";

export const getUser = () => {
  return {
    type: GET_USER_REQUEST,
  };
};

export const updateUserAvatarRequest = (avatar) => {
  return {
    type: UPDATE_USER_AVATAR_REQUEST,
    payload: avatar,
  };
};

export const setUserAvatarReducer = (avatar) => {
  return {
    type: SET_USER_AVATAR_REDUCER,
    data: avatar,
  };
};

export const setUserReducer = (data) => {
  return {
    type: SET_USER_REDUCER,
    data: data,
  };
};

export const setUserVerificationReducer = (data) => {
  return {
    type: SET_USER_VERIFICATION_REDUCER,
    data: data,
  };
};

export const setUserLogoutReducer = () => {
  return {
    type: SET_USER_LOGOUT_REDUCER,
  };
};
