import { Fragment, memo, useEffect, useState } from "react";
import { Card, CardBody, Container, CardHeader } from "reactstrap";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import routes from "routers/routes";
import FooterMobile from "components/Footers/FooterMobile";
import { ReducerType } from "redux/reducers";
import { useParams } from "react-router-dom";
import { CONTRACTOR_TIMESHEET_STATUS } from "config/constants";
import { ITimesheet } from "redux/reducers/Contractor";
import { fCurrency, calculateTimesheetNetContractor } from "utils/price";
import { ITimesheetStatus } from "../AddEditTimesheet";
import moment from "moment";

interface ITimesheetParams {
  status?: CONTRACTOR_TIMESHEET_STATUS;
}

interface Props {}

const Timesheet = memo((props: Props) => {
  const { status: statusParam } = useParams<ITimesheetParams>();

  const dispatch = useDispatch();
  const { allTimesheets } = useSelector(
    (state: ReducerType) => state.contractor
  );

  const getStatus = (status: CONTRACTOR_TIMESHEET_STATUS) => {
    switch (status) {
      case CONTRACTOR_TIMESHEET_STATUS.APPROVED:
        return CONTRACTOR_TIMESHEET_STATUS.APPROVED;
      case CONTRACTOR_TIMESHEET_STATUS.PAID:
        return CONTRACTOR_TIMESHEET_STATUS.PAID;
      default:
        return CONTRACTOR_TIMESHEET_STATUS.PENDING;
    }
  };

  const status: CONTRACTOR_TIMESHEET_STATUS = getStatus(statusParam);
  const initialStatus: ITimesheetStatus = { quantity: 0, totalPrice: 0 };

  const [pending, setPending] = useState<ITimesheetStatus>(initialStatus);
  const [approved, setApproved] = useState<ITimesheetStatus>(initialStatus);
  const [paid, setPaid] = useState<ITimesheetStatus>(initialStatus);
  const [list, setList] = useState<ITimesheet[]>([]);

  // TIMESHEET STATUSES
  useEffect(() => {
    if (allTimesheets) {
      let pendingTotal = 0;
      let approvedTotal = 0;
      let paidTotal = 0;
      const pendingList = allTimesheets.filter(
        (item: ITimesheet) => item.pending
      );
      const approvedList = allTimesheets.filter(
        (item: ITimesheet) => item.approved && !item.paid
      );
      const paidList = allTimesheets.filter((item: ITimesheet) => item.paid);

      pendingList?.forEach((item: ITimesheet) => {
        pendingTotal += calculateTimesheetNetContractor(
          item.hours,
          item.rateOffered
        );
      });
      approvedList?.forEach((item: ITimesheet) => {
        approvedTotal += calculateTimesheetNetContractor(
          item.hours,
          item.rateOffered
        );
      });
      paidList?.forEach((item: ITimesheet) => {
        paidTotal += calculateTimesheetNetContractor(
          item.hours,
          item.rateOffered
        );
      });

      setPending({ quantity: pendingList?.length, totalPrice: pendingTotal });
      setApproved({
        quantity: approvedList?.length,
        totalPrice: approvedTotal,
      });
      setPaid({ quantity: paidList?.length, totalPrice: paidTotal });

      switch (status) {
        case CONTRACTOR_TIMESHEET_STATUS.PENDING:
          setList(pendingList);
          break;
        case CONTRACTOR_TIMESHEET_STATUS.APPROVED:
          setList(approvedList);
          break;
        case CONTRACTOR_TIMESHEET_STATUS.PAID:
          setList(paidList);
          break;
        default:
          setList([]);
      }
    }
  }, [allTimesheets, status]);

  const renderStatus = (status: CONTRACTOR_TIMESHEET_STATUS) => {
    switch (status) {
      case CONTRACTOR_TIMESHEET_STATUS.PENDING:
        return "Pending";
      case CONTRACTOR_TIMESHEET_STATUS.APPROVED:
        return "Approved";
      case CONTRACTOR_TIMESHEET_STATUS.PAID:
        return "Paid";
      default:
        return "...";
    }
  };

  return (
    <Fragment>
      <ScrollNavbar />
      <div
        className={clsx("page-header header-filter", classes.wrapper)}
        filter-color="yellow"
      >
        <div className={classes.header} />
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project14.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.container}>
            <Card className={classes.card}>
              <div className={classes.addButtonContainer}>
                <p
                  className={classes.addButton}
                  onClick={() =>
                    dispatch(push(routes.contractor.addEditTimesheet))
                  }
                >
                  <i className="now-ui-icons ui-1_simple-add" />
                  Timesheets
                </p>
              </div>

              <CardHeader className={classes.cardHeader}>
                <div
                  className={clsx(
                    classes.headerItem,
                    classes.backgroundPending,
                    {
                      [classes.active]:
                        status === CONTRACTOR_TIMESHEET_STATUS.PENDING,
                    }
                  )}
                  onClick={() =>
                    dispatch(
                      push(
                        routes.contractor.timesheet.status.replace(
                          ":status",
                          "pending"
                        )
                      )
                    )
                  }
                >
                  <div className={classes.headerItemTitle}>
                    <p>PENDING</p>
                    <p>({pending.quantity})</p>
                  </div>
                  <p className={classes.headerItemPrice}>
                    {fCurrency(pending.totalPrice)}
                  </p>
                </div>
                <div className={classes.verticalDiviver} />
                <div
                  className={clsx(
                    classes.headerItem,
                    classes.backgroundApproved,
                    {
                      [classes.active]:
                        status === CONTRACTOR_TIMESHEET_STATUS.APPROVED,
                    }
                  )}
                  onClick={() =>
                    dispatch(
                      push(
                        routes.contractor.timesheet.status.replace(
                          ":status",
                          "approved"
                        )
                      )
                    )
                  }
                >
                  <div className={classes.headerItemTitle}>
                    <p>APPROVED</p>
                    <p>({approved.quantity})</p>
                  </div>
                  <p className={classes.headerItemPrice}>
                    {fCurrency(approved.totalPrice)}
                  </p>
                </div>
                <div className={classes.verticalDiviver} />
                <div
                  className={clsx(classes.headerItem, classes.backgroundPaid, {
                    [classes.active]:
                      status === CONTRACTOR_TIMESHEET_STATUS.PAID,
                  })}
                  onClick={() =>
                    dispatch(
                      push(
                        routes.contractor.timesheet.status.replace(
                          ":status",
                          "paid"
                        )
                      )
                    )
                  }
                >
                  <div className={classes.headerItemTitle}>
                    <p>PAID</p>
                    <p>({paid.quantity})</p>
                  </div>
                  <p className={classes.headerItemPrice}>
                    {fCurrency(paid.totalPrice)}
                  </p>
                </div>
              </CardHeader>

              <CardBody className={classes.cardBody}>
                {!!list?.length ? (
                  <div className={classes.listContainer}>
                    {list.map((item) => (
                      <div
                        className={
                          status === CONTRACTOR_TIMESHEET_STATUS.PENDING
                            ? classes.listItemHover
                            : classes.listItem
                        }
                        key={item.id}
                        onClick={() =>
                          status === CONTRACTOR_TIMESHEET_STATUS.PENDING &&
                          dispatch(
                            push(
                              routes.contractor.timesheet.edit.replace(
                                ":timesheetId",
                                `${item.id}`
                              )
                            )
                          )
                        }
                      >
                        <div className={classes.listItemLeft}>
                          <p className={classes.timesheetName}>
                            {item.approver}
                          </p>
                          <p className={classes.timesheetId}>
                            Reference ID: {item.id}
                          </p>
                          <p className={classes.timesheetDate}>
                            For: {moment(item.weekEnding).format("DD/MM/YYYY")}
                          </p>
                        </div>
                        <div className={classes.listItemRight}>
                          <p className={classes.timesheetPrice}>
                            {fCurrency(
                              calculateTimesheetNetContractor(
                                item.hours,
                                item.rateOffered
                              )
                            )}
                          </p>
                          <p className={classes.timesheetStatus}>
                            Status: {renderStatus(status)}
                          </p>
                          {status === CONTRACTOR_TIMESHEET_STATUS.PAID && (
                            <p className={classes.datePaid}>
                              Date paid:{" "}
                              {item?.datePaid
                                ? moment(item?.datePaid).format("DD/MM/YYYY")
                                : "Unknown"}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={classes.noItemMessage}>No item.</div>
                )}
              </CardBody>
            </Card>
          </Container>
        </div>
        <Footer className={classes.footer} />
      </div>
      <FooterMobile />
    </Fragment>
  );
});

export default Timesheet;
