// ./store/selector.ts

import { ModalMeta } from '../../typings/modal';
import type { UIState } from './reducer';

export const getOpenModalsList: (state: any) => string[] = (state) => {
  // Safeguard to return an empty array if state or state.ui.modal is undefined
  // console.log(state)
  if (!state || !state.ui || !state.ui.modal) {
    return [];
  }

  // If state and state.ui.modal are defined, proceed as normal
  return Object.keys(state.ui.modal).filter((modalId) => state.ui.modal[modalId].open);
};

export const isModalOpen: (state: any, id: string) => boolean = (
  state,
  id
) => {
  // Safeguard to return false if state or state.ui.modal is undefined or the modal with the specified id does not exist in state
  if (!state || !state.ui || !state.ui.modal || !state.ui.modal[id]) {
    return false;
  }

  // If state, state.ui.modal, and the modal with the specified id are defined, proceed as normal
  return state.ui.modal[id].open;
};

export const getModalMeta: (
  state: any,
  id: string
) => ModalMeta | undefined = (state, id) => state.ui.modal[id]?.meta;
