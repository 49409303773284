import { put, takeLatest, all, call } from "redux-saga/effects";
import { IExpense } from "redux/reducers/Contractor";
import {
  GET_ALL_EXPENSES_REQUEST,
  setAllExpensesReducer,
} from "redux/reducers/Contractor/actionTypes";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { ContractorExpenseService } from "services/Contractor/Timesheet/Expense";

function* requestGetAllExpenses() {
  try {
    yield put(setLoading(true));
    const [allExpenses]: IExpense[][] = yield all([
      call(ContractorExpenseService.getExpenses),
    ]);
    yield put(setAllExpensesReducer(allExpenses));
  } catch (e: any) {
    console.log(e);
    yield put(setLoading(false));
  } finally {
    yield put(setLoading(false));
  }
}

function* getAllExpenses() {
  yield takeLatest(
    GET_ALL_EXPENSES_REQUEST,
    requestGetAllExpenses
  );
}

export default getAllExpenses;
