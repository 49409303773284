import { memo, useState } from "react";
import { Controller, FieldError, FieldErrors, Merge } from "react-hook-form";
import Select, { GroupBase, StylesConfig } from "react-select";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { FormGroup, Tooltip } from "reactstrap";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

const customStyles = (
  _?: boolean
): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () => ({
    display: "none",
  }),
  container: (provided) => ({
    ...provided,
    margin: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    background: state.isSelected ? "#e8f1fb" : "#ffffff",
    color: "#2c2c2c",
    "&:hover": {
      background: "#e8f1fb",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.menuIsOpen ? "1px solid #f96332" : "1px solid #e3e3e3",
    borderRadius: "30px",
    boxShadow: "none",
    backgroundColor: state.isDisabled ? "#e3e3e3" : "unset",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      borderColor: "#f96332",
      transition: "all 0.3s ease-in-out",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: "18px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#888888",
    opacity: 0.8,
    fontSize: "0.8571em",
    fontWeight: "400",
    margin: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#888888" : "#2c2c2c",
    fontSize: "0.8571em",
    fontWeight: 400,
    margin: 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
});

interface Props extends StateManagerProps {
  className?: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrors<any>>;
  name?: string;
  control?: any;
  inputRef?: any;
  bindKey?: any;
  bindLabel?: any;
  tooltipText?: string;
}

const CustomSelect = memo(
  ({
    className,
    errorMessage,
    name,
    control,
    inputRef,
    bindKey,
    bindLabel,
    tooltipText,
    ...rest
  }: Props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const uniqueId = `CustomSelect-${name}`; // Unique ID for each select
    const { ref: refInput, ...inputProps } = inputRef || { ref: null }; 
    return (
      <FormGroup
        className={clsx(
          classes.root,
          { "has-danger": !!errorMessage },
          className
        )}
      >
        {control ? (
          <>
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <div id={uniqueId}>
                <Select
                {...field}
                styles={customStyles(!!errorMessage)}
                menuPortalTarget={document.querySelector("body")}
                getOptionValue={(option) => option[bindKey || "id"]}
                getOptionLabel={(option) => option[bindLabel || "name"]}
                noOptionsMessage={() => "..."}
                {...rest}
                />
                </div>
              )}
            />
          </>
        ) : (
          <>
            <Select
              styles={customStyles(!!errorMessage)}
              menuPortalTarget={document.querySelector("body")}
              getOptionValue={(option) => option[bindKey || "id"]}
              getOptionLabel={(option) => option[bindLabel || "name"]}
              noOptionsMessage={() => "..."}
              {...rest}
            />
          </>
        )}
        
        {errorMessage && (
          <span className="text-danger ml-2 mt-1 d-block">{errorMessage}</span>
        )}
                {/* Tooltip */}
                {tooltipText && (
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={uniqueId} // Use the unique ID here
            toggle={toggleTooltip}
          >
            {tooltipText}
          </Tooltip>
        )}
      </FormGroup>
    );
  }
);

export default CustomSelect;
