const setAuthCredentials = (data) => {
  const {
    user: { email, username },
    jwt,
  } = data || { user: {} };
  localStorage.setItem("username", username);
  localStorage.setItem("email", email);
  localStorage.setItem("jwt", jwt);
};

const removeAuthCredentials = (data) => {
  localStorage.removeItem("username");
  localStorage.removeItem("email");
  localStorage.removeItem("jwt");
};

export { setAuthCredentials, removeAuthCredentials };
