import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_TALENTS_REQUEST,
  setTalentReducer,
} from "redux/reducers/Employer/actionTypes";
import { TalentProfileService } from "services/Employer/Listing/Talent/TalentProfile";

function* requestGetTalentList() {
  try {
    yield put(setLoading(true));
    // so this one gets talent if you are authorised to do so (its internal talent only)
    //const [talentList] = yield all([call(TalentProfileService.getTalents)]);
    //this however, gets all talent, irrelavent of access, only for necta assist 
    const [talentList] = yield all([call(TalentProfileService.getAllTalents)]);
    // console.log("look HERE!" + JSON.stringify(talentList))
    yield put(setTalentReducer(talentList));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getTalentList() {
  yield takeLatest(GET_TALENTS_REQUEST, requestGetTalentList);
}

export default getTalentList;
