import { memo } from "react";
import { Form, Card, CardBody, Modal, Row, Col, ModalHeader } from "reactstrap";
import classes from "./styles.module.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { JobService } from "services/Employer/Listing/Job";
import { saveAs } from "file-saver";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { JobOfferService } from "services/Contractor/Offer/Offer";
import { ReducerType } from "redux/reducers";

interface SendContractProps {
  onClose: () => void;
  isOpen: boolean;
  contractor?: any;
  contract?: any;
  job: any;
}

const SeeContract = memo(
  ({ onClose, isOpen, contractor, contract, job }: SendContractProps) => {
    const dispatch = useDispatch();
    const { setting } = useSelector((state: ReducerType) => state.employer);

    const downloadConfirm = () => {
      dispatch(setLoading(true));
      JobService.getTemplate("ConfirmTemplate.docx")
        .then((res) => {
          const myFile = new File([res as BlobPart], "ConfirmTemplate.docx", {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          var reader = new FileReader();
          reader.readAsBinaryString(myFile);
          reader.onload = function (evt) {
            const content = evt.target.result;
            var zip = new PizZip(content);
            var doc = new Docxtemplater(zip, {
              paragraphLoop: true,
              linebreaks: true,
            });
            doc.render({
              JobTitle: job?.title,
              EmployerName: `${setting?.firstName} ${setting?.lastName}`,
              ContractorName: `${contractor?.firstName} ${contractor?.lastName}`,
              Address: `${job?.city}, ${job?.country}`,
              StartDate: moment(contract?.startDate).format("DD/MM/YYYY"),
              EndDate: moment(contract?.endDate).format("DD/MM/YYYY"),
              Duration: `${moment(contract?.endDate).diff(
                moment(contract?.startDate),
                "days"
              )} day(s)`,
              ClientCompany: job?.companyName,
              RateOffered: `$${contract?.rateOffered}`,
              NoticePeriod: contractor?.noticePeriod
                ? `${contractor.noticePeriod} week(s)`
                : contractor?.availableDate
                ? `Available from ${moment(contractor.availableDate).format(
                    "DD/MM/YYYY"
                  )}`
                : "N/A",
            });
            var blob = doc.getZip().generate({
              type: "blob",
              mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              compression: "DEFLATE",
            });
            const confirm = new File([blob], "ConfirmationOfAssignment.docx", {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            saveAs(confirm, "ConfirmationOfAssignment.docx");
          };
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    };

    return (
      <Card className={classes.card}>
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          centered
          scrollable
          className={classes.modal}
        >
          <ModalHeader toggle={onClose} className={classes.modalHeader}>
            THE OFFER'S INFORMATION
          </ModalHeader>
          <Form>
            <CardBody className={classes.cardDateBody}>
              <Row className="mb-1">
                <Col xs={12}>
                  <p className={classes.commonTitle}>
                    {contract?.offered && contract?.accepted
                      ? "The contract has been accepted!"
                      : contract?.offered && contract?.rejected
                      ? "The contract has been declined!"
                      : "The contract has not been responded yet!"}
                  </p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={6}>
                  <p className={classes.commonTitle}>Name:</p>
                </Col>
                <Col xs={6}>
                  <p className={classes.commonTitle}>
                    {contractor?.firstName} {contractor?.lastName}
                  </p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={6}>
                  <p className={classes.commonTitle}>Start date:</p>
                </Col>
                <Col xs={6}>
                  <p className={classes.commonTitle}>
                    {moment(contract?.startDate).format("DD/MM/YYYY")}
                  </p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={6}>
                  <p className={classes.commonTitle}>End date:</p>
                </Col>
                <Col xs={6}>
                  <p className={classes.commonTitle}>
                    {moment(contract?.endDate).format("DD/MM/YYYY")}
                  </p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={6}>
                  <p className={classes.commonTitle}>Rate offered:</p>
                </Col>
                <Col xs={6}>
                  <p className={classes.commonTitle}>{contract?.rateOffered}</p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={6}>
                  <p className={classes.commonTitle}>Manager name:</p>
                </Col>
                <Col xs={6}>
                  <p className={classes.commonTitle}>{contract?.managerName}</p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={6}>
                  <p className={classes.commonTitle}>Approval name:</p>
                </Col>
                <Col xs={6}>
                  <p className={classes.commonTitle}>
                    {contract?.approvalName}
                  </p>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={6}>
                  <p className={classes.commonTitle}>Purchase Order Number:</p>
                </Col>
                <Col xs={6}>
                  <p className={classes.commonTitle}>
                    {contract?.purchaseOrderNumber}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  {contract?.offered && contract?.accepted && (
                    <p
                      className={classes.downloadBtn}
                      onClick={downloadConfirm}
                    >
                      Download the Confirmation of Assignment
                    </p>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Form>
        </Modal>
      </Card>
    );
  }
);

export default SeeContract;
