import { useState } from 'react';
import { formatDate } from '../../../utils/dateUtils';
import classes from '../styles.module.scss';

const UserInfo = ({ user, messageInfo, handleBackListMobile }) => {
  const [showDescription, setShowDescription] = useState(false);

  // for testing
  // for (const key in user) {
  // 	console.log(`${key}: ${user[key]}`)
  // }
  // console.log(`User type is: ${user.type}`)
  // console.log(`MessageInfo type is: ${messageInfo.usertype}`)
  // for (const key in messageInfo) {
  // 	console.log(`MessgeInfo::: ${key}: ${messageInfo[key]}`)
  // }

  const localUserIsContractor = user.type === 'contractor'; // true if the "local"(user on state/redux) user is a contractor, so we can render the other user's details
  const jobIsContract = messageInfo.job_type === 'Contract'; // true if the job being offered is a contract
// console.log(messageInfo)
  // wrapping the output from the field and providing a fallback value for the user to see. Best to move and expand this logic to handle errors
  const renderField = (value) => (value ? value : 'Not specified');

  return (
    <div className={classes.descriptionContainer}>
      

      <div className={classes.descriptionHeader}>
          <div className={classes.btnBack}>
            <i className='fa fa-chevron-left' onClick={handleBackListMobile} />
          </div>
          <div className={classes.descriptionTitle} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            {localUserIsContractor
              ? messageInfo.employer_name
                ? [
                    <span>{renderField(messageInfo.employer_job_title)}</span>,
                    <span>{renderField(messageInfo.employer_company)}</span>
                  ]
                : null
              : messageInfo.candidate_name
                ? [
                    <span>{renderField(messageInfo.candidate_name)}</span>,
                    <span>{renderField(messageInfo.candidate_title)}</span>
                  ]
                : null
            }
          </div>
        </div>


      <div className={classes.descriptionContentWrapper}>
        {showDescription && (
          <>
            {user.role && (
              <div className={classes.descriptionContent}>
                {`- Location: ${
                  localUserIsContractor
                    ? renderField(messageInfo.employer_location)
                    : renderField(messageInfo.candidate_location)
                }`}
              </div>
            )}

            <div className={classes.descriptionContent}>
              {localUserIsContractor
                ? jobIsContract
                  ? `- Offered Rate: ${renderField(
                      messageInfo.employer_job_rate
                        ? `$${messageInfo.employer_job_rate} per hour`
                        : null
                    )}`
                  : `- Offered Salary: ${renderField(
                      messageInfo.employer_job_salary
                        ? `$${messageInfo.employer_job_salary}`
                        : null
                    )}`
                : jobIsContract
                ? `- Preferred Rate: ${renderField(
                    messageInfo.candidate_preferred_rate
                      ? `$${messageInfo.candidate_preferred_rate} per hour`
                      : null
                  )}`
                : `- Preferred Salary: ${renderField(
                    messageInfo.candidate_preferred_salary
                      ? `$${messageInfo.candidate_preferred_salary}`
                      : null
                  )}`}
            </div>

            {user.role && (
              <div className={classes.descriptionContent}>
                {`- Work policy: ${
                  localUserIsContractor
                    ? renderField(messageInfo.employer_work_policy)
                    : renderField(messageInfo.candidate_work_policy)
                }`}
              </div>
            )}

            {user.role && (
              <div className={classes.descriptionContent}>
                {localUserIsContractor
                  ? `- Starting Date: ${formatDate(renderField(messageInfo.employer_starting_date))}`
                  : messageInfo.notice_period === null
                  ? `- Available: ${formatDate(messageInfo.available_date)}`
                  : `- Notice Period: ${messageInfo.notice_period} weeks`}
              </div>
            )}

            {user.role && messageInfo.job_type && (
              <div className={classes.descriptionContent}>
                {`- Engagement: ${messageInfo.job_type}`}
              </div>
            )}
          </>
        )}
      {!(messageInfo.employer_job_title === "AI Assistant" || messageInfo.candidate_title === "AI Assistant") && (
        <div className={classes.descriptionIcon}>
          {showDescription ? (
            <i
              className='fa fa-arrow-alt-circle-up'
              onClick={() => setShowDescription(false)}
            />
          ) : (
            <i
              className='fa fa-arrow-alt-circle-down'
              onClick={() => setShowDescription(true)}
            />
          )}
        </div>)}
      </div>
    </div>
  );
};

export default UserInfo;
