import { memo } from "react";
import classes from "./styles.module.scss";
import clsx from "clsx";
// import { Button, Form } from "reactstrap";
import FooterMobile from "components/Footers/FooterMobile";
// import { useDispatch, useSelector } from "react-redux";
// import { ReducerType } from "redux/reducers";
// import { ChatEngine, sendMessage, ChatEngineContext } from "react-chat-engine";
// import { useForm } from "react-hook-form";
// import { JobOfferService } from "services/Contractor/Offer/Offer";
// import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
// import CustomInput from "components/Common/CustomInput";
// import { getAllChats } from "redux/reducers/Contractor/actionTypes";
// import { NumberOfExperience } from "models/Employer/Listings/Dashboard";
import ChatApp from "../../../components/ChatApp/ChatApp";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const Messenger = memo(({}: Props) => {
  // const dispatch = useDispatch();
  // const { user } = useSelector((state: ReducerType) => state.user);

  // const { activeChat, setActiveChat } = useContext<any>(ChatEngineContext);

  // const [showDescription, setShowDescription] = useState(true);
  // const [jobDescriptions, setJobDescriptions] = useState([]);
  // const [employer, setEmployer] = useState(null);

  // const { register, watch, reset, handleSubmit } = useForm({
  //   mode: "onChange",
  // });

  // useEffect(() => {
  //   dispatch(getAllChats(user));
  // }, []);

  // useEffect(() => {
  //   dispatch(setLoading(true));
  //   JobOfferService.getAllOffersJobs(user.id)
  //     .then((res) => {
  //       setJobDescriptions(res);
  //     })
  //     .catch((err) => dispatch(setErrorMess(err)))
  //     .finally(() => dispatch(setLoading(false)));
  // }, [dispatch, user]);

  // const sendMessageNow = (data, creds, chatID, username) => {
  //   if (watch("sendMessage")) {
  //     sendMessage(creds, chatID, {
  //       attachments: [],
  //       files: [],
  //       created: new Date().toISOString(),
  //       text: `${watch("sendMessage")}`,
  //       sender_username: username,
  //     });
  //     reset({
  //       sendMessage: "",
  //     });
  //   }
  // };

  // const handleChatCard = (chat) => {
  //   dispatch(getAllChats(user, chat?.id));
  //   setActiveChat(chat.id);
  //   if (window.innerWidth <= 576) {
  //     if (
  //       document
  //         .querySelector(".ce-chat-feed-column")
  //         .classList.contains("d-none")
  //     ) {
  //       document
  //         .querySelector(".ce-chat-feed-column")
  //         .classList.remove("d-none");
  //     }
  //     if (
  //       !document
  //         .querySelector(".ce-chat-list-column")
  //         .classList.contains("d-none")
  //     ) {
  //       document.querySelector(".ce-chat-list-column").classList.add("d-none");
  //     }
  //   }
  // };

  // const handleBackListMobile = () => {
  //   if (window.innerWidth <= 576) {
  //     if (
  //       !document
  //         .querySelector(".ce-chat-feed-column")
  //         .classList.contains("d-none")
  //     ) {
  //       document.querySelector(".ce-chat-feed-column").classList.add("d-none");
  //     }
  //     if (
  //       document
  //         .querySelector(".ce-chat-list-column")
  //         .classList.contains("d-none")
  //     ) {
  //       document
  //         .querySelector(".ce-chat-list-column")
  //         .classList.remove("d-none");
  //     }
  //   }
  // };

  // const getDateTime = (date, offset) => {
  //   if (!date) return "";

  //   date = date.replace(" ", "T");
  //   offset = offset ? offset : 0;

  //   const year = date.substr(0, 4);
  //   const month = date.substr(5, 2);
  //   const day = date.substr(8, 2);
  //   const hour = date.substr(11, 2);
  //   const minute = date.substr(14, 2);
  //   const second = date.substr(17, 2);

  //   var d = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
  //   d.setHours(d.getHours() + offset);
  //   return d;
  // };

  // const formatTime = (dateTime) => {
  //   var time = dateTime.toLocaleString("en-US");
  //   return time.split(" ")[1].slice(0, -3) + " " + time.slice(-2);
  // };

  // const formatDate = (dateTime) => {
  //   const options = {
  //     weekday: "short",
  //     month: "short",
  //     day: "numeric",
  //     year: "numeric",
  //   };
  //   return dateTime.toLocaleString("en-US", options);
  // };

  // const formatDateTime = (dateTime) => {
  //   return formatTime(dateTime) + ", " + formatDate(dateTime);
  // };

  // useEffect(() => {
  //   if (window.innerWidth <= 576) {
  //     if (
  //       document.querySelector(".ce-chat-feed-column") &&
  //       document.querySelector(".ce-chat-list-column")
  //     ) {
  //       if (
  //         !document
  //           .querySelector(".ce-chat-feed-column")
  //           .classList.contains("d-none")
  //       ) {
  //         document
  //           .querySelector(".ce-chat-feed-column")
  //           .classList.add("d-none");
  //       }
  //       if (
  //         document
  //           .querySelector(".ce-chat-list-column")
  //           .classList.contains("d-none")
  //       ) {
  //         document
  //           .querySelector(".ce-chat-list-column")
  //           .classList.remove("d-none");
  //       }
  //     }
  //   } else if (window.innerWidth > 576) {
  //     if (
  //       document.querySelector(".ce-chat-list-column") &&
  //       document.querySelector(".ce-chat-feed-column")
  //     ) {
  //       if (
  //         document
  //           .querySelector(".ce-chat-list-column")
  //           .classList.contains("d-none")
  //       ) {
  //         document
  //           .querySelector(".ce-chat-list-column")
  //           .classList.remove("d-none");
  //       }
  //       if (
  //         document
  //           .querySelector(".ce-chat-feed-column")
  //           .classList.contains("d-none")
  //       ) {
  //         document
  //           .querySelector(".ce-chat-feed-column")
  //           .classList.remove("d-none");
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [window.innerWidth, dispatch]);

  return (
    <>
      <ScrollNavbar />
      <div className={classes.wrapper}>
        <div className={classes.header} />
        <div>
          <ChatApp key={1} />
        </div>
      </div>
      <FooterMobile />
    </>
  );
});

export default Messenger;
