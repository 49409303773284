import React, { useEffect, useState, useRef } from 'react';
import { FormGroup } from 'reactstrap';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.module.scss'; 

interface CustomHtmlProps {
  defaultValue?: string;
  errorMessage?: any; // Allow undefined
  placeholder?: string;
  onChange?: (content: string) => void;
  value?: string;
  style?: React.CSSProperties; // Add this line to accept style prop
}

const CustomHtml: React.FC<CustomHtmlProps> = ({ value, errorMessage, placeholder, onChange = () => {}, style }) => {
  const quillRef = useRef<ReactQuill>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const quillInstance = quillRef.current;
    if (quillInstance) {
      const editor = quillInstance.getEditor();
      editor.root.addEventListener('focus', () => setIsFocused(true));
      editor.root.addEventListener('blur', () => setIsFocused(false));

      return () => {
        editor.root.removeEventListener('focus', () => setIsFocused(true));
        editor.root.removeEventListener('blur', () => setIsFocused(false));
      };
    }
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <FormGroup>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={modules}
        style={{
          border: isFocused ? '1px solid #f96332' : '1px solid #e3e3e3',
          borderRadius: '20px',
          ...style, // Apply the custom styles passed in through the style prop
        }}
      />
      {errorMessage && <span className="text-danger ml-2 mt-1 d-block">{errorMessage}</span>}
    </FormGroup>
  );
};

export default CustomHtml;
