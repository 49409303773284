import { memo, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { JobService } from "services/Employer/Listing/Job";
import SignaturePad from "signature_pad";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import classes from "./styles.module.scss";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import ImageModule from "docxtemplater-image-module-free";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import { saveAs } from "file-saver";

interface SignTermProps {
  onCloseSignTermModal: () => void;
  handleAcceptTerm: (data) => Promise<void>;
  contractData: any;
}

const SignTerm = memo((props: SignTermProps) => {
  const { handleAcceptTerm, onCloseSignTermModal, contractData } = props;
  const { user } = useSelector((state: ReducerType) => state.user);

  const dispatch = useDispatch();
  const { setting } = useSelector((state: ReducerType) => state.employer);

  const [signature, setSignature] = useState(null);

  useEffect(() => {
    const canvas = document.getElementById("canvas");
    function resizeCanvas() {
      // When zoomed out to less than 100%, for some very strange reason,
      // some browsers report devicePixelRatio as less than 1
      // and only part of the canvas is cleared then.
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      (canvas as any).width = canvas.offsetWidth * ratio;
      (canvas as any).height = canvas.offsetHeight * ratio;
      (canvas as any).getContext("2d").scale(ratio, ratio);
    }
    if (canvas) {
      window.onresize = resizeCanvas;
      resizeCanvas();
      const signaturePad = new SignaturePad(canvas as any, {
        backgroundColor: "#ffffff",
        penColor: "#000000",
      });
      document.getElementById("clear").addEventListener("click", () => {
        signaturePad.clear();
      });
      document.getElementById("undo").addEventListener("click", () => {
        var data = signaturePad.toData();
        if (data) {
          data.pop();
          signaturePad.fromData(data);
        }
      });
      document
        .getElementById("submit-contract")
        .addEventListener("click", () => {
          const data = signaturePad.toDataURL("image/png");
          setSignature(data);
        });
    }
  }, []);

  const handleSubmit = (res) => {
    return new Promise(() => {
      const myFile = new File([res as BlobPart], "Terms-Of-Business-V2.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
      function base64Parser(dataURL) {
        if (typeof dataURL !== "string" || !base64Regex.test(dataURL)) {
          return false;
        }
        const stringBase64 = dataURL.replace(base64Regex, "");
        const binaryString = window.atob(stringBase64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes.buffer;
      }
      const imageOpts = {
        getImage(tag) {
          return base64Parser(tag);
        },
        getSize() {
          return [210, 120];
        },
      };
      var reader = new FileReader();
      reader.readAsBinaryString(myFile);
      reader.onload = async function (evt) {
        const content = evt.target.result;
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
        doc.render({
          Client: `${setting?.firstName} ${setting?.lastName}`,
          Company: setting?.name,
        });
        doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          modules: [new ImageModule(imageOpts)],
          delimiters: { start: "[", end: "]" },
        });
        await doc.renderAsync({
          signature,
        });
        var blob = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          compression: "DEFLATE",
        });
        await JobService.postContract(
          blob,
          `${user?.id}-${S3_UPLOAD_TYPE_PRIFIX.TERMS_OF_BUSINESS}`
        )
          .then(async () => {
            const myTermsOfBusiness = new File(
              [blob],
              "SignedTermOfBusiness.docx",
              {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              }
            );
            await handleAcceptTerm(contractData);
            saveAs(myTermsOfBusiness, "SignedTermOfBusiness.docx");
          })
          .catch((e) => dispatch(setErrorMess(e)))
          .finally(() => {
            dispatch(setLoading(false));
            onCloseSignTermModal();
          });
      };
    });
  };

  const handleSubmitContract = async () => {
    dispatch(setLoading(true));

    JobService.getTemplate("Terms-Of-Business-V2.docx")
      .then(async (res) => {
        await handleSubmit(res);
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    if (signature) {
      handleSubmitContract();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  return (
    <Card className={classes.card}>
      <CardBody className={classes.cardBody}>
        <CardTitle className={classes.title}>SIGN HERE</CardTitle>
      </CardBody>
      <CardBody className={classes.canvasContainer}>
        <canvas
          id="canvas"
          className={classes.canvas}
          width={350}
          height={200}
        />
      </CardBody>
      <div className={classes.canvasButtons}>
        <Button size="sm" color="info" type="button" id="undo">
          Undo
        </Button>
        <Button size="sm" color="info" type="button" id="clear">
          Clear
        </Button>
      </div>
      <CardBody className={classes.cardBody}>
        <div className={classes.buttonContainer}>
          <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={onCloseSignTermModal}
          >
            Back
          </Button>
          <Button
            id="submit-contract"
            size="md"
            color="primary"
            type="button"
            className={classes.btn}
          >
            Submit
          </Button>
        </div>
      </CardBody>
    </Card>
  );
});

export default SignTerm;
