import { memo, useEffect, useMemo } from "react";
import { Form, Button, Row, CardHeader, Card, FormGroup } from "reactstrap";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSelect from "components/Common/CustomSelect";
import CustomDatePicker from "components/Common/CustomDatePicker";
import { SET_TIME, NOTICE_PERIOD, WORK_FROM_HOME } from "config/constants";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { PreferenceService } from "services/Contractor/NewMember/Preference";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { UtilContractor } from "utils/contractor";
import {
  PreferenceAvailableTypes,
  PreferencePolicyTypes,
  PreferenceTravelingRadius,
} from "models/Contractor/NewMember/Preference";
import { ReducerType } from "redux/reducers";
//import { UtilChatEngine } from "utils/chatEngine";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";
import CustomSlider from "components/Common/CustomSlider";
import clsx from "clsx";
import moment from "moment";
import { setUserReducer } from "redux/reducers/User/actionTypes";
import { setWelcome } from 'redux/reducers/Status/actionTypes';

interface Props {}

interface PreferenceFormData {
  id?: number;
  availableDate?: string;
  minHourlyRate: number;
  hourlyRate: number;
  radius?: number;
  availableType: {
    id: number;
    name: string;
  };
  setTime?: Date;
  noticePeriod?: number;
  policy?: {
    id: number;
    name: string;
    value: string;
  };
  travellingRadius?: {
    id: number;
    name: string;
    value: number;
  };
}

// eslint-disable-next-line no-empty-pattern
const Preference = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);
console.log(profile)
  const schema = useMemo(() => {
    return yup.object().shape({
      minHourlyRate: yup
        .number()
        .typeError("Please enter a number")
        .min(30, "At least $30")
        .max(300, "At most $300")
        .required("This field is required"),
      hourlyRate: yup
        .number()
        .typeError("Please enter a number")
        .min(30, "At least $30")
        .max(300, "At most $300")
        .required("This field is required"),
      availableType: yup.object().required("This field is required"),
      setTime: yup
        .date()
        .nullable()
        .when("availableType", {
          is: (type) => type?.id === SET_TIME,
          then: yup
            .date()
            .typeError("Please enter a valid date")
            .required("This field is required"),
        }),
      policy: yup.object().required("This field is required"),
      travellingRadius: yup
        .object()
        .nullable()
        .when("policy", {
          is: (type) => !!type && type?.id !== WORK_FROM_HOME,
          then: yup.object().required("This field is required"),
        }),
    });
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    watch,
    reset,
    setValue,
  } = useForm<PreferenceFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const availableType = watch("availableType");
  const policy = watch("policy");
  const travellingRadius = watch("travellingRadius");

  useEffect(() => {
    if (availableType?.id === SET_TIME) {
      resetField("noticePeriod");
      setValue("noticePeriod", null);
    } else if (availableType?.id === NOTICE_PERIOD) {
      resetField("setTime");
      setValue("setTime", null);
    }
    // eslint-disable-next-line
  }, [availableType]);

  useEffect(() => {
    if (policy?.id === WORK_FROM_HOME && travellingRadius) {
      resetField("travellingRadius");
    }
    // eslint-disable-next-line
  }, [policy]);

  useEffect(() => {
    if (profile?.preference) {
      reset({
        id: profile.preference.id,
        setTime: new Date(profile.preference.availableDate),
        availableType: UtilContractor.getCurrentAvailableType(
          profile.preference.availableDate,
          profile.preference.noticePeriod
        ),
        minHourlyRate: profile.preference.minRate,
        hourlyRate: profile.preference.maxRate,
        travellingRadius: UtilContractor.getCurrentTravellingRadius(
          profile.preference.radius
        ),
        policy: UtilContractor.getCurrentWorkPolicy(
          profile.preference.workPolicy
        ),
      });
    } else {
      reset({
        minHourlyRate: 30,
        hourlyRate: 30,
      });
    }
  }, [profile, reset]);

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.skill));
  };

  const onSubmit = (data: PreferenceFormData) => {
    dispatch(setLoading(true));
  
    const preferenceParams = {
      availableDate: data?.setTime?.toISOString(),
      noticePeriod: 4, // Default to 4
      minRate: data?.minHourlyRate,
      maxRate: data?.hourlyRate,
      radius: data?.travellingRadius?.value,
      workPolicy: data?.policy?.value,
    };
  
    if (data?.availableType?.name === "A specific date") {
      preferenceParams.noticePeriod = 2;
      preferenceParams.availableDate = data?.setTime?.toISOString();
    } else if (data?.availableType?.name === "Two weeks notice") {
      preferenceParams.noticePeriod = 2;
      preferenceParams.availableDate = null;
    } else {
      preferenceParams.noticePeriod = 4; // Default to 4 if no specific type is matched
    }
  
    const handleResponse = (res: any) => {
      dispatch(setProfileReducer({ ...profile, preference: res }));
      dispatch(push(routes.contractor.newMember.futureOpportunities));
    };
  
    const handleError = (e: any) => {
      dispatch(setErrorMess(e));
    };
    // console.log("data", data)
    if (data?.id) {
      // console.log("PUT preferenceParams", preferenceParams);
      PreferenceService.putPreference(preferenceParams, data.id)
        .then((res) => {
          handleResponse(res);
          dispatch(setUserReducer({ ...user, preferenceCompleted: true }));
        })
        .catch(handleError)
        .finally(() => dispatch(setLoading(false)));
    } else {
      PreferenceService.postPreference(preferenceParams)
        .then((res) => {
          // console.log("POST", preferenceParams);
          dispatch(setUserReducer({ ...user, preferenceCompleted: true }));
          handleResponse(res);
          // console.log("res", res);
          dispatch(setWelcome(true));
        })
        .catch(handleError)
        .finally(() => dispatch(setLoading(false)));
    }
  };
  

  const renderAvailableInput = () => {
    switch (availableType?.id) {
      case SET_TIME:
        return (
          <CustomDatePicker
            className={classes.subField}
            name="setTime"
            control={control}
            placeholder="Pick a date..."
            dateFormat="dd/MM/yyyy"
            errorMessage={errors.setTime?.message}
            minDate={moment().toDate()}
            maxDate={moment().add(1, "y").toDate()}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Card className={classes.card}>
      <div className={classes.cardContainer}>
        <CardHeader>
          <p className={classes.title}>What does good look like?</p>
          <p className={classes.subtitle}>You can always change later.</p>
        </CardHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.content}>
            <FormGroup className={classes.section}>
              <span className={clsx("ml-1 mb-3", classes.rateTitle)}>
                Hourly rate no lower than $...:
              </span>
              <CustomSlider
                name="minHourlyRate"
                min={30}
                max={300}
                control={control}
                className={classes.sliderRate}
                errorMessage={errors.minHourlyRate?.message}
              />
            </FormGroup>
            <FormGroup className={classes.section}>
              <span className={clsx("ml-1 mb-3", classes.rateTitle)}>
                Preferred Hourly Rate $...:
              </span>
              <CustomSlider
                name="hourlyRate"
                min={30}
                max={300}
                control={control}
                className={classes.sliderRate}
                errorMessage={errors.hourlyRate?.message}
              />
            </FormGroup>
            <FormGroup className={classes.section}>
              <CustomSelect
                placeholder="I am available on..."
                name="availableType"
                control={control}
                options={PreferenceAvailableTypes}
                errorMessage={errors.availableType?.message}
              />
              {renderAvailableInput()}
            </FormGroup>
            <FormGroup className={classes.section}>
              <CustomSelect
                placeholder="My policy is..."
                name="policy"
                control={control}
                options={PreferencePolicyTypes}
                errorMessage={errors.policy?.message}
                tooltipText="Choose 'Office' if you prefer working solely from the Office. Opt for 'Hybrid' is you'd like to negotiate a mix of remote and office work. Select 'Work From Home' if you exclusively want to work Remotely. 'All' signifies openness to any work arrangement based on mutual agreement."
              />
            </FormGroup>
            <FormGroup className={classes.section}>
              <CustomSelect
                isDisabled={!policy || policy?.id === WORK_FROM_HOME}
                placeholder="My travelling radius is..."
                name="travellingRadius"
                control={control}
                value={
                  policy?.id === WORK_FROM_HOME || !travellingRadius
                    ? null
                    : travellingRadius
                }
                options={PreferenceTravelingRadius}
                errorMessage={errors.travellingRadius?.message}
                tooltipText="Please indicate the maximum distance you are willing to travel to work each day."
              />
            </FormGroup>
          </div>
          <Row className="justify-content-between p-0 m-0">
            <Button
              size="md"
              color="default"
              type="button"
              className={classes.btn}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              size="md"
              color="primary"
              type="submit"
              className={classes.btn}
            >
              Next
            </Button>
          </Row>
        </Form>
      </div>
    </Card>
  );
});

export default Preference;
