import { API } from "aws-amplify";

export class StripeService {
  static async postCreateClient(payload): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/stripe/create-customer`; // Update to your actual endpoint path
    const myInit = {
      response: true,
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data || e);
      });
  }

  static async putUpdateCard(payload): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/stripe/update-card`; // Update to your actual endpoint path
    const myInit = {
      response: true,
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        // console.log(res)
        return Promise.resolve(res);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data || e);
      });
  }


  static async putCancel(payload): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/stripe/cancel`; 
    const myInit = {
      response: true,
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        // console.log(res)
        return Promise.resolve(res);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data || e);
      });
  }

  static async getProducts(): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/stripe/products`; 
    const myInit = {
      response: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data || e);
      });
  }


  static async postSubscription(orgid): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/stripe/subscription-status`;
    const myInit = {
      response: true,
      body: orgid,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
