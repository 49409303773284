import React, { Fragment, memo, useEffect, useState } from 'react';
import { Button, Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes';
import { JobService } from 'services/Employer/Listing/Job';
import { ReducerType } from 'redux/reducers';
import Client from './AddClient';
import AddNewJob from './AddEditJob';
import CreateProject from './AddProject';
import { useModal } from 'components/Modals';
import {
  setDeclinedCandidatesReducer,
  setJobListReducer,
  setJobOfListingReducer,
  getAllCandidateList,
} from 'redux/reducers/Employer/actionTypes';
import classes from './styles.module.scss';
import {
  getJobList, getSetting
} from "redux/reducers/Employer/actionTypes";

interface DashboardProps {}

const MyClientjobs = memo((props: DashboardProps) => {
  const dispatch = useDispatch();
  const { jobList, interviewOffers, jobOffers } = useSelector((state: ReducerType) => state.employer);
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  const [data, setData] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [jobToEdit, setJobToEdit] = useState(null);
  const [isProject, setIsProject] = useState(false);
  const [rawClientData, setRawClientData] = useState([]);
  const [clients, setClients] = useState([]);
  const [allJobs, setAllJobs] = useState([]);

  useEffect(() => {
    if (jobList && jobList.length > 0) {
      const filteredJobs = jobList
        .filter(item => !item.isArchived)
        .sort((a, b) => b.id - a.id)
        .map(job => {
          const interviewCount = interviewOffers.filter(offer => offer.job === job.id).length;
          const contractCount = jobOffers.filter(offer => offer.job === job.id).length;
          return {
            ...job,
            interview: interviewCount,
            contract: contractCount
          };
        });
      setData(filteredJobs);
      setJobsLoaded(true);
    }
  }, [jobList, interviewOffers, jobOffers]);

  useEffect(() => {
    let mappedClients = [];
    JobService.getClient()
      .then(data => {
        const sortedData = data.sort((a, b) => b.id - a.id);
        setRawClientData(sortedData);
        mappedClients = sortedData.map(client => ({
          value: client.id.toString(),
          label: `${client.firstName} ${client.lastName} | ${client.company}`
        }));
        setClients(mappedClients);
        // console.log("clients", mappedClients);
  
        // Fetch all jobs after fetching clients
        return JobService.getJobAll();
      })
      .then(jobList => {
        // console.log("all jobs", jobList);
        // Extract client IDs from mapped clients
        const clientIds = mappedClients.map(client => parseInt(client.value));
        // Filter jobs based on client IDs and add client label
        const filteredJobs = jobList
          .filter(job => clientIds.includes(job.client))
          .map(job => {
            const client = mappedClients.find(client => parseInt(client.value) === job.client);
            return { ...job, clientLabel: client?.label || 'Unknown Client' };
          });
        setAllJobs(filteredJobs);
      })
      .catch(error => console.error('Error fetching clients or jobs:', error));
  }, [dispatch]);

  const handleUpgrade = ({ data }) => {
    openUpgrade({ data: data.id });
  };

  const { onOpen: openUpgrade } = useModal('Upgrade');

  const handleAddJob = (data) => {
    dispatch(setLoading(true));
    let jobResponse = null;
    let shouldPoll = false;

    JobService.postJob(data)
      .then(res => {
        jobResponse = res;
        dispatch(setJobOfListingReducer(res));
        dispatch(setJobListReducer([...jobList, res]));

        const isAssistUser = user.role === 7;
        const isPaidUser = setting?.headHunter === true;
        shouldPoll = isAssistUser || isPaidUser;

        if (shouldPoll && data?.listings === true) {
          const send = { job_id: res.id, region: data?.region?.toLowerCase() };
          JobService.headHunter(send).catch(e => {
            console.error("Error triggering headhunter:", e);
          });
        }
        triggerGitHubAction();
        if (!isAssistUser && !isPaidUser) {
          handleUpgrade({ data: { id: res.id } });
        }

        if (data.listings) {
          JobService.postSocial(res.id);
        }

        return JobService.postDeclineCandidate(res.id, {
          jobId: res.id,
          contractorIds: [],
          employer: user?.id,
        });
      })
      .then(res => {
        dispatch(setDeclinedCandidatesReducer(res));
      })
      .catch(err => {
        console.log("Error:", err);
      })
      .finally(() => {
        dispatch(setLoading(false));
        if (shouldPoll && jobResponse?.id) {
          const pollInterval = setInterval(() => {
            JobService.checkHeadHunterStatus(jobResponse.id)
              .then(statusResponse => {
                if (statusResponse === true) {
                  dispatch(getJobList());
                  clearInterval(pollInterval);
                }
              })
              .catch(pollError => {
                console.error("Error during polling:", pollError);
                clearInterval(pollInterval);
              });
          }, 3000);
        }
      });
  };

  const handleUpdateJob = (data, id) => {
    dispatch(setLoading(true));
    let shouldPoll = false;
    let jobResponse = null;

    JobService.putJob(data, id)
      .then(async (res) => {
        jobResponse = res;
        dispatch(setJobOfListingReducer(res));
        const index = jobList?.findIndex((item) => item?.id === res?.id);
        let headHunterStatus;
        const isAssistUser = user.role === 7;
        const isPaidUser = setting?.headHunter === true;
        shouldPoll = isAssistUser || isPaidUser;

        if (index !== -1) {
          const job = jobList[index];
          headHunterStatus = job?.headhunter;

          if (shouldPoll && data?.listings === true) {
            const send = { job_id: id, region: job?.region?.toLowerCase() };
            await JobService.headHunterUpdate(send, id).catch(e => {
              console.error("Error triggering headhunter:", e);
            });
            JobService.headHunter(send).catch(e => {
              console.error("Error triggering headhunter:", e);
            });
          }
        }

        triggerGitHubAction();
        dispatch(getJobList());
        dispatch(getSetting());

        return JobService.putDeclineCandidate(res.id, {
          jobId: res.id,
          contractorIds: [],
          employer: user.id,
        });
      })
      .then((res2) => {
        dispatch(setDeclinedCandidatesReducer(res2));
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => {
        dispatch(setLoading(false));
        if (shouldPoll && jobResponse?.id) {
          const pollInterval = setInterval(() => {
            JobService.checkHeadHunterStatus(jobResponse.id)
              .then(statusResponse => {
                if (statusResponse === true) {
                  dispatch(getJobList());
                  clearInterval(pollInterval);
                }
              })
              .catch(pollError => {
                console.error("Error during polling:", pollError);
                clearInterval(pollInterval);
              });
          }, 3000);
        }
      });
  };

  const triggerGitHubAction = async () => {
    const githubApiUrl = 'https://api.github.com/repos/Surge-NZ/necta/dispatches';
    const token = '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';

    const payload = {
      event_type: 'listings',
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/vnd.github.v3+json',
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(githubApiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('GitHub Actions trigger failed');
      }
    } catch (error) {
      console.error('Error triggering GitHub Actions:', error);
    }
  };

  const handleOpenAddClientForm = (item) => {
    setIsClient(true);
  };


  const handleCloseClient = () => {
    setIsClient(false);
  };

  const handleOpenAddProjectForm = () => {
    setIsProject(true);
    setIsClient(false);
  };

  const handleCloseProject = () => {
    setIsProject(false);
  };

  const getAllCandidates = async (job, isRedirectToListingJob, assist) => {
    if (!job?.id) return;

    dispatch(setLoading(true));

    const weights = {
      title: 10,
      description: 20,
      responsibilities: 40,
      technical_skills: 30,
    };

    try {
      const response = await fetch('https://j9z0sb36kk.execute-api.ap-southeast-2.amazonaws.com/prod/headhunter/rankings', {
        method: 'POST',
        body: JSON.stringify({
          job_id: String(job.id),
          weights: weights,
          request_type: isRedirectToListingJob ? "next_ten" : "next_ten",
          request_typeNumber: 0,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const json = await response.json();

      // Check if the response body is a valid JSON
      let res;
      try {
        res = JSON.parse(json.body);
        // console.log(res)
      } catch (err) {
        return;
      }

      if (!res || res.length === 0) {
        return; // Stop further execution if no candidates are found
      }
      
      // Send offers to all candidates found in the API response
      for (const candidate of res) {
        const adjustedScore = String(Math.min(candidate.scores * 4, 0.99));
        const dataSubmit = {
          job: job.id,
          contractor: candidate.user,
          employer: user?.id,
          offered: false,
          scores: adjustedScore,
        };

        try {
          await JobService.postJobOffer(dataSubmit);
        } catch (err) {
          console.error('Error sending job offer:', err);
          dispatch(setErrorMess('Error sending job offer.'));
        }
      }
      dispatch(getAllCandidateList());
    } catch (error) {
      console.error('Error fetching candidates:', error);
      dispatch(setErrorMess('Error fetching candidates.'));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (jobsLoaded) {
      const fetchAllOffers = async () => {
        try {
          const [offersResponse, otherOffersResponse] = await Promise.all([
            JobService.getAllOffers(),
            JobService.getAllOffersOther()
          ]);

          if (!offersResponse || !otherOffersResponse) {
            throw new Error('Invalid response from API');
          }

          const combinedOffers = {
            interviews: [
              ...(offersResponse.interviews || []),
              ...(otherOffersResponse.interviews || [])
            ],
            jobOffers: [
              ...(offersResponse.jobOffers || []),
              ...(otherOffersResponse.jobOffers || [])
            ]
          };

          setData(prevData => {
            if (!prevData || prevData.length === 0) {
              return prevData;
            }

            const updatedData = prevData.map(job => {
              const jobId = job.id ? job.id.toString() : '';

              if (!jobId) {
                return job;
              }

              const jobInterviews = combinedOffers.interviews.filter(offer => offer.job && offer.job.toString() === jobId);
              const jobOffersOnly = combinedOffers.jobOffers.filter(offer => offer.job && offer.job.toString() === jobId);
              const candidatesOfJob = offersResponse.concat(otherOffersResponse).filter(offer => offer.job && offer.job.toString() === jobId);

              return {
                ...job,
                candidatesOfJob: candidatesOfJob,
                jobOffers: jobOffersOnly,
                interviews: jobInterviews,
              };
            });

            return updatedData;
          });
        } catch (error) {
          console.error('Error fetching all offers:', error);
          dispatch(setErrorMess('Error fetching all offers.'));
        }
      };

      fetchAllOffers();
    }
  }, [jobsLoaded]);

  const formatNumber = (num) => {
    if (num == null) return '0';
    if (num >= 1000 && num < 10000) {
      return (num / 1000).toFixed(1) + 'k';
    } else if (num >= 10000) {
      return Math.floor(num / 1000) + 'k';
    }
    return num.toString();
  };

  const handleEditClick = (job) => {
    setJobToEdit(job);
    setIsEditMode(true);
    setIsAddMode(false);
    setIsProject(false);
    setIsClient(false);
  };

  const handleAddClick = () => {
    setJobToEdit(null);
    setIsEditMode(false);
    setIsAddMode(true);
    setIsProject(false);
    setIsClient(false);
  };

  const handleCloseEdit = () => {
    setJobToEdit(null);
    setIsEditMode(false);
    setIsAddMode(false);
  };

  const JobCard = ({ job }) => {
    const longListCount = (job.candidatesOfJob || [])
      .filter(candidate => (!candidate.rejected && !candidate.revoked)
        && !candidate.offered
        && !(job.interviews || []).some(interview => interview.job === job.id && interview.contractor === candidate.contractor)
        && !(job.jobOffers || []).some(offer => offer.job === job.id && offer.contractor === candidate.contractor))
      .length;

    const shortListCount = (job.candidatesOfJob || [])
      .filter(candidate => (!candidate.rejected && !candidate.revoked)
        && candidate.offered
        && !(job.interviews || []).some(interview => interview.job === job.id && interview.contractor === candidate.contractor)
        && !(job.jobOffers || []).some(offer => offer.job === job.id && offer.contractor === candidate.contractor))
      .length;

    const interviewCount = (job.interviews || []).length;
    const offerCount = (job.jobOffers || []).length;

    const formatSalaryOrRate = (salary, rate) => {
      if (salary) {
        return `$${salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per annum`;
      } else if (rate) {
        return `$${rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per hour`;
      }
      return '';
    };

    const handleFindClick = () => {
      const isRedirectToListingJob = true; // or false, depending on your logic
      const assist = true; // or false, depending on your logic
      getAllCandidates(job, isRedirectToListingJob, assist);
    };

    return (
      <div className={classes.jobContainer}>
        <div className={classes.jobHeader}>
          <div className={classes.jobInfo}>
            <div className={classes.jobTitle}>{job.title}</div>
            <div className={classes.jobSubTitle}>
              {formatSalaryOrRate(job.maxSalary, job.maxRate)} | {job.city} {job.country}
            </div>
            <div className={classes.clientLabel}>
              Client: {job.clientLabel}
            </div>
            {
                    job?.organization === setting?.idOrganisation && (
                       <strong><span className="mr-2">THIS JOB IS OWNED BY YOU</span></strong>
                    )}
          </div>
          <div className={classes.jobDetails}>
            <div>
              <div className={classes.candidateCircle}>{formatNumber(job.headhunterAmount)}</div>
              <div>Identified</div>
            </div>
            <div>
              <div className={classes.candidateCircle}>{longListCount}</div>
              <div>Long List</div>
            </div>
            <div>
              <div className={classes.candidateCircle}>{shortListCount}</div>
              <div>Short List</div>
            </div>
            <div>
              <div className={classes.candidateCircle}>{interviewCount}</div>
              <div>Interview</div>
            </div>
            <div>
              <div className={classes.candidateCircle}>{offerCount}</div>
              <div>Offer</div>
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          <NavLink to={`/employer/admin/myclientjobs/${job.id}`}>
            <Button color="primary" onClick={handleFindClick}>Manage</Button>
          </NavLink>
          <Button color="secondary" onClick={() => handleEditClick(job)}>Edit</Button>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Container className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.title}>Clients Opportunities</div>
          <p>
            An overview of your clients opportunities for diagnosis and assistance.
          </p>
          <div className={classes.buttons}>
            {(user.role === 7) && (
              <Button
                color='success'
                size='sm'
                onClick={() => handleOpenAddClientForm(setting)}
                className={classes.btnContent}
              >
                <i className='now-ui-icons ui-1_simple-add mr-1' /> View Clients
              </Button>
            )}
            <Button color="primary" onClick={handleAddClick}>Add New Opportunity</Button>
          </div>
        </div>

        {
          isClient ? (
            <Client onClose={handleCloseClient} />
          ) : isProject ? (
            <CreateProject onClose={handleCloseProject} />
          ) : (
            isEditMode || isAddMode ? (
              <AddNewJob
                isOpen={isEditMode || isAddMode}
                onClose={handleCloseEdit}
                itemEdit={isEditMode ? jobToEdit : null}
                handleAddJob={(data) => {
                  handleAddJob(data);
                  handleCloseEdit();
                }}
                handleUpdateJob={(data, id) => {
                  handleUpdateJob(data, id);
                  handleCloseEdit();
                }}
              />
            ) : (
              allJobs.map((job) => (
                <JobCard key={job.id} job={job} />
              ))
            )
          )
        }
      </Container>
    </Fragment>
  );
});

export default MyClientjobs;
