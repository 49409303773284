import { memo, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Container, Table } from "reactstrap";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import classes from "./styles.module.scss";
import FooterMobile from "components/Footers/FooterMobile";
import SelectYear from "components/Common/SelectYear";
import clsx from "clsx";
import AddNewExpense from "./AddExpense";
import { ContractorExpenseService } from "services/Contractor/Timesheet/Expense";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import moment from "moment";
import { ReducerType } from "redux/reducers";
import { setAllExpensesReducer } from "redux/reducers/Contractor/actionTypes";
import { fCurrency } from "utils/price";

interface Props {}

const Expense = memo((props: Props) => {
  const dispatch = useDispatch();
  const { allExpenses } = useSelector((state: ReducerType) => state.contractor);

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);

  const handleOpenAddExpenseForm = () => {
    setIsOpenAddModal(true);
  };

  const onCloseAddModal = () => {
    setIsOpenAddModal(false);
  };

  const handleAddExpense = (data, name) => {
    dispatch(setLoading(true));
    ContractorExpenseService.postExpense(data)
      .then((res) => {
        dispatch(setAllExpensesReducer([...allExpenses, {...res, name: name}]))
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <>
      <ScrollNavbar />
      <div
        className={clsx("page-header header-filter", classes.wrapper)}
        filter-color="yellow"
      >
        <div className={classes.header} />
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg32.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.container}>
            <Card className={classes.card}>
              <div className={classes.buttonContainer}>
                <SelectYear
                  placeholder="Year"
                  options={[{ id: 1, name: "April 2022 - March 2023" }]}
                />
                <p
                  className={classes.addButton}
                  onClick={() => handleOpenAddExpenseForm()}
                >
                  <i className="now-ui-icons ui-1_simple-add" />
                  New Expenses
                </p>
              </div>
              <CardHeader>
                <div className={classes.awaitingPaymentSection}>
                  <p className={classes.sectionItem}>Awaiting Payment</p>
                  <p className={classes.sectionItem}>{fCurrency(allExpenses.map((expense) => !(expense?.isPaid) ? Number(expense?.total) : 0).reduce((partialSum, a) => partialSum + a, 0))}</p>
                </div>
                <div className={classes.paidSection}>
                  <p className={classes.sectionItem}>Paid</p>
                  <p className={classes.sectionItem}>{fCurrency(allExpenses.map((expense) => (expense?.isPaid) ? Number(expense?.total) : 0).reduce((partialSum, a) => partialSum + a, 0))}</p>
                </div>
                <hr className={classes.divider} />
              </CardHeader>
              <CardBody className={classes.cardBody}>
                <div className={classes.tableContainer}>
                  <Table className={classes.table} responsive>
                    <thead>
                      <tr>
                        <th className={classes.statusItem}>STATUS</th>
                        <th className={classes.hideOnMobile}>DATE</th>
                        <th className={classes.vendorItem}>VENDOR</th>
                        <th className={classes.hideOnMobile}>NET</th>
                        <th className={classes.hideOnMobile}>GST</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allExpenses.map((item, index) => (
                        <tr key={index}>
                          <td className={classes.hideOnMobile}>
                            {item.isPaid ? "Paid" : "Awaiting"}
                          </td>
                          <td className={classes.hideOnDesktop}>
                            <div
                              className={
                                item?.isPaid
                                  ? classes.paidStatus
                                  : classes.approvedStatus
                              }
                            />
                          </td>
                          <td className={classes.hideOnMobile}>
                            {moment(item?.dateOfExpense).format(`DD/MM/YYYY`)}
                          </td>
                          <td>{item?.name}</td>
                          <td className={classes.hideOnMobile}>{fCurrency(Number(item?.net))}</td>
                          <td className={classes.hideOnMobile}>{fCurrency(Number(item?.net * item?.gst / 100))}</td>
                          <td>${Number(item?.total).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
        <Footer />
      </div>
      <FooterMobile />
      <AddNewExpense
        onClose={onCloseAddModal}
        isOpen={isOpenAddModal}
        handleAddExpense={handleAddExpense}
      />
    </>
  );
});

export default Expense;
