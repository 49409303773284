/*eslint-disable*/
import { memo } from "react";
import { Link, useParams } from "react-router-dom";
import { routesOutside } from "routers/routes";
import { Container } from "reactstrap";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import { USER_TYPE } from "config/constants";

interface Props {
  className?: string;
}

const Footer = memo((props: Props) => {
  const { className } = props;

  const { user } = useSelector((state: ReducerType) => state.user);

  return (
    <footer className={clsx("footer", className, classes.footer)}>
      <Container className={classes.container}>
        <nav>
          <ul>
            <li>
              <Link to={routesOutside.aboutUs} target='_blank'>
                About Us
              </Link>
            </li>
            <li>
              <Link
                to={
                  window?.location?.pathname?.split("/")[1] ===
                  USER_TYPE.CONTRACTOR
                    ? routesOutside.contractorBlog
                    : routesOutside.employerBlog
                }
                target='_blank'
              >
                Blog
              </Link>
            </li>
            <li>
              <Link to={routesOutside.policy} target='_blank'>
                Policies
              </Link>
            </li>
          </ul>
        </nav>
        <div className={`copyright ${classes.copyright}`} id='copyright'>
          © {new Date().getFullYear()}{" "}
          <a href='https://www.necta.nz' target='_blank'>
            Necta
          </a>
        </div>
      </Container>
    </footer>
  );
});

export default Footer;
