import axios from "axios";
import moment from "moment";
import jwt_decode from "jwt-decode";
import {
  removeAuthCredentials,
  setAuthCredentials,
} from "../../LocalStorageService";

class StrapiAuthService {
  /**
   * login with strapi using email and password
   * @param {*} username
   * @param {*} password
   */
  async login(username, password) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/local`,
        {
          username,
          password,
        }
      );
      setAuthCredentials(response.data);
      return response;
    } catch (error) {
      console.log("error signing in", error);
    }
  }

  /**
   * login with google
   */
  loginGoogle() {
    window.location = `${process.env.REACT_APP_BACKEND_URL}/api/connect/google`;
  }

  /**
   * log out with amplify
   */
  async logout() {
    try {
      removeAuthCredentials();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  /**
   * login in to strapi with federated callback token
   * @param {*} provider
   * @param {*} token
   */
  async loginWithCode(token, provider) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/${provider}/callback${token}`
      );
      setAuthCredentials(response.data);
      return response;
    } catch (error) {
      console.log("error login with code: ", error);
    }
  }

  /**
   * get current users jwt token from the local storage
   * @returns
   */
  getCurrentUser() {
    try {
      const jwt = localStorage.getItem("jwt");
      const decoded = jwt_decode(jwt);
      if (moment().isBefore(moment(decoded.exp))) {
        return null;
      } else {
        return jwt;
      }
    } catch (e) {
      return null;
    }
  }
}

const strapiAuthService = new StrapiAuthService();

export default strapiAuthService;
