/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, useEffect, useState } from "react";
import {
  Button,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  Container,
} from "reactstrap";
import classes from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { JobOfferService } from "services/Contractor/Offer/Offer";
import {
  setErrorMess,
  setLoading,
} from "redux/reducers/Status/actionTypes";
import { ReducerType } from "redux/reducers";
import TinderCard from "react-tinder-card";
import clsx from "clsx";
import { ImageService } from "services/Image";
//import { UtilChatEngine } from "utils/chatEngine";
import { ListingEmailService } from "services/Employer/Listing/Email";
import { EmailTypes } from "models/Employer/Listings/Dashboard";
import { getAllOffers } from "redux/reducers/Contractor/actionTypes";
import { UtilContractor } from "utils/contractor";

interface ConnectProps {}

const Connect = memo((props: ConnectProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { allOffers } = useSelector((state: ReducerType) => state.contractor);

  const [offerList, setOfferList] = useState([]);
  const [countItem, setCountItem] = useState(0);
  const [activeItem, setActiveItem] = useState(offerList[0]);
  const [animating, setAnimating] = useState(false);
  const [tinderList, setTinderList] = useState([]);

  const handleProgressOffer = (jobItem) => {
    // console.log("Apply")
    setCountItem(countItem + 1);
   
  };

  const handleDeclineOffer = (jobItem) => {
    // console.log("decline")
    setCountItem(countItem + 1);
    
  };

  const handleRemoveStamp = (index) => {
    let tinderCardItem = document.getElementById(`tinder-card-${index}`)
      .firstChild as HTMLElement;
    tinderCardItem.classList.remove(classes.tinderAcceptCard);
    tinderCardItem.classList.remove(classes.tinderDeclineCard);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const offerJobs = allOffers?.apply ? allOffers.apply.map((job) => ({
      ...job,
      isDisplayed: false
  })) : [];
    console.log(offerJobs);
    const promiseArr = [];
    offerJobs?.forEach((item) => {
      if (item?.employerPhotoUrl) {
          promiseArr.push(
              ImageService.getImage(
                  item?.employerPhotoUrl,
                  item?.employerIdentityId
              )
          );
        }
        return null;
    });

    Promise.all(promiseArr)
        .then((res) => {
            const enrichedJobs = offerJobs.map((item, index) => ({
                ...item,
                photoURL: res[index],
            }));
            return enrichedJobs;
        })
        .then((enrichedJobs) => {
            setOfferList([...enrichedJobs]);
            setTinderList([...enrichedJobs].reverse());
            if (enrichedJobs.length !== 0) {
                setActiveItem(enrichedJobs[enrichedJobs.length - 1].id);
            }
        })
        .catch((err) => dispatch(setErrorMess(err)))
        .finally(() => dispatch(setLoading(false)));
}, [dispatch, user?.id, allOffers]);

const handleSwipe = (item, index, direction) => {
    // You need to decide what should happen when a user swipes right or left on a job.
    // E.g., maybe swiping right sends a job application?
    setCountItem(prev => prev + 1);
    const newList = [...offerList];
    newList[offerList.length - 1 - index].isDisplayed = true;
    setActiveItem(item?.id);
    setOfferList([...newList]);
    
    if (direction === "right") {
        // Example: Maybe send a job application?
    } else {
        // Example: Maybe just skip the job or store it as "not interested"?
    }
};
const goToIndex = (newIndex) => {
  if (animating) return;
  setCountItem(newIndex);
};

const onExiting = () => {
  setAnimating(true);
};

const onExited = () => {
  setAnimating(false);
};
const handleAddStamp = (index, direction) => {
  let tinderCardItem = document.getElementById(`tinder-card-${index}`)
    .firstChild as HTMLElement;
  tinderCardItem.classList.add(classes.currentTinderCard);
  if (direction === "right") {
    tinderCardItem.classList.remove(classes.tinderDeclineCard);
    tinderCardItem.classList.add(classes.tinderAcceptCard);
  } else {
    tinderCardItem.classList.remove(classes.tinderAcceptCard);
    tinderCardItem.classList.add(classes.tinderDeclineCard);
  }
};

  return (
    <div className={classes.containerRight}>
      <Container className={classes.containerCarousel}>
        {offerList.length > 0 && countItem < offerList.length ? (
          <Carousel
            activeIndex={countItem}
            className={classes.carousel}
            interval={false}
            slide={false}
            next={() =>
              handleDeclineOffer(
                offerList.findIndex((item) => item?.id === activeItem)
              )
            }
            previous={() =>
              handleProgressOffer(
                offerList.findIndex((item) => item?.id === activeItem)
              )
            }
          >
            <CarouselIndicators
              items={offerList}
              activeIndex={countItem}
              onClickHandler={goToIndex}
            />
            {offerList.map((item, index) => {
              if (!item?.isDisplayed) {
                return (
                  <CarouselItem
    className={classes.displayNone}
    key={item.id} // use unique id
    onExiting={onExiting}
    onExited={onExited}
>
    <div className={classes.carouselItemContainer}>
        {/* Left side: Image with buttons overlay */}
        <div className={classes.imageContainer}>
            <img
                src={item?.photoURL || require("assets/img/bg20.jpg")}
                alt="Avatar"
                className={classes.fullImage}
            />
            <div className={classes.buttonOverlay}>
                <Button
                    color="primary"
                    size="lg"
                    onClick={(e) => {
                        e.preventDefault();
                        handleProgressOffer(item);
                    }}
                    className="d-none d-sm-block"
                >
                    Submit
                </Button>
                <Button
                    color="grey"
                    size="lg"
                    onClick={() => handleDeclineOffer(item)}
                    className="d-none d-sm-block"
                >
                    Pass
                </Button>
            </div>
        </div>

        {/* Right side: Job details */}
        <div className={classes.jobDetailsContainer}>
            {/* <p className={classes.titleHeaderRight}>OFFER TO APPLY</p> */}
            <div className={classes.descriptionContainer}>
                <div className={classes.descriptionHeader}>
                    <p className={classes.descriptionTitle}>{`${item?.title}`}</p>
                    <p className={classes.descriptionSubtitle}>
                        {item?.companyName ? `${item?.companyName}` : ""}|
                        {(item?.isContract && " Contract") ||

                            (item?.isFixed && " Fixed Term") ||

                            (item?.isPermanent && " Permanent")}{" "} 
                    </p>
                </div>
                <div className={classes.descriptionContent}>

                <strong>Compensation:</strong> {" $"}{item?.isContract ? item?.maxRate : item?.maxSalary}
                      {(item?.isContract && " ph") || (item?.isFixed && " per annum") || (item?.isPermanent && " per annum")}
                

                        </div>
                        <div className={classes.descriptionContent}>
                          <strong>Location:</strong> {item?.city}{", "}{item?.country}
                        </div>
                        <div className={classes.descriptionContent}>
                        <strong>Work Policy: </strong>{
                                item?.workPolicy === "mixed" 
                                    ? "Hybrid" 
                                    : item?.workPolicy === "office" 
                                        ? "Office" 
                                        : item?.workPolicy === "workFromHome" 
                                            ? "Work From Home" 
                                            : "Not specified"
                            }
                        </div>
            </div>
            <p className={classes.title}>Role Summary:</p>
            <div className={classes.responsibilityContainer}>
                {item?.description}
            </div>
            <p className={classes.title}>Responsibilities:</p>
            <div className={classes.responsibilityContainer}>
                {item?.responsibilities}
            </div>
        </div>
    </div>
</CarouselItem>

                );
              } else {
                return (
                  <CarouselItem
                    className={classes.displayNone}
                    key={index}
                  ></CarouselItem>
                );
              }
            })}
          </Carousel>
        ) : (
          <p className={classes.titleHeaderRight}>
            There are no more opportunities that match your profile. 
          </p>
        )}
      </Container>

      <Container className={classes.containerTinderCard}>
        {offerList.length > 0 && countItem < offerList.length ? (
          tinderList.map((item, index) => {
            if (item?.isDisplayed === false) {
              return (
                <div
                  key={index}
                  id={`tinder-card-${index}`}
                  onTouchEndCapture={() => handleRemoveStamp(index)}
                  onMouseUpCapture = {()=>handleRemoveStamp(index)}
                >
                  <TinderCard
                    swipeThreshold={180}
                    className={classes.swipe}
                    preventSwipe={["up", "down"]}
                    onCardLeftScreen={(direction) =>
                      handleSwipe(item, index, direction)
                    }
                    onSwipeRequirementFulfilled={(direction) =>
                      handleAddStamp(index, direction)
                    }
                  >
                    <div className={classes.cardContainerWrapper}>
                      <p className={classes.progressWord}>Submit</p>
                      <p className={classes.declineWord}>Pass</p>
                      {/* <p className={classes.titleHeaderRight}>
                        OFFER TO CONNECT
                      </p> */}
                      <div className={classes.headerRight}>
                        <img
                          src={item?.photoURL || require("assets/img/ryan.jpg")}
                          alt="Avatar"
                          className={classes.avatar}
                        />
                        <div className={classes.buttonContainer}>
                          <Button
                            color="success"
                            size="lg"
                            onClick={() => handleProgressOffer(item)}
                            className="d-none d-sm-block"
                          >
                            Submit
                          </Button>
                          <Button
                            color="danger"
                            size="lg"
                            onClick={() => handleDeclineOffer(item)}
                            className="d-none d-sm-block"
                          >
                            Pass
                          </Button>
                        </div>
                      </div>
                      <div className={classes.descriptionContainer}>
                      <div className={classes.descriptionHeader}>

<p className={classes.descriptionTitle}>{`${item?.title}`}</p>

<p className={classes.descriptionSubtitle}>

    {item?.companyName ? `${item?.companyName}` : ""}|

    {(item?.isContract && " Contract") ||



        (item?.isFixed && " Fixed Term") ||



        (item?.isPermanent && " Permanent")}{" "}

</p>

</div>
<div className={classes.descriptionContent}>

 

<strong>Compensation:</strong> {" $"}{item?.isContract ? item?.maxRate : item?.maxSalary}

      {(item?.isContract && " ph") || (item?.isFixed && " per annum") || (item?.isPermanent && " per annum")}





        </div>

        <div className={classes.descriptionContent}>

          <strong>Location:</strong> {item?.city}{", "}{item?.country}

        </div>

        <div className={classes.descriptionContent}>

        <strong>Work Policy: </strong>{

                item?.workPolicy === "mixed"

                    ? "Hybrid"

                    : item?.workPolicy === "office"

                        ? "Office"

                        : item?.workPolicy === "workFromHome"

                            ? "Work From Home"

                            : "Not specified"

            }

        </div>
                      </div>
                      <p className={classes.title}>Role Summary:</p>

            <div className={classes.responsibilityContainer}>

                {item?.description}

            </div>

            <p className={classes.title}>Responsibilities:</p>

            <div className={classes.responsibilityContainer}>

                {item?.responsibilities}

            </div>
                    </div>
                  </TinderCard>
                </div>
              );
            } else {
              return (
                <TinderCard
                  className="d-none"
                  key={index}
                  preventSwipe={["up", "down"]}
                  onCardLeftScreen={(direction) =>
                    handleSwipe(item, index, direction)
                  }
                  swipeThreshold={180}
                />
              );
            }
          })
        ) : (
          <p
            className={clsx(classes.titleHeaderRight, classes.noOfferContainer)}
          >
             There are no more opportunities that match your profile.
          </p>
        )}
      </Container>
    </div>
  );
});

export default Connect;
