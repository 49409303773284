// styles
import "assets/css/bootstrap.min.css";
// import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import "react-datepicker/dist/react-datepicker.css";
import './index.scss';


import React from 'react';
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
//import { PersistGate } from 'redux-persist/integration/react';
import App from "App";
import { configure } from 'amplify/Config';
import { ModalProvider } from 'components/Modals';
import { history, createConfigureStore } from "redux/configureStore";
import reportWebVitals from "reportWebVitals";
import TagManager from 'react-gtm-module'

configure();
export {};
const tagManagerArgs = {
  gtmId: 'G-SW6MQ9P86V'
}
TagManager.initialize(tagManagerArgs)

// const { store, persistor } = createConfigureStore();
const { store } = createConfigureStore();
// const yourUserId ="637"

ReactDOM.render(
  <Provider store={store}>
    <ModalProvider>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        
          <App history={history} dispatch={store.dispatch} />

      {/* </PersistGate> */}
    </ModalProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

