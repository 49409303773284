import axios from "axios";

export class CVParseService {
  static async postCV(data) {
    const url = 'https://j9z0sb36kk.execute-api.ap-southeast-2.amazonaws.com/prod/cvparse';
    // console.log('DocumentName:', data);
    const payload = {
      fileContent: data.DocumentAsBase64String,
      
      fileExtension: data.DocumentName.split('.').pop().toLowerCase(), // Assuming DocumentName has the file name
    };
  
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.json())
    .then((data) => Promise.resolve(data))
    .catch((e) => Promise.reject(e));
  }

}
