import { memo, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Form, Modal } from "reactstrap";
import CustomInput from "components/Common/CustomInput";
import classes from "./styles.module.scss";
import * as yup from "yup";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormGroup from "reactstrap/lib/FormGroup";
import CustomDatePicker from "components/Common/CustomDatePicker";
import CustomTextarea from "components/CustomTextarea";
import CustomSelect from "components/Common/CustomSelect";
import CustomCheckbox from "components/Common/CustomCheckbox";
import clsx from "clsx";
import { ExperienceEmploymentTypes } from "models/Contractor/NewMember/Experience";
import { UtilContractor } from "utils/contractor";
import moment from "moment";
import SearchLocationInput from "components/Common/GoogleAddress";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  data: any;
  handleUpdateExperience: (data, removeList, callback?: () => void) => void;
}

interface ExperienceFormData {
  experienceList: {
    id?: number;
    title: string;
    company: string;
    city: string;
    country: string;
    address?: string;
    startDate: any;
    endDateIsPresent: boolean;
    endDate?: string;
    employmentType: {
      id: number;
      name: string;
      value: string;
    };
    summary: string;
    responsibilities: string;
  }[];
}

// eslint-disable-next-line no-empty-pattern
const EditExperience = memo(
  ({ onClose, isOpen, data, handleUpdateExperience }: Props) => {
    const [itemRemoveList, setItemRemoveList] = useState([]);
    const schema = useMemo(() => {
      return yup.object().shape({
        experienceList: yup.array().of(
          yup.object().shape({
            id: yup.number(),
            title: yup
              .string()
              .max(100, "Maximum 100 characters")
              .required("This field is required"),
            company: yup
              .string()
              .max(150, "Maximum 150 characters")
              .required("This field is required"),
            address: yup
              .string()
              .max(3000, "Maximum 3000 characters")
              .required("This field is required"),
            summary: yup
              .string()
              .max(3000, "Maximum 3000 characters")
              .required("This field is required"),
            startDate: yup
              .date()
              .typeError("Please enter a valid date")
              .required("This field is required"),
            endDateIsPresent: yup.boolean(),
            endDate: yup.date().when("endDateIsPresent", {
              is: true,
              then: yup
                .date()
                .typeError("Please enter a valid date")
                .nullable(),
              otherwise: yup.date().typeError("Please enter a valid date"),
            }),
            employmentType: yup.object().required("This field is required"),
          })
        ),
      });
    }, []);

    const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
      getValues,
      reset,
      control,
    } = useForm<ExperienceFormData>({
      resolver: yupResolver(schema),
      mode: "onChange",
    });
    const { fields, append, remove } = useFieldArray({
      control,
      name: "experienceList",
      keyName: "fieldID",
    });

    useEffect(() => {
      if (isOpen) {
        if (!!data?.length) {
          data.forEach((item) => {
            let newEndDate;
            if (item?.endDateIsPresent) {
              newEndDate = new Date();
            } else {
              newEndDate = new Date(item?.endDate);
            }
            append({
              id: item?.id,
              title: item?.title,
              company: item?.company,
              address:
                item.city && item.country ? `${item.city}, ${item.country}` : "",
              startDate: new Date(item?.startDate),
              endDateIsPresent: item?.endDateIsPresent,
              endDate: newEndDate,
              employmentType: UtilContractor.getCurrentEmploymentType(
                item?.employmentType
              ),
              summary: item?.summary,
              responsibilities: item?.responsibilities,
            });
          });
        } else {
          // If no data is passed, initialize with an empty template for adding a new experience
          append({
            title: '',
            company: '',
            city: '',
            country: '',
            startDate: null,
            endDateIsPresent: false,
            endDate: null,
            employmentType: { id: 0, name: '', value: '' },
            summary: '',
            responsibilities: '',
          });
        }
      }
    }, [append, data, reset, isOpen]);

    const handleChangeEndDateIsPresent = (index) => {
      const endDateIsPresent = getValues(
        `experienceList.${index}.endDateIsPresent`
      );
      setValue(`experienceList.${index}.endDateIsPresent`, !endDateIsPresent);
      setValue(`experienceList.${index}.endDate`, null);
    };

    const handleRemove = (id, index) => {
      remove(index);
      if (id) {
        setItemRemoveList((prev) => [...prev, id]);
      }
    };

    const onSubmit = (data: ExperienceFormData) => {
      const dataRequest = data.experienceList.map((item) => {
        return {
          id: item?.id,
          title: item?.title,
          company: item?.company,
          city: item?.city,
          country: item?.country,
          startDate: item?.startDate,
          endDate: item?.endDate,
          employmentType: item?.employmentType?.value,
          endDateIsPresent: item?.endDateIsPresent,
          summary: item?.summary,
          responsibilities: item?.responsibilities,
        };
      });
      handleUpdateExperience(dataRequest, itemRemoveList, _onClose);
    };

    const _onClose = () => {
      remove();
      setItemRemoveList([]);
      onClose();
    };

    return (
      <Card className={classes.card}>
        <Modal
          className={classes.modal}
          isOpen={isOpen}
          toggle={_onClose}
          centered
          scrollable
        >
          <CardBody className={classes.cardBody}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <p className={classes.title}>Edit Experience</p>
              <div className={classes.content}>
                {fields?.map((field: any, index) => {
                  return (
                    <div key={field?.fieldID}>
                      <div className={classes.btnDelete}>
                        <Button
                          onClick={() => handleRemove(field?.id, index)}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                      </div>
                      <FormGroup className={classes.section}>
                        <CustomInput
                          placeholder="Your Title"
                          type="text"
                          autoComplete="off"
                          inputRef={register(`experienceList.${index}.title`)}
                          errorMessage={
                            errors.experienceList &&
                            errors.experienceList[index]?.title?.message
                          }
                        />
                      </FormGroup>
                      <FormGroup className={classes.section}>
                        <CustomInput
                          placeholder="Organization Name"
                          type="text"
                          autoComplete="off"
                          inputRef={register(`experienceList.${index}.company`)}
                          errorMessage={
                            errors.experienceList &&
                            errors.experienceList[index]?.company?.message
                          }
                        />
                      </FormGroup>
                      <FormGroup className={classes.section}>
                        <Controller
                          name={`experienceList.${index}.address`}
                          control={control}
                          render={({ field }) => (
                            <SearchLocationInput
                              {...field}
                              ref={null}
                              placeholder="Location"
                              errorMessage={
                                errors.experienceList &&
                                errors.experienceList[index]?.address?.message
                              }
                              value={field.value}
                              onChange={(value: any) => {
                                setValue(
                                  `experienceList.${index}.city`,
                                  value?.address?.city
                                );
                                setValue(
                                  `experienceList.${index}.country`,
                                  value?.address?.country
                                );
                                return field.onChange(
                                  value?.address?.formattedAddress
                                );
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                      <div className={classes.dateContainer}>
                        <CustomDatePicker
                          name={`experienceList.${index}.startDate`}
                          control={control}
                          placeholder="From..."
                          dateFormat="dd/MM/yyyy"
                          maxDate={
                            watch(`experienceList.${index}.endDateIsPresent`)
                              ? moment().toDate()
                              : watch(`experienceList.${index}.endDate`)
                          }
                          errorMessage={
                            errors.experienceList &&
                            errors.experienceList[index]?.startDate?.message
                          }
                        />
                        <CustomCheckbox
                          content="I am currently in this engagement"
                          inputRef={register(
                            `experienceList.${index}.endDateIsPresent`
                          )}
                          errorMessage={
                            errors.experienceList &&
                            errors.experienceList[index]?.endDateIsPresent
                              ?.message
                          }
                          className={clsx("my-2", classes.customCheckbox)}
                          onChange={() => handleChangeEndDateIsPresent(index)}
                        />
                        <CustomDatePicker
                          className={
                            watch(`experienceList.${index}.endDateIsPresent`)
                              ? classes.disabled
                              : ""
                          }
                          readOnly={watch(
                            `experienceList.${index}.endDateIsPresent`
                          )}
                          name={`experienceList.${index}.endDate`}
                          control={control}
                          placeholder="To..."
                          dateFormat="dd/MM/yyyy"
                          minDate={watch(`experienceList.${index}.startDate`)}
                          errorMessage={
                            errors.experienceList &&
                            errors.experienceList[index]?.endDate?.message
                          }
                        />
                      </div>
                      <FormGroup className={classes.section}>
                        <CustomSelect
                          placeholder="Employment type.."
                          name={`experienceList.${index}.employmentType`}
                          control={control}
                          options={ExperienceEmploymentTypes}
                          errorMessage={
                            errors.experienceList &&
                            errors.experienceList[index]?.employmentType
                              ?.message
                          }
                        />
                      </FormGroup>
                      <FormGroup className={classes.section}>
                        <CustomTextarea
                          className={classes.textarea}
                          placeholder="Summary"
                          autoComplete="off"
                          inputRef={register(`experienceList.${index}.summary`)}
                          errorMessage={
                            errors.experienceList &&
                            errors.experienceList[index]?.summary?.message
                          }
                        />
                      </FormGroup>
                      <FormGroup className={classes.section}>
                        <CustomTextarea
                          className={classes.textarea}
                          placeholder="Responsibilities"
                          autoComplete="off"
                          inputRef={register(
                            `experienceList.${index}.responsibilities`
                          )}
                          errorMessage={
                            errors.experienceList &&
                            errors.experienceList[index]?.responsibilities
                              ?.message
                          }
                        />
                      </FormGroup>
                      {index !== fields.length - 1 && (
                        <hr className={classes.divider} />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={classes.buttonContainer}>
                <Button color="default" type="button" onClick={_onClose}>
                  Cancel
                </Button>
                {/* <Button
                  type="button"
                  className={classes.btnAddMore}
                  onClick={() => append({})}
                >
                  <span className="text-dark">
                    Add more
                    <i className="fa fa-plus ml-2" />
                  </span> 
                </Button> */}
                <Button color="primary" type="submit">
                  Ok
                </Button>
              </div>
            </Form>
          </CardBody>
        </Modal>
      </Card>
    );
  }
);

export default EditExperience;
