export const GET_USER_OF_CHAT_REQUEST = "GET_USER_OF_CHAT_REQUEST";

export const SET_USER_OF_CHAT_REDUCER = "SET_USER_OF_CHAT_REDUCER";

export const getUserOfChat = () => {
  return {
    type: GET_USER_OF_CHAT_REQUEST,
  };
};

export const setUserOfChatReducer = (data) => {
  return {
    type: SET_USER_OF_CHAT_REDUCER,
    data: data,
  };
};
