export const TypesOfTimesheetStatus = {
    paid: {
        id: 1,
        name: 'Paid',
        value: 'Paid'
    },
    approved: {
        id: 2,
        name: 'Approved',
        value: 'Approved'
    },
    declined: {
        id: 3,
        name: 'Declined',
        value: 'Declined'
    },
    pending: {
        id: 3,
        name: 'Pending',
        value: 'Pending'
    }
};

export const TypesOfTerminateDate = [{ id: 1, name: "Today" }, { id: 2, name: "With Notice" }];