import produce from "immer";
import * as types from "./actionTypes";

export interface StatusState {
  isLoading: boolean;
  isLoadingAuth: boolean;
  error?: string;
  success?: string;
  isWelcome?: boolean;
  isOffersStillExist?: boolean;
  isOffersToConnectStillExist?: boolean;
  isOffersToInterviewStillExist?: boolean;
  isContractualOffersStillExist?: boolean;
  isHaveNewMessage?: boolean;
}

const initial: StatusState = {
  isLoading: false,
  isLoadingAuth: true,
  isWelcome: false,
  isOffersStillExist: false,
  isOffersToConnectStillExist: false,
  isOffersToInterviewStillExist: false,
  isContractualOffersStillExist: false,
  isHaveNewMessage: false,
};

export const statusReducer = (state = initial, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_LOADING_AUTH_REDUCER:
        draft.isLoadingAuth = action.isLoadingAuth;
        break;
      case types.SET_LOADING_REDUCER:
        draft.isLoading = action.isLoading;
        break;
      case types.SET_ERROR_REDUCER:
        draft.error = action.error;
        break;
      case types.SET_SUCCESS_REDUCER:
        draft.success = action.success;
        break;
      case types.SET_WELCOME_REDUCER:
        draft.isWelcome = action.isWelcome;
        break;
      case types.SET_OFFERS_STILL_EXIST_REDUCER:
        draft.isOffersStillExist = action.isOffersStillExist;
        break;
      case types.SET_OFFERS_TO_CONNECT_STILL_EXIST_REDUCER:
        draft.isOffersToConnectStillExist = action.isOffersToConnectStillExist;
        break;
      case types.SET_OFFERS_TO_INTERVIEW_STILL_EXIST_REDUCER:
        draft.isOffersToInterviewStillExist = action.isOffersToInterviewStillExist;
        break;
      case types.SET_CONTRACTUAL_OFFERS_STILL_EXIST_REDUCER:
        draft.isContractualOffersStillExist = action.isContractualOffersStillExist;
        break;
      case types.SET_IS_HAVE_NEW_MESSAGE_REDUCER:
        draft.isHaveNewMessage = action.isHaveNewMessage;
        break;
      default:
        return state;
    }
  });
