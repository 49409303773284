import React, { useState, useEffect } from "react";
import { Link, useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classes from './styles.module.scss';
import clsx from 'clsx';
import avatar from "assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { push } from "connected-react-router";
import routes, { routesOutside } from "routers/routes";
import authFactory from "services/authService/AuthFactory";
import { setUserLogoutReducer } from "redux/reducers/User/actionTypes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import { faBell } from '@fortawesome/free-solid-svg-icons';
import {
  setAllChatsReducer,
  setAllTimesheetsReducer,
  setJobListReducer,
  setJobOffersReducer,
  setSettingReducer,
  setTalentReducer,
} from "redux/reducers/Employer/actionTypes";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { ImageService } from "services/Image";

function AdminNavbar(props) {
  const { jobList, allCandidatesList } = useSelector((state) => state.employer);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [color, setColor] = useState("transparent");
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const sidebarToggle = React.useRef();
  const dispatch = useDispatch();

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("white");
    }
    setIsOpen(!isOpen);
  };

  const { setting } = useSelector((state) => state.employer);
  const user = useSelector((state) => state.user.user);
  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (user && setting) {
          if (setting.photoUrl) {
            const imageBlob = await ImageService.getImageBlob(setting.photoUrl);
            const imageFile = blobToFile(imageBlob, "avatar.png");
            setImagePreviewUrl(URL.createObjectURL(imageFile));
          } else {
            const imageKey = `${setting.idOrganisation}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`;
            // console.log("imageKey", imageKey)
            try {
              const imageUrl = await ImageService.getCompanyAvatar(imageKey);
              const imageFile = blobToFile(imageUrl, "avatar.png");
              setImagePreviewUrl(URL.createObjectURL(imageFile));
            } catch (error) {
              console.error("Failed to load company avatar, falling back to default avatar");
              setImagePreviewUrl(avatar);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
        setImagePreviewUrl(avatar);
      }
    };
  
    fetchImage();
  }, [user, setting]);
  
  
  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const dropdownToggle = () => {
    setDropdownOpen(prevState => !prevState);
  };
  
  const handleDropdownClose = () => {
    if (dropdownOpen) {
      setNotifications(notifications.map(notification => ({ ...notification, read: true })));
    }
  };

  const userDropdownToggle = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    if (sidebarToggle.current) {
      sidebarToggle.current.classList.toggle("toggled");
    } else {
      console.log("sidebarToggle reference is null");
    }
  };
  const handleSignOut = async () => {
    dispatch(setLoading(true));
    await authFactory
      .generate()
      .logout()
      .then(() => {
        dispatch(setUserLogoutReducer());
        dispatch(setSettingReducer(null));
        dispatch(setJobListReducer([]));
        dispatch(setTalentReducer([]));
        dispatch(setJobOffersReducer([]));
        dispatch(setAllTimesheetsReducer([]));
        dispatch(setAllChatsReducer(null));
  
        const redirectURL = setting?.idOrganisation
          ? `${routes.employer.login}/${setting.idOrganisation}`
          : routes.employer.login;
  
        dispatch(push(redirectURL));
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  const [notifications, setNotifications] = useState([
    { text: 'Notification 1', read: false },
    { text: 'Notification 2', read: false },
    { text: 'Notification 3', read: true }
  ]);

  const markAsRead = (index) => {
    setNotifications(notifications.map((notification, i) => (
      i === index ? { ...notification, read: true } : notification
    )));
  };

  const unreadNotifications = notifications.filter(notification => !notification.read).length;
  const hasNotifications = unreadNotifications > 0;
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("white");
    } else {
      setColor("transparent");
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return () => window.removeEventListener("resize", updateColor);
  }, [isOpen]);
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf(classes["nav-open"]) !== -1
    ) {
      document.documentElement.classList.toggle(classes["nav-open"]);
      sidebarToggle.current.classList.toggle(classes["toggled"]);
    }
  }, [location]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filteredJobs = jobList.filter(job =>
      job.title.toLowerCase().includes(value) || job.id.toString().includes(value)
    ).slice(0, 3);

    const filteredCandidates = allCandidatesList.filter(candidate =>
      candidate.accepted && candidate.jobId &&
      jobList.some(job => job.id === candidate.jobId) &&
      (candidate.firstName.toLowerCase().includes(value) ||
      candidate.lastName.toLowerCase().includes(value) ||
      candidate.title.toLowerCase().includes(value))
    ).slice(0, 3);

    setFilteredResults({ jobs: filteredJobs, candidates: filteredCandidates });
  };

  return (
    <Navbar
      color={
        window.location.href.indexOf("full-screen-maps") !== -1
          ? "white"
          : color
      }
      expand="lg"
      className={clsx(
        classes.navbar,
        window.location.href.indexOf("full-screen-maps") !== -1
          ? classes["navbar-absolute"]
          : classes["navbar-absolute"],
        { [classes["navbar-transparent"]]: color === "transparent" }
      )}
    >
      <Container fluid>
        <div style={{ display: 'flex', alignItems: 'center' }}>  
          <div>
            <button
              type="button"
              ref={sidebarToggle}
              className={classes["navbar-toggler"]}
              onClick={openSidebar}
            >
              <span className={classes["navbar-toggler-bar"] + " " + classes["bar1"]} />
              <span className={classes["navbar-toggler-bar"] + " " + classes["bar2"]} />
              <span className={classes["navbar-toggler-bar"] + " " + classes["bar3"]} />
            </button>
          </div>
          {setting && (
             
                
          <NavbarBrand className={classes.brand} href="/">{setting?.name || "NECTA" }</NavbarBrand>
        )}
        </div>
  
        <NavbarToggler onClick={toggle}>
          <img 
            src={imagePreviewUrl || avatar} 
            alt="Avatar" 
            style={{ width: '30px', height: '30px', borderRadius: '50%' }} 
          />
        </NavbarToggler>
  
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <form style={{ position: 'relative' }}>
            <InputGroup className="no-border">
              <Input 
                placeholder="Search..." 
                value={searchTerm} 
                onChange={handleSearch} 
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="now-ui-icons ui-1_zoom-bold" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            {searchTerm && (
              <div className={classes.searchResults}>
                {filteredResults.jobs && filteredResults.jobs.map((job) => (
                  <NavLink 
                    key={job.id} 
                    to={`/employer/admin/myjobs/${job.id}`} 
                    className={classes.searchResultItem}
                  >
                    <span>{job.id} | {job.title}</span>
                  </NavLink>
                ))}
                {filteredResults.candidates && filteredResults.candidates.map((candidate) => (
                  <NavLink 
                    key={candidate.id} 
                    to={`/jobs/${candidate.jobId}/candidates/${candidate.id}`} 
                    className={classes.searchResultItem}
                  >
                    <span>{candidate.firstName} {candidate.lastName} | {candidate.title}</span>
                  </NavLink>
                ))}
              </div>
            )}
          </form>
          <Nav style={{ alignItems: 'center', paddingLeft: '10px' }} navbar>
            {!isOpen && (
              <Dropdown nav className={classes.user} isOpen={dropdownOpen} toggle={dropdownToggle} onClosed={handleDropdownClose}>
                <DropdownMenu className={classes.dropdownmenu} right>
                  {notifications.map((notification, index) => (
                    <DropdownItem 
                      key={index} 
                      className={classes.dropdownitem} 
                      style={{ backgroundColor: !notification.read ? '#f8d7da' : 'transparent' }}
                      onClick={() => markAsRead(index)}
                    >
                      {notification.text}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
            {!isOpen && (
              <Dropdown nav className={classes.user} isOpen={userDropdownOpen} toggle={userDropdownToggle}>
                <DropdownToggle nav caret className={classes.dropdowntoggle}>
                  <div className={classes.photo}>
                    {imagePreviewUrl && (
                      <img src={imagePreviewUrl} alt="Avatar" />
                    )}
                   
                  </div>
                  <span>
                    <b className={classes.caret} /> 
                  </span>
                </DropdownToggle>
                <DropdownMenu className={classes.dropdownmenu} right>
                  <DropdownItem tag="div">
                    <NavLink
                      to="/employer/admin/profile"
                      className={classes.navlink}
                    >
                      <span className={classes['sidebar-normal']}>Profile</span>
                    </NavLink>
                  </DropdownItem> 
                  <DropdownItem divider />
                  <DropdownItem tag="div">
                    <NavLink
                      to="#"
                      className={classes.navlink}
                      onClick={handleSignOut}
                    >
                      <span className={classes['sidebar-normal']}>Log Out</span>
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
            {isOpen && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <NavLink
                  to="/employer/admin/profile"
                  className={classes.navlink}
                  style={{ padding: '10px' }}
                >
                  Profile
                </NavLink>
                {/* <NavLink
                  to="/employer/admin/mysetting"
                  className={classes.navlink}
                  style={{ padding: '10px' }}
                >
                  Settings
                </NavLink> */}
                <NavLink
                  to="#"
                  className={classes.navlink}
                  onClick={handleSignOut}
                  style={{ padding: '10px' }}
                >
                  Log Out
                </NavLink>
              </div>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

AdminNavbar.defaultProps = {
  brandText: "",
};

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
};

export default AdminNavbar;
