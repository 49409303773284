import produce from "immer";
import * as types from "./actionTypes";
import { IVisaTypes } from "models/Contractor/NewMember/MojCheck";

interface BasicProfile {
  id: number;
  firstName: string;
  lastName: string;
  title: string;
  about: string;
  photoUrl: string;
  linkedinUrl: string;
  currentCompany: string;
  region?: string;
}

interface Contact {
  id: number;
  countryCode: string;
  mobile: string;
  city: string;
  country: string;
  region: string;
}

interface Preference {
  id?: number;
  availableDate?: string;
  noticePeriod?: number;
  minRate?: number;
  maxRate?: number;
  radius?: number;
  workPolicy?: string;
  preferFixedTerm?: boolean;
  preferPermanent?: boolean;
  minSalary?: number;
  maxSalary?: number;
}

interface Testimonial {
  id: string;
  title: string;
  email: string;
  name: string;
  response: string;
}

interface Experience {
  id?: number;
  title: string;
  company: string;
  city?: string;
  country?: string;
  startDate: string | Date;
  endDate?: string | Date;
  employmentType: string;
  endDateIsPresent: boolean;
  summary: string;
  responsibilities: string;
}

interface Education {
  id?: number;
  schoolName: string;
  degreeName: string;
  startDate?: string;
  endDate: string;
  endDateIsPresent?: boolean;
  city?: string;
  country?: string;
}
interface Skill {
  id: number;
  skillList: string[];
}

interface MojCheck {
  id?: number;
  mojUrl?: string;
  visaType?: IVisaTypes;
}

export interface ITaxDetails {
  id?: number;
  irdNumber?: string;
  jobOffer?: number;
  nameOnAccount?: string;
  bank?: string;
  branch?: string;
  account?: string;
  suffix?: string;
  gstNumber?: string;
  gstType?: string;
  taxCode?: string;
  userId?: number;
  isLimitedCompany?: boolean;
  companyName?: string;
  businessNumber?: string;
  withholdingTax?: number;
  isPaye?: number;
  isGstRegistered?: number;
  agreedToTerms?: number;
  gstFilingFrequency?: string;
  gstStartingMonth?: number;
  workType?: string;
  usingHomeOffice?: boolean;
  homeOfficeRate?: number;
  bicCode?: string;
}

export interface IProfile {
  basicProfile: BasicProfile;
  contact: Contact;
  preference: Preference;
  testimonial: Testimonial[];
  experience: Experience[];
  education: Education[];
  skill: Skill;
  mojCheck: MojCheck;
  taxDetails: ITaxDetails;
}

interface ConnectOffers {
  id?: number;
  job?: number;
  contractor?: number;
  employer?: number;
  offered?: boolean;
  revoked?: boolean;
  accepted?: boolean;
  rejected?: boolean;
}

interface InterviewOffers {
  id?: number;
  job?: number;
  contractor?: number;
  employer?: number;
  offered?: boolean;
  revoked?: boolean;
  accepted?: boolean;
  rejected?: boolean;
  interviewTime?: string | Date;
  type?: string;
  interviewAddress?: string;
  interviewLink?: string;
  title?: string;
}

export interface JobOffers {
  id?: number;
  job?: number;
  contractor?: number;
  employer?: number;
  offered?: boolean;
  revoked?: boolean;
  accepted?: boolean;
  rejected?: boolean;
  startDate?: string | Date;
  endDate?: string | Date;
  rateOffered?: string;
  salary?: number;
  managerName?: string;
  approvalName?: string;
  approvalEmail?: string;
  contractUrl?: string;
  isContraContractUsed?: boolean;
  isExtendContract?: boolean;
}

export interface Jobs {
  id?: number;
  title?: string;
  private?: boolean;
  description?: string;
  experinceName?: string;
  experienceYears?: number;
  educationField?: string;
  educationLevel?: string;
  experienceFeild?: string;
  technicalSkills?: string[];
  educationName?: string;
  organization?: number;
  referenceCode?: string;
  department?: number;
  companyName?: string;
  city?: string;
  region?: string;
  country?: string;
  responsibilities?: string;
  isPermanent?: boolean;
  isFixed?: boolean;
  isContract?: boolean;
  minRate?: number;
  maxRate?: number;
  maxSalary?: number;
  minSalary?: number;
  workPolicy?: string;
  connectOfferId?: number;
  headhunterAmount?: number;
  employerPhotoUrl?: string;
  employerIdentityId?: string;
  contacted?: number;
  applied?: number;
  declined?: number;
  isArchived?: boolean;
  client?: string;
  clientid?: number;
  orgadmin?: number;
}

export interface Apply {
  id?: number;
  title?: string;
  description?: string;
  experinceName?: string;
  experienceYears?: number;
  educationField?: string;
  educationLevel?: string;
  experienceFeild?: string;
  technicalSkills?: string[];
  educationName?: string;
  organization?: number;
  referenceCode?: string;
  department?: number;
  companyName?: string;
  city?: string;
  region?: string;
  country?: string;
  responsibilities?: string;
  isPermanent?: boolean;
  isFixed?: boolean;
  isContract?: boolean;
  minRate?: number;
  maxRate?: number;
  maxSalary?: number;
  minSalary?: number;
  workPolicy?: string;
  connectOfferId?: number;
  employerPhotoUrl?: string;
  employerIdentityId?: string;
  isArchived?: boolean;
}

export interface IAllOffers {
  connectOffers: ConnectOffers[];
  interviewOffers: InterviewOffers[];
  jobOffers: JobOffers[];
  jobs: Jobs[]; 
  apply: Apply[]; 
}

export interface ITimesheet {
  approved: boolean;
  approver: string;
  contractor: number;
  declined: boolean;
  employer: number;
  hours: number;
  id: number;
  job: number;
  jobOffer: number;
  paid: boolean;
  pending: boolean;
  rateOffered: number;
  weekEnding: string | Date;
  datePaid: string | Date;
}

export interface IExpense {
  id: number;
  jobOffer: number;
  vendor: string;
  dateOfExpense: string;
  net: number;
  gst: number;
  total: number;
  isPaid: boolean;
  name: string;
}

export interface ContractorState {
  profile: IProfile;
  allOffers: IAllOffers;
  allTimesheets: ITimesheet[];
  allExpenses: IExpense[];
}

const initial: ContractorState = {
  profile: null,
  allOffers: null,
  allTimesheets: [],
  allExpenses: [],
};

export const contractorReducer = (state = initial, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_PROFILE_REDUCER:
        draft.profile = action?.data;
        break;
      case types.SET_ALL_OFFERS_REDUCER:
        draft.allOffers = action?.data;
        break;
      case types.SET_ALL_TIMESHEETS_CONTRACTOR_REDUCER:
        draft.allTimesheets = action?.data;
        break;
      case types.SET_ALL_EXPENSES_REDUCER:
        draft.allExpenses = action?.data;
        break;
      default:
        return state;
    }
  });
