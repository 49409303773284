import { memo } from "react";
import { Modal, Table, Button } from "reactstrap";
import classes from "./styles.module.scss";
import clsx from "clsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface DownloadGstReportProps {
  onClose: () => void;
  isOpen: boolean;
  data?: any;
}

const DownloadGstReport = memo(
  ({ onClose, isOpen, data }: DownloadGstReportProps) => {
    const handleDownload = () => {
      const pdfElement = document.getElementById("pdf-component");
      if (pdfElement) {
        const w = pdfElement?.offsetWidth;
        const h = pdfElement?.offsetHeight;
        html2canvas(pdfElement, {
          scale: 4,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const doc = new jsPDF("p", "pt", [w, h]);

          doc.addImage(imgData, "PNG", 0, 30, w, h);
          doc.save(`GST_Report.pdf`);
          onClose();
        });
      }
    };

    return (
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        centered
        scrollable
        className={classes.modal}
      >
        <div id="pdf-component" className={clsx(classes.pdfWrapper)}>
          <div className={classes.tableContainer}>
            <h3 className={clsx("mb-1", classes.title)}>GST Report</h3>
            <h5 className={classes.subTitle}>JAN '22 - JAN'23</h5>
            <Table className={classes.table} responsive>
              <tbody>
                <tr>
                  <td className={classes.contentTitle}>Sales and Income</td>
                  <td className={clsx("text-right", classes.contentTitle)}>
                    $
                  </td>
                </tr>
                <tr>
                  <td>Total Sales and Income</td>
                  <td className="text-right">
                    {parseFloat(data?.totalOfInvoices).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>Zero-rated (GST Exclusive Income)</td>
                  <td className="text-right">
                    {parseFloat(data?.totalInvoiceWithoutGst).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>NET GST Sales and Income</td>
                  <td className="text-right">
                    {parseFloat(data?.netOfIncome).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>Total GST collected (Sales and Income)</td>
                  <td className="text-right">
                    {parseFloat(data?.totalGstCollected).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>Total GST on Sales and Income</td>
                  <td className="text-right">
                    {parseFloat(data?.totalGstOnSalesAndIncome).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={classes.contentTitle}>
                    Purchases and Expenses
                  </td>
                  <td className={clsx("text-right", classes.contentTitle)}>
                    $
                  </td>
                </tr>
                <tr>
                  <td>Total Purchases and Expenses</td>
                  <td className="text-right">
                    {parseFloat(data?.totalExpenses).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>GST Paid</td>
                  <td className="text-right">
                    {parseFloat(data?.GstPaid).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={classes.contentTitle}>
                    Total GST Paid for Purchases and Expenses
                  </td>
                  <td className={clsx("text-right", classes.contentTitle)}>
                    {parseFloat(data?.totalGetPaid).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td className={classes.contentTitle}>Total GST to Pay</td>
                  <td className={clsx("text-right", classes.contentTitle)}>
                    {parseFloat(data?.totalGstToPaid).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className={classes.btnWrapper}>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button color="primary" onClick={() => handleDownload()}>
            Download
          </Button>
        </div>
      </Modal>
    );
  }
);

export default DownloadGstReport;
