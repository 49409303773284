import { API, Storage } from "aws-amplify";

export class BillService {
  static async getAllContractorBill(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/bills`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllEmployerBill(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/bills`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async createOneBill(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/bills`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async updateOneBill(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/bills`;
    const myInit = {
      response: true,
      body: data
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
