import { memo } from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import classes from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => void;
}

const DeleteWarningModal = memo(({ isOpen, onClose, handleDelete }: Props) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} className={classes.termModal}>
        <ModalHeader className={classes.modalHeader}>
          <strong>Warning:</strong> De-activating the user will mean they will not be found on Necta, you can not undo this action.... make sure you have written consent!
        </ModalHeader>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleDelete}>
            Deactivate
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

export default DeleteWarningModal;
