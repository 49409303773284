import { API } from "aws-amplify";

export class ListingEmailService {
  static async newMessageEmail(userId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${userId}/newmessageemail`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async offerCreateEmail(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${offerId}/offercreateemail`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async acceptOfferEmail(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${offerId}/offeracceptemail`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async contractorInterviewDetailsEmail(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${offerId}/contractorinterviewdetails`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async employerInterviewDetailsEmail(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${offerId}/employerinterviewdetails`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async declinedContractEmail(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${offerId}/offerdeclinedemail`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getEmployerSetting(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${id}/settings`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postEmployerSetting(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${id}/settings`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putEmployerSetting(id, settingsId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${id}/settings/${settingsId}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async terminateContractEmail(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${id}/terminateemail`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
