import { memo, useEffect, useMemo, useState } from "react";
import { Form, Button, Card, CardBody, Modal, FormGroup } from "reactstrap";
import classes from "./styles.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/Common/CustomInput";
import CustomSelect from "components/Common/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "components/Common/CustomDatePicker";
import clsx from "clsx";
import CustomSwitch from "components/Common/CustomSwitch";
import { ReducerType } from "redux/reducers";
import { JobOfferService } from "services/Contractor/Offer/Offer";
import { OrganizationService } from "services/Employer/NewMember/Organization";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { JobOffers, Jobs } from "redux/reducers/Contractor";
import CustomSlider from "components/Common/CustomSlider";
import { InvoiceService } from "services/Contractor/Books/Invoice";
import { ClientInfoService } from "services/Contractor/Books/ClientInfo";
import CustomRadioButtons from "components/Common/CustomRadioButtons";
import { LineItemService } from "services/Contractor/Books/lineItem";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const ManualInvoice = memo(({ onClose, isOpen }: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const [clientList, setClientList] = useState([
    {
      id: 0,
      name: "+ Add new",
    },
  ]);
  const [editClient, setEditClient] = useState(false);
  const [isInclusiveGST, setIsInclusiveGST] = useState(false);

  const schema = useMemo(() => {
    return yup.object().shape({
      client: yup.object().nullable().required("This field is required"),
      clientFirstName: yup.string().required("This field is required"),
      clientLastName: yup.string().required("This field is required"),
      clientEmail: yup
        .string()
        .email("Please enter a valid email")
        .required("This field is required"),
      clientAddress: yup.string().required("This field is required"),
      clientPhoneNumber: yup.string().required("This field is required"),
      clientContactName: yup.string().required("This field is required"),
      date: yup
        .date()
        .typeError("Please enter a valid date")
        .required("This field is required"),
      dueDate: yup
        .date()
        .typeError("Please enter a valid date")
        .required("This field is required"),
      description: yup.string().required("This field is required"),
      isLabour: yup.boolean(),
      hours: yup
        .number()
        .nullable()
        .typeError("Please enter a valid number")
        .when("isLabour", {
          is: true,
          then: yup
            .number()
            .typeError("Please enter a Valid Number")
            .required("This field is required"),
        }),
      hourlyRate: yup
        .number()
        .nullable()
        .when("isLabour", {
          is: true,
          then: yup
            .number()
            .nullable()
            .typeError("Please enter a Valid Number")
            .required("This field is required"),
        }),
      net: yup
        .number()
        .nullable()
        .typeError("Please enter a valid number")
        .when("isLabour", {
          is: false,
          then: yup
            .number()
            .typeError("Please enter a Valid Number")
            .required("This field is required"),
        }),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (isOpen) {
      handlePrepareData();
    }
  }, [dispatch, isOpen]);

  const handlePrepareData = async () => {
    ClientInfoService.getAllClient(user?.id).then((res) => {
      const clientListArr = [];
      res.map((item) => {
        clientListArr.push({
          ...item,
          name: `${item?.firstName} ${item?.lastName}`,
        });
      });
      setClientList([
        ...clientListArr,
        {
          id: 0,
          name: "+ Add new",
        },
      ]);
    });
  };

  const handleChangeClient = (client) => {
    setValue("client", client);
    if (client?.id === 1) {
      setValue("clientFirstName", "");
      setValue("clientLastName", "");
      setValue("clientEmail", "");
      setValue("clientAddress", "");
      setValue("clientPhoneNumber", "");
      setValue("clientContactName", "");
    } else {
      setValue("clientFirstName", client?.firstName);
      setValue("clientLastName", client?.lastName);
      setValue("clientEmail", client?.email);
      setValue("clientAddress", client?.address);
      setValue("clientPhoneNumber", client?.phone);
      setValue("clientContactName", client?.contactName);
    }
  };

  const onSubmit = (data) => {
    if (data?.client?.id === 0) {
      ClientInfoService.createClient(user?.id, {
        firstName: data?.clientFirstName,
        email: data?.clientEmail,
        address: data?.clientAddress,
        phone: data?.clientPhoneNumber,
        contactName: data?.clientContactName,
        contractor: user?.id,
        lastName: data?.clientLastName,
      }).then((res) => {
        InvoiceService.createManualInvoice(user?.id, {
          contractor: user?.id,
          client: res?.id,
          status: "AUTHORISED",
        }).then((invoice) => {
          let net = 0;
          let total = 0;
          if (data?.isLabour) {
            net = data?.hours * data?.rate;
          } else {
            net = data?.net;
          }
          if (isInclusiveGST) {
            total = net;
          } else {
            total = net * 1.15;
          }
          LineItemService.createLineItem(user?.id, invoice?.id, {
            date: data?.date,
            dueDate: data?.dueDate,
            description: data?.description,
            hours: data?.hours,
            rate: data?.rate,
            quantity: 1,
            isLabour: data?.isLabour,
            net: net,
            isIncludeGst: isInclusiveGST,
            total: total,
            invoice: invoice?.id,
            userId: res?.id,
          }).then(()=>{
            onClose();
          })
        });
      });
    } else {
      ClientInfoService.updateClient(user?.id, data?.client?.id, {
        firstName: data?.clientFirstName,
        email: data?.clientEmail,
        address: data?.clientAddress,
        phone: data?.clientPhoneNumber,
        contactName: data?.clientContactName,
        contractor: user?.id,
        lastName: data?.clientLastName,
      }).then((res) => {
        InvoiceService.createManualInvoice(user?.id, {
          contractor: user?.id,
          client: res?.id,
          status: "AUTHORISED",
        }).then((invoice) => {
          let net = 0;
          let total = 0;
          if (data?.isLabour) {
            net = data?.hours * data?.rate;
          } else {
            net = data?.net;
          }
          if (isInclusiveGST) {
            total = net;
            net = net * 0.85;
          } else {
            total = net * 1.15;
          }
          LineItemService.createLineItem(user?.id, invoice?.id, {
            date: data?.date,
            dueDate: data?.dueDate,
            description: data?.description,
            hours: data?.hours,
            rate: data?.rate,
            quantity: 1,
            isLabour: data?.isLabour,
            net: net,
            isIncludeGst: isInclusiveGST,
            total: total,
            invoice: invoice?.id,
            userId: res?.id,
          }).then(()=>{
            onClose();
          })
        });
      });
    }
  };

  return (
    <Card className={classes.card}>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        centered
        scrollable
        className={classes.modal}
      >
        <CardBody className={classes.cardBody}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p className={classes.title}>Manual Invoice</p>
            <div className={classes.content}>
              <h4 className={classes.sectionTitle}>Client</h4>
              <CustomSelect
                placeholder="Client"
                name="client"
                onChange={(clientt) => handleChangeClient(clientt)}
                control={control}
                options={clientList}
                errorMessage={errors.client?.message}
              />
              <CustomInput
                placeholder="Client first name"
                type="text"
                autoComplete="off"
                inputRef={register("clientFirstName")}
                errorMessage={errors.clientFirstName?.message}
              />
              <CustomInput
                placeholder="Client last name"
                type="text"
                autoComplete="off"
                inputRef={register("clientLastName")}
                errorMessage={errors.clientLastName?.message}
              />
              <CustomInput
                placeholder="Client email"
                type="text"
                autoComplete="off"
                inputRef={register("clientEmail")}
                errorMessage={errors.clientEmail?.message}
              />
              <CustomInput
                placeholder="Client address"
                type="text"
                autoComplete="off"
                inputRef={register("clientAddress")}
                errorMessage={errors.clientAddress?.message}
              />
              <CustomInput
                placeholder="Client phone number"
                type="text"
                autoComplete="off"
                inputRef={register("clientPhoneNumber")}
                errorMessage={errors.clientPhoneNumber?.message}
              />
              <CustomInput
                placeholder="Client contact name"
                type="text"
                autoComplete="off"
                inputRef={register("clientContactName")}
                errorMessage={errors.clientContactName?.message}
              />
              {editClient && (
                <Button
                  size="md"
                  color="default"
                  type="submit"
                  className={classes.btnEditClient}
                >
                  Edit client
                </Button>
              )}
              <h4 className={classes.sectionTitle}>Invoice</h4>
              <CustomDatePicker
                name="date"
                control={control}
                placeholder="Date"
                dateFormat="dd/MM/yyyy"
                errorMessage={errors.date?.message}
              />
              <CustomDatePicker
                name="dueDate"
                control={control}
                placeholder="Due Date"
                dateFormat="dd/MM/yyyy"
                errorMessage={errors.dueDate?.message}
              />
              <div className="mb-1">
                <p
                  className={clsx(
                    classes.commonSubtitle,
                    classes.commonSubtitleFutureOpportunities
                  )}
                >
                  Labour or Outcome?
                </p>
                <CustomRadioButtons
                  content={[
                    { title: "Yes", value: true, default: false },
                    { title: "No", value: false, default: true },
                  ]}
                  name="isLabour"
                  inputName="isLabour"
                  control={control}
                  className={classes.customRadio}
                  errorMessage={errors.isLabour?.message}
                />
              </div>
              {watch("isLabour") ? (
                <>
                  <CustomInput
                    placeholder="Hours"
                    type="text"
                    autoComplete="off"
                    inputRef={register("hours")}
                    errorMessage={errors.hours?.message}
                  />
                  <FormGroup className={classes.section}>
                    <span className={clsx("ml-1 mb-3", classes.rateTitle)}>
                      Hourly rate:
                    </span>
                    <CustomSlider
                      name="hourlyRate"
                      min={30}
                      max={300}
                      control={control}
                      className={classes.sliderRate}
                      errorMessage={errors.hourlyRate?.message}
                    />
                  </FormGroup>
                </>
              ) : (
                <>
                  <CustomInput
                    placeholder="Net"
                    type="text"
                    autoComplete="off"
                    inputRef={register("net")}
                    errorMessage={errors.net?.message}
                  />
                </>
              )}
              <CustomInput
                placeholder="Description"
                type="text"
                autoComplete="off"
                inputRef={register("description")}
                errorMessage={errors.description?.message}
              />
              <div className={classes.customSwitchWrapper}>
                <p>Is the total amount inclusive of GST?</p>
                <CustomSwitch
                  value={isInclusiveGST}
                  onChange={() => {
                    setIsInclusiveGST(!isInclusiveGST);
                  }}
                  onText="YES"
                  offText="NO"
                  className={clsx(classes.customCheckbox, "my-2")}
                />
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                size="md"
                color="default"
                type="button"
                className={classes.btn}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                size="md"
                color="info"
                type="submit"
                className={classes.btn}
              >
                Save
              </Button>
              <Button
                size="md"
                color="primary"
                type="submit"
                className={classes.btn}
              >
                Send
              </Button>
            </div>
          </Form>
        </CardBody>
      </Modal>
    </Card>
  );
});

export default ManualInvoice;
