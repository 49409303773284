import { API } from "aws-amplify";

export class OrganizationService {
  static async postOrganization(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/organization";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getIntegration(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/organization/integration`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putIntegration(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/organization/integration`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getOrganization() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/organization";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getOrganizationChart() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/organization/orgchart";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getEmployerOrganization(employerId: number) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${employerId}/organization`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putOrganization(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/organization/${id}`;
    const myInit = {
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
