/* eslint-disable no-useless-escape */
export enum SIGN_UP_MODE {
  CREATE,
  CONFIRM,
}

export enum FORGOT_PASSWORD_MODE {
  EMAIL,
  CONFIRM_NEW_PASSWORD,
}

export enum OFFER_TYPE {
  CONNECT,
  INTERVIEW,
  CONTRACT,
  APPLY,
}

export enum CONTRACTOR_TIMESHEET_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  PAID = "paid",
}

export enum TIMESHEET_STATUS {
  PENDING = "Pending",
  APPROVED = "Approved",
  PAID = "Paid",
  DECLINED = "Declined",
}

export const S3_UPLOAD_TYPE_PRIFIX = {
  COMPANYAVATAR: "companyavatar",
  AVATAR: "avatar",
  ID: "identity",
  MOJ: "moj",
  VISA: "visa",
  JOB_CONTRACT: "job-contract",
  TERMS_OF_BUSINESS: "term-of-business",
  TERMS_OF_BUSINESS_COPILOT: "term-of-business-copilot",
  TALENT_CV: "talent-cv",
};

export const USER_TYPE = {
  EMPLOYER: "employer",
  CONTRACTOR: "contractor",
};

export enum EMPLOYER_PAGE {
  BILLING,
  DASHBOARD,
  MANAGE,
  TIMESHEET,
  LISTING,
}

export const LINKEDIN_REGEX =
  /(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/gm;

export const PASSWORD_REGREX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;

export const PHONENUMBER_REGREX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const URL_REGREX =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

export const SET_TIME = 1;
export const NOTICE_PERIOD = 2;

export const OFFICE = 1;
export const MIXED = 2;
export const WORK_FROM_HOME = 3;
export const ALL = 4;

export const COMMISSION_RATE = 2;
export const TAX_RATE = 15;

export const WORK_POLICY = {
  OFFICE: "office",
  MIXED: "mixed",
  WORK_FROM_HOME: "workFromHome",
  ALL: "all",
};

// status
export const USER_STATUS = {
  AVAILABLE: "AVAILABLE", 
  INTERVIEW: "INTERVIEW", 
  // CONTRACT: "contract", 
  ENGAGED: "ENGAGED",
  DEACTIVATED: "DEACTIVATED", 
}

export const EMPLOYMENT_TYPE = {
  PERMANENT: "permanent",
  CONTRACT: "contract",
};

export const API = {
  EDUCATION: {
    DEFAULT: "/api/educations",
    ID: "/api/educations/:id",
  },
  EXPERIENCE: {
    DEFAULT: "/api/experiences",
    ID: "/api/experiences/:id",
  },
  SKILL: {
    DEFAULT: "/api/skills",
    ID: "/api/skills/:id",
  },
  PROFILE: {
    DEFAULT: "/api/user-profiles",
    ID: "/api/user-profiles/:id",
  },
};

export const GST_STARTING_MONTH = {
  MONTHLY: "monthly",
  TWO_MONTHLY: "twoMonthly",
  SIX_MONTHLY: "sixMonthly",
};

export const JOB_TYPE = {
  FIXED: "fixed",
  PERMANENT: "permanent",
  CONTRACT: "contract",
};