import { EMPLOYMENT_TYPE } from "config/constants";

export interface ExperienceParams {
  id?: number;
  title: string;
  company: string;
  city?: string;
  country?: string;
  startDate: string | Date;
  endDate?: string | Date;
  employmentType: string;
  endDateIsPresent: boolean;
  summary: string;
  responsibilities: string;
}

export interface DeleteExperience {
  ids: number[];
}

export const ExperienceEmploymentTypes = [
  { id: 1, name: "Permanent", value: EMPLOYMENT_TYPE.PERMANENT },
  { id: 2, name: "Contract", value: EMPLOYMENT_TYPE.CONTRACT },
];
