import { API, Storage } from "aws-amplify";

export class ClientInfoService {
  static async getAllClient(contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/manualinvoices/clientinfo`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getOneClient(contractorId, clientInfoId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/manualinvoices/clientinfo/${clientInfoId}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async createClient(contractorId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/manualinvoices/clientinfo`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async updateClient(contractorId, clientInfoId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractor/${contractorId}/manualinvoices/clientinfo/${clientInfoId}`;
    const myInit = {
      response: true,
      body: data
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
  static async deleteClient(contractorId, clientInfoId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractor/${contractorId}/manualinvoices/clientinfo/${clientInfoId}`;
    const myInit = {
      response: true,
    };
    return API.del(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
