import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import classes from './styles.module.scss';
import clsx from 'clsx';
import { Nav, Collapse } from "reactstrap";
import { useSelector } from 'react-redux';
import avatar from "assets/img/ryan.jpg";
import logo from "assets/img/logo.png";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import { ImageService } from "services/Image";


var ps;

function Sidebar(props) {
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();
  const { setting } = useSelector((state) => state.employer);
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const [initialImage, setInitialImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const imageKey = `${setting?.idOrganisation}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`;
      try {
        if (imageKey) {
          const imageUrl = await ImageService.getCompanyAvatar(imageKey);
          const imageFile = blobToFile(imageUrl, "avatar.png");
          setInitialImage(URL.createObjectURL(imageFile));
        } else {
          setInitialImage(logo);
        }
      } catch (error) {
        console.error("Failed to load initial image", error);
        setInitialImage(logo);
      }
    };
  
    fetchImage();
  }, [setting]);


  
  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };


  React.useEffect(() => { 
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  });

  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);

  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const coreLinks = [
    {
      name: 'Dashboard',
      path: '/employer/admin/dashboard',
      icon: 'fas fa-tachometer-alt'
    },
    {
      name: 'My Jobs',
      path: '/employer/admin/myjobs',
      icon: 'fas fa-briefcase'
    },
    {
      name: 'My Clients',
      path: '/employer/admin/myclients',
      icon: 'fas fa-user-tie'
    },
    {
      name: 'Messages',
      path: '/employer/admin/messenger',
      icon: 'fas fa-envelope'
    },
    {
      name: 'Candidates',
      path: '/employer/admin/candidates',
      icon: 'fas fa-user-friends'
    },
  ];

  const hrLinks = [
    {
      name: 'Job Descriptions',
      path: '/employer/admin/jobtemplates',
      icon: 'fas fa-file-alt'
    },
  ];
  
  const payrollLinks = [
    {
      name: 'Timesheets',
      path: '/employer/admin/timesheets',
      icon: 'fas fa-clock'
    },
    {
      name: 'Transactions',
      path: '/employer/admin/billing',
      icon: 'fas fa-dollar-sign'
    },
  ];
  
  const organisationLinks = [
    {
      name: 'Organisation Settings',
      path: '/employer/admin/settings',
      icon: 'fas fa-cog'
    },
    {
      name: 'User Management',
      path: '/employer/admin/users',
      icon: 'fas fa-user'
    },
    {
      name: 'Internal Talent',
      path: '/employer/admin/talent',
      icon: 'fas fa-users'
    },
    {
      name: 'Integration',
      path: '/employer/admin/integration',
      icon: 'fas fa-plug'
    },    
    {
      name: 'Contractors',
      path: '/employer/admin/contractor',
      icon: 'fas fa-briefcase'
    },
    // bring back when we do HR 
    //{
    //   name: 'Employees',
    //   path: '/employer/admin/employees',
    //   icon: 'fas fa-id-badge'
    // },
  ];

  //old code for when internal talent goes live
  // const filteredOrganisationLinks = setting?.admin 
  //   ? organisationLinks 
  //   : organisationLinks.filter(link => link.name !== 'Organisation Settings');
  let filteredOrganisationLinks = organisationLinks;
  if (!setting?.admin) {
    filteredOrganisationLinks = filteredOrganisationLinks.filter(link => 
      link.name !== 'Organisation Settings' 
      && link.name !== 'User Management'
      && link.name !== 'Integration'
    );
  }
  
  
  // Filter out 'Internal Talent' if user role is not 7
  let filteredcoreLinks = coreLinks;
  if (user?.role !== 7) {
    filteredcoreLinks = filteredcoreLinks.filter(link => link.name !== 'My Clients');
  }


  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = () => {

    return (
      <>

    
          {filteredcoreLinks.map((link, key) => (
            <li key={key}>
              <NavLink
                to={link.path}
                className={clsx(classes.navlink, { [classes.active]: isActive(link.path) })}
              >
            <i className={link.icon} />
            <span className="sidebar-normal">{link.name}</span>
          </NavLink>
            </li>
          ))}
      
  
        <h6> Payroll</h6>
     
          {payrollLinks.map((link, key) => (
            <li key={key}>
              <NavLink
                to={link.path}
                className={clsx(classes.navlink, { [classes.active]: isActive(link.path) })}
              >
                <i className={link.icon} />
                <span className={classes["sidebar-normal"]}>{link.name}</span>
              </NavLink>
            </li>
          ))}


          <h6> People</h6>
              
              {hrLinks.map((link, key) => (
                <li key={key}>
                  <NavLink
                    to={link.path}
                    className={clsx(classes.navlink, { [classes.active]: isActive(link.path) })}
                  >
                    <i className={link.icon} />
                    <span className={classes["sidebar-normal"]}>{link.name}</span>
                  </NavLink>
                </li>
              ))}
    
  
        <h6>Organisation</h6>
      
          {filteredOrganisationLinks.map((link, key) => (
            <li key={key}>
              <NavLink
                to={link.path}
                className={clsx(classes.navlink, { [classes.active]: isActive(link.path) })}
              >
                <i className={link.icon} />
                <span className={classes["sidebar-normal"]}>{link.name}</span>
              </NavLink>
            </li>
          ))}
     

      </>
    );
  };


  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    
    // console.log("here", window.location.href.indexOf(routeName));
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <>
      <div className={clsx("sidebar", classes.sidebar)} data-color={props.backgroundColor}>
        <div className={clsx('logo', classes.logo)}>
          <a
            className={clsx(classes['simple-text'], classes['logo-mini'])}
            target="_blank"
          >
            <div className="logo-img">
            {initialImage && (
            <img className={classes.img} src={initialImage} alt="react-logo" />
            )}
            </div>
          </a>
          <a
                className={clsx(classes['logo-text'], classes['logo-normal'], 'logo-normal')}
                target="_blank"
              >
                {setting && (
                  setting?.name || "NECTA"
                )}
              </a>
          <div className={classes['navbar-minimize']}>
            {/* <Button
              outline
              className="btn-round btn-icon"
              color="neutral"
              id="minimizeSidebar"
              onClick={() => props.minimizeSidebar()}
            >
              <i className={clsx("now-ui-icons text_align-center", classes['visible-on-sidebar-regular'])} />
              <i className={clsx("now-ui-icons design_bullet-list-67", classes['visible-on-sidebar-mini'])} />
            </Button> */}
          </div>
        </div>

        <div className={clsx("sidebar-wrapper", classes['sidebar-wrapper'])} ref={sidebar}>
          {/* <div className={clsx("user", classes['user'])}>
            <div className={classes.photo}>
              <img src={avatar} alt="Avatar" />
            </div>
            <div className={clsx("info", classes['info'])}>
              <a
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={openAvatar}
                onClick={() => setOpenAvatar(!openAvatar)}
              >
                <span>
                  Michael Law
                  <b className={classes.caret} />
                </span>
              </a>
              <Collapse isOpen={openAvatar}>
                <ul className={classes.nav}>
                  <li>
                    <a href="#pablo" onClick={(e) => e.preventDefault}>
                      <span className={classes['sidebar-mini-icon']}>MP</span>
                      <span className={classes['sidebar-normal']}>My Profile</span>
                    </a>
                  </li>
                  <li>
                    <a href="#pablo" onClick={(e) => e.preventDefault}>
                    <span className={classes['sidebar-mini-icon']}>EP</span>
                    <span className={classes['sidebar-normal']}>Edit Profile</span>
                    </a>
                  </li>
                  <li>
                    <a href="#pablo" onClick={(e) => e.preventDefault}>
                    <span className={classes['sidebar-mini-icon']}>S</span>
                    <span className={classes['sidebar-normal']}>Settings</span>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div> */}
          <Nav className={clsx("nav", classes['nav'])}>{createLinks()}</Nav>
        </div>
      </div>
    </>
  );
}

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: "orange",
  minimizeSidebar: () => {},
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf([
    "orange",
    "blue",
    "yellow",
    "green",
    "red",
    "purple",
  ]),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

export default Sidebar;
