import { createRef, memo, RefObject, useEffect, useState } from "react";
import clsx from "clsx";
import { Col, Container, Row, Button, UncontrolledTooltip } from "reactstrap";
import classes from "./styles.module.scss";
import EditBasicProfile from "./EditBasicProfile";
import { ImageService } from "services/Image";
import defaultAvatar from "assets/img/placeholder.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { Link } from "react-router-dom";
import routes from "routers/routes";
import { BasicProfileService } from "services/Contractor/NewMember/BasicProfile";
import moment from "moment";
import { ReducerType } from "redux/reducers";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";
import { WORK_POLICY } from "config/constants";
//import { UserChatEngineService } from "services/Message/User/UserChatEngine";
import password from "secure-random-password";
import { UserService } from "services/User";
import { setUserReducer } from "redux/reducers/User/actionTypes";
import { UtilDefaultAvatar } from "utils/defaultAvatar";
import { convertVisaType } from "utils/moj";
import { useModal } from 'components/Modals';

const ProfilePageHeader = memo(() => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  let pageHeader = createRef();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [location, setLocation] = useState({
    city: "",
    country: "",
  });
  const [numberOfTestimonials, setNumberOfTestimonials] = useState(null);
  const [yearsExperience, setYearsExperience] = useState(null);
  const { onOpen: openCreateCV} = useModal('CreateCV');

  const handleOpenCreateCVModal = ({ profile }) => {
    // Create a new object that includes the existing profile data
    const updatedProfile = {
      ...profile,
      contact: {
        ...profile.contact,
        email: user?.email,
      },
    };
  
    // Call the function to open the CV modal with the updated profile
    openCreateCV({ profile: updatedProfile });
  };
  
  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        (pageHeader.current as any).style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, [pageHeader]);

  useEffect(() => {
    if (profile) {
      if (profile.basicProfile?.photoUrl) {
        dispatch(setLoading(true));
        ImageService.getImage(profile.basicProfile?.photoUrl, null)
          .then((res) => setAvatar(res))
          .catch((e) => dispatch(setErrorMess(e)))
          .finally(() => dispatch(setLoading(false)));
      } else {
        setAvatar(null);
      }

      if (profile.contact) {
        setLocation({
          city: profile.contact?.city,
          country: profile.contact?.country,
        });
      }

      if (!!profile.experience?.length) {
        let minDate = new Date(profile.experience[0]?.startDate);
        let maxDate = new Date(profile.experience[0]?.endDate);
        profile.experience?.map((item) => {
          if (new Date(item?.startDate) < minDate) {
            minDate = new Date(item?.startDate);
          }
          if ((item as any)?.endDateIsPresent) {
            maxDate = new Date();
          } else if (new Date(item?.endDate) > maxDate) {
            maxDate = new Date(item?.endDate);
          }
          return null;
        });
        const totalNumberOfMonths =
          maxDate.getMonth() -
          minDate.getMonth() +
          12 * (maxDate.getFullYear() - minDate.getFullYear());
          setYearsExperience((totalNumberOfMonths / 12).toFixed());
      }

      setNumberOfTestimonials(
        profile.testimonial?.filter((item) => item?.response !== null).length
      );
    }
  }, [dispatch, profile]);

  // const updateUserChatEngine = (avt, firstName, lastName, basicProfile) => {
  //   UserChatEngineService.getUserChatEngine(user?.chatUserId)
  //     .then(async (res) => {
  //       const customJson = {
  //         ...JSON.parse(res?.custom_json),
  //         ...basicProfile,
  //       };
  //       let form_data = new FormData();
  //       form_data.append("username", user?.id.toString());
  //       form_data.append("first_name", firstName);
  //       form_data.append("last_name", lastName);
  //       if (avt) {
  //         form_data.append("avatar", avt, avt.name);
  //       } else {
  //         const defaultAvatar = await UtilDefaultAvatar.getFile();
  //         form_data.append("avatar", defaultAvatar, defaultAvatar.name);
  //       }
  //       form_data.append("secret", user?.chatSecret);
  //       form_data.append("custom_json", JSON.stringify(customJson));
  //       UserChatEngineService.patchUserChatEngine(
  //         user?.chatUserId,
  //         form_data
  //       ).catch((e) => dispatch(setErrorMess(e)));
  //     })
  //     .catch((e) => dispatch(setErrorMess(e)));
  // };

  const handleUpdateBasicProfile = (dataSubmit) => {
    // console.log("Do you have a profile?" + profile?.basicProfile?.id)
    if (profile?.basicProfile?.id) {
      // console.log("data being sent for put" + JSON.stringify(dataSubmit))
      BasicProfileService.putBasicProfile(dataSubmit, profile?.basicProfile?.id)
        .then((res) => {
          // console.log("data being recieved" + JSON.stringify(dataSubmit))
          // updateUserChatEngine(
          //   dataSubmit?.photoFile,
          //   dataSubmit?.firstName,
          //   dataSubmit?.lastName,
          //   {
          //     title: dataSubmit?.title,
          //     about: dataSubmit?.about,
          //   }
          // );
          dispatch(setProfileReducer({ ...profile, basicProfile: res }));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    } else {
      // console.log("data being sent"  + JSON.stringify(dataSubmit))
      BasicProfileService.postBasicProfile(dataSubmit)
      
        .then(async (res) => {
          // console.log("Response" + JSON.stringify(res))
          // const chatSecret = password.randomPassword({
          //   characters: [password.lower, password.upper, password.digits],
          // });
          // let form_data = new FormData();
          // form_data.append("username", user?.id.toString());
          // form_data.append("first_name", dataSubmit?.firstName);
          // form_data.append("last_name", dataSubmit?.lastName);
          // if (res?.photoUrl) {
          //   if (dataSubmit?.photoFile) {
          //     form_data.append(
          //       "avatar",
          //       dataSubmit?.photoFile,
          //       dataSubmit?.photoFile.name
          //     );
          //   }
          // } else {
          //   const defaultAvatar = await UtilDefaultAvatar.getFile();
          //   form_data.append("avatar", defaultAvatar, defaultAvatar.name);
          // }
          // form_data.append("secret", chatSecret);
          // form_data.append(
          //   "custom_json",
          //   JSON.stringify({
          //     title: dataSubmit?.title,
          //     about: dataSubmit?.about,
          //   })
          // );
          // UserChatEngineService.postUserChatEngine(form_data)
          //   .then((res) => {
          //     UserService.putUser(user?.id, {
          //       ...user,
          //       chatUserId: res?.id,
          //       chatSecret: chatSecret,
          //     })
          //       .then(() => {
          //         dispatch(
          //           setUserReducer({
          //             ...user,
          //             chatUserId: res?.id,
          //             chatSecret: chatSecret,
          //           })
          //         );
          //       })
          //       .catch((e) => dispatch(setErrorMess(e)));
          //   })
          //   .catch((e) => dispatch(setErrorMess(e)));

          dispatch(setProfileReducer({ ...profile, basicProfile: res }));
        })
        .catch((e) => dispatch(setErrorMess(e)));
    }
  };

  const handleToggle = () => setIsOpenEdit(!isOpenEdit);

  return (
    <>
      <div
        className={clsx(classes.pageHeader, "page-header clear-filter ")}
        filter-color="yellow"
      >
        <div
          className="page-header-image"
          style={{
            backgroundColor: "black",
          }}
          ref={pageHeader as RefObject<HTMLDivElement>}
        />
        <Container className={`position-relative ${classes.container}`}>
          <div className="photo-container">
            <img alt="Avatar" src={avatar ? avatar : defaultAvatar}></img>
          </div>
          <h3 className={`title ${classes.title}`}>
            {profile?.basicProfile?.firstName && profile?.basicProfile?.lastName
              ? `${profile?.basicProfile?.firstName} ${profile?.basicProfile?.lastName}`
              : "..."}
          </h3>
          <p className="category">{profile?.basicProfile?.title || "..."}</p>

          <div className="content mb-3">
            <div className="social-description">
              <h2>{yearsExperience ? yearsExperience : "..."}</h2>
              <p>Years Experience </p>
            </div>
            <div className="social-description">
              <h2>{numberOfTestimonials || "0"}</h2>
              <p>{numberOfTestimonials > 1 ? "Testimonials" : "Testimonial"}</p>
            </div>
            <div className="social-description">
              <h2>${profile?.preference?.maxRate || "..."}</h2>
              <p>Preferred Rate</p>
            </div>
          </div>

          <Row className={classes.infoRow}>
            <Col xs="0" sm="2" />
            <Col xs="6" sm="4" className={classes.infoGroup}>
              <p className="mb-0">
                Available
                {profile?.preference?.availableDate
                  ? profile?.preference?.noticePeriod
                    ? " on:"
                    : " in:"
                  : profile?.preference?.noticePeriod
                  ? ` in:`
                  : " on:"}
              </p>
              <h2>
                {profile?.preference?.availableDate
                  ? moment(profile?.preference?.availableDate).format(
                      "DD/MM/YYYY"
                    )
                  : profile?.preference?.noticePeriod
                  ? `${profile?.preference?.noticePeriod} week(s)`
                  : "..."}
              </h2>
            </Col>
            <Col xs="6" sm="4" className={classes.infoGroup}>
              <p className="mb-0">Work Style:</p>
              <h2>
                {(profile?.preference?.workPolicy ===
                  WORK_POLICY.WORK_FROM_HOME &&
                  "Work from Home") ||
                  (profile?.preference?.workPolicy === WORK_POLICY.MIXED &&
                    "Hybrid") ||
                  (profile?.preference?.workPolicy === WORK_POLICY.OFFICE &&
                    "Office") ||
                  (profile?.preference?.workPolicy === WORK_POLICY.ALL &&
                    "All") ||
                  "..."}
              </h2>
            </Col>
            <Col xs="0" sm="2" />
            <Col xs="0" sm="2" />
            <Col xs="6" sm="4" className={classes.infoGroup}>
              <p className="mb-0">Visa Type:</p>
              <h2>
                {profile?.mojCheck?.visaType
                  ? convertVisaType(profile?.mojCheck?.visaType)?.name
                  : "..."}
              </h2>
            </Col>
            <Col xs="6" sm="4" className={classes.infoGroup}>
              <p className="mb-0">Location:</p>
              <h2>
                {location?.city && location?.country
                  ? `${location?.city}, ${location?.country}`
                  : "..."}
              </h2>
            </Col>
            <Col xs="0" sm="2" />
          </Row>
          <Button
            className={clsx(
              "btn-icon btn-round",
              classes.editBtnDefault,
              classes.editBtn
            )}
            onClick={handleToggle}
          >
            <i className="fas fa-edit" />
          </Button>
          <EditBasicProfile
            onClose={handleToggle}
            isOpen={isOpenEdit}
            data={profile?.basicProfile}
            handleUpdateBasicProfile={handleUpdateBasicProfile}
          />
        </Container>
      </div>
      <div className="section">
        <Container className="position-relative">
          <div className="button-container">
          <Button
              className="btn-round mr-1"
              color="primary"
              size="lg"
              onClick={() => handleOpenCreateCVModal({ profile })}
          >
              Create a CV
            </Button>
            {/* <Button
              className="btn-round btn-icon mr-1"
              color="default"
              id="tooltip871723210"
              size="lg"
              href={
                profile?.basicProfile?.linkedinUrl?.includes("http")
                  ? `${profile?.basicProfile?.linkedinUrl}`
                  : `//${profile?.basicProfile?.linkedinUrl}`
              }
              target="_blank"
            >
              <i className="fab fa-linkedin" />
            </Button> 
            <UncontrolledTooltip delay={0} target="tooltip871723210">
              See my Linkedin Profile
            </UncontrolledTooltip> */}
          </div>
          <h2 className="title text-center">About me</h2>
          <p className={`description ${classes.description}`}>
            {profile?.basicProfile?.about}
          </p>
        </Container>
      </div>
    </>
  );
});

export default ProfilePageHeader;
