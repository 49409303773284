import { memo } from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import classes from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleArchive: () => void;
}

const ArchiveWarningModal = memo(({ isOpen, onClose, handleArchive }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={classes.termModal}>
      <ModalHeader className={classes.modalHeader}>
        <strong>Warning:</strong> All related job information will be archived.
        You CAN NOT undo this action.
      </ModalHeader>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="danger" onClick={handleArchive}>
          Archive
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export default ArchiveWarningModal;
