import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from '../error-boundary/ErrorBoundary';
import { isModalOpen, getModalMeta } from '../../redux/store/selector';
import { closeModal } from '../../redux/store/action';
import { UIState } from '../../redux/store/reducer';
import { ModalMeta } from '../../typings/modal';


interface ILazyComponentProps {
  filename: string;
}

export function LazyComponent({ filename }: ILazyComponentProps) {


  const dispatch = useDispatch();
  const isOpen = useSelector<UIState, boolean>((state) =>
    isModalOpen(state, filename)
  );
  const meta = useSelector<UIState, ModalMeta | undefined>((state) =>
    getModalMeta(state, filename)
  );

  const handleModalClose = () => {
    dispatch(closeModal(filename));
  };

  // const ComponentWrapper = React.lazy(() =>
  //   import(`./${filename}/${filename}.tsx`).then((module) => ({
  //     default: module[filename],
  //   }))
  // );

  const ComponentWrapper = React.lazy(() => import(`./${filename}/${filename}.tsx`));


//  console.log('isOpen:', isOpen, 'filename:', filename);
  return (
    <Suspense fallback={null}>
      <ErrorBoundary>
        {filename ? (
          <ComponentWrapper isOpen={isOpen} onClose={handleModalClose} {...meta} />

        ) : null}
      </ErrorBoundary>
    </Suspense>
  );
}
