import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_ALL_CLIENT_CANDIDATE_LIST_REQUEST,
  setClientCandidateListReducer,
} from "redux/reducers/Employer/actionTypes";
import { TalentProfileService } from "services/Employer/Listing/Talent/TalentProfile";

function* requestGetClientCandidateList() {
  try {
    yield put(setLoading(true));
    // console.log("ummmm")
    const [jobList] = yield all([call(TalentProfileService.getAllClientCandidates)]);
    yield put(setClientCandidateListReducer(jobList));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getAllClientCandidateList() {
  yield takeLatest(GET_ALL_CLIENT_CANDIDATE_LIST_REQUEST, requestGetClientCandidateList);
}

export default getAllClientCandidateList;
