import { memo } from "react";
import ScrollNavbar from "components/Navbars/Employer/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import routes from "routers/routes";
import { Container } from "reactstrap";
import { Redirect, Switch } from "react-router-dom";
import classes from "./styles.module.scss";
import BasicProfile from "./components/BasicProfile";
import Organisation from "./components/Organisation";
import EmployerAuthRoute from "routers/EmployerAuthRoute";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const NewMember = memo(({}: Props) => {
  return (
    <>
      <ScrollNavbar name="Talent" to={routes.contractor.login} />
      <div className="page-header header-filter" filter-color="yellow">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/employer_login.jpg") + ")",
          }}
        />
        {/* <div className="content"> */}
          <Container className={classes.pageContainer}>
            <Switch>
              <EmployerAuthRoute
                path={routes.employer.newMember.basicProfile}
                render={(props) => <BasicProfile {...props} />}
              />
              <EmployerAuthRoute
                path={routes.employer.newMember.organization}
                render={(props) => <Organisation {...props} />}
              />

              <Redirect
                from={routes.employer.newMember.root}
                to={routes.employer.newMember.basicProfile}
              />
            </Switch>
          </Container>
        {/* </div> */}
        <Footer />
      </div>
    </>
  );
});

export default NewMember;
