import { Fragment, memo } from "react";
import { Container } from "reactstrap";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import classes from "./styles.module.scss";
import clsx from "clsx";
import FooterMobile from "components/Footers/FooterMobile";

interface Props {}

const Payroll = memo((props: Props) => {

  return (
    <Fragment>
      <ScrollNavbar />
      <div className="page-header header-filter" filter-color="yellow">
        <div className={classes.header} />
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project6.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.container}>
            <p className={classes.title}>PAYROLL</p>
          </Container>
        </div>
        <Footer />
      </div>
      <FooterMobile />
    </Fragment>
  );
});

export default Payroll;
