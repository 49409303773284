import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Input, Button } from 'reactstrap';
import classes from '../styles.module.scss';
import CustomInput from 'components/Common/CustomInput';
import SendMessage from 'components/Common/Icons/Submit/send-message';

const ChatInput = ({ userId, roomId, receiverId, onSendMessage }) => {
  const [input, setInput] = useState('');

  const handleSendMessage = () => {
    onSendMessage(userId, receiverId, roomId, input);
    setInput('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className={classes.inputContainer}>
      <CustomInput
        type='text'
        name='sendMessage'
        className={classes.inputTextarea}
        placeholder='Type a message...'
        value={input}
        onChange={(event) => setInput(event.target.value)}
        onKeyDown={handleKeyDown}
        autoComplete='off'
      />

      {input && (
        <div
          className={classes.SendMessageContainer}
          onClick={handleSendMessage}
        >
          <SendMessage width='28px' height='auto' />
        </div>
      )}
    </div>
  );
};

export default ChatInput;
