import { memo } from "react";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import { Container } from "reactstrap";
import ScrollToTop from "routers/ScrollToTop"
import { Redirect, Switch } from "react-router-dom";
import routes from "routers/routes";
import BasicProfile from './components/BasicProfile'
import FutureOpportunities from "./components/FutureOpportunities";
import Preference from "./components/Preference";
import classes from "./styles.module.scss";
import ContactProfile from "./components/ContactProfile";
import Testimonials from "./components/Testimonials";
import Education from "./components/Education";
import MOJ from "./components/MojCheck";
import Skill from "./components/Skill";
import Experience from "./components/Experience";
import ContractorAuthRoute from "routers/ContractorAuthRoute";
import UploadCv from "./components/UploadCv";
import Visa from "./components/Visa"

interface Props {}

// eslint-disable-next-line no-empty-pattern
export const NewMember = memo(({}: Props) => {
  return (
    <>
      <ScrollToTop className="login-page" />
      <ScrollNavbar />
      <div className={`page-header header-filter ${classes.page}`} filter-color="yellow">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/login.jpg") + ")",
          }}
        />
        <div className={classes.pageContent}>
          <Container className={classes.pageContainer}>
            <Switch>
              <ContractorAuthRoute path={routes.contractor.newMember.uploadCv} render={(props) => <UploadCv {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.basicProfile} render={(props) => <BasicProfile {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.contactProfile} render={(props) => <ContactProfile {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.education} render={(props) => <Education {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.experience} render={(props) => <Experience {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.futureOpportunities} render={(props) => <FutureOpportunities {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.skill} render={(props) => <Skill {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.preference} render={(props) => <Preference {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.visa} render={(props) => <Visa {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.testimonials} render={(props) => <Testimonials {...props} />} />
              <ContractorAuthRoute path={routes.contractor.newMember.mojCheck} render={(props) => <MOJ {...props} />} />
              
              <Redirect from={routes.contractor.newMember.root} to={routes.contractor.newMember.uploadCv} />
            </Switch>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  )
})

export default NewMember