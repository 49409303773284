import { API } from "aws-amplify";
import { BasicProfileParams } from "models/Contractor/NewMember/BasicProfile";

export class BasicProfileService {
  static async postBasicProfile(data: BasicProfileParams): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/contractorprofile";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getBasicProfile(): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/contractorprofile";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putBasicProfile(
    data: BasicProfileParams,
    id: number
  ): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractorprofile/${id}`;
    const myInit = {
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
