import { all } from 'redux-saga/effects';
import { authSagas } from './Auth';
import { userSagas } from './User';
import { contractorSagas } from './Contractor';
import { employerSagas } from './Employer';
import updateAvatar from './User/updateAvatar';

// Register all your watchers
export const rootSaga = function* root() {
  yield all([
    authSagas(),
    userSagas(),
    contractorSagas(),
    employerSagas(),
    updateAvatar(),
  ]);
};
