// jobBoardsData.js
import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const jobBoards = [
  {
    name: 'TradeMe',
    id: 'trademe',
    price: '$129',
    icon: faBriefcase,
    details: 'A popular job board in New Zealand.',
  },
  {
    name: 'LinkedIn',
    id: 'linkedin',
    price: '',
    icon: faBriefcase,
    details: 'Professional networking platform for job postings.',
  },
  {
    name: 'Seek',
    id: 'seek',
    price: '$389',
    icon: faBriefcase,
    details: 'One of the largest job boards in Australia and New Zealand.',
  },
  {
    name: 'Do Good Jobs',
    id: 'dogoodjobs',
    price: '$169',
    icon: faBriefcase,
    details: 'A job board focusing on not-for-profit and social enterprise roles.',
  },
];