import { Fragment, memo } from "react";
import ScrollNavbar from 'components/Navbars/Contractor/ScrollNavbar'
import routes from "routers/routes";
import ContractorAuthRoute from "routers/ContractorAuthRoute";
import { Redirect, Switch } from "react-router-dom";
import Messenger from "./components/Messenger";
import Dashboard from "./components/Dashboard";
import Setting from "./components/Setting";
import ListingJob from "./components/ListingJob";
import FooterEmployerMobile from "components/Footers/FooterEmployerMobile";
//import { ChatEngineWrapper } from "react-chat-engine";
import Talent from "./components/Talent";
import { useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";

interface Props {}

const Listing2 = memo((props: Props) => {
  const { user } = useSelector((state: ReducerType) => state.user);

  return (
    <Fragment>
      <ScrollNavbar />
      <Switch>
        <ContractorAuthRoute path={routes.contractor.listing.jobProgress} render={(props) => <Messenger {...props} />} />
        <ContractorAuthRoute path={routes.contractor.listing.settings} render={(props) => <Setting {...props} />} />
        <ContractorAuthRoute path={routes.contractor.listing.dashboard} render={(props) => <Dashboard {...props} />} />
        <ContractorAuthRoute path={routes.contractor.listing.listingJob} render={(props) => <ListingJob {...props} />} />
        {/* <ContractorAuthRoute path={routes.employer.listing.assistCandidates} render={(props) => <AssistCandidates {...props} />} /> */}
        {/* {user?.role === 7 && <ContractorAuthRoute path={routes.contractor.listing.talent} render={(props) => <Talent {...props} />} />} */}

        <Redirect from={routes.employer.listing.root} to={routes.employer.listing.dashboard} />
      </Switch>

      <FooterEmployerMobile />
    </Fragment>
  );
});

export default Listing2;
