/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useMemo, useState } from "react";
import Logo from "../../../assets/img/logo.png";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import ScrollNavbar from "components/Navbars/Employer/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "./styles.module.scss";
import { useForm } from "react-hook-form";
import CustomInput from "components/Common/CustomInput";
import routes from "routers/routes";
import { PASSWORD_REGREX, SIGN_UP_MODE, USER_TYPE } from "config/constants";
import userFactory from "services/userService/UserFactory";
import CustomCheckbox from "components/Common/CustomCheckbox";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { ReducerType } from "redux/reducers";
import { useParams, useLocation } from "react-router-dom";
import { ImageService } from "services/Image";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";

interface SignupProps {}

// eslint-disable-next-line no-empty-pattern
const Signup = memo(({}: SignupProps) => {
  const [user, setUser] = useState(null);
  const [mode, setMode] = useState(SIGN_UP_MODE.CREATE);
  const [isResendEmail, setIsResendEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const userState = useSelector((state: ReducerType) => state.user);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const organisationID = params.get("organisationID");
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

  

  const schema = useMemo(() => {
    return mode === SIGN_UP_MODE.CREATE
      ? yup.object().shape({
          email: yup
            .string()
            .email("Please enter a valid email")
            .required("Email is required"),
          password: yup
            .string()
            .required("Password is required")
            .matches(
              PASSWORD_REGREX,
              "At least 8 characters, must include 1 upper case, 1 lower case, 1 number, and 1 special character"
            ),
          acceptTerm: yup
            .boolean()
            .oneOf([true], "Please accept our T&C to use our services."),
        })
      : yup
          .object()
          .shape({ code: yup.string().required("This field is required") });
  }, [mode]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (userState?.user) {
      if (userState?.user?.profileCompleted) {
        dispatch(push(routes.employer.admin.dashboard));
      } else {
        dispatch(push(routes.employer.newMember.root));
      }
    }
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchCustomization = async (id: string) => {
      try {
        const imageKey = `${id}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`;
        const imageBlob = await ImageService.getCompanyAvatar(imageKey);
        const imageFile = blobToFile(imageBlob, "avatar.png");
        setImagePreviewUrl(URL.createObjectURL(imageFile));
      } catch (error) {
        setImagePreviewUrl(Logo); // Fallback to default logo
      }
    };
  
    if (organisationID) {
      fetchCustomization(organisationID);
    } else {
      setImagePreviewUrl(Logo);
    }
  }, [organisationID]);

  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const handleShowModal = () => setModal(!modal);

  const handleAcceptTerm = () => {
    setValue("acceptTerm", true);
    setModal(!modal);
  };

  const handleDeclineTerm = () => {
    setValue("acceptTerm", false);
    setModal(!modal);
  };

  const handleResendEmail = async () => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .resendConfimationCode(user)
      .then(() => {
        setIsResendEmail(true);
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const handleConfirm = async (data) => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .confirm(user, data?.code)
      .then(() => {
        dispatch(push(organisationID ? `/employer/login/${organisationID}` : routes.employer.login));
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const handleSignUp = async (data) => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .register(data?.email, data?.password, USER_TYPE.EMPLOYER)
      .then((result) => {
        setUser(result.username);
        setMode(SIGN_UP_MODE.CONFIRM);
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
        setUser(null);
      })
      .finally(() => dispatch(setLoading(false)));
  };
 
  return (
    <>
    {!organisationID && <ScrollNavbar name="Talent" to={routes.contractor.login} />}
      <div className="page-header header-filter" filter-color="yellow">
        <div
          className="page-header-image"
          style={{
            backgroundImage: organisationID
              ? "none"
              : "url(" + require("assets/img/employer_login.jpg") + ")",
            backgroundColor: organisationID ? "#333" : "transparent",
          }}
        ></div>
        <div className="content">
          <Container>
            <Row>
            {!organisationID && 
            <>
              <Col
                className="ml-auto mr-auto d-none d-md-block"
                md="6"
                lg="4"
                style={{ background: "rgba(0, 0, 0, 0.8)" }}
              >
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons media-2_sound-wave"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Connecting the right people</h5>
                    <p className="description" style={{ fontWeight: "400" }}>
                      We simplify the finding talent experience. Our software
                      allows you to enter your requirements and automatically
                      recieve offers that match the closest
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons media-1_button-pause"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">
                      End to End Technology Partner
                    </h5>
                    <p className="description" style={{ fontWeight: "400" }}>
                      Continuously updating new features to simplify finding
                      talent, managing contracts, approving timesheets and
                      creating a single payroll invoice for all.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons users_single-02"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Simple Pricing</h5>
                    <p className="description" style={{ fontWeight: "400" }}>
                      Necta provides free membership for all users, with
                      affordable options for additional services to meet varying
                      needs and a $2 payroll to maintain talent through our
                      automated system.
                    </p>
                  </div>
                </div>
              </Col>
              </>
}
<Col
                  className={`mr-auto my-auto ${organisationID ? "ml-auto" : ""}`}
                  md={organisationID ? "6" : "6"}
                  lg={organisationID ? "4" : "4"}
                >
                <Card className="card-signup">
                  <CardBody>
                  {imagePreviewUrl && (
                    <img
                      alt="Logo"
                      src={organisationID ? imagePreviewUrl : Logo}
                      width={150}
                    />
                  )}
                    <CardTitle className="text-center" tag="h4">
                      {organisationID
                        ? (!user ? "Create User" : "Confirm your email")
                        : (!user ? "Employer Sign Up" : "Confirm your email")}
                    </CardTitle>

                    {!user ? (
                      <Form
                        action=""
                        className="form"
                        method=""
                        onSubmit={handleSubmit(handleSignUp)}
                      >
                        <div className={classes.inputContainer}>
                          <CustomInput
                            startIcon={
                              <i className="now-ui-icons users_circle-08" />
                            }
                            placeholder="Email"
                            type="email"
                            autoComplete="off"
                            inputRef={register("email")}
                            errorMessage={errors.email?.message}
                          />
                          <CustomInput
                            startIcon={<i className="fa fa-text-height" />}
                            placeholder="Password"
                            type="password"
                            inputRef={register("password")}
                            errorMessage={errors.password?.message}
                          />
                          <CustomCheckbox
                            content={
                              <>
                                I agree to the{" "}
                                <a
                                  href="javascript:void(0);"
                                  onClick={handleShowModal}
                                >
                                  terms and conditions
                                </a>
                                <Modal
                                  isOpen={modal}
                                  toggle={handleShowModal}
                                  className={classes.termModal}
                                >
                                  <ModalHeader toggle={handleShowModal}>
                                    Terms And Conditions
                                  </ModalHeader>
                                  <ModalBody>
                                    Please read this agreement carefully before accessing or using the information and
                                    services available through the Necta website (“Site”). By accessing or using the Site,
                                    you agree to be bound by the terms and conditions. Necta may modify this
                                    agreement at any time, and such modifications shall be effective immediately
                                    upon posting the modified agreement on the Site. Advertiser terms and conditions must
                                    be accepted and adhered to by Advertisers who use Necta's services and the Site. Necta
                                    also has a comprehensive terms on our site <a href="https://necta.nz/terms">HERE</a>.
                                  </ModalBody>

                                  <ModalFooter className="d-flex justify-content-end">
                                    <Button
                                      color="primary"
                                      onClick={handleAcceptTerm}
                                    >
                                      Accept
                                    </Button>
                                    <Button
                                      color="default"
                                      onClick={handleDeclineTerm}
                                      className="ml-3"
                                    >
                                      Decline
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                                .
                              </>
                            }
                            inputRef={register("acceptTerm")}
                            errorMessage={errors.acceptTerm?.message}
                          />
                          <span className={`text-danger ${classes.text}`}>
                            {errorMessage}
                          </span>
                        </div>
                        <CardFooter className={classes.cardFooter}>
                          <Button
                            block
                            className="btn"
                            color={organisationID ? "secondary" : "primary"}
                            type="submit"
                            size="md"
                          >
                            Get Started
                          </Button>
                          <div className={classes.btnHaveAccountContainer}>
                            <Button
                              className={classes.btnHaveAccount}
                              tag={Link}
                              to={organisationID ? `/employer/login/${organisationID}` : routes.employer.login}
                              color="neutral"
                            >
                              I have an account
                            </Button>
                          </div>
                        </CardFooter>
                      </Form>
                    ) : (
                      <Form
                        action=""
                        className="form"
                        method=""
                        onSubmit={handleSubmit(handleConfirm)}
                      >
                        <CardBody className={classes.cardBody}>
                          <div className={classes.inputCodeContainer}>
                            <p className={classes.text}>
                              {isResendEmail
                                ? "New email has been sent"
                                : "The code has been sent to your email"}
                            </p>
                            <CustomInput
                              placeholder="Verification code"
                              type="text"
                              autoComplete="off"
                              inputRef={register("code")}
                              errorMessage={errors.code?.message}
                            />
                            <p className={classes.text}>
                              <span onClick={handleResendEmail}>
                                Resend email?
                              </span>
                            </p>
                            <span className={`text-danger ${classes.text}`}>
                              {errorMessage}
                            </span>
                          </div>
                          <CardFooter className={classes.cardFooter}>
                            <Button
                              className="btn-round"
                              color={organisationID ? "secondary" : "primary"}
                              type="submit"
                              size="lg"
                            >
                              Submit
                            </Button>
                            <div className={classes.btnHaveAccountContainer}>
                              <Button
                                className={classes.btnHaveAccount}
                                tag={Link}
                                to={routes.contractor.login}
                                color="neutral"
                              >
                                I have an account
                              </Button>
                            </div>
                          </CardFooter>
                        </CardBody>
                        {!organisationID && <CardFooter className="text-center"></CardFooter>}
                      </Form>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
});

export default Signup;
