import { API, Storage } from "aws-amplify";

export class LineItemService {
  static async getAllLineItem(contractorId, invoiceId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/manualinvoices/${invoiceId}/lineitems`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getOneLineItem(contractorId, invoiceId, lineItemId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractor/${contractorId}/manualinvoices/${invoiceId}/lineitems/${lineItemId}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async createLineItem(contractorId, invoiceId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/manualinvoices/${invoiceId}/lineitems`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async updateManualInvoice(contractorId, invoiceId, lineItemId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractor/${contractorId}/manualinvoices/${invoiceId}/lineitems/${lineItemId}`;
    const myInit = {
      response: true,
      body: data
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
  static async deleteManualInvoice(contractorId, invoiceId, lineItemId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractor/${contractorId}/manualinvoices/${invoiceId}/lineitems/${lineItemId}`;
    const myInit = {
      response: true,
    };
    return API.del(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
