import { memo, useState, useEffect, Fragment } from "react";
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import FooterTransparentEmployer from "components/Footers/FooterTransparentEmployer";
import classes from "./styles.module.scss";
import clsx from "clsx";
import CustomInput from "components/Common/CustomInput";
import { EmployerTimesheetService } from "services/Employer/Timesheet/Timesheet";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import moment from "moment";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { getAllTimesheets, getAllApproverTimesheets } from "redux/reducers/Employer/actionTypes";
import { TypesOfTimesheetStatus } from "models/Employer/Manage/Timesheet";
import { TIMESHEET_STATUS } from "config/constants";
import PopupHandle from "./PopupHandle";
import { XeroInvoiceService } from "services/Xero/XeroInvoice";
import {
  InvoiceTypes,
  AccountCode,
  TaxType,
} from "models/Employer/Listings/Xero";
import { COMMISSION_RATE } from "config/constants";
import { InvoiceService } from "services/Employer/Manage/Invoice";
import { BillService } from "services/Contractor/Bill/Bill";
import { XeroContactService } from "services/Xero/XeroContact";

interface Props { }

const Timesheet = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { timesheets } = useSelector((state: ReducerType) => state.employer);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  const { timesheetsApprover } = useSelector((state: ReducerType) => state.employer);
  const [increasingFilterDate, setIncreasingFilterDate] = useState(true);
  const [stageFilter, setStageFilter] = useState(null);
  const [timesheetList, setTimesheetList] = useState([]);
  const [valueOfSearchInput, setValueOfSearchInput] = useState("");
  const [popupHandle, setPopupHandle] = useState({
    isOpen: false,
    timesheet: null,
  });

  useEffect(() => {
    const timesheetsApproverArray = [...timesheetsApprover].filter((item) => (timesheets?.findIndex((tItem) => tItem?.id === item?.id) === -1)).map((item) => ({
      ...item,
      typeOfTimesheet: "approver"
    }));
    const newArray = timesheets.concat(timesheetsApproverArray)
    handleSetTimesheetList(newArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, increasingFilterDate, stageFilter, timesheets, timesheetsApprover]);

  const handleSetTimesheetList = (inputTimeSheet) => {
    let tempTimesheetList = [];

    tempTimesheetList = [...inputTimeSheet];

    if (increasingFilterDate && tempTimesheetList?.length) {
      tempTimesheetList.sort((a: any, b: any) => {
        const firstTime = new Date(b.weekEnding).getTime();
        const secondTime = new Date(a.weekEnding).getTime();
        return firstTime - secondTime;
      });
    } else if (!increasingFilterDate && tempTimesheetList?.length) {
      tempTimesheetList.sort((a: any, b: any) => {
        const firstTime = new Date(b.weekEnding).getTime();
        const secondTime = new Date(a.weekEnding).getTime();
        return -firstTime + secondTime;
      });
    }

    let tempStageTimesheets = [];

    if (stageFilter === TypesOfTimesheetStatus.approved.value) {
      tempStageTimesheets = tempTimesheetList.filter(
        (item) => item?.approved === true && item?.paid === false
      );
    } else if (stageFilter === TypesOfTimesheetStatus.declined.value) {
      tempStageTimesheets = tempTimesheetList.filter(
        (item) => item?.declined === true
      );
    } else if (stageFilter === TypesOfTimesheetStatus.paid.value) {
      tempStageTimesheets = tempTimesheetList.filter(
        (item) => item?.paid === true
      );
    } else if (stageFilter === TypesOfTimesheetStatus.pending.value) {
      tempStageTimesheets = tempTimesheetList.filter(
        (item) => item?.pending === true
      );
    } else {
      tempStageTimesheets = [...tempTimesheetList];
    }

    setTimesheetList([...tempStageTimesheets]);
  };

  const handleStateOfTimesheet = (timesheet) => {
    if (timesheet?.paid) {
      return TIMESHEET_STATUS.PAID;
    } else if (timesheet?.pending) {
      return TIMESHEET_STATUS.PENDING;
    } else if (timesheet?.approved) {
      return TIMESHEET_STATUS.APPROVED;
    } else {
      return TIMESHEET_STATUS.DECLINED;
    }
  };

  const createOrUpdateXeroAccount = () => {
    XeroContactService.putEmployerContact(user?.id, user?.id, {
      name: setting?.name,
      firstName: setting?.firstName,
      lastName: setting?.lastName,
      emailAddress: setting?.email,
      contactNumber: user?.id,
      addresses: [
        {
          AddressType: "STREET",
          City: setting?.city,
          Region: setting?.region,
          Country: setting?.country
        },
        {
          AddressType: "POBOX",
          City: setting?.city,
          Region: setting?.region,
          Country: setting?.country
        }
      ],
      phones: [
        {
          PhoneType: "DEFAULT",
          PhoneNumber: setting?.workPhone,
          PhoneCountryCode: setting?.workPhoneCountryCode || "+64"
        },
        {
          PhoneType: "DDI",
          PhoneNumber: setting?.workPhone,
          PhoneCountryCode: setting?.workPhoneCountryCode || "+64"
        },
        {
          PhoneType: "MOBILE",
          PhoneNumber: setting?.workPhone,
          PhoneCountryCode: setting?.workPhoneCountryCode || "+64"
        },
        {
          PhoneType: "FAX",
          PhoneNumber: setting?.workPhone,
          PhoneCountryCode: setting?.workPhoneCountryCode || "+64"
        }
      ]
    })
      .catch((e) => dispatch(setErrorMess(e)));
  }

  const handleApproveTimesheet = async (timesheet) => {
    const timesheetApproveApis = [];

    await createOrUpdateXeroAccount();

    if (timesheet) {
      dispatch(setLoading(true));

      //bill in xero
      timesheetApproveApis.push(
        await XeroInvoiceService.updateEmployerInvoice(user?.id, {
          type: InvoiceTypes.ACCPAY,
          date: moment().format("YYYY-MM-DD"),
          dueDate:
            moment().add(1, "months").format("YYYY-MM-DD").slice(0, -2) + "25",
          contactNumber: timesheet?.contractor,
          status: "AUTHORISED",
          lineItems: [
            {
              Description: `w/e ${moment(timesheet?.weekEnding).format(
                "DD/MM/YYYY"
              )} | ${timesheet?.contractorFirstName} ${timesheet?.contractorLastName
                } - ${timesheet?.contractorTitle}`,
              Quantity: timesheet?.hours,
              UnitAmount: Number(timesheet?.rateOffered),
              AccountCode: AccountCode,
              TaxType: !timesheet?.contractorIsGSTRegister ? "NONE" : TaxType,
            },
          ],
        }).catch((e) => dispatch(setErrorMess(e)))
      );

      //invoice in xero
      timesheetApproveApis.push(
        await XeroInvoiceService.updateEmployerInvoice(user?.id, {
          type: InvoiceTypes.ACCREC,
          date: moment().format("YYYY-MM-DD"),
          dueDate:
            moment().add(7, "days").format("YYYY-MM-DD"),
          contactNumber: timesheet?.employer,
          status: "AUTHORISED",
          lineItems: [
            {
              Description: `w/e ${moment(timesheet?.weekEnding).format(
                "DD/MM/YYYY"
              )} | ${timesheet?.contractorFirstName} ${timesheet?.contractorLastName
                } - ${timesheet?.contractorTitle}`,
              Quantity: timesheet?.hours,
              UnitAmount: Number(timesheet?.rateOffered) + COMMISSION_RATE,
              AccountCode: AccountCode,
              TaxType: TaxType,
            },
          ],
        }).catch((e) => dispatch(setErrorMess(e)))
      );

      timesheetApproveApis.push(
        await EmployerTimesheetService.approveTimesheet(
          user?.id,
          timesheet?.id,
          {
            ...timesheet,
            pending: false,
            approved: true,
          }
        )
          .then((res) => {
            dispatch(getAllTimesheets(user?.id));
            dispatch(getAllApproverTimesheets(user?.id, encodeURIComponent(user?.email)));
            return res;
          })
          .catch((e) => dispatch(setErrorMess(e)))
          .finally(() => {
            setPopupHandle({ isOpen: false, timesheet: null });
          })
      );

      await Promise.all(timesheetApproveApis)
        .then(async (res) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [resACCPAY, resACCREC, resTimesheet] = res;

          await InvoiceService.createOneInvoice(user?.id, {
            timesheet: timesheet?.id,
            employer: timesheet?.employer,
            contractor: timesheet?.contractor,
            xeroInvoiceId: resACCREC?.Invoices[0]?.InvoiceID,
          }).catch((e) => dispatch(setErrorMess(e)));

          await BillService.createOneBill(user?.id, {
            timesheet: timesheet?.id,
            employer: timesheet?.employer,
            contractor: timesheet?.contractor,
            xeroBillId: resACCPAY?.Invoices[0]?.InvoiceID,
          }).catch((e) => dispatch(setErrorMess(e)));
        })
        .finally(() => dispatch(setLoading(false)));
    }
  };

  const handleDeclineTimesheet = (timesheet) => {
    if (timesheet) {
      dispatch(setLoading(true));
      EmployerTimesheetService.declineTimesheet(user?.id, timesheet?.id, {
        ...timesheet,
        pending: false,
        declined: true,
      })
        .then(() => {
          dispatch(getAllTimesheets(user?.id));
          dispatch(getAllApproverTimesheets(user?.id, encodeURIComponent(user?.email)));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => {
          setPopupHandle({ isOpen: false, timesheet: null });
          dispatch(setLoading(false));
        });
    }
  };

  const handleSearch = () => {
    dispatch(setLoading(true));
    EmployerTimesheetService.searchTimesheet(user?.id, valueOfSearchInput)
      .then((res) => {
        handleSetTimesheetList(res);
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleClickFilterDate = () => {
    setIncreasingFilterDate(!increasingFilterDate);
  };

  const handleClickFilterStage = (status) => {
    setStageFilter(status);
  };

  return (
    <Fragment>
      <div
        className={clsx("page-header header-filter", classes.pageHeader)}
        filter-color="yellow"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project19.jpg") + ")",
          }}
        />
        <div className={clsx("content mb-0", classes.content)}>
          <Container className={classes.container}>
            <p className={classes.title}>TIMESHEETS</p>
            <div className={classes.tableHeaderContainer}>
              <CustomInput
                endIcon={
                  <i
                    className={clsx("fa fa-search", classes.searchIcon)}
                    onClick={handleSearch}
                  />
                }
                placeholder="Search..."
                className={classes.customInput}
                onChange={(e) => {
                  setValueOfSearchInput(e.target.value);
                }}
              />
            </div>
            <div className={classes.tableContainer}>
              <Table responsive className={classes.table}>
                <thead>
                  <tr>
                    <th className="text-center">Name</th>
                    <th className={clsx("text-center", classes.hideOnMobile)}>
                      Title
                    </th>
                    <th>Hours</th>
                    <th>
                      <div
                        className={classes.tableTitle}
                        onClick={handleClickFilterDate}
                      >
                        End date
                        {increasingFilterDate ? (
                          <i className="now-ui-icons arrows-1_minimal-up" />
                        ) : (
                          <i className="now-ui-icons arrows-1_minimal-down" />
                        )}
                      </div>
                    </th>
                    <th className={classes.hideOnMobile}>
                      <div className={classes.tableTitle}>
                        <UncontrolledDropdown
                          nav
                          className={classes.uncontrolledDropdown}
                        >
                          <DropdownToggle
                            caret
                            color="default"
                            data-toggle="dropdown"
                            id="navbarDropdownMenuLink"
                            nav
                            onClick={(e) => e.preventDefault()}
                            className={classes.dropdownWrapper}
                          >
                            Stage
                          </DropdownToggle>
                          <DropdownMenu
                            aria-labelledby="navbarDropdownMenuLink"
                            className={classes.dropdownMenu}
                            container="body"
                          >
                            <DropdownItem
                              onClick={() => handleClickFilterStage(null)}
                            >
                              ALL STAGES
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleClickFilterStage(TIMESHEET_STATUS.PENDING)
                              }
                            >
                              Pending
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleClickFilterStage(
                                  TIMESHEET_STATUS.APPROVED
                                )
                              }
                            >
                              Approved
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleClickFilterStage(
                                  TIMESHEET_STATUS.DECLINED
                                )
                              }
                            >
                              Declined
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleClickFilterStage(TIMESHEET_STATUS.PAID)
                              }
                            >
                              Paid
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </th>
                    <th className={classes.hideOnMobile} />
                  </tr>
                </thead>

                <tbody className={classes.desktop}>
                  {timesheetList.map((item, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        {item?.contractorFirstName} {item?.contractorLastName}
                      </td>
                      <td className="text-center">{item?.contractorTitle}</td>
                      <td className="text-center">{item?.hours}</td>
                      <td className="text-center">
                        {moment(item?.weekEnding).format("DD/MM/YYYY")}
                      </td>
                      <td className={clsx("text-center", classes.upperCase)}>
                        {handleStateOfTimesheet(item) === TIMESHEET_STATUS.PAID && item?.typeOfTimesheet !== "approver" ? "Approved" : handleStateOfTimesheet(item)}
                      </td>
                      <td
                        className={clsx(
                          "text-right",
                          classes.uncontrolledDropdownWrapper
                        )}
                      >
                        <UncontrolledDropdown
                          nav
                          className={classes.uncontrolledDropdown}
                        >
                          <DropdownToggle
                            caret
                            color="default"
                            data-toggle="dropdown"
                            id="navbarDropdownMenuLink"
                            nav
                            onClick={(e) => e.preventDefault()}
                            className={classes.dropdownWrapper}
                          >
                            <p className={classes.dropdownContent}>More</p>
                          </DropdownToggle>
                          <DropdownMenu
                            aria-labelledby="navbarDropdownMenuLink"
                            className={classes.dropdownMenu}
                            container="body"
                          >
                            <DropdownItem
                              className={
                                handleStateOfTimesheet(item) ===
                                  TIMESHEET_STATUS.PAID ||
                                  handleStateOfTimesheet(item) ===
                                  TIMESHEET_STATUS.APPROVED ||
                                  handleStateOfTimesheet(item) ===
                                  TIMESHEET_STATUS.DECLINED
                                  ? "disabled"
                                  : ""
                              }
                              onClick={() => handleApproveTimesheet(item)}
                            >
                              Approve
                            </DropdownItem>
                            <DropdownItem
                              className={
                                handleStateOfTimesheet(item) ===
                                  TIMESHEET_STATUS.PAID ||
                                  handleStateOfTimesheet(item) ===
                                  TIMESHEET_STATUS.APPROVED ||
                                  handleStateOfTimesheet(item) ===
                                  TIMESHEET_STATUS.DECLINED
                                  ? "disabled"
                                  : ""
                              }
                              onClick={() => handleDeclineTimesheet(item)}
                            >
                              Decline
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>

                <tbody className={classes.mobile}>
                  {timesheetList.map((item, index) => (
                    <tr
                      key={index}
                      className={
                        item.approved
                          ? classes.approved
                          : item.declined
                            ? classes.declined
                            : classes.pending
                      }
                      onClick={() =>
                        item?.pending &&
                        setPopupHandle({ isOpen: true, timesheet: item })
                      }
                    >
                      <td className="text-center">
                        {item?.contractorFirstName} {item?.contractorLastName}
                      </td>
                      <td className="text-center">{item?.hours}</td>
                      <td className="text-center">
                        {moment(item?.weekEnding).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Container>
        </div>
        {/* <FooterTransparentEmployer /> */}
      </div>
      <PopupHandle
        timesheet={popupHandle?.timesheet}
        isOpen={popupHandle?.isOpen}
        onClose={() => setPopupHandle({ isOpen: false, timesheet: null })}
        handleApprove={handleApproveTimesheet}
        handleDecline={handleDeclineTimesheet}
      />
    </Fragment>
  );
});

export default Timesheet;
