import { Fragment, memo, useMemo, useState } from "react";
import { Button, Card, CardBody, Form } from "reactstrap";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { ReducerType } from "redux/reducers";
import { UtilCvParsing } from "utils/cvParsing";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import CustomInput from "components/Common/CustomInput";
import clsx from "clsx";

interface Props {}

const UploadCv = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);
  const [localError, setLocalError] = useState("");
  // console.log(profile)

  const schema = useMemo(() => {
    return yup.object().shape({
      cvUpload: yup.mixed().required("This field is required"),
      cvUploadFileBase64: yup.string(),
    });
  }, []);

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
 
  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.basicProfile));
  };
  
  const onSubmitCv = async (data) => {
    dispatch(setLoading(true));
    setLocalError(""); // Clear previous error message

    const parsingSuccess = await UtilCvParsing.cvParsing(dispatch, user, profile, data, false);

    if (parsingSuccess) {
      dispatch(push(routes.contractor.newMember.preference));
    } else {
      setLocalError("We couldn't parse your CV in time. Please try simplifying your CV or continue manually.");
    }

    dispatch(setLoading(false));
  };
  

  const handleFileInputChange = ({ target }) => {
    if (target?.files[0]) {
      const file = target.files[0];
      setValue("cvUpload", file, { shouldValidate: true });
  
      // Extract the file name
      setValue("cvFileName", file.name); // <-- Add this line to store the file name
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const temp = reader.result as string;
        const arr = temp.split(",");
        setValue("cvUploadFileBase64", arr[1]); // <-- This remains as is for base64 content
      };
    } else {
      setValue("cvUpload", null);
      setValue("cvUploadFileBase64", "");
      setValue("cvFileName", ""); // Reset the file name when no file is selected
    }
  };

  return (
    <Card className={classes.card}>
      <CardBody>
        <p className={classes.title}>Lets try and save you some time?</p>
        <div className={classes.disclaimerContainer}></div>
        <Fragment>
          <p className={classes.subTitle}>
            When a CV is uploaded, we try our best to create your profile. We
            do not store your CV but use it to fill out your Necta profile.
          </p>
          <Form onSubmit={handleSubmit(onSubmitCv)}>
            <div className={classes.cvContent}>
              <p className={classes.commonTitle}>Upload CV here:</p>
              <input
                className={classes.uploadFile}
                type="file"
                placeholder="Upload..."
                accept=".doc, .docx, .pdf"
                onChange={handleFileInputChange}
              />
              <span className="text-danger ml-3 mt-1 d-block text-left">
                {errors?.cvUpload?.message && errors?.cvUpload?.message}
              </span>
              {localError && (
              <span className="text-danger ml-3 mt-1 d-block text-left">
                {localError}
              </span>
            )}
            </div>
            <div className={classes.buttonContainer}>
              <Button color="default" type="button" onClick={handleBack}>
                No, Thank You
              </Button>
              <Button color="primary" type="submit">
                Yes, Please
              </Button>
            </div>
          </Form>
        </Fragment>
      </CardBody>
    </Card>
  );
});

export default UploadCv;
