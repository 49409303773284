import { memo, useState, useEffect } from "react";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { Input, InputProps, FormGroup, Tooltip } from "reactstrap";
import { FieldError, FieldErrors, Merge, UseFormRegisterReturn } from "react-hook-form";

interface Props extends InputProps{
  className?: string;
  placeholder?: string;
  autoComplete?: string;
  inputRef?: UseFormRegisterReturn<string>;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrors<any>>;
  tooltipText?: string;
  name?: string;
  isDisabled?: boolean;
  
}

const CustomTextarea = memo(
  ({ className, inputRef, errorMessage, tooltipText, name, isDisabled, ...props }: Props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const uniqueId = `CustomSelect-${name}`; // Unique ID for each select
    const { ref: refInput, ...inputProps } = inputRef || { ref: null }; 
    return (
      <FormGroup
        className={clsx(
          classes.root,
          { "has-danger": !!errorMessage },
          className
        )}
      >
        <Input
          {...inputProps}
          type="textarea"
          id={uniqueId} 
          className={clsx(classes.textarea, {
            "form-control-danger": !!errorMessage,
          })}
          innerRef={refInput}
          disabled={isDisabled}
          {...props}
        />
        {errorMessage && (
          <span className="text-danger ml-2 mt-1 d-block">{errorMessage}</span>
        
        )}
      {tooltipText && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={uniqueId} // Use the unique ID here
          toggle={toggleTooltip}
        >
          {tooltipText}
        </Tooltip>
      )}
      </FormGroup>
    );
  }
);

export default CustomTextarea;
