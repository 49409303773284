export const InvoiceTypes = {
    ACCREC: "ACCREC", //necta -> employer (invoices)
    ACCPAY: "ACCPAY" // contractor -> necta (bill)
};

export const TaxType = "OUTPUT2"; // 15% tax according to Xero

export const InvoiceStatus = {
    PAID: "PAID",
    AUTHORISED: "AUTHORISED",
    VOID: "VOIDED" 
}

export const AccountCode = "200";