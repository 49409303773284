import { memo, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Form } from "reactstrap";
import CustomInput from "components/Common/CustomInput";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import * as yup from "yup";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormGroup from "reactstrap/lib/FormGroup";
import CustomDatePicker from "components/Common/CustomDatePicker";
import CustomTextarea from "components/CustomTextarea";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { ExperienceService } from "services/Contractor/NewMember/Experience";
import CustomSelect from "components/Common/CustomSelect";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import CustomCheckbox from "components/Common/CustomCheckbox";
import clsx from "clsx";
import {
  ExperienceParams,
  ExperienceEmploymentTypes,
} from "models/Contractor/NewMember/Experience";
import { UtilContractor } from "utils/contractor";
import { ReducerType } from "redux/reducers";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";
import moment from "moment";
import SearchLocationInput from "components/Common/GoogleAddress";

interface Props {}

interface ExperienceFormData {
  experienceList: {
    id: number;
    title: string;
    company: string;
    city: string;
    country: string;
    address?: string;
    fromDate: string | Date;
    toDate: string | Date;
    endDateIsPresent: boolean;
    employmentType: {
      id: number;
      name: string;
      value: string;
    };
    summary: string;
    responsibility: string;
  }[];
}

// eslint-disable-next-line no-empty-pattern
const Experience = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const [deleteItems, setDeleteItem] = useState([]);

  const schema = useMemo(() => {
    return yup.object().shape({
      experienceList: yup.array().of(
        yup.object().shape({
          id: yup.number(),
          title: yup
            .string()
            .max(100, "Maximum 100 characters")
            .required("This field is required"),
          company: yup
            .string()
            .max(150, "Maximum 150 characters")
            .required("This field is required"),
          address: yup
            .string()
            .max(100, "Maximum 100 characters")
            .required("This field is required"),
          fromDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("This field is required"),
          endDateIsPresent: yup.boolean(),
          toDate: yup.date().when("endDateIsPresent", {
            is: true,
            then: yup.date().typeError("Please enter a valid date").nullable(),
            otherwise: yup.date().typeError("Please enter a valid date"),
          }),
          employmentType: yup.object().required("This field is required"),
          summary: yup
            .string()
            .nullable()
            .max(1000, "Maximum 1000 characters")
            .required("This field is required"),
          responsibility: yup
            .string()
            .nullable()
            .max(3000, "Maximum 3000 characters")
            .required("This field is required"),
        })
      ),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    control,
    reset,
  } = useForm<ExperienceFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "experienceList",
    keyName: "fieldID",
  });

  useEffect(() => {
    append({});
  }, [append]);

  useEffect(() => {
    if (!!profile?.experience?.length) {
      reset({
        experienceList: profile.experience.map((item: ExperienceParams) => ({
          id: item?.id,
          title: item?.title,
          company: item?.company,
          address:
            item.city && item.country ? `${item.city}, ${item.country}` : "",
          fromDate: new Date(item?.startDate),
          endDateIsPresent: item?.endDateIsPresent,
          toDate: new Date(item?.endDate),
          employmentType: UtilContractor.getCurrentEmploymentType(
            item?.employmentType
          ),
          summary: item?.summary,
          responsibility: item?.responsibilities,
        })),
      });
    }
  }, [profile, reset]);

  const handleChangeEndDateIsPresent = (index) => {
    const endDateIsPresent = getValues(
      `experienceList.${index}.endDateIsPresent`
    );
    setValue(`experienceList.${index}.endDateIsPresent`, !endDateIsPresent);
    setValue(`experienceList.${index}.toDate`, null);
  };

  const handleDeleteItem = (id: number, index: number) => {
    remove(index);
    if (id) {
      setDeleteItem([...deleteItems, id]);
    }
  };

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.education));
  };

  const onSubmit = async (data: ExperienceFormData) => {
    dispatch(setLoading(true));
    const experienceParams = data?.experienceList?.map((item) => {
      return {
        id: item?.id,
        title: item?.title,
        company: item?.company,
        city: item?.city,
        country: item?.country,
        startDate: item?.fromDate,
        endDate: item?.toDate,
        employmentType: item?.employmentType?.value,
        endDateIsPresent: item?.endDateIsPresent,
        summary: item?.summary,
        responsibilities: item?.responsibility,
      };
    });
    if (deleteItems?.length > 0) {
      await ExperienceService.deleteExperience({
        ids: deleteItems,
      }).catch((e) => dispatch(setErrorMess(e)));
    }

    if (profile?.experience?.length) {
      await ExperienceService.putExperience(experienceParams)
        .then((res) => {
          dispatch(setProfileReducer({ ...profile, experience: res }));
          dispatch(push(routes.contractor.newMember.skill));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    } else {
      await ExperienceService.postExperience(experienceParams)
        .then((res) => {
          dispatch(setProfileReducer({ ...profile, experience: res }));
          dispatch(push(routes.contractor.newMember.skill));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    }
  };

  return (
    <Card className={classes.card}>
      <CardBody className={classes.cardBody}>
        <p className={classes.title}>Experience</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.content}>
            {fields?.map((field: any, index) => {
              return (
                <div key={field?.fieldID}>
                  {fields?.length > 1 && (
                    <div className={classes.btnDelete}>
                      <Button
                        onClick={() => handleDeleteItem(field?.id, index)}
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    </div>
                  )}
                  <FormGroup className={classes.section}>
                    <CustomInput
                      placeholder="Your Title"
                      type="text"
                      autoComplete="off"
                      inputRef={register(`experienceList.${index}.title`)}
                      errorMessage={
                        errors.experienceList &&
                        errors.experienceList[index]?.title?.message
                      }
                    />
                  </FormGroup>
                  <FormGroup className={classes.section}>
                    <CustomInput
                      placeholder="Organization Name"
                      type="text"
                      autoComplete="off"
                      inputRef={register(`experienceList.${index}.company`)}
                      errorMessage={
                        errors.experienceList &&
                        errors.experienceList[index]?.company?.message
                      }
                    />
                  </FormGroup>
                  <FormGroup className={classes.section}>
                    <Controller
                      name={`experienceList.${index}.address`}
                      control={control}
                      render={({ field }) => (
                        <SearchLocationInput
                          {...field}
                          ref={null}
                          placeholder="Location"
                          errorMessage={
                            errors.experienceList &&
                            errors.experienceList[index]?.address?.message
                          }
                          value={field.value}
                          onChange={(value: any) => {
                            setValue(
                              `experienceList.${index}.city`,
                              value?.address?.city
                            );
                            setValue(
                              `experienceList.${index}.country`,
                              value?.address?.country
                            );
                            return field.onChange(
                              value?.address?.formattedAddress
                            );
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                  <div className={classes.dateContainer}>
                    <CustomDatePicker
                      name={`experienceList.${index}.fromDate`}
                      control={control}
                      placeholder="From..."
                      dateFormat="dd/MM/yyyy"
                      maxDate={
                        watch(`experienceList.${index}.endDateIsPresent`)
                          ? moment().toDate()
                          : watch(`experienceList.${index}.toDate`)
                      }
                      errorMessage={
                        errors.experienceList &&
                        errors.experienceList[index]?.fromDate?.message
                      }
                    />
                    <CustomCheckbox
                      content="I am currently in this engagement"
                      inputRef={register(
                        `experienceList.${index}.endDateIsPresent`
                      )}
                      errorMessage={
                        errors.experienceList &&
                        errors.experienceList[index]?.endDateIsPresent?.message
                      }
                      className={clsx("my-2", classes.customCheckbox)}
                      onChange={() => handleChangeEndDateIsPresent(index)}
                    />
                    <CustomDatePicker
                      className={
                        watch(`experienceList.${index}.endDateIsPresent`)
                          ? classes.disabled
                          : ""
                      }
                      readOnly={watch(
                        `experienceList.${index}.endDateIsPresent`
                      )}
                      name={`experienceList.${index}.toDate`}
                      control={control}
                      placeholder="To..."
                      dateFormat="dd/MM/yyyy"
                      minDate={watch(`experienceList.${index}.fromDate`)}
                      errorMessage={
                        errors.experienceList &&
                        errors.experienceList[index]?.toDate?.message
                      }
                    />
                  </div>
                  <FormGroup className={classes.section}>
                    <CustomSelect
                      placeholder="Employment type.."
                      name={`experienceList.${index}.employmentType`}
                      control={control}
                      options={ExperienceEmploymentTypes}
                      errorMessage={
                        errors.experienceList &&
                        errors.experienceList[index]?.employmentType?.message
                      }
                    />
                  </FormGroup>
                  <FormGroup className={classes.section}>
                    <CustomTextarea
                      className={classes.textarea}
                      placeholder="Summary"
                      autoComplete="off"
                      inputRef={register(`experienceList.${index}.summary`)}
                      errorMessage={
                        errors.experienceList &&
                        errors.experienceList[index]?.summary?.message
                      }
                    />
                  </FormGroup>
                  <FormGroup className={classes.section}>
                    <CustomTextarea
                      className={classes.textarea}
                      placeholder="Responsibilities"
                      autoComplete="off"
                      inputRef={register(
                        `experienceList.${index}.responsibility`
                      )}
                      errorMessage={
                        errors.experienceList &&
                        errors.experienceList[index]?.responsibility?.message
                      }
                    />
                  </FormGroup>
                  {index !== fields.length - 1 && (
                    <hr className={classes.divider} />
                  )}
                </div>
              );
            })}
          </div>
          <div className={classes.buttonContainer}>
            <Button color="default" type="button" onClick={handleBack}>
              Back
            </Button>
            <Button
              type="button"
              className={classes.btnAddMore}
              onClick={() => append({})}
            >
              <span className="text-dark">
                Add more
                <i className="fa fa-plus ml-2" />
              </span>
            </Button>
            <Button color="primary" type="submit">
              Next
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
});

export default Experience;
