import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Constants from "../../Constants";
import authFactory from "services/authService/AuthFactory";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import routes from "routers/routes";
import { setUserReducer } from "redux/reducers/User/actionTypes";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { UserService } from "services/User";

const AuthCallback = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      await authFactory
        .generate()
        .loginWithCode(location.search, Constants.AuthProviders.GOOGLE)
        .then(() => {
          dispatch(setLoading(true));
          UserService.getUser()
            .then((res) => {
              dispatch(setUserReducer(res));
              if (res?.profileCompleted) {
                dispatch(push(routes.contractor.profile));
              } else {
                dispatch(push(routes.contractor.newMember.root));
              }
            })
            .catch((e) => dispatch(setErrorMess(e)))
            .finally(() => dispatch(setLoading(false)));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <div className="page-header header-filter" filter-color="yellow">
      <div
        className="page-header-image"
        style={{
          backgroundImage: "url(" + require("assets/img/login.jpg") + ")",
        }}
      />
    </div>
  );
};

export default AuthCallback;
