import { faFacebook, faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Collapse, Input, FormGroup, Label } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import classes from './styles.module.scss';
import { API } from 'aws-amplify';
import { Remarkable } from 'remarkable';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const platforms = [
  { name: 'Facebook', id: 'facebook', icon: faFacebook as IconProp },
  { name: 'Instagram', id: 'instagram', icon: faInstagram as IconProp },
  { name: 'Twitter', id: 'twitter', icon: faTwitter as IconProp },
  { name: 'LinkedIn', id: 'linkedin', icon: faLinkedin as IconProp },
];

const OutreachForm = ({ control, errors, watch, setValue, itemEdit, register, data }) => {
  const [selectedPlatforms, setSelectedPlatforms] = useState(platforms.map((p) => p.id));
  const [contentVisibility, setContentVisibility] = useState({});
  const [generatedContent, setGeneratedContent] = useState({});
  const [isContentGenerated, setIsContentGenerated] = useState({});
  const [loadingState, setLoadingState] = useState({}); // Track loading state for each platform

  useEffect(() => {
    if (data?.outreach?.length) {
      const existingContent = {};
      const contentGenerated = {};  // Initialize generated state
      data.outreach.forEach((platformData) => {
        const { name, content } = platformData;
        existingContent[name.toLowerCase()] = content;
        contentGenerated[name.toLowerCase()] = !!content; // true if content exists
      });
      setGeneratedContent(existingContent);
      setIsContentGenerated(contentGenerated);  // Set the generated content state
    }
  
    platforms.forEach((platform) => {
      const platformId = platform.id;
      if (data?.outreach?.some((o) => o.name.toLowerCase() === platformId)) {
        const content = data.outreach.find((o) => o.name.toLowerCase() === platformId).content;
        setValue(`${platformId}_content`, content);
      } else {
        setValue(`${platformId}_content`, '');
      }
    });
  }, [data, setValue]);

  const toggleContentVisibility = async (platformId) => {
    // console.log('Content visibility toggled for platform:', platformId);
    // console.log('Current visibility:', contentVisibility[platformId]);
  
    if (!contentVisibility[platformId]) {
      // console.log('Content not visible, checking if already generated...');
      if (!isContentGenerated[platformId]) {
        // console.log('Content not generated yet, calling API...');
        await callApi(platformId);
      }
    }
  
    setContentVisibility((prev) => ({
      ...prev,
      [platformId]: !prev[platformId],
    }));
  };
  

  const generatePrompt = (platform, jobTitle, jobDescription, jobResponsibilities, company) => {
    if (platform !== 'Email') {
      return `As a hiring manager, generate a social media post for ${platform} about the job title: ${jobTitle}, job description: ${jobDescription}, and responsibilities: ${jobResponsibilities} at ${company}. DO NOT GIVE ANY LINKS TO APPLY or any placeholders for links.`;
    }
    return `Write three sentences from ${company}, explaining that their application for the role of ${jobTitle} has been unsuccessful.`;
  };

  const callApi = async (platformId) => {
    setLoadingState((prev) => ({ ...prev, [platformId]: true })); // Set loading state to true

    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/openai';
    const jobTitle = watch('title');
    const jobDescription = watch('description');
    const jobResponsibilities = watch('responsibilities');
    const companyName = watch('company');

    const prompt = generatePrompt(
      platforms.find((p) => p.id === platformId)?.name,
      jobTitle,
      jobDescription,
      jobResponsibilities,
      companyName
    );

    const myInit = {
      response: true,
      body: { prompt, tokens: 600 },
    };

    try {
      const response = await API.post(apiName, path, myInit);
      const responseBody = response.data;
      const plainText = new Remarkable().render(responseBody.response).replace(/(<([^>]+)>)/gi, '');

      setGeneratedContent((prevContent) => ({
        ...prevContent,
        [platformId]: plainText,
      }));

      setIsContentGenerated((prevGenerated) => ({
        ...prevGenerated,
        [platformId]: true,
      }));

      setValue(`${platformId}_content`, plainText);
    } catch (error) {
      console.error('Failed to call the API', error);
    } finally {
      setLoadingState((prev) => ({ ...prev, [platformId]: false })); // Set loading state to false
    }
  };

  const handlePlatformChange = (platformId) => {
    setSelectedPlatforms((prev) =>
      prev.includes(platformId) ? prev.filter((id) => id !== platformId) : [...prev, platformId]
    );
  };

  const handleInputChange = (platformId, newValue) => {
    setGeneratedContent((prevContent) => ({
      ...prevContent,
      [platformId]: newValue,
    }));
    setValue(`${platformId}_content`, newValue);
  };

  return (
    <Row className={classes.outreachContainer}>
      <p>
        Necta will automatically generate social media posts and emails on your behalf; if you want to have your own flavor, edit on this page. Social media is published on NECTA accounts.
      </p>
      {platforms.map((platform) => (
        <Col md={12} key={platform.id} className={classes.platformRow}>
          <div className={classes.platformItem}>
            <FormGroup check inline className={classes.platformCheckbox}>
              <Label check className={classes.platformLabel}>
                <Input
                  type="checkbox"
                  checked={selectedPlatforms.includes(platform.id)}
                  onChange={() => handlePlatformChange(platform.id)}
                  className={classes.platformCheckboxInput}
                />
                <FontAwesomeIcon icon={platform.icon} className={classes.platformIcon} />
                {platform.name}
              </Label>
              <Button
                color="link"
                onClick={() => toggleContentVisibility(platform.id)}
                className={classes.toggleButton}
                disabled={loadingState[platform.id]} // Disable button while loading
              >
                {loadingState[platform.id]
                  ? 'Generating...' // Show generating state
                  : contentVisibility[platform.id]
                  ? 'Hide Content' // Show hide content if visible
                  : 'View Content'}
              </Button>
            </FormGroup>
          </div>
          <Collapse isOpen={contentVisibility[platform.id]}>
            <Controller
              name={`${platform.id}_content`}
              control={control}
              defaultValue={generatedContent[platform.id] || ''}
              render={({ field: { onChange, value } }) => (
                <FormGroup className={classes.contentArea}>
                  <Label className={classes.contentLabel}>
                    {platform.id !== 'email'
                      ? `Post Content for ${platform.name}`
                      : 'Rejection Email Content'}
                  </Label>
                  <Input
                    type="textarea"
                    value={generatedContent[platform.id] || value}
                    onChange={(e) => handleInputChange(platform.id, e.target.value)}
                    placeholder={
                      platform.id !== 'email'
                        ? `Generated ${platform.name} post content will appear here...`
                        : 'Generated rejection email will appear here...'
                    }
                    rows={platform.id !== 'email' ? '8' : '10'}
                    className={classes.textArea}
                  />
                </FormGroup>
              )}
            />
          </Collapse>
        </Col>
      ))}
    </Row>
  );
};

export default OutreachForm;
