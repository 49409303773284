import { IVisaTypes, VisaTypes } from "models/Contractor/NewMember/MojCheck";

export const convertVisaType = (value: IVisaTypes) => {
  switch (value) {
    case IVisaTypes.NZ_AUS_CITIZEN:
      return VisaTypes[0];
    case IVisaTypes.NZ_PERMANENT_RESIDENT:
      return VisaTypes[1];
    case IVisaTypes.NZ_WORK_VISA:
      return VisaTypes[2];
    case IVisaTypes.OTHER:
      return VisaTypes[3];
    default:
      return null;
  }
};
