import { memo, useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SliderBar from "../SliderBar";
import getCroppedImg, { readFile } from "./cropImage";

import classes from "./styles.module.scss";

interface Props {
  image: any;
  isOpen?: boolean;
  onConfirm: (file: any, base64File: any) => void;
  onClose: () => void;
  shape?: "rect" | "round";
  aspect?: number;
}

const ImageCropper = memo(
  ({
    aspect = 1,
    image,
    onClose,
    onConfirm,
    isOpen = false,
    shape = "rect",
  }: Props) => {
    const [imageSrc, setImageSrc] = useState("");
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    });

    useEffect(() => {
      if (image) {
        const imageSrc = readFile(image);
        setImageSrc(imageSrc);
      }
    }, [image]);

    useEffect(() => {
      if (!isOpen) {
        setZoom(1);
        setImageSrc("");
        setCrop({ x: 0, y: 0 });
      }
    }, [isOpen]);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleConfirm = async () => {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      onConfirm && onConfirm(croppedImage.file, croppedImage.base64File);
    };

    const handleChangeSlider = (value) => {
      setZoom(value);
    };

    const handleClose = () => {
      onClose();
    };

    return (
      <Modal isOpen={isOpen} className={classes.modal} centered={true}>
        <ModalHeader tag="p" className={classes.modalTitle}>
          Image Cropper
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          <div className={classes.imageCropperContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape={shape}
            />
          </div>
          <SliderBar
            id="image-cropper-slider"
            start={1}
            min={1}
            max={3}
            step={0.01}
            value={zoom}
            setValueInput={handleChangeSlider}
            className={classes.slider}
          />
        </ModalBody>
        <ModalFooter className={classes.modalFooter}>
          <Button className={classes.btn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className={classes.btn}
            color="primary"
            onClick={handleConfirm}
          >
            Crop
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);

export default ImageCropper;
