import { memo, useEffect, useState } from "react";
import clsx from "clsx";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import classes from "./styles.module.scss";
import EditExperience from "./EditExperience";
import { ExperienceService } from "services/Contractor/NewMember/Experience";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { setProfileReducer, getProfile } from "redux/reducers/Contractor/actionTypes";
import { ReducerType } from "redux/reducers";

// eslint-disable-next-line no-empty-pattern
const MyExperience = memo(() => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const [data, setData] = useState([]);
  const [iconTabs, setIconTabs] = useState([]);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [currentExperience, setCurrentExperience] = useState(null);

  useEffect(() => {
    if (!!profile?.experience?.length) {
      setData([...profile.experience]?.sort(sortOrder).reverse());
    }
  }, [profile]);

  useEffect(() => {
    let arr = [];
    data.map((_, index) => {
      arr.push(`${index} Main`);
      return null;
    });
    setIconTabs(arr);
  }, [data]);

  const formatStartDate = (date) => {
    const newDate = new Date(date);
    const month = newDate.toLocaleString("en-US", { month: "long" });
    const year = newDate.getFullYear();
    return `${month} ${year}`;
  };

  const datediff = (startDate, endDate, endDateIsPresent) => {
    const newStartDate = new Date(startDate);
    let newEndDate;
    if (endDateIsPresent) {
      newEndDate = new Date();
    } else {
      newEndDate = new Date(endDate);
    }
    const totalNumberOfMonths =
      newEndDate.getMonth() -
      newStartDate.getMonth() +
      12 * (newEndDate.getFullYear() - newStartDate.getFullYear());

    const numberOfMonths = totalNumberOfMonths % 12;
    const numberOfYears = Math.floor(totalNumberOfMonths / 12);

    if (numberOfYears === 0) {
      if (numberOfMonths <= 1) return `${numberOfMonths} month`;
      else return `${numberOfMonths} months`;
    } else if (numberOfYears === 1) {
      if (numberOfMonths === 0) return `1 year`;
      else if (numberOfMonths === 1) return `1 year 1 month`;
      else return `1 year ${numberOfMonths} months`;
    } else {
      if (numberOfMonths === 0) return `${numberOfYears} years`;
      else if (numberOfMonths === 1) return `${numberOfYears} years 1 month`;
      else return `${numberOfYears} years ${numberOfMonths} months`;
    }
  };

  const handleUpdateExperience = async (dataSubmit, removeList, handleResetForm) => {
    dispatch(setLoading(true));
  
    try {
      if (!profile?.experience?.length) {
        await ExperienceService.postExperience(dataSubmit);
      } else {
        if (removeList.length > 0) {
          await Promise.all([
            ExperienceService.putExperience(dataSubmit),
            ExperienceService.deleteExperience({ ids: removeList }),
          ]);
        } else {
          await ExperienceService.putExperience(dataSubmit);
        }
      }
  
      // Refresh profile after the update to ensure all experiences are displayed
      await dispatch(getProfile());
  
      // Optionally reset the form or close the modal
      handleResetForm && handleResetForm();
      setIsOpenEdit(false);  // Close the modal after saving
    } catch (e) {
      dispatch(setErrorMess(e));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
  if (!!profile?.experience?.length) {
    setData([...profile.experience]?.sort(sortOrder).reverse());
  }
}, [profile]);

  const sortOrder = (first, second) => {
    if (first?.endDateIsPresent && second?.endDateIsPresent) {
      if (first?.startDate > second?.startDate) return 1;
      if (first?.startDate < second?.startDate) return -1;
      return 0;
    }
    else if (first?.endDateIsPresent) {
      return 1;
    }
    else if (second?.endDateIsPresent) {
      return -1;
    }
    else {
      if (first?.endDate > second?.endDate) return 1;
      if (first?.endDate < second?.endDate) return -1;
      return 0;
    }
  };

  const handleToggle = () => setIsOpenEdit(!isOpenEdit);

  return (
    <>
      <div className="section">
        <Container className="position-relative">
          <Button
            className={clsx(
              "btn-icon btn-round",
              classes.editBtnDefault,
              classes.editBtn
            )}
            onClick={() => {
              setCurrentExperience(null); // For adding a new experience
              handleToggle();
            }}
          >
            <i className="fas fa-plus" />
          </Button>
          <EditExperience
            onClose={handleToggle}
            isOpen={isOpenEdit}
            data={currentExperience ? [currentExperience] : []} // Pass the specific experience
            handleUpdateExperience={handleUpdateExperience}
          />
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="6">
              <h2 className="title text-center pt-0 pb-3">My Experience</h2>
            </Col>
          </Row>
          <Row>
            {data?.map((item, index) => {
              return (
                <Col className="ml-auto mr-auto" md="10" xl="6" key={index}>
                  <Card className="p-4">
                    <h3 className="category mb-4">{item.title}</h3>
                    <Button
                      className={clsx(
                        "btn-icon btn-round btn-xs",
                        classes.editBtnDefault,
                        classes.editBtn
                      )}
                      onClick={() => {
                        setCurrentExperience(item); // Set the specific item to be edited
                        handleToggle();
                      }}
                    >
                      <i className="fas fa-edit" />
                    </Button>
                    <CardHeader>
                      <Nav
                        className={clsx(
                          "nav-pills-primary justify-content-center",
                          classes.navExItems
                        )}
                        pills
                      >
                        <NavItem className={classes.navExItem}>
                          <NavLink
                            className={
                              iconTabs[index] === `${index} Main`
                                ? "active"
                                : ""
                            }
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              const result = [...iconTabs];
                              result[index] = `${index} Main`;
                              setIconTabs(result);
                            }}
                          >
                            Main
                          </NavLink>
                        </NavItem>
                        <NavItem className={classes.navExItem}>
                          <NavLink
                            className={
                              iconTabs[index] === `${index} Summary`
                                ? "active"
                                : ""
                            }
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              const result = [...iconTabs];
                              result[index] = `${index} Summary`;
                              setIconTabs(result);
                            }}
                          >
                            Summary
                          </NavLink>
                        </NavItem>
                        <NavItem className={classes.navExItem}>
                          <NavLink
                            className={
                              iconTabs[index] === `${index} Responsibilities`
                                ? "active"
                                : ""
                            }
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              const result = [...iconTabs];
                              result[index] = `${index} Responsibilities`;
                              setIconTabs(result);
                            }}
                          >
                            Responsibilities
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </CardHeader>
                    <CardBody>
                      <TabContent
                        className="text-left"
                        activeTab={iconTabs[index]}
                      >
                        <TabPane
                          className={classes.main}
                          tabId={`${index} Main`}
                        >
                          <ul>
                            <li>{item?.company}</li>
                            <li>
                              {formatStartDate(item?.startDate)} until{" "}
                              {item?.endDateIsPresent
                                ? "present"
                                : formatStartDate(item?.endDate)}
                            </li>
                            <li>{item?.country}</li>
                            <li>
                              {datediff(
                                item?.startDate,
                                item?.endDate,
                                item?.endDateIsPresent
                              )}
                            </li>
                          </ul>
                        </TabPane>
                        <TabPane
                          className={classes?.summary}
                          tabId={`${index} Summary`}
                        >
                          <p>{item?.summary}</p>
                        </TabPane>
                        <TabPane
                          className={classes.responsibility}
                          tabId={`${index} Responsibilities`}
                        >
                          <p>{item?.responsibilities}</p>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
});

export default MyExperience;
