import { API, Storage } from "aws-amplify";

export class InvoiceService {
  static async getAllManualInvoice(contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/manualinvoices`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getManualInvoice(contractorId, manualInvoiceId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractor/${contractorId}/manualinvoices/${manualInvoiceId}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async createManualInvoice(contractorId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/manualinvoices`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async updateManualInvoice(contractorId, manualInvoiceId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractor/${contractorId}/manualinvoices/${manualInvoiceId}`;
    const myInit = {
      response: true,
      body: data
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
  static async deleteManualInvoice(contractorId, manualInvoiceId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractor/${contractorId}/manualinvoices/${manualInvoiceId}`;
    const myInit = {
      response: true,
    };
    return API.del(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
