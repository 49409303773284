import { API } from "aws-amplify";

export class ContractorExpenseService {
  static async getExpenses(): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractorexpenses`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postExpense(data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractorexpense`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putExpense(expenseId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractorexpense/${expenseId}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getExpensesVendors(id): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/vendors`;
    const myInit = {
      response: true
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postExpensesVendors(id, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/vendors`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putExpensesVendors(id, vendorId ,data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/vendors/${vendorId}`;
    const myInit = {
      response: true,
      body: data
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
