import { memo, useEffect, useMemo, useState } from "react";
import { Form, Button, Card, CardBody, Modal } from "reactstrap";
import classes from "./styles.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/Common/CustomInput";
import CustomSelect from "components/Common/CustomSelect";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "components/Common/CustomDatePicker";
import clsx from "clsx";
import CustomSwitch from "components/Common/CustomSwitch";
import { ReducerType } from "redux/reducers";
import { JobOfferService } from "services/Contractor/Offer/Offer";
import { OrganizationService } from "services/Employer/NewMember/Organization";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { JobOffers, Jobs } from "redux/reducers/Contractor";
import { ContractorExpenseService } from "services/Contractor/Timesheet/Expense";
import { ExpenseTypes } from "models/Contractor/NewMember/Expense"
interface Props {
  onClose: () => void;
  isOpen: boolean;
  handleAddExpense: (data, name) => void;
}

const AddNewExpense = memo(({ onClose, isOpen, handleAddExpense }: Props) => {
  const dispatch = useDispatch();
  const { allOffers, allExpenses } = useSelector(
    (state: ReducerType) => state.contractor
  );
  const { user } = useSelector((state: ReducerType) => state.user);
  const [isInclusiveGST, setIsInclusiveGST] = useState(false);
  const [isNeedGST, setIsNeedGST] = useState(false);
  const [clientList, setClientList] = useState([]);

  const schema = useMemo(() => {
    return yup.object().shape({
      vendor: yup.object().nullable().required("This field is required"),
      type: yup.object().nullable().required("This field is required"),
      dateOfExpense: yup
        .date()
        .typeError("Please enter a valid date")
        .required("This field is required"),
      amount: yup
        .number()
        .typeError("Please enter a valid number")
        .required("This field is required"),
      status: yup.boolean(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  });

  const watchStatus = watch("status");

  useEffect(() => {
    setValue("status", true);
    ContractorExpenseService.getExpensesVendors(user?.id)
      .then((res) => setClientList(res))
  }, [dispatch, isOpen])

  const handleCreate = (inputValue) => {
    const checkCreated = clientList?.findIndex((item) => item?.id === -1)
    let test = [];
    if (checkCreated > -1) {
      test = clientList.splice(checkCreated, 1);
    }

    const newOption = {
      id: -1,
      name: inputValue
    }
    setClientList((prev) => [...prev, newOption]);
    setValue("vendor", newOption);
  };

  // const handlePrepareData = async () => {
  //   dispatch(setLoading(true));
  //   const allAcceptedJobs = allOffers?.jobOffers.filter(
  //     (item) => item?.accepted
  //   );
  //   const allJobOfferIdExpensed = allExpenses.map((item) => item?.jobOffer);
  //   const employerIdList = allAcceptedJobs.map((item) => item?.employer);

  //   // get employer information based on employer ID list
  //   const employerList = [];
  //   await Promise.all(
  //     employerIdList.map(
  //       async (item) =>
  //         await JobOfferService.getEmployerProfile(item)
  //           .then(async (profile) => {
  //             await OrganizationService.getEmployerOrganization(item)
  //               .then((organization) => {
  //                 employerList.push({ profile, organization });
  //               })
  //               .catch((e) => dispatch(setErrorMess(e)));
  //           })
  //           .catch((e) => dispatch(setErrorMess(e)))
  //     )
  //   );

  //   const result = [];
  //   const allAcceptedJobsWithoutExpense = allAcceptedJobs.filter(
  //     (item) => !allJobOfferIdExpensed.includes(item?.id)
  //   );
  //   allAcceptedJobsWithoutExpense.forEach((offer: JobOffers) => {
  //     const job = allOffers.jobs?.find((job: Jobs) => job.id === offer.job);
  //     const client = employerList.find(
  //       (employer) => employer.profile?.employerId === offer.employer
  //     );
  //     result.push({
  //       ...offer,
  //       name: `${job?.title || ""} - ${client?.organization?.name || ""}`,
  //     });
  //   });
  //   setClientList(result);
  //   dispatch(setLoading(false));
  // };

  // useEffect(() => {
  //   if (!isOpen) {
  //     reset({
  //       vendor: null,
  //       dateOfExpense: null,
  //       amount: null,
  //       status: false,
  //     });
  //     setIsInclusiveGST(false);
  //   } else {
  //     handlePrepareData();
  //   }
  // }, [dispatch, isOpen]);

  const onSubmit = async (data) => {
    let net = 0;
    let total = 0;
    if (!isNeedGST) {
      net = data?.amount;
      total = data?.amount;
    }
    else if (isInclusiveGST) {
      net = data?.amount * 20 / 23; // /1.15
      total = data?.amount;
    } else {
      net = data?.amount;
      total = net + net * 15 / 100; // *1.15
    }
    
    //Create new vendor case
    if (data?.vendor?.id === -1) {
      await ContractorExpenseService.postExpensesVendors(user?.id, {
        name: data?.vendor?.name
      })
        .then((result) => {
          handleAddExpense({
            vendor: result?.id,
            dateOfExpense: data?.dateOfExpense,
            isPaid: data?.status,
            net: net,
            gst: isNeedGST ? 15 : 0,
            total: total,
            type: data?.type.value
          }, data?.vendor?.name);
        })
    }
    else {
      handleAddExpense({
        vendor: data?.vendor?.id,
        dateOfExpense: data?.dateOfExpense,
        isPaid: data?.status,
        net: net,
        gst: isNeedGST ? 15 : 0,
        total: total,
        type: data?.type.value
      }, data?.vendor?.name);
    }

    reset();

    onClose();

  };

  return (
    <Card className={classes.card}>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        centered
        scrollable
        className={classes.modal}
      >
        <CardBody className={classes.cardBody}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p className={classes.title}>Add New Expenses</p>
            <div className={classes.content}>
              <CustomCreatableSelect
                placeholder="Select or create a vendor"
                name="vendor"
                control={control}
                options={clientList}
                onCreateOption={handleCreate}
                errorMessage={errors.vendor?.message}
              />
              <CustomSelect
                placeholder="Type of expense"
                name="type"
                control={control}
                options={ExpenseTypes}
                errorMessage={errors.type?.message}
              />
              <CustomDatePicker
                name="dateOfExpense"
                control={control}
                placeholder="Date of expense"
                dateFormat="dd/MM/yyyy"
                errorMessage={errors.dateOfExpense?.message}
              />
              <CustomInput
                placeholder="Total Amount"
                type="text"
                autoComplete="off"
                inputRef={register("amount")}
                errorMessage={errors.amount?.message}
              />
              <div className={classes.customSwitchWrapper}>
                <p>Does this Expense Require GST?</p>
                <CustomSwitch
                  value={isNeedGST}
                  onChange={() => {
                    setIsNeedGST(!isNeedGST);
                  }}
                  onText="YES"
                  offText="NO"
                  className={clsx(classes.customCheckbox, "my-2")}
                />
              </div>
              {isNeedGST &&
                (<div className={classes.customSwitchWrapper}>
                  <p>Is the total amount inclusive of GST?</p>
                  <CustomSwitch
                    value={isInclusiveGST}
                    onChange={() => {
                      setIsInclusiveGST(!isInclusiveGST);
                    }}
                    onText="YES"
                    offText="NO"
                    className={clsx(classes.customCheckbox, "my-2")}
                  />
                </div>)
              }
              <div className={classes.customSwitchWrapper}>
                <p>Have you paid yet?</p>
                <CustomSwitch
                  value={watchStatus}
                  onChange={() => {
                    setValue("status", !watchStatus);
                  }}
                  onText="YES"
                  offText="NO"
                  className={clsx(classes.customCheckbox, "my-2")}
                />
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                size="md"
                color="default"
                type="button"
                className={classes.btn}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                size="md"
                color="primary"
                type="submit"
                className={classes.btn}
              >
                Ok
              </Button>
            </div>
          </Form>
        </CardBody>
      </Modal>
    </Card>
  );
});

export default AddNewExpense;
