import { memo, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import classes from './styles.module.scss';
import './modal.css';
import { Button, Card, CardBody, Spinner, Form } from 'reactstrap';

export interface IBaseModalProps {
  show: boolean;
  title: string;
  children: string | ReactNode;
  footer?: string | ReactNode;
  closeOnTap?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  size?: 'card' | 'large';
  submitButton?: string;
  cancelButton?: string;
  loading?: boolean;
}

const BaseModal = memo((props: IBaseModalProps) => {
  const { title, footer, onClose, children, onSubmit, show, size = 'default', loading } = props;
  const root = document.getElementById('root');

  if (!root) throw new Error('Root node not found. Can`t render modal.');

  const handleCancelButtonClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleOkButtonClick = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return createPortal(
    <div
      className={classnames("Modal", { "Modal-show": show })} // This is the backdrop
    >
      <div
        id='modal-content'
        className={classnames({
          Modal: true,
          'Modal-show': props.show,
        })}
        onClick={(e) => e.stopPropagation()} 
      >
        <Card className={`${classes.card} ${classes[size]}`}>
          <CardBody className={classes.CardBody}>
            <Form>
              <p className={classes.title}>{title}</p>
              <div className={classes.content}>{children}</div>
              <div className={classes.buttonContainer}>
                {footer && <div className='Modal-footer'>{footer}</div>}
                <Button
                  size='md'
                  color='default'
                  type='button'
                  onClick={handleCancelButtonClick}
                  className={classes.btn}
                >
                  {props.cancelButton || 'Cancel'}
                </Button>
                <Button
                  size='md'
                  color='primary'
                  type='button'
                  onClick={handleOkButtonClick}
                  className={classes.btn}
                  disabled={props.loading} // Disable the button while loading
                >
                  {props.loading ? <Spinner size="sm" /> : (props.submitButton || 'Ok')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>,
    root
  );
});

export { BaseModal };
