import { put, takeLatest, all, call } from "redux-saga/effects";
import { ITimesheet } from "redux/reducers/Contractor";
import {
  GET_ALL_TIMESHEETS_CONTRACTOR_REQUEST,
  setAllTimesheetsReducer,
} from "redux/reducers/Contractor/actionTypes";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { ContractorTimesheetService } from "services/Contractor/Timesheet/Timesheet";

function* requestGetAllTimesheets(data: {
  type: string;
  contractorId: number;
}) {
  try {
    yield put(setLoading(true));
    const [allTimesheets]: ITimesheet[][] = yield all([
      call(ContractorTimesheetService.getAllTimesheet, data?.contractorId),
    ]);
    yield put(setAllTimesheetsReducer(allTimesheets));
  } catch (e: any) {
    console.log(e);
    yield put(setLoading(false));
  } finally {
    yield put(setLoading(false));
  }
}

function* getAllTimesheets() {
  yield takeLatest(
    GET_ALL_TIMESHEETS_CONTRACTOR_REQUEST,
    requestGetAllTimesheets
  );
}

export default getAllTimesheets;
