import { memo, useEffect, useState, Fragment } from "react";
import FooterDefault from "components/Footers/FooterDefault";
import { useSelector, useDispatch } from "react-redux";
import { ReducerType } from "redux/reducers";
import classes from "./styles.module.scss";
import {
  Button,
  Container,
  Table,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import clsx from "clsx";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Highcharts from "highcharts";
import * as Exporting from "highcharts/modules/exporting";
import { fCurrency } from "utils/price";
import { BillService } from "services/Contractor/Bill/Bill";
import { XeroInvoiceService } from "services/Xero/XeroInvoice";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import FooterMobile from "components/Footers/FooterMobile";
import moment from "moment";
import Footer from "components/Footers/FooterTransparent";

Exporting.default(Highcharts);

interface Props {}

// eslint-disable-next-line no-empty-pattern
const ContractorDashboard = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { allExpenses } = useSelector((state: ReducerType) => state.contractor);
  const { user } = useSelector((state: ReducerType) => state.user);
  const [invoiceList, setInvoiceList] = useState([]);

  const [totalExpenseArray, setTotalExpenseArray] = useState([]);
  const [totalIncomeArray, setTotalIncomeArray] = useState([]);

  // Indexes:
  // April: 0 (12 - 3 + 3) % 12
  // May: 1
  // ...
  // Jan: 9 - (12 - 3) % 12
  // Feb: 10 - 12 - 3 + 1
  // Mar: 11 12 - 3 + 2

  const calculateMonth = (month) => {
    return (12 - 3 + month) % 12;
  };

  const calculateYear = (date: moment.Moment) => {
    if (date.month() <= 2) {
      return date.year() - 1;
    } else {
      return date.year();
    }
  };

  const calculateFutureProjection = (
    tempTotalIncomeArray,
    tempTotalLastYearIncomeArray
  ) => {
    const futureMonth = 11 - calculateMonth(moment().month()) + 1;

    let futureProfit;
    let netProfit;
    let result;

    if (futureMonth !== 12) {
      // previous months profit
      netProfit = tempTotalIncomeArray.reduce((partialSum, a, index) => {
        if (index < calculateMonth(moment().month())) return partialSum + a;
        else return partialSum + 0;
      }, 0);

      futureProfit =
        ((netProfit / calculateMonth(moment().month())) * futureMonth * 86) /
        100;

      result = futureProfit / futureMonth;
    } else {
      // last year profit
      netProfit = tempTotalLastYearIncomeArray.reduce((partialSum, a) => {
        return partialSum + a;
      }, 0);

      futureProfit = ((netProfit / 12) * futureMonth * 86) / 100;

      result = futureProfit / futureMonth;
    }
    return result;
  };

  useEffect(() => {
    if (totalIncomeArray.length || totalExpenseArray.length) {
      const actualIncome = totalIncomeArray
        .map((item, index) => item - totalExpenseArray[index])
        .map((actualIncome) => ({ y: actualIncome }));
      actualIncome.splice(
        (11 - calculateMonth(moment().month())) * -1,
        actualIncome.length
      );

      Highcharts.chart({
        chart: {
          renderTo: "dashboardChart",
        },
        title: {
          text: "Projected Earnings over Time",
        },
        legend: {
          enabled: false,
        },
        yAxis: {
          title: {
            text: "EARNINGS",
          },
        },
        xAxis: {
          title: {
            text: "TIME",
          },
          categories: [
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "Jan",
            "Feb",
            "Mar",
          ],
          type: "category",
        },
        series: [
          {
            name: "TOTAL INCOME",
            type: "line",
            color: "#f9a826",
            marker: {
              symbol: "circle",
            },
            data: totalIncomeArray.map((item) => ({ y: item })),
            zones: [
              {
                value: calculateMonth(moment().month()),
                dashStyle: "Solid",
              },
              {
                value: 11,
                dashStyle: "Dot",
              },
            ],
            zoneAxis: "x",
          },
          {
            name: "ACTUAL INCOME",
            type: "line",
            color: "#c92620",
            data: actualIncome,
            marker: {
              symbol: "triangle",
            },
            zIndex: 100,
          },
          {
            name: "TOTAL EXPENSES",
            type: "line",
            color: "#2ca8ff",
            data: totalExpenseArray.map((item) => ({ y: item })),
            marker: {
              symbol: "circle",
            },
          },
        ],
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
      });
    }
  }, [dispatch, totalIncomeArray, totalExpenseArray]);

  useEffect(() => {
    dispatch(setLoading(true));
    BillService.getAllContractorBill(user?.id)
      .then((res) => {
        if (res?.length) {
          const xeroBillIds = res.map((item) => item?.xeroBillId);
          XeroInvoiceService.getInvoicesByInvoiceIds(
            user?.id,
            xeroBillIds.join(", ")
          )
            .then((xeroInvoices) => {
              if (xeroInvoices?.Invoices?.length) {
                const mainInvoiceList = xeroInvoices.Invoices.map(
                  (xeroItem) => {
                    const tempItem = res.find(
                      (i) => i.xeroBillId === xeroItem.InvoiceID
                    );
                    return {
                      ...xeroItem,
                      employerFirstName: tempItem?.employerFirstName,
                      employerLastName: tempItem?.employerLastName,
                      timesheetPaid: tempItem?.timesheetPaid,
                      timesheetApproved: tempItem?.timesheetApproved,
                      timesheetPending: tempItem?.timesheetPending,
                      timesheetDeclined: tempItem?.timesheetDeclined,
                      timesheetWeekEnding: tempItem?.timesheetWeekEnding,
                    };
                  }
                );
                setInvoiceList(mainInvoiceList);

                const tempTotalIncomeArray = new Array(12).fill(0);
                const tempTotalLastYearIncomeArray = new Array(12).fill(0);

                mainInvoiceList.forEach((item) => {
                  if (
                    calculateYear(moment(item?.DateString)) ===
                    calculateYear(moment())
                  ) {
                    const index = calculateMonth(
                      moment(item?.DateString).month()
                    );
                    tempTotalIncomeArray[index] += Number(item?.Total);
                  }
                  if (
                    calculateYear(moment(item?.DateString)) ===
                    calculateYear(moment()) - 1
                  ) {
                    const index = calculateMonth(
                      moment(item?.DateString).month()
                    );
                    tempTotalLastYearIncomeArray[index] += Number(item?.Total);
                  }
                  if (
                    calculateYear(moment(item?.DateString)) ===
                      calculateYear(moment()) &&
                    (moment(item?.DateString).month() === 0 ||
                      moment(item?.DateString).month() === 1 ||
                      moment(item?.DateString).month() === 2)
                  ) {
                    const index = calculateMonth(
                      moment(item?.DateString).month()
                    );
                    tempTotalLastYearIncomeArray[index] += Number(item?.Total);
                  }
                });

                const tempPredict = calculateFutureProjection(
                  tempTotalIncomeArray,
                  tempTotalLastYearIncomeArray
                );
                for (
                  let i = calculateMonth(moment().month()) + 1;
                  i < 12;
                  i++
                ) {
                  if (tempPredict) {
                    tempTotalIncomeArray[i] = tempPredict;
                  } else {
                    tempTotalIncomeArray[i] = null;
                  }
                }

                setTotalIncomeArray(tempTotalIncomeArray);
              }
            })
            .catch((error) => dispatch(setErrorMess(error)));
        }
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  }, [dispatch]);

  useEffect(() => {
    if (allExpenses?.length) {
      const tempTotalExpenseArray = new Array(12).fill(0);

      allExpenses.forEach((item) => {
        if (
          calculateYear(moment(item?.dateOfExpense)) === calculateYear(moment())
        ) {
          const index = calculateMonth(moment(item?.dateOfExpense).month());
          tempTotalExpenseArray[index] += Number(item?.total);
        }
      });

      for (let i = calculateMonth(moment().month()) + 1; i < 12; i++) {
        tempTotalExpenseArray[i] = null;
      }

      setTotalExpenseArray(tempTotalExpenseArray);
    }
  }, [allExpenses]);

  const checkCurrentYear = (date) => {
    if (calculateYear(moment(date)) === moment().year()) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <ScrollNavbar />
      <div
        className={clsx("page-header header-filter", classes.wrapper)}
        filter-color="yellow"
      >
        <div className={classes.header} />
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project15.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.container}>
            <h1 className={classes.mainTitle}>DASHBOARD</h1>
            <Row>
              <Col>
                <div className={classes.sectionTotal}>
                  <p className={classes.sectionTotalTitle}>
                    THIS YEAR TOTAL INCOME:
                  </p>
                  <p className={classes.sectionTotalNumber}>
                    {fCurrency(
                      invoiceList
                        ?.filter((item) => checkCurrentYear(item?.DateString))
                        .map((invoice) => Number(invoice?.Total))
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )}
                  </p>
                </div>
                <div className={classes.sectionTotal}>
                  <p className={classes.sectionTotalTitle}>
                    THIS YEAR TOTAL EXPENSE:
                  </p>
                  <p className={classes.sectionTotalNumber}>
                    {fCurrency(
                      allExpenses
                        ?.filter((item) =>
                          checkCurrentYear(item?.dateOfExpense)
                        )
                        .map((expense) => Number(expense?.total))
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )}
                  </p>
                </div>
                <div className={classes.sectionTotal}>
                  <p className={classes.sectionTotalTitle}>MONEY OWNED:</p>
                  <p className={classes.sectionTotalNumber}>
                    {fCurrency(
                      invoiceList
                        ?.filter((item) => checkCurrentYear(item?.DateString))
                        .map((invoice) =>
                          invoice?.Status !== "PAID"
                            ? Number(invoice?.Total)
                            : 0
                        )
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )}
                  </p>
                </div>
                <div className={classes.sectionTotal}>
                  <p className={classes.sectionTotalTitle}>MONEY OWNING:</p>
                  <p className={classes.sectionTotalNumber}>
                    {fCurrency(
                      allExpenses
                        ?.filter((item) =>
                          checkCurrentYear(item?.dateOfExpense)
                        )
                        .map((expense) =>
                          !expense?.isPaid ? Number(expense?.total) : 0
                        )
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )}
                  </p>
                </div>
                <div className={classes.sectionTotal}>
                  <p className={classes.sectionTotalTitle}>TOTAL GST AMOUNT:</p>
                  <p className={clsx(classes.sectionTotalNumber, (
                      invoiceList
                        ?.filter((item) => checkCurrentYear(item?.DateString))
                        .map((invoice) => invoice?.TotalTax)
                        .reduce((partialSum, a) => partialSum + a, 0) -
                        allExpenses
                          ?.filter(
                            (item) =>
                              moment(item?.dateOfExpense).year() ===
                              moment().year()
                          )
                          .map((expense) =>
                            Number(expense?.gst)
                              ? Number(expense?.total - expense?.net)
                              : 0
                          )
                          .reduce((partialSum, a) => partialSum + a, 0)
                    ) < 0 ? classes.negativeNumber : "")}>
                    {fCurrency(
                      invoiceList
                        ?.filter((item) => checkCurrentYear(item?.DateString))
                        .map((invoice) => invoice?.TotalTax)
                        .reduce((partialSum, a) => partialSum + a, 0) -
                        allExpenses
                          ?.filter(
                            (item) =>
                              moment(item?.dateOfExpense).year() ===
                              moment().year()
                          )
                          .map((expense) =>
                            Number(expense?.gst)
                              ? Number(expense?.total - expense?.net)
                              : 0
                          )
                          .reduce((partialSum, a) => partialSum + a, 0)
                    )}
                  </p>
                </div>
              </Col>
              <Col className={classes.leftInfo}>
                <div className={classes.chartLegendWrapper}>
                  <div className={classes.chartLegend}>
                    <p>KEY:</p>
                    <ul>
                      <li>
                        <i
                          className={clsx(
                            "fas fa-circle",
                            classes.totalIncomeLegend
                          )}
                        ></i>{" "}
                        TOTAL INCOME
                      </li>
                      <li>
                        <i
                          className={clsx(
                            "fas fa-caret-up",
                            classes.actualIncomeLegend
                          )}
                        ></i>{" "}
                        ACTUAL INCOME
                      </li>
                      <li>
                        <i
                          className={clsx(
                            "fas fa-circle",
                            classes.totalExpensesLegend
                          )}
                        ></i>{" "}
                        TOTAL EXPENSES
                      </li>
                      <li>
                        <i className={clsx("fas fa-ellipsis-h")}></i> FUTURE
                        PROJECTIONS
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="dashboardChart" className={classes.dashboardChart} />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
      <FooterMobile />
    </Fragment>
  );
});

export default ContractorDashboard;
