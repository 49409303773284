import { API } from "aws-amplify";

export class XeroContactService {
  
  static async getContact(id): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/xero/contacts`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postContact(id, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/xero/contacts`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putEmployerContact(id, contactId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/employers/${id}/xero/contacts/${contactId}`;
    const myInit = {
      response: true,
      body: data
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putContractorContact(id, contactId, data): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/xero/contacts/${contactId}`;
    const myInit = {
      response: true,
      body: data
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
