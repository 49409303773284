import defaultAvatar from "assets/img/placeholder.jpg";

export class UtilDefaultAvatar {
  static async getFile() {
    const blobToFile = (theBlob: Blob, fileName: string): File => {
      return new File([theBlob as any], fileName, {
        lastModified: new Date().getTime(),
        type: theBlob.type,
      });
    };
    return blobToFile(
      await fetch(defaultAvatar).then((r) => r.blob()),
      "defaultAvatar.png"
    );
  }
}
