import { Fragment, memo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  Col,
  Container,
  Row,
  CardLink,
} from "reactstrap";
import classes from "./styles.module.scss";
import _ from "lodash";
import clsx from "clsx";
import FooterTransparentEmployer from "components/Footers/FooterTransparentEmployer";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import TinderCard from "react-tinder-card";
import { Link } from "react-router-dom";
import routes from "routers/routes";
import { JobService } from "services/Employer/Listing/Job";
import moment from "moment";
import { setErrorMess } from "redux/reducers/Status/actionTypes";
import { ListingEmailService } from "services/Employer/Listing/Email";
import { EmailTypes } from "models/Employer/Listings/Dashboard";
import {
  setCandidatesReducer,
  setDeclinedCandidatesReducer,
} from "redux/reducers/Employer/actionTypes";

interface Props {}

const ListingJob = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { jobOfListing, declinedCandidates } = useSelector(
    (state: ReducerType) => state.employer
  );
  const candidatesList = jobOfListing?.candidates || [];

  const initialData = candidatesList?.map((item) => {
    return { ...item, isDisplayed: false };
  });
  // const listTinderCard = [...initialData].reverse();

  const [listTinderCard, setListTinderCard] = useState(() => {
    const initialData = candidatesList?.map((item) => {
      return { ...item, isDisplayed: false };
    });
    return initialData?.reverse() || [];
  });
  

  const [list, setList] = useState(initialData);
  const [animating, setAnimating] = useState(false);
  const [countItem, setCountItem] = useState(0);

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const handleDecline = (selectedCandidateId) => {
    // console.log('handleDecline triggered');
    // console.log("Current candidatesList:", candidatesList);
    // console.log("this one")
    // console.log(selectedCandidateId)
    // console.log(declinedCandidates?.contractorIds)
    const dataSubmit = {
      jobId: jobOfListing?.id,
      contractorIds: [
        selectedCandidateId,
      ],
      employer: user?.id,
    };

    // console.log('Data to submit for Decline:', dataSubmit);
    // console.log(declinedCandidates)
    if (!declinedCandidates) {
      JobService.postDeclineCandidate(jobOfListing?.id, dataSubmit)
        .then((res) => {
          // console.log('postDeclineCandidate response:', res);

          dispatch(setDeclinedCandidatesReducer(res));
          const updatedCandidates = candidatesList.filter(
            (item) => item?.id !== selectedCandidateId
          );
          // console.log('Updated candidates list after decline:', updatedCandidates);

          dispatch(setCandidatesReducer(updatedCandidates as any));
          setCountItem((prev) => {
            const newValue = prev + 1;
            // console.log("Updating count item to:", newValue);
            return newValue;
          });
        })
        .catch((err) => {
          // console.error('Error in postDeclineCandidate:', err);
          dispatch(setErrorMess(err));
        });
        
    } else {
      declinedCandidates.contractorIds?.forEach((item) =>
        dataSubmit.contractorIds.push(item as any)
        
      );
      // console.log("bringing them together")
      // console.log(dataSubmit.contractorIds)
      // console.log("test together ness")
      // console.log(declinedCandidates.contractorIds)
      JobService.putDeclineCandidate(jobOfListing?.id, dataSubmit)
        .then((res) => {
          // console.log("putDeclineCandidate response:", res);

          dispatch(setDeclinedCandidatesReducer(res));
          const updatedCandidates = candidatesList.filter(
            (item) => item?.id !== selectedCandidateId
          );
          // console.log(
          //   "Updated candidates list after decline:",
          //   updatedCandidates
          // );

          dispatch(setCandidatesReducer(updatedCandidates as any));
          setCountItem((prev) => {
            const newValue = prev + 1;
            // console.log("Updating count item to:", newValue);
            return newValue;
          });
        })
        .catch((err) => {
          console.error("Error in putDeclineCandidate:", err);
          dispatch(setErrorMess(err));
        });
    }
    setListTinderCard(prevList => prevList.filter(item => item.id !== selectedCandidateId));
  };

  const handleAccept = (selectedCandidateId) => {
    const dataSubmit = {
      job: jobOfListing?.id,
      contractor: selectedCandidateId,
      employer: user?.id,
      offered: true,
    };
    // console.log('Data to submit:', dataSubmit);

    JobService.postJobOffer(dataSubmit)
      .then(async (res) => {
        // console.log('JobService.postJobOffer response:', res);

        if (res?.id) {
          // console.log('Sending offer create email');
          await ListingEmailService.offerCreateEmail(res.id, {
            type: EmailTypes.NEW_CONNECT_OFFER_FOR_CONTRACTOR,
          })
            .then((emailRes) => {
              // console.log('Email sent successfully:', emailRes);
            })
            .catch((err) => {
              // console.error('Error sending email:', err);
              dispatch(setErrorMess(err));
            });
        } else {
          // console.warn('No response ID received from postJobOffer');
        }

        const updatedCandidates = candidatesList.filter(
          (item) => item?.id !== res?.contractor
        );
        // console.log('Updated candidates list:', updatedCandidates);

        dispatch(setCandidatesReducer(updatedCandidates as any));
        setCountItem((prev) => {
          const newValue = prev + 1;
          // console.log('Updating count item to:', newValue);
          return newValue;
        });
      })
      .catch((err) => {
        // console.error('Error in postJobOffer:', err);
        dispatch(setErrorMess(err));
      });
      setListTinderCard(prevList => prevList.filter(item => item.id !== selectedCandidateId));
  };

  const goToIndex = () => {
    if (animating) return;
  };

  const handleAddStamp = (index, direction) => {
    let tinderCardItem = document.getElementById(`tinder-card-${index}`)
      .firstChild as HTMLElement;
    tinderCardItem.classList.add(classes.currentTinderCard);
    if (direction === "right") {
      tinderCardItem.classList.remove(classes.tinderDeclineCard);
      tinderCardItem.classList.add(classes.tinderAcceptCard);
    } else {
      tinderCardItem.classList.remove(classes.tinderAcceptCard);
      tinderCardItem.classList.add(classes.tinderDeclineCard);
    }
  };

  const handleRemoveStamp = (index) => {
    let tinderCardItem = document.getElementById(`tinder-card-${index}`)
      .firstChild as HTMLElement;
    tinderCardItem.classList.remove(classes.tinderAcceptCard);
    tinderCardItem.classList.remove(classes.tinderDeclineCard);
  };

  const handleSwipe = (item, direction) => {
    // console.log(item)
    // console.log("SWIPE TRIGGERED");
    if (direction === "right") {
      handleAccept(item?.id)
    } else {
      // console.log("ELSE")
      // console.log(item?.id)
      handleDecline(item?.id)
    }
  };

  const processSummary = (text) => {
    return text.replace(/\. (?!\d{4}-\d{2}-\d{2})/g, ".\n- ");
  };

  const CardContainer = ({ item, index }) => {
    return (
      <Container className={classes.cardContainerWrapper}>
        <p className={classes.progressWord}>Progress</p>
        <p className={classes.declineWord}>Decline</p>
        <Row>
          <Col className={clsx("ml-auto d-flex", classes.cardContainer)} md="6">
            <Link
              to={routes.employer.listing.dashboard}
              className={classes.btnBack}
            >
              ← Back
            </Link>
            <Card
              className={clsx(
                "card-background card-background-product card-raised",
                classes.card
              )}
            >
              <CardBody className={classes.cardBody}>
                <div>
                  <CardTitle
                    tag="h2"
                    className={clsx("my-2", classes.cardTitle)}
                  />
                </div>
                <div className={classes.btnWrapper}>
                  <Button
                    color="primary"
                    onClick={() => handleAccept(item.id)}
                    className="px-5"
                  >
                    Progress
                  </Button>
                  <Button
                    onClick={() => handleDecline(item.id)}
                    className="px-5"
                  >
                    Decline
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className={clsx("mr-auto mb-3", classes.infoWrapper)} md="6">
            <div className={clsx("info info-horizontal", classes.info)}>
              <div className="icon icon-danger">
                <i className="now-ui-icons ui-2_chat-round"></i>
              </div>
              <div className="description">
                <h4 className="info-title my-3">{item?.title}</h4>
                <p
                  className={clsx(
                    "description mb-0",
                    classes.descriptionContainer
                  )}
                >
                  {item?.city && (
                    <p className="mb-0 px-0">- Location: {item?.city}</p>
                  )}
                  <p className="mb-0 px-0">
                    - Availability:{" "}
                    {moment(item?.availableDate).format("DD/MM/YYYY")}
                  </p>
                  <p className="mb-0 px-0">
                    - Work Policy:{" "}
                    {item?.workPolicy === "workFromHome"
                      ? "Work from home"
                      : item?.workPolicy === "mixed"
                      ? "Mixed"
                      : "Office"}
                  </p>
                  <p className="mb-0 px-0">
                    - Total Experience:{" "}
                    {item?.experienceYears ? item?.experienceYears : "0"}{" "}
                    {item?.experienceYears > 1
                      ? "years experience"
                      : "year experience"}
                  </p>
                </p>
              </div>
            </div>
            <div className={clsx("info info-horizontal", classes.info)}>
              <div className="icon icon-danger">
                <i className="now-ui-icons business_chart-pie-36"></i>
              </div>
              <div className="description">
                <h4 className="info-title my-3">Career Summary:</h4>
                <p
                  className={clsx(
                    "description mb-0",
                    classes.descriptionContainer
                  )}
                >
                  {item.summary
                    ? item.summary.length > 200
                      ? item.summary.slice(0, 200) + "..."
                      : item.summary
                    : "No summary"}
                </p>
              </div>
            </div>
            <div className={clsx("info info-horizontal", classes.info)}>
              <div className="icon icon-danger">
                <i className="now-ui-icons design-2_ruler-pencil"></i>
              </div>
              <div className="description">
                <h4 className="info-title my-3">Last Engagement:</h4>
                <p
                  className={clsx("description", classes.descriptionContainer)}
                >
                  <p className="mb-0 px-0">- {item.lastTitle}</p>
                  <p className="mb-0 px-0">- {item.lastCompany}</p>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <Fragment>
      <div
        className={clsx("page-header", classes.pageHeader)}
        filter-color="yellow"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg39.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.containerCarousel}>
            <Row className={clsx(classes.cardWrapper, "pt-2")}>
              {countItem >= list.length || list.length === 0 ? (
                <Col className="ml-auto d-flex" md="12">
                  <Card
                    className={clsx(
                      "card-background card-background-product card-raised",
                      classes.cardNoMoreItem
                    )}
                  >
                    <Link
                      to={routes.employer.listing.dashboard}
                      className={classes.btnBackNoMoreItem}
                    >
                      ← Back
                    </Link>
                    <CardBody>
                      <div>
                        <CardTitle tag="h2" className="mt-0">
                        We're sorry, there are currently no more active candidates available for this job opportunity. 
                        </CardTitle>
                        <CardTitle tag="h3" className="mt-2">
                        {/* Please check the specifics of your search criteria and try again.
                        Alternatively, you may consider utilising our HeadHunter service to help you find the perfect match for your needs. {" "}
                         */}
                         <Button color="primary" className="px-5">
                            <Link to={routes.employer.listing.dashboard}>
                              Try HeadHunter?
                            </Link>
                          </Button>
                          </CardTitle>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ) : (
                <Carousel
                  activeIndex={countItem}
                  next={() => {
                    const currentItemId = list[countItem]?.id;
                    handleDecline(currentItemId);
                  }}
                  previous={() => {
                    const currentItemId = list[countItem]?.id;
                    handleAccept(currentItemId);
                  }}
                  className={classes.carousel}
                  interval={false}
                  slide={false}
                >
                  <CarouselIndicators
                    items={list}
                    activeIndex={countItem}
                    onClickHandler={goToIndex}
                  />
                  {list?.map((item, index) => {
                    if (item?.isDisplayed)
                      return (
                        <CarouselItem
                          className={classes.displayNone}
                          key={index}
                        />
                      );
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={index}
                      >
                        <CardContainer item={item} index={index} />
                      </CarouselItem>
                    );
                  })}
                </Carousel>
              )}
            </Row>
          </Container>

          <Container className={classes.containerTinderCard}>
            {listTinderCard.length === 0 ? (
              <Col className="ml-auto d-flex px-0" md="12">
                <Card
                  className={clsx(
                    "card-background card-background-product card-raised",
                    classes.cardNoMoreItem
                  )}
                >
                  <CardBody>
                    <div>
                      <CardTitle tag="h2" className="mt-0">
                        That's all our Active Talent for today.
                      </CardTitle>
                      <CardTitle tag="h2" className="mt-2">
                        To Access Passive Candidates and crowd source your
                        opportunity, try{" "}
                        <Button color="primary" className="px-5">
                          <Link to={routes.employer.listing.dashboard}>
                            HeadHunter
                          </Link>
                        </Button>
                      </CardTitle>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              listTinderCard?.map((item, index) => {
                if (item?.isDisplayed === false) {
                  return (
                    <div
                      id={`tinder-card-${index}`}
                      onTouchEndCapture={() => handleRemoveStamp(index)}
                      onMouseUpCapture={() => handleRemoveStamp(index)}
                    >
                      <TinderCard
                        className={classes.swipe}
                        key={index}
                        preventSwipe={["up", "down"]}
                        onCardLeftScreen={(direction) =>
                          handleSwipe(item, direction)
                        }
                        onSwipeRequirementFulfilled={(direction) =>
                          handleAddStamp(index, direction)
                        }
                        swipeThreshold={180}
                      >
                        <CardContainer item={item} index={index} />
                      </TinderCard>
                    </div>
                  );
                } else {
                  return (
                    <TinderCard
                      className="d-none"
                      key={index}
                      preventSwipe={["up", "down"]}
                      onCardLeftScreen={(direction) =>
                        handleSwipe(item, direction)
                      }
                      swipeThreshold={180}
                    ></TinderCard>
                  );
                }
              })
            )}
            <Button
              color="primary"
              className={clsx("rounded-circle", classes.btnBackMobile)}
              tag={Link}
              to={routes.employer.listing.dashboard}
            >
              Back
            </Button>
            {listTinderCard.length !== 0 && (
              <Fragment>
                <Button
                  color="success"
                  onClick={() => {
                    const currentItemId = list[countItem]?.id;
                    // console.log(currentItemId);
                    handleAccept(currentItemId);
                  }}
                  className={clsx("px-5", classes.btnProgressMobile)}
                >
                  Progress
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    const currentItemId = list[countItem]?.id;
                    // console.log(currentItemId);
                    handleDecline(currentItemId);
                  }}
                  className={clsx("px-5", classes.btnDeclineMobile)}
                >
                  Decline
                </Button>
              </Fragment>
            )}
          </Container>
        </div>
        {/* <FooterTransparentEmployer /> */}
      </div>
    </Fragment>
  );
});

export default ListingJob;
