import { Auth } from "aws-amplify";
import axios from "axios";
import { setAuthCredentials } from "../../LocalStorageService";

class StrapiUserService {
  /**
   * register user
   * @param {*} email
   * @param {*} password
   */
  async register(email, password) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/local/register`,
        {
          username: email,
          email,
          password,
        }
      );
      setAuthCredentials(response && response.data);
      return response;
    } catch (error) {
      console.log("error signing up:", error);
    }
  }

  /**
   * send email confirmation
   * @param {*} username
   * @param {*} code
   */
  async confirm(username, code) {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/send-email-confirmation`,
        {
          email: username,
        }
      );
    } catch (error) {
      console.log("error confirming sign up", error);
    }
  }

  /**
   * resend confirmation code with amlify
   * @param {*} username
   */
  async resendConfimationCode(username) {
    try {
      await Auth.resendSignUp(username);
      console.log("code resent successfully");
    } catch (err) {
      console.log("error resending code: ", err);
    }
  }

  /**
   * forgot password code send
   * @param {*} username
   */
  async forgotPassword(username) {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/forgot-password`,
        {
          email: username,
        }
      );
      console.log("forgot password code sent successfully");
    } catch (err) {
      console.log("error sending the forgot password code: ", err);
    }
  }

  /**
   * new password submition
   * @param {*} code
   * @param {*} password
   * @param {*} passwordConfirmation
   */
  async forgotPasswordSubmit(code, password, passwordConfirmation) {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password`,
        {
          code,
          password,
          passwordConfirmation,
        }
      );
      console.log("new password submitted successfully");
    } catch (err) {
      console.log("error submitting new passowrd: ", err);
    }
  }
}

const strapiUserService = new StrapiUserService();

export default strapiUserService;
