import { memo, useEffect, useMemo } from 'react';
import { Form, Button, Card, CardBody } from 'reactstrap';
import classes from './styles.module.scss';
import routes from 'routers/routes';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/Common/CustomInput';
import { SkillService } from 'services/Contractor/NewMember/Skill';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes';
import { ReducerType } from 'redux/reducers';
import { setProfileReducer } from 'redux/reducers/Contractor/actionTypes';

interface Props {}

interface SkillFormData {
  id?: number;
  skillList: {
    name: string;
  }[];
}

// eslint-disable-next-line no-empty-pattern
const Skill = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const schema = useMemo(() => {
    return yup.object().shape({
      id: yup.number().nullable(), // Make id optional
      skillList: yup.array().of(
        yup.object().shape({
          name: yup.string().nullable(), // Make name optional
        })
      ).nullable(), // Make skillList optional
    });
  }, []);

  

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<SkillFormData>({
    // resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'skillList',
  });

  useEffect(() => {
    append({});
    append({});
    append({});
  }, [append]);

  useEffect(() => {
    if (profile?.skill && !!profile.skill.skillList?.length) {
      reset({
        id: profile.skill.id,
        skillList: profile.skill.skillList?.map((item: any) => ({
          name: item,
        })),
      });
    }
  }, [reset, profile]);

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.experience));
  };

  const onSubmit = async (data: SkillFormData) => {
    dispatch(setLoading(true));
    const skillParams = data?.skillList?.map((item) => item?.name).filter(name => name && name.trim().length > 0);
    console.log(skillParams[0])
    if (data?.id) {      
      await SkillService.putSkill(skillParams, data?.id)
        .then((res) => {
          dispatch(setProfileReducer({ ...profile, skill: res }));
          dispatch(push(routes.contractor.newMember.preference));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    } else if (skillParams && skillParams.length > 0) {
        await SkillService.postSkill(skillParams)
            .then((res) => {
                dispatch(setProfileReducer({ ...profile, skill: res }));
                dispatch(push(routes.contractor.newMember.preference));
            })
            .catch((e) => dispatch(setErrorMess(e)))
            .finally(() => dispatch(setLoading(false)));
    } else {     
        dispatch(push(routes.contractor.newMember.preference));
        dispatch(setLoading(false))
    }
  };

  return (
    <Card className={classes.card}>
      <CardBody className={classes.cardBody}>
        <div className={classes.titleContainer}>
          <p className={classes.title}>Skills &amp; Capabilities</p>
          <p className={classes.subTitle}>
            Please enter any skills or capabilities you think a potential client
            would like to know about.
          </p>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.content}>
            {fields?.map((field, index) => {
              return (
                <CustomInput
                  endIcon={
                    fields?.length > 3 && (
                      <Button
                        onClick={() => remove(index)}
                        className={classes.btnDelete}
                      >
                        <i className='fa fa-trash' />
                      </Button>
                    )
                  }
                  key={field?.id}
                  placeholder='Skill, capability, tag,...'
                  type='text'
                  autoComplete='off'
                  inputRef={register(`skillList.${index}.name`)}
                  errorMessage={
                    errors.skillList && errors.skillList[index]?.name?.message
                  }
                />
              );
            })}
          </div>
          <div className={classes.buttonContainer}>
            <Button color='default' type='button' onClick={handleBack}>
              Back
            </Button>
            <Button
              type='button'
              className={classes.btnAddMore}
              onClick={() => append({})}
            >
              <span className='text-dark'>
                Add more
                <i className='fa fa-plus ml-2' />
              </span>
            </Button>

            <Button color='primary' type='submit'
             
              style={{ backgroundColor: '#f2e822', color: 'black' }}
            >
              Skip
            </Button>
            <Button color='primary' type='submit'>
              Next
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
});

export default Skill;
