import { memo, useMemo, useEffect } from "react";
import {
  Form,
  Card,
  CardBody,
  Modal,
  Row,
  Col,
  ModalHeader,
  Button,
} from "reactstrap";
import classes from "./styles.module.scss";
import CustomInput from "components/Common/CustomInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "components/Common/CustomDatePicker";
import { JobService } from "services/Employer/Listing/Job";
import { ContractorEmailService } from "services/Contractor/Offer/Email";
import { EmailTypes } from "models/Employer/Listings/Dashboard";
import { setErrorMess } from "redux/reducers/Status/actionTypes";
import moment from "moment";
import { getAllOffers } from "redux/reducers/Contractor/actionTypes";
import { ReducerType } from "redux/reducers";

interface TerminateContractProps {
  onClose: () => void;
  isOpen: boolean;
  contract?: any;
  onCloseContractModal?: () => void;
}

const TerminateContract = memo(
  ({
    onClose,
    isOpen,
    contract,
    onCloseContractModal,
  }: TerminateContractProps) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: ReducerType) => state.user);

    const schema = useMemo(() => {
      return yup.object().shape({
        endDate: yup
          .date()
          .typeError("Please enter a valid date")
          .required("This field is required"),
        reason: yup.string().required("This field is required"),
      });
    }, []);

    const {
      register,
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        endDate: null,
        reason: "",
      },
    });

    useEffect(() => {
      reset();
    }, [isOpen, reset]);

    const onSubmit = (data) => {
      JobService.revokeContractJobOffer(
        {
          contractor: contract?.contractor,
          employer: contract?.employer,
          job: contract?.job,
          startDate: contract?.startDate,
          endDate: data?.endDate,
          rateOffered: contract?.rateOffered,
          approvalName: contract?.approvalName,
          approvalEmail: contract?.approvalEmail,
          purchaseOrderNumber: contract?.purchaseOrderNumber,
        },
        contract?.id
      )
        .then((res) => {
          ContractorEmailService.terminateContractEmail(contract?.id, {
            type: EmailTypes.CONTRACTOR_TERMINATE_NOTICE_OFFER,
          })
            .then(() => {
              dispatch(getAllOffers(user?.id));
              onClose();
              onCloseContractModal();
            })
            .catch((e) => dispatch(setErrorMess(e)));
        })
        .catch((e) => dispatch(setErrorMess(e)));
    };

    return (
      <Card className={classes.card}>
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          centered
          scrollable
          className={classes.modal}
        >
          <ModalHeader toggle={onClose} className={classes.modalHeader}>
            Give notice
          </ModalHeader>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CardBody className={classes.cardDateBody}>
              <Row className="mb-3">
                <Col className="d-flex" xs={4} md={3}>
                  <p className={classes.commonTitle}>End Date:</p>
                </Col>
                <Col xs={8} md={9}>
                  <CustomDatePicker
                    name="endDate"
                    control={control}
                    placeholder="Date"
                    dateFormat="dd/MM/yyyy"
                    minDate={moment().add(2, "week").toDate()}
                    errorMessage={errors.endDate?.message}
                  />
                </Col>
              </Row>
              <Row className="mb-1">
                <Col className="d-flex" xs={4} md={3}>
                  <p className={classes.commonTitle}>Reason:</p>
                </Col>
                <Col xs={8} md={9}>
                  <CustomInput
                    className={classes.input}
                    placeholder="What is the reason for termination?"
                    autoComplete="off"
                    inputRef={register("reason")}
                    errorMessage={errors.reason?.message}
                  />
                </Col>
              </Row>
              <Row>
                <Col className={classes.buttonActionContainer}>
                  <Button color="danger" type="submit">
                    CONFIRM
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Form>
        </Modal>
      </Card>
    );
  }
);

export default TerminateContract;
