import { memo, useMemo, useEffect } from "react";
import { Form, Button, Card, CardBody, Modal, CardTitle } from "reactstrap";
import classes from "./styles.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextarea from "components/CustomTextarea";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  declineItem?: any;
  declineType?: any;
  handleDeclineOfferDesktop?: any;
  handleDeclineOfferMobile?: any;
  handleRestoreCard?: any;
  index?: any;
}

const DeclineReason = memo(
  ({
    onClose,
    isOpen,
    declineItem,
    declineType,
    handleDeclineOfferDesktop,
    handleDeclineOfferMobile,
    handleRestoreCard,
    index,
  }: Props) => {
    const schema = useMemo(() => {
      return yup.object().shape({
        reason: yup.string().nullable().required("This field is required"),
      });
    }, []);

    const onSubmit = (data) => {
      if (declineType === "desktop") {
        handleDeclineOfferDesktop(declineItem, data?.reason);
      } else {
        handleDeclineOfferMobile(declineItem, data?.reason);
      }
      onClose();
    };

    const {
      handleSubmit,
      formState: { errors },
      reset,
      register,
    } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

    useEffect(() => {
      reset({
        reason: null,
      });
    }, [isOpen, reset]);

    const handleCancel = () => {
      if (declineType === "mobile") {
        handleRestoreCard(declineItem, index);
      }
      onClose();
    };

    return (
      <Card className={classes.card}>
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          centered
          scrollable
          className={classes.modal}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CardBody className={classes.cardBody}>
              <CardTitle className={classes.title}>
                What is the reason for the decline ?
              </CardTitle>
            </CardBody>
            <CardBody className={classes.cardDateBody}>
              <CustomTextarea
                placeholder={"Type in your reason"}
                type="text"
                autoComplete="off"
                inputRef={register("reason")}
                errorMessage={errors?.reason?.message}
              />
            </CardBody>
            <CardBody className={classes.cardBody}>
              <div className={classes.buttonContainer}>
                <Button
                  size="md"
                  color="default"
                  type="button"
                  className={classes.btn}
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
                <Button
                  size="md"
                  color="primary"
                  type="submit"
                  className={classes.btn}
                >
                  Send
                </Button>
              </div>
            </CardBody>
          </Form>
        </Modal>
      </Card>
    );
  }
);

export default DeclineReason;
