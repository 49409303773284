import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_JOB_CLIENT_LIST_REQUEST,
  setJobClientListReducer,
} from "redux/reducers/Employer/actionTypes";
import { JobService } from "services/Employer/Listing/Job";

function* requestGetJobClientList() {
  try {
    yield put(setLoading(true));
    // console.log("ummmm")
    const [jobClientList] = yield all([call(JobService.getJobClient)]);
    yield put(setJobClientListReducer(jobClientList));
    console.log(jobClientList)
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getJobClientList() {
  // console.log("this is working")
  yield takeLatest(GET_JOB_CLIENT_LIST_REQUEST, requestGetJobClientList);
}

export default getJobClientList;
