import { Row, Col } from 'reactstrap';
import { Controller } from 'react-hook-form';
import CustomInput from 'components/Common/CustomInput';
import SearchLocationInput from 'components/Common/GoogleAddress';
import CustomSelect from 'components/Common/CustomSelect';
import CustomSlider from 'components/Common/CustomSlider';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import CustomCheckboxFlip from 'components/Common/CustomCheckBoxFlip';
import CustomMultiSelect from 'components/Common/CustomMultiSelect';
import CustomTagsInput from 'components/Common/CustomTagsInput';
import { JobTypes, NumberOfExperience } from 'models/Employer/Listings/Dashboard';
import { PreferencePolicyTypes } from 'models/Contractor/NewMember/Preference';
import { VisaTypes } from 'models/Contractor/NewMember/MojCheck';
import moment from 'moment';

const JobDetailsForm = ({ control, errors, watch, setValue, itemEdit, register }) => {
    const jobTypeField = watch('jobType');
    const isPrivate = watch('private');

    const handlePrivateChange = (isChecked) => {
        setValue('private', isChecked); // Update the 'private' field in the form state
    };
  
  return (
    <>
      <CustomInput
        placeholder="Title"
        type="text"
        autoComplete="off"
        inputRef={register('title')}
        errorMessage={errors.title?.message}
      />
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <SearchLocationInput
            {...field}
            ref={null}
            placeholder="Nearest town or city"
            errorMessage={errors.address?.message}
            value={field.value}
            onChange={(value: any) => {
              setValue('region', value?.address?.region);
              setValue('city', value?.address?.city);
              setValue('country', value?.address?.country);
              return field.onChange(value?.address?.formattedAddress);
            }}
          />
        )}
      />
      <CustomSelect
        placeholder="Job type"
        name="jobType"
        control={control}
        options={JobTypes}
        errorMessage={errors.jobType?.message}
      />
      {jobTypeField?.name && (
        <>
          <Row>
            <Col md={9}>
              <span className="ml-1">
                {jobTypeField?.name === 'Contract' ? 'Hourly Rate' : 'Expected Salary'}
              </span>
              <CustomSlider
                name="hourlyRate"
                min={jobTypeField?.name === 'Contract' ? 30 : 40000}
                max={jobTypeField?.name === 'Contract' ? 300 : 500000}
                step={jobTypeField?.name === 'Contract' ? 1 : 1000}
                control={control}
                errorMessage={errors.hourlyRate?.message}
                defaultValue={itemEdit?.maxSalary || itemEdit?.maxRate}
              />
            </Col>
            <Col md={3} className="d-flex flex-column align-items-center justify-content-center">
              <span className="ml-1">
                {jobTypeField?.name === 'Contract' ? 'Keep Rate Private' : 'Keep Salary Private'}
              </span>
              <CustomCheckboxFlip
                firstLabel=""
                secondLabel=""
                onChange={handlePrivateChange}
                checked={itemEdit?.private}
                tooltipText="Keep salary or rate private"
              />
            </Col>
          </Row>
        </>
      )}
      <CustomDatePicker
        name="startDate"
        control={control}
        placeholder="Start date."
        dateFormat="dd/MM/yyyy"
        minDate={moment().toDate()}
        maxDate={moment().add(1, 'y').toDate()}
        errorMessage={errors.startDate?.message}
      />
      <CustomSelect
        placeholder="Policy"
        name="policy"
        control={control}
        options={PreferencePolicyTypes}
        errorMessage={errors.policy?.message}
      />
      <CustomSelect
        placeholder="Number of relevant experience"
        name="numberOfExperience"
        control={control}
        options={NumberOfExperience}
        errorMessage={errors.numberOfExperience?.message}
      />
      <CustomMultiSelect
        placeholder="Select visa type"
        name="visaType"
        control={control}
        isMulti={true}
        options={VisaTypes}
        errorMessage={errors.visaType?.message}
      />
      <Controller
        name="skill"
        control={control}
        render={({ field }) => (
          <CustomTagsInput
            {...field}
            name="skill"
            placeholder="Required Skills"
            onChange={(value: any) => field.onChange(value)}
            value={field.value ? field.value : []}
            control={control}
            errorMessage={errors.skill?.message}
          />
        )}
      />
    </>
  );
};

export default JobDetailsForm;


{/*               
              <CustomInput
                placeholder='Title'
                type='text'
                autoComplete='off'
                inputRef={register('title')}
                errorMessage={errors.title?.message}
              />
              <Controller
                name='address'
                control={control}
                render={({ field }) => (
                  <SearchLocationInput
                    {...field}
                    ref={null}
                    placeholder='Nearest town or city'
                    errorMessage={errors.address?.message}
                    value={field.value}
                    onChange={(value: any) => {
                      setValue('region', value?.address?.region);
                      setValue('city', value?.address?.city);
                      setValue('country', value?.address?.country);
                      return field.onChange(value?.address?.formattedAddress);
                    }}
                  />
                )}
              />
              <CustomSelect
                placeholder='Job type'
                name='jobType'
                control={control}
                options={JobTypes}
                errorMessage={errors.jobType?.message}
              />
              {jobTypeField?.name && (
                <>
                  <Row>
                    <Col md={9}>
                      <span className='ml-1'>
                        {jobTypeField?.name === 'Contract'
                          ? 'Hourly Rate'
                          : 'Expected Salary'}
                      </span>
                      <CustomSlider
                        name='hourlyRate'
                        min={jobTypeField?.name === 'Contract' ? 30 : 40000}
                        max={jobTypeField?.name === 'Contract' ? 300 : 500000}
                        step={jobTypeField?.name === 'Contract' ? 1 : 1000}
                        control={control}
                        className={classes.sliderHourlyRate}
                        errorMessage={errors.hourlyRate?.message}
                        defaultValue={itemEdit?.maxSalary || itemEdit?.maxRate}
                      />
                    </Col>
                    <Col md={3}>
                      <span className='ml-1'>
                        {jobTypeField?.name === 'Contract'
                          ? 'Keep Rate Private'
                          : 'Keep Salary Private'}
                      </span>
                      <CustomCheckboxFlip
                        firstLabel=''
                        secondLabel=''
                        onChange={handlePrivateChange}
                        checked={itemEdit?.private}
                        tooltipText='blah algbh'
                      />
                    </Col>
                  </Row>
                  <span className='ml-1'>
                    {isPrivate 
                      ? jobTypeField?.name === 'Contract'
                          ? 'Necta will not publish the maximum rate on our listings page or share it with candidates. It will however, be used to match the right candidates, meaning candidates will know its above their minimum rate.'
                          : 'Necta will not publish the maximum salary on our listings page or share it with candidates. It will however, be used to match the right candidates, meaning candidates will know its above their minimum salary.'
                      : ""
                    }
                  </span>
                </>
              )}
              <CustomDatePicker
                name='startDate'
                control={control}
                placeholder='Start date.'
                dateFormat='dd/MM/yyyy'
                minDate={moment().toDate()}
                maxDate={moment().add(1, 'y').toDate()}
                errorMessage={errors.startDate?.message}
              />
              <CustomSelect
                placeholder='Policy'
                name='policy'
                control={control}
                options={PreferencePolicyTypes}
                errorMessage={errors.policy?.message}
                tooltipText='Choose "Office" if you prefer workers solely in the Office. Opt for "Hybrid" is you would like to negotiate a mix of remote and office work. Select "Work From Home" if you exclusively want Remote Workers. All signifies openness to any work arrangement based on mutual agreement.'
              />
              <CustomSelect
                placeholder='Number of relevant experience'
                name='numberOfExperience'
                control={control}
                options={NumberOfExperience}
                errorMessage={errors.numberOfExperience?.message}
                tooltipText='In general, a "Junior" is someone with less than three years of experience, while a "Senior" role is someone with over five years of experience.'
              />
              <CustomMultiSelect
                placeholder='Select visa type'
                name='visaType'
                control={control}
                isMulti={true}
                options={VisaTypes}
                errorMessage={errors.visaType?.message}
                tooltipText='Select what visa you need, let us connect the hierarchy. i.e Permanent Resident will also bring back Citizens.'
              />
              <Controller
                name='skill'
                control={control}
                render={({ field }) => (
                  <CustomTagsInput
                    {...field}
                    name='skill'
                    placeholder='Skills'
                    onChange={(value: any) => {
                      return field.onChange(value);
                    }}
                    value={field.value ? field.value : []}
                    control={control}
                    errorMessage={errors.skill?.message}
                  />
                )}
              /> */}