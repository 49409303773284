import React, { useState, useEffect } from 'react';
import ChatList from './ChatList/ChatList';
import ChatWindow from './ChatWindow/ChatWindow';
import { Container, Row, Col } from 'reactstrap';
import ErrorBoundary from './ErrorHandling/ErrorBoundary';
import classes from './styles.module.scss';
import clsx from 'clsx';
import { useWebSocket } from 'services/Message/User/WebSocketProvider';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ChatAppService } from '../../services/ChatAppService';
import { ReducerType } from 'redux/reducers';
import { ImageService } from "services/Image";

const ChatApp = () => {
  // const { sendMessage, webSocket, receivedMessage, unreadMessages  } = useWebSocket();
  // const { sendMessage, webSocket, receivedMessage  } = useWebSocket();
  const { user } = useSelector((state) => state.user);
  const [room, setRoom] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [isChatListVisible, setIsChatListVisible] = useState(true);
  const [messageInfo, setMessageInfo] = useState(null); // add this state
  const [reloadChat, setReloadChat] = useState(false);


  const { 
    webSocket, 
    sendMessage, 
    receivedMessage, 
    unreadMessages, 
    hasUnreadMessages,
    clearUnreadMessages
  } = useWebSocket();


  const handleBackListMobile = () => {
    if (window.innerWidth <= 576) {
      setIsChatListVisible(true);
    }
  };

  // useEffect(() => {
  //   console.log("Unread Messages:", unreadMessages); // add this
  // }, [unreadMessages]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 576) {
        setIsChatListVisible(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChatSelection = (user) => {
    setRoom(user);
    if (window.innerWidth <= 576) {
      setIsChatListVisible(false);
    }
  };

  useEffect(() => {
    // console.log("Rooms:", rooms);
    //console.log("Message Info:", messageInfo);
  }, [rooms, messageInfo]);

  useEffect(() => {
    if (receivedMessage) {
      if (receivedMessage?.type === 'rooms') {
        const roomsPromises = receivedMessage.rooms.map(async (room) => {
          const { receiverId, userId } = room;
          //.log("IDs:", receiverId, userId);
          if (receiverId && userId) {
            const messageInfo = await ChatAppService.getMessageInfo(userId, receiverId);
            const info = messageInfo[0] || {};
            try {
              let employerAvatarUrl, candidateAvatarUrl;
              // console.log("Employer avatar ID:", info.employer_identity);
              // console.log("Candidate avatar ID:", info.candidate_identity);
              // console.log("Employer avatar:", info.candidate_avatar);
              // console.log("Candidate avatar:", info.employer_avatar);
              if (userId === 1105 || receiverId === 1105) {
               
                if (receiverId === 1105) {
                  candidateAvatarUrl = await ImageService.getImage("1106-avatar", "ap-southeast-2:601abe41-8cfd-46d1-af07-7d216545189a");
                  info.candidate_name = "Nelly Necta";
                  info.candidate_title = "AI Assistant";
                  info.employer_company = "Necta";
                } else
                employerAvatarUrl = await ImageService.getImage("1106-avatar", "ap-southeast-2:601abe41-8cfd-46d1-af07-7d216545189a");
                  info.employer_name = "Nelly Necta";
                  info.employer_job_title = "AI Assistant";
                  info.employer_company = "Necta";
            } else {
                employerAvatarUrl = await ImageService.getImage(info.employer_avatar, info.employer_identity);
                candidateAvatarUrl = await ImageService.getImage(info.candidate_avatar, info.candidate_identity);
            }
              
              const updatedMessageInfo = { ...info, employerAvatarUrl, candidateAvatarUrl };
              return { ...room, messageInfo: [updatedMessageInfo] };
            } catch(err) {
              console.error('Error fetching avatars:', err);
            }
          } else {
            return Promise.resolve(room);
          }
        });
  
        Promise.all(roomsPromises)
          .then(updatedRooms => {
            // Sort the rooms based on the latest message's created date
            const sortedRooms = sortRooms(updatedRooms);
            setRooms(sortedRooms);

            // Set the first room as the default active room if no room is already active
            if (!room && sortedRooms.length > 0) {
              setRoom(sortedRooms[0]);
            }
          })
          .catch(err => console.error('Error updating rooms:', err));

      } else if (receivedMessage?.type === 'message') {
          if (!receivedMessage.created) {
              receivedMessage.created = new Date().toISOString();
          }
        const chatRoom = _.find(rooms, (r) => r.id === receivedMessage.roomId);
        if (chatRoom) {
          const updatedChatRoom = { ...chatRoom };
          updatedChatRoom.messages.push(receivedMessage);
  
          // Find the index of the updated room in the original array
          const roomIndex = rooms.findIndex(room => room.id === chatRoom.id);
  
          // Replace the old room with the updated room in the array
          const updatedRooms = [...rooms];
          updatedRooms[roomIndex] = updatedChatRoom;
  
          // Re-sort the rooms
          const sortedRooms = sortRooms(updatedRooms);
  
          // Update state with the new, sorted rooms
          setRooms(sortedRooms);
          // console.log('Sorted rooms:', sortedRooms);
  
          // Also update the current room if the new message is in the current room
          if (room && room.id === updatedChatRoom.id) {
            setRoom(updatedChatRoom);
          }
        }
      } else if (receivedMessage?.type === 'updatedMessage') {
        const chatRoom = _.find(rooms, (r) => r.id === receivedMessage.roomId);
        if (chatRoom) {
          // Find the message that needs to be updated
          const messageIndex = chatRoom.messages.findIndex(message => message.id === receivedMessage.id);
          if (messageIndex !== -1) {
            // Replace the old message with the updated message in the messages array
            const updatedMessages = [...chatRoom.messages];
            updatedMessages[messageIndex] = receivedMessage;
            
            const updatedChatRoom = { ...chatRoom, messages: updatedMessages };
            
            // Find the index of the updated room in the original array
            const roomIndex = rooms.findIndex(room => room.id === chatRoom.id);
            
            // Replace the old room with the updated room in the array
            const updatedRooms = [...rooms];
            updatedRooms[roomIndex] = updatedChatRoom;
            
            // Re-sort the rooms
            const sortedRooms = sortRooms(updatedRooms);
            
            // Update state with the new, sorted rooms
            setRooms(sortedRooms);
            
            // Also update the current room if the updated message is in the current room
            if (room && room.id === updatedChatRoom.id) {
              setRoom(updatedChatRoom);
            }
          }
        }
      } else {
        console.log('unknown data type');
      }
    }
  }, [receivedMessage]);
  
  const sortRooms = (rooms) => {
    return rooms.sort((roomA, roomB) => {
      // Get the timestamp of the latest message in roomA
      let dateA = roomA.messages.length > 0 
        ? new Date(roomA.messages[roomA.messages.length - 1].created).getTime() 
        : 0;
  
      // If there are no messages in roomA, use the room's creation timestamp instead
      if (dateA === 0) {
        dateA = new Date(roomA.created).getTime();
      }
  
      // Do the same for roomB
      let dateB = roomB.messages.length > 0 
        ? new Date(roomB.messages[roomB.messages.length - 1].created).getTime() 
        : 0;
  
      if (dateB === 0) {
        dateB = new Date(roomB.created).getTime();
      }
  
      // Sort in descending order so the most recent rooms appear at the top
      return dateB - dateA;
    });
  };
  
  
  

  
  

  



  // on initial render fetch the historical messages
useEffect(() => {
  if (webSocket && webSocket.readyState === WebSocket.OPEN && user?.id) {
    webSocket.send(
      JSON.stringify({
        action: 'getChatHistory',
        userId: user?.id,
      })
    );
  } else {
    // Retry after 1 second if WebSocket connection is not open
    const timeoutId = setTimeout(() => {
      if (user?.id) {
        webSocket.send(
          JSON.stringify({
            action: 'getChatHistory',
            userId: user?.id,
          })
        );
      }
    }, 1000);
    // Cleanup timeout on unmount
    return () => clearTimeout(timeoutId);
  }
}, [webSocket, user?.id, reloadChat]);

  const handleReloadChat = () => {
    setReloadChat(prev => !prev);
  };

  const handleSendMessage = (userId, receiverId, roomId, message) => {
    if (webSocket) {
      webSocket.send(
        JSON.stringify({
          action: 'sendPrivate',
          data: {
            userId: userId,
            receiverId: receiverId,
            message: message,
            roomId: roomId, // Add roomId to the data
          },
        })
      );
    }
  };

  return (
    <ErrorBoundary>
      <Container className={classes.chatWrapper} fluid>
        <Row>
          {(isChatListVisible || window.innerWidth > 576) && (
            <Col className={clsx(classes.chatList, 'chatList')}>
              <ChatList
                user={user}
                rooms={rooms}
                activeRoom={room}
                onSelectChatRoom={(selectedRoom) => {
                  setRoom(selectedRoom);
                  setIsChatListVisible(false);
                }}
                messageInfo={messageInfo}
              />
            </Col>
          )}
          {room && (!isChatListVisible || window.innerWidth > 576) && (
            <Col className={clsx(classes.chatWindow, 'chatWindow')}>
              <ChatWindow
                handleBackListMobile={handleBackListMobile}
                room={room}
                classes="ce-chat-list-column"
                user={user}
                onSendMessage={handleSendMessage}
              />
            </Col>
          )}
        </Row>
      </Container>
    </ErrorBoundary>
  );
};

export default ChatApp;
