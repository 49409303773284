import React, { memo, useState, useEffect, ChangeEvent } from "react";
import classes from "./styles.module.scss";
import { FormGroup, Input, InputProps, Label, Tooltip } from "reactstrap";
import { FieldError, FieldErrors, Merge } from "react-hook-form";

// Omit onChange from InputProps
interface Props extends Omit<InputProps, 'onChange'> {
  className?: string;
  content?: React.ReactNode;
  inputRef?: any;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrors<any>>;
  firstLabel?: string;  // new prop for the false label
  secondLabel?: string; 
  tooltipText?: string;
  checked?: boolean;  // new prop for the true label
  onChange?: (checked: boolean) => void;
}

const CustomCheckboxFlip = memo(
  ({ className, content, inputRef, errorMessage, firstLabel, secondLabel, tooltipText, checked, onChange, ...rest }: Props) => {
    
    const [isChecked, setIsChecked] = useState(checked);

    // Use useEffect to update the local state when the checked prop changes
    useEffect(() => {
      // console.log(firstLabel, checked)
      setIsChecked(checked);
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.target.checked);
      if (onChange) {
        onChange(e.target.checked);
      }
    };
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const { ref: refInput, ...inputProps } = inputRef || { ref: null };
    const uniqueId = `CustomCheckboxFlip-${firstLabel}-${secondLabel}`;
    return (
      <FormGroup check className={className}>

        <Label check className={classes.checkboxWrapper}>

          <Input
            {...inputProps}
            type="checkbox"
            checked={isChecked}
            innerRef={refInput}
            {...rest}
            onChange={handleChange}  // Attach the handler
          />
          <span>{firstLabel || 'False'}</span>  
          <span>{secondLabel || 'True'}</span>  
        </Label>
        {content}
        <span className="text-danger text-left ml-1 mt-1 d-block">
          {errorMessage}
          {/* {tooltipText && (
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={uniqueId} // Use the unique ID here
            toggle={toggleTooltip}
          >
            {tooltipText}
          </Tooltip>
        )} */}
        </span>

      </FormGroup>
    );
  }
);

export default CustomCheckboxFlip;

