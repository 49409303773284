import { Auth } from "aws-amplify";

class AmplifyUserService {
  /**
   * register user
   * @param {*} email
   * @param {*} password
   */
  async register(email, password, type) {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // optional
          "custom:role": type,
          // other custom attributes
        },
      });
      return user;
    } catch (error) {
      throw error;
    }
  }

  /**
   * send email confirmation
   * @param {*} username
   * @param {*} code
   */
  async confirm(username, code) {
    try {
      console.log(code);
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      throw error;
    }
  }

  /**
   * resend confirmation code with amlify
   * @param {*} username
   */
  async resendConfimationCode(username) {
    try {
      await Auth.resendSignUp(username);
      // console.log("code resent successfully");
    } catch (error) {
      throw error;
    }
  }

  /**
   * forgot password code send
   * @param {*} username
   */
  async forgotPassword(username) {
    try {
      const result = await Auth.forgotPassword(username);
      return result;
    } catch (error) {
      throw error;
    }
  }

  /**
   * new password submition
   * @param {*} username
   * @param {*} code
   * @param {*} new_password
   */
  async forgotPasswordSubmit(username, code, new_password) {
    try {
      const result = await Auth.forgotPasswordSubmit(
        username,
        code,
        new_password
      );
      return result;
    } catch (error) {
      throw error;
    }
  }
}

const amplifyUserService = new AmplifyUserService();

export default amplifyUserService;
