import { memo, useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import classes from "./styles.module.scss";

interface Props {
  education: {
    degreeName: string;
    schoolName: string;
    endDate: string;
    country: string;
  }[];
}

// eslint-disable-next-line no-empty-pattern
const Education = memo(({ education }: Props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(education?.sort(sortDataByDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [education.length]);

  const sortDataByDate = (first, second) =>
    Number(Date.parse(second?.endDate)) - Number(Date.parse(first?.endDate));

  return (
    <>
      <div className={clsx("section", classes.bgDark)}>
        <Container className="position-relative">
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="6">
              <h2
                className={clsx("title text-center pt-0 pb-3", classes.title)}
              >
                Education {"&"} Qualification
              </h2>
            </Col>
          </Row>
          <Row>
            {data?.map((item, index) => (
              <Col md="6" xs="12" key={index}>
                <Card className={clsx("p-4", classes.card)}>
                  <CardBody className={classes.cardbody}>
                    <h3>{item?.degreeName}</h3>
                    <p>
                      <span className={classes.emphasizeColor}>Provider:</span>{" "}
                      {item?.schoolName}
                    </p>
                    <p>
                      <span className={classes.emphasizeColor}>
                        Finished Date:
                      </span>{" "}
                      {item?.endDateIsPresent
                        ? 'Currently studying'
                        : moment(item?.endDate).format('DD/MM/YYYY')}
                    </p>
                    <p>
                      <span className={classes.emphasizeColor}>Country:</span>{" "}
                      {item?.country}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
});

export default Education;
