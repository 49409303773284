import { memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Card, CardBody, Container, Row, Col, Button } from 'reactstrap';
import classes from './styles.module.scss';
import EditEducation from './EditEducation';
import { EducationService } from 'services/Contractor/NewMember/Education';
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from 'redux/reducers';
import { setProfileReducer } from 'redux/reducers/Contractor/actionTypes';

interface Props {}

// eslint-disable-next-line no-empty-pattern
const Education = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const [data, setData] = useState([]);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  useEffect(() => {
    if (!!profile?.education?.length) {
      setData([...profile.education]?.sort(sortDataByDate));
    }
  }, [dispatch, profile]);

  const sortDataByDate = (first, second) =>
    Number(Date.parse(second?.endDate)) - Number(Date.parse(first?.endDate));

  const handleToggle = () => setIsOpenEdit(!isOpenEdit);

  const handleUpdateEducation = (dataSubmit, removeList, handleResetForm) => {
    dispatch(setLoading(true));
    if (!profile?.education?.length) {
      EducationService.postEducation(dataSubmit)
        .then((res) => {
          handleResetForm && handleResetForm();
          dispatch(setProfileReducer({ ...profile, education: res }));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    } else {
      if (removeList.length > 0) {
        Promise.all([
          EducationService.putEducation(dataSubmit),
          EducationService.deleteEducation({ ids: removeList }),
        ])
          .then((res) => {
            handleResetForm && handleResetForm();
            dispatch(setProfileReducer({ ...profile, education: res[0] }));
          })
          .catch((e) => dispatch(setErrorMess(e)))
          .finally(() => dispatch(setLoading(false)));
      } else {
        EducationService.putEducation(dataSubmit)
          .then((res) => {
            handleResetForm && handleResetForm();
            dispatch(setProfileReducer({ ...profile, education: res }));
          })
          .catch((e) => dispatch(setErrorMess(e)))
          .finally(() => dispatch(setLoading(false)));
      }
    }
  };

  return (
    <>
      <div className={clsx('section', classes.bgDark)}>
        <Container className='position-relative'>
          <Button
            className={clsx(
              'btn-icon btn-round',
              classes.editBtnDefault,
              classes.editBtn
            )}
            onClick={handleToggle}
          >
            <i className='fas fa-edit' />
          </Button>
          <EditEducation
            onClose={handleToggle}
            isOpen={isOpenEdit}
            data={data}
            handleUpdateEducation={handleUpdateEducation}
          />
          <Row>
            <Col className='ml-auto mr-auto' md='10' xl='6'>
              <h2
                className={clsx('title text-center pt-0 pb-3', classes.title)}
              >
                Education {'&'} Qualification
              </h2>
            </Col>
          </Row>
          <Row>
            {data?.map((item, index) => (
              <Col md='6' xs='12' key={index}>
                <Card className={clsx('p-4', classes.card)}>
                  <CardBody className={classes.cardbody}>
                    <h3>{item?.degreeName}</h3>
                    <p>
                      <span className={classes.emphasizeColor}>Provider:</span>{' '}
                      {item?.schoolName}
                    </p>
                    <p>
                      <span className={classes.emphasizeColor}>
                        Finished Date:
                      </span>{' '}
                      {item?.endDateIsPresent
                        ? 'Currently studying'
                        : moment(item?.endDate).format('DD/MM/YYYY')}
                    </p>
                    <p>
                      <span className={classes.emphasizeColor}>Country:</span>{' '}
                      {item?.country}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
});

export default Education;
