import { API } from "aws-amplify";
import { TaxParams } from "models/Contractor/NewMember/Tax";

export class TaxService {
  static async postTax(data: TaxParams): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/contractortaxdetails";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getTax(): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/contractortaxdetails";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putTax(data: TaxParams, id: number): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractortaxdetails/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  
  static async sendWithholdingTaxForm (id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${id}/withholdingtaxform`;
    const myInit = {
      response: true,
      body: data
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
