import { API } from "aws-amplify";

export class SkillService {
  static async postSkill(data: string[]) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/skillset";
    const myInit = {
      response: true,
      body: {
        skillList: data,
      },
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getSkill() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/skillset";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putSkill(data: string[], id: number) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/skillset/${id}`;
    const myInit = {
      response: true,
      body: {
        id: id,
        skillList: data,
      },
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
