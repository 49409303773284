import { memo, useEffect, useMemo } from "react";
import { Form, Button, Card, CardBody, Modal } from "reactstrap";
import ImageUpload from "components/CustomUpload";
import classes from "./styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/Common/CustomInput";
import CustomTextarea from "components/CustomTextarea";
import { ImageService } from "services/Image";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { ReducerType } from "redux/reducers";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  data: any;
  handleUpdateBasicProfile?: (data) => void;
}

interface BasicProfileFormData {
  firstName: string;
  lastName: string;
  professionalTitle: string;
  currentCompany: string;
  linkedinUrl?: string;
  aboutYou: string;
  avatar?: File;
}

const EditBasicProfile = memo(
  ({ onClose, isOpen, data, handleUpdateBasicProfile }: Props) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: ReducerType) => state.user);

    const schema = useMemo(() => {
      return yup.object().shape({
        avatar: yup.mixed(),
        firstName: yup
          .string()
          .min(2, "At least 2 characters")
          .max(64, "At least 64 characters")
          .required("This field is required"),
        lastName: yup
          .string()
          .min(2, "At least 2 characters")
          .max(64, "At least 64 characters")
          .required("This field is required"),
        professionalTitle: yup
          .string()
          .min(2, "At least 2 characters")
          .max(64, "At least 64 characters")
          .required("This field is required"),
        currentCompany: yup
          .string()
          .typeError("This field is required")
          .required("This field is required"),
        linkedinUrl: yup.string().nullable(),
        aboutYou: yup
          .string()
          .typeError("This field is required")
          .required("This field is required"),
      });
    }, []);

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      control,
    } = useForm<BasicProfileFormData>({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

    useEffect(() => {
      if (data) {
        reset({
          firstName: data?.firstName,
          lastName: data?.lastName,
          professionalTitle: data?.title,
          currentCompany: data?.currentCompany,
          linkedinUrl: data?.linkedinUrl,
          aboutYou: data?.about,
        });
      }
    }, [data, reset]);

    useEffect(() => {
      if (data?.photoUrl && user) {
        ImageService.getImageBlob(data?.photoUrl)
          .then((res) => {
            setValue("avatar", blobToFile(res as Blob, "avatar.png"));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      }
    }, [data, dispatch, setValue, user]);

    const blobToFile = (theBlob: Blob, fileName: string): File => {
      return new File([theBlob as any], fileName, {
        lastModified: new Date().getTime(),
        type: theBlob.type,
      });
    };

    const onSubmit = (data: BasicProfileFormData) => {
      dispatch(setLoading(true));
      ImageService.postImage(
        data?.avatar,
        `${user?.id}-${S3_UPLOAD_TYPE_PRIFIX.AVATAR}`
      )
        .then((res) => {
          handleUpdateBasicProfile({
            firstName: data?.firstName,
            lastName: data?.lastName,
            title: data?.professionalTitle,
            currentCompany: data?.currentCompany,
            about: data?.aboutYou,
            photoFile: data?.avatar,
            photoUrl: res,
            linkedInUrl: data?.linkedinUrl,
          });
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => {
          onClose();
          dispatch(setLoading(false));
        });
    };

    return (
      <Card>
        <Modal isOpen={isOpen} toggle={onClose} centered scrollable>
          <CardBody className={classes.cardBody}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <p className={classes.title}>Edit Basic Profile</p>
              <div className={classes.content}>
                <Controller
                  name="avatar"
                  control={control}
                  render={({ field }) => (
                    <ImageUpload
                      avatar={!data?.photoUrl}
                      value={field?.value}
                      onChange={(file) => field?.onChange(file)}
                      isDisabled={false}
                    />
                  )}
                />
                <CustomInput
                  placeholder="First Name"
                  type="text"
                  autoComplete="off"
                  inputRef={register("firstName")}
                  errorMessage={errors.firstName?.message}
                />
                <CustomInput
                  placeholder="Last Name"
                  type="text"
                  autoComplete="off"
                  inputRef={register("lastName")}
                  errorMessage={errors.lastName?.message}
                />
                <CustomInput
                  placeholder="Job Title"
                  type="text"
                  autoComplete="off"
                  inputRef={register("professionalTitle")}
                  errorMessage={errors.professionalTitle?.message}
                />
                <CustomInput
                  placeholder="Current Company"
                  type="text"
                  autoComplete="off"
                  inputRef={register("currentCompany")}
                  errorMessage={errors.currentCompany?.message}
                />
                <CustomInput
                  placeholder="LinkedIn URL"
                  type="text"
                  autoComplete="off"
                  inputRef={register("linkedinUrl")}
                  errorMessage={errors.linkedinUrl?.message}
                />
                <CustomTextarea
                  className={classes.textarea}
                  placeholder="Write a Professional Summary"
                  autoComplete="off"
                  inputRef={register("aboutYou")}
                  errorMessage={errors.aboutYou?.message}
                />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  size="md"
                  color="default"
                  type="button"
                  className={classes.btn}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  size="md"
                  color="primary"
                  type="submit"
                  className={classes.btn}
                >
                  Ok
                </Button>
              </div>
            </Form>
          </CardBody>
        </Modal>
      </Card>
    );
  }
);

export default EditBasicProfile;
