export enum IVisaTypes {
  NZ_AUS_CITIZEN = "NZAUSCitizen",
  NZ_PERMANENT_RESIDENT = "NZPermanentVisa",
  NZ_WORK_VISA = "NZWorkVisa",
  OTHER = "Other",
}

export const VisaTypes = [
  { id: 1, name: "Citizen", value: "NZAUSCitizen" },
  { id: 2, name: "Permanent Resident", value: "NZPermanentVisa" },
  { id: 3, name: "Work Visa", value: "NZWorkVisa" },
  { id: 4, name: "Other", value: "Other" },
];
