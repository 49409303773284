import { strict as assert } from 'assert';
import constants from '../../Constants';
import amplifyUserService from '../userService/factories/AmplifyUserService';
import strapiUserService from '../userService/factories/StrapiUserService';

class UserFactory {

    constructor() {
        this.registry= new Map();
    }

    register(type, entityImpl) {
        assert(this.registry.has(type) === false, "type already registered");
        this.registry.set(type, entityImpl);
    }

    generate(type) {
        assert(this.registry.has(type || process.env.REACT_APP_AUTH_TYPE) === true, "type not registered");
        return this.registry.get(type || process.env.REACT_APP_AUTH_TYPE);
    }
}

const userFactory = new UserFactory();
userFactory.register(constants.AuthType.AMPLIFY, amplifyUserService);
userFactory.register(constants.AuthType.STRAPI, strapiUserService);

export default userFactory;