/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, memo } from "react";
import { FormGroup, Input, InputProps } from "reactstrap";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { FieldError, FieldErrors, Merge } from "react-hook-form";

let autoComplete: any;

const loadScript = (url: string, callback: any) => {
  let script: any = document.createElement("script");
  script.setAttribute("id", "googleapis");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const handleScriptLoad = (onChange: any, autoCompleteRef: any) => {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["(regions)"] }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () => handlePlaceSelect(onChange));
};

const handlePlaceSelect = async (onChange: any) => {
  const addressObject = autoComplete.getPlace();
  const latitude = addressObject?.geometry?.location.lat();
  const longitude = addressObject?.geometry?.location.lng();
  const address = seperateAddress(addressObject);
  onChange({
    address,
    latitude,
    longitude,
  });
};

const seperateAddress = (addressObject: any) => {
  const { address_components, formatted_address, value, ...rest } =
    addressObject;
  let country: string,
    region: string,
    city1: string,
    city2: string,
    city3: string;
  address_components?.forEach((item: any) => {
    if (item.types.includes("country")) {
      country = item.long_name;
    }
    if (item.types.includes("administrative_area_level_1")) {
      region = item.long_name;
    }
    if (item.types.includes("administrative_area_level_2")) {
      city1 = item.long_name;
    } else if (item.types.includes("sublocality_level_1")) {
      city2 = item.long_name;
    } else if (item.types.includes("locality")) {
      city3 = item.long_name;
    }
  });
  const city = city1 || city2 || city3 || "";

  return {
    country,
    city,
    region,
    formattedAddress: `${city}, ${region}, ${country}`,
  };
};

interface Props extends InputProps {
  className?: string;
  placeholder?: any;
  onChange?: (value: any) => void;
  value?: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrors<any>>;
}

const SearchLocationInput = memo(
  ({
    className,
    placeholder,
    onChange,
    value,
    errorMessage,
    ...props
  }: Props) => {
    const autoCompleteRef = useRef(null);
    const [valueShow, setValueShow] = useState("");

    useEffect(() => {
      setValueShow(value || "");
    }, [value]);

    useEffect(() => {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        () => handleScriptLoad(onChange, autoCompleteRef)
      );
    }, []);

    return (
      <FormGroup
        className={clsx(
          classes.root,
          { "has-danger": !!errorMessage },
          className
        )}
      >
        <Input
          autoComplete="off"
          className={clsx({ "form-control-danger": !!errorMessage })}
          innerRef={autoCompleteRef}
          placeholder={placeholder}
          value={valueShow}
          onChange={(e) => setValueShow(e.target.value)}
          onFocus={() => {
            loadScript(
              `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`,
              () => handleScriptLoad(onChange, autoCompleteRef)
            );
          }}
          onBlur={() => {
            const googleApisNode = document.getElementById("googleapis");
            if (googleApisNode) {
              document
                .getElementsByTagName("head")[0]
                .removeChild(googleApisNode);
            }
          }}
          {...props}
        />
        {errorMessage && (
          <span className="text-danger ml-2 mt-1 d-block">{errorMessage}</span>
        )}
      </FormGroup>
    );
  }
);

export default SearchLocationInput;
