import { memo, useEffect, useState } from "react";
import clsx from "clsx";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import classes from "./styles.module.scss";

interface Props {
  experience: {
    title: string,
    company: string,
    startDate: string,
    endDateIsPresent: boolean,
    endDate: string,
    summary: string,
    responsibilities: string
  }[];
  testimonials: any;
}

// eslint-disable-next-line no-empty-pattern
const MyExperience = memo(({ experience, testimonials }: Props) => {
  const [data, setData] = useState([]);
  const [iconTabs, setIconTabs] = useState([]);

  useEffect(() => {
    setData(experience?.sort(sortOrder).reverse())
  }, [experience.length]);

  useEffect(() => {
    let arr = [];
    data.map((_, index) => {
      arr.push(`${index} Main`);
    });
    setIconTabs(arr);
  }, [data]);

  const formatStartDate = (date) => {
    const newDate = new Date(date);
    const month = newDate.toLocaleString("en-US", { month: "long" });
    const year = newDate.getFullYear();
    return `${month} ${year}`;
  };

  const datediff = (startDate, endDate, endDateIsPresent) => {
    const newStartDate = new Date(startDate);
    let newEndDate;
    if (endDateIsPresent) {
      newEndDate = new Date();
    } else {
      newEndDate = new Date(endDate);
    }
    const totalNumberOfMonths =
      newEndDate.getMonth() -
      newStartDate.getMonth() +
      12 * (newEndDate.getFullYear() - newStartDate.getFullYear());

    const numberOfMonths = totalNumberOfMonths % 12;
    const numberOfYears = Math.floor(totalNumberOfMonths / 12);

    if (numberOfYears === 0) {
      if (numberOfMonths <= 1) return `${numberOfMonths} month`;
      else return `${numberOfMonths} months`;
    } else if (numberOfYears === 1) {
      if (numberOfMonths === 0) return `1 year`;
      else if (numberOfMonths === 1) return `1 year 1 month`;
      else return `1 year ${numberOfMonths} months`;
    } else {
      if (numberOfMonths === 0) return `${numberOfYears} years`;
      else if (numberOfMonths === 1) return `${numberOfYears} years 1 month`;
      else return `${numberOfYears} years ${numberOfMonths} months`;
    }
  };

  const sortOrder = (first, second) => {
    if (first?.endDateIsPresent && second?.endDateIsPresent) {
      if (first?.startDate > second?.startDate) return 1;
      if (first?.startDate < second?.startDate) return -1;
      return 0;
    }
    else if (first?.endDateIsPresent) {
      return 1;
    }
    else if (second?.endDateIsPresent) {
      return -1;
    }
    else {
      if (first?.endDate > second?.endDate) return 1;
      if (first?.endDate < second?.endDate) return -1;
      return 0;
    }
  };

  const renderTestimonial = (id) => {
    const testimonial = testimonials?.find((tesItem) => id === tesItem?.experience.id && tesItem?.response);
    if (!testimonials) return null;

    return ({
      providersTitle: testimonial?.providersTitle,
      response: testimonial?.response,
      name: testimonial?.name,
      company: testimonial?.experience?.company
    })
  }

  return (
    <>
      <div className="section">
        <Container className="position-relative">
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="6">
              <h2 className="title text-center pt-0 pb-3">My Experience</h2>
            </Col>
          </Row>
          <Row>
            {data?.map((item, index) => {
              return (
                <Col className="ml-auto mr-auto" md="10" xl="6" key={index}>
                  <Card className="p-4">
                    <h3 className="category mb-4">{item.title}</h3>
                    <CardHeader>
                      <Nav
                        className={clsx("nav-pills-primary justify-content-center", classes.navExItems)}
                        pills
                      >
                        <NavItem className={classes.navExItem}>
                          <NavLink
                            className={
                              iconTabs[index] === `${index} Main`
                                ? "active"
                                : ""
                            }
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              const result = [...iconTabs];
                              result[index] = `${index} Main`;
                              setIconTabs(result);
                            }}
                          >
                            Main
                          </NavLink>
                        </NavItem>
                        <NavItem className={classes.navExItem}>
                          <NavLink
                            className={
                              iconTabs[index] === `${index} Summary`
                                ? "active"
                                : ""
                            }
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              const result = [...iconTabs];
                              result[index] = `${index} Summary`;
                              setIconTabs(result);
                            }}
                          >
                            Summary
                          </NavLink>
                        </NavItem>
                        <NavItem className={classes.navExItem}>
                          <NavLink
                            className={
                              iconTabs[index] === `${index} Responsibilities`
                                ? "active"
                                : ""
                            }
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              const result = [...iconTabs];
                              result[index] = `${index} Responsibilities`;
                              setIconTabs(result);
                            }}
                          >
                            Responsibilities
                          </NavLink>
                        </NavItem>
                        {
                          renderTestimonial(item?.id)?.response ? (
                            <NavItem className={classes.navExItem}>
                              <NavLink
                                className={
                                  iconTabs[index] === `${index} Testimonials`
                                    ? "active"
                                    : ""
                                }
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const result = [...iconTabs];
                                  result[index] = `${index} Testimonials`;
                                  setIconTabs(result);
                                }}
                              >
                                Testimonials
                              </NavLink>
                            </NavItem>
                          ) : ""
                        }
                      </Nav>
                    </CardHeader>
                    <CardBody>
                      <TabContent
                        className="text-left"
                        activeTab={iconTabs[index]}
                      >
                        <TabPane
                          className={classes.main}
                          tabId={`${index} Main`}
                        >
                          <ul>
                            <li>{item?.company}</li>
                            <li>
                              {formatStartDate(item?.startDate)} until{" "}
                              {item?.endDateIsPresent
                                ? "present"
                                : formatStartDate(item?.endDate)}
                            </li>
                            <li>{item?.country}</li>
                            <li>{datediff(item?.startDate, item?.endDate, item?.endDateIsPresent)}</li>
                          </ul>
                        </TabPane>
                        <TabPane
                          className={classes?.summary}
                          tabId={`${index} Summary`}
                        >
                          <p>{item?.summary}</p>
                        </TabPane>
                        <TabPane
                          className={classes.responsibility}
                          tabId={`${index} Responsibilities`}
                        >
                          <p>{item?.responsibilities}</p>
                        </TabPane>
                        <TabPane
                          className={classes.responsibility}
                          tabId={`${index} Testimonials`}
                        >
                          <ul className={classes.commonTitle}>
                            {
                              testimonials.map((tesItem) => {
                                if (tesItem?.experience?.id === item?.id) {
                                  return (
                                    <li>
                                      <h1>
                                        {tesItem?.name}, {tesItem?.providersTitle} - {tesItem?.experience?.company}
                                      </h1>
                                      <h5>
                                        {tesItem?.response}
                                      </h5>
                                    </li>
                                  )
                                }
                              })
                            }
                          </ul>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
});

export default MyExperience;
