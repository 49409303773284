import { put, takeLatest, all, call } from "redux-saga/effects";
import { IAllOffers } from "redux/reducers/Contractor";
import {
  GET_ALL_OFFERS_REQUEST,
  setAllOffersReducer,
} from "redux/reducers/Contractor/actionTypes";
import {
  setOffersStillExist,
  setOffersToConnectStillExist,
  setOffersToInterviewStillExist,
  setContractualOffersStillExist,
} from "redux/reducers/Status/actionTypes";
import { JobOfferService } from "services/Contractor/Offer/Offer";
import { JobService } from 'services/Employer/Listing/Job';

function* requestGetAllOffers(data: { type: string; contractorId: number }) {
  try {
    const [connectOffers, interviewOffers, jobOffers, jobs, apply] = yield all([
      call(JobOfferService.getAllOffers, data?.contractorId),
      call(JobOfferService.getAllInterviewOffers, data?.contractorId),
      call(JobOfferService.getAllContractJobOffers, data?.contractorId),
      call(JobOfferService.getAllOffersJobs, data?.contractorId),
      call(JobService.getJobAll),
    ]);
    let isOffersToConnectStillExist = false;
    let isOffersToInterviewStillExist = false;
    let isContractualOffersStillExist = false;
    connectOffers?.forEach((item) => {
      if (!item?.accepted && !item?.rejected) {
        isOffersToConnectStillExist = true;
      }
    });
    interviewOffers?.forEach((item) => {
      if (!item?.accepted && !item?.rejected) {
        isOffersToInterviewStillExist = true;
      }
    });
    jobOffers?.forEach((item) => {
      if (!item?.accepted && !item?.rejected) {
        isContractualOffersStillExist = true;
      }
    });
    const allOffers: IAllOffers = {
      connectOffers: connectOffers || [],
      interviewOffers: interviewOffers || [],
      jobOffers: jobOffers || [],
      jobs: jobs || [],
      apply: apply || [],
    };
    yield put(setAllOffersReducer(allOffers));
    yield put(setOffersToConnectStillExist(isOffersToConnectStillExist));
    yield put(setOffersToInterviewStillExist(isOffersToInterviewStillExist));
    yield put(setContractualOffersStillExist(isContractualOffersStillExist));
    if (
      isOffersToConnectStillExist ||
      isOffersToInterviewStillExist ||
      isContractualOffersStillExist
    ) {
      yield put(setOffersStillExist(true));
    } else {
      yield put(setOffersStillExist(false));
    }
  } catch (e: any) {
    console.log(e);
  }
}

function* getAllOffers() {
  yield takeLatest(GET_ALL_OFFERS_REQUEST, requestGetAllOffers);
}

export default getAllOffers;
