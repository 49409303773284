import { memo, useEffect, useMemo, useState } from 'react';
import { Form, Button, Card, CardBody } from 'reactstrap';
import CustomInput from 'components/Common/CustomInput';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import classes from './styles.module.scss';
import routes from 'routers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  setLoading,
  setErrorMess,
  setWelcome,
} from 'redux/reducers/Status/actionTypes';
import { TestimonialService } from 'services/Contractor/NewMember/Testimonial';
import { ReducerType } from 'redux/reducers';
import { setProfileReducer } from 'redux/reducers/Contractor/actionTypes';
import CustomSelect from 'components/Common/CustomSelect';
// import { EmailTypes } from "models/Employer/Listings/Dashboard";

interface Props {}

interface TestimonialFormData {
  testimonialsList?: {
    id?: number;
    name?: string;
    title?: string;
    email?: string;
    providersTitle?: string;
    experience?: {
      id?: number;
      company?: string;
    };
  }[];
}

// eslint-disable-next-line no-empty-pattern
const Testimonial = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: ReducerType) => state.contractor);
  // const { user } = useSelector((state: ReducerType) => state.user);
  const [deleteItems, setDeleteItem] = useState([]);

  const schema = useMemo(() => {
    return yup.object().shape({
      testimonialsList: yup.array().of(
        yup.object().shape({
          name: yup.string().max(100, 'At least 100 characters'),
          title: yup.string().max(200, 'At least 200 characters'),
          email: yup.string().email('Please enter a valid email'),
          experience: yup.object(),
        })
      ),
    });
  }, []);

  const handleSkip = () => {
    reset({
      testimonialsList: [{}],
    });
    dispatch(push(routes.contractor.profile));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<TestimonialFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'testimonialsList',
    keyName: 'fieldID',
  });

  useEffect(() => {
    append({});
  }, [append]);

  useEffect(() => {
    if (!!profile?.testimonial?.length) {
      reset({
        testimonialsList: profile.testimonial.map((item: any) => ({
          id: item?.id,
          name: item?.name,
          email: item?.email,
          title: item?.providersTitle,
          experience: item?.experience,
        })),
      });
    }
  }, [reset, profile]);

  const handleDeleteItem = (id: number, index: number) => {
    remove(index);
    if (id) {
      setDeleteItem([...deleteItems, id]);
    }
  };

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.visa));
  };

  const onSubmit = async (data: TestimonialFormData) => {
    dispatch(setLoading(true));
    const testimonialParams = data?.testimonialsList
      ?.map((item) => {
        return {
          id: item?.id,
          name: item?.name,
          email: item?.email,
          providersTitle: item?.title,
          experience: item?.experience?.id,
        };
      })
      .filter(
        (item) =>
          item?.name && item?.email && item?.providersTitle && item?.experience
      );
    if (deleteItems.length > 0) {
      await TestimonialService.deleteTestimonial({
        ids: deleteItems,
      }).catch((e) => dispatch(setErrorMess(e)));
    }
    TestimonialService.putTestimonial(testimonialParams)
      .then((res) => {
        // if (res?.length) {
        //   res.map((item) => {
        //     TestimonialService.requestTestimonialEmail(user?.id, item?.id, {
        //       type: EmailTypes.TESTIMONIAL_REQUEST,
        //     })
        //       .catch((e) => dispatch(setErrorMess(e)))
        //       .finally(() => {
        //         dispatch(setWelcome(true));
        //         dispatch(setProfileReducer({ ...profile, testimonial: res }));
        //         dispatch(push(routes.contractor.profile));
        //       });
        //     return null;
        //   });
        // } else {
        //   dispatch(setWelcome(true));
        //   dispatch(setProfileReducer({ ...profile, testimonial: res }));
        //   dispatch(push(routes.contractor.profile));
        // }
        dispatch(setWelcome(true));
        dispatch(setProfileReducer({ ...profile, testimonial: res }));
        dispatch(push(routes.contractor.profile));
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <Card className={classes.card}>
      <CardBody className={classes.cardBody}>
        <div className={classes.titleContainer}>
          <p className={classes.title}>Testimonials</p>
          <p className={classes.subTitle}>
            We will email and gather your testimonials only once!
          </p>
          <p className={classes.subTitle}>
            These Testimonial Requests will be stored in your settings page,
            where you can add additional requests and send the official email
            request at any time.
          </p>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.content}>
            {fields?.map((field: any, index) => (
              <div key={field?.fieldID}>
                {fields?.length > 1 && (
                  <div className={classes.btnDelete}>
                    <Button onClick={() => handleDeleteItem(field?.id, index)}>
                      <i className='fa fa-trash' />
                    </Button>
                  </div>
                )}
                <CustomInput
                  startIcon={<i className='fa fa-user' />}
                  placeholder='Name...'
                  type='text'
                  autoComplete='off'
                  inputRef={register(`testimonialsList.${index}.name`)}
                  errorMessage={
                    errors.testimonialsList &&
                    errors.testimonialsList[index]?.name?.message
                  }
                />
                <CustomInput
                  startIcon={<i className='fa fa-text-height' />}
                  placeholder='Title...'
                  type='text'
                  autoComplete='off'
                  inputRef={register(`testimonialsList.${index}.title`)}
                  errorMessage={
                    errors.testimonialsList &&
                    errors.testimonialsList[index]?.title?.message
                  }
                />
                <CustomInput
                  startIcon={<i className='fa fa-at' />}
                  placeholder='Email...'
                  type='email'
                  autoComplete='off'
                  inputRef={register(`testimonialsList.${index}.email`)}
                  errorMessage={
                    errors.testimonialsList &&
                    errors.testimonialsList[index]?.email?.message
                  }
                />
                <CustomSelect
                  className={classes.commonSelect}
                  placeholder="Testimonial provider's company"
                  name={`testimonialsList.${index}.experience`}
                  bindLabel={'company'}
                  control={control}
                  options={
                    profile?.experience?.length ? profile?.experience : []
                  }
                  errorMessage={
                    errors.testimonialsList &&
                    errors.testimonialsList[index]?.experience?.message
                  }
                />
                {index !== fields.length - 1 && (
                  <hr className={classes.divider} />
                )}
              </div>
            ))}
          </div>
          <div className={classes.buttonContainer}>
            <Button color='default' type='button' onClick={handleBack}>
              Back
            </Button>
            <Button
              type='button'
              className={classes.btnAddMore}
              onClick={() => append({})}
            >
              <span className='text-dark'>
                Add more
                <i className='fa fa-plus ml-2' />
              </span>
            </Button>
            <Button
              // color='primary'
              onClick={handleSkip}
              type='submit'
              style={{ backgroundColor: '#f2e822', color: 'black' }}
            >
              Skip
            </Button>
            <Button color='primary' type='submit'>
              Next
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
});

export default Testimonial;
