module.exports = {
    UserTypes: {
        EMPLOYEE: 'employee',
        EMPLOYER: 'employer'
    },
    AuthProviders: {
        GOOGLE: 'google'
    },
    AuthType: {
        AMPLIFY: 'amplify',
        STRAPI: 'strapi'
    }
};

