import { useEffect, useRef } from 'react';
import _ from 'lodash';
import UserInfo from '../UserInfo/UserInfo';
import Message from '../Message/Message';
import ChatInput from '../ChatInput/ChatInput';
import { ListGroup, ListGroupItem } from 'reactstrap';
import classes from '../styles.module.scss';



const ChatWindow = ({ user, room, onSendMessage, handleBackListMobile }) => {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(scrollToBottom, [room, room.messages]);
  // console.log('Rendering ChatWindow', { user, room });
  return (
    <>
      {/* <div>Chat Window</div> This line is just for debugging */}
      <div className={classes.userInfoContainer}>
        <UserInfo
          user={user}
          handleBackListMobile={handleBackListMobile}
          messageInfo={room.messageInfo && room.messageInfo[0]}
        />
      </div>
          <ListGroup className={classes.chatListContainer}>
            {room &&
              room.messages &&
              _.map(room.messages, (chatMessage) => {
                // console.log("its here" +JSON.stringify(chatMessage.userId))
                return (
                  <ListGroupItem
                    className={classes.chatListItem}
                    key={chatMessage.id}
                  >
                    <Message
                      message={{
                        content: chatMessage.message,
                        created: chatMessage.created,
                        userId: chatMessage.userId,
                        read: chatMessage.read,
                      }}
                      user={user}
                      info={room.messageInfo && room.messageInfo[0]} // Pass the specific messageInfo for this room
                    />
                  </ListGroupItem>
                );
              })}
            <div ref={messagesEndRef} />
          </ListGroup>
   
      <div className='inputContainer'>
        <ChatInput
          userId={user && user.id}
          roomId={room && room.id}
          receiverId={
            user && room && user.id === room.userId
              ? room.receiverId
              : room.userId
          }
          onSendMessage={onSendMessage}
        />
      </div>
    </>
  );
};

export default ChatWindow;
