import React, { Fragment, memo, useEffect, useState } from 'react';
import { Button, Container, Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import classes from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes';
import { JobService } from 'services/Employer/Listing/Job';
import { ReducerType } from 'redux/reducers';
import AddNewJob from './AddEditJob';
import UploadJobDescriptionModal from './AddJobDescription';
import { useModal } from 'components/Modals';
import {
  setCandidatesReducer,
  setDeclinedCandidatesReducer,
  setJobListReducer,
  setJobOfListingReducer,
  getAllCandidateList,
} from 'redux/reducers/Employer/actionTypes';
import {
  getJobTemplatesList, getSetting
} from "redux/reducers/Employer/actionTypes";
import { ImageService } from 'services/Image';


interface DashboardProps {}

const Myjobs = memo((props: DashboardProps) => {
  const dispatch = useDispatch();
  const { jobTemplatesList, interviewOffers, jobOffers } = useSelector((state: ReducerType) => state.employer);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [parsedJobData, setParsedJobData] = useState(null);
  const [isAddMode, setIsAddMode] = useState(false);
  const toggleUploadModal = () => setUploadModalOpen(!isUploadModalOpen);

  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  const [data, setData] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [jobToEdit, setJobToEdit] = useState(null);
  const [isProject, setIsProject] = useState(false); 
  const [showSignTermModal, setShowSignTermModal] = useState(false);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [parsedJobDataReady, setParsedJobDataReady] = useState(false); // New state
  const [file, setFile] = useState(null);
  

  const handleFileParsed = (parsedData, file) => {
    setParsedJobData(parsedData);  // Store parsed data
    setFile(file);  // Store file separately
    setUploadModalOpen(false);  // Close upload modal
    setIsAddMode(true);  // Open AddNewJob modal
  
    // Delay setting parsedJobDataReady until modal is open
    setTimeout(() => setParsedJobDataReady(true), 100); // This ensures that the data is processed after the modal is opened
  };

  useEffect(() => {
    if (!isAddMode) {
      setParsedJobDataReady(false); // Reset the ready flag when modal is closed
    }
  }, [isAddMode]);

  
  useEffect(() => {
    if (jobTemplatesList && jobTemplatesList.length > 0) {
      // console.log("settings", setting)
      const filteredJobs = jobTemplatesList
        .filter(item => !item.isArchived)
        .sort((a, b) => b.id - a.id)
        .map(job => {
          const interviewCount = interviewOffers.filter(offer => offer.job === job.id).length;
          const contractCount = jobOffers.filter(offer => offer.job === job.id).length;
          return {
            ...job,
            interview: interviewCount,
            contract: contractCount
          };
        });
      setData(filteredJobs);
      setJobsLoaded(true);
    }
  }, [jobTemplatesList, interviewOffers, jobOffers]);
  
  const handleUpgrade = ({ data }) => {
    const jobId = data.id;
  
    if (setting?.terms) {
      // console.log("This is the jobId we are sending", jobId);
      openUpgrade({ jobId: jobId }); // Pass the jobId directly
    } else {
      setCurrentJobId(jobId); // Set the state
      // console.log("setting current job to", jobId);
      setShowSignTermModal(true);
    }
  };

  const { onOpen: openUpgrade } = useModal('Payment');
  const handleAcceptTerm = async () => {
    // console.log("after accepted what is it", currentJobId);
    setShowSignTermModal(false);
    openUpgrade({ jobId: currentJobId }); // Ensure the correct value of currentJobId is passed
  };
  const handleShowSignTermModal = () => {
    setShowSignTermModal(false);
    handleClose()
  };
  
  const handleClose = async () => {
    try {
      dispatch(setLoading(true));
      await JobService.putJob({ listings: false }, currentJobId);
      dispatch(setLoading(false));
      dispatch(getJobTemplatesList());
    } catch (error) {
      dispatch(setErrorMess("There was an error cancelling the job listing."));
      dispatch(setLoading(false));
    }
  };

  const handleAddJob = async (data, file = null) => {
    dispatch(setLoading(true));
  
    const organization = setting?.idOrganisation;
    const updatedData = { ...data, organization }; // Add organization to the data object
    // console.log("updatedData", updatedData);
  
    try {
      // Step 2: Create the job template
      const res = await JobService.postJobTemplates(updatedData);
      const jobId = res.data?.id; // Extract the newly created job's ID from the response
      console.log("file", file, jobId)
      if (file && jobId) {
        // Step 3: Upload the file to S3 using the job ID
        const fileExtension = file.name.split('.').pop();
        const fileName = `${organization}-${jobId}.${parsedJobData.name}.${fileExtension}`;
        const s3Response = await ImageService.postJobDescription(file, fileName);
  
        console.log('Uploaded file key:', s3Response);
      }
  
      // Refresh job templates list
      dispatch(getJobTemplatesList());
      dispatch(getSetting());
    } catch (error) {
      console.error("Error creating job or uploading file:", error);
      dispatch(setErrorMess("There was an error creating the job or uploading the file."));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleUpdateJob = (data, id) => {
    dispatch(setLoading(true));
    // console.log("data", data)
    // console.log("id", id)
    JobService.putJobTemplates(data, id)
      .then(async (res) => {
        // If you need to do something with the response, you can do it here.
        dispatch(getJobTemplatesList());
        dispatch(getSetting());
      })
      .catch((error) => {
        // Handle the error appropriately (e.g., show a notification, log it, etc.)
        console.error("Error updating job:", error);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  

  const triggerGitHubAction = async () => {
    const githubApiUrl = 'https://api.github.com/repos/Surge-NZ/necta/dispatches';
    const token = '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';

    const payload = {
      event_type: 'listings',
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/vnd.github.v3+json',
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(githubApiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('GitHub Actions trigger failed');
      }
    } catch (error) {
      console.error('Error triggering GitHub Actions:', error);
    }
  };


  const handleCloseClient = () => {
    setIsClient(false);
  };

  const handleOpenAddProjectForm = (item) => {
    setIsProject(true);
    setIsClient(false);
  };

  const handleCloseProject = () => {
    setIsProject(false);
  };
  

  const getAllCandidates = async (job, isRedirectToListingJob, assist) => {
    if (!job?.id) return;
  
    dispatch(setLoading(true));
  
    const weights = {
      title: 10,
      description: 20,
      responsibilities: 40,
      technical_skills: 30,
    };
  
    try {
      const response = await fetch('https://j9z0sb36kk.execute-api.ap-southeast-2.amazonaws.com/prod/headhunter/rankings', {
        method: 'POST',
        body: JSON.stringify({
          job_id: String(job.id),
          weights: weights,
          request_type: isRedirectToListingJob ? "next_ten" : "next_ten",
          request_typeNumber: 0,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const json = await response.json();
  
      // Check if the response body is a valid JSON
      let res;
      try {
        res = JSON.parse(json.body);
        // console.log(res)
      } catch (err) {
        return;
      }
  
      if (!res || res.length === 0) {
        return; // Stop further execution if no candidates are found
      }
      
      // Send offers to all candidates found in the API response
      for (const candidate of res) {
        const adjustedScore = String(Math.min(candidate.scores * 4, 0.99));
        const dataSubmit = {
          job: job.id,
          contractor: candidate.user,
          employer: user?.id,
          offered: false,
          scores: adjustedScore,
        };
  
        try {
          await JobService.postJobOffer(dataSubmit);
        } catch (err) {
          console.error('Error sending job offer:', err);
          dispatch(setErrorMess('Error sending job offer.'));
        }
      }
      dispatch(getAllCandidateList());
    } catch (error) {
      console.error('Error fetching candidates:', error);
      dispatch(setErrorMess('Error fetching candidates.'));
    } finally {
      dispatch(setLoading(false));
    }
  };
  
  

  useEffect(() => {
    if (jobsLoaded) {
      const fetchAllOffers = async () => {
        // dispatch(setLoading(true));
        try {
          const [offersResponse, otherOffersResponse] = await Promise.all([
            JobService.getAllOffers(),
            JobService.getAllOffersOther()
          ]);

          if (!offersResponse || !otherOffersResponse) {
            throw new Error('Invalid response from API');
          }
 
          const combinedOffers = {
            interviews: [
              ...(offersResponse.interviews || []),
              ...(otherOffersResponse.interviews || [])
            ],
            jobOffers: [
              ...(offersResponse.jobOffers || []),
              ...(otherOffersResponse.jobOffers || [])
            ]
          };

          setData(prevData => {
            if (!prevData || prevData.length === 0) {
              return prevData;
            }

            const updatedData = prevData.map(job => {
              const jobId = job.id ? job.id.toString() : '';

              if (!jobId) {
                return job;
              }

              const jobInterviews = combinedOffers.interviews.filter(offer => offer.job && offer.job.toString() === jobId);
              const jobOffersOnly = combinedOffers.jobOffers.filter(offer => offer.job && offer.job.toString() === jobId);
              const candidatesOfJob = offersResponse.concat(otherOffersResponse).filter(offer => offer.job && offer.job.toString() === jobId);

              return {
                ...job,
                candidatesOfJob: candidatesOfJob,
                jobOffers: jobOffersOnly,
                interviews: jobInterviews,
              };
            });

            return updatedData;
          });
        } catch (error) {
          console.error('Error fetching all offers:', error);
          dispatch(setErrorMess('Error fetching all offers.'));
        } finally {
          // dispatch(setLoading(false));
        }
      };

      fetchAllOffers();
    }
  }, [jobsLoaded]);

  const formatNumber = (num) => {
    if (num == null) return '0';
    if (num >= 1000 && num < 10000) {
      return (num / 1000).toFixed(1) + 'k';
    } else if (num >= 10000) {
      return Math.floor(num / 1000) + 'k';
    }
    return num.toString();
  };

  const handleEditClick = (job) => {
    setJobToEdit(job);
    setIsEditMode(true);
    setIsAddMode(false);
    setIsProject(false);
    setIsClient(false);
  };

  const handleAddClick = () => {
    setJobToEdit(null);  // Clear job to edit
    setParsedJobData(null);  // Clear parsed job data
    setIsEditMode(false);  // Ensure edit mode is off
    setIsAddMode(true);  // Enable add mode
    setIsProject(false);
    setIsClient(false);
  };

  const handleCloseEdit = () => {
    setJobToEdit(null);
    setIsEditMode(false);
    setIsAddMode(false);
    
  };

  const JobCard = ({ job }) => {
  
    const formatSalaryOrRate = (salary, rate) => {
      if (salary) {
        return `$${salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per annum`;
      } else if (rate) {
        return `$${rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per hour`;
      }
      return '';
    };
  

    return (
      <div className={classes.jobContainer}>
        <div className={classes.jobHeader}>
          <div className={classes.jobInfo}>
            <div className={classes.jobTitle}>{job?.title}</div>
            <div className={classes.jobSubTitle}>
              {formatSalaryOrRate(job?.maxSalary, job?.maxRate)} | {job?.city} {job?.country}
            </div>
            <div 
              className={classes.jobSubTitle}
              dangerouslySetInnerHTML={{ __html: job?.description.slice(0, 400) + (job?.description.length > 400 ? "..." : "") }}
            ></div>
          <div className={classes.jobSubTitle}>
                    {Array.isArray(job?.technicalSkills) && job?.technicalSkills.length > 0 && (
            job?.technicalSkills.map((skill, index) => (
              <span key={index} className={classes.skillLabel}>
                {skill}
              </span>
            ))
          )}
          </div>
          </div>
          <div className={classes.jobDetails}>
            <div>
              <div className={classes.candidateCircle2}>{job?.experienceYears === 7 ? "Senior" : job?.experienceYears === 6 ? "Intermediate" : "Junior"}</div>
              <div>Seniority</div>
            </div>
            <div>
              <div className={classes.candidateCircle2}>{job?.workPolicy === "office" ? "Office" : job?.workPolicy === "mixed" ? "Hybrid" : job?.workPolicy === "workFromHome" ? "Remote" : "All" }</div>
              <div>Work Policy</div>
            </div>
            <div>
              <div className={classes.candidateCircle2}>{job?.isPermanent ? "Permanent" : job?.isFixedTerm ? "Fixed" : "Contract" }</div>
              <div>Job Type</div>
            </div>
          </div>
        </div>
        <div className={classes.buttons}>

          <Button color="secondary" onClick={() => handleEditClick(job)}>Edit</Button>
        </div>
      </div>
    );
  };
  

  return (
    <Fragment>
      <Container className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.title}>Job Description Templates</div>
          <p>
            Streamline your workflow by creating, uploading, and storing your company's job descriptions in one place. Leverage job templates to quickly generate job postings during recruitment or to assign roles during the onboarding process.
          </p>
          <div className={classes.buttons}>
            
          <Button color="secondary" size="sm" onClick={toggleUploadModal} className={classes.btnContent}>
              Upload Job Description
            </Button>
            <Button color="primary" onClick={handleAddClick}>Add New</Button>
          </div>
        </div> 

        {

          isEditMode || isAddMode ? (
              <AddNewJob
                isOpen={isEditMode || isAddMode}
                onClose={handleCloseEdit}
                itemEdit={isEditMode ? jobToEdit : null}
                parsedJobData={parsedJobData}
                file={file}
                handleAddJob={(data) => {
                  handleAddJob(data);
                  handleCloseEdit();
                }}
                handleUpdateJob={(data, id) => {
                  handleUpdateJob(data, id);
                  handleCloseEdit();
                }}
              />
          ) : (
            data.map((job) => (
              <JobCard key={job.id} job={job} />
            ))
          )

        
      }
        <UploadJobDescriptionModal
          isOpen={isUploadModalOpen}
          toggle={toggleUploadModal}
          organizationId={setting?.idOrganisation}
          onFileParsed={handleFileParsed}  // Pass organization ID
        />
      </Container>
    </Fragment>
  );
});

export default Myjobs;
