export const JobTypes = [
  { id: 1, name: "Contract" },
  { id: 2, name: "Permanent" },
  { id: 3, name: "Fixed Term" },
];

export const NumberOfExperience = [
  { id: 1, name: "Junior", value: 3 },
  { id: 2, name: "Intermediate", value: 6 },
  { id: 3, name: "Senior", value: 7 },
];

export const TypesOfInterview = [
  { id: 1, name: "In-person", value: "inPerson" },
  { id: 2, name: "Online", value: "online" },
];

export const EmailTypes = {
  NEW_CHAT_FOR_CONTRACTOR: "newChatForContractor",
  NEW_CHAT_FOR_EMPLOYER: "newChatForEmployer",
  NEW_CONNECT_OFFER_FOR_CONTRACTOR: "newConnectOfferForContractor",
  NEW_INTERVIEW_OFFER_FOR_CONTRACTOR: "newInterviewOfferForContractor",
  NEW_JOB_OFFER_FOR_CONTRACTOR: "newJobOfferForContractor",
  WELCOME_EMAIL_FOR_CONTRACTOR: "welcomeEmailForContractor",
  WELCOME_AND_RESET_PASSWORD_FOR_USER: 'welcomeAndResetPasswordForUser',
  WELCOME_EMAIL_FOR_EMPLOYER: "welcomeEmailForEmployer",
  CONTRACTOR_CONNECT_OFFER_ACCEPTED: "contractorConnectOfferAccepted",
  CONTRACTOR_INTERVIEW_OFFER_ACCEPTED: "contractorInterviewOfferAccepted",
  CONTRACTOR_JOB_OFFER_ACCEPTED: "contractorJobOfferAccepted",
  CONTRACTOR_JOB_OFFER_DECLINED: 'contractorJobOfferDeclined',
  CONTRACTOR_INACTIVE_SIGN_UP: "contractorInactiveSignUp",
  EMPLOYER_INACTIVE_SIGN_UP: "employerInactiveSignUp",
  TESTIMONIAL_REQUEST: "testimonialRequest",
  INTERVIEW_OFFER_DETAILS_FOR_EMPLOYER: "interviewOfferDetailsForEmployer",
  INTERVIEW_OFFER_DETAILS_FOR_CONTRACTOR: "interviewOfferDetailsForContractor",
  CONTRACTOR_WITHHOLDING_TAX_FORM: 'contractorWithholdingTaxForm',
  EMPLOYER_TIMESHEET_AWAITING: 'employerTimesheetAwaiting',
  TERMINATE_IMMEDIATE_OFFER: 'terminateImmediateOffer',
  TERMINATE_NOTICE_OFFER: 'terminateNoticeOffer',
  TIMESHEET_APPROVER_INVITE: 'timesheetApproverInvite',
  CONTRACTOR_TERMINATE_NOTICE_OFFER: 'contractorTerminateNoticeOffer',
  EXTEND_JOB_OFFER_CONTRACTOR: 'extendJobOfferContractor',
  CONTRACTOR_INTERVIEW_OFFER_DECLINED: 'contractorInterviewOfferDeclined'
};
