import { all } from "redux-saga/effects";
//import getAllChats from "./getAllChats";
import getAllTimesheets from "./getAllTimesheets";
import getInterviewOffers from "./getInterviewOffers";
import getJobList from "./getJobList";
import getJobClientList from "./getJobClientList";
import getJobOffers from "./getJobOffers";
import getSetting from "./getSetting";
import getTalentList from "./getTalentList";
import getAllTimesheetsApprover from "./getAllTimesheetsApprover";
import getAllCandidatesList from "./getAllCandidateList";
import getAllClientCandidateList from "./getAllClientCandidateList"
import getClientInterviewOffers from "./getClientInterviewOffers"
import getJobTemplatesList from "./getJobTemplatesList"

export const employerSagas = function* root() {
  // yield all([
  //   getSetting(),
  //   getJobList(),
  //   getJobOffers(),
  //   getAllTimesheets(),
  //   getAllChats(),
  //   getTalentList(),
  //   getInterviewOffers(),
  //   getAllTimesheetsApprover()
  // ]);
  yield all([
    getSetting(),
    getJobList(),
    getJobClientList(),
    getJobOffers(),
    getAllTimesheets(),
    getAllCandidatesList(),
    getTalentList(),
    getInterviewOffers(),
    getClientInterviewOffers(),
    getAllTimesheetsApprover(),
    getAllClientCandidateList(),
    getJobTemplatesList()
  ]);
};
