import { Card, CardBody, Row, Col, Collapse, Button  } from 'reactstrap';
import classes from './styles.module.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Controller } from 'react-hook-form';
import CustomInput from 'components/Common/CustomInput';
import SearchLocationInput from 'components/Common/GoogleAddress';
import CustomSelect from 'components/Common/CustomSelect';
import CustomSlider from 'components/Common/CustomSlider';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import CustomCheckboxFlip from 'components/Common/CustomCheckBoxFlip';
import CustomMultiSelect from 'components/Common/CustomMultiSelect';
import CustomTagsInput from 'components/Common/CustomTagsInput';
import { JobTypes, NumberOfExperience } from 'models/Employer/Listings/Dashboard';
import { PreferencePolicyTypes } from 'models/Contractor/NewMember/Preference';
import { VisaTypes } from 'models/Contractor/NewMember/MojCheck';
import moment from 'moment';
import CustomHtml from 'components/CustomHtml';

const ReviewComponent = ({ data, watch, setValue, itemEdit, onListingsChange }) => {


  const [openIndexes, setOpenIndexes] = useState([]);
  const isListings = watch('listings');


  const handleListingsChange = (isChecked) => {
    setValue('listings', !isChecked); // This flips true/false between Draft and Live
    onListingsChange(!isChecked); // Notify the parent about the change
  };
  // Toggle a specific index
  const toggleDropdown = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index)); // Close it
    } else {
      setOpenIndexes([...openIndexes, index]); // Open it
    }
  };
  // console.log(data)
  return (
    <Card className={classes.card}>
      <CardBody>
        {/* <h3 className={classes.sectionTitle}>Job Details</h3> */}
        <Row style={{ margin: '4px' }}>
        <CustomCheckboxFlip
            firstLabel='Draft'
            secondLabel='Live'
            onChange={(isChecked) => {
              handleListingsChange(!isChecked);
            }}
            checked={isListings}
          />
          <p style={{ 
            color: 'black',
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'flex-start',
            fontWeight:'400', 
            fontSize: '1.0em',
            marginTop: '12px'  
          }}>
            {isListings 
              ? "Live allows applications and recruiter copilot to activate."
              : "Draft allows you to make changes before pushing live."
            }
          </p>
        </Row>
        <Row>
          <Col md={6}>
            <strong>Title:</strong> {data.title}
          </Col>
          <Col md={6}>
            <strong>Company:</strong> {data.company}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <strong>Job Type:</strong> {data.jobType?.name || 'N/A'}
          </Col>
          <Col md={6}>
            <strong>Hourly Rate/Salary:</strong> {data.hourlyRate || data.maxSalary || 'N/A'}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <strong>Start Date:</strong> {data.startDate ? new Date(data.startDate).toLocaleDateString() : 'N/A'}
          </Col>
          <Col md={6}>
            <strong>Policy:</strong> {data.policy?.name || 'N/A'}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <strong>Experience:</strong> {data.numberOfExperience?.name || 'N/A'}
          </Col>
          <Col md={6}>
            <strong>Address:</strong> {`${data.city}, ${data.region}, ${data.country}`}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <strong>Skills:</strong> {data.skill?.join(', ') || 'N/A'}
          </Col>
        </Row>
    <br></br>
        <Row>
          <Col md={12}>
            <strong>Description:</strong>
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <strong>Responsibilities:</strong>
            <div dangerouslySetInnerHTML={{ __html: data.responsibilities }} />
          </Col>
        </Row>

        {data.outreach?.map((platform, index) => (
        <Row key={index} className={classes.outreachRow}>
          <Col md={12}>
            <div className={classes.platformHeader}>
              <strong>{platform.name}</strong>
              {/* If no content, show a checkmark */}
              {!platform.content ? (
                <FontAwesomeIcon icon={faCheck} className={classes.iconCheck}  />
              ) : (
                <Button
                  color="link"
                  onClick={() => toggleDropdown(index)}
                  className={classes.dropdownButton}
                >
                  {openIndexes.includes(index) ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </Button>
              )}
            </div>

            {/* Dropdown content for platforms with content */}
            <Collapse isOpen={openIndexes.includes(index)}>
              <div className={classes.dropdownContent}>
                <p>{platform.content}</p>
              </div>
            </Collapse>
          </Col>
        </Row>
        ))}
        <br></br>

        {data.questions?.map((question, index) => (
          <Row key={index}>
            <Col md={12}>
              <strong>Question {index + 1}:</strong> {question.question} (Type: {question.type})
            </Col>
          </Row>
        ))}
        <br></br>

        {data.jobBoards?.map((board, index) => (
        <Row key={index}>
          <Col md={12} className={classes.boardRow}>
            <strong>{board.name}:</strong> 
            <FontAwesomeIcon 
              icon={board.agreement ? faCheck : faTimes} 
              className={board.agreement ? classes.iconCheck : classes.iconCross} 
            /> 
          </Col>
        </Row>
      ))}


      </CardBody>
    </Card>
  );
};

export default ReviewComponent;
