import { memo, useMemo, useEffect } from "react";
import { Form, Button, Row, CardHeader, Card } from "reactstrap";
import CustomInput from "components/Common/CustomInput";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { ContactProfileService } from "services/Contractor/NewMember/ContactProfile";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import SearchLocationInput from "components/Common/GoogleAddress";
import { ReducerType } from "redux/reducers";
//import { UtilChatEngine } from "utils/chatEngine";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";

interface Props {}

interface ContactProfileFormData {
  id?: number;
  phoneNumber: string;
  countryCode: string;
  city?: string;
  country?: string;
  region?: string;
  address?: string;
}

// eslint-disable-next-line no-empty-pattern
const ContactProfile = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const schema = useMemo(() => {
    return yup.object().shape({
      id: yup.number(),
      phoneNumber: yup
        .string(),
        // .typeError("This field is required")
        // .required("This field is required"),
      countryCode: yup.string().nullable(),
      address: yup
      .string()
      .typeError("This field is required")
      .required("This field is required"),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<ContactProfileFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (profile?.contact) {
      reset({
        id: profile.contact.id,
        phoneNumber: profile.contact.mobile,
        countryCode: profile.contact.countryCode,
        address:
          profile.contact.region && profile.contact.country
            ? profile.contact?.city
              ? `${profile.contact?.city}, ${profile.contact?.region}, ${profile.contact?.country}`
              : `${profile.contact.region}, ${profile.contact.country}`
            : "",
      });
    }
  }, [profile, reset]);

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.visa));
  };

  const onSubmit = async (data: ContactProfileFormData) => {
    dispatch(setLoading(true));
    //console.log("got to here" + data?.id)
    if (data?.id) {
      await ContactProfileService.putContactProfile(
        {
          countryCode: data?.countryCode,
          mobile: data?.phoneNumber,
          city: data?.city,
          country: data?.country,
          region: data?.region,
        },
        data?.id
      )
      
        .then((res) => {
          
          // UtilChatEngine.updateUserChatEngine(dispatch, user, {
          //   city: data?.city,
          //   country: data?.country,
          // });
          dispatch(setProfileReducer({ ...profile, contact: res }));
          // dispatch(push(routes.contractor.newMember.education));
          dispatch(push(routes.contractor.profile)); 
        })
        .catch((err) => dispatch(setErrorMess(err)))
        .finally(() => dispatch(setLoading(false)));
    } else {
      await ContactProfileService.postContactProfile({
        countryCode: "+64",
        mobile: data?.phoneNumber,
        city: data?.city,
        country: data?.country,
        region: data?.region,
      })
        .then((res) => {
          // UtilChatEngine.updateUserChatEngine(dispatch, user, {
          //   city: data?.city,
          //   country: data?.country,
          // });
          dispatch(setProfileReducer({ ...profile, contact: res }));
          // dispatch(push(routes.contractor.newMember.education));
          dispatch(push(routes.contractor.profile)); 
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    }
  };

  return (
    <Card className={classes.card}>
      <CardHeader>
        <p className={classes.title}>Contact Profile</p>
      </CardHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.content}>
          <CustomInput
            placeholder="Phone Number"
            type="text"
            autoComplete="off"
            inputRef={register("phoneNumber")}
            errorMessage={errors.phoneNumber?.message}
          />
          <CustomInput
            placeholder="Country Code"
            type="text"
            autoComplete="off"
            inputRef={register("countryCode")}
            errorMessage={errors.countryCode?.message}
            className="d-none"
          />
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <SearchLocationInput
                {...field}
                ref={null}
                placeholder="Your nearest town or city"
                errorMessage={errors.address?.message}
                
                value={field.value}
                onChange={(value: any) => {
                  setValue("region", value?.address?.region);
                  setValue("city", value?.address?.city);
                  setValue("country", value?.address?.country);
                  return field.onChange(value?.address?.formattedAddress);
                }}
              />
            )}
          />
        </div>
        <Row className="justify-content-between p-0 m-0">
          <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            size="md"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Next
          </Button>
        </Row>
      </Form>
    </Card>
  );
});

export default ContactProfile;
