import produce from "immer";
import * as types from "./actionTypes";

export interface IUser {
  id: number;
  username: string;
  email: string;
  setupCompleted: boolean;
  profileCompleted: boolean;
  educationCompleted: boolean;
  experienceCompleted: boolean;
  preferenceCompleted: boolean;
  skillCompleted: boolean;
  role: number;
  type: string;
  chatUserId: number;
  chatSecret: string;
  avatar: string;
}

export interface IUserVerification {
  username: string;
}

export interface UserState {
  user: IUser;
  userVerification: IUserVerification;
}

const initial: UserState = {
  user: null,
  userVerification: null,
};

export const userReducer = (state = initial, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_USER_REDUCER:
        draft.user = action?.data;
        break;
      case types.SET_USER_VERIFICATION_REDUCER:
        draft.userVerification = action?.data;
        break;
      case types.SET_USER_LOGOUT_REDUCER:
        draft.user = null;
        break;
      case types.SET_USER_AVATAR_REDUCER:
        draft.user.avatar = action?.data;
        break;
      default:
        return state;
    }
  });
