import { Fragment, memo } from "react";
import { Button, Card, Container, Table } from "reactstrap";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import classes from "./styles.module.scss";
import clsx from "clsx";
import FooterMobile from "components/Footers/FooterMobile";

interface Props {}

const Payslip = memo((props: Props) => {
  const invoiceList = [];

  // const invoiceList = [
  //   {
  //     payNumber: "PAYNB1",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  //   {
  //     payNumber: "PAYNB2",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  //   {
  //     payNumber: "PAYNB3",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  //   {
  //     payNumber: "PAYNB4",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  //   {
  //     payNumber: "PAYNB5",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  //   {
  //     payNumber: "PAYNB6",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  //   {
  //     payNumber: "PAYNB7",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  //   {
  //     payNumber: "PAYNB8",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  //   {
  //     payNumber: "PAYNB9",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  //   {
  //     payNumber: "PAYNB10",
  //     date: "01/06/2022",
  //     paye: "$1,500.00",
  //     net: "$1,000.00",
  //     totalPay: "$1,200.00",
  //   },
  // ];

  const handleDownloadPayslip = (payNumber) => {
    console.log(payNumber);
  };

  return (
    <Fragment>
      <ScrollNavbar />
      <div className="page-header header-filter" filter-color="yellow">
        <div className={classes.header} />
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg32.jpg") + ")",
          }}
        />
        <div className={clsx("content", classes.content)}>
          <Container className={classes.container}>
            <Card className={classes.card}>
              <p className={classes.title}>PAYSLIPS</p>
              <div className={classes.tableContainer}>
                <Table className={classes.table} responsive>
                  <thead>
                    <tr>
                      <th>Pay Number</th>
                      <th>Date</th>
                      <th className={classes.mobileHideTable}>Paye</th>
                      <th className={classes.mobileHideTable}>Net</th>
                      <th>Total Pay</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceList.map((item) => (
                      <tr key={item.payNumber}>
                        <th scope="row">{item.payNumber}</th>
                        <td>{item.date}</td>
                        <td className={classes.mobileHideTable}>{item.paye}</td>
                        <td className={classes.mobileHideTable}>{item.net}</td>
                        <td>{item.totalPay}</td>
                        <td>
                          <Button
                            color="info"
                            onClick={() =>
                              handleDownloadPayslip(item.payNumber)
                            }
                          >
                            Download PDF
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Container>
        </div>
        <Footer />
      </div>
      <FooterMobile />
    </Fragment>
  );
});

export default Payslip;
