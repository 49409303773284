import { put, takeLatest, all, call } from "redux-saga/effects";
import { setLoading } from "redux/reducers/Status/actionTypes";
import {
  GET_ALL_INTERVIEWS_EMPLOYER_REQUEST,
  setInterviewOffersReducer,
} from "redux/reducers/Employer/actionTypes";
import { JobService } from "services/Employer/Manage/Job";

function* requestGetInterviewOffers() {
  try {
    yield put(setLoading(true));
    const [interviewOffers] = yield all([call(JobService.getAllInterviewOfferOfAEmployer)]);
    yield put(setInterviewOffersReducer(interviewOffers));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getInterviewOffers() {
  yield takeLatest(GET_ALL_INTERVIEWS_EMPLOYER_REQUEST, requestGetInterviewOffers);
}

export default getInterviewOffers;
