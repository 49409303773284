import React from 'react';

function SendMessage(props) {
	const title = props.title || "send message";
	const { height = "64", width = "64" } = props;

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className="SendMessage">
			<title>{title}</title>
			<g fill="#e86c42">
				<path d="M61.707,2.293c-0.273-0.275-0.683-0.365-1.048-0.233l-58,21c-0.378,0.137-0.638,0.488-0.658,0.89 c-0.02,0.402,0.203,0.777,0.566,0.952l20.886,10.025l18.866-13.945c0.462-0.341,1.041,0.238,0.699,0.699L29.074,40.547 l10.025,20.886C39.266,61.78,39.617,62,40,62c0.017,0,0.033,0,0.05-0.001c0.402-0.021,0.753-0.28,0.891-0.658l21-58 C62.072,2.976,61.981,2.567,61.707,2.293z" fill="#e86c42"/>
			</g>
		</svg>
	);
};

export default SendMessage;
