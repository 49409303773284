import { API } from "aws-amplify";
import {
  ExperienceParams,
  DeleteExperience,
} from "models/Contractor/NewMember/Experience";

export class ExperienceService {
  static async postExperience(data: ExperienceParams[]): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/experiences";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getExperience(): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/experiences";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putExperience(data: ExperienceParams[]): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/experiences";
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async deleteExperience(data: DeleteExperience): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/experiences/delete";
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
