import { put, takeLatest, call } from "redux-saga/effects";
import {
  UPDATE_USER_AVATAR_REQUEST,
  setUserAvatarReducer,
} from "redux/reducers/User/actionTypes";
import { ImageService } from "services/Image";

function* requestUpdateUserAvatar(action) {
  try {
    const res = yield call(ImageService.postImage, action.payload.image, action.payload.key);
    yield put(setUserAvatarReducer(res));
  } catch (e: any) {
    console.log(e);
  }
}

function* updateAvatar() {
  yield takeLatest(UPDATE_USER_AVATAR_REQUEST, requestUpdateUserAvatar);
}

export default updateAvatar;
